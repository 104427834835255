/**
 *
 * 该文件主要存放 style 操作，包括节点添加、删除、替换等
 */

//	正则匹配中，特殊字符 * . ?  $ ^ [ ] ( ) { } | \ /
//	针对以上特殊字符，进行相应转义处理
function fixRegSpecialCharacter(cls) {
    var specialCharacter = ['\\', '.', '?', '$', '*', '^', '[', ']', '{', '}', '|', '(', ')', '/'];

    for (var i = 0, len = specialCharacter.length; i < len; i++) {
        cls = cls.replace(specialCharacter[i], '\\' + specialCharacter[i]);
    }

    return cls;
}

/* 添加/移除样式标签<style>到文档上 - start */
export const setCtrlStyleCssList = function (sid, id, css, options) {
    var win = window;
    var doc = win.document;
    var style = win.$('#' + sid);
    var list = new Array();
    if (style.length == 1) {
        // ie6下会把没换行的也自动加了换行，例如#id .xxx{\n\tDISPLAY: none\n}
        var html = style.html();
        html = html.replace(/{\r\n/g, '{').replace(/\t/g, '').replace(/\r\n}/g, ';}');
        list = html.split('\n');
        style.remove();
    }

    for (var i = list.length - 1; i >= 0; --i) {
        var line = list[i];
        for (var n = 0; n < css.length; ++n) {
            var cls = css[n].cls;
            var key = css[n].key;
            var reg = new RegExp('#' + id + ' +' + fixRegSpecialCharacter(cls) + ' *{ *' + key + '[^;]*;', 'gi');

            if (id == '' || id == 'undefined') {
                //没有传ID的情况
                reg = new RegExp(fixRegSpecialCharacter(cls) + ' *{ *' + key + '[^;]*;', 'gi');
            }

            if (line.length == 0 || /^\s$/.test(line) || reg.test(line)) {
                if (
                    options &&
                    options.noRemoveModuleStyle &&
                    line.indexOf('module') > -1 &&
                    (id == '' || id == 'undefined') &&
                    cls.indexOf('module') === -1
                ) {
                    break;
                }
                list.splice(i, 1);
                break;
            }
        }
    }
    for (let n = 0; n < css.length; ++n) {
        if (id == '' || id == 'undefined') {
            //没有传ID的情况
            list.push(css[n].cls + '{' + css[n].key + ':' + css[n].value + ';}');
        } else {
            list.push('#' + id + ' ' + css[n].cls + '{' + css[n].key + ':' + css[n].value + ';}');
        }
    }

    //默认减减组成新的列表是倒序，覆盖插入的样式是插后面
    if (options && options.rev) {
        list.reverse();
    }

    $(doc.querySelector('head')).append('<style type="text/css" id="' + sid + '">' + list.join('\n') + '</style>');
};

export const setCtrlStyleCss = function (sid, id, cls, key, value, options) {
    var win = window;
    var doc = win.document;
    var style = win.$('#' + sid);
    var list = new Array();
    // 这里采用循环，不知道怎么会生成多个相同id的style节点，这里进行遍历删除 --> 处理 mobiStyleModule style节点，处理2.0模块内容区背景颜色，图片问题 @qjie
    while (style.length == 1) {
        // ie6下会把没换行的也自动加了换行，例如#id .xxx{\n\tDISPLAY: none\n}
        var html = style.html();
        html = html.replace(/{\r\n/g, '{').replace(/\t/g, '').replace(/\r\n}/g, ';}');
        list = list.concat(html.split('\n'));
        style.remove();
        style = win.$('#' + sid);
    }
    var reg = new RegExp('#' + id + ' +' + fixRegSpecialCharacter(cls) + ' *{ *' + key + '[^;]*;', 'gi');
    if (id == '' || id == 'undefined') {
        //没有传ID的情况
        reg = new RegExp(fixRegSpecialCharacter(cls) + ' *{ *' + key + '[^;]*;', 'gi');
    }

    for (var i = list.length - 1; i >= 0; --i) {
        var line = list[i];
        if (line.length == 0 || /^\s$/.test(line) || reg.test(line)) {
            if (
                options &&
                options.noRemoveModuleStyle &&
                line.indexOf('module') > -1 &&
                (id == '' || id == 'undefined') &&
                cls.indexOf('module') === -1
            ) {
                break;
            }
            list.splice(i, 1);
        }
    }

    if (id == '' || id == 'undefined') {
        //没有传ID的情况
        list.push(cls + '{' + key + ':' + value + ';}');
    } else {
        list.push('#' + id + ' ' + cls + '{' + key + ':' + value + ';}');
    }

    $(doc.querySelector('head')).append('<style type="text/css" id="' + sid + '">' + list.join('\n') + '</style>');
};

export const removeCtrlStyleCss = function (sid, id, cls, key, options) {
    var win = window;
    var doc = win.document;
    var style = win.$('#' + sid);
    var list = new Array();
    if (style.length == 1) {
        // ie6下会把没换行的也自动加了换行，例如#id .xxx{\n\tDISPLAY: none\n}
        var html = style.html();
        html = html.replace(/{\r\n/g, '{').replace(/\t/g, '').replace(/\r\n}/g, ';}');
        list = html.split('\n');
        style.remove();
    }
    var reg = new RegExp('#' + id + ' +' + fixRegSpecialCharacter(cls) + ' *{ *' + key + '[^;]*;', 'gi');
    if (id == '' || id == 'undefined') {
        //没有传ID的情况
        reg = new RegExp(fixRegSpecialCharacter(cls) + ' *{ *' + key + '[^;]*;', 'gi');
    }
    for (var i = list.length - 1; i >= 0; --i) {
        var line = list[i];
        if (line.length == 0 || /^\s$/.test(line) || reg.test(line)) {
            if (
                options &&
                options.noRemoveModuleStyle &&
                line.indexOf('module') > -1 &&
                (id == '' || id == 'undefined') &&
                cls.indexOf('module') === -1
            ) {
                break;
            }
            list.splice(i, 1);
        }
    }
    $(doc.querySelector('head')).append('<style type="text/css" id="' + sid + '">' + list.join('\n') + '</style>');
};

export const removeCtrlStyleCssList = function (sid, id, css, options) {
    var win = window;
    var doc = win.document;
    var style = win.$('#' + sid);
    var list = new Array();
    if (style.length == 1) {
        // ie6下会把没换行的也自动加了换行，例如#id .xxx{\n\tDISPLAY: none\n}
        var html = style.html();
        html = html.replace(/{\r\n/g, '{').replace(/\t/g, '').replace(/\r\n}/g, ';}');
        list = html.split('\n');
        style.remove();
    }
    for (var i = list.length - 1; i >= 0; --i) {
        var line = list[i];
        for (var n = 0; n < css.length; ++n) {
            var cls = css[n].cls;
            var key = css[n].key;
            var reg = new RegExp('#' + id + ' +' + fixRegSpecialCharacter(cls) + ' *{ *' + key + '[^;]*;', 'gi');
            if (id == '' || id == 'undefined') {
                //没有传ID的情况
                reg = new RegExp(fixRegSpecialCharacter(cls) + ' *{ *' + key + '[^;]*;', 'gi');
            }
            if (line.length == 0 || /^\s$/.test(line) || reg.test(line)) {
                if (
                    options &&
                    options.noRemoveModuleStyle &&
                    line.indexOf('module') > -1 &&
                    (id == '' || id == 'undefined') &&
                    cls.indexOf('module') === -1
                ) {
                    break;
                }
                list.splice(i, 1);
                break;
            }
        }
    }
    $(doc.querySelector('head')).append('<style type="text/css" id="' + sid + '">' + list.join('\n') + '</style>');
};

export const removeCtrlStyleCssList2 = function (sid, id, css) {
    var $ = jm;
    var style = $('#' + sid);
    var list = new Array();
    if (style.length == 1) {
        // ie6下会把没换行的也自动加了换行，例如#id .xxx{\n\tDISPLAY: none\n}
        var html = style.html();
        html = html.replace(/{\r\n/g, '{').replace(/\t/g, '').replace(/\r\n}/g, ';}');
        list = html.split('\n');
        style.remove();
    }
    for (var i = list.length - 1; i >= 0; --i) {
        var line = list[i];
        for (var n = 0; n < css.length; ++n) {
            var cls = css[n].cls;
            var key = css[n].key;
            var reg = '#' + id + ' ' + cls + '{' + key;
            if (line.length == 0 || /^\s$/.test(line) || line.indexOf(reg) != -1) {
                list.splice(i, 1);
                break;
            }
        }
    }
    $('head').append('<style type="text/css" id="' + sid + '">' + list.join('\n') + '</style>');
};

/* 添加/移除样式标签<style>到文档上 - end */
export const setFullSiteStyleCss = function (moduleId, cls, key, value) {
    var options = {};
    options.noRemoveModuleStyle = true;
    removeCtrlStyleCss('firstPaintStyle', moduleId, cls, key, value, options);
    setCtrlStyleCss('mobiStyleFullSiteModule', moduleId, cls, key, value, options);
};

export const setFullSiteStyleCssList = function (moduleId, css) {
    var options = {};
    options.noRemoveModuleStyle = true;
    removeCtrlStyleCssList('firstPaintStyle', moduleId, css, options);
    setCtrlStyleCssList('mobiStyleFullSiteModule', moduleId, css, options);
};

export const removeFullSiteStyleCss = function (moduleId, cls, key) {
    var options = {};
    options.noRemoveModuleStyle = true;
    removeCtrlStyleCss('mobiStyleFullSiteModule', moduleId, cls, key, null, options);
    // 首屏输出样式在这个id里
    removeCtrlStyleCss('firstPaintStyle', moduleId, cls, key, null, options);
};

export const removeFullSiteStyleCssList = function (moduleId, css) {
    var options = {};
    options.noRemoveModuleStyle = true;
    removeCtrlStyleCssList('mobiStyleFullSiteModule', moduleId, css, null, options);
    // 首屏输出样式在这个id里
    removeCtrlStyleCssList('firstPaintStyle', moduleId, css, null, options);
};

export const removeStyleCssList = function (sid, id) {
    var win = window;
    var style = win.$('#' + sid);
    var list = [];

    if (style.length == 1) {
        var html = style.html();
        list = html.split('\n');
        style.html('');
    }

    for (var i = list.length - 1; i >= 0; --i) {
        var line = list[i];

        if (line.length == 0 || line.indexOf(id) > -1) {
            list.splice(i, 1);
            continue;
        }
    }

    style.html(list.join('\n'));
};

export const setModuleStyleCss = function (moduleId, cls, key, value) {
    removeCtrlStyleCss('firstPaintStyle', 'module' + moduleId, cls, key, value);
    setCtrlStyleCss('mobiStyleModule', 'module' + moduleId, cls, key, value);
};

export const setModuleStyleCssList = function (moduleId, css) {
    removeCtrlStyleCssList('firstPaintStyle', 'module' + moduleId, css);
    setCtrlStyleCssList('mobiStyleModule', 'module' + moduleId, css);
};

export const removeModuleStyleCss = function (moduleId, cls, key) {
    removeCtrlStyleCss('mobiStyleModule', 'module' + moduleId, cls, key);
    removeCtrlStyleCss('firstPaintStyle', 'module' + moduleId, cls, key);
};

export const removeModuleStyleCssList = function (moduleId, css) {
    removeCtrlStyleCssList('mobiStyleModule', 'module' + moduleId, css);
    removeCtrlStyleCssList('firstPaintStyle', 'module' + moduleId, css);
};

export const addPreviewCtrlStyle = function (sid, styles) {
    var style = $(document).find('#' + sid);
    var $head = $(document.querySelector('head'));
    var list = [];
    if (!styles.length) return;
    var styleList = styles.split('\n');

    if (style.length == 1) {
        var html = style.html();
        html = html.replace(/{\r\n/g, '{').replace(/\t/g, '').replace(/\r\n}/g, ';}');
        list = html.split('\n');
        style.remove();
    }

    for (var i = list.length - 1; i >= 0; --i) {
        var line = list[i];

        for (var j = styleList.length - 1; j >= 0; --j) {
            var _style = styleList[j];
            var key = $.trim(_style.match(/[^{]*/)[0]);

            if (key.length && (line.length == 0 || line.indexOf(key) === 0)) {
                list.splice(i, 1);
                break;
            }
        }
    }

    list.push(styles);
    $head.append('<style type="text/css" id="' + sid + '">' + list.join('\n') + '</style>');
};

export const addStyleCssList = (sid, styles) => {
    var win = window;
    var style = win.$('#' + sid);
    var list = [];

    if (style.length == 1) {
        var html = style.html();
        list = html.split('\n');
    }

    list.push(styles);
    style.html(list.join('\n'));
};

export const addCtrlStyle = (sid, styles) => {
    var style = $('#' + sid);
    var list = new Array();

    if (style.length == 1) {
        // ie6下会把没换行的也自动加了换行，例如#id .xxx{\n\tDISPLAY: none\n}
        var html = style.html();
        html = html.replace(/{\r\n/g, '{').replace(/\t/g, '').replace(/\r\n}/g, ';}');
        list = html.split('\n');
        style.remove();
    }
    list.push(styles);

    $(document.querySelector('head')).append('<style type="text/css" id="' + sid + '">' + list.join('\n') + '</style>');
};

export const setCtrlStyle = function (sid, cssStr) {
    var style = jm('#' + sid);
    var list = new Array();
    if (style.length == 1) {
        // ie6下会把没换行的也自动加了换行，例如#id .xxx{\n\tDISPLAY: none\n}
        var html = style.html();
        html = html.replace(/{\r\n/g, '{').replace(/\t/g, '').replace(/\r\n}/g, ';}');
        list = html.split('\n');
        style.remove();
    }
    list.push(cssStr);

    jm('head').append('<style type="text/css" id="' + sid + '">' + list.join('\n') + '</style>');
};

export const createStyleSheet = (id, initialRule) => {
    const style = document.createElement('style');
    style.id = id;
    style.innerHTML = `${initialRule}`;
    jm(document.head).prepend(jm(style));
};
