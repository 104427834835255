<template>
    <component :is="component"></component>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ProductGroupDetailV3 from './v3/product-group-detail.vue';
import ProductGroupDetailV1 from './v1/product-group-detail.vue';

export default {
    name: 'ProductGroupDetail',
    data() {
        return {};
    },
    computed: {
        ...mapState('app', ['openThemeV3']),
        ...mapGetters(['getModuleById']),
        component() {
            return this.openThemeV3 ? ProductGroupDetailV3 : ProductGroupDetailV1;
        },
    },
};
</script>
