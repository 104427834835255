/**
 * 社交分享 Service
 */
import { ServiceFactory } from './factory';
import { ShareToService as BaseShareToService } from '@jz/biz-shared';
import { mobiIng } from '@/shared/fai';

const SERVICE_INTERFACE = 'ShareTo';

const $toastService = mobiIng;

export class ShareToService extends BaseShareToService {
    constructor() {
        super($toastService);
    }
}

ServiceFactory.register(SERVICE_INTERFACE, {
    interfaceClass: ShareToService,
});
