// @ts-check
import { MobiModule } from '@/modules/Module';
import NewsDetailVisitor from './visitor.vue';
import { MODULE_STYLE } from '@/def/module';

class NewsDetailModule extends MobiModule {
    constructor() {
        super(MODULE_STYLE.NEWS_DETAIL, { system: true });
    }

    /** @override */
    render() {
        return NewsDetailVisitor;
    }
}

export const NewsDetail = new NewsDetailModule();
