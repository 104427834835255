<template>
    <login-version-three v-if="openThemeV3" :module-id="moduleId"></login-version-three>
    <login-version-two v-else :module-id="moduleId"></login-version-two>
</template>
<script>
import LoginVersionTwo from './loginV2.vue';
import LoginVersionThree from './loginV3.vue';
import { mapState } from 'vuex';
export default {
    name: 'Login',
    components: {
        LoginVersionTwo,
        LoginVersionThree,
    },
    props: {
        moduleId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapState('app', ['openThemeV3']),
    },
};
</script>
<style lang="scss">
@import '../login.scss';
</style>
