<template>
    <component :is="component"></component>
</template>

<script>
import { mapState } from 'vuex';
import MsgBoardV3 from './v3/msg-board.vue';
import MsgBoardV1 from './v1/msg-board.vue';

export default {
    name: 'MsgBoard',
    computed: {
        ...mapState('app', ['openThemeV3']),
        component() {
            return this.openThemeV3 ? MsgBoardV3 : MsgBoardV1;
        },
    },
};
</script>
