var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "module-frame",
    {
      class: {
        has_bottom_fixed:
          _vm.hasRecommendedModule && (_vm.isOpen || _vm.shareOn),
      },
      attrs: { "module-id": _vm.moduleId },
    },
    [
      _c(
        "div",
        {
          staticClass: "newsNewDetailPanel newsDetailV3Panel detailPanelV3",
          attrs: { id: "newsNewDetailPanel" + _vm.moduleId },
        },
        [
          _c("news-detail-header"),
          _vm._v(" "),
          _c("news-detail-content", { attrs: { info: _vm.newsInfo } }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isOpen && (_vm.allowSubmitMessage || _vm.shareOn)
        ? _c("div", { staticClass: "newsBottom" }, [
            _vm.allowSubmitMessage
              ? _c(
                  "div",
                  { staticClass: "postComment", attrs: { id: "postComment" } },
                  [
                    _c("span", { staticClass: "icon-postComm" }),
                    _vm._v(_vm._s(_vm.mobi_productPostCommentTheme3Ls)),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.shareOn
              ? _c("span", {
                  staticClass:
                    "icon-share g_iconMiddle g_round newsShare icon-defaultColor",
                  attrs: { id: "newsDetailShare" },
                })
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("comment"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }