var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loaded,
            expression: "loaded",
          },
        ],
        staticClass: "msgBoard",
      },
      [
        _c(
          "div",
          { staticClass: "msgTop" },
          [
            _vm.manageMode
              ? [
                  _c("div", { staticClass: "noticeArea" }, [
                    _c("div", { staticClass: "notice" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.msgCanNotSubmitText) +
                          "\n                    "
                      ),
                    ]),
                  ]),
                ]
              : [
                  _c("div", { staticClass: "noticeArea" }, [
                    _c("div", {
                      staticClass: "notice",
                      staticStyle: { display: "none" },
                    }),
                  ]),
                ],
            _vm._v(" "),
            _vm._l(_vm.displayList, function (tmpProp) {
              return [
                _vm.defaultCreator !== "" &&
                tmpProp.type === _vm.MSGBOARD_PROP_DEF.TYPE.SYS_NAME
                  ? [
                      _c(
                        "div",
                        { key: tmpProp.id, staticClass: "g_globalLine" },
                        [
                          _c("input", {
                            staticClass: "msg_ipt g_input",
                            class: [
                              _vm.getBitMemory(
                                tmpProp.flag,
                                _vm.MSGBOARD_PROP_DEF.FLAG.REQUIRED
                              )
                                ? "msg_isMust"
                                : "",
                            ],
                            staticStyle: { color: "#c8c8c8" },
                            attrs: {
                              id: tmpProp.fieldKey,
                              type: _vm._f("inputTypeFilter")(tmpProp.fieldKey),
                              maxlength: _vm.MSGBOARD_DEF.LIMIT.REQ_PROP_MAXLEN,
                              disabled: "",
                            },
                            domProps: { value: _vm.defaultCreator },
                          }),
                          _vm._v(" "),
                          _c("span", {
                            class: [_vm.iconClassFilter(tmpProp.fieldKey)],
                          }),
                        ]
                      ),
                    ]
                  : tmpProp.id == _vm.MSGBOARD_PROP_DEF.TYPE.SYS_EMAIL
                  ? [
                      _c(
                        "div",
                        { key: tmpProp.id, staticClass: "g_globalLine" },
                        [
                          _c("input", {
                            staticClass: "msg_ipt g_input",
                            class: _vm.mustClassStr(tmpProp.flag),
                            attrs: {
                              id: tmpProp.fieldKey,
                              type: _vm._f("inputTypeFilter")(tmpProp.fieldKey),
                              maxlength: _vm.MSGBOARD_DEF.LIMIT.REQ_PROP_MAXLEN,
                              placeholder: _vm.addMust(
                                tmpProp[_vm.MSGBOARD_PROP_DEF.INFO.NAME],
                                tmpProp.flag
                              ),
                            },
                            domProps: { value: _vm.defaultEmail },
                          }),
                          _vm._v(" "),
                          _c("span", {
                            class: [_vm.iconClassFilter(tmpProp.fieldKey)],
                          }),
                        ]
                      ),
                    ]
                  : tmpProp.id == _vm.MSGBOARD_PROP_DEF.TYPE.SYS_PHONE
                  ? [
                      _c(
                        "div",
                        { key: tmpProp.id, staticClass: "g_globalLine" },
                        [
                          _c("input", {
                            staticClass: "msg_ipt g_input",
                            class: _vm.mustClassStr(tmpProp.flag),
                            attrs: {
                              id: tmpProp.fieldKey,
                              onkeyup: "value=value.replace(/[^\\d]/g,'')",
                              type: _vm._f("inputTypeFilter")(tmpProp.fieldKey),
                              maxlength: _vm.MSGBOARD_DEF.LIMIT.REQ_PROP_MAXLEN,
                              placeholder: _vm.addMust(
                                tmpProp[_vm.MSGBOARD_PROP_DEF.INFO.NAME],
                                tmpProp.flag
                              ),
                            },
                            domProps: { value: _vm.defaultPhone },
                          }),
                          _vm._v(" "),
                          _c("span", {
                            class: [_vm.iconClassFilter(tmpProp.fieldKey)],
                          }),
                        ]
                      ),
                    ]
                  : _c(
                      "div",
                      { key: tmpProp.fieldKey, staticClass: "g_globalLine" },
                      [
                        _c("input", {
                          staticClass: "msg_ipt g_input",
                          class: _vm.mustClassStr(tmpProp.flag),
                          attrs: {
                            id: tmpProp.fieldKey,
                            type: _vm._f("inputTypeFilter")(tmpProp.fieldKey),
                            maxlength: _vm.MSGBOARD_DEF.LIMIT.REQ_PROP_MAXLEN,
                            placeholder: _vm.addMust(
                              tmpProp[_vm.MSGBOARD_PROP_DEF.INFO.NAME],
                              tmpProp.flag
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("span", {
                          class: [_vm.iconClassFilter(tmpProp.fieldKey)],
                        }),
                      ]
                    ),
              ]
            }),
            _vm._v(" "),
            _c("div", { staticClass: "g_globalLine g_globalLine_textArea" }, [
              _c("textarea", {
                staticClass: "g_textArea msg_textArea msg_isMust",
                attrs: {
                  id: "reqContent",
                  maxlength: _vm.MSGBOARD_DEF.LIMIT.REQ_CONTENT_MAXLEN,
                  placeholder: _vm.msgBoardContentText,
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-mmsgIcon" }),
            ]),
            _vm._v(" "),
            _c("div", { staticStyle: { clear: "both" } }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "g_globalLine", class: _vm.captchaHideClass },
              [
                _c("input", {
                  staticClass: "captchaText g_input",
                  attrs: {
                    id: "msgBoardCaptcha",
                    type: "text",
                    maxlength: "4",
                    placeholder: _vm.enterVerificationCodeText,
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "captchaImg",
                  attrs: { id: "msgBoardCaptchaImg", src: _vm.validateCodeSrc },
                  on: { click: _vm.changeValidateCode },
                }),
              ]
            ),
            _vm._v(" "),
            _vm.manageMode
              ? [
                  _c("div", { staticClass: "g_globalLine g_globalLine_subm" }, [
                    _c("input", {
                      staticClass:
                        "g_button sendIcon msgSubmitButton submitIcon",
                      attrs: { type: "button", value: _vm.submitText },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "mustSpan" }, [_vm._v("  ")]),
                  ]),
                ]
              : [
                  _c("div", { staticClass: "g_globalLine g_globalLine_subm" }, [
                    _c("input", {
                      staticClass:
                        "g_button sendIcon msgSubmitButton submitIcon",
                      class: {
                        "not-allowed-button": _vm.allowSubmit === false,
                      },
                      attrs: { type: "button", value: _vm.submitText },
                      on: { click: _vm.addMsgHandler },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "mustSpan" }, [_vm._v("  ")]),
                  ]),
                ],
          ],
          2
        ),
        _vm._v(" "),
        _vm.showComments
          ? _c(
              "div",
              {
                staticClass: "msgBottom",
                class: { msgBottomDisplay: _vm.msgList.length === 0 },
              },
              [
                _c("div", { staticClass: "spaceRow" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "msgBottomTop" },
                  [
                    _c("div", { staticClass: "g_globalLine mtitle" }, [
                      _c(
                        "div",
                        {
                          staticClass: "mbTitle userMsgIcon",
                          staticStyle: { display: "inline-block" },
                        },
                        [
                          _c("span", { staticClass: "icon-userMsgIcon" }),
                          _vm._v(
                            _vm._s(_vm.msgBoardUserCommentsText) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.msgList, function (msgInfo) {
                      return [
                        _c(
                          "div",
                          {
                            key: msgInfo.id,
                            staticClass: "jz_msgItem",
                            attrs: { id: _vm._f("msgItemIdText")(msgInfo.id) },
                          },
                          [
                            _c("div", { staticClass: "jz_seperate_line" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "msgArea g_panel" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "msgTitle msgTitleHeight" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "textAndIcon" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "msgUser",
                                            attrs: { title: msgInfo.msgName },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(msgInfo.msgName) +
                                                "\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        !_vm.openMsgAuto &&
                                        !_vm.checkBit(
                                          msgInfo.flag,
                                          _vm.MSGBOARD_DEF.FLAG.PUBLIC
                                        )
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "jz_private_label",
                                                },
                                                [_vm._v(" 未公开 ")]
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "msgTime",
                                        attrs: { title: msgInfo.msgTitle2 },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.formatDate(msgInfo.reqTime)
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "msgContent",
                                  domProps: {
                                    innerHTML: _vm._s(msgInfo.reqContent),
                                  },
                                }),
                                _vm._v(" "),
                                msgInfo.rspContent
                                  ? [
                                      _c("div", {
                                        staticClass: "placeholderDiv",
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "reqArea g_replyPanel" },
                                        [
                                          _c("div", {
                                            staticClass: "msgArrow",
                                          }),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "admReq" }, [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(_vm.msgBoardReplyText) +
                                                "\n                                    "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", {
                                            staticClass: "admReqContent",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                msgInfo.rspContent
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  : [
                                      _c("div", {
                                        staticStyle: { clear: "both" },
                                      }),
                                    ],
                              ],
                              2
                            ),
                          ]
                        ),
                      ]
                    }),
                  ],
                  2
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.pagination.total !== 0 && _vm.showComments
          ? _c("pagination", {
              attrs: {
                "module-id": _vm.moduleId,
                "total-size": _vm.pagination.total,
                "page-size": _vm.module.content.page,
                pageno: _vm.pagination.pageNo,
                "open-theme-v3": false,
                "ajax-pagenation": true,
              },
              on: { "on-change": _vm.changePage },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }