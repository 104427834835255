import { getUrlParam } from '@/shared/url';
import { hasOpenFaiscoBaiduBridge } from './scripts/baidu-birdge';
import { statisticalMobiVisitors, mobiStatVisitTime } from './scripts/statistics';
import { printBaiduPushJs } from './scripts/baidu-push';
import { report as reportPerformance } from './scripts/performance';
import { addDistCode, bindMemberDistId } from './scripts/dist-code';
import { initMobiPage } from './scripts/init-page';
import { logDog } from '@/shared/log';
import { autoFixGWebTop } from '@/shared/autoFixGwebTop';
import { bookingBoxBind } from '@/modules/shared/product/booking';
import { autoLoginMemberSign } from './scripts/auto-login';
import { handleWxShare } from './scripts/wx-share';
import { addRequestPrefix } from '@/shared/util';
import { linkToForm } from '@/modules/mobiForm/util';
import { ing } from '@/shared/tips';
import lottery from '@/features/global-mobi-function/methods/lottery';
import { mobiIng } from '@/shared/fai';
import { initImgNoSelect } from '@/shared/util';
import { initAudioResourceHandler, initVideoResourceHandler } from '@/shared';
import formSubmitSuccessTipCustom from '@/modules/mobiForm/module/components/form-submit-success-tip-custom.vue';
import { getOneFormInfoApi } from '@/api/form';
/**
 * @fileoverview
 * preview渲染时需要执行的逻辑
 */

/**
 * 迁移mobi.jsp.inc输出的脚本, dom ready后执行
 */
export function execPreviewScriptsOnDomReady() {
    const manageMode = VITE_APP_MODE !== 'visitor';

    if (!manageMode) {
        rememberPageScrollPosition();
    }

    fixIosBug();

    if (Fai.top._ver == 10 || Fai.top._ver == 11) {
        limitUv();
    }

    if (!manageMode && Fai.top._isFaiHost && !Fai.top._fkAuth && !Fai.top._isFromJzmManage && !Fai.top._isFromQz) {
        limitDayUv();
    }

    if (!window.Logined && window.mbAutoLoginAllow) {
        autoLoginMemberSign(window.$store.state.app.lcid);
    }

    /*禁用右键，同时禁目复制、剪切 (管理态开放)*/
    if (Fai.top.banCopyTextImg && !(VITE_APP_MODE !== 'visitor')) {
        closeRightClickTool();
    }

    if (Fai.top.isBanWXResizeFontSize) {
        banWXResizeFontSize();
    }

    if (!manageMode) {
        showErrnoTips();
    }
}
/**
 *
 * 管理态路由变化就会执行，代码都需要处理重复执行的问题
 * @export
 */
export function execPreviewScriptsOnLoaded() {
    Fai.top._windowLoaded = true;
    checkUserDomainAction();

    actionByformPayResult(Fai.top._formModuleJumpUrl);

    Mobi.loadBDMap.loadBDMapScript();

    if (Fai.top.OpenWaterMark && cssTest()) {
        if (!jm('#GWWatermark').length) {
            jm('body').append("<svg id='GWWatermark' class='gw_watermark'><svg>");
        }
    }
    setTimeout(hasOpenFaiscoBaiduBridge, 1000); /*延迟一秒，等待百度商桥加载完成*/ // TODO 在商桥加载完的地方再进行处理

    initMobiPage(window._templateDef);
    statisticalMobiVisitors();

    if (!(VITE_APP_MODE !== 'visitor')) {
        mobiStatVisitTime();
    }

    if (Fai.top.openAutoPushMobi && !Fai.top._internal) {
        printBaiduPushJs();
    }

    reportPerformance();

    addDistCode();
    bindMemberDistId();

    bindViewIdForTs();

    ajaxLogDog();
    bookingBoxBind(Fai.top._bookingOverLimit);

    if (!(VITE_APP_MODE !== 'visitor')) {
        handleWxShare();
    }

    if (window.$store.state.app.isJzCdnFlowLimit) {
        initVideoResourceHandler();
        initAudioResourceHandler();
    }
}

function fixIosBug() {
    // 只对IOS生效
    let iosReg = /(iPhone|iPad|iPod|iOS)/i;
    if (!iosReg.test(navigator.userAgent)) {
        return;
    }

    // 处理IOS下软键盘激活结束后没有重置页面高度，导致底部留白、点击元素混乱的问题，目前暂时只处理表单模块
    fixWebViewKeyBoardNoResetPagePosition();
}

function fixWebViewKeyBoardNoResetPagePosition() {
    const formModuleClass = '.jz_mobi_form';
    let isReset = true; //是否归位
    let scrollTop = 0; //当前滚动距离
    jm('body')
        .off('focusin.fixIosBug')
        .on('focusin.fixIosBug', (e) => {
            let target = e.target;
            if (jm(target).closest(formModuleClass).length) {
                scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
                isReset = false; //聚焦时键盘弹出，焦点在输入框之间切换时，会先触发上一个输入框的失焦事件，再触发下一个输入框的聚焦事件
            }
        });

    jm('body')
        .off('focusout.fixIosBug')
        .on('focusout.fixIosBug', (e) => {
            let target = e.target;
            if (jm(target).closest(formModuleClass).length) {
                isReset = true;
                setTimeout(() => {
                    if (isReset) {
                        window.scrollTo(0, scrollTop);
                    }
                }, 300);
            }
        });
}

function limitUv() {}

function goLeft() {
    //780是根据您给的尺寸(滚动横幅的长度)，可变的
    if (Fai.top.scollDistance == -780) {
        Fai.top.scollDistance = 0;
    }
    Fai.top.scollDistance -= 1;
    jm('.fk-mobiTips .scroll').css({
        left: Fai.top.scollDistance + 'px',
    });
}

function limitDayUv() {
    var params = '&siteLan=' + Fai.top._wid + '&siteId=' + Fai.top._siteId;
    const body = '#g_body';
    jm.ajax({
        type: 'post',
        url: '/ajax/site_h.jsp?cmd=getWafNotCk_getLimitPreview',
        data: params,
        timeout: 60000,
        success(data) {
            var result = jm.parseJSON(data);
            if (result.success && result.type != 0) {
                var tips = '';
                if (result.type == 1) {
                    if (!Fai.top._oem) {
                        logDog(200732, 1);
                        var aurl = '';
                        if (result.prefix && result.mHomeHost) {
                            aurl =
                                result.prefix +
                                result.mHomeHost +
                                '/feedBack/feedBack.jsp?url_s=' +
                                result.prefix +
                                result.acct;
                        }
                        if (Fai.top._isShowFkAuthTips) {
                            tips +=
                                '免费域名' +
                                result.acct +
                                `只用作预览访问，商业用途请先<a href='//${window.Hosts.home}/marketing/domain.html' target='_blank' style='color:#5874d8;font-size:12px;text-decoration:underline;'>绑定独立域名</a>或<a href='//${window.Hosts.portal}/portal.jsp#appId=setAuth' target='_blank' style='color:#5874d8;font-size:12px;text-decoration:underline;'>开通` +
                                Fai.top._fkAuthTitle +
                                '</a>';
                        } else {
                            tips +=
                                '免费域名' +
                                result.acct +
                                "只用作预览使用，如涉及到不良信息传播、转账交易等，请谨慎对待。<a style='color:#ff8e1e;font-size:14px;text-decoration:underline;' onclick='javascript:Mobi.logDog(200732, 2);'  href='" +
                                aurl +
                                "'>我要投诉</a>";
                        }
                    } else {
                        tips +=
                            '免费域名' + result.acct + '只用作预览使用，如涉及到不良信息传播、转账交易等，请谨慎对待。';
                    }
                    var html =
                        '<div id="fk-tipsLimitUv" class="fk-tipsDiv"><div  class="fk-mobiTips"><div class="scroll"><li>' +
                        tips +
                        '</li><li>' +
                        tips +
                        '</li></div></div></div>';
                    jm(body).prepend(html);
                    //设置滚动
                    Fai.top.scollDistance = 0;
                    //设置滚动速度
                    setInterval(goLeft, 20);
                    autoFixGWebTop(Fai.top._templateLayoutId);
                } else if (result.type == 2) {
                    if (Fai.top._oem) {
                        tips +=
                            '访客量超出限制，免费域名' + result.acct + '只用作预览访问，商业使用请绑定独立域名访问。';
                        let html =
                            '<div class="fk-limtUv"><div class="tipDialog">' +
                            '<span class="quitIcon"></span>' +
                            '<img width="440px" height="245px" class="tipComtent" src="' +
                            Fai.top._resImageRoot +
                            '/image/v2/pageNotFound.png">' +
                            '</img>' +
                            '<div class="tipFont" style="font-size:16px;width:90%;margin-left:5%;"> ' +
                            tips +
                            '</div>' +
                            '</div></div>';
                        jm(body).append(html);
                        var bodyObj = document.getElementById('g_body');
                        bodyObj.style.overflowY = 'hidden';
                    } else {
                        if (Fai.top._isShowFkAuthTips) {
                            //在别人的基础上、添加两条链接 by jser 2019-4-8
                            var urlForBindDevDomain = '//' + Fai.top._homeHost + '/marketing/domain.html'; //绑定独立域名
                            var urlForApplyFaiscoAuth = '//' + Fai.top._portalHost + '/portal.jsp#appId=setAuth'; //申请凡科认证
                            tips +=
                                '免费域名' +
                                result.acct +
                                "只用作预览访问，商业用途请先<a href='" +
                                urlForBindDevDomain +
                                "' onclick='Site.logCorpDog(200848, 6);' target='_blank' style='color:#5874d8;text-decoration:underline;'>绑定独立域名</a>或<a href='" +
                                urlForApplyFaiscoAuth +
                                "' target='_blank' onclick='Site.logCorpDog(200848, 3);' style='color:#5874d8;text-decoration:underline;'>开通" +
                                Fai.top._fkAuthTitle +
                                '</a>';
                        } else {
                            tips +=
                                '访客量超出限制，免费域名' +
                                result.acct +
                                '只用作预览访问，商业使用请绑定独立域名访问。';
                        }
                        let tips2 = '';
                        if (result.ver == 0) {
                            tips2 += '升级网站,绑定域名';
                        } else if (result.isFaiDomain) {
                            tips2 += '访问:' + result._mainUrl;
                        } else {
                            tips2 += '登录后台，绑定域名';
                        }
                        let html =
                            '<div class="fk-limtUv"><div class="tipDialog">' +
                            '<span class="quitIcon"></span>' +
                            '<img width="440px" height="245px" class="tipComtent" src="' +
                            Fai.top._resImageRoot +
                            '/image/v2/pageNotFound.png">' +
                            '</img>' +
                            '<div class="tipFont" style="font-size:16px;width:90%;margin-left:5%;"> ' +
                            tips +
                            '</div>' +
                            '<div class="tipButton tipButton2">' +
                            tips2 +
                            '</div>' +
                            '</div></div>';
                        jm(body).append(html);
                        jm('.fk-limtUv .tipButton').click(function () {
                            if (result.ver == 0) {
                                window.open(`//jz${window.Hosts.mHome}/product.html`);
                            } else if (result.isFaiDomain) {
                                window.open(Fai.top.httpStr + result._mainUrl);
                            } else {
                                window.open(`//${window.Hosts.portal}/portal.jsp`);
                            }
                        });
                        let bodyObj = document.getElementById('g_body');
                        bodyObj.style.overflowY = 'hidden';
                    }
                } else if (result.type == 3) {
                    tips = '当前域名禁止访问';
                    var tips3 = '点击这里访问您的网站';
                    let html =
                        '<div class="fk-limtUv"><div class="tipDialog">' +
                        '<span class="quitIcon"></span>' +
                        '<img width="440px" height="245px" class="tipComtent" src="' +
                        Fai.top._resImageRoot +
                        '/image/v2/pageNotFound.png">' +
                        '</img>' +
                        '<div class="tipFont" style="font-size:16px;width:90%;margin-left:5%;"> ' +
                        tips +
                        '</div>' +
                        '<div class="tipButton tipButton2">' +
                        tips3 +
                        '</div>' +
                        '</div></div>';
                    jm(body).append(html);
                    jm('.fk-limtUv .tipButton').click(function () {
                        window.open(Fai.top.httpStr + result.acct);
                    });
                    let bodyObj = document.getElementById('g_body');
                    bodyObj.style.overflowY = 'hidden';
                }
            }
        },
    });
}

/*禁用右键，同时禁目复制、剪切*/
function closeRightClickTool() {
    var $document = jm(document);
    // PC端
    $document.on('contextmenu copy cut', function (event) {
        if (jm(event.target).is('input, textarea')) {
            return;
        } else {
            return false;
        }
    });

    // 手机端
    $document.off('touchend.bc').on('touchend.bc', function () {
        jm('.img_no_select').removeClass('img_no_select');
    });

    // 为了不阻塞其他主要JS，这里延时加载
    jm(window).on('load', function () {
        setTimeout(function () {
            jm('img').forEach(function (el) {
                initImgNoSelect(el);
            });
        }, 1000);
    });
}

function banWXResizeFontSize() {
    if (typeof WeixinJSBridge == 'object' && typeof WeixinJSBridge.invoke == 'function') {
        handleFontSize();
    } else {
        document.addEventListener('WeixinJSBridgeReady', handleFontSize, false);
    }
    function handleFontSize() {
        // 设置网页字体为默认大小
        WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: 0 });
        // 重写设置网页字体大小的事件
        WeixinJSBridge.on('menu:setfont', function () {
            WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: 0 });
        });
    }
}

function cssTest() {
    var elem = document.createElement('x');
    elem.style.cssText = 'pointer-events:auto';
    return elem.style.pointerEvents === 'auto';
}

function checkUserDomainAction() {
    if (VITE_APP_MODE !== 'visitor' || Fai.top._isDev || Fai.top.isDebug) {
        return;
    }
    jm.ajax({
        url: '/ajax/siteDomain_h.jsp?cmd=getWafNotCk_checkFaiDomain',
        type: 'POST',
        data: { domain: window.location.host },
        success(result) {
            try {
                var res = jm.parseJSON(result);
                if (res.success && !!res.isFaiDomain) {
                    return;
                }
                removeSite();
            } catch (e) {
                removeSite();
            }
        },
        error: actionForErrorAjax,
    });

    function actionForErrorAjax(result) {
        let { responseText } = result;

        if (result.status == 500 && responseText.indexOf('empty response') != -1) {
            removeSite();
        }
    }

    function removeSite() {
        jm('body').html('');
    }
}

function bindViewIdForTs() {
    var currentUrl = window.location.href;
    var viewerId = getUrlParam(currentUrl, 'viewerId') || '';
    if (viewerId && viewerId != '') {
        jm.cookie('ts_viewerId', viewerId, { path: '/' });
    } else {
        viewerId = jm.getCookie('ts_viewerId');
    }
    var memberId = Fai.top.sessionMemberId || 0;
    if (memberId <= 0) {
        return;
    }
    if (viewerId && viewerId != '') {
        viewerId = parseInt(viewerId);
        if (!isNaN(viewerId)) {
            jm.ajax({
                type: 'get',
                url: '/ajax/member_h.jsp?cmd=setWafCk_setMemberViewerIdForTs&viewerId=' + viewerId,
                success() {},
            });
        }
    }
}

function ajaxLogDog() {
    jm.ajax({
        type: 'POST',
        url: '/ajax/log_h.jsp?cmd=wafNotCk_mobiLogDog&hostName=' + Fai.top.location.host,
        error() {
            /*if(Fai.ing){
                Fai.ing('系统异常，请稍候重试', true);
            }else{
                Mobi.ing('系统异常，请稍候重试', true);
            }*/
        },
        success() {},
    });
}

export function closeLoading() {
    //loading
    var webLoading = document.getElementById('webLoading');
    if (!webLoading) return;
    // webLoading.innerHTML = "";
    webLoading.style.display = 'none';
}

export const showLoading = function () {
    var webLoading = document.getElementById('webLoading');
    if (!webLoading) return;
    webLoading.style.display = 'block';
};

async function actionByformPayResult(jumpUrl = '') {
    // 检测是否是从支付页面返回
    const isFromPay = window.sessionStorage.getItem('from_mobi_pay') === 'true';
    window.sessionStorage.removeItem('from_mobi_pay');
    if (!isFromPay) {
        return;
    }

    // 处理url参数
    const curUrlSearch = window.location.search;
    const urlParams = new URLSearchParams(curUrlSearch);
    const scrollTop = +urlParams.get('scrollTop');
    const isPopupForm = urlParams.get('isPopupForm') === 'true';
    const formId = +urlParams.get('formId');
    const bssId = +urlParams.get('bssId');
    const payStatus = urlParams.get('payStatus');
    const isReturnOrCancelPay = payStatus === '0';
    const isPayPal = Boolean(urlParams.get('PayerID'));

    if (isReturnOrCancelPay) {
        failPayAction({
            scrollTop,
            isPopupForm,
            formId,
            isLastQueryFail,
        });
        return;
    }

    let isLastQueryFail = false;
    let removeQueryLoading = null;

    let isPaySuccess = false;
    let payingTipsModal = null;
    if (isPayPal) {
        payingTipsModal = Vue.prototype.$modal.confirm({
            footer: null,
            title: LS.paying || '请稍等，正在支付中...',
        });
    } else {
        setTimeout(() => {
            if (!isPaySuccess) {
                removeQueryLoading = showQueryLoaing();
            }
        }, 1500);
    }
    isPaySuccess = await queryFormPayResult(bssId, isPayPal ? 60 : 6);
    if (isPayPal) {
        payingTipsModal.destroy();
    } else {
        removeQueryLoading?.();
        isLastQueryFail = !isPaySuccess;
    }

    // 跳转到原页面并执行后续逻辑
    if (isPaySuccess) {
        Mobi.logDog(201217, 4);
        setscrollTop(scrollTop);
        let { success, data } = await getOneFormInfoApi({ formId });
        const formInfo = success ? data.module.content.fi : {};
        let submitSuccessTipCustom = formInfo.submitSuccessTipCustom;
        let popupContent = formInfo.popupContent;
        if (!submitSuccessTipCustom) {
            ing(LS.paySuccess || '支付成功', 1);
        } else {
            jm("<div id='formSubmitSuccessTipCustom'></div>").appendTo('#g_body');

            new Vue({
                components: {
                    'form-submit-success-tip-custom': formSubmitSuccessTipCustom,
                },
                data() {
                    return {
                        popupContent,
                    };
                },
                methods: {
                    close() {
                        this.$destroy();
                        $(`#formSubmitSuccessTipCustom`).remove();
                    },
                },
                template: `<form-submit-success-tip-custom :content="popupContent" :close="close"></form-submit-success-tip-custom>`,
            }).$mount('#formSubmitSuccessTipCustom');
        }

        const isAction = jumpUrl.startsWith('javascript:');
        // 通过localStorage获取支付后表单是否触发抽奖
        if (localStorage.getItem('pay_lotteryId') && localStorage.getItem('pay_lotteryId') != 'undefined') {
            const pay_success_lotteryId = localStorage.getItem('pay_lotteryId'),
                pay_success_phoneNumber = localStorage.getItem('pay_phoneNumber'),
                allowForm = true;
            lottery.getLotteryInfo(pay_success_lotteryId, pay_success_phoneNumber, null, allowForm);
            localStorage.setItem('pay_lotteryId', '');
            localStorage.setItem('pay_phoneNumber', '');
        } else {
            if (jumpUrl) {
                // 这里的链接可能是功能链接，插入一个a标签并模拟点击
                setTimeout(() => {
                    if (isAction) {
                        eval(jumpUrl);
                    } else {
                        window.location.href = jumpUrl;
                    }
                }, 2500);
            }
        }
    } else {
        if (isPayPal) {
            Vue.prototype.$modal.confirm({
                title: LS.payError || '支付异常，请联系商家确认订单信息',
                themeColor: window.$store.state.app.themeColor,
            });
        } else {
            failPayAction({
                scrollTop,
                isPopupForm,
                formId,
                isLastQueryFail,
            });
        }
    }
}

function setscrollTop(scrollTop) {
    document.documentElement.scrollTop = scrollTop;
    window.pageYOffset = scrollTop;
    document.body.scrollTop = scrollTop;
}

function failPayAction({ scrollTop, isPopupForm, formId, isLastQueryFail }) {
    Mobi.logDog(201217, 3);

    setscrollTop(scrollTop);

    if (isPopupForm) {
        linkToForm(formId);
    }

    const queryFailText = isLastQueryFail ? '请前往会员中心查看支付结果' : '支付失败';
    ing(queryFailText, isLastQueryFail ? 1 : 2);
}

function queryFormPayResult(bssId, retryTimes, topResolve, topReject) {
    return new Promise((resolve, reject) => {
        if (!topResolve) {
            topResolve = resolve;
        }
        if (!topReject) {
            topReject = reject;
        }
        let param = [];
        param.push(`&id=${bssId}`);

        jm.ajax({
            type: 'post',
            url: `${addRequestPrefix({
                newPath: '/ajax',
                oldPath: 'ajax',
            })}/mobiForm_h.jsp?cmd=getWafNotCk_getSiteFormSubmitById`,
            data: param.join(''),
            error() {
                Mobi.ing('系统繁忙，请稍候重试', false);
                reject();
            },
            success: (result) => {
                try {
                    result = jm.parseJSON(result);
                } catch (error) {
                    topReject(error);
                }
                if (!result.success) {
                    topReject(result);
                    return;
                }
                if (result.isPaySuccess) {
                    topResolve(result.isPaySuccess);
                } else {
                    if (retryTimes > 0) {
                        setTimeout(() => {
                            queryFormPayResult(bssId, retryTimes - 1, topResolve, topReject);
                        }, 1000);
                    } else {
                        topResolve(result.isPaySuccess);
                    }
                }
            },
        });
    });
}

function showQueryLoaing() {
    const loadingID = `showQueryLoaing`;
    jm('body').append(`
        <div
            id="${loadingID}"
            style="
                width: 3rem;
                height:3rem;
                background: rgba(0,0,0,.75);
                border-radius: 0.2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                z-index: 99999;
            "
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 30 30"
                style="
                    width: 1.15rem;
                    height: 1.15rem;
                "
            >
            <style>
                .showQueryLoaing {
                    animation: svg-load 1.8s linear infinite;
                }
                @keyframes svg-load {
                    from {
                        transform:rotate(0);
                        transform-origin: 50% 50%;
                    }
                    to {
                        transform:rotate(360deg);
                        transform-origin: 50% 50%;
                    }
                }
            </style>
                <path class="showQueryLoaing" d="M8,20.63A1.37,1.37,0,0,0,7,21L4.16,23.85a1.41,1.41,0,1,0,2,2L9,23a1.4,1.4,0,0,0-1-2.4ZM6.56,15a1.41,1.41,0,0,0-1.4-1.41H1.41a1.41,1.41,0,0,0,0,2.82H5.16A1.41,1.41,0,0,0,6.56,15ZM6.15,4.16a1.41,1.41,0,1,0-2,2L7,9a1.41,1.41,0,0,0,1.37.39,1.43,1.43,0,0,0,1-1A1.41,1.41,0,0,0,9,7ZM22,9.37A1.37,1.37,0,0,0,23,9l2.81-2.81a1.41,1.41,0,1,0-2-2L21,7a1.4,1.4,0,0,0,1,2.4ZM23,21a1.41,1.41,0,0,0-1.37-.39,1.43,1.43,0,0,0-1,1A1.41,1.41,0,0,0,21,23l2.81,2.81a1.41,1.41,0,1,0,2-2Zm-8,2.4a1.41,1.41,0,0,0-1.41,1.4v3.75a1.41,1.41,0,1,0,2.82,0V24.84A1.41,1.41,0,0,0,15,23.44Zm13.59-9.85H24.84a1.41,1.41,0,0,0,0,2.82h3.75a1.41,1.41,0,1,0,0-2.82ZM15,0a1.41,1.41,0,0,0-1.41,1.41V5.16a1.41,1.41,0,0,0,2.82,0V1.41A1.41,1.41,0,0,0,15,0Zm0,0" fill="#fff">
                </path>
            </svg>
        </div>
    `);

    return function removeQueryLoaing() {
        jm(`#${loadingID}`).remove();
    };
}

// original window.Pre.errnoIng
function showErrnoTips() {
    // 获取url参数
    function getUrlQueryString(name) {
        var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
        var r = window.location.search.substr(1).match(reg);
        if (r != null) {
            return unescape(r[2]);
        }
        return null;
    }

    function redirectWithNoErrno(errno) {
        document.location.href = window.location.href.replace(new RegExp(`errno=${errno}`), '');
    }

    const errno = getUrlQueryString('errno');
    if (errno == 14) {
        mobiIng(window.LS.memberLoginNoPermission);
        setTimeout(() => {
            redirectWithNoErrno(errno); // 回到首页提示没有权限之后，把errno参数去掉，一面重新刷新首页时出现没有权限的提示
        }, 1000); // 1s 后再跳转，用户看清提示语
    }
}

function rememberPageScrollPosition() {
    const StorageKey = 'scroll-position';
    const scrollPosition = JSON.parse(window.sessionStorage.getItem(StorageKey) || '{}');
    const pageKey = window.location.href;

    jm('#g_web').scrollTop(scrollPosition[pageKey]);

    jm(window).on('beforeunload', function () {
        scrollPosition[pageKey] = jm('#g_web').scrollTop();
        window.sessionStorage.setItem(StorageKey, JSON.stringify(scrollPosition));
    });
}
