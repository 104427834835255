import { getUrlParam } from '@/shared/url';

class LocaterDomServiceCtor {
    initLocater() {
        initLocater();
    }

    bindManageEventListeners() {
        bindEventListeners();
    }

    openLanaguageSelector() {
        openLanSelect();
    }

    closeLanguageSelector() {
        closeLanSelect();
    }
}

export const LocaterDomService = new LocaterDomServiceCtor();

function bindEventListeners() {
    $(document.getElementById('preview')).hover(
        function () {},
        function () {
            if ($('#multiLanguageCtrl').length < 1 || $('#multiLanguageCtrl').css('display') === 'none') {
                return;
            }
            setTimeout(function () {
                closeLanSelect();
            }, 100);
        }
    );

    $('.lanItem, .langItem').click(function () {
        if ($('#lanSelect').length < 1 || parseInt($('#lanSelect')) == 0) {
            return;
        }
        var topUrl = document.location.href;
        var manageUrl = topUrl.split('/')[2];
        var loc = $(this).attr('value');
        var isStaticUrl = loc.indexOf('/h-index.html') != -1;
        if (isStaticUrl) {
            loc = loc.split('/h-index.html')[0];
            document.location.href = '//' + manageUrl + loc + '/manage/mobiDesign.jsp';
        } else {
            document.location.href = '//' + manageUrl + loc + 'manage/mobiDesign.jsp';
        }
    });
}

function initLocater() {
    var divInit = jm('#multiLanguageCtrl ');
    if (!divInit) {
        return false;
    } else {
        var itemCurrent = jm('#lanSelect .lanContainer').find('div[currentlan]'),
            itemTitle = itemCurrent.length === 1 ? jm(itemCurrent[0]).text() : jm(itemCurrent[1]).text(),
            itemClass =
                itemCurrent.length === 1
                    ? jm(itemCurrent[0]).attr('currentlan')
                    : jm(itemCurrent[1]).attr('currentlan');

        itemCurrent.hide();

        jm(divInit).addClass('Icon' + itemClass);
        jm('#lanSelected').addClass('Icon' + itemClass);

        divInit.find('.lanTitle').eq(0).text(itemTitle);
        jm('#lanSelected').find('.lanTitle').eq(0).text(itemTitle);

        jm('.lanItem ').click(function (e) {
            if (jm('#lanSelect').length < 1 || parseInt(jm('#lanSelect').height()) == 0) {
                return;
            }
            e.stopPropagation();

            if (!(VITE_APP_MODE !== 'visitor')) {
                // 管理态在 Mobi.initMultiLanguage 中绑定了跳转事件
                document.location.href = jm(this).attr('value');
            }
        });

        jm(document)
            .unbind('click')
            .bind('click', function (event) {
                var targetId = event.target || event.srcElement;

                if (targetId.id === 'multiLanguageCtrl') {
                    return;
                }
                var target = jm(targetId);
                if (target.closest('#lanSelect').length == 0) {
                    /*if(jm("#lanSelect").hasClass('lanDisabled')){
					return;
				}*/
                    closeLanSelect();
                    //Mobi.removeLanSelectFlag();
                }

                // 搜狗浏览器的<a>标签父元素不允许event.stopPropagation()\event.preventDefault()等方法，否则链接不跳转 @ken 20180320
                if (!(navigator.userAgent.toLowerCase().indexOf('sogoumobilebrowser') > -1)) {
                    event.stopPropagation();
                }
            });
        //主要是因为像手机QQ浏览器那样，即使最外层高度为0，子元素单纯是视觉隐藏，仍然会遮挡部分元素,原因不明。
        jm('#lanSelect .lanContainer').height(0);

        var returnUrl = getUrlParam(window.location.href, 'returnUrl');
        if (!returnUrl) {
            returnUrl = window.location.pathname + window.location.search;
        }
        returnUrl = jm.encodeUrl(returnUrl);

        var signupHref = jm('#topBarMemberSignup').attr('href');
        jm('#topBarMemberSignup').attr('href', signupHref + returnUrl);

        var memberNameHref = jm('#topBarMemberName').attr('href');
        jm('#topBarMemberName').attr('href', memberNameHref + returnUrl);
    }
}

function closeLanSelect() {
    if (jm('#lanSelect').length < 1 || parseInt(jm('#lanSelect').height()) == 0) {
        return;
    }

    if (jm('#bgm_icon').length > 0 && jm('#bgm_icon').hasClass('bgm_rightUp')) {
        jm('#bgm_icon').css('visibility', 'visible');
    }

    jm('#lanSelect').height(0);

    jm('#lanSelect').css('opacity', 0.3);
    jm('#lanSelect .lanContainer').height(0);
    if (VITE_APP_MODE !== 'visitor') {
        jm('#lanFixed').remove();
    }
}

function openLanSelect() {
    if (jm('#lanSelect').length < 1 || parseInt(jm('#lanSelect').height()) > 0) {
        return;
    }

    if (jm('#bgm_icon').length > 0 && jm('#bgm_icon').hasClass('bgm_rightUp')) {
        jm('#bgm_icon').css('visibility', 'hidden');
    }
    jm('#lanSelect .lanContainer').height('');
    var tmpHeight = parseFloat(jm('#lanSelected').height()) + parseFloat(jm('#lanSelect .lanContainer').height());
    var marginTop = jm('.multiLanguageCtrl ').css('margin-top');
    if (marginTop.indexOf('px') > 0) {
        marginTop = parseFloat(marginTop.substr(0, marginTop.length - 2));
    } else {
        marginTop = parseFloat(marginTop);
    }
    tmpHeight = tmpHeight + marginTop;
    jm('#lanSelect').height(tmpHeight + 'px');
    jm('#lanSelect').css('opacity', 1);
    if (VITE_APP_MODE !== 'visitor') {
        jm('#webMultilingualArea').append("<div id='lanFixed' class='lanFixed'></div>");
    }
}
