import { GROUP_STYLE } from '../constants';

export default {
    props: {
        groupStyle: {
            type: Number,
            default: -1,
        },
        productGroupType: {
            type: Number,
            default: -1,
        },
        prUrl: {
            type: String,
            default: '',
        },
        firstGroupList: {
            type: Array,
            default: () => [],
        },
        secondGroupMap: {
            type: Object,
            default: () => ({}),
        },
        topLevel: {
            type: Number,
            default: -1,
        },
        panelOptions: {
            type: Object,
            default: () => ({}),
        },
        isMallTheme: {
            type: Boolean,
            default: false,
        },
        catalogList: {
            type: Array,
            default: () => [],
        },
        hasAuth: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isImageStyle() {
            return this.groupStyle !== GROUP_STYLE.TEXT;
        },
        isHorizon() {
            return (
                this.groupStyle === GROUP_STYLE.TWO_LEVEL_HORIZON || this.groupStyle === GROUP_STYLE.THREE_LEVEL_HORIZON
            );
        },
    },
};
