var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "div",
        { staticClass: "ad_guide_purchaseVerForFree" },
        [
          _c("lower-left-component", {
            attrs: { config: _vm.config },
            on: { closeAd: _vm.closeAd, clickAd: _vm.clickAd },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }