import request from './request/index';

export function getAllVote() {
    return request.get('/ajax/design_h.jsp?cmd=getWafNotCk_getAllVote').then(({ data }) => data);
}

export function getVoteResult(voteId) {
    const url = VITE_APP_MODE !== 'visitor' ? '/api/manage/vote/getResult' : '/api/guest/vote/getResult';

    return request
        .get(url, {
            params: {
                id: voteId,
            },
        })
        .then(({ data }) => data);
}

export function addVoteItem(data) {
    const url = '/ajax/vote_h.jsp?cmd=addWafCk_voteItem';
    return request.post(url, { data }).then(({ data }) => data);
}
