var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "module-frame",
    { attrs: { "module-id": _vm.moduleId } },
    [
      _c(
        "div",
        {
          staticClass: "newsList newsMore",
          class: _vm.newsListClass,
          attrs: { id: _vm.idSelector },
        },
        [
          _vm.picNum === 5
            ? _c(
                "div",
                {
                  staticClass: "J_newsListPanel f-cardGraphicNews",
                  attrs: { id: "J_newsListPanel" + _vm.moduleId, picNum: "5" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "J_newsListSlidePanel f-cardGraphicNewsSlide",
                      attrs: { picNum: "5" },
                    },
                    [
                      _vm.newsList.length === 0
                        ? _c("div", { staticClass: "fk-noProduct" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.productNoResultText) +
                                " "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.productRescreenText) +
                                "\n                "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasTwoColumnArrangeWay
                        ? _c(
                            "div",
                            {
                              staticClass: "newsArrayPanel",
                              class: ["column" + _vm.newsArrangeWay],
                            },
                            [
                              _vm._l(_vm.newsList, function (newsItem, i) {
                                return [
                                  _c(
                                    "news-more-item",
                                    _vm._b(
                                      {
                                        key: newsItem.id,
                                        attrs: {
                                          index: i,
                                          "news-item": newsItem,
                                        },
                                      },
                                      "news-more-item",
                                      _vm.newsMoreItemProps,
                                      false
                                    )
                                  ),
                                  _vm._v(" "),
                                  _vm.hasTwoColumnArrangeWay &&
                                  (_vm.isNeedTwoColumn(i) ||
                                    _vm.isNeedcSeparatorLine(i))
                                    ? _c("div", {
                                        key: newsItem.id + "c2_sep",
                                        staticClass: "c2_separatorLine",
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.picNum != 5 &&
                                  _vm.picNum != 6 &&
                                  _vm.picNum != 7 &&
                                  !_vm.hasTwoColumnArrangeWay
                                    ? _c("div", {
                                        key: newsItem.id + "g_sep",
                                        staticClass:
                                          "g_separator separatorLine",
                                      })
                                    : _vm._e(),
                                ]
                              }),
                            ],
                            2
                          )
                        : [
                            _vm._l(_vm.newsList, function (newsItem, i) {
                              return [
                                _c(
                                  "news-more-item",
                                  _vm._b(
                                    {
                                      key: newsItem.id,
                                      attrs: {
                                        index: i,
                                        "news-item": newsItem,
                                      },
                                    },
                                    "news-more-item",
                                    _vm.newsMoreItemProps,
                                    false
                                  )
                                ),
                                _vm._v(" "),
                                _vm.hasTwoColumnArrangeWay &&
                                (_vm.isNeedTwoColumn(i) ||
                                  _vm.isNeedcSeparatorLine(i))
                                  ? _c("div", {
                                      key: newsItem.id + "c2_sep",
                                      staticClass: "c2_separatorLine",
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.picNum != 5 &&
                                _vm.picNum != 6 &&
                                _vm.picNum != 7 &&
                                !_vm.hasTwoColumnArrangeWay
                                  ? _c("div", {
                                      key: newsItem.id + "g_sep",
                                      staticClass: "g_separator separatorLine",
                                    })
                                  : _vm._e(),
                              ]
                            }),
                          ],
                    ],
                    2
                  ),
                ]
              )
            : [
                _vm.newsList.length === 0
                  ? _c("div", { staticClass: "fk-noProduct" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.productNoResultText) +
                          " "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.productRescreenText) +
                          "\n            "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasTwoColumnArrangeWay
                  ? [
                      _vm.picNum !== 0
                        ? [
                            _c(
                              "news-more-item",
                              _vm._b(
                                { attrs: { "news-item": _vm.newsList[0] } },
                                "news-more-item",
                                _vm.newsMoreItemProps,
                                false
                              )
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "newsArrayPanel",
                                class: ["column" + _vm.newsArrangeWay],
                              },
                              [
                                _vm._l(
                                  _vm.newsList.slice(1),
                                  function (newsItem, i) {
                                    return [
                                      _c(
                                        "news-more-item",
                                        _vm._b(
                                          {
                                            key: newsItem.id,
                                            attrs: {
                                              index: i + 1,
                                              "news-item": newsItem,
                                            },
                                          },
                                          "news-more-item",
                                          _vm.newsMoreItemProps,
                                          false
                                        )
                                      ),
                                      _vm._v(" "),
                                      _vm.hasTwoColumnArrangeWay &&
                                      (_vm.isNeedTwoColumn(i + 1) ||
                                        _vm.isNeedcSeparatorLine(i + 1))
                                        ? _c("div", {
                                            key: newsItem.id + "c2_sep",
                                            staticClass: "c2_separatorLine",
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.picNum != 5 &&
                                      _vm.picNum != 6 &&
                                      _vm.picNum != 7 &&
                                      !_vm.hasTwoColumnArrangeWay
                                        ? _c("div", {
                                            key: newsItem.id + "g_sep",
                                            staticClass:
                                              "g_separator separatorLine",
                                          })
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                          ]
                        : [
                            _c(
                              "div",
                              {
                                staticClass: "newsArrayPanel",
                                class: ["column" + _vm.newsArrangeWay],
                              },
                              [
                                _vm._l(_vm.newsList, function (newsItem, i) {
                                  return [
                                    _c(
                                      "news-more-item",
                                      _vm._b(
                                        {
                                          key: newsItem.id,
                                          attrs: {
                                            index: i,
                                            "news-item": newsItem,
                                          },
                                        },
                                        "news-more-item",
                                        _vm.newsMoreItemProps,
                                        false
                                      )
                                    ),
                                    _vm._v(" "),
                                    _vm.hasTwoColumnArrangeWay &&
                                    (_vm.isNeedTwoColumn(i) ||
                                      _vm.isNeedcSeparatorLine(i))
                                      ? _c("div", {
                                          key: newsItem.id + "c2_sep",
                                          staticClass: "c2_separatorLine",
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.picNum != 5 &&
                                    _vm.picNum != 6 &&
                                    _vm.picNum != 7 &&
                                    !_vm.hasTwoColumnArrangeWay
                                      ? _c("div", {
                                          key: newsItem.id + "g_sep",
                                          staticClass:
                                            "g_separator separatorLine",
                                        })
                                      : _vm._e(),
                                  ]
                                }),
                              ],
                              2
                            ),
                          ],
                    ]
                  : [
                      _vm._l(_vm.newsList, function (newsItem, i) {
                        return [
                          _c(
                            "news-more-item",
                            _vm._b(
                              {
                                key: newsItem.id,
                                attrs: { index: i, "news-item": newsItem },
                              },
                              "news-more-item",
                              _vm.newsMoreItemProps,
                              false
                            )
                          ),
                          _vm._v(" "),
                          _vm.hasTwoColumnArrangeWay &&
                          (_vm.isNeedTwoColumn(i) ||
                            _vm.isNeedcSeparatorLine(i))
                            ? _c("div", {
                                key: newsItem.id + "c2_sep",
                                staticClass: "c2_separatorLine",
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.picNum != 5 &&
                          _vm.picNum != 6 &&
                          _vm.picNum != 7 &&
                          !_vm.hasTwoColumnArrangeWay
                            ? _c("div", {
                                key: newsItem.id + "g_sep",
                                staticClass: "g_separator separatorLine",
                              })
                            : _vm._e(),
                        ]
                      }),
                    ],
              ],
        ],
        2
      ),
      _vm._v(" "),
      _vm.showPagination && _vm.newsTotal > 1
        ? _c("pagenation", {
            attrs: {
              "module-id": _vm.moduleId,
              "total-size": _vm.newsTotal,
              "page-size": _vm.count,
              pageno: _vm.pageno,
              "open-theme-v3": _vm.openThemeV3,
              "ajax-pagenation": true,
            },
            on: { "on-change": _vm.changePage },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }