import request from './request';

export function getCustomSearchResultInfo(options) {
    let { projectId, searchData, moduleId, mId } = options;
    return request.get(`/api/guest/other/getCustomSearchResultInfo`, {
        params: {
            projectId,
            searchData,
            moduleId,
            mId,
        },
    });
}
