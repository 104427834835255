import { checkNode } from '@/modules/simpleText/method';
import { isModuleV2 } from '@/manage/module-edit-panel/utils/module';
import { removeModuleStyleCssList, setModuleStyleCssList } from '@/shared/style';
import { getStyle } from '@/shared/util';
import { getPreviewObject } from '@/shared/getPreviewObject';

export const getSimpleTextContent = (module) => {
    var editor = module.find('.fk-editor'),
        editorText = '',
        result = {};

    // 这里将px转为rem再保存入数据库中-----------zhj
    checkNode.setIstoRem(true);
    checkNode.traverseTagStyle(editor);

    editorText = editor.text();
    editor.find('.editTip').remove();
    editor.find('*').removeAttr('class');

    if (editorText.length > 10000) {
        result.text = editorText.substring(0, 10000);
    } else {
        result.text = editor.html();
    }

    if (editor.hasClass('fk-editor-vertical-rl')) {
        result.wm = 1;
    } else if (editor.hasClass('fk-editor-vertical-lr')) {
        result.wm = 2;
    } else {
        result.wm = 0;
    } //自然宽度(只在垂直排版的时候有效)

    result.aw = editor.attr('_autowidth') == '1' ? true : false;
    return result;
};

export const getFloagBtnContent = function (module) {
    var flBtn = module.find('.floatBtn'),
        floatBtnText = flBtn.text(),
        moduleId = parseInt(module.attr('id').replace('module', '')),
        result = {};

    if (floatBtnText.length > 10000) {
        floatBtnText = floatBtnText.substring(0, 10000);
    } else {
        floatBtnText = flBtn.text().trim();
    }

    var moduleAttr = window.$store.state.manage.moduleAttr['module' + moduleId] || {};

    // moduleAttr = window.$store.getters.getModuleById(moduleId);

    let moduleInfo = window.$store.state.currentPageModuleIdMap[moduleId];

    moduleAttr.content = moduleInfo.content || {}; // baseset

    if (moduleAttr.content.bs) {
        moduleAttr.content.bs.v = floatBtnText;
        result.bs = moduleAttr.content.bs;
    } // size

    if (moduleAttr.content.s) {
        result.s = moduleAttr.content.s;
    } // radius

    if (moduleAttr.content.bg) {
        result.bg = moduleAttr.content.bg;
    } // border

    if (moduleAttr.content.b) {
        result.b = moduleAttr.content.b;
    } // shadow

    if (moduleAttr.content.sh) {
        result.sh = moduleAttr.content.sh;
    } // radius

    if (moduleAttr.content.r) {
        result.r = moduleAttr.content.r;
    }

    if (moduleAttr.content.color) {
        result.color = moduleAttr.content.color;
    }

    if (moduleAttr.content.fbs != null) {
        result.fbs = moduleAttr.content.fbs;
    }

    if (moduleAttr.content.fs) {
        result.fs = moduleAttr.content.fs;
    }

    return result;
};

export const getPackContent = (module) => {
    var tmpIdArray = [],
        canSet = true,
        moduleInPackData = [],
        content = {},
        position,
        result;
    module.find(".J_formPackContent [id^='module'].form").each(function (index, el) {
        let inPackModuleId = parseInt($(el).attr('id').replace('module', ''));
        position = $(el).position();
        result = {};
        result.id = inPackModuleId;
        result.t = position.top;
        result.l = position.left;
        canSet = true; // 重置 canSet 的值
        if ($.inArray(inPackModuleId, tmpIdArray) != -1) {
            canSet = false;
        } else {
            tmpIdArray.push(inPackModuleId);
        }

        if (!isNaN(result.id) && !isNaN(result.t) && !isNaN(result.l) && canSet) {
            moduleInPackData.push($.toJSON(result));
        }
    });

    content.moduleList = moduleInPackData;
    return content;
};

export function hideModuleBannerBg(id) {
    setModuleStyleCssList(id, [
        { cls: '.formBannerTitle' + id, key: 'background', value: 'none' },
        {
            cls: '.formBannerTitle' + id + ' .titleLeft' + id,
            key: 'background',
            value: 'none',
        },
        {
            cls: '.formBannerTitle' + id + ' .titleCenter' + id,
            key: 'background',
            value: 'none',
        },
        {
            cls: '.formBannerTitle' + id + ' .titleRight' + id,
            key: 'background',
            value: 'none',
        },
    ]);
}

export function showModuleBannerBg(id) {
    removeModuleStyleCssList(id, [
        { cls: '.formBannerTitle' + id, key: 'background' },
        { cls: '.formBannerTitle' + id + ' .textContent', key: 'background' },
        {
            cls: '.formBannerTitle' + id + ' .titleLeft' + id,
            key: 'background',
        },
        {
            cls: '.formBannerTitle' + id + ' .titleCenter' + id,
            key: 'background',
        },
        {
            cls: '.formBannerTitle' + id + ' .titleRight' + id,
            key: 'background',
        },
    ]);
}

export function hideModuleContentBg(id) {
    setModuleStyleCssList(id, [
        { cls: '', key: 'background', value: 'none' },
        { cls: '.formMiddle' + id, key: 'background', value: 'none' },
        { cls: '.g_background', key: 'background', value: 'none' },
        {
            cls: '.formMiddle' + id + ' .formMiddleCenter' + id,
            key: 'background',
            value: 'none',
        },
        { cls: '.formMiddleContent' + id, key: 'background', value: 'none' },
    ]);
}

export function showModuleContentBg(id) {
    removeModuleStyleCssList(id, [
        { cls: '', key: 'background' },
        { cls: '.formMiddle' + id, key: 'background' },
        { cls: '.g_background', key: 'background' },
        {
            cls: '.formMiddle' + id + ' .formMiddleCenter' + id,
            key: 'background',
        },
        { cls: '.formMiddleContent' + id, key: 'background' },
    ]);
}

export function setModuleHeight(id, height) {
    setModuleHeight2(id, height);
}

export function setModuleHeight2(id, height, htmlFontSizeFix) {
    //模块id，模块高度
    var module = document.getElementById('module' + id);
    let htmlFontSize = window._htmlFontSize;

    if (!module) {
        return;
    }
    /*height是相对于rem=20.5时的一个高度
    在pc看手机页面时，要将高度相对于当前的rem进行缩放，因为rem有可能是48px这种比较大的数字，所以转换就可能有问题
    by jser 2017-10-18
    */

    var manageIframeHtmlFontSize = htmlFontSizeFix || window._htmlFontSize; //管理态下手机壳内页面的fontSize

    var visitHtmlFontSize = window._htmlFontSize; //访客态fontSize

    var scale = visitHtmlFontSize / manageIframeHtmlFontSize;
    var moduleStyle = window.jm(module).attr('_modulestyle');
    var isStyle53 = moduleStyle == 53;
    let isPack = moduleStyle == 51;
    if (isPack) {
        let autoHeightType = jm(module).attr('_autoheight');
        if (autoHeightType == 0) {
            // _height 为用户自定义的高度，这里需同管理态一样的rem，不然大小在不同屏幕上会不一样。工单：623723
            height = parseInt(jm(module).attr('_height'));
        }
        htmlFontSize = window.$store.state.htmlFontSize || window._htmlFontSize;
        module.style.height = height / htmlFontSize + 'rem';
    } else {
        height *= scale;
        height = parseInt(height);
        module.style.height = height / htmlFontSize + 'rem';
    }

    /** @type {HTMLElement} */
    var moduleBanner = module.querySelector('.formBannerTitle' + id);
    /** @type {HTMLElement} */
    var moduleMiddle = module.querySelector('.formMiddle');
    var moduleBannerHeight = 0;
    var isStyle53AndVer2 = isStyle53 && isModuleV2(moduleStyle); //常用模块没有bannerTitle

    if (moduleBanner) {
        if (getComputedStyle(moduleBanner).display != 'none') {
            if (window.jm(module).hasClass('formStyle2')) {
                moduleBannerHeight = 2 * htmlFontSize;
            } else {
                moduleBannerHeight = moduleBanner.offsetHeight;
            }
        }
    }

    if (!isStyle53AndVer2) {
        var midHeight = height - moduleBannerHeight - getFrameHeight(moduleMiddle);
        moduleMiddle.style.height = midHeight / htmlFontSize + 'rem';
        var moduleMiddleCenter = moduleMiddle.querySelector('.middleCenter');
        midHeight = midHeight - getFrameHeight(moduleMiddleCenter);
        /** @type {HTMLElement} */
        var moduleMiddleContent = moduleMiddle.querySelector('.formMiddleContent');
        midHeight = midHeight - getFrameHeight(moduleMiddleContent);
        moduleMiddleContent.style.height = midHeight / htmlFontSize + 'rem';
        moduleMiddleContent.style.overflow = 'hidden';
    }

    if (window.jm(module).hasClass('formStyle51')) {
        /** @ts-ignore */
        moduleMiddle.querySelector('.J_packContentList').style.height = height / htmlFontSize + 'rem';
    }
}

export function setModuleBannerHeight(id, height) {
    setModuleBannerHeight2(id, height);
}

function setModuleBannerHeight2(id, height) {
    if (id === 0) return;

    var module = getPreviewObject('module' + id);
    /** @type {HTMLElement} */
    var formBannerTitle = module.querySelector('.formBannerTitle');

    formBannerTitle && (formBannerTitle.style.height = height / window._htmlFontSize + 'rem');
}

function getFrameHeight(obj) {
    var padding =
        parseInt(getStyle(obj, 'padding-top').replace('px', '')) +
        parseInt(getStyle(obj, 'padding-bottom').replace('px', ''));
    var margin =
        parseInt(getStyle(obj, 'margin-top').replace('px', '')) +
        parseInt(getStyle(obj, 'margin-bottom').replace('px', ''));
    var border =
        parseInt(getStyle(obj, 'border-top-width').replace('px', '')) +
        parseInt(getStyle(obj, 'border-bottom-width').replace('px', ''));
    return border + margin + padding;
}

export function autoModuleHeight(id) {
    var module = $(getPreviewObject('module' + id));
    module.css('height', 'auto');
    module.find('.formMiddle').first().css('height', 'auto');
    var moduleMiddleContent = module.find('.formMiddleContent').first();
    moduleMiddleContent.css('height', 'auto');
    if (module.hasClass('formStyle54')) {
        //按钮模块不隐藏
        if (!isModuleV2(54)) {
            // @20200113 ken  不知道是什么作用，2.0模块先去掉，否则影响阴影
            moduleMiddleContent.css('overflow-y', 'auto');
        }
    }

    if (module.hasClass('formStyle52')) {
        Vue.prototype.$editor.setEditorAutoHeight(id);
    }
}

//for自由容器圈选功能的mask，previewWindow内部的mask
const addModuleMask = function (options) {
    var location = {};
    location.t = { x: 0, y: 0 }; //顶部遮罩位置
    location.b = { x: 0, y: 0 }; //底部遮罩的位置

    //默认值
    var settings = {
        id: 0,
        refresh: false /*是否为刷新*/,
        useClass: false /*是否使用自定义class*/,
        leftClass: '',
        rightClass: '',
        topClass: '',
        bottomClass: '',
        centerClass: '',
        enableTopBar: false /*是否让topbar可点击*/,
        callback: false /*回调函数*/,
        extFn: [] /*扩展方法*/,
        backgroundColor: '',
    };
    $.extend(settings, options); //整合数据

    var module = $('#module' + settings.id);

    var gBodyOffset = $('#g_body').offset();

    if (module.length < 1) {
        return;
    }

    var $mask = $('.moduleMaskContainer');
    if ($mask.length < 1 && options.refresh) {
        //刷新的话，如果没有该结构了，则不需要刷新
        return;
    }

    var body_width = $('#g_body').outerWidth(); //document.documentElement ? document.documentElement.clientWidth : document.body.clientWidth;   var body_height = $('body').outerHeight(); //先暂时改为这样
    var body_height = $(document).height();

    var m_height = module.outerHeight();
    var bgColor = settings.backgroundColor;
    var standardTop = 0;

    //初始化数据
    location.t.x = 0;
    location.t.y = module.offset().top - gBodyOffset.top;
    location.b.x = 0;
    location.b.y = module.offset().top - gBodyOffset.top + m_height;

    var moduleMaskTopStyle =
        'position:absolute;top:' +
        standardTop +
        'px;left:' +
        location.t.x +
        'px;width:' +
        body_width +
        'px;height:' +
        location.t.y +
        'px;' +
        'background-color:' +
        bgColor +
        ';z-index:' +
        9030;
    var moduleMaskBottomStyle =
        'position:absolute;top:' +
        location.b.y +
        'px;left:' +
        location.b.x +
        'px;width:' +
        body_width +
        'px;height:' +
        (body_height - location.b.y) +
        'px;' +
        'background-color:' +
        bgColor +
        ';z-index:' +
        9030;

    if ($mask.length > 0) {
        var moduleMaskContainer = $mask;
        var maskTop = moduleMaskContainer.children('.moduleMask-top');
        var maskBottom = moduleMaskContainer.children('.moduleMask-bottom');
        maskTop.attr('style', moduleMaskTopStyle);
        maskBottom.attr('style', moduleMaskBottomStyle);

        if (moduleMaskContainer.attr('useClass')) {
            maskTop.removeClass().addClass('moduleMask moduleMask-top');
            maskBottom.removeClass().addClass('moduleMask moduleMask-bottom');
        }
        if (settings.useClass) {
            maskTop.addClass(' ' + settings.topClass);
            maskBottom.addClass(' ' + settings.bottomClass);
        }

        moduleMaskContainer.attr('useClass', settings.useClass ? 1 : 0);
        moduleMaskContainer.appendTo($('#g_body'));
    } else {
        var html = [];
        html.push("<div class='moduleMaskContainer' useClass='" + (settings.useClass ? 1 : 0) + "'>");
        html.push(
            " <div class='moduleMask moduleMask-top " +
                (settings.useClass ? settings.topClass : '') +
                "' style='" +
                moduleMaskTopStyle +
                "'></div>"
        );
        html.push(
            " <div class='moduleMask moduleMask-bottom " +
                (settings.useClass ? settings.bottomClass : '') +
                "' style='" +
                moduleMaskBottomStyle +
                "'></div>"
        );
        html.push('</div>');
        $('#g_body').append(html.join(''));
    }

    $mask = $('.moduleMaskContainer');

    //自定义扩展，可绑定自定义方法
    if (settings.extFn.length > 0) {
        for (var i = 0, len = settings.extFn.length; i < len; i++) {
            var extFn_obj = settings.extFn[i].obj || $mask; //绑定事件的对象
            var extFn_name = settings.extFn[i].name; //绑定的事件（例如：click.mask）
            var extFn_fn = settings.extFn[i].fn; //绑定的方法
            extFn_obj.off(extFn_name);
            extFn_obj.on(extFn_name, extFn_fn);
        }
        $mask.data('extFn', settings.extFn); //记录，以便最后清除
    }
};

const addModuleMaskForText = function (options) {
    // 新的编辑器不需要遮罩层控制点击退出，废弃此逻辑
    var location = {};
    location.lt = { x: 0, y: 0 }; //左上角
    location.lb = { x: 0, y: 0 }; //左下角
    location.rt = { x: 0, y: 0 }; //右上角
    location.rb = { x: 0, y: 0 }; //右下角

    //默认值
    var settings = {
        id: 0,
        refresh: false /*是否为刷新*/,
        useClass: false /*是否使用自定义class*/,
        leftClass: '',
        rightClass: '',
        topClass: '',
        bottomClass: '',
        centerClass: '',
        enableTopBar: false /*是否让topbar可点击*/,
        callback: false /*回调函数*/,
        extFn: [] /*扩展方法*/,
    };
    $.extend(settings, options); //整合数据

    // Fai.top.$(window).off("resize.addModuleMask");//先将已有的事件移除，避免重复绑定

    var module = $('#module' + settings.id);

    if (module.length < 1) {
        return;
    }
    var $mask = $('.moduleMaskContainer');
    if ($mask.length < 1 && options.refresh) {
        //刷新的话，如果没有该结构了，则不需要刷新
        return;
    }

    // 这里body变为了 preview
    let $preview = $('#preview');
    var body_width = $preview.outerWidth();
    var body_height = $preview.outerHeight();
    var reviewPageFrameOffset = $('#preview').offset();
    var topBodyWidth = $('body').outerWidth();
    var topBodyHeight = $('body').outerHeight();
    var scrollTop = $preview.scrollTop();

    var $webHeaderBox = $('#webHeaderBox');
    var webHeaderBoxHeight =
        $webHeaderBox.height() && $webHeaderBox.css('position') == 'fixed' ? $webHeaderBox.height() : 0;

    var m_width = module.outerWidth();
    var m_height = module.outerHeight();
    var m_offset = module.offset();
    var m_offsetTop = m_offset.top - scrollTop;
    var m_offsetLeft = m_offset.left; //  + reviewPageFrameOffset.left;
    var tmpBottomLine = m_offsetTop + m_height; //模块底部边际
    var tmpRightLine = m_offsetLeft + m_width; //模块右部边际

    var standardTop = 0;

    // 初始化数据，改造后的
    location.lt.x = m_offsetLeft; // 直接用模块本身
    location.lt.y = m_offsetTop;
    location.lb.x = location.lt.x;
    location.lb.y = tmpBottomLine > body_height ? body_height : tmpBottomLine; //超出页面底部可视区，以可视区边际为准
    location.rt.x = m_width > body_width ? body_width : tmpRightLine; //超出页面右部可是区，以可视区边际为准
    location.rt.y = location.lt.y;
    location.rb.x = location.rt.x;
    location.rb.y = location.lb.y;

    var moduleMaskLeftStyle =
        'position:absolute;top:' +
        standardTop +
        'px;left:0;width:' +
        location.lt.x +
        'px;height:' +
        topBodyHeight +
        'px;';
    var moduleMaskRightStyle =
        'position:absolute;top:' +
        standardTop +
        'px;left:' +
        location.rt.x +
        'px;width:' +
        (topBodyWidth - location.rt.x - 450) +
        'px;height:' +
        topBodyHeight +
        'px;';
    var moduleMaskTopStyle =
        'position:absolute;top:' +
        standardTop +
        'px;left:' +
        location.lt.x +
        'px;width:' +
        m_width +
        'px;height:' +
        (location.lt.y - standardTop) +
        'px;';
    var moduleMaskBottomStyle =
        'position:absolute;top:' +
        location.lb.y +
        'px;left:' +
        location.lb.x +
        'px;width:' +
        m_width +
        'px;height:' +
        (topBodyHeight - location.lb.y) +
        'px;';
    var moduleMaskCenterStyle =
        'position:absolute;top:' +
        location.lt.y +
        'px;left:' +
        location.lt.x +
        'px;width:' +
        m_width +
        'px;height:' +
        m_height +
        'px;';

    //如果模块丢失视野，则退出dialog
    if (location.lb.y < reviewPageFrameOffset.top + webHeaderBoxHeight) {
        return true;
    }

    if ($mask.length > 0) {
        var moduleMaskContainer = $mask;
        var maskLeft = moduleMaskContainer.children('.moduleMask-left');
        var maskRight = moduleMaskContainer.children('.moduleMask-right');
        var maskTop = moduleMaskContainer.children('.moduleMask-top');
        var maskBottom = moduleMaskContainer.children('.moduleMask-bottom');
        var maskCenter = moduleMaskContainer.children('.moduleMask-center');
        maskLeft.attr('style', moduleMaskLeftStyle);
        maskRight.attr('style', moduleMaskRightStyle);
        maskTop.attr('style', moduleMaskTopStyle);
        maskBottom.attr('style', moduleMaskBottomStyle);
        maskCenter.attr('style', moduleMaskCenterStyle);

        if (moduleMaskContainer.attr('useClass')) {
            maskLeft.removeClass().addClass('moduleMask moduleMask-left');
            maskRight.removeClass().addClass('moduleMask moduleMask-right');
            maskTop.removeClass().addClass('moduleMask moduleMask-top');
            maskBottom.removeClass().addClass('moduleMask moduleMask-bottom');
            maskCenter.removeClass().addClass('moduleMask moduleMask-center');
        }
        if (settings.useClass) {
            maskLeft.addClass(' ' + settings.leftClass);
            maskRight.addClass(' ' + settings.rightClass);
            maskTop.addClass(' ' + settings.topClass);
            maskBottom.addClass(' ' + settings.bottomClass);
            maskCenter.addClass(' ' + settings.centerClass);
        }

        moduleMaskContainer.attr('useClass', settings.useClass ? 1 : 0);
        moduleMaskContainer.appendTo($('body'));
    } else {
        var html = [];
        html.push("<div class='moduleMaskContainer' useClass='" + (settings.useClass ? 1 : 0) + "'>");
        html.push(
            " <div class='moduleMask moduleMask-left " +
                (settings.useClass ? settings.leftClass : '') +
                "' style='" +
                moduleMaskLeftStyle +
                "'></div>"
        );
        html.push(
            " <div class='moduleMask moduleMask-right " +
                (settings.useClass ? settings.rightClass : '') +
                "' style='" +
                moduleMaskRightStyle +
                "'></div>"
        );
        html.push(
            " <div class='moduleMask moduleMask-top " +
                (settings.useClass ? settings.topClass : '') +
                "' style='" +
                moduleMaskTopStyle +
                "'></div>"
        );
        html.push(
            " <div class='moduleMask moduleMask-bottom " +
                (settings.useClass ? settings.bottomClass : '') +
                "' style='" +
                moduleMaskBottomStyle +
                "'></div>"
        );
        html.push(
            " <div class='moduleMask moduleMask-center " +
                (settings.useClass ? settings.centerClass : '') +
                "' style='" +
                moduleMaskCenterStyle +
                "'></div>"
        );
        html.push('</div>');
        $('body').append(html.join(''));
    }

    $mask = $('.moduleMaskContainer');

    if (!settings.refresh) {
        //不刷新，则默认是生成新的遮罩，需要将绑定的旧数据清除
        var extFn = $mask.data('extFn');
        if (extFn) {
            //清除绑定的副产品
            for (var i = 0, len = extFn.length; i < len; i++) {
                var extFn_obj = extFn[i].obj || $mask; //绑定事件的对象
                var extFn_name = extFn[i].name; //绑定的事件（例如：click.mask）
                extFn_obj.off(extFn_name);
            }
        }
    }

    //自定义扩展，可绑定自定义方法
    if (settings.extFn.length > 0) {
        for (let i = 0, len = settings.extFn.length; i < len; i++) {
            let extFn_obj = settings.extFn[i].obj || $mask; //绑定事件的对象
            let extFn_name = settings.extFn[i].name; //绑定的事件（例如：click.mask）
            var extFn_fn = settings.extFn[i].fn; //绑定的方法
            extFn_obj.off(extFn_name);
            extFn_obj.on(extFn_name, extFn_fn);
        }
        $mask.data('extFn', settings.extFn); //记录，以便最后清除
    }
    //回调方法
    if (settings.callback) {
        settings.callback();
    }
};

const removeModuleMask = function () {
    var mask = $('.moduleMaskContainer');
    var extFn = mask.data('extFn');
    if (extFn) {
        //清除绑定的副产品
        for (var i = 0, len = extFn.length; i < len; i++) {
            var extFn_obj = extFn[i].obj || mask; //绑定事件的对象
            var extFn_name = extFn[i].name; //绑定的事件（例如：click.mask）
            extFn_obj.off(extFn_name);
        }
    }
    mask.remove();
};

const ModuleUtils = {
    getPackContent,
    showModuleBannerBg,
    hideModuleBannerBg,
    showModuleContentBg,
    hideModuleContentBg,
    setModuleHeight,
    setModuleHeight2,
    setModuleBannerHeight,
    setModuleBannerHeight2,
    getFrameHeight,
    autoModuleHeight,
    addModuleMask,
    removeModuleMask,
    isModuleV2,
    addModuleMaskForText,
};

export function registerModuleUtils(Vue) {
    Vue.prototype.$moduleUtils = ModuleUtils;
}

export const refreshModuleHeight = (id) => {
    var module = $(document.getElementById('module' + id));

    if (module.attr('_autoHeight') == 0) {
        setModuleHeight(id, module.height());
    }
};
