var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "newsLine line icon-gline J_picNum",
      class: _vm.newsLineClassNames,
      attrs: {
        id: _vm.newsLineId,
        topClassname: _vm.topClassName,
        topSwitch: _vm.topSwitch,
        newsId: _vm.id,
        newsName: _vm.title,
        picnum: "2",
      },
    },
    [
      _c(
        "a",
        {
          staticClass: "lineBodyLink",
          staticStyle: { width: "auto" },
          attrs: {
            hidefocus: "true",
            href: _vm.href,
            target: _vm.target,
            onclick: _vm.onclickStr,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "lineBody lineBodyPic",
              attrs: { id: _vm.lineBodyId },
            },
            [
              _vm.topFlagClass
                ? _c("div", { staticClass: "topFlagTableCell" }, [
                    _c("div", { class: _vm.topFlagClass }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.topicPicPosition === 1
                ? [
                    !_vm.pictureId
                      ? _c(
                          "div",
                          {
                            staticClass: "picTableCell",
                            style: _vm.picTableCellStyle,
                          },
                          [
                            _c("div", {
                              staticClass: "newsLeftImg icon-noFigureS",
                              style: _vm.picCellStyle,
                              attrs: { id: _vm.newsImgId, alt: _vm.alt },
                            }),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "picTableCell",
                            style: _vm.picTableCellStyle,
                          },
                          [
                            _c("div", {
                              staticClass: "newsLeftImg",
                              style: _vm.picCellStyle,
                              attrs: { id: _vm.newsImgId, alt: _vm.alt },
                            }),
                          ]
                        ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "fontTableCell" }, [
                _c("div", { staticClass: "fontHiddenTable" }, [
                  _c("div", { staticClass: "twoLineHidden" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.title) +
                        "\n                    "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.topicPicPosition === 2
                ? [
                    !_vm.pictureId
                      ? _c(
                          "div",
                          {
                            staticClass: "picTableCell",
                            style: _vm.picTableCellStyle,
                          },
                          [
                            _c("div", {
                              staticClass: "newsLeftImg icon-noFigureS",
                              style: _vm.picCellStyle,
                              attrs: { id: _vm.newsImgId, alt: _vm.alt },
                            }),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "picTableCell",
                            style: _vm.picTableCellStyle,
                          },
                          [
                            _c("div", {
                              staticClass: "newsLeftImg",
                              style: _vm.picCellStyle,
                              attrs: { id: _vm.newsImgId, alt: _vm.alt },
                            }),
                          ]
                        ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _vm.showNewsElementsRequire
        ? _c(
            "div",
            {
              staticClass:
                "newsElementsPanel g_newsElementsPanel J_newsElementsPanel",
              on: { click: _vm.gotoHref },
            },
            [
              _vm.showCommentRequire
                ? _c(
                    "div",
                    { staticClass: "ne_newsComment faisco-icons-comment" },
                    [
                      _c("span", { staticClass: "newsCommentCount" }, [
                        _vm._v(_vm._s(_vm.totalNewsCommentCount)),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showAuthorRequire
                ? _c(
                    "div",
                    {
                      staticClass: "ne_newsAuthor faisco-icons-author",
                      style: _vm.newsAuthorStyle,
                    },
                    [
                      _c("span", { staticClass: "newsAuthorName" }, [
                        _vm._v(_vm._s(_vm.newsAuthor)),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              (_vm.showCommentRequire || _vm.showAuthorRequire) &&
              (_vm.showSortRequire || _vm.showDateRequire)
                ? _c("div", {
                    staticClass: "ne_separatorLine g_ne_separatorLine",
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.showSortRequire
                ? _c("div", { staticClass: "ne_newsSort" }, [
                    _vm._v(_vm._s(_vm.groupName)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.showDateRequire
                ? _c("div", { staticClass: "ne_newsTime" }, [
                    _vm._v(_vm._s(_vm.formattedNewsDate)),
                  ])
                : _vm._e(),
            ]
          )
        : [
            _vm.showCommentRequire
              ? _c(
                  "div",
                  { staticClass: "ne_newsComment faisco-icons-comment" },
                  [
                    _c("span", { staticClass: "newsCommentCount" }, [
                      _vm._v(_vm._s(_vm.totalNewsCommentCount)),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showAuthorRequire
              ? _c(
                  "div",
                  {
                    staticClass: "ne_newsAuthor faisco-icons-author",
                    style: _vm.newsAuthorStyle,
                  },
                  [
                    _c("span", { staticClass: "newsAuthorName" }, [
                      _vm._v(_vm._s(_vm.newsAuthor)),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            (_vm.showCommentRequire || _vm.showAuthorRequire) &&
            (_vm.showSortRequire || _vm.showDateRequire)
              ? _c("div", [
                  _c("div", {
                    staticClass: "ne_separatorLine g_ne_separatorLine",
                  }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.showSortRequire
              ? _c("div", [
                  _c("div", { staticClass: "ne_newsSort" }, [
                    _vm._v(_vm._s(_vm.groupName)),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.showDateRequire
              ? _c("div", { staticClass: "ne_newsTime" }, [
                  _vm._v(_vm._s(_vm.formattedNewsDate)),
                ])
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }