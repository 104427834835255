var _preState = { level: 0 };
var _cusBackClick = false;

let cusHistory = {
    init() {
        let _this = this;
        jm(window)
            .off('popstate.h')
            .on('popstate.h', function (event) {
                // 监听系统跳转按钮（前进后退跳转成功后都会执行

                // 判断前进还是后退
                // 缓存跳转前的state，用prestate
                // 如果是从会员中心聚合页点击前进，则state.level > prestate.level
                // 如果时从会员中心聚合页点击后退，系统直接跳url，此时colId会变化，不应该再进入这个方法，系统直接跳url。

                // 如果是收货地址点击前进，则state.level > prestate.level，调用前进的方法
                // 如果是收货地址点击后退，则state.level < prestate.level，调用后退的方法

                // 如果从会员中心聚合页跳转到推广员页面，推广员页面再init一下。

                // 如果用自定义后退按钮，则不需要进入这个逻辑

                // 若跳转后，还处于会员中心页、推广员页面、会员注册页面的内页，才走下面自动跳转逻辑。否则由系统自动跳转
                if (Fai.top._pageId != 12 && Fai.top._pageId != 34 && Fai.top._pageId != 11) {
                    return;
                }

                // 如果是通过自定义按钮返回的，则返回
                if (_cusBackClick) {
                    _cusBackClick = false;
                    _preState = history.state || {};
                    return;
                }

                if (event.state) {
                    if (event.state.level > _preState.level) {
                        _this.goForward(event);
                    } else {
                        _this.goBack(event);
                    }
                } else {
                    _this.goBack(event);
                }

                _preState = event.state || { level: 0 };
            });
    },
    add(curPageId, historyCash, level, selector) {
        // 内页，无url跳转才需要add
        if (window.history && window.history.pushState && historyCash !== 'false') {
            var curPageInsideDom = findCurInsidePageDom();
            var state = { pageId: curPageId, level, selector };
            var cusBack;

            // 手动记录入历史
            window.history.pushState(state, null, location.href);

            // 相对于前进后退而言，当前页是_preState
            _preState = state;

            // 点击自定义的后退按钮时，要纠正系统history的记录位置
            if (curPageInsideDom) {
                cusBack = jm(curPageInsideDom).find('.g_close');
                cusBack.off('click.h').on('click.h', function (event, sysBack) {
                    if (sysBack !== 'true') {
                        history.back();
                        _cusBackClick = true;
                    }
                });
            }
        }
    },
    goForward(event) {
        jm(event.state.selector).each(function (index, el) {
            jm(el).trigger('click', 'false');
        });
    },
    goBack() {
        var curPageInsideDom = findCurInsidePageDom();
        if (curPageInsideDom) {
            jm(curPageInsideDom).find('.g_close').trigger('click', 'true');
        }
    },
};

function findCurInsidePageDom() {
    var dom;
    jm('.J_cusBack').each(function () {
        if (jm(this).css('display') === 'block') {
            dom = this;
            return false;
        }
    });
    return dom;
}

export default cusHistory;
