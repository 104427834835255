export const initBgmPosition = function () {
    var bgm_icon = jm('#bgMusic .bgm_icon');
    if (bgm_icon.length < 1) {
        return;
    }

    var bgm_iconBottom = 0;
    bgm_icon.css({ top: '', bottom: '' });

    if (bgm_icon.hasClass('bgm_rightUp') || bgm_icon.hasClass('bgm_leftUp')) {
        scrollBgmPosition();
    } else if (bgm_icon.hasClass('bgm_rightDown') || bgm_icon.hasClass('bgm_leftDown')) {
        if (jm('#customerServiceDiv').length < 1 || jm('#customerServiceDiv').find('.service').length < 1) {
            bgm_iconBottom = 20;
        } else {
            bgm_iconBottom = parseInt(jm('#customerServiceDiv').height()) + 20;
        }
        bgm_icon.css('bottom', bgm_iconBottom + 'px');
    }
};

export const scrollBgmPosition = function () {
    if (Fai.top._openThemeV3) {
        scrollBgmPositionV3();
        return;
    }

    var bgm_icon = jm('#bgMusic .bgm_icon'),
        targetArea = jm('#webMultilingualArea'),
        webHeaderBox = jm('#webHeaderBox'),
        loginHeader = jm('.loginHeader.webHeaderBg'),
        headerHeight = parseInt(webHeaderBox.height()) || 0,
        $body = jm('body'),
        templateFrameId = Fai.top._templateFrameId;

    var pos = 0,
        bgm_iconTop = 10,
        bodyScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    if (bgm_icon.hasClass('bgm_rightUp') || bgm_icon.hasClass('bgm_leftUp')) {
        if (targetArea.length > 0) {
            //参照物滚动的位置，存在登录条则参照物为登录条，否则参照物为webHeaderBox
            pos = targetArea.offset().top + parseInt(targetArea.height()) - bodyScrollTop;
        } else {
            pos = webHeaderBox.offset().top + headerHeight - bodyScrollTop;
        }
        //但是有种情况，如果webHeader是display：none的话就考虑是不是登录条的问题
        if (!pos) {
            //处理详情页的时候，背景音乐按钮遮住顶部按钮
            if (
                $body.hasClass('mobiCol20') ||
                $body.hasClass('mobiCol21') ||
                $body.hasClass('mobiCol24') ||
                $body.hasClass('mobiCol28')
            ) {
                if (templateFrameId >= 2000) {
                    pos = headerHeight + 10;
                } else if (jm('.productDetailHeader').height() > 0) {
                    pos = parseInt(jm('.productDetailHeader').height()) + 10;
                } else if (jm('.photoGroupDetailHeader').height() > 0) {
                    pos = parseInt(jm('.photoGroupDetailHeader').height()) + 10;
                }
            } else {
                if (loginHeader.length > 0) {
                    pos = loginHeader.offset().top + parseInt(loginHeader.height()) - bodyScrollTop + 10;
                }
                //如果不是login条的问题，直接以窗口为参照物，丢~
                pos = pos > 0 ? pos : 10;

                if (templateFrameId === 2004) {
                    pos = pos + headerHeight;
                }
            }

            bgm_icon.css('top', pos + 'px');
            return;
        }

        if (webHeaderBox.css('position') == 'fixed') {
            //如果webHeaderbox是固定页面定位
            if (pos >= headerHeight) {
                //如果登录条存在且没有进入webHeaderbox的范围。
                bgm_iconTop = pos + 10;
            } else {
                bgm_iconTop = headerHeight + 10;
            }
        } else {
            if (pos >= 0) {
                //如果登录条存在且仍在视角范围内
                bgm_iconTop = pos + 10;
            }
        }

        bgm_icon.css('top', bgm_iconTop + 'px');
    }
};

export const scrollBgmPositionV3 = function () {
    var bgm_icon = jm('#bgMusic .bgm_icon'),
        webHeaderBox = jm('#webHeader_v3'),
        headStyleId = webHeaderBox.attr('styleId'),
        loginHeader = jm('.loginHeader.webHeaderBg'),
        headerHeight = parseInt((webHeaderBox[0] || {}).offsetHeight) || 0,
        $body = jm('body');
    var pos = 0,
        bgm_iconTop = 10,
        bodyScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    if (bgm_icon.hasClass('bgm_rightUp') || bgm_icon.hasClass('bgm_leftUp')) {
        //但是有种情况，如果webHeader是display：none的话就考虑是不是登录条的问题
        if (headerHeight == 0) {
            //处理详情页的时候，背景音乐按钮遮住顶部按钮
            if (
                $body.hasClass('mobiCol20') ||
                $body.hasClass('mobiCol21') ||
                $body.hasClass('mobiCol24') ||
                $body.hasClass('mobiCol28')
            ) {
                if (jm('.productDetailHeaderV3').height() > 0) {
                    pos = parseInt(jm('.productDetailHeaderV3').height()) + 10;
                } else if (jm('.photoGroupDetailHeader').height() > 0) {
                    pos = parseInt(jm('.photoGroupDetailHeader').height()) + 10;
                }
            } else {
                if (loginHeader.length > 0) {
                    pos = loginHeader.offset().top + parseInt(loginHeader.height()) - bodyScrollTop + 10;
                }
                //如果不是login条的问题，直接以窗口为参照物，丢~
                pos = pos > 0 ? pos : 10;
            }

            bgm_icon.css('top', pos + 'px');
            return;
        } else {
            if (headStyleId < 5) {
                pos = headerHeight;
            } else {
                pos = 0;
            }
            if (pos < 0) {
                pos = 0;
            }
        }
        bgm_icon.css('top', pos + bgm_iconTop + 'px');
    }
};
