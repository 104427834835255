var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "J_groupItem",
      class: [_vm.thirdGroupItemClass],
      attrs: { "data-id": _vm.groupItem.id },
    },
    [
      _vm.isImageStyle
        ? [
            _c(
              "a",
              {
                staticClass: "J_imgContainer f-imgContainer",
                attrs: {
                  id: "imgContainer" + _vm.groupItem.id,
                  href: _vm.groupItem.url,
                  target: "_self",
                },
              },
              [
                _vm.groupItem.mIcon
                  ? [
                      _vm.isFontIconId(_vm.groupItem.mIcon)
                        ? [
                            _vm.groupItem.mIconColor
                              ? _c("span", {
                                  staticClass: "J_itemImage f-itemImg",
                                  style: { color: _vm.groupItem.mIconColor },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", {
                              staticClass: "J_itemImage f-itemImg",
                            }),
                          ]
                        : _vm.groupItem.fileInfo
                        ? _c("img", {
                            staticClass: "J_itemImage f-itemImg",
                            attrs: {
                              src: _vm.groupItem.iconPath,
                              "data-w": _vm.groupItem.fileInfo.width,
                              "data-h": _vm.groupItem.fileInfo.height,
                            },
                          })
                        : _c("span", {
                            staticClass:
                              "J_itemImage notImage faisco-icons-pic",
                          }),
                    ]
                  : _c("img", {
                      staticClass: "J_itemImage notImage f-itemImg",
                      attrs: {
                        src: _vm.defaultImg,
                        "data-w": "326",
                        "data-h": "326",
                      },
                    }),
              ],
              2
            ),
            _vm._v(" "),
            _c("a", { attrs: { href: _vm.groupUrl, target: "_self" } }, [
              _c("p", { staticClass: "f-itemImgName" }, [
                _vm._v(_vm._s(_vm.groupItem.name)),
              ]),
            ]),
          ]
        : _c(
            "a",
            {
              staticClass: "itemImageParent",
              attrs: { href: _vm.groupUrl, target: "_self" },
            },
            [
              _c("p", { staticClass: "f-itemImgName" }, [
                _vm._v(_vm._s(_vm.groupItem.name)),
              ]),
            ]
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }