/* eslint-disable no-empty */
//记录提交了的购买且还未返回结果的产品ID  防止网络慢的情况下的多次点击会出现多个dialog
//buyType==1 立即购物
//entry购买入口，1表示在产品展示模块进入，0表示在产品详情进入
//moduleId区分开避免叠加

import { logDog } from './log';
import { ing } from './tips';
import { addRequestPrefix } from '@/shared/util';
import { preprocessUrl } from '@/shared/url';
import { request } from '@/api/request';
import { encodeHtml } from '@/shared/util';
//新增options参数，里面放其他参数
let mallBuying = {};
export function mallBuy(productId, buyType, entry, moduleId, LSOption, LSCount, options) {
    options = options || {};
    var isGroupBuy = options.isGroupBuy || false;
    var groupBuyId = options.groupBuyId || 0;
    var groupBuyDetailId = options.groupBuyDetailId || 0;
    var isSecKill = options.isSecKill || 0;
    var secKillId = isSecKill ? options.secKillId || 0 : 0;
    var isIntegralMall = options.isIntegralMall || false;
    var integralMallId = options.integralMallId || 0;
    var isEarnestSale = options.isEarnestSale || false;
    var earnestSaleId = options.earnestSaleId || 0;
    var isCpOrder = options.isCpOrder || false;
    var combinedPurchaseId = options.combinedPurchaseId || 0;
    var communityGroupId = options.communityGroupId || 0;
    var pdInfoList = options.pdInfoList || [];
    var hideAddCartTips = options.hideAddCartTips || false; //隐藏添加购物车成功提示
    var fromCommunityGroup = options.fromCommunityGroup || false; //来自社区团购相关

    var cb = options.cb || 0; //成功之后的回调

    if (isCpOrder) {
        if (typeof mallBuying['cp' + combinedPurchaseId] == 'undefined') {
            mallBuying['cp' + combinedPurchaseId] = false;
        }
    } else {
        if (typeof mallBuying[productId] == 'undefined') {
            mallBuying[productId] = false;
        }
    }

    var hasAjax = false;
    jm.each(mallBuying, function (productId, ajaxing) {
        //有任何一个产品的ajax未返回的话则直接return
        if (ajaxing) {
            hasAjax = true;
            return false;
        }
    });

    if (hasAjax) {
        return;
    }
    if (isCpOrder) {
        mallBuying['cp' + combinedPurchaseId] = true;
    } else {
        mallBuying[productId] = true;
    }
    var mktSuffix = '';
    if (
        isGroupBuy &&
        jm('.J_productNumberContent_pt').length > 0 &&
        jm('.J_productNumberContent_pt').css('display') != 'none'
    ) {
        mktSuffix = '_pt';
    }
    if (
        isIntegralMall &&
        jm('.J_productNumberContent_im').length > 0 &&
        jm('.J_productNumberContent_im').css('display') != 'none'
    ) {
        mktSuffix = '_im';
    }

    jm('#productNum' + mktSuffix).change(); //做层保护，提交前触发一下，防止有些利用history.back漏洞绕过限购

    var ajax_data = 'id=' + productId + '&fromDetail=true',
        optionDataList = [],
        mallOptions = jm('.J-op'),
        count = 0,
        success = true;
    if (isCpOrder) {
        ajax_data = 'combinedPurchaseId=' + combinedPurchaseId + '&isCpOrder=true';
    } else {
        if (entry == 1) {
            mallOptions = jm('#productMallOptionPanel' + productId + "[moduleId='" + moduleId + "'] .J-op");
        }
        var isBreak = false; //是否跳出each循环
        if (!!LSOption && !jm.isEmptyObject(LSOption)) {
            ajax_data += '&optionList=' + jm.encodeUrl(jm.toJSON(LSOption));
        } else {
            jm(mallOptions).each(function (index) {
                if (isBreak) return;
                var option = jm(this),
                    tmpName = option.attr('option_name'),
                    tmpFieldKey = option.attr('data'),
                    tmpType = option.attr('type'),
                    choiceId = option.attr('option_data'),
                    isTime = option.attr('isTime');

                if (isTime === 'true') {
                    var selected = Mobi.priceCalendar.getSelected();
                    if (selected == null || selected === '' || typeof selected == 'undefined') {
                        ing(jm.format(LS.mallCartChoiceItemError, encodeHtml(tmpName)));
                        isBreak = true;
                        success = false;
                        return false;
                    } else {
                        var optionData = {};
                        optionData.name = tmpFieldKey;
                        optionData.value = selected;
                        if (tmpType != null) {
                            optionData.name = tmpFieldKey.replace(/^opIndept/, '');
                            optionData.type = parseInt(tmpType);
                        }
                        optionDataList.push(optionData);
                        isBreak = false;
                    }
                } else {
                    if (choiceId) {
                        // eslint-disable-next-line no-redeclare
                        var optionData = {};
                        optionData.name = tmpFieldKey;
                        optionData.value = parseInt(choiceId);
                        optionData.prop = tmpName;
                        optionData.selected = jm('.isSelected')[index].innerText;
                        if (tmpType != null) {
                            optionData.name = tmpFieldKey.replace(/^opIndept/, '');
                            optionData.type = parseInt(tmpType);
                        }
                        optionDataList.push(optionData);
                        isBreak = false;
                    } else {
                        ing(jm.format(LS.mallCartChoiceItemError, encodeHtml(tmpName)));
                        isBreak = true;
                        success = false;
                        return false;
                    }
                }
            });

            var oldOptionsStr = 'oldOptionsStr';
            if (entry == 1) {
                oldOptionsStr += productId;
            }

            if (Mobi.optionsStr[oldOptionsStr] != 'null' && Mobi.optionsStr[oldOptionsStr]) {
                var oldOptionsArr = Mobi.optionsStr[oldOptionsStr].split('_'),
                    newOptionDataList = [];
                for (var i = 0; i < oldOptionsArr.length; i++) {
                    if (oldOptionsArr[i] === 'timeOption') {
                        for (let j = 0; j < optionDataList.length; j++) {
                            if (optionDataList[j].type == 5 || optionDataList[j].type == 17) {
                                newOptionDataList.push(optionDataList[j]);
                            }
                        }
                    } else {
                        for (let j = 0; j < optionDataList.length; j++) {
                            if (optionDataList[j].name == oldOptionsArr[i]) {
                                newOptionDataList.push(optionDataList[j]);
                            }
                        }
                    }
                }
                optionDataList = newOptionDataList;
            }

            if (!success) {
                mallBuying[productId] = false;
                return false;
            }

            if (optionDataList.length > 0) {
                ajax_data += '&optionList=' + jm.encodeUrl(jm.toJSON(optionDataList));
            }
        }

        var countObj = jm('#productNum' + mktSuffix);
        if (jm('#productMallOptionPanel' + productId + ' #productNum' + mktSuffix).length != 0) {
            countObj = jm('#productMallOptionPanel' + productId + ' #productNum' + mktSuffix);
        }
        var countStr = countObj.val(),
            // eslint-disable-next-line no-redeclare
            count = 1;
        if (jm.isInteger(countStr)) {
            count = parseInt(countStr);
        }
        if (count < 1) {
            count = 1;
        } else if (count > 9999999) {
            count = 9999999;
        }
        countObj.val(count);
        if (LSCount) {
            count = LSCount;
        }
        ajax_data += '&amount=' + count;
    }
    var cmdStr = 'addWafCk_addCartItem';

    if (fromCommunityGroup) {
        mallBuying['cp' + combinedPurchaseId] = false;
        mallBuying[productId] = false;
        Mobi.removeMallTIps();
        cb && cb(optionDataList, count);
        return;
    }
    let tmpcookieData;
    cmdStr = 'addWafCk_addCartItem';
    //立即购买
    if (buyType === 1) {
        var cookieData = {
            pid: productId,
            amount: count,
            optList: jm.toJSON(LSOption),
            isGroupBuy: isGroupBuy,
            groupBuyId: groupBuyId,

            secKillId: secKillId,

            isIntegralMall: isIntegralMall,
            integralMallId: integralMallId,

            isEarnestSale: isEarnestSale,
            earnestSaleId: earnestSaleId,

            pdInfoList: pdInfoList,

            isCpOrder: isCpOrder,
            combinedPurchaseId: combinedPurchaseId,
            communityGroupId: communityGroupId,
        };
        if (
            cookieData.optList == null ||
            cookieData.optList.length == 0 ||
            !cookieData.optList ||
            cookieData.optList == 'null'
        ) {
            cookieData.optList = jm.toJSON(optionDataList);
        }
        tmpcookieData = cookieData;
        cmdStr = 'addWafCk_addIme';
        ajax_data += '&codata=' + jm.encodeUrl(jm.toJSON(cookieData));
    }

    if (buyType == 1) {
        //灰度测试、立即购买

        (async () => {
            //就拼团砍价和社区团购有detailId
            let FieldPosDef = {
                active: 0,
                marketingType: 1,
                marketingId: 2,
                marketingDetailId: 3,
            };
            let marketTable = {
                isGroupBuy: [isGroupBuy, Mobi.MarketingType.GROUP_BUY, groupBuyId, options.groupBuyDetailId],
                isIntegralMall: [isIntegralMall, Mobi.MarketingType.INTEGRAL_MALL, integralMallId, 0],
                isEarnestSale: [isEarnestSale, Mobi.MarketingType.EARNEST_SALE, earnestSaleId, 0],
                isCpOrder: [isCpOrder, Mobi.MarketingType.COMBINED_PURCHASE, combinedPurchaseId, 0],
                isSecKill: [isSecKill, Mobi.MarketingType.SEC_KILL, secKillId, 0],
            };

            //给定默认值，避免接口报错
            let marketingType = 0,
                marketingId = 0,
                marketingDetailId = 0;
            Object.keys(marketTable).some((key) => {
                let value = marketTable[key];
                if (value[FieldPosDef.active]) {
                    marketingType = value[FieldPosDef.marketingType];
                    marketingId = value[FieldPosDef.marketingId];
                    marketingDetailId = value[FieldPosDef.marketingDetailId];
                    return true;
                }
            });

            try {
                let optList = '[]';
                if (tmpcookieData && tmpcookieData.optList) {
                    optList = tmpcookieData.optList;
                }
                let immeArgs = {
                    fromDetail: true,
                    productId,
                    amount: count,
                    optionList: JSON.parse(optList) || [],
                    pdInfoList,
                    marketingType,
                    marketingId,
                    marketingDetailId,
                };
                let result = await Mobi.Ajax.addImmePreOrder(immeArgs);

                let data = result;
                if (data.success) {
                    if (buyType === 1) {
                        // var urlArgS = "";
                        if (isGroupBuy) {
                            logDog(200479, 0); //买家开团或参团
                        } else if (isSecKill) {
                        } else if (isIntegralMall) {
                        } else if (isEarnestSale) {
                            logDog(201085, 1);
                        } else if (isCpOrder) {
                        }
                        location.href =
                            Mobi.preprocessUrl({ path: '', oldPath: '' }) + `mstl.jsp?preOrderId=${data.preOrderId}`;
                    } else {
                        if (!hideAddCartTips) {
                            Mobi.showMallTips(LS.addToMallCatSuccess, true);
                        } else {
                            Mobi.removeMallTIps();
                        }
                    }
                    //更新购物车的产品数量
                    Mobi.refreshMallCartNum();
                    cb && cb();

                    mallBuying['cp' + combinedPurchaseId] = false;
                    mallBuying[productId] = false;
                } else {
                    failHandler(data, true);
                }
            } catch (e) {
                console.log('e', e);
                errorHandler();
            }
        })();
    } else {
        request
            .post('/ajax/order_h.jsp?cmd=' + cmdStr, {
                data: ajax_data,
            })
            .catch(function () {
                errorHandler();
            })
            .then(function (response) {
                const { data: result } = response;
                if (result.success) {
                    successHandler(result);
                } else {
                    failHandler(result);
                }
            });
    }

    function successHandler() {
        if (buyType === 1) {
            var urlArgS = '';
            if (isGroupBuy) {
                urlArgS += '&isGroupBuy=' + isGroupBuy;
                urlArgS += '&groupBuyId=' + groupBuyId;
                urlArgS += '&groupBuyDetailId=' + groupBuyDetailId;

                logDog(200479, 0); //买家开团或参团
            } else if (isSecKill) {
                urlArgS += '&secKillId=' + secKillId;
            } else if (isIntegralMall) {
                urlArgS += '&isIntegralMall=' + isIntegralMall;
                urlArgS += '&integralMallId=' + integralMallId;
            } else if (isEarnestSale) {
                urlArgS += '&isEarnestSale=' + isEarnestSale;
                urlArgS += '&earnestSaleId=' + earnestSaleId;
                logDog(201085, 1);
            } else if (isCpOrder) {
                urlArgS += '&isCpOrder=' + isCpOrder;
                urlArgS += '&combinedPurchaseId=' + combinedPurchaseId;
            }
            //因为cookie方法在部分浏览器尤其是safari浏览器下以iframe形式嵌套 无法存储cookie。
            //故嵌套iframe而且在ios下 就作传参处理
            //juzi 20160627
            if (window.self != window.top && /(iPhone|iPad|iPod|safari)/i.test(navigator.userAgent.toLowerCase())) {
                location.href =
                    Mobi.preprocessUrl({ path: '', oldPath: '' }) +
                    'mstl.jsp?immeData=' +
                    jm.encodeUrl(jm.toJSON(cookieData)) +
                    urlArgS;
            } else {
                location.href = Mobi.preprocessUrl({ path: '', oldPath: '' }) + 'mstl.jsp?imme' + urlArgS;
            }
        } else {
            if (!hideAddCartTips) {
                Mobi.showMallTips(LS.addToMallCatSuccess, true);
            } else {
                Mobi.removeMallTIps();
            }
        }
        //更新购物车的产品数量
        Mobi.refreshMallCartNum();
        cb && cb();

        mallBuying['cp' + combinedPurchaseId] = false;
        mallBuying[productId] = false;
    }

    function failHandler(data, isPreSettle = false) {
        if (data.rt == Mobi.MallAjaxErrno.authMemberNotAllow) {
            ing(data.data + LS.authLevelBuy);

            if (buyType === 1) {
                Mobi.logMonitor(5953, true);
            } else {
                Mobi.logMonitor(5955, true);
            }
        } else if ([Mobi.MallAjaxErrno.login, -503].includes(data.rt)) {
            //新旧的加购接口，对于未登录的返回码不一致，一个是3，一个是-503 by jser 20201103
            // 如果用户未登录，就把当前购买参数记录进storage，登录后在mobi.jsp.inc拿出来继续购买流程

            if (buyType === 1) {
                Mobi.logMonitor(5953, true);
            } else {
                Mobi.logMonitor(5955, true);
            }

            var addCartJson = {};
            addCartJson.buyType = buyType;
            addCartJson.productId = productId;
            addCartJson.entry = entry;
            addCartJson.moduleId = moduleId;
            addCartJson.option = jm.toJSON(optionDataList);
            addCartJson.count = count;
            addCartJson.options = options;
            localStorage.setItem('mallBuyCallBack', jm.toJSON(addCartJson));
            var goToMallCartUrl =
                Mobi.preprocessUrl({ path: '', oldPath: '' }) +
                'login.jsp?returnUrl=' +
                jm.encodeUrl(jm.getUrlRoot(location.href)) +
                '&errno=11';
            location.href = goToMallCartUrl;
        } else {
            if (buyType === 1) {
                Mobi.logMonitor(5952, true);
            } else {
                Mobi.logMonitor(5954, true);
            }

            if (isCpOrder && data.rt == Mobi.MallAjaxErrno.OutOfAllowAmount) {
                jm('#productBuy').data('max-amount', data.maxAmount || 0);
            }

            if (isPreSettle) {
                Mobi.returnMallErrorMsgV2(data, buyType, isCpOrder);
            } else {
                Mobi.returnMallErrorMsg(data.rt, buyType, isCpOrder);
            }
        }
        mallBuying['cp' + combinedPurchaseId] = false;
        mallBuying[productId] = false;
    }

    function errorHandler() {
        ing(LS.js_systemError);
        mallBuying[productId] = false;
        if (buyType === 1) {
            Mobi.logMonitor(5952, true);
        } else {
            Mobi.logMonitor(5954, true);
        }
    }
}

export function cutPrice(cpId, pdId) {
    var ajax_data = 'cpId=' + cpId + '&pdId=' + pdId,
        optionDataList = [],
        mallOptions = jm('.J-op'),
        success = true;

    jm(mallOptions).each(function () {
        var option = jm(this),
            tmpName = option.attr('option_name'),
            tmpFieldKey = option.attr('data'),
            tmpType = option.attr('type'),
            choiceId = option.attr('option_data');
        let isTime = option.attr('isTime');

        if (isTime === 'true') {
            var selected = Mobi.priceCalendar.getSelected();
            if (selected == null || selected === '' || typeof selected == 'undefined') {
                ing(jm.format(LS.mallCartChoiceItemError, encodeHtml(tmpName)));
                success = false;
                return false;
            } else {
                var optionData = {};
                optionData.name = tmpFieldKey;
                optionData.value = selected;
                if (tmpType != null) {
                    optionData.name = tmpFieldKey.replace(/^opIndept/, '');
                    optionData.type = parseInt(tmpType);
                }
                optionDataList.push(optionData);
            }
        } else {
            if (choiceId) {
                // eslint-disable-next-line no-redeclare
                var optionData = {};
                optionData.name = tmpFieldKey;
                optionData.value = parseInt(choiceId);
                if (tmpType != null) {
                    optionData.name = tmpFieldKey.replace(/^opIndept/, '');
                    optionData.type = parseInt(tmpType);
                }
                optionDataList.push(optionData);
            } else {
                ing(jm.format(LS.mallCartChoiceItemError, encodeHtml(tmpName)));
                success = false;
                return false;
            }
        }
    });

    var oldOptionsStr = 'oldOptionsStr';
    oldOptionsStr += pdId;
    if (Mobi.optionsStr[oldOptionsStr] != 'null' && Mobi.optionsStr[oldOptionsStr]) {
        var oldOptionsArr = Mobi.optionsStr[oldOptionsStr].split('_'),
            newOptionDataList = [];
        for (var i = 0; i < oldOptionsArr.length; i++) {
            for (var j = 0; j < optionDataList.length; j++) {
                if (optionDataList[j].name == oldOptionsArr[i]) {
                    newOptionDataList.push(optionDataList[j]);
                }
            }
        }
        optionDataList = newOptionDataList;
    }
    if (!success) {
        Mobi.mallBuying[pdId] = false;
        return false;
    }

    if (optionDataList.length > 0) {
        ajax_data += '&optionList=' + jm.encodeUrl(jm.toJSON(optionDataList));
    }

    jm.ajax({
        type: 'post',
        url: addRequestPrefix({ newPath: '/ajax', oldPath: 'ajax' }) + '/cutPrice_h.jsp?cmd=addWafCk_cutPrice',
        data: ajax_data,
        error() {
            ing(LS.js_systemError);
        },
        success(result) {
            result = jm.parseJSON(result);
            if (result.success) {
                var showAnime = true;
                if (result.alreadyHave) {
                    showAnime = false;
                }
                var cpDetailId = result.cpDetailId;
                var memId = result.memId;
                // 置起已经砍过价的cookie
                setCpDetailCookie(cpId, cpDetailId, memId);
                location.href =
                    preprocessUrl({ path: '', oldPath: '' }) +
                    'cpDetail.jsp?cpId=' +
                    cpId +
                    '&cpDetailId=' +
                    cpDetailId +
                    '&pdId=' +
                    pdId +
                    '&isInit=true&showAnime=' +
                    showAnime;
            } else {
                if (result.rt == -100) {
                    var goToLoginUrl =
                        preprocessUrl({ path: '', oldPath: '' }) +
                        'login.jsp?returnUrl=' +
                        jm.encodeUrl(jm.getUrlRoot(location.href)) +
                        '&errno=11';
                    location.href = goToLoginUrl;
                } else if (result.rt == -2 || result.msg == null || result.msg == '') {
                    ing(LS.js_argsError);
                } else {
                    ing(result.msg);
                }
            }
        },
    });
}

function setCpDetailCookie(cpId, cpDetailId) {
    jm.cookie('cp_' + cpId + '_cpDetail_' + cpDetailId, true, { path: '/' });
}
