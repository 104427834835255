var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isShowFloatIcon
    ? _c("float-effect", {
        attrs: {
          id: "floatEffect",
          "icon-type": _vm.it,
          "img-src": _vm.imgSrc,
          "style-type": _vm.st,
          "icon-background-color": _vm.ibc,
          "icon-background-color-type": _vm.ibct,
          "icon-color": _vm.ic,
          "icon-color-type": _vm.ict,
          "jump-url": _vm.jumpInfo.u,
          "jump-info": _vm.jumpInfo,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }