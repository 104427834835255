import { isPlainObject, deepExtendClone } from '@jz/utils';
import { AnimationApis, ANIMATION_TYPE_LIST, ANIMATION_TYPE } from '@jz/biz-shared';

import jzFdpLogIns from '@/shared/fdpForJz';
import { Monitor } from '@/features/monitor/index';
/**
 * @function name - getMobiAnimationConfig
 * @description 获取转换后的模块数据可供AnimationManage使用的数据
 * @author mikey.chuhui
 * @date 2021-03-25
 * @param options {Object}
 */

export const getMobiAnimationConfig = (options) => {
    if (!isPlainObject(options)) return;
    const _moduleData = deepExtendClone(options);
    const { id = 0, animation = {} } = _moduleData;
    const config = {};
    config['contentTerminal'] = 'mobi';
    config['manageMode'] = VITE_APP_MODE !== 'visitor';
    config['Monitor'] = Monitor;
    config['JzFdpLog'] = jzFdpLogIns;
    if (id === 0) return config;
    config['id'] = id;
    config['idSelector'] = `module${id}`;
    // 方便后续 mobi同步site扩展动画使用
    ANIMATION_TYPE_LIST.forEach((value) => {
        switch (value) {
            case ANIMATION_TYPE.ADMISSION:
                // eslint-disable-next-line no-case-declarations
                const animationTransform = deepExtendClone(animation);
                switch (animationTransform.t) {
                    case 4:
                        animationTransform.t = 6;
                        break;
                    case 5:
                        animationTransform.t = 9;
                        break;
                    case 6:
                        animationTransform.t = 7;
                        break;
                    case 7:
                        animationTransform.t = 8;
                        break;
                    case 8:
                        animationTransform.t = 4;
                        break;
                    case 9:
                        animationTransform.t = 5;
                        break;
                    default:
                        break;
                }
                config[value] = animationTransform;
                break;
            default:
                config[value] = {};
                break;
        }
    });
    return config;
};

/**
 * @function name - initMobiAnimation
 * @description 初始化AnimationManage实例
 * @author mikey.chuhui
 * @date 2021-03-25
 * @param options {Object}
 * @example
 * initMobiAnimation({id:888,commprop:{}});
 */

export const initMobiAnimation = (options) => {
    const { id } = options;
    if (AnimationApis.hasAnimationManageMap(id)) {
        return;
    }
    const config = getMobiAnimationConfig(options);
    return AnimationApis.initAnimationManage(config);
};

export const refreshOneMobiAnimationEl = (id) => {
    let animationManage = AnimationApis.getAnimationManage(id);
    if (animationManage) {
        ANIMATION_TYPE_LIST.forEach((key) => {
            const animationConfig = animationManage.animation[key] || {};
            if (animationConfig && animationConfig.type > 0) {
                switch (key) {
                    // case ANIMATION_TYPE.CONTENT:
                    //     animationConfig.el = getContentDomSeqList(moduleType, animationConfig.order, id);
                    //     animationManage.setAnimation();
                    //     animationManage.setAnimationElId();
                    //     break;
                    default:
                        animationConfig.el = $(`#module${id}`)[0];
                        animationManage.setAnimation();
                        animationManage.setAnimationElId();
                        break;
                }
            }
        });
    }
};
