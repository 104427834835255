<template>
    <div class="module_alert" :class="tipClass">
        <span class="text-left">
            <slot></slot>
        </span>
    </div>
</template>

<script>
export default {
    name: 'ModuleAlert',
    props: {
        type: {
            type: String,
            default: '',
        },
    },
    data() {
        return {};
    },
    computed: {
        tipClass() {
            return {
                [`module_alert_${this.type}`]: true,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.module_alert_warning {
    background-color: #fff5dc;
    text-align: center;
    padding: 10px;
    color: #ff8e1e;
    font-size: 13px;
    margin-bottom: 20px;
    line-height: 22px;
    word-break: break-all;
}

.text-left {
    display: inline-block;
    text-align: left;
}
</style>
