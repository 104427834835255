import { encodeHtml, decodeHtml } from '@/shared/util';

import { getLanCode, mobiIng } from '@/shared/fai';
import { preprocessUrl } from '@/shared/url';
import { changeHref } from '@/features/router/universal';
import './searchBase.scss';
import './search.scss';
/**
 * created from Mobi.bindTopSearch
 * @param {Object} options
 * @returns
 */
export function bindTopSearch(options) {
    if (!options) return;
    jm('.header_v3 .topSearch')
        .off('click.search')
        .bind('click.search', function () {
            popupSearch(options);
        });
    jm('.jsb-showRange-block')
        .off('click.search')
        .bind('click.search', function () {
            popupSearch(options);
        });
}

/**
 * create from Mobi.popupSearch
 * @param {Object} options
 * @returns
 */
export function popupSearch(options) {
    // fromModule标记是否模块进入
    var { showDropMenu, dropMenuList, fromModule, searchTitle } = options;
    options.searchTip = options.searchTip || $('#topSearch').attr('placeholder');

    // 管理态
    if (
        VITE_APP_MODE !== 'visitor' &&
        !fromModule &&
        Fai.top._pattern &&
        Array.isArray(Fai.top._pattern.hs.dropMenuList)
    ) {
        dropMenuList = Fai.top._pattern.hs.dropMenuList;
    }

    var searchType = 0;
    if (dropMenuList && dropMenuList.length > 0) {
        for (let item of dropMenuList) {
            if (item.value == searchTitle) {
                searchType = item.type;
                break;
            }
        }
    }

    Object.assign(options, { searchType }, { fromModule });
    if (!options || !typeof options.hotTopic) {
        return;
    }

    if (typeof options.hotTopic === 'string' && options.hotTopic) {
        options.hotTopic = JSON.parse(options.hotTopic);
    }

    var searchHistory;
    //产品搜索
    if (options.searchProduct) {
        if (jm.getCookie('productSearchHistory')) {
            searchHistory = JSON.parse(jm.getCookie('productSearchHistory'));
        }
    } else {
        if (jm.getCookie('searchHistory')) {
            searchHistory = JSON.parse(jm.getCookie('searchHistory'));
        }
    }
    var searchKey;
    var html = [];
    var defaultTitle = searchTitle || (dropMenuList && dropMenuList[0].value) || '全站';
    html.push(` <div id="searchWindow" class="searchWindow newSearchWindow">
					<div class="searchInputBar">
				 		<div style="display: none;"  class="subMenu sb-subMenu">
							<div class="triangle-wrap"><i class="triangle"></i></div>`);
    if (dropMenuList && dropMenuList.length > 0) {
        for (let item of dropMenuList) {
            html.push(` <div class="subMenuItem" style="${item.show ? '' : 'display: none'}">
							<span data-type="${item.type}" class="subMenuItem-span">${encodeHtml(item.value)}</span>
						</div>`);
        }
    }
    html.push(` 		</div>

					<div class="popupSearchWrap newPopupSearchWrap">

						<svg class="${fromModule ? 'fromModule-searchSvg' : ''} searchSvg ${
        showDropMenu ? 'hide-element' : ''
    }" id="icon-searchSubmit">
							<use xlink:href="#jzm-v19" :fill="#868686" style="fill:#868686"></use>
						</svg>

						<form style="display: flex;align-items: center;">

							<div class="${fromModule ? 'fromModule-showRange-block' : ''} sb-showRange-block ${showDropMenu ? '' : 'hide-element'}">
								<div style="margin-left: 0;" class="searchRange sb-defaultTitle">${encodeHtml(defaultTitle)}</div>
								<div class="arrow-svgDiv">
									<svg class="icon-arrow">
										<use fill='#808080' xlink:href="#jzm-vicon_arrow_panel" />
									</svg>
								</div>
							</div>
							<input id="searchInput" class="popupSearchInput newSearchInput" placeholder=${decodeHtml(options.searchTip || '')}>
						</form>
						<svg class="icon-delete" id="icon-delete">
							<use xlink:href="#jzm-v25" :fill="#ffffff" style="color:#cccccc; fill:#ffffff;"></use>
						</svg>
						<div id="icon-closeSearch" class="icon-closeSearch">${LS.cancel}</div>
					</div>
				</div>`);

    if (options && options.hotTopic && options.hotTopic.length) {
        html.push('	<div class="hotTopicWrap">');
        html.push('		<h3>' + LS.hostSearch + '</h3>');
        for (var i = 0, l = options.hotTopic.length; i < l; i++) {
            html.push('<span class="topicItem">' + encodeHtml(options.hotTopic[i]) + '</span>');
        }
        html.push('	</div>');
    }

    if (options.historySearch && searchHistory) {
        html.push('<div class="historyTopicWrap">');
        html.push('			<svg class="deleteSvg" id="clearHistoryBtn">');
        html.push('				<use xlink:href="#jzm-v18" :fill="#2E2D2D" style="fill:#2E2D2D"></use>');
        html.push('			</svg>');
        html.push('		<h3>' + LS.historySearch + '</h3>');

        if (searchHistory) {
            for (let i = 0, l = searchHistory.length; i < l; i++) {
                html.push('<span class="topicItem">' + encodeHtml(searchHistory[i]) + '</span>');
            }
        }

        html.push('</div>');
    }
    html.push('</div>');

    var jmhtml = jm(html.join(''));

    jm('#g_web').append(jmhtml);

    jm('#searchWindow').on('click', function () {
        var submenu = jm('#searchWindow .searchInputBar .subMenu');
        if (submenu[0].style.display != 'none') {
            changeArrowDirection();
        }
        submenu.hide();
    });

    jm('.sb-subMenu').on('click', function (e) {
        var event = e || window.event;
        var target = event.target || event.srcElement;
        var submenu = jm('#searchWindow .searchInputBar .subMenu');
        var searchRange = jm('.sb-showRange-block .searchRange');
        var text = target.innerText;
        // 区分模块全站搜索与顶部搜索
        var selectEl = fromModule ? '.module-defaultTitle' : '.title-top-defaultTitle';
        submenu.hide();
        changeArrowDirection();
        searchType = parseInt(target.getAttribute('data-type') || 0);
        searchRange.text(text);
        jm(selectEl).text(text);
        Object.assign(options, { searchType }, { searchTitle: text });
        event.stopPropagation();
    });
    jm('.sb-showRange-block').on('click', function (e) {
        var event = e || window.event;
        var submenu = jm('#searchWindow .searchInputBar .subMenu');
        event.stopPropagation();

        submenu.toggle();

        changeArrowDirection();
    });
    jm('#icon-closeSearch').on('click', function () {
        jm('#searchWindow').on(
            'animationend webkitAnimationEnd msAnimationEnd oAnimationEnd oanimationend',
            function () {
                jm('#searchWindow').remove();
                jm(this).off('animationend webkitAnimationEnd msAnimationEnd oAnimationEnd oanimationend');
            }
        );
        jm('#searchWindow').addClass('searchWindowOff');
        jm(this).off('click');
    });
    jm('#icon-searchSubmit').on('click', function () {
        searchKey = jm.trim(jm('#searchInput').val());
        if (searchKey == '') {
            jm('#searchInput').get(0).focus();
            mobiIng('请输入搜索内容');
            return;
        }
        searchInMobi(searchKey, options);
    });
    jm('#icon-delete').on('click', function () {
        jm('#searchInput').val('');
        jm('#icon-delete').hide();
    });

    jm('.newSearchInput').on('input', function () {
        if (jm(this).val() != '') {
            jm('#icon-delete').show();
        } else {
            jm('#icon-delete').hide();
        }
    });
    jm('#searchWindow').on('keypress', '#searchInput', function (e) {
        if (e.which === 13) {
            searchKey = jm.trim(jm('#searchInput').val());
            // 监听搜索内容为空时的回车事件
            if (searchKey == '') {
                mobiIng('请输入搜索内容');
                jm('#searchInput').get(0).blur();
                jm('#searchInput').get(0).focus();
                return false;
            } else {
                searchInMobi(searchKey, options);
            }
            return false;
        }
    });
    jm('#searchWindow').on('click', '.topicItem', function () {
        searchKey = jm(this).text();
        searchInMobi(searchKey, options);
        jm('.topicItem').off('click');
    });

    jm('#searchWindow').on('click', '#clearHistoryBtn', function () {
        jm('.historyTopicWrap').remove();
        jm(this).remove();
        if (options.searchProduct) {
            jm.cookie('productSearchHistory', '', -1, { path: '/' });
        } else {
            jm.cookie('searchHistory', '', -1, { path: '/' });
        }
    });
    jm('#searchInput').get(0).focus();
}

function searchInMobi(key, options) {
    //检查搜索前是否保存了
    if (VITE_APP_MODE !== 'visitor' && Vue.prototype.$designer.checkSaveBar()) {
        return false;
    }

    //新模块搜索关闭面板
    if (VITE_APP_MODE !== 'visitor') {
        Vue.prototype.$designer.close();
    }

    var moduleId = options.moduleId;
    var searchKey = key;
    var nSL = options.nSL;
    var rangeType = options.rangeType;

    //如果有缓存优先使用缓存的搜索范围(全站搜索)
    if (
        window.localStorage &&
        window.localStorage.getItem &&
        window.localStorage.getItem('totalSearchRange' + moduleId) &&
        VITE_APP_MODE !== 'visitor'
    ) {
        nSL = JSON.parse(window.localStorage.getItem('totalSearchRange' + moduleId)).nSL;
        rangeType = JSON.parse(window.localStorage.getItem('totalSearchRange' + moduleId)).rangeType;
    }

    //格式化noList全局搜索
    if (!options.searchProduct) {
        if (typeof JSON.parse(jm.toJSON(nSL)) === 'object') {
            var temp = [];
            for (let k in JSON.parse(jm.toJSON(nSL))) {
                temp[k] = JSON.parse(jm.toJSON(nSL))[k];
            }
            nSL = temp;
        }
    }
    //全站搜索，如果范围全部，nolist设为空
    if (rangeType === 0) {
        nSL = [];
    }

    if (typeof moduleId == 'undefined') {
        moduleId = -1;
    }
    // 目前搜索结果页仅显示 顶部搜索栏，模块搜索不需要searchTitle
    var addUrl = options.fromModule ? '' : '&searchTitle=' + options.searchTitle;
    var lanCode = getLanCode();
    var lanCodePrefix = lanCode === '' ? '/' : `/${lanCode}/`;
    var url =
        lanCodePrefix +
        'searchResult.jsp?searchword=' +
        jm.encodeUrl(jm.trim(searchKey)) +
        '&nSL=' +
        jm.encodeUrl(jm.toJSON(nSL)) +
        '&searchModuleId=' +
        moduleId +
        '&searchType=' +
        options.searchType +
        addUrl;
    // 产品搜索多传一个searchProduct=1的参数，以及模块的id
    if (options.searchProduct == 1) {
        url =
            preprocessUrl({ path: '', oldPath: '' }) +
            'pr.jsp?psId=' +
            moduleId +
            '&searchword=' +
            jm.encodeUrl(jm.trim(searchKey));
    }
    // window.location.href = url;
    changeHref(url);
    jm('#icon-closeSearch').trigger('click');

    //记录搜索历史，最多记录10项，但注意UI同时要求搜索历史展示面板里最多只显示两行数据，超出的会被overflow:hidden隐藏掉
    var searchHistory = [];
    if (options.searchProduct) {
        if (jm.getCookie('productSearchHistory')) {
            searchHistory = JSON.parse(jm.getCookie('productSearchHistory')) || [];
        }
    } else {
        if (jm.getCookie('searchHistory')) {
            searchHistory = JSON.parse(jm.getCookie('searchHistory')) || [];
        }
    }
    if (searchHistory.indexOf(searchKey) !== -1) {
        return;
    }
    searchHistory.unshift(searchKey);
    searchHistory = searchHistory.slice(0, 10);
    if (options.searchProduct) {
        jm.cookie('productSearchHistory', jm.toJSON(searchHistory), {
            expires: 7,
            path: '/',
        });
    } else {
        jm.cookie('searchHistory', jm.toJSON(searchHistory), {
            expires: 7,
            path: '/',
        });
    }
}

function changeArrowDirection() {
    var arrowSvg = jm('.sb-showRange-block .arrow-svgDiv');
    if (arrowSvg.hasClass('arrow-svgDiv-up')) {
        arrowSvg.removeClass('arrow-svgDiv-up');
    } else {
        arrowSvg.addClass('arrow-svgDiv-up');
    }
}
