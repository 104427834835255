export const fixNewsDetailVideo = function () {
    var ua = Fai.top.navigator.userAgent;

    if (!/(iPhone)/i.test(ua)) {
        return;
    }

    //苹果手机才需要修复，preload="none"且src在souce上且系统是10.3以上，需要点击两次视频才播放。
    // 修复方案：第一次点击会猝发progress，若未播放过则手动play()一次可以解决
    jm('video').each(function (index, el) {
        jm(el).on('progress', function () {
            el.played.length < 1 && el.play();
        });
    });
};

// 3.0无头图时候顶部图标颜色
export const changeIconBg = function () {
    if ($('body').find('#newsDetailPanel.pageStyle1').length) {
        $('.jz_themeV3 .newsDetailV3Panel .newsBack, .jz_themeV3 .newsNewDetailPanel .newsHome').css(
            'background-color',
            '#ffffff'
        );
        $('.jz_themeV3 .newsDetailV3Panel .newsBack, .jz_themeV3 .newsNewDetailPanel .newsHome').addClass(
            'newsIconBlack'
        );
    } else if ($('body').find('#newsDetailPanel.pageStyle0').length) {
        $('.jz_themeV3 .newsDetailV3Panel .newsBack, .jz_themeV3 .newsNewDetailPanel .newsHome').addClass(
            'newsIconWhite'
        );
    }
};

// 修复开启评论，底部有一个输入框占位，关闭评论后将会出现一个空白区域 ---- cys
export const fixFormMiddleContentBottom = function (moduleId, isOpenComment) {
    var module = jm('#module' + moduleId);
    if (module.length === 0) {
        return;
    }
    var formMiddleContent = module.find('.formMiddleContent');
    let onlyShare = module.find('.only_share');
    if (isOpenComment) {
        formMiddleContent.removeAttr('style');
    } else {
        if (onlyShare.length == 0) {
            formMiddleContent.css('margin-bottom', 0);
        }
    }
};

// 由于有些主题的 form 样式会设置 top 属性和 border-radius，这会影响详情页的布局
export const fixModuleTop = function (moduleId) {
    var module = jm('#module' + moduleId);
    if (module.length === 0) {
        return;
    }
    if (parseInt(module.css('top')) > 0) {
        module.css('top', 0);
    }
    if (parseInt(module.css('border-radius')) > 0) {
        module.css('border-radius', 0);
    }
};
