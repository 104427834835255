import { getFooterV3, getFooterV2 } from '@/api/footer';
import { SiteVerDef } from '@/def/ver';

/**
 * =================================================
 *
 * Constants
 *
 * =================================================
 */

const LOAD_FOOTER_INFO = 'LoadFooterInfo';
const LOAD_FOOTER_INFOV2 = 'LoadFooterInfoV2';

export { LOAD_FOOTER_INFO, LOAD_FOOTER_INFOV2 };

/**
 * =================================================
 *
 * State
 *
 * =================================================
 */
const state = () => ({
    footerStyle: null,
    footerMenuInfo: null,
    openLanInfoList: [],
    footerSupportInfo: null,
    footerShowIpv6: true,
    domainBeianStr: '',
    reportUrl: '',
    siteVer: -1,
    _siteDemo: false,
    oemPageTemplateView: false,
    footerV2: {
        hiddenTop: false,
        designMode: true,
        isMallTheme: false,
        showFaiscoSupport: false,
        homeLink: '',
        profileLink: '',
        pcUrl: '',
        showPC: false,
        openLanInfoList: [],
        footerInfo: '',
        footerStyleInfo: {},
        wapOptimizerContent: '',
        showDomainBeian: false,
        domainBeianStr: '',
        footerSupportInfo: {},
        footerAuth: false,
    },
    footerChanged: false,
    wapOptimizerContent: '',
});
/**
 * =================================================
 *
 * getters
 *
 * =================================================
 */
const getters = {
    footerStyleInfoGetters: (state) => state.footerV2.footerStyleInfo ?? {},
    footerStyleGetters: (state) => state.footerStyle ?? {},
    footerMenuInfoGetters: (state) =>
        state.footerMenuInfo ?? {
            lanSwitch: {},
            entrance: {},
            home: {},
            profile: {},
        },
    footerSupportInfoGetters: (state) => state.footerSupportInfo ?? {},
    footerSupportInfoV2Getters: (state) => state.footerV2.footerSupportInfo ?? {},
};

/**
 * =================================================
 *
 * actions
 *
 * =================================================
 */
let loaded = false;
const actions = {
    loadFooterInfo({ commit, rootState }) {
        if (rootState.app.openThemeV3) {
            if (loaded) return;
            return getFooterV3()
                .then((data) => {
                    loaded = true;
                    commit(LOAD_FOOTER_INFO, data);
                    return data;
                })
                .catch((value) => {
                    console.log(value);
                });
        } else {
            return getFooterV2()
                .then((data) => {
                    commit(LOAD_FOOTER_INFOV2, data);
                    return data;
                })
                .catch((value) => {
                    console.log(value);
                });
        }
    },
};

/**
 * =================================================
 *
 * mutations
 *
 * =================================================
 */
const mutations = {
    [LOAD_FOOTER_INFO](state, payload) {
        const {
            footerStyle,
            footerMenuInfo,
            openLanInfoList,
            footerSupportInfo,
            footerShowIpv6,
            domainBeianStr,
            reportUrl,
            siteVer,
            _siteDemo,
            oemPageTemplateView,
            wapOptimizerContent,
        } = payload;
        state.footerStyle = footerStyle;
        state.footerMenuInfo = footerMenuInfo;
        state.openLanInfoList = openLanInfoList;
        state.footerSupportInfo = footerSupportInfo;
        state.footerShowIpv6 = footerShowIpv6;
        state.domainBeianStr = domainBeianStr;
        state.reportUrl = reportUrl;
        state.siteVer = siteVer;
        state._siteDemo = _siteDemo;
        state.oemPageTemplateView = oemPageTemplateView;
        state.wapOptimizerContent = wapOptimizerContent;
    },
    [LOAD_FOOTER_INFOV2](state, payload) {
        const {
            designMode,
            domainBeianStr,
            footerStyleInfo,
            footerSupportInfo,
            hiddenTop,
            homeLink,
            isMallTheme,
            openLanInfoList,
            pcUrl,
            profileLink,
            reportUrl,
            showDomainBeian,
            showFaiscoSupport,
            showPC,
            wapOptimizerContent,
            siteVer,
            footerAuth,
        } = payload;
        state.footerV2.designMode = designMode;
        state.footerV2.domainBeianStr = domainBeianStr;
        state.footerV2.footerStyleInfo = footerStyleInfo;
        state.footerV2.footerSupportInfo = footerSupportInfo;
        state.footerV2.hiddenTop = hiddenTop;
        state.footerV2.homeLink = homeLink;
        state.footerV2.isMallTheme = isMallTheme;
        state.footerV2.openLanInfoList = openLanInfoList;
        state.footerV2.pcUrl = pcUrl;
        state.footerV2.profileLink = profileLink;
        state.footerV2.reportUrl = reportUrl;
        state.footerV2.showDomainBeian = showDomainBeian;
        state.footerV2.showFaiscoSupport = showFaiscoSupport;
        state.footerV2.showPC = showPC;
        state.footerV2.wapOptimizerContent = wapOptimizerContent;
        state.footerV2.isFreeVer = siteVer === SiteVerDef.FREE;
        state.footerV2.footerAuth = footerAuth;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
