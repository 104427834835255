var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "module-frame",
    { attrs: { "module-id": _vm.moduleId } },
    [
      _c(
        _vm.component,
        _vm._b({ tag: "component" }, "component", _vm.propsData, false)
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }