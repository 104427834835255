import request from './request';

export function getFooterV3() {
    return request.get('/api/manage/footer/footerV3').then(({ data = {} }) => {
        return data.data;
    });
}

export function getFooterV2() {
    return request.get('/api/manage/footer/footerV2').then(({ data = {} }) => {
        return data.data;
    });
}

export function getFooterDesign() {
    return request.get('/api/manage/footer/footerDesign').then(({ data = {} }) => {
        return data.data;
    });
}

export function getWechatDemoFootSupport() {
    return request
        .get('/ajax/footer_h.jsp?cmd=getWafNotCk_wechatDemoFootSupport')
        .then(({ data = {} }) => {
            return data;
        })
        .catch((err) => console.log(err));
}
