<template>
    <float-effect :components-table="componentsTable" />
</template>

<script>
import FloatEffect from './floatEffect.vue';
import { componentsTable } from '@/modules/visitor-interface';

export default {
    name: 'FloatEffectVisitor',
    components: {
        FloatEffect,
    },

    data() {
        return {
            componentsTable,
        };
    },
};
</script>

<style></style>
