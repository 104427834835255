var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mProductList prContainer",
      attrs: { id: "mProductList" + _vm.moduleId },
    },
    [
      _vm._l(_vm.list, function (item, index) {
        return [
          _c(
            "div",
            {
              key: "pr3_0_" + index,
              staticClass: "productPicListForm J_product_item",
              attrs: {
                title: _vm.manageMode && item.top != 0 ? "该产品已置顶" : "",
                productid: item.id,
              },
            },
            [
              _c("a", { attrs: { href: item.mobiHtmlUrl } }, [
                _c(
                  "div",
                  { staticClass: "tableBox" },
                  [
                    _c("inner-wrap", { attrs: { item: item } }, [
                      _c("div", { staticStyle: { position: "relative" } }, [
                        _vm.options.salePromotionOpen
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "g_main_bgColor_v3 g_bgColor f-productListTopPromotion",
                              },
                              [_vm._v(_vm._s(item.saleFlagName))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.options.openKnowPayment && item.productType == 2
                          ? _c(
                              "span",
                              { staticClass: "quality_content_label" },
                              [_vm._v(_vm._s(item.labelText))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "J_img_lazyload style3Img",
                          class: _vm.picStretchClass,
                          attrs: {
                            alt: item.name,
                            src: _vm.options.loadingPath,
                            "src-original": item.newPath,
                          },
                        }),
                        _vm._v(" "),
                        _vm.options.showSoldOut
                          ? _c("div", { staticClass: "soldOutBox" }, [
                              _c("img", {
                                staticClass: "soldOut",
                                attrs: { src: _vm.options.soldOutImg },
                              }),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tableCell tableCell-70" },
                      [
                        _vm.showProName
                          ? _c(
                              "div",
                              {
                                class: _vm.nameWrap
                                  ? "nameWrap"
                                  : "paramNowrap",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "g_link paramName" },
                                  [
                                    item.isTimedAdded
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "g_oldThemeBgColor g_main_bgColor_v3 m_timedAddTag",
                                          },
                                          [_vm._v("即将开售")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getProName(item.name)
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm.getShowMallCart(item)
                                      ? _c("mcart", {
                                          attrs: {
                                            pid: item.id,
                                            type: 3,
                                            text: item.shopBtnTypeText,
                                            "form-id": item.productFormId,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _c(
                              "div",
                              {
                                class: _vm.nameWrap
                                  ? "nameWrap"
                                  : "paramNowrap",
                              },
                              [
                                _c(
                                  "p",
                                  { staticClass: "g_link paramName" },
                                  [
                                    _vm.getShowMallCart(item)
                                      ? _c("mcart", {
                                          attrs: {
                                            pid: item.id,
                                            type: 3,
                                            text: item.shopBtnTypeText,
                                            "form-id": item.productFormId,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                        _vm._v(" "),
                        _c("prop-panel", { attrs: { info: item } }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            key: "pr3_1_" + index,
            staticClass: "g_separator separatorLine",
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }