<template>
    <news-detail :module-id="moduleId"></news-detail>
</template>
<script>
import NewsDetail from './module/index.vue';
import { clueLogMixin } from '../util.js';

export default {
    name: 'NewsDetailVisitor',
    components: {
        NewsDetail,
    },
    mixins: [clueLogMixin],
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {};
    },
};
</script>
