var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mixNewsStyleLine newsLine J_picNum",
      class: _vm.lineClass,
      attrs: {
        id: _vm.newsLineId,
        topClassname: _vm.topClassName,
        topSwitch: _vm.topSwitch,
        newsId: _vm.id,
        newsName: _vm.title,
        picnum: "4",
      },
    },
    [
      _c("div", { staticClass: "lineBody", attrs: { id: _vm.lineBodyId } }, [
        _c("div", { staticClass: "mixNewsStyleImgBox3" }, [
          _c(
            "a",
            {
              staticStyle: {
                width: "100%",
                height: "auto",
                overflow: "hidden",
                "max-width": "100%",
                "max-height": "8rem",
                display: "block",
                position: "relative",
              },
              attrs: {
                hidefocus: "true",
                href: _vm.href,
                target: _vm.target,
                onclick: _vm.onclickStr,
              },
            },
            [
              !_vm.pictureId
                ? _c("div", {
                    staticClass: "newsTitlePic icon-noFigureB",
                    attrs: { id: "newsTitlePic", alt: _vm.alt },
                  })
                : _c("div", {
                    staticClass: "newsTitlePic",
                    style: _vm.newsTitlePicStyle,
                    attrs: { id: "newsTitlePic", alt: _vm.alt },
                  }),
              _vm._v(" "),
              _c("div", { staticClass: "mixNewsStyleTitleContainer3" }, [
                _c("div", { class: _vm.topFlagClass }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: [
                      _vm.showLine
                        ? "mixNewsStyleTitle1"
                        : "mixNewsStyleTitle2",
                    ],
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.title) +
                        "\n                    "
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }