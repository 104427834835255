<template>
    <float-effect-container
        v-if="isShowFloatZoneContainer || isEdit"
        :components-table="componentsTable"
    ></float-effect-container>
</template>

<script>
import floatEffectContainer from './floatEffectContainer.vue';
export default {
    components: {
        floatEffectContainer,
    },
    props: {
        componentsTable: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {};
    },
    computed: {
        isShowFloatZoneContainer() {
            return this.ft === 1 && this.isShowFloatEffect;
        },
        ft: {
            get() {
                return this.$store.state.floatEffect.ft;
            },
        },
        isShowFloatEffect: {
            get() {
                return this.$store.getters['panelSetting/isShowFloatEffect'];
            },
        },
        isEdit() {
            return this.$store.state.floatEffect.isEdit;
        },
    },
};
</script>

<style lang="scss">
%extend_1 {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    border-radius: 0;
}

%extend_2 {
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}

.float_zone_wrap {
    height: 100%;
}
.floatZoneContainer {
    .formStyle52.form {
        @extend %extend_1;
        .formMiddle {
            @extend %extend_1;
        }
    }
    .formStyle53.form {
        margin: 0;
        padding: 0;
    }
    .formStyle54.form {
        margin: 0;
    }
    > .form {
        @extend %extend_2;
        > .formMiddle {
            @extend %extend_2;
            // .middleLeft {
            //     @extend %extend_2;
            // }
            > .middleCenter {
                @extend %extend_2;
                > .formMiddleContent {
                    @extend %extend_2;
                }
            }
        }
    }
}
</style>
