<template>
    <div v-if="show" class="ad_guide_purchaseVerForFree">
        <lower-left-component :config="config" @closeAd="closeAd" @clickAd="clickAd"> </lower-left-component>
    </div>
</template>

<script>
import lowerLeftComponent from '@/features/advertisement/component/loverLeft.vue';
import { logDog } from '@/shared/log';
import { showWeChatAd } from '../weChatAd';
import { isWechat } from '@/shared/browser';
import { jzTechSupportClick } from '@/shared/fdpLog';
export default {
    name: 'PurchaseVerForFree',
    components: {
        lowerLeftComponent,
    },
    inject: ['websiteInfo'],
    data() {
        return {
            show: true,
        };
    },
    computed: {
        config() {
            let adImg = '',
                adAlt = '';
            if (this.websiteInfo.openFreeVerAd) {
                adImg = Fai.top._resRoot + '/image/mobiAdvert/mfjz.png';
                adAlt = '免费建站';
            } else if (this.websiteInfo.openFreeVerAdMall) {
                adImg = Fai.top._resRoot + '/image/mobiAdvert/mobi-mall-adv.png';
                adAlt = '免费建商城';
            }

            return {
                adImg,
                adAlt,
                closeImg: Fai.top._resRoot + '/image/mobiAdvert/mfkz-close.png?v=202012021447',
            };
        },
    },
    mounted() {
        window.bizShared.context.fdpLog('jz_tech_support_expo', {
            jz_free_text1: '免费站点广告',
            jz_version: window.bizShared.context.jzVersion,
        });
        if (isWechat()) {
            window.bizShared.context.fdpLog('jz_wx_mobi_gg_expo', {
                jz_free_text1: '左侧免费广告',
                jz_version: window.bizShared.context.jzVersion,
            });
        }
    },
    methods: {
        clickAd() {
            if (!Fai.top._oem) {
                jzTechSupportClick();
            }
            if (isWechat() || Fai.top._oem) {
                showWeChatAd(2);
            } else {
                window.open(`//jz${window.Hosts.mHome}/?_ta=2599`);
            }

            if (this.websiteInfo.openFreeVerAdMall) {
                logDog(201632, 2);
            }
        },
        closeAd() {
            this.show = false;
        },
    },
};
</script>

<style lang="scss">
.ad_guide_purchaseVerForFree {
    .ad_component_loverLeft {
        position: fixed;
        margin-left: 0.7rem;
        bottom: 2.125rem;
        width: 4.225rem;
        height: 1.6rem;
    }
    .ad_loverLeft_header {
        position: absolute;
        right: 0;
        top: -1.35rem;
        height: 1.35rem;
        width: 1.35rem;
        cursor: pointer;
    }
    .ad_loverLeft_header__close {
        width: 0.9rem;
        height: 0.9rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>
