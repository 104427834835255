import LotteryPopup from './popup';
import ResultPopup from './result';

import { ing, mobiIng } from '@/shared/fai';
import { getLotteryInfo as getLotteryInfoAjax } from '@/api/linkSetting';

// 获取抽奖弹窗数据
const getLotteryInfo = function (id, phoneNumber, loginUrl = null, allowForm = false) {
    // 管理态禁止抽奖
    if (VITE_APP_MODE !== 'visitor') {
        ing('当前为管理状态，提交无效。', true);
        return;
    }
    if (window.CanNotLottery) {
        return;
    }
    // 弱网环境下频繁触发抽奖弹窗
    window.CanNotLottery = true;

    // 未知情况下id==0 或 为空，发起请求导致后台告警，这里直接return
    if (!id || id == '0') {
        return;
    }
    getLotteryInfoAjax(id).then((data) => {
        if (data.success) {
            window.CanNotLottery = false;
            let lotteryList = data.info.prizeList,
                isOpenLottery = data.lotterySettingInfo.isOpenLottery,
                lotteryOpen = data.info.flag,
                allowLottery = data.lotterySettingInfo.allowLottery;
            // 活动开启
            if (isOpenLottery && lotteryOpen && allowLottery) {
                // 自定义链接控件，修改成表单，不弹出窗口
                if (!allowForm && data.info.type == 1) {
                    return;
                }
                openLotteryPopup(lotteryList, id, phoneNumber, loginUrl);
            } else {
                mobiIng('活动未开启，尽请期待！', 0);
            }
        }
    });
};

// 打开抽奖弹窗
const openLotteryPopup = function (lotteryList, lotteryId, phoneNumber, loginUrl) {
    LotteryPopup.render(lotteryList, lotteryId, phoneNumber, loginUrl);
};

export default {
    LotteryPopup,
    ResultPopup,
    getLotteryInfo,
    openLotteryPopup,
};
