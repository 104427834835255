import { loadStyle } from '@/api/firstPaint';
import { updateColInfo, fixColIdForRouter } from './col';
import { refreshPageWindowData } from './util';
export async function updatePageData({ colId, cusUrl }) {
    let data;
    // 处理首屏数据
    try {
        const res = await window.$store.dispatch('app/loadFirstPaintData', {
            colId,
            cusUrl,
        });
        if (res.success === true) {
            data = res.data.data;
            updateColInfo(data._colInfo);
            refreshPageWindowData(data);
            fixColIdForRouter(data._colInfo.id, colId);
            // 替换首屏 css links
            replaceCssLinks(res.data.cssLinks);
            // 替换首屏style
            loadStyle({ colId, cusUrl }).then(({ style }) => {
                resetStyle(style);
            });
        } else {
            if (res.rt === -3) {
                // 栏目不存在
                return {
                    pageNotFound: true,
                };
            }
        }
    } catch (error) {
        return data;
    }
    return data;
}

function resetStyle(styleStr) {
    if (styleStr == '') {
        return styleStr;
    }

    const PAGE_CLASS_FLAG = 'J_page_style';

    // 清空带有 PAGE_CLASS_FLAG 的节点
    $(`.${PAGE_CLASS_FLAG}`).remove();

    const styleArray = styleStr
        .split(/(<style[\s\S]+?>[\s\S]+?<\/style>)+?/g)
        .filter((item) => item.indexOf('<style') === 0);
    for (let style of styleArray) {
        const idMatched = style.match(/id=['"]([\s\S]+?)['"]/);
        if (idMatched !== null && Array.isArray(idMatched) && idMatched.length > 1) {
            const idSelector = `#${idMatched[1]}`;

            if ($(idSelector).length === 0) {
                const $styleEl = $(style);
                $('head').append($styleEl[0]);
            } else {
                $(idSelector).replaceWith(style);
            }
            $(idSelector).addClass(PAGE_CLASS_FLAG);
        }
    }
}

function replaceCssLinks(cssLinks) {
    const cssLinkIds = Object.keys(cssLinks);
    for (let cssLinkId of cssLinkIds) {
        const el = document.getElementById(cssLinkId);
        if (el) {
            el.setAttribute('href', cssLinks[cssLinkId]);
        }
    }
}

/**
 *
 *  更新访客态和管理态的vuex变量(从window从提交到vuex的部分)
 *  TODO 这部分访客态从首屏数据直接拿到commit到vue先
 * @export
 */
export function updateVistorPageState() {
    // 悬浮效果
    {
        window.$store.commit('panelSetting/changeIsShowFloatEffect', window.isShowFloatEffect);
        window.$store.commit('panelSetting/changeIsIndependentShowFloatEffect', window.isIndependentShowFloatEffect);
        window.$store.commit(
            'panelSetting/changeIsIndependentShowFloatEffectType',
            window.isIndependentShowFloatEffectType
        );

        window.$store.commit('panelSetting/changeIsFloatEffectAllow', window.isFloatEffectAllow);
        window.$store.commit('panelSetting/changeIsDisabledFloatEffectPage', window.isDisabledFloatEffectPage);
    }

    window.$store.commit('updateAllNewsCount', window._allNewsCount);

    window.$store.commit('nav/UpdateFullPageNav', {
        hide: window.fullPageNavHide,
    });

    window.$store.commit('nav/UpdateIndependentNav', {
        hide: window._independentNavHide,
    });
    window.$store.commit('app/setOpenMultiPdInquiry', window.openMultiPdInquiry);
}

export function updatePageState() {
    updateVistorPageState();

    window.$store.commit('panelSetting/updatePanelSettingData', window);
    window.$store.commit('backgroundSetting/updateBackgroundImg', window._backgroundInfo);

    window.$store.commit('updateAllProductCount', window._allProductCount);

    window.$store.dispatch('manage/theme/updateThemeId', window._templateFrameId);

    window.$store.commit('app/updateAllowedBackTop', window._allowedBackTop);
}
