import { autoFixGWebTop } from '@/shared/autoFixGwebTop';

let countSiteTitleBgHeiht = 0;

/**********将Logo图片根据上下外边距进行等宽高压缩**********/
export const logoSizeCompressByMargin = function () {
    if (jm('#pageLogo').length < 1) {
        return;
    }

    var pageLogo = jm('#pageLogo'),
        pageHeader = jm('#webHeader');

    if (pageHeader.css('display') == 'none') {
        return;
    }

    pageLogo.height(0);
    var imgMarginTop = parseFloat(pageLogo.css('margin-top').replace('px', '')),
        imgMarginBottom = parseFloat(pageLogo.css('margin-bottom').replace('px', '')),
        imgMarginLeft = parseFloat(pageLogo.css('margin-left').replace('px', '')),
        imgMarginRight = parseFloat(pageLogo.css('margin-right').replace('px', '')),
        logoStyleId = parseInt(pageLogo.attr('sid')),
        logoOrgPW = parseInt(pageLogo.attr('pw')),
        logoOrgPH = parseInt(pageLogo.attr('ph')),
        headerHeight = parseFloat(pageHeader.height()),
        size_tmp = 0,
        tmpHearderWidth;
    size_tmp = Math.floor(headerHeight - imgMarginTop - imgMarginBottom);
    pageLogo.height(size_tmp + 'px');

    if (logoStyleId != 0) {
        pageLogo.width(parseInt(pageLogo.height()) + 'px');
    } else {
        // fixed by json  : 未考虑到logo长度长过header区域导致被挡住
        // 修正：当logo长度长过header区域时，应用header宽度。
        tmpHearderWidth = (parseInt(pageLogo.height()) * logoOrgPW) / logoOrgPH;
        tmpHearderWidth =
            tmpHearderWidth > pageHeader.width()
                ? pageHeader.width() - imgMarginLeft - imgMarginRight
                : tmpHearderWidth;
        pageLogo.width(tmpHearderWidth + 'px');
    }

    jm('#webHeaderBox #header').removeClass('header-forLogo'); //修复浮动的logo会占位往下挤containerBox

    pageLogo.css('visibility', 'visible');
};
/**********根据Logo位置将标题进行重新定位**********/

export const titlePositionRefreshByLogo = function () {
    if (jm('#pageLogo').length < 1) {
        return;
    }

    var pageLogo = jm('#pageLogo'),
        pageHeader = jm('#header'),
        pageTitle = jm('#pageTitle'),
        webHeader = jm('#webHeader');

    if (webHeader.css('display') == 'none') {
        return;
    } //初始化样式--start--

    pageLogo.css({
        position: '',
        float: '',
        left: '',
        right: '',
    });
    var textAlign = 'right';

    if (Fai.top._mobiSiteTitle.align == 0) {
        textAlign = '';
    } else if (Fai.top._mobiSiteTitle.align == 1) {
        textAlign = 'left';
    } else if (Fai.top._mobiSiteTitle.align == 2) {
        textAlign = 'center';
    }

    pageHeader.css('text-align', textAlign);
    pageHeader.css('padding', '');
    pageTitle.css('max-width', ''); //初始化样式--end--

    var headerWidth = parseInt(pageHeader.width()),
        logoWidth = parseInt(pageLogo.width()),
        logoMarginLeft = parseInt(pageLogo.css('margin-left').replace('px', '')),
        logoMarginRight = parseInt(pageLogo.css('margin-right').replace('px', '')),
        headerPaddingLeft = parseInt(pageHeader.css('padding-left').replace('px', '')),
        headerPaddingRight = parseInt(pageHeader.css('padding-right').replace('px', '')),
        titlePaddingLeft = parseInt(pageTitle.css('padding-left').replace('px', '')),
        titlePaddingRight = parseInt(pageTitle.css('padding-right').replace('px', '')); //有logo时，对标题进行文字是否过长处理

    if (pageLogo.css('display') != 'none') {
        pageTitle.css(
            'max-width',
            headerWidth -
                logoWidth -
                logoMarginLeft -
                logoMarginRight -
                titlePaddingLeft -
                titlePaddingRight -
                10 +
                'px'
        ); //如果文字是居中的话，而且logo是left或right时，将logo设为absolute定位再进行定位计算。

        if (pageHeader.css('text-align') == 'center' && (pageLogo.hasClass('left') || pageLogo.hasClass('right'))) {
            pageLogo.css({
                position: 'absolute',
                float: 'none',
            });
            pageHeader.css('padding', 0);

            if (pageLogo.hasClass('left')) {
                //header的padding-left是否大于0
                if (headerPaddingLeft > 0) {
                    pageLogo.css('left', headerPaddingLeft + 'px');
                } else {
                    pageLogo.css('left', 0);
                } //当标题文字进入logo范围时

                if (jm('#pageTitle').text() != '' && pageTitle.offset().left < pageLogo.offset().left + logoWidth) {
                    pageHeader.css(
                        'padding',
                        '0 0 0 ' + (headerPaddingLeft + logoMarginLeft + logoMarginRight + logoWidth) + 'px'
                    );
                    pageHeader.css('text-align', 'left');
                }
            } else if (pageLogo.hasClass('right')) {
                //header的padding-right是否大于0
                if (headerPaddingRight > 0) {
                    pageLogo.css('right', headerPaddingRight + 'px');
                } else {
                    pageLogo.css('right', 0);
                } //当标题文字进入logo范围时

                if (
                    jm('#pageTitle').text() != '' &&
                    pageTitle.offset().left + parseInt(pageTitle.width()) > pageLogo.offset().left
                ) {
                    pageHeader.css(
                        'padding',
                        '0 ' + (headerPaddingRight + logoMarginLeft + logoMarginRight + logoWidth) + 'px 0 0'
                    );
                    pageHeader.css('text-align', 'right');
                }
            } //仅当居中时，且logo没有远左远右
        } else if (pageHeader.css('text-align') == 'center') {
            pageHeader.css('padding', 0); //根据logo和标题的位置来重新定位

            if (headerPaddingLeft > 0) {
                var isLogoOver = pageLogo.offset().left <= pageHeader.offset().left + headerPaddingLeft;
                var isTitleOver = pageTitle.offset().left <= pageHeader.offset().left + headerPaddingLeft;
                var isTitleHide = pageTitle.css('display') === 'none'; //fix 手机滑动过程中，logo莫名左对齐 by jser 2017-12-8

                if (isTitleHide) {
                    //如果标题不显示，则不存在越界的概念
                    isTitleOver = false;
                }

                var isOver = isLogoOver || isTitleOver;

                if (isOver) {
                    pageHeader.css('padding', '');
                    pageHeader.css('text-align', 'left');
                }
            } else if (headerPaddingRight > 0) {
                if (
                    pageLogo.offset().left + logoWidth >= pageHeader.offset().left + headerWidth ||
                    pageTitle.offset().left + parseInt(pageTitle.width()) >= pageHeader.offset().left + headerWidth
                ) {
                    pageHeader.css('padding', '');
                    pageHeader.css('text-align', 'right');
                }
            }
        } // 修复pageTitle为空且占了一行的bug ken 20170307
        // 如果title在logo下面且为空，隐藏title

        if (pageLogo.height() <= pageTitle.offset().top && pageTitle.text() == '') {
            pageTitle.addClass('fk-pageTitleFix');

            if (VITE_APP_MODE !== 'visitor') {
                autoFixGWebTop(Fai.top._templateLayoutId);
            }
        } else {
            pageTitle.removeClass('fk-pageTitleFix');
        }
    } else {
        //无logo时，对标题进行文字是否过长处理
        pageTitle.css('max-width', headerWidth - titlePaddingLeft - titlePaddingRight + 'px');

        if (pageHeader.css('text-align') == 'center') {
            pageHeader.css('padding', 0); //header有padding值时，如果文字长度达到该padding区域的范围时

            if (headerPaddingLeft > 0 && pageTitle.offset().left <= pageHeader.offset().left + headerPaddingLeft) {
                pageHeader.css('padding', '');
            } else if (
                headerPaddingRight > 0 &&
                parseInt(pageTitle.width()) >= pageHeader.offset().left + headerWidth - headerPaddingRight
            ) {
                pageHeader.css('padding-right', '1.5rem');
            }
        }
    }
};
/**********设置MObi标题栏目背景**********/

export const setShowSiteTitleBgFlag = function (flag) {
    var webHeader = jm('#webHeader');

    if (Fai.top._templateFrameId >= 2000 && Fai.top._templateFrameId < 3000) {
        return;
    }
    let showSiteTitleBgGloable = Mobi.showSiteTitleBgGloable;
    if (flag == 0) {
        showSiteTitleBgGloable.showSiteTitleBgFlag = true;
        webHeader.removeClass('mobiTitleClose');
    } else {
        showSiteTitleBgGloable.showSiteTitleBgFlag = false;
        webHeader.addClass('mobiTitleClose');
    }

    setTimeout(function () {
        changeTempCountSiteBgHeight();
    }, 1000);
};

export const changeTempCountSiteBgHeight = function () {
    if (Fai.top._templateFrameId >= 2000 && Fai.top._templateFrameId < 3000) {
        return;
    }

    if (jm('#headerBg').length < 1) {
        return;
    }
    let showSiteTitleBgGloable = Mobi.showSiteTitleBgGloable;
    var headerBg = jm('#headerBg'),
        headerWhiteBg = jm('#headerWhiteBg'),
        webHeader = jm('#webHeader'),
        //  动态计算webHeader 的高度
        height = parseFloat(webHeader.height()),
        paddingTop = parseFloat(webHeader.css('padding-top')),
        paddingBottom = parseFloat(webHeader.css('padding-bottom')),
        //  -width 兼容火狐和IE
        borderTop = parseFloat(webHeader.css('border-top-width')),
        borderBottom = parseFloat(webHeader.css('border-bottom-width')),
        allNum = height + paddingTop + paddingBottom + borderTop + borderBottom,
        all = allNum.toString() + 'px';
    headerBg.css('height', allNum / window._htmlFontSize + 'rem');

    if (paddingBottom > 0) {
        headerBg.css('padding-bottom', paddingBottom / window._htmlFontSize + 'rem');
    }

    headerWhiteBg.css('height', allNum / window._htmlFontSize + 'rem');
    countSiteTitleBgHeiht = all;

    if (showSiteTitleBgGloable.bgType !== 'undefined' && showSiteTitleBgGloable.bgType != 'null') {
        changeSiteTitleBg(
            showSiteTitleBgGloable.bgType,
            showSiteTitleBgGloable.bgImgStyle,
            showSiteTitleBgGloable.bgFontColor,
            showSiteTitleBgGloable.bgFontAlpha,
            showSiteTitleBgGloable.linkCoverPreViewPath
        );
    }
};

export const changeSiteTitleBg = function (bgType, bgImgStyle, bgFontColor, bgFontAlpha, linkCoverPreViewPath) {
    let showSiteTitleBgGloable = Mobi.showSiteTitleBgGloable;
    showSiteTitleBgGloable.bgType = bgType;
    showSiteTitleBgGloable.bgImgStyle = bgImgStyle;
    showSiteTitleBgGloable.bgFontColor = bgFontColor;
    showSiteTitleBgGloable.bgFontAlpha = bgFontAlpha;
    showSiteTitleBgGloable.linkCoverPreViewPath = linkCoverPreViewPath; //  动态计算背景的长度

    var headerBg = jm('#headerBg');
    var webHeader = jm('#webHeader');
    var headerWhiteBg = jm('#headerWhiteBg');

    if (bgType != null && bgType != '') {
        if (bgType == 1) {
            // 设置标题栏隐藏
            headerBg.css('background', 'transparent');
            headerBg.css('background-color', '#fff');
            headerBg.css('opacity', '1');
            headerBg.css('filter', '100');

            if (showSiteTitleBgGloable.showSiteTitleBgFlag) {
                headerBg.css('display', 'block');
                headerWhiteBg.css('display', 'block');
            } else {
                headerBg.css('display', 'none');
                headerWhiteBg.css('display', 'none');
            }
        } else if (bgType == 2) {
            headerBg.css('background-position', '');
            headerBg.css('background-size', '');
            headerBg.css('background-repeat', '');
            headerBg.css('background-color', '');
            headerBg.css('background-image', '');
            var isGradient = typeof bgFontColor == 'string' && bgFontColor.indexOf('linear-gradient') >= 0; // 自定义设置标题栏
            // 自定义图片没有初始化时检查

            if (linkCoverPreViewPath != null && linkCoverPreViewPath != '') {
                headerBg.css('background-image', 'url(' + linkCoverPreViewPath + ')');
            }

            if (isGradient && linkCoverPreViewPath != null && linkCoverPreViewPath != '') {
                headerBg.css('background-image', 'url(' + linkCoverPreViewPath + '),' + bgFontColor);
            } else if (isGradient) {
                headerBg.css('background-image', bgFontColor);
            } else {
                headerBg.css('background-color', bgFontColor);
            }

            if (bgImgStyle == 2) {
                // 自适应
                headerBg.css('opacity', bgFontAlpha / 100);
                headerBg.css('background-size', 'cover');
                headerBg.css('background-repeat', 'inherit');
                headerBg.css('filter', bgFontAlpha);
            } else if (bgImgStyle == 3) {
                // 拉伸
                headerBg.css('opacity', bgFontAlpha / 100);
                headerBg.css('background-size', '100% 100%');
                headerBg.css('background-repeat', 'inherit');
                headerBg.css('filter', bgFontAlpha);
            } else if (bgImgStyle == 4) {
                // 平铺
                headerBg.css('opacity', bgFontAlpha / 100);
                headerBg.css('background-size', 'inherit');
                headerBg.css('background-repeat', 'repeat');
                headerBg.css('filter', bgFontAlpha);
            } else if (bgImgStyle == 5) {
                // 不平铺
                headerBg.css('opacity', bgFontAlpha / 100);
                headerBg.css('background-size', 'inherit');
                headerBg.css('background-repeat', 'no-repeat');
                headerBg.css('filter', bgFontAlpha);
            } else if (bgImgStyle == 6) {
                // 等比例
                headerBg.css('background-repeat', 'no-repeat');
                headerBg.css('background-position', 'center center');
                headerBg.css('background-size', 'contain');
                headerBg.css('filter', bgFontAlpha);
            } else if (bgImgStyle == 7) {
                headerBg.css('background-repeat', 'repeat-x');
                headerBg.css('background-position', 'center top');
                headerBg.css('filter', bgFontAlpha);
            } else if (bgImgStyle == 8) {
                headerBg.css('background-repeat', 'repeat-x');
                headerBg.css('background-position', 'center center');
                headerBg.css('filter', bgFontAlpha);
            } else if (bgImgStyle == 9) {
                headerBg.css('background-repeat', 'repeat-x');
                headerBg.css('background-position', 'center bottom');
                headerBg.css('filter', bgFontAlpha);
            } else if (bgImgStyle == 10) {
                headerBg.css('background-repeat', 'repeat-y');
                headerBg.css('background-position', 'left center');
                headerBg.css('filter', bgFontAlpha);
            } else if (bgImgStyle == 11) {
                headerBg.css('background-repeat', 'repeat-y');
                headerBg.css('background-position', 'center center');
                headerBg.css('filter', bgFontAlpha);
            } else if (bgImgStyle == 12) {
                headerBg.css('background-repeat', 'repeat-y');
                headerBg.css('background-position', 'right center');
                headerBg.css('filter', bgFontAlpha);
            } else if (bgImgStyle == 13) {
                headerBg.css('background-repeat', 'no-repeat');
                headerBg.css('background-position', 'center top');
                headerBg.css('background-size', '100% 100%');
                headerBg.css('filter', bgFontAlpha);
            } else if (bgImgStyle == 14) {
                headerBg.css('background-repeat', 'no-repeat');
                headerBg.css('background-position', 'center center');
                headerBg.css('filter', bgFontAlpha);
            } else if (bgImgStyle == 15) {
                headerBg.css('background-repeat', 'no-repeat');
                headerBg.css('background-position', 'left top');
                headerBg.css('filter', bgFontAlpha);
            } else if (bgImgStyle == 16) {
                headerBg.css('background-repeat', 'no-repeat');
                headerBg.css('background-position', 'center top');
                headerBg.css('filter', bgFontAlpha);
            } else if (bgImgStyle == 17) {
                headerBg.css('background-repeat', 'no-repeat');
                headerBg.css('background-position', 'right top');
                headerBg.css('filter', bgFontAlpha);
            } else if (bgImgStyle == 18) {
                headerBg.css('background-repeat', 'no-repeat');
                headerBg.css('background-position', 'left center');
                headerBg.css('filter', bgFontAlpha);
            } else if (bgImgStyle == 19) {
                headerBg.css('background-repeat', 'no-repeat');
                headerBg.css('background-position', 'right center');
                headerBg.css('filter', bgFontAlpha);
            } else if (bgImgStyle == 20) {
                headerBg.css('background-repeat', 'no-repeat');
                headerBg.css('background-position', 'left bottom');
                headerBg.css('filter', bgFontAlpha);
            } else if (bgImgStyle == 21) {
                headerBg.css('background-repeat', 'no-repeat');
                headerBg.css('background-position', 'center bottom');
                headerBg.css('filter', bgFontAlpha);
            } else if (bgImgStyle == 22) {
                headerBg.css('background-repeat', 'no-repeat');
                headerBg.css('background-position', 'right bottom');
                headerBg.css('filter', bgFontAlpha);
            } else {
                // 默认不显示图片
                !isGradient && headerBg.css('background-image', 'none');
                headerBg.css('filter', bgFontAlpha);
                headerBg.css('opacity', bgFontAlpha / 100);
            }

            if (showSiteTitleBgGloable.showSiteTitleBgFlag) {
                headerBg.css('display', 'block');
                headerWhiteBg.css('display', 'block');
            } else {
                headerBg.css('display', 'none');
                headerWhiteBg.css('display', 'none');
            }
        } else {
            // 默认，就是去掉标题栏背景这个div
            headerBg.css('display', 'none');
            headerWhiteBg.css('display', 'none');
            webHeader.css('background-image', '');
            webHeader.css('background-color', '');
        }
    }

    if (Fai.top._templateFrameId >= 2000 && Fai.top._templateFrameId < 3000) {
        return;
    }

    window.onload = function () {
        changeTempCountSiteBgHeight();
    };

    countSiteTitleBgHeiht = webHeader.height();
    headerBg.css('height', countSiteTitleBgHeiht / window._htmlFontSize + 'rem');
    headerWhiteBg.css('height', countSiteTitleBgHeiht / window._htmlFontSize + 'rem');
};
