import { deviceTypeIsMobi } from '@/features/global-mobi-function/methods/linkSetting';
import { isWechat } from '../browser';
import { encodeHtml, isPC } from '../util';

import { mobiAlert } from '@/shared/tips';
import { mobiShowScroll, mobiHideScroll } from '@/shared/util';
export function openQQ(accout) {
    if (VITE_APP_MODE !== 'visitor') {
        Fai.ing('管理态不支持跳转外部链接，请通过“预览”进行访问', true);
        return;
    }
    let href = getQQUrl(accout);
    window.location.href = href;
}

export function showPhonesBg() {
    var phoneBox = jm('.J_showMorePhone');

    if (phoneBox.length < 1) {
        return;
    }

    phoneBox.on('click.phone', function () {
        showJumpPhone();
    });
}

//如果有多个客服QQ，则弹出多个
export function showQqBg() {
    var qqBox = jm('.J_showMoreQq');

    if (qqBox.length < 1) {
        return;
    }

    qqBox.on('click.qq', function () {
        showMoreQQ();
    });
}

export function showMoreQQ() {
    var qqInfo = Fai.top._onlineServiceJson.tcInfo || Fai.top._onlineServiceJson.qq.qqInfo,
        qqType = 5;
    jm('#qqDiv').remove();
    if (qqInfo.length >= 1) {
        mobiHideScroll();
        var bh = document.body.clientHeight;
        var bw = document.body.clientWidth;
        var parent = document.getElementById('g_web');
        var parentNode_div = document.createElement('div');
        var childNode_bg = document.createElement('div');
        var childNode_tables = document.createElement('div');
        var scrollTable = document.createElement('div');
        parentNode_div.id = 'qqDiv';
        childNode_bg.id = 'fullbg';
        childNode_tables.id = 'tablesDiv';
        scrollTable.id = 'scrollTable';
        parentNode_div.className = 'qqDiv';
        childNode_tables.className = 'tablesDiv';
        childNode_bg.className = 'qqFullBg';
        scrollTable.className = 'scrollTable';

        parent.appendChild(parentNode_div);
        var parentDiv = document.getElementById('qqDiv');

        parentDiv.appendChild(childNode_bg);
        parentDiv.appendChild(childNode_tables);
        childNode_tables.appendChild(scrollTable);
        var fullbgDiv = document.getElementById('fullbg');
        var tablesDiv = document.getElementById('tablesDiv');
        var scrollTableDiv = document.getElementById('scrollTable');

        fullbgDiv.style.height = bh + 'px';
        fullbgDiv.style.width = bw + 'px';
        fullbgDiv.style.position = 'fixed';

        let target = isWechat() ? '_self' : '_blank';

        //获取数据，循环
        for (var i = 0; i < qqInfo.length; i++) {
            var num = i + 1;
            var name = encodeHtml(qqInfo[i].name);
            var number = qqInfo[i].number;
            var fullTableDiv = document.createElement('div');

            fullTableDiv.id = 'fullTableDiv' + num;
            fullTableDiv.className = 'fullTableDiv';
            scrollTableDiv.appendChild(fullTableDiv);
            fullTableDiv.innerHTML = `
                                        <a id="numberHref_${qqType}${num}" target="${target}" href="${
                isPC() ? getQQUrl(number) : `javascript:Mobi.openQQ(${number});`
            }">
                                    `;

            var numberHref = document.getElementById('numberHref_' + qqType + num);

            var childNode_table = document.createElement('div');
            childNode_table.id = 'tableDiv';
            childNode_table.className = 'tableBox tableDiv';
            numberHref.appendChild(childNode_table);

            var qName = document.createElement('div');
            var qNumber = document.createElement('div');
            var qOperation = document.createElement('div');
            var qLine = document.createElement('div');
            var qOperImg = document.createElement('div');

            qName.className = 'qqName qqbox';
            qName.id = 'qqName' + num;
            qNumber.className = 'qqNumber qqbox';
            qNumber.id = 'qqNumber' + num;
            qOperation.className = 'qqOperation qqbox';
            qOperation.id = 'qqOperation' + num;
            qLine.id = 'qqLine';
            qLine.className = 'qqLine';
            qOperImg.id = 'qqOperImg' + num;
            qOperImg.className = 'qqOperImg icon-qqOperImg';

            childNode_table.appendChild(qName);
            childNode_table.appendChild(qNumber);
            childNode_table.appendChild(qOperation);
            scrollTableDiv.appendChild(qLine);
            qOperation.appendChild(qOperImg);

            var qqName = document.getElementById('qqName' + num);
            var qqNumber = document.getElementById('qqNumber' + num);

            var insertText = document.createTextNode(name);
            qqName.appendChild(insertText);
            qqNumber.innerHTML = number;
        }

        var qCancle = document.createElement('div');
        var cancleClick = document.createElement('div');
        qCancle.id = 'qCancle';
        qCancle.className = 'qCancle';
        cancleClick.id = 'qqCancle';
        cancleClick.className = 'qqCancle';
        tablesDiv.appendChild(qCancle);
        qCancle.appendChild(cancleClick);
        var cancleId = document.getElementById('qqCancle');
        // cancleId.innerHTML = "取消";
        cancleId.innerHTML = LS.cancel;

        var viewHeight = document.documentElement.clientHeight - cancleId.offsetHeight;
        var divHeight = scrollTableDiv.offsetHeight;

        if (divHeight > viewHeight) {
            scrollTable.style.height = document.documentElement.clientHeight - cancleId.offsetHeight + 'px';
        }

        // 名字宽度自适应
        let nameMaxWidth = 0;
        const $qqNameList = jm('#qqDiv').find('.qqName');
        $qqNameList.forEach((el) => {
            const offset = jm(el).offset();
            if (offset.width > nameMaxWidth) {
                nameMaxWidth = offset.width;
            }
        });

        if (nameMaxWidth != 0) {
            $qqNameList.forEach((el) => {
                jm(el).css('width', nameMaxWidth + 'px');
            });
        }

        //div逐渐打开
        setTimeout(function () {
            tablesDiv.style.cssText = 'bottom: 0; ';
        }, 10);
        setTimeout(function () {
            fullbgDiv.style.opacity = '0.3';
        }, 10);

        fullbgDiv.onclick = function () {
            var tmp_fullbgDiv = document.getElementById('qqDiv');
            tablesDiv.style.cssText = 'bottom: -100%';
            fullbgDiv.style.opacity = '0';
            mobiShowScroll();
            setTimeout(function () {
                tmp_fullbgDiv.parentNode.removeChild(tmp_fullbgDiv);
            }, 100);
        };
        cancleId.onclick = function () {
            var tmp_fullbgDiv = document.getElementById('qqDiv');
            tablesDiv.style.cssText = 'bottom: -100%';
            fullbgDiv.style.opacity = '0';
            mobiShowScroll();
            setTimeout(function () {
                tmp_fullbgDiv.parentNode.removeChild(tmp_fullbgDiv);
            }, 100);
        };
    }
}

export function getQQUrl(accout = '') {
    accout = typeof accout === 'string' ? accout.trim() : accout; // 去除头尾空格
    let href = `//wpa.qq.com/msgrd?v=3&uin=${accout}&site=qq&menu=yes`;

    if (!isWechat() && !isPC()) {
        //手机普通浏览器用这个
        href = `mqqwpa://im/chat?chat_type=wpa&uin=${accout}`;
        if (jm.os.ios) {
            href = `mqqwpa://im/chat?chat_type=wpa&uin=${accout}&version=1&src_type=web&web_src=oicqzone.com`;
        }
    }
    return href;
}

//弹出客服电话列表
export function showJumpPhone() {
    var phoneInfo = Fai.top._onlineServiceJson.phone.phoneInfo;

    if (!phoneInfo) {
        return;
    }

    if (phoneInfo.length >= 1) {
        var bh = document.body.clientHeight;
        var bw = document.body.clientWidth;
        var parent = document.getElementById('g_web');
        var parentNode_div = document.createElement('div');
        var childNode_bg = document.createElement('div');
        var childNode_tables = document.createElement('div');
        var scrollTable = document.createElement('div');
        var iScroll_div = document.createElement('div');

        parentNode_div.id = 'phoneDiv';
        childNode_bg.id = 'fullbg';
        childNode_tables.id = 'tablesDiv';
        scrollTable.id = 'scrollTable';
        parentNode_div.className = 'phoneDiv';
        childNode_tables.className = 'tablesDiv';
        childNode_bg.className = 'phoneFullBg';
        scrollTable.className = 'scrollTable';

        iScroll_div.id = 'iScrollDiv';
        parent.appendChild(parentNode_div);
        var parentDiv = document.getElementById('phoneDiv');

        parentDiv.appendChild(childNode_bg);
        parentDiv.appendChild(childNode_tables);
        childNode_tables.appendChild(scrollTable);
        var fullbgDiv = document.getElementById('fullbg');
        var tablesDiv = document.getElementById('tablesDiv');
        var scrollTableDiv = document.getElementById('scrollTable');
        scrollTableDiv.appendChild(iScroll_div);
        var iScrollDiv = document.getElementById('iScrollDiv');
        fullbgDiv.style.height = bh + 'px';
        fullbgDiv.style.width = bw + 'px';
        fullbgDiv.style.position = 'fixed';

        //获取数据，循环
        for (var i = 0; i < phoneInfo.length; i++) {
            var num = i + 1;
            var name = encodeHtml(phoneInfo[i].name);
            var number = phoneInfo[i].number;
            var fullTableDiv = document.createElement('div');

            fullTableDiv.id = 'fullTableDiv' + num;
            fullTableDiv.className = 'fullTableDiv';
            iScrollDiv.appendChild(fullTableDiv);
            fullTableDiv.innerHTML =
                "<a id='numberHref_" +
                Fai.top._onlineServiceJson.phone.type +
                num +
                "' href='javascript:Mobi.triggerServiceNumber(1, \"" +
                number +
                '");\'>';
            var numberHref = parentDiv.getElementsByClassName('fullTableDiv')[i].getElementsByTagName('a')[0];

            var childNode_table = document.createElement('div');
            childNode_table.id = 'tableDiv';
            childNode_table.className = 'tableBox tableDiv';
            numberHref.appendChild(childNode_table);

            var pName = document.createElement('div');
            var pNumber = document.createElement('div');
            var pOperation = document.createElement('div');
            var pLine = document.createElement('div');
            var pOperImg = document.createElement('div');

            pName.className = 'phoneName phonebox';
            pName.id = 'phoneName' + num;
            pNumber.className = 'phoneNumber phonebox';
            pNumber.id = 'phoneNumber' + num;
            pOperation.className = 'phoneOperation phonebox';
            pOperation.id = 'phoneOperation' + num;
            pLine.id = 'phoneLine';
            pLine.className = 'phoneLine';
            pOperImg.id = 'phoneOperImg' + num;
            pOperImg.className = 'phoneOperImg icon-phoneOperImg';

            childNode_table.appendChild(pName);
            childNode_table.appendChild(pNumber);
            childNode_table.appendChild(pOperation);
            iScrollDiv.appendChild(pLine);
            pOperation.appendChild(pOperImg);

            var phoneName = document.getElementById('phoneName' + num);
            var phoneNumber = document.getElementById('phoneNumber' + num);

            var insertText = document.createTextNode(name);
            phoneName.appendChild(insertText);
            phoneNumber.innerHTML = number;

            if (!jm.os.supportsTouch) {
                numberHref.onclick = function () {
                    mobiAlert('该功能无法在桌面浏览器中启动');
                };
            }
        }

        var pCancle = document.createElement('div');
        var cancleClick = document.createElement('div');
        pCancle.id = 'pCancle';
        pCancle.className = 'pCancle';
        cancleClick.id = 'phoneCancle';
        cancleClick.className = 'phoneCancle';
        tablesDiv.appendChild(pCancle);
        pCancle.appendChild(cancleClick);
        var cancleId = document.getElementById('phoneCancle');
        //cancleId.innerHTML = "取消";
        cancleId.innerHTML = LS.cancel;

        var viewHeight = document.documentElement.clientHeight - cancleId.offsetHeight;
        var divHeight = scrollTableDiv.offsetHeight;
        //判断div的高度是否超出可视区高度
        if (divHeight > viewHeight) {
            scrollTable.style.height = document.documentElement.clientHeight - cancleId.offsetHeight + 'px';
        }
        if (deviceTypeIsMobi()) {
            new iScroll('scrollTable');
        }
        //div逐渐打开
        setTimeout(function () {
            tablesDiv.style.cssText = 'bottom: 0;';
        }, 10);
        setTimeout(function () {
            fullbgDiv.style.opacity = '0.3';
        }, 10);

        // 名字宽度自适应
        let nameMaxWidth = 0;
        const $phoneNameList = jm('#phoneDiv').find('.phoneName');
        $phoneNameList.forEach((el) => {
            const offset = jm(el).offset();
            if (offset.width > nameMaxWidth) {
                nameMaxWidth = offset.width;
            }
        });

        if (nameMaxWidth != 0) {
            $phoneNameList.forEach((el) => {
                jm(el).css('width', nameMaxWidth + 'px');
            });
        }

        fullbgDiv.onclick = function () {
            var tmp_fullbgDiv = document.getElementById('phoneDiv');
            tablesDiv.style.cssText = 'bottom: -100%';
            fullbgDiv.style.opacity = '0';
            setTimeout(function () {
                tmp_fullbgDiv.parentNode.removeChild(tmp_fullbgDiv);
            }, 100);
        };
        cancleId.onclick = function () {
            var tmp_fullbgDiv = document.getElementById('phoneDiv');
            tablesDiv.style.cssText = 'bottom: -100%';
            fullbgDiv.style.opacity = '0';
            setTimeout(function () {
                tmp_fullbgDiv.parentNode.removeChild(tmp_fullbgDiv);
            }, 100);
        };
    }
}

//判断是否接入百度商桥
export function isOpenBaiduBridge() {
    var baiduBridge = window._hmt;
    var isOpen = false;
    if (typeof baiduBridge != 'undefined') {
        var baiduBridgeId = baiduBridge.id;
        if (typeof baiduBridgeId != 'undefined') {
            isOpen = true;
        }
    }
    return isOpen;
}
