import { logJZFdp } from '@/api/logDog';
import { mobiIng, parseFileSize } from '@/shared/fai';
import { slideshowView } from '@/shared/photo/slides.js';
import { request } from '@/api/request';
import { JZUpload } from '@jz/biz-shared';

const defaultFileTypes = [
    {
        id: 0,
        name: '图片类',
        list: [
            {
                type: 0,
                name: 'JPG',
            },
            {
                type: 1,
                name: 'JPEG',
            },
            {
                type: 2,
                name: 'PNG',
            },
            {
                type: 3,
                name: 'PDF',
            },
            {
                type: 4,
                name: 'GIF',
            },
        ],
    },
    {
        id: 1,
        name: '文档类',
        list: [
            {
                type: 5,
                name: 'DOCX',
            },
            {
                type: 6,
                name: 'TXT',
            },
            {
                type: 7,
                name: 'HTML',
            },
            {
                type: 8,
                name: 'PPTX',
            },
            {
                type: 9,
                name: 'RAR',
            },
        ],
    },
    {
        id: 2,
        name: '音频类',
        list: [
            {
                type: 10,
                name: 'MP3',
            },
            {
                type: 11,
                name: 'MP4',
            },
            {
                type: 12,
                name: 'WAV',
            },
            {
                type: 13,
                name: 'WMA',
            },
            {
                type: 14,
                name: 'FLAC',
            },
        ],
    },
];

export const memberFileUpload = ({ moduleId, fileSizeLimit, fileUpInfo, propId, previewLinkInfo }) => {
    let $fileUploadBtn = jm(`#module${moduleId} #member_file_contentwrap-${propId} .member_file_btn`);

    if (VITE_APP_MODE !== 'visitor') {
        $fileUploadBtn.on('click', function () {
            mobiIng('当前为管理状态，提交无效。');
        });
        return;
    }
    initFileDetail(previewLinkInfo, propId, moduleId);
    initDeleteFileEvent(moduleId, propId);
    let fileTypeList = '*.*';
    if (fileUpInfo.fupt) {
        let selectList = fileUpInfo.sfl || [];
        let customFileTypes = fileUpInfo.cft || [];
        let allFileTypes = defaultFileTypes.map((item) => item.list);
        allFileTypes.push(customFileTypes);
        allFileTypes = allFileTypes.reduce((cur, next) => {
            return cur.concat([...next]);
        }, []);
        let finalTypeList = allFileTypes.filter((item) => selectList.some((i) => i == item.type));
        //自定义类型
        fileTypeList = finalTypeList.map((item) => `*.${item.name};`).join('');
        fileTypeList = fileTypeList.toLocaleLowerCase();
    }

    JZUpload.initUploadifyButton({
        dom: $fileUploadBtn[0],
        onChange: async (e) => {
            let file = e.target.files[0];
            let jzUpload = new JZUpload({
                getAccessKeyUrl: '/api/manage/advanceUpload/genAccessKey', // 请求库、请求路径、请求行为

                onError(error) {
                    mobiIng('文件:' + file.name + '，' + error.message);
                    jm($fileUploadBtn[0]).find('.selectbtn').val('');
                },
                onComplete(data) {
                    mobiIng('文件: ' + file.name + ' 上传成功', true);
                    let md5 = jm.md5(file.name + file.size + file.type + file.lastModified);
                    onFileUploadEvent(
                        {
                            fileMd5: md5,
                            ...data,
                        },
                        moduleId,
                        propId
                    );
                    logJZFdp('jz_c_vipfiles_submit', {
                        jz_free_text_0: getLogFileSizeStr(file.size),
                        jz_content_terminal: 'mobi',
                        jz_site_id: Fai.top._siteId,
                    });
                    jm($fileUploadBtn[0]).find('.selectbtn').val('');
                },
                onNext(info) {
                    const percent = Math.ceil(info.total.percent);
                    if (percent == 100) {
                        mobiIng(LS.mobiFormSubmitFileUploadIng + percent + '%', true);
                    } else {
                        mobiIng(LS.mobiFormSubmitFileUploadIng + percent + '%', false, true);
                    }
                },
                // faiupload的params
                params: {
                    aid: Fai.top._aid,
                    folderId: Fai.top._siteId,
                    // 业务参数
                    bssInfo: {
                        fromSite: true,
                        siteId: Fai.top._siteId || 0,
                        fileSizeLimit: fileSizeLimit,
                    },
                    accessKeyInfo: {
                        // 是否上传到临时目录 TODO
                        isTmp: true,
                    },
                },
                tipsFn(string) {
                    mobiIng(string, true);
                },
                fileTypeExts: fileTypeList,
            });
            // 开始上传
            jzUpload.upload(file);
        },
        buttonText: ' ',
        fileTypeExts: fileTypeList,
    });
};
function initDeleteFileEvent(moduleId, propId) {
    let moduleDom = jm(`#module${moduleId} #member_file_contentwrap-${propId}`),
        detailDom = moduleDom.find('.member_file_content'),
        uploadDom = moduleDom.find('.member_file_btn');
    moduleDom.find('.member_file_close').on('click', function () {
        delete MemberFileUtil.fileObj[propId];
        moduleDom.find(`input[data-name='${propId}']`).attr('value', '');
        detailDom && detailDom.removeClass('member_file_content-show');
        uploadDom && uploadDom.removeClass('member_file_btn-hide');
        uploadDom && uploadDom.find('span').text('+ 添加文件');
    });
}
function onFileUploadEvent(args, moduleId, propId) {
    let file_name = args.name, //返回文件名
        file_size = parseFileSize(args.size); //返回文件大小
    let moduleDom = jm(`#module${moduleId} #member_file_contentwrap-${propId}`),
        nameDom,
        sizeDom,
        uploadDom,
        previewDom,
        downloadDom,
        detailDom;

    (detailDom = moduleDom.find('.member_file_content')),
        (nameDom = moduleDom.find('.member_file_name')),
        (sizeDom = moduleDom.find('.member_file_size')),
        (uploadDom = moduleDom.find('.member_file_btn')),
        (previewDom = moduleDom.find('.member_file_preview_link')),
        (downloadDom = moduleDom.find('.member_file_download_link'));

    detailDom && detailDom.addClass('member_file_content-show');
    uploadDom && uploadDom.addClass('member_file_btn-hide');
    uploadDom && uploadDom.find('span').text('修改文件');
    nameDom && nameDom.text(file_name);
    sizeDom && sizeDom.text(file_size);
    MemberFileUtil.fileObj[propId] = args;
    moduleDom.find(`input[data-name='${propId}']`).attr('value', args.fileId);
    // 后台反馈pm上传没保存的时候，是不可以下载和预览的，先关闭。
    // downloadDom.attr('href', args.du);
    // initPreviewImgHref(args.path, args.fileId, previewDom, file_name, moduleId);
    downloadDom.hide();
    previewDom.hide();
}
function initPreviewImgHref(path, fileId, previewDom, fileName, moduleId) {
    if (/(jpg|jpeg|png|gif)$|(jpg|jpeg|png|gif)\?v=|(jpg|jpeg|png|gif)\?_tm=/.test(fileName)) {
        initPreviewImgEvent(path, previewDom, moduleId);
    } else if (/\.(pdf|PDF)$/.test(fileName)) {
        initPdfPreview(path, previewDom);
    } else {
        initNormalPreviewEvent(previewDom, fileId);
    }
}
function initPreviewImgEvent(path, previewDom, moduleId) {
    previewDom.attr('href', 'javascript:void(0);');
    previewDom.off('click.previewImg').on('click.previewImg', function (e) {
        e.preventDefault();
        e.stopPropagation();
        slideshowView({
            id: `${moduleId}`,
            previewSrcList: [
                {
                    src: path,
                    title: '',
                },
            ],
            initialIndex: 0,
            logEventConfig: {
                event: 'jz_hdpzj',
                properties: {
                    jz_content_terminal: 'mobi-会员中心-我的资料',
                    jz_version: Fai.top.jzVersion,
                },
            },
        });
    });
}

function initPdfPreview(path, previewDom) {
    previewDom.off('click.previewImg');
    previewDom.attr('href', path);
}

function initNormalPreviewEvent(previewDom, fileId) {
    previewDom.off('click.previewImg');
    previewDom.attr('href', `/view.jsp?fileID=${fileId}`);
}

function getLogFileSizeStr(size) {
    let _1M = 1024 * 1024,
        _50M = _1M * 50,
        _200M = _1M * 200;
    if (size <= _50M) {
        return '小于等于50m';
    } else if (size <= _200M) {
        return '大于50m小于等于200m';
    } else {
        return '大于200m';
    }
}

export const MemberFileUtil = {
    fileObj: {}, //用户上传文件时，接口返回的数据
    saveMemberFile() {
        const data = {};
        let fileList = [];
        Object.keys(this.fileObj).forEach((key) => {
            fileList.push(this.fileObj[key]);
        });
        data.fileList = JSON.stringify(fileList);
        //保存用户上传的临时文件。
        return request.post('/ajax/member_h.jsp?cmd=setWafCk_saveMemberFile', {
            data,
        });
    },
};

function initFileDetail(previewLinkInfo, propId, moduleId) {
    //转换文件大小单位
    if (window._colInfo.id == 12) {
        let moduleDom = jm(`#module${moduleId} #member_file_contentwrap-${propId}`);
        let sizeDom = moduleDom.find(`.member_file_size`);
        setTimeout(() => {
            //由于Fai.min.js还没加载所以要setTimeout
            sizeDom.text(parseFileSize(parseInt(sizeDom.text())));
        });
        if (previewLinkInfo.fileId && previewLinkInfo.filePath) {
            initPreviewImgHref(
                previewLinkInfo.filePath,
                previewLinkInfo.fileId,
                moduleDom.find('.member_file_preview_link'),
                previewLinkInfo.fileName,
                moduleId
            );
        }
    }
}
