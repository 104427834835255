<template>
    <sign-up-three v-if="openThemeV3" :module="module" :module-id="moduleId"></sign-up-three>
    <sign-up-two v-else :module="module" :module-id="moduleId"></sign-up-two>
</template>
<script>
import SignUpTwo from './signupV2.vue';
import SignUpThree from './signupV3.vue';
import { mapGetters, mapState } from 'vuex';
export default {
    name: 'MemberSignUp',
    components: {
        SignUpTwo,
        SignUpThree,
    },
    props: {
        moduleId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapState('app', ['openThemeV3']),
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId);
        },
    },
};
</script>
<style lang="scss">
@import '../signup.scss';
</style>
