import { closeLoading } from '../index';
import { scrollNavFix } from '@/shared/nav';
import { changeMorePosition } from './ajax-load-dom';
import { fixJzmHomeIframeParam, getUrlParam } from '@/shared/url';
import { getMobiOSType, getBrowserType } from '@/shared/browser';
import { copyTipsDom, initTipsMarquee } from './tips-marquee';

import { logDog } from '@/shared/log';
import { unloadForATag, onlineServiceInputWord } from '@/system/onlineService/index';
import { debounce, browser } from '@jz/utils';
import { autoSetNavBarBottom } from '@/shared/nav';
import { logoSizeCompressByMargin, titlePositionRefreshByLogo } from '@/shared/title';
import { initBgmPosition } from '@/shared/bgMusic';
import { getPreviewObject, getPreviewObjectClass } from '@/shared/getPreviewObject';
import { autoFixGWebTop } from '@/shared/autoFixGwebTop';
import { initForms } from './init-forms';
import { navSwipeMenu, showNavItemContainer, removeAllSwipeMenuClass } from '@/system/nav/comm';
import { jumpToModulePosition, jumpAnimation } from '@/features/global-mobi-function/methods/moduleJump';
export function initMobiPage(_templateDef) {
    //Mobi.resetHtmlFontSize();这个函数在最顶部加载了，但因页面存在滚动条，导致计算不正确，所以针对PC再算一次
    if (!jm.os.supportsTouch) {
        resetHtmlFontSize();
    }

    if (!Fai.top._openThemeV3) {
        initTemplate2(_templateDef);
    }

    //online service start
    unloadForATag();
    //online service send

    //调用根据语言环境显示分页按钮的函数

    //拉动浏览器的时候需要重新计算banner高度
    jm(document).ready(function () {
        var windowWidth = jm(window).width();
        window.initialWindowWidth = windowWidth;

        Fai.top.window.onresize = function () {
            if (jm(window).width() != windowWidth) {
                window.initialWindowWidth = windowWidth = jm(window).width();
                resetHtmlFontSize();
                if (!Fai.top._openThemeV3) {
                    logoSizeCompressByMargin();
                    titlePositionRefreshByLogo();
                }
                autoFixGWebTop(Fai.top._templateLayoutId);
                initBgmPosition();
            }
        };
    });

    //修复由于在pageFunctions里写死的fontSize:20.0造成的误差。
    if (!Fai.top._openThemeV3) {
        initForms();
    }

    //修正页面高度小于显示屏高度的 BUG
    //Mobi.fixGwebHieght();
    //控制用户输入时候在线客服隐藏
    onlineServiceInputWord();

    //对某些模板，用户向上滚的时候，收起导航，像下滚的时候展示
    //Mobi.topContentFixedAutoHidden(layoutId);
    closeLoading();

    //页面初始化后发送logDog
    initMobiPageWithLogDog();

    //佳信客服统计 --- 佳信，只是有外链，并不知道加载完回调，在这里能获取到节点，暂时就写在这里吧
    setTimeout(function () {
        jm('#jiaxin-mcs-fixed-btn')
            .off('click.log')
            .on('click.log', function () {
                logDog(200171, 6);
            });
    }, 1000);

    // site文章里面添加弹窗容器链接，然后mobi应用这个文章，就会报错，这里加个空函数保护下
    window.Fai && (window.Fai.top._popupZone = {});
    window.Fai && (window.Fai.top._popupZone.openZone = function () {});
    if (typeof window.Site == 'undefined') {
        window.Site = {};
    }
    window.Site && (window.Site.openSiteForm = function () {});

    // 移除之前生成的百度商桥节点，切换页面会影响
    jm('#newBridge').remove();

    //查看是否有百度商桥的链接控件、有的话输出百度商桥代码
    if (!Fai.top._hmt && jm("a[href*='Mobi.showBaiduBridge']").length > 0) {
        jm('#g_body').append("<div id='baiduBridgeScript'></div>");
        var code = window._baiduBridgeCodes || Fai.top._onlineServiceJson.baiduBridge.codes;
        if (code) {
            jm('#baiduBridgeScript').append(code);
            jm.fkEval.call(window, jm('#baiduBridgeScript script').html());
        }
    }

    // 被jzm的iframe嵌套时，需要加标志
    fixJzmHomeIframeNest();

    // 检查有没有带上Fai锚点
    checkFaiAnchor();

    fixIphoneXFooter();
}

function resetHtmlFontSize() {
    if (VITE_APP_MODE !== 'visitor') {
        return;
    }
    var clientWidth = document.documentElement ? document.documentElement.clientWidth : document.body.clientWidth,
        scrollWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth; //此时再计算一次pc会有滚动条，要把滚动条算进去

    // mac 的 safari 的媒体查询不包括滚动条，故这里修复
    if (getMobiOSType() === 8 && getBrowserType() === 4) {
        scrollWidth = document.documentElement.clientWidth || document.body.clientWidth;
    }

    if (scrollWidth > 768 && scrollWidth < 1280) {
        clientWidth = 768;
    } else if (scrollWidth >= 1280) {
        clientWidth = 512;
    }
    document.documentElement.style.fontSize = (clientWidth * 1) / 16 + 'px';
    //出现滚动条后再计算一次，不然导致_htmlFontSize在js中的值与HTML结构中赋予的值不一致（则rem转化时会有问题）
    Fai.top._htmlFontSize = (clientWidth * 1) / 16;
}

export function initTemplate2(_templateDef) {
    var layoutId = _templateDef.layout,
        moduleStyle = _templateDef.moduleStyle,
        designType = _templateDef.templateDesignType;

    removeAllSwipeMenuClass();

    autoFixGWebTop(layoutId);

    const navBar = getPreviewObject('navbar'),
        navbarList = getPreviewObject('navbarList');
    if (navBar) {
        navBar.setAttribute('style', '');
    }
    if (navbarList) {
        navbarList.setAttribute('style', '');
    }
    jm(getPreviewObject('webTop')).css('height', '');
    if (getPreviewObject('navButton')) {
        if (Fai.top._templateFrameId === 1004 || Fai.top._templateFrameId === 1023) {
            jm(navBar).append(getPreviewObject('navButton'));
        } else {
            jm(getPreviewObject('webHeaderBox')).prepend(getPreviewObject('navButton'));
        }
    }
    if (layoutId == 1) {
        navBar && jm(getPreviewObject('webFooterBox')).append(navBar);
        showNavItemContainer();
        if (designType === 1) {
            navSwipeMenu(layoutId);
        }
    } else if (layoutId == 2 || layoutId == 4 || layoutId == 5 || layoutId == 7) {
        if (layoutId !== 5 && layoutId !== 7) {
            navBar && getPreviewObject('webTop').appendChild(navBar);
        } else {
            navBar && getPreviewObject('webHeaderBox').appendChild(navBar);
        }

        autoSetNavBarBottom(layoutId);
        navSwipeMenu(layoutId);
    } else if (layoutId == 3 || layoutId == 0 || layoutId == 8) {
        navBar && jm(getPreviewObject('webHeaderBox')).append(navBar);
        if (layoutId == 0) {
            showNavItemContainer();
        } else if (layoutId == 8) {
            showNavItemContainer();
            navSwipeMenu(layoutId);
        } else {
            if (designType === 1) {
                navSwipeMenu(layoutId);
            }
        }
    } else if (layoutId == 6) {
        //横幅下面
        navBar && jm(getPreviewObject('webBannerBox')).append(navBar);
        showNavItemContainer();
    }
    autoSetNavBarBottom(layoutId);

    //多语言
    if (getPreviewObject('webMultilingualArea')) {
        if (layoutId == 4) {
            jm(getPreviewObject('webHeaderBox')).append(getPreviewObject('webMultilingualArea'));
        } else {
            jm(getPreviewObject('webBannerBox')).prepend(getPreviewObject('webMultilingualArea'));
        }
    }
    if (
        Fai.top._templateFrameId === 1005 ||
        Fai.top._templateFrameId === 1004 ||
        Fai.top._templateFrameId === 1019 ||
        Fai.top._templateFrameId === 1023 ||
        Fai.top._templateFrameId === 1024 ||
        Fai.top._templateFrameId === 1021
    ) {
        scrollNavFix();
        jm(window).bind('load', function () {
            scrollNavFix();
        });
    }

    scrollHeaderTop();
    changeMorePosition(moduleStyle);

    var fixedAreaBox = jm('#fixedAreaBox');
    if (fixedAreaBox.height() == 0) {
        jm('.J_area_box_wrap').addClass('fixedAreaBoxEmpty');
    }
    /********************  nav  end  *******************/
}

export function topTipMarquee() {
    var mobiTipsMarquee = getPreviewObject('mobiTipsMarquee');

    if (!mobiTipsMarquee) return false;

    var marquee = getPreviewObjectClass('J_marquee')[0],
        tipsDom = mobiTipsMarquee.querySelectorAll('li'),
        marqueeInnerWidth = jm(marquee).width(),
        marqueeFlag = false, // 是否需要触发滚动的标志
        left = [],
        marquee_li = 0;

    for (var i = 0; i < tipsDom.length; i++) {
        left[i] = parseInt(tipsDom[i].offsetWidth);

        if (marqueeInnerWidth > left[i]) {
            //mobiTipsMarquee的面积 与 滚动消息长度的比较
            marquee_li = i == 0 ? marqueeInnerWidth : parseInt(marquee_li) + left[i];
        } else {
            marquee_li = i == 0 ? left[i] : parseInt(marquee_li) + left[i];
        }
    }

    if (tipsDom.length == 1 && left[0] < marqueeInnerWidth) {
        //只有一个滚动tip。并且不超过Ul长度，不滚动。
    } else {
        copyTipsDom(tipsDom, mobiTipsMarquee);
        marqueeFlag = true;
    }

    if (marqueeFlag) {
        initTipsMarquee(marquee_li);
    }
}

//会员注册协议，顶部条滚动固定
function scrollHeaderTop() {
    document.addEventListener(
        'touchstart',
        function () {
            HeaderTopSroll();
        },
        false
    );
    document.addEventListener(
        'touchmove',
        function () {
            HeaderTopSroll();
        },
        false
    );
    document.addEventListener(
        'touchend',
        function () {
            HeaderTopSroll();
        },
        false
    );
    document.addEventListener(
        'touchcancel',
        function () {
            HeaderTopSroll();
        },
        false
    );
    document.addEventListener(
        'scroll',
        function () {
            HeaderTopSroll();
        },
        false
    );

    function HeaderTopSroll() {
        if (document.getElementsByClassName('J_registerHeader').length) {
            var g_body = document.getElementById('g_body'),
                mProPage = document.getElementsByClassName('J_registerHeader')[0],
                isHeaderTop = false;
            var headerOffsetTop = mProPage.offsetTop,
                headerClientHeight = mProPage.clientHeight;

            var scrollTop = g_body.scrollTop;
            if ((scrollTop > headerOffsetTop || scrollTop < headerOffsetTop) && !isHeaderTop) {
                jm(mProPage).addClass('headerScroll_fix');
                isHeaderTop = true;
            }

            if (scrollTop - headerClientHeight < headerOffsetTop && isHeaderTop) {
                jm(mProPage).removeClass('headerScroll_fix');
                isHeaderTop = false;
            }
        }
    }
}

//页面初始话进行logdog by jser 2017-5-24
function initMobiPageWithLogDog() {
    //统计分辨率
    screenResolutionLogDog();

    //进行屏幕分辨率的统计
    function screenResolutionLogDog() {
        var dogId = 200100,
            topDPR = Fai.top.devicePixelRatio,
            topScreen = Fai.top.screen,
            resolution,
            recordKey = 'fkLogDog-screenResolution', //缓存用的键名
            manageDogSrcMap,
            visitDogSrcMap,
            dogSrcMap,
            dogSrc;
        //如果检测过，那么不进行统计
        if (localStorage.getItem(recordKey)) {
            return;
        }

        //构建管理态src映射体
        manageDogSrcMap = {
            '1920*1080': 1,
            '1366*768': 2,
            '1440*900': 3,
            '1600*900': 4,
            other: 5,
            '1680*1050': 6,
            '1280*800': 7,
            '1280*1024': 8,
            '1360*768': 9,
            '1280*720': 10,
            '1024*768': 11,
            '1280*768': 12,
            '1600*1200': 13,
            '1600*1024': 14,
        };
        //构建访客态src映射体
        visitDogSrcMap = {
            '1080*1920': 15,
            '720*1280': 16,
            '480*854': 17,
            '540*960': 18,
            '1080*1812': 19,
            '720*1184': 20,
            '1080*1776': 21,
            '1080*1794': 22,
            '750*1334': 23,
            '1242*2208': 24,
            '640*960': 25,
            '768*1024': 26,
            '1125*2001': 27,
            other: 28,
            '640*1136': 29,
            '1440*2560': 30,
            '1140*2960': 31,
        };
        //转换键名 case: "1080*1920" => 2073600 这个转换实现得很棒
        function translateMapKey(map) {
            var expression, //表达式
                exprArr,
                exprVal;
            //对表达式进行转换
            for (expression in map) {
                if (expression.indexOf('*') > -1) {
                    exprArr = expression.split('*'); //将键名切割
                    exprVal = exprArr[0] * exprArr[1]; //获取表达式的值
                    map[exprVal] = map[expression]; //将表达式键转成数字键
                    delete map[expression]; //删除表达式键名
                }
            }
            return map;
        }
        //获取最终映射体积
        dogSrcMap = VITE_APP_MODE !== 'visitor' ? manageDogSrcMap : visitDogSrcMap;
        //计算分辨率
        resolution = topScreen.width * topDPR * (topScreen.height * topDPR);
        //专业映射体
        dogSrcMap = translateMapKey(dogSrcMap); //将映射体的键名转成数值
        //获取最终src
        dogSrc = dogSrcMap[resolution] || dogSrcMap.other;

        //处理特殊场景（PC电脑浏览手机页面，直接算作other）
        if (browser.isPc() && !(VITE_APP_MODE !== 'visitor')) {
            dogSrc = dogSrcMap.other;
        }

        //发送logdog
        if (dogId && dogSrc) {
            //标志已经检测过
            try {
                //无痕模式下，无法缓存，放弃该场景下的统计
                localStorage.setItem(recordKey, true);
                logDog(dogId, dogSrc);
            } catch (e) {
                return;
            }
        }
    }
}

function checkFaiAnchor() {
    var urlHash = window.location.hash;
    var hashArray = urlHash.substring(1).split('_');

    if (VITE_APP_MODE != 'visitor') {
        try {
            let mAnchorId = localStorage.getItem('mAnchorId');
            if (mAnchorId) {
                setTimeout(() => {
                    jumpAnimation(mAnchorId);
                    localStorage.removeItem('mAnchorId');
                }, 500);
                return;
            }
        } catch (err) {
            console.log('checkFaiAnchor', err);
        }
    }

    if (hashArray[0] != 'fai') {
        return;
    }
    var moduleId = parseInt(hashArray[1]);
    if (moduleId) {
        var jumpToModuleOption = {};
        jumpToModuleOption.jumpModuleId = moduleId;
        jumpToModuleOption.colUrl = '';
        jumpToModuleOption.colId = -1;

        // 这里是为了修复有点dom节点还没渲染的时候，导致定位出错
        setTimeout(function () {
            jumpToModulePosition(jumpToModuleOption);
        }, 1000);
    }
    return;
}

// 修复 使用iphoneX访问时，网站底部导航或其他按钮被手机底部的黑色设置条遮挡。任务ID：ID1001093
function fixIphoneXFooter() {
    if (!isIphoneX()) return;
    if (!checkHasFooter()) return;
    var $body = jm('#g_body');
    $body.addClass('notIsMall');
    fixedFooterHandler();
    jm(window).off('scroll.fixfooter').on('scroll.fixfooter', debounce(fixedFooterHandler, 100));

    function isIphoneX() {
        const isIphone = /iphone/gi.test(navigator.userAgent);
        const isIphoneX = screen.height == 812 && screen.width == 375;
        const isIphone11 = screen.height == 896 && screen.width == 414;
        return isIphone && (isIphoneX || isIphone11);
    }

    function fixedFooterHandler() {
        var windowInnerWidth = window.innerWidth;
        var windowInnerHeight = window.innerHeight;
        var screenWidth = window.screen.width;
        var screenHeight = window.screen.width;
        var scale = windowInnerWidth / screenWidth;

        // 处理lib-flexible放大viewport的情况
        // 部分浏览器在滚动页面时会显示/隐藏工具栏，影响视口高度。在有底部工具栏的情况下，不做iPhoneX的fix。350为经验值
        if (windowInnerHeight / scale - screenHeight > 350) {
            $body.addClass('fixed_iphoneX_footer');
        } else {
            $body.removeClass('fixed_iphoneX_footer');
        }
    }
}

// 从jzm进来，跳转其他页面时需要把标志jzm进来的参数带上
function fixJzmHomeIframeNest() {
    //if(fromJzmHome && parent && parent.document && parent.document.location.host == "jzm.fff.com") {
    if (isFromJzmHomeIframe()) {
        jm('#mobiCornerAd').addClass('jz_foreHideCornerAd'); // 隐藏广告

        jm('#g_body').delegate('a', 'click', function () {
            var $link = jm(this);
            fixJzmHomeIframeParam($link, $link.attr('href'));
        });
    }
}

function isFromJzmHomeIframe() {
    return getUrlParam(window.location.href, '_fromJzmHome') == 'true';
}

// 是否有底部
function checkHasFooter() {
    const p_footer_nav_wrap = jm('#p_footer_nav_wrap'); // 3.0主题底部导航
    const webCustomerServiceBox = jm('#webCustomerServiceBox'); // 服务导航
    const mallFixNavWrap = jm('.mallFixNavWrap'); // 2.0商城底部导航
    const newsBottom = jm('.J_newsBottom'); // 文章评论
    const newsBottom2 = jm('.newsBottom'); // 文章评论
    const fixedAreaBox = jm('#fixedAreaBox'); // 2.0产品详情客服
    const serviceBtnOuter = jm('#serviceBtnOuter'); // 2.0产品详情客服

    if (
        (!!p_footer_nav_wrap.length &&
            p_footer_nav_wrap.css('display') != 'none' &&
            p_footer_nav_wrap.find('#J_footer_nav').css('margin-bottom') == '0px') ||
        (!!webCustomerServiceBox.length && webCustomerServiceBox.css('display') != 'none') ||
        (!!mallFixNavWrap.length && mallFixNavWrap.css('display') != 'none') ||
        (!!newsBottom.length && newsBottom.css('display') != 'none') ||
        (!!newsBottom2.length && newsBottom2.css('display') != 'none') ||
        (!!fixedAreaBox.length &&
            fixedAreaBox.css('display') != 'none' &&
            !fixedAreaBox.hasClass('p_footer_item_wrap')) ||
        (!!serviceBtnOuter.length && serviceBtnOuter.css('display') != 'none') ||
        ((Fai.top._bookingOpen ||
            Fai.top._productFormOpen ||
            Fai.top._isMobiMallOpen ||
            Fai.top._immediatelyConsultOpen) &&
            Fai.top._pageId == 20) // 产品详情底部导航
    ) {
        return true;
    }
    return false;
}
