/***
 * 供访客态使用的全局指令
 */

import { registerBanCopyDirective } from './v-bancopy';
import { registerDomPortalDirective } from './v-dom-portal';

export function registerGlobalDirectives(Vue) {
    registerBanCopyDirective(Vue);
    registerDomPortalDirective(Vue);
}
