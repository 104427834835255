var render, staticRenderFns
import script from "./item-style-other.vue?vue&type=script&lang=jsx&"
export * from "./item-style-other.vue?vue&type=script&lang=jsx&"
import style0 from "./item-style-other.vue?vue&type=style&index=0&id=1243e967&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1243e967",
  null
  
)

export default component.exports