import { getHeaderV2, getHeaderV3 } from '@/api/header';
import { COL_DEF_FLAG } from '@/def/col';

/**
 * =================================================
 *
 * Constants
 *
 * =================================================
 */

const LOAD_HEADER_V3_INFO = 'LoadHeaderV3Info';

const LOAD_HEADER_V2_INFO = 'LoadHeaderV2Info';

const SET_HEADER_V2_LOGO_DATA = 'setHeaderV2LogoData';

const SET_HEADER_V3_HIDDEN = 'SetHeaderV3Hiiden';

export { LOAD_HEADER_V3_INFO, LOAD_HEADER_V2_INFO };

/**
 * =================================================
 *
 * State
 *
 * =================================================
 */
const state = () => ({
    headStyle: {},
    logoJumpInfo: {},
    title: {},
    searchOptions: {},
    openLanInfoList: [],
    urls: [],
    mallOpen: false,
    allProductNum: 0,
    productGroupBtnHref: '',
    headerV2: {
        title: '',
        mallOpen: false,
        urls: [],
        allProductNum: -1,
        productGroupBtnHref: '',
        canUseNofollow: false,
        isHeadHide: false,
        isMallTheme: false,
        logo: {},
        picParam: {},
        jumpTitleUrl: '',
        logoJumpUrl: '',
        setShowSiteTitleBgFlag: '',
        linkCoverPreViewPath: '',
    },
});

/* =================================================
 *
 * Getters
 *
 * =================================================
 */
const getters = {
    headStyleId: (state) => {
        return state.headStyle ? state.headStyle.styleId : 0;
    },
};

/**
 * =================================================
 *
 * actions
 *
 * =================================================
 */
const actions = {
    loadHeaderInfo({ commit, rootState }) {
        if (rootState.app.openThemeV3) {
            return getHeaderV3(rootState.app.colId).then((data) => {
                commit(LOAD_HEADER_V3_INFO, data);
            });
        } else {
            return getHeaderV2(rootState.app.colId).then((data) => {
                commit(LOAD_HEADER_V2_INFO, data);
            });
        }
    },
    independentSetHeaderV3({ dispatch }, set) {
        const bit = COL_DEF_FLAG.INDEPEND_SET_HEAD;
        dispatch('app/setColFlag', { bit, value: set }, { root: true });
    },
    independentSetHeaderV3Hide({ dispatch }, isHiddenHead) {
        const bit = COL_DEF_FLAG.INDEPEND_SET_HEAD;
        dispatch('app/setColFlag', { bit, value: isHiddenHead }, { root: true });
    },
};

/**
 * =================================================
 *
 * mutations
 *
 * =================================================
 */
const mutations = {
    [LOAD_HEADER_V3_INFO](state, payload) {
        const {
            headStyle,
            logoJumpInfo,
            title,
            searchOptions,
            openLanInfoList,
            mallOpen,
            pageUrls,
            allProductNum,
            productGroupBtnHref,
        } = payload;
        state.headStyle = headStyle;
        state.logoJumpInfo = logoJumpInfo;
        state.title = title;
        state.searchOptions = searchOptions;
        state.openLanInfoList = openLanInfoList;
        state.mallOpen = mallOpen;
        state.urls = pageUrls;
        state.allProductNum = allProductNum;
        state.productGroupBtnHref = productGroupBtnHref;
    },
    [LOAD_HEADER_V2_INFO](state, payload) {
        const {
            title,
            mallOpen,
            pageUrls,
            allProductNum,
            productGroupBtnHref,
            canUseNofollow,
            isHeadHide,
            isMallTheme,
            logo,
            picParam,
            jumpTitleUrl,
            logoJumpUrl,
            setShowSiteTitleBgFlag,
            linkCoverPreViewPath,
        } = payload;
        state.headerV2.title = title;
        state.headerV2.mallOpen = mallOpen;
        state.headerV2.urls = pageUrls;
        state.headerV2.allProductNum = allProductNum;
        state.headerV2.productGroupBtnHref = productGroupBtnHref;
        state.headerV2.canUseNofollow = canUseNofollow;
        state.headerV2.isHeadHide = isHeadHide;
        state.headerV2.isMallTheme = isMallTheme;
        state.headerV2.logo = logo;
        state.headerV2.picParam = picParam;
        state.headerV2.jumpTitleUrl = jumpTitleUrl;
        state.headerV2.logoJumpUrl = logoJumpUrl;
        state.headerV2.setShowSiteTitleBgFlag = setShowSiteTitleBgFlag;
        state.headerV2.linkCoverPreViewPath = linkCoverPreViewPath;
    },
    [SET_HEADER_V2_LOGO_DATA](state, data) {
        Object.assign(state.headerV2.logo, data);
    },
    updateHeaderV2IsHide(state, payload) {
        state.headerV2.isHeadHide = payload;
    },
    [SET_HEADER_V3_HIDDEN](state, isHeadHidden) {
        // 0 为 不隐藏, 1 为 隐藏
        state.headStyle.hh = isHeadHidden;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
