var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._bgMusicOpenNow
    ? _c(
        "div",
        {
          staticClass: "fk_bgMusic",
          attrs: { "data-headhidden": _vm.headHideV3 },
        },
        [
          _vm.mobiBgMusic.isOpen && _vm.allowedBackTop
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.setting.isHidden ===
                        _vm.MobiBgMusicSettingDef.ISHIDDEN,
                      expression:
                        "setting.isHidden === MobiBgMusicSettingDef.ISHIDDEN",
                    },
                  ],
                  on: { click: _vm.switchToPlay },
                },
                [
                  _c("div", { attrs: { id: "bgMusic" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "bgm_icon",
                        class: _vm.posCls,
                        style: _vm._bgMusicHidden,
                        attrs: { id: "bgm_icon" },
                      },
                      [_c("div", { staticClass: "bgm_icon_inner" })]
                    ),
                    _vm._v(" "),
                    _c("audio", {
                      ref: "audio",
                      attrs: {
                        id: "bgmAudio",
                        src: _vm.audioSrc,
                        "data-original": _vm.filePath,
                        autoplay: _vm._bgMusicAutoPlay,
                        loop: _vm._bgMusicLoop,
                      },
                      on: {
                        durationchange: _vm.durationchange,
                        ended: _vm.ended,
                      },
                    }),
                  ]),
                ]
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }