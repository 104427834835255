<template>
    <div
        :id="newsLineId"
        :topClassname="topClassName"
        :topSwitch="topSwitch"
        :newsId="id"
        :newsName="title"
        class="J_cardGraphicNewsStyle cardGraphicNewsStyle newsLine J_picNum"
        :class="lineClass"
        picnum="5"
    >
        <div :id="lineBodyId" class="lineBody">
            <a
                class="J_mixNewsStyleImgBox5_link f_mixNewsStyleImgBox5_link"
                hidefocus="true"
                :href="href"
                :target="target"
                :onclick="onclickStr"
            >
                <div class="mixNewsStyleImgBox5">
                    <div v-if="!pictureId" id="newsTitlePic" :alt="alt" class="newsTitlePic icon-noFigureB"></div>
                    <div v-else id="newsTitlePic" :alt="alt" class="newsTitlePic" :style="newsTitlePicStyle"></div>

                    <div class="J_mixNewsStyleTitleContainer5 mixNewsStyleTitleContainer5">
                        <div :class="[showLine ? 'mixNewsStyleTitle1' : 'mixNewsStyleTitle2']">
                            <div :class="topFlagClass"></div>{{ title }}
                        </div>
                        <div class="separatorLine"></div>
                        <div class="mixNewsStyleSummary">{{ summary }}</div>
                        <div
                            v-if="showNewsElementsRequire"
                            class="newsElementsPanel g_newsElementsPanel J_newsElementsPanel"
                            @click="gotoHref"
                        >
                            <div v-if="showCommentRequire" class="ne_newsComment faisco-icons-comment">
                                <span class="newsCommentCount">{{ totalNewsCommentCount }}</span>
                            </div>
                            <div
                                v-if="showAuthorRequire"
                                class="ne_newsAuthor faisco-icons-author"
                                :style="newsAuthorStyle"
                            >
                                <span class="newsAuthorName">{{ newsAuthor }}</span>
                            </div>
                            <div
                                v-if="(showCommentRequire || showAuthorRequire) && (showSortRequire || showDateRequire)"
                                class="ne_separatorLine g_ne_separatorLine"
                            ></div>
                            <div v-if="showSortRequire" class="ne_newsSort">{{ groupName }}</div>
                            <div v-if="showDateRequire" class="ne_newsTime">{{ formattedNewsDate }}</div>
                        </div>
                        <template v-else>
                            <div v-if="showCommentRequire" class="ne_newsComment faisco-icons-comment">
                                <span class="newsCommentCount">{{ totalNewsCommentCount }}</span>
                            </div>
                            <div
                                v-if="showAuthorRequire"
                                class="ne_newsAuthor faisco-icons-author"
                                :style="newsAuthorStyle"
                            >
                                <span class="newsAuthorName">{{ newsAuthor }}</span>
                            </div>
                            <div
                                v-if="(showCommentRequire || showAuthorRequire) && (showSortRequire || showDateRequire)"
                                class="ne_separatorLine g_ne_separatorLine"
                            >
                            </div>
                            <div v-if="showSortRequire" class="ne_newsSort">{{ groupName }}</div>
                            <div v-if="showDateRequire" class="ne_newsTime">{{ formattedNewsDate }}</div>
                        </template>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
import newsMoreMixin from '../mixins/news-more-mixin';

export default {
    name: 'NewsMoreItemStyle5',
    mixins: [newsMoreMixin],
    data() {
        return {};
    },
};
</script>

<style scoped lang="scss">
/*卡片图文列表样式*/
.newsList .f-cardGraphicNews {
    width: 100%;
    overflow: hidden;
    position: relative;
}
.newsList .cardGraphicNewsStyle {
    float: left;
}
.newsList .cardGraphicNewsStyle .lineBody {
    position: relative;
    overflow: hidden;
    padding: 0.4rem 0 0.45rem 0.7rem;
}
.isNewAddModule .newsList .cardGraphicNewsStyle .lineBody {
    padding: 0.4rem 0 0.45rem 0.7rem !important;
}
.newsList .cardGraphicNewsStyle .f_mixNewsStyleImgBox5_link {
    width: 100%;
    height: auto;
    overflow: hidden;
    max-width: 100%;
    display: block;
    position: relative;
    background: #fff;
    text-shadow: none;
}
.newsList .cardGraphicNewsStyle .mixNewsStyleImgBox5 {
    clear: both;
    border: 1px solid #e6e6e6;
    width: 12.5rem;
}
.newsList .cardGraphicNewsStyle .mixNewsStyleTitleContainer5 {
    padding: 0.55rem 0.65rem 0.75rem;
}
.newsList .cardGraphicNewsStyle .mixNewsStyleTitle1 {
    width: auto;
    display: block;
    font-weight: normal;
    font-size: 0.7rem;
    color: #666666;
    letter-spacing: 0.02rem;
    max-height: 1.9rem;
    overflow: hidden;
}
.newsList .cardGraphicNewsStyle .justifyTextAlign {
    text-align: justify;
}
.newsList .cardGraphicNewsStyle .centerTextAlign {
    text-align: center;
}
.g_locale1033 .newsList .cardGraphicNewsStyle .mixNewsStyleTitle1 {
    width: auto;
    display: block;
    font-weight: normal;
    font-size: 0.7rem;
    color: #666666;
    letter-spacing: 0.02rem;
    text-align: justify;
    max-height: 1.9rem;
    overflow: hidden;
    word-wrap: break-word;
}
.newsList .cardGraphicNewsStyle .mixNewsStyleTitle2 {
    width: auto;
    display: block;
    font-weight: normal;
    font-size: 0.7rem;
    color: #666666;
    letter-spacing: 0.02rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: auto;
    line-height: 1.3rem;
}
.newsList .cardGraphicNewsStyle.wWLine .lineBody .g_topFlag {
    width: 0.95rem;
    height: 0.95rem;
    vertical-align: top;
    display: inline-block;
    position: relative;
    margin-right: 0.1rem;
    margin-top: 0.04rem;
}
.newsList .cardGraphicNewsStyle .lineBody .g_topFlag {
    width: 0.95rem;
    height: 0.95rem;
    vertical-align: top;
    display: inline-block;
    position: relative;
    margin-right: 0.1rem;
    margin-top: 0.2rem;
}
.newsList .cardGraphicNewsStyle .separatorLine {
    border-top: 1px solid #e6e6e6;
    margin: 0.55rem 0 0.45rem;
}
.newsList .cardGraphicNewsStyle .mixNewsStyleSummary {
    color: #ababab;
    display: block;
    width: auto;
    height: auto;
    font-size: 0.6rem;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
    overflow: hidden;
    line-height: 1rem;
    max-height: 1.82rem;
}
.g_locale1033 .newsList .cardGraphicNewsStyle .mixNewsStyleSummary {
    color: #ababab;
    display: block;
    width: auto;
    height: auto;
    font-size: 0.6rem;
    word-wrap: break-word;
    word-break: normal;
    white-space: normal;
    overflow: hidden;
    line-height: 1rem;
    text-align: justify;
    max-height: 1.82rem;
}
.newsList .f-cardGraphicNewsSlide {
    position: absolute;
    left: 0;
}
.newsList .mixNewsStyleImgBox5 .newsTitlePic {
    width: 12.5rem;
    height: 7.8rem;
    box-sizing: border-box;
    background-color: #f2f2f2;
}
</style>
