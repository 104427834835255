import { isNull } from '@/shared/fai';

export let Img = {
    MODE_SCALE_FILL: 1, // 根据区域能够填满的最大值等比例缩放。图片100x50，区域50x50，结果50x25。
    MODE_SCALE_WIDTH: 2, // 根据区域宽度等比例缩放，结果高度将不受区域高度限制，即可能撑大高度。图片100x50，区域50x10，结果50x25。
    MODE_SCALE_HEIGHT: 3, // 根据区域高度等比例缩放，结果宽度将不受区域宽度限制，即可能撑大宽度。图片100x50，区域50x50，结果100x50。
    MODE_SCALE_DEFLATE_WIDTH: 4, // 根据区域宽度等比例缩小，不放大，结果高度将不受区域高度限制。图片100x50，区域50x10，结果50x25；图片100x50，区域200x100，结果100x50。
    MODE_SCALE_DEFLATE_HEIGHT: 5, // 根据区域高度等比例缩小，不放大，结果宽度将不受区域高度限制。图片100x50，区域50x50，结果100x50；图片100x50，区域200x100，结果100x50。
    MODE_SCALE_DEFLATE_FILL: 6, // 根据区域能够填满的最大值等比例缩小，不放大。图片100x50，区域50x50，结果50x25。
    MODE_SCALE_DEFLATE_MAX: 7, // 根据区域等比例缩小，不放大，结果的宽度和高度不能同时超过区域限制。图片200x100，区域100x100，结果200x100；图片100x200，区域100x100，结果100x200。
};

// 使用此函数时，不要在img标签中先设置大小，会使得调整img大小时失败；先隐藏图片，避免出现图片从原始图片变为目标图片的过程
// <img src="xx.jpg" style="display:none;" onload="Fai.Img.optimize(this, {width:100, height:50, mode:Fai.Img.MODE_SCALE_FILL});"/>
Img.optimize = function (img, option) {
    // ie下对于display:none的img不会加载
    // 这里要用临时图片，是因为当动态改变图片src时，由于图片的大小已经被设置，因此再次获取会失败
    var imgTmp = new Image();
    // 这里还不能先置空，否则将会引起对''文件的一次访问
    //	imgTmp.src = '';
    imgTmp.src = img.src;
    var imgWidth = imgTmp.width;
    var imgHeight = imgTmp.height;
    if (isNull(imgWidth) || imgWidth == 0 || isNull(imgHeight) || imgHeight == 0) {
        // chrome似乎对临时图片的加载会有延迟，立即取大小会失败
        imgWidth = img.width;
        imgHeight = img.height;
    }
    var size = Img.calcSize(imgWidth, imgHeight, option.width, option.height, option.mode);
    img.width = size.width;
    img.height = size.height;
    if (option.display == 1) {
        img.style.display = 'inline';
    } else if (option.display == 2) {
        img.style.display = 'none';
    } else if (option.display == 3) {
        img.style.display = 'inline-block';
    } else {
        img.style.display = 'block';
    }
    return { width: img.width, height: img.height };
};

Img.calcSize = function (width, height, maxWidth, maxHeight, mode) {
    var size = { width: width, height: height };
    var rateWidth;
    var rateHeight;
    if (mode == Img.MODE_SCALE_FILL) {
        rateWidth = width / maxWidth;
        rateHeight = height / maxHeight;
        if (rateWidth > rateHeight) {
            size.width = maxWidth;
            size.height = height / rateWidth;
        } else {
            size.width = width / rateHeight;
            size.height = maxHeight;
        }
    } else if (mode == Img.MODE_SCALE_WIDTH) {
        rateWidth = width / maxWidth;
        size.width = maxWidth;
        size.height = height / rateWidth;
    } else if (mode == Img.MODE_SCALE_HEIGHT) {
        rateHeight = height / maxHeight;
        size.width = width / rateHeight;
        size.height = maxHeight;
    } else if (mode == Img.MODE_SCALE_DEFLATE_WIDTH) {
        rateWidth = width / maxWidth;
        if (rateWidth > 1) {
            size.width = maxWidth;
            size.height = height / rateWidth;
        }
    } else if (mode == Img.MODE_SCALE_DEFLATE_HEIGHT) {
        rateHeight = height / maxHeight;
        if (rateHeight > 1) {
            size.width = width / rateHeight;
            size.height = maxHeight;
        }
    } else if (mode == Img.MODE_SCALE_DEFLATE_FILL) {
        rateWidth = width / maxWidth;
        rateHeight = height / maxHeight;

        if (rateWidth > rateHeight) {
            if (rateWidth > 1) {
                size.width = maxWidth;
                size.height = height / rateWidth;
            }
        } else {
            if (rateHeight > 1) {
                size.width = width / rateHeight;
                size.height = maxHeight;
            }
        }
    } else if (mode == Img.MODE_SCALE_DEFLATE_MAX) {
        if (width > maxWidth && height > maxHeight) {
            rateWidth = width / maxWidth;
            rateHeight = height / maxHeight;

            if (rateWidth < rateHeight) {
                size.width = maxWidth;
                size.height = height / rateWidth;
            } else {
                size.width = width / rateHeight;
                size.height = maxHeight;
            }
        }
    }
    size.width = Math.floor(size.width);
    size.height = Math.floor(size.height);
    if (size.width == 0) {
        size.width = 1;
    }
    if (size.height == 0) {
        size.height = 1;
    }
    return size;
};
