var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("preview", {
    attrs: {
      "components-table": _vm.componentsTable,
      "system-components": _vm.SystemComponents,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }