<template>
    <div v-if="isTopbarOpen" id="webMultilingualArea" class="multilingualArea">
        <div class="multilingual">
            <template v-if="true">
                <div
                    v-if="memberVisible || manageMode || fromJZM"
                    id="memberEntrance"
                    :style="memberEntranceStyle"
                    class="memberEntrance"
                >
                    <template v-if="memberName === '' || sessionMemberId == 0">
                        <a id="topBarMemberLogin" :href="loginUrl" class="g_mullink member_a memberIcon">
                            <span class="faisco-icons-contact1"></span
                            ><span class="g_mullinkFont">{{ memberLoginLoginText }}</span>
                        </a>
                        <a id="topBarMemberSignup" :href="signUpUrlAndReturn" class="g_mullink member_a signupIcon">
                            <span class="faisco-icons-edit1"></span
                            ><span class="g_mullinkFont">{{ memberRegText }}</span>
                        </a>
                    </template>
                    <template v-else>
                        <a id="topBarMemberName" :href="profileUrlAndReturn" class="g_mullink member_a memberIcon">
                            <span class="faisco-icons-contact1"></span
                            ><span class="g_mullinkFont">{{ memberName }}</span>
                        </a>
                        <a class="g_mullink exit" hidefocus="true" @click="logout">
                            <span class="g_mullinkFont">{{ topBarLogoutText }}</span>
                        </a>
                    </template>
                </div>
                <template v-if="(mallOpen && mallHidden === 0) || manageMode">
                    <a
                        v-if="isCnOrTcn"
                        id="mallCartIcon"
                        class="g_mullink carIcon"
                        :href="mcartUrl"
                        :style="mallCartIconStyle"
                    >
                        <span class="faisco-icons-cart1"></span>
                        <span class="g_mullinkFont">{{ rbarCartText }}</span>
                        <span class="proNum">{{ productAmountStr }}</span>
                    </a>
                    <a v-else id="mallCartIcon" class="g_mullink carIcon" :href="mcartUrl" :style="mallCartIconStyle">
                        <span class="faisco-icons-cart1"></span><span class="proNum">{{ productAmountStr }}</span>
                    </a>
                </template>

                <template v-if="openLanguageInfoList.length > 1 && (locaterModule.hidden === 0 || manageMode)">
                    <div
                        id="multiLanguageCtrl"
                        class="multiLanguageCtrl"
                        :class="languageClass"
                        :style="localeHiddenStyle"
                    >
                        <span class="lanTitle" :style="localeFontStyle"></span>
                        <span class="lanIcon"></span>
                    </div>
                    <div id="lanSelect" class="lanSelect">
                        <table cellpadding="0" cellspacing="0">
                            <tr>
                                <td>
                                    <div class="lanSelected">
                                        <div id="lanSelected" class="multiLanguageCtrl" :class="languageClass">
                                            <span class="lanTitle" :style="localeFontStyle"></span>
                                            <span class="lanIcon"></span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="lanContainer" :class="languageClass" :style="localeFontStyle">
                                        <div
                                            v-for="language in openLanguageInfoList"
                                            :key="language.lcid"
                                            class="lanItem"
                                            :class="createLanguageIconClass(language.lcid)"
                                            :value="language.url"
                                            :[createCurrentLanProperty(language.lcid)]="language.lcid"
                                        >
                                            <span class="lanTitle" :class="createLanguageTitleClass(language.lcid)">{{
                                                language.name
                                            }}</span>
                                            <span class="lanIcon"></span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { onLogout } from '@/system/member/profile/method';
import { getLocaterData } from '@/api/module/locater';
import { LocaterDomService } from '../locaterDomService';
import { getPreviewObject } from '@/shared/getPreviewObject';

export default {
    name: 'MultilingualArea',
    data() {
        return {
            isTopbarOpen: true,
            fromJZM: false,
            memberVisible: false,
            manageMode: VITE_APP_MODE !== 'visitor',
            sessionMemberId: -1,
            memberName: '',
            loginUrl: '',
            signUpUrl: '',
            profileUrl: '',
            mallOpen: false,
            mallHidden: 0,
            mcartUrl: '',
            productAmountStr: '',
            openLanguageInfoList: [],
            locaterModule: {
                hidden: 0,
                fontType: 0,
                ms: -1,
            },
        };
    },
    computed: {
        ...mapState('app', ['lcid', 'LS']),
        memberEntranceStyle() {
            const style = {
                float: 'left',
            };
            if (this.memberVisible === false) {
                style.display = 'none';
            }
            return style;
        },
        memberLoginLoginText() {
            return this.LS.memberLoginLogin;
        },
        memberRegText() {
            return this.LS.memberReg;
        },
        signUpUrlAndReturn() {
            return `${this.signUpUrl}?returnUrl=`;
        },
        profileUrlAndReturn() {
            return `${this.profileUrl}?returnUrl=`;
        },
        topBarLogoutText() {
            return this.LS.topBarLogout;
        },
        isCnOrTcn() {
            return [2052, 1028].includes(this.lcid);
        },
        mallCartIconStyle() {
            const hidden = this.mallOpen === false || this.mallHidden === 1;
            return hidden ? { display: 'none' } : {};
        },
        rbarCartText() {
            return this.LS.rbarCart;
        },
        localeHiddenStyle() {
            return this.locaterModule.hidden === 1 ? { display: 'none' } : {};
        },
        languageClass() {
            if ([1, 2, 3].includes(this.locaterModule.ms)) {
                return `lanStyle${this.locaterModule.ms}`;
            } else {
                return '';
            }
        },
        localeFontStyle() {
            if (this.locaterModule.fontType === 1) {
                const style = {
                    fontSize: `${this.locaterModule.size}px`,
                    color: this.locaterModule.color,
                };
                if (this.locaterModule.weight === 1) {
                    style.fontWeight = 'bold';
                }
                return style;
            }
            return {};
        },
    },
    async mounted() {
        await this.loadLocaterData();
        await this.$nextTick();
        LocaterDomService.initLocater();
        if (VITE_APP_MODE !== 'visitor') {
            LocaterDomService.bindManageEventListeners();
        }
        if (VITE_APP_MODE !== 'visitor') {
            this.$emit('loaded');
        }
        this.initLanguageCtrl();
    },
    methods: {
        async loadLocaterData() {
            const { data } = await getLocaterData();
            this.isTopbarOpen = data.isTopbarOpen;
            this.fromJZM = data.fromJZM;
            this.memberVisible = data.memberVisible;
            this.sessionMemberId = data.sessionMemberId;
            this.mallHidden = data.mallHidden;
            this.memberName = data.memberName;
            this.openLanguageInfoList = data.openLanguageInfoList.filter((item) => {
                return [
                    2052,
                    1033,
                    1028,
                    1041,
                    1042,
                    1108,
                    1054,
                    11274,
                    1049,
                    1036,
                    1040,
                    1031,
                    20521,
                    this.lcid,
                ].includes(item.lcid);
            });
            this.loginUrl = data.pageUrls.loginUrl;
            this.signUpUrl = data.pageUrls.signUpUrl;
            this.profileUrl = data.pageUrls.profileUrl;
            this.mcartUrl = data.pageUrls.mcartUrl;
            this.productAmountStr = data.proNumStr;
            this.locaterModule = data.locaterModule;
        },
        logout() {
            onLogout();
        },
        createLanguageIconClass(lcid) {
            return `Icon${lcid}`;
        },
        createCurrentLanProperty(lcid) {
            return lcid === this.lcid ? 'currentLan' : null;
        },
        createLanguageTitleClass(lcid) {
            return `title<${lcid}>`;
        },
        initLanguageCtrl() {
            const lanSelect = (e) => {
                if (parseInt(jm('#lanSelect').height()) > 0) {
                    LocaterDomService.openLanaguageSelector();
                } else {
                    if (VITE_APP_MODE !== 'visitor') {
                        this.$layer.removeAllEditLayer();
                    }
                    LocaterDomService.openLanaguageSelector();
                }

                e.stopPropagation();
                return false;
            };

            /*绑定点击事件--start*/
            if (VITE_APP_MODE !== 'visitor') {
                setTimeout(function () {
                    $(getPreviewObject('multiLanguageCtrl')).unbind('click').bind('click', lanSelect);
                    $(getPreviewObject('lanSelected')).unbind('click').bind('click', lanSelect);
                }, 2000);
            } else {
                jm('#multiLanguageCtrl').unbind('click').bind('click', lanSelect);
                jm('#lanSelected').unbind('click').bind('click', lanSelect);
            }
            /*绑定点击事件--end*/
        },
    },
};
</script>
