<template>
    <module-frame :module-id="moduleId">
        <div :id="'newsDetail' + moduleId" class="newsDetail">
            <div v-if="options.hideNews" class="newsDetail_hide"
                ><div class="newsDetail_hide--img"></div
                ><p class="newsDetail_hide--text">{{ options.jz_newsAudit_tips }}</p></div
            >
            <template v-else>
                <div v-if="cusTitleStyleOn" class="title" :style="titleStyle">
                    <span class="titleText">{{ newsInfo.title }}</span>
                </div>
                <h1 v-else class="title"
                    ><span class="titleText">{{ newsInfo.title }}</span></h1
                >
                <div class="g_separator separatorLine"></div>
                <div class="richContent content">
                    <html-render :html="newsContent" ref-name="newsContent" />
                    <pagenation
                        v-if="totalSize > 1"
                        :module-id="moduleId"
                        :total-size="totalSize"
                        :page-size="1"
                        :pageno="pageno"
                        :open-theme-v3="false"
                        :ajax-pagenation="true"
                        @on-change="changePage"
                        @click.native.stop=""
                    >
                    </pagenation>
                </div>
                <!-- 附件区域 -->
                <div v-if="viewList.length > 0" class="attachList">
                    <attach v-for="(item, index) in viewList" :key="'attach_' + index" :info="item" />
                </div>
            </template>
            <!-- 分享区域 -->
            <template v-if="shareOn">
                <template v-if="isMicromessenger">
                    <div class="sharePosition1">
                        <div id="shareToFriend" class="shareToFriendDiv" @click="showBg">
                            <div class="shareToFriendIcon"></div>
                            <div class="shareToFriend">发送给朋友</div>
                        </div>
                    </div>
                    <div class="sharePosition2">
                        <div id="shareToWeiXin" class="shareToWeiXinDiv" @click="showBg">
                            <div class="shareToWeiXinIcon"></div>
                            <div class="shareToWeiXin">发送到朋友圈</div>
                        </div>
                    </div>
                </template>
                <div v-else class="shareInfo">
                    <div class="shareCtrl shareto">{{ shareLs }} : </div>
                    <div id="sharePanel" class="shareCtrl sharePanel">
                        <template v-for="(shareItem, index) in printShareLink">
                            <a
                                v-if="shareItem.icon == 'Weixin'"
                                id="shareLinkWeixin"
                                :key="'wx_' + index"
                                hidefocus="true"
                                :title="shareLs + shareItem.n"
                                :href="isSpider ? null : 'javascript:;'"
                            >
                                <div class="shareIcon" :class="shareItem.icon"></div>
                            </a>
                            <a
                                v-else-if="shareItem.icon == 'qq_share'"
                                id="shareLinkQQ"
                                :key="'qq_' + index"
                                hidefocus="true"
                                hide-focus="true"
                                :title="shareLs + shareItem.n"
                                :href="isSpider ? null : 'javascript:;'"
                            >
                                <div class="shareIcon" :class="shareItem.icon"></div>
                            </a>
                            <a
                                v-else
                                :key="'other_' + index"
                                hidefocus="true"
                                hide-focus="true"
                                :title="shareLs + shareItem.n"
                                :href="isSpider ? null : 'javascript:;'"
                                @click="jumptoShareUrl(shareItem.url)"
                            >
                                <div class="shareIcon" :class="shareItem.icon"></div>
                            </a>
                            <a v-if="index == 2" :key="'index_' + index" hide-focus="true" @click="showMore">
                                <div id="showOrHide" class="shareIcon showIcon"></div>
                            </a>
                        </template>
                    </div>
                </div>
                <div class="clearfloat" style="height: 5px"></div>
            </template>
            <!-- 评论区域 -->
            <div
                v-if="options.isOpen && realNameAuth.allowShowMessage"
                class="pdCommentSwitch"
                style="width: 90%; margin: 0 auto; clear: both"
                @click="getCommentDiv"
            >
                <div class="left liuyanIcon"></div>
                <div class="middle">{{ productCumulativeCommentsLs }}({{ options.totalSize }})</div>
                <div class="right rightArrow"></div>
            </div>
            <!-- 页码 -->
            <div v-if="showPrevAndNext" id="prevAndNextDiv">
                <table class="paginateTable">
                    <tbody>
                        <tr>
                            <td colspan="2" align="center" valign="middle">
                                <div :id="'singleNewspagenation' + moduleId" class="pagenation">
                                    <div v-if="hasPrev" class="pagePrev icon-pagePrev" :class="pagenPrevStyle"
                                        ><a
                                            hidefocus="true"
                                            :href="prevNewsUrl"
                                            :newsid="prevReqArgs.id"
                                            @click="setUrlArgToCookie($event, false)"
                                        ></a
                                    ></div>
                                    <div
                                        v-else
                                        :class="pagenPrevStyle"
                                        class="pagePrev icon-pagePrev pageDisable"
                                    ></div>
                                    <div v-if="hasNext" class="pageNext icon-pageNext" :class="pagenNextStyle"
                                        ><a
                                            hidefocus="true"
                                            :newsid="nextReqArgs.id"
                                            :href="nextNewsUrl"
                                            @click="setUrlArgToCookie($event, true)"
                                        ></a
                                    ></div>
                                    <div
                                        v-else
                                        :class="pagenNextStyle"
                                        class="pageNext icon-pageNext pageDisable"
                                    ></div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- 幻灯片 -->
            <image-viewer
                v-if="showMobiViewer"
                :z-index="zIndex"
                :initial-index="initialIndex"
                :on-close="onClose"
                :url-list="images"
                :is-mobi="true"
                :content-terminal="'mobi'"
                :manage-mode="manageMode"
                :document-event-el="documentEventEl"
                append-to-container-id="g_body"
                :append-to-body="false"
                :disable-long-press="banCopyTextImg"
            />
        </div>
    </module-frame>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';

import { showTopTips } from '@/shared/tips';
import { checkBit } from '@/shared/fai';
import { showBg, showMore } from '@/system/newsDetail/util';
import { initShare } from '@/features/global-mobi-function/methods/share/index';
import { getCommentDiv } from '@/modules/shared/comment';
import { slidesshowMixins } from '@/modules/moduleMixins';
import { getPictureSlidesImgs } from '@/features/global-mobi-function/methods/share/index';
import { bindImgLazyLoad } from '@/shared/imageLazyload';
import { Clue } from '@jz/biz-shared';
import ModuleFrame from '@/modules/frame/index.vue';
import pagenation from '@/components/pagenation.vue';
import attach from '@/system/newsDetail/newsDetailV3/module/component/attach.vue';
import htmlRender from '@/components/html-render/index.vue';
import { getNewsPrev } from '@/api/news';
import { MOBILE_AUTH_TIPS } from '@/services';

export default {
    name: 'NewsDetail',
    components: {
        ModuleFrame,
        pagenation,
        attach,
        htmlRender,
    },
    mixins: [slidesshowMixins],
    provide() {
        return {
            moduleId: this.moduleId,
            openThemeV3: false,
            manageMode: VITE_APP_MODE !== 'visitor',
            style: this.module.style,
        };
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            manageMode: VITE_APP_MODE !== 'visitor',
            pageno: 1,
            CUS_TITLE_STYLE: 0x2, // 自定义标题样式
            isMicromessenger: false,
            prevNextData: {
                hasNext: false,
                hasPrev: false,
                prev: {},
                next: {},
            },
            nextReqArgs: {},
            prevReqArgs: {},
        };
    },
    computed: {
        ...mapState('app', ['lcid', 'LS', 'realNameAuth']),
        ...mapState(['mobiTipList']),
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        options() {
            return this.module.renderOptions || {};
        },
        content() {
            return this.module.content;
        },
        newsInfo() {
            return this.options.newsInfo;
        },
        newsId() {
            return this.newsInfo.id;
        },
        newsContentArray() {
            return this.options.newsContent.replace(/<video/g, "<video preload='none'").split('<_fainp/>');
        },
        totalSize() {
            return this.newsContentArray.length;
        },
        newsContent() {
            return this.newsContentArray[this.pageno - 1] || '';
        },
        cusTitleStyleOn() {
            return checkBit(this.module.flag, this.CUS_TITLE_STYLE);
        },
        titleStyleData() {
            return this.module.prop3 || {};
        },
        titleStyle() {
            let { ss = 25, bb = false, cc = '#333' } = this.titleStyleData;
            return {
                'font-size': ss + 'px',
                'font-weight': bb ? 'bolder' : 'normal',
                color: cc,
            };
        },
        viewList() {
            return this.options.viewList;
        },
        shareOn() {
            // 开启分享
            return this.content.shareShow;
        },
        shareLinkList() {
            return this.options.shareLinkList;
        },
        shareIdList() {
            return this.content.shareLinkList;
        },
        printShareLink() {
            let print = [];
            for (let shareInfoIndex in this.shareLinkList) {
                let shareInfo = this.shareLinkList[shareInfoIndex];
                for (let shareIdIndex in this.shareIdList) {
                    let shareId = this.shareIdList[shareIdIndex];
                    let shareInfoId = shareInfo.icon;
                    if (shareInfoId == shareId) {
                        print.push(shareInfo);
                    }
                }
            }
            return print;
        },
        isSpider() {
            return this.options.isSpider;
        },
        showPrevNext() {
            return this.content.pageIcon;
        },
        pagination() {
            return this.options.prevNextArgs || {};
        },
        nextNewId() {
            return this.pagination.nextNewId || -1;
        },
        hasNext() {
            if (this.isOpenNewMobiNewsPreAndNext) {
                return this.prevNextData.hasNext;
            }
            return this.nextNewId != -1;
        },
        prevNewId() {
            return this.pagination.prevNewId || -1;
        },
        hasPrev() {
            if (this.isOpenNewMobiNewsPreAndNext) {
                return this.prevNextData.hasPrev;
            }
            return this.prevNewId != -1;
        },
        nextNewsUrl() {
            if (this.isOpenNewMobiNewsPreAndNext) {
                return this.prevNextData.next.href;
            }
            return this.pagination.nextNewsUrl;
        },
        prevNewsUrl() {
            if (this.isOpenNewMobiNewsPreAndNext) {
                return this.prevNextData.prev.href;
            }
            return this.pagination.prevNewsUrl;
        },
        pagenPrevStyle() {
            return this.lcid == 2052 ? 'icon-cnNewsP' : '';
        },
        pagenNextStyle() {
            return this.lcid == 2052 ? 'icon-cnNewsN' : '';
        },
        shareLs() {
            return this.LS.share;
        },
        productCumulativeCommentsLs() {
            return this.LS.productCumulativeComments;
        },
        isOpenNewMobiNewsPreAndNext() {
            return this.options.isOpenNewMobiNewsPreAndNext === true && this.options.isShowPrevNext;
        },
        showPrevAndNext() {
            if (this.isOpenNewMobiNewsPreAndNext) {
                return this.prevNextData.hasNext === true || this.prevNextData.hasPrev === true;
            }
            return this.showPrevNext && this.isEmptyObject(this.pagination);
        },
    },
    watch: {
        newsContent() {
            this.initImgEvent();
        },
        module: {
            async handler() {
                await Vue.nextTick();
                this.init();
            },
            deep: true,
        },
    },
    mounted() {
        this.init();
        if (this.isOpenNewMobiNewsPreAndNext) {
            this.getPrevAndNext();
        }
        this.initRealNameAuthTips();
    },
    methods: {
        ...mapMutations(['addMobiTips']),
        getPrevAndNext() {
            let fullUrl = window.location.pathname + window.location.search;
            let anchor = window.location.hash;

            if (VITE_APP_MODE !== 'visitor') {
                const { h: href } = window.$router.currentRoute.query;
                const [url, hash = ''] = href.split('#');
                fullUrl = url;
                anchor = `#${hash}`;
            }

            const params = {
                moduleId: this.module.id,
                newsId: this.newsId,
                fullUrl,
                anchor,
            };
            getNewsPrev(params).then((nodeData) => {
                this.prevNextData.hasNext = nodeData.hasNext;
                this.prevNextData.hasPrev = nodeData.hasPrev;
                this.prevNextData.prev = nodeData.prev;
                this.prevNextData.next = nodeData.next;
                this.prevReqArgs = this.getCookieArgs(nodeData.prev.reqArgCookieStr);
                this.nextReqArgs = this.getCookieArgs(nodeData.next.reqArgCookieStr);
            });
        },
        getCookieArgs(cookieStr = '') {
            let [cookieData = ''] = cookieStr?.match(/{.*}/) ?? [];
            if (cookieData) {
                try {
                    return JSON.parse(cookieData)?.args ?? {};
                } catch (e) {
                    console.error(e);
                    return {};
                }
            } else {
                return {};
            }
        },
        changePage(pageno) {
            this.pageno = pageno;
        },
        showBg,
        jumptoShareUrl(url) {
            Clue?.logClueShareNewsAction();
            window.open(url);
        },
        showMore,
        getCommentDiv() {
            getCommentDiv('getWafNotCk_news', this.newsId);
        },
        isEmptyObject(obj) {
            var name;
            for (name in obj) {
                return false;
            }
            return true;
        },
        refreshSildeImg() {
            this.images = getPictureSlidesImgs(`#newsDetail${this.moduleId} img`);
        },
        initImgEvent() {
            // 懒加载、幻灯片
            this.$nextTick(() => {
                bindImgLazyLoad(`module${this.moduleId}`);
                this.refreshSildeImg();
            });
        },
        setUrlArgToCookie(event, isNext = false) {
            let args = isNext ? this.nextReqArgs : this.prevReqArgs;
            if (this.options.openStaticUrl && args != null) {
                window.JZ.setUrlArgToCookie(event.target, args);
            }
        },
        init() {
            let { options } = this;
            let { hasThirdCode, newsInfo } = options;
            if ($.isEmptyObject(newsInfo)) {
                return false;
            }
            if (hasThirdCode) {
                // 20171115 判断分销免费域名是否有第三方代码，有的话就给提示
                Fai.top._oem ? showTopTips() : showTopTips(3);
            }
            if (typeof window != 'undefined' && typeof navigator != 'undefined') {
                this.isMicromessenger = window.navigator.userAgent.indexOf('micromessenger') > -1;
            }
            if (this.printShareLink.length > 0) {
                initShare('shareLinkWeixin', '', 'shareLinkQQ');
            }
            this.initImgEvent();
        },
        initRealNameAuthTips() {
            if (VITE_APP_MODE === 'visitor') {
                if (this.realNameAuth.allowShowMessage === false) {
                    this.addMobiTips(MOBILE_AUTH_TIPS);
                }
            }
        },
    },
};
</script>

<style>
@import '../../newsDetail.scss';
.newsDetail {
    overflow: auto;
}
.newsDetail .title {
    float: none;
    line-height: 1.5rem;
    background: none;
    height: auto;
}
.newsDetail .attachList .attachListItem--item {
    display: flex;
    padding: 0.5rem 0 0.5rem 0.5rem;
    background-color: #f4f4f4;
    margin-bottom: 0.5rem;
}
.newsDetail .title .titleText {
    font-size: 0.9rem;
    line-height: 1.75rem;
    color: #333;
    float: none;
    display: block;
    white-space: normal;
    height: auto;
    overflow: auto;
    padding: 0.4rem;
    font-weight: normal;
    word-wrap: break-word;
}

/* 文章分享 */
.shareInfo {
    float: right;
    clear: both;
    width: 11rem;
    margin-bottom: 0.4rem;
}
.shareInfo .shareCtrl {
    float: left;
    height: 0.8rem;
    line-height: 0.8rem;
}
.shareInfo .shareCtrl a {
    font-family: verdana;
    text-decoration: none;
    display: block;
    width: 1.5rem;
    float: left;
    margin: 0px 0.3rem 0.25rem 0px;
}
.shareInfo .shareMt a {
    width: 4rem;
    margin: 0.25rem 0px 0.25rem 0px;
}
.shareInfo .sharePanel {
    width: 7.2rem;
    height: 1.4rem;
    padding: 4px;
    overflow: hidden;
}
.shareInfo .shareNotTitlePanel {
    height: auto;
}
.shareInfo .shareIcon {
    float: left;
    height: 1.5rem;
    line-height: 1.5rem;
    width: 1.5rem;
    margin: 0px 0.1rem 0px 0.1rem;
}
.shareInfo .shareto {
    cursor: default;
    margin-top: 10px;
}
.shareInfo .sina_weibo {
    background: url(/image/share/sina_weibo.png?v=201711250607) 0px 0px no-repeat;
    background-size: 1.4rem 1.4rem;
}
.shareInfo .qq_weibo {
    background: url(/image/share/qq_weibo.png?v=201711250607) 0px 0px no-repeat;
    background-size: 1.4rem 1.4rem;
}
.shareInfo .qq_share {
    background: url(/image/share/qq_share.png?v=201804231155) 0px 0px no-repeat;
    background-size: 1.4rem 1.4rem;
}
.shareInfo .qq_zone {
    background: url(/image/share/qq_zone.png?v=201711250607) 0px 0px no-repeat;
    background-size: 1.4rem 1.4rem;
}
.shareInfo .kaixin001 {
    background: url(/image/share/kaixin001.png?v=201711250607) 0px 0px no-repeat;
    background-size: 1.4rem 1.4rem;
}
.shareInfo .renren {
    background: url(/image/share/renren.png?v=201711250607) 0px 0px no-repeat;
    background-size: 1.4rem 1.4rem;
}
.shareInfo .douban {
    background: url(/image/share/douban.png?v=201711250607) 0px 0px no-repeat;
    background-size: 1.4rem 1.4rem;
}
.shareInfo .sohu_weibo {
    background: url(/image/share/sohu_weibo.png?v=201711250607) 0px 0px no-repeat;
    background-size: 1.4rem 1.4rem;
}
.shareInfo .netease_weibo {
    background: url(/image/share/lofter.png?v=201711250607) 0px 0px no-repeat;
    background-size: 1.4rem 1.4rem;
}
.shareInfo .baidu_hi {
    background: url(/image/share/baidu_hi.png?v=201711250607) 0px 0px no-repeat;
    background-size: 1.4rem 1.4rem;
}
.shareInfo .baidu_tieba {
    background: url(/image/share/baidu_tieba.png?v=201711250607) 0px 0px no-repeat;
    background-size: 1.4rem 1.4rem;
}
.shareInfo .taojianghu {
    background: url(/image/share/taojianghu.png?v=201711250607) 0px 0px no-repeat;
    background-size: 1.4rem 1.4rem;
}
.shareInfo .FaceBook {
    background: url(/image/share/facebook.png?v=201711250607) 0px 0px no-repeat;
    background-size: 1.4rem 1.4rem;
}
.shareInfo .Twitter {
    background: url(/image/share/twitter.png?v=201711250607) 0px 0px no-repeat;
    background-size: 1.4rem 1.4rem;
}
.shareInfo .LinkedIn {
    background: url(/image/share/linkedin.png?v=201711250607) 0px 0px no-repeat;
    background-size: 1.4rem 1.4rem;
}
.shareInfo .showIcon {
    background: url(/image/share/showicon.png?v=201711250607) 0px 0px no-repeat;
    background-size: 1.4rem 1.4rem;
}
.shareInfo .hideIcon {
    background: url(/image/share/hideicon.png?v=201711250607) 0px 0px no-repeat;
    background-size: 1.4rem 1.4rem;
}
.shareInfo .Weixin {
    background: url(/image/share/weixin.png?v=201711250607) 0px 0px no-repeat;
    background-size: 1.4rem 1.4rem;
}
.shareToFriendDiv {
    border: 1px solid #c3c1c1;
    float: right;
    box-shadow: 0px 2px 2px 0px #c5c5c5;
    background-image: linear-gradient(to top, #dddddd, #f2f3f2);
    background-color: gray;
    border-radius: 2px;
    width: 140px;
    height: 34px;
    line-height: 34px;
    margin-left: 7%;
}
.shareToWeiXinDiv {
    border: 1px solid #c3c1c1;
    float: left;
    box-shadow: 0px 2px 2px 0px #c5c5c5;
    background-image: linear-gradient(to top, #dddddd, #f2f3f2);
    background-color: gray;
    border-radius: 2px;
    width: 140px;
    height: 34px;
    line-height: 34px;
    margin-right: 7%;
}
.shareToFriend {
    height: 30px;
    width: 117px;
    line-height: 30px;
    font-size: 13px;
    font-family: '微软雅黑';
    text-align: right;
    margin-top: 2px;
}
.shareToFriendIcon {
    background: url(/image/shareIcon.png?v_20140271750) 0 -679px no-repeat;
    background-size: 28px 785px;
    height: 30px;
    width: 30px;
    position: absolute;
    margin-left: 19px;
    margin-top: 2px;
}
.shareToWeiXin {
    height: 30px;
    width: 122px;
    line-height: 30px;
    font-family: '微软雅黑';
    font-size: 13px;
    text-align: right;
    margin-top: 2px;
}
.shareToWeiXinIcon {
    background: url(/image/shareIcon.png?v_20140271750) 0 -645px no-repeat;
    background-size: 28px 785px;
    height: 30px;
    width: 30px;
    position: absolute;
    margin-left: 14px;
    margin-top: 2px;
}
.shareFullbg {
    background-color: #000000;
    left: 0;
    opacity: 0.7;
    top: 0;
    z-index: 100;
}
.shareTips {
    z-index: 999;
    position: fixed;
    right: 1rem;
    top: 1rem;
    width: 254px;
    height: 11rem;
    background: url(/image/prompt.png?v_20140271750) 0 0 no-repeat;
    float: right;
}
.sharePosition1 {
    width: 49%;
    margin-bottom: 0.4rem;
    float: left;
}
.sharePosition2 {
    width: 49%;
    margin-bottom: 0.4rem;
    float: right;
}
.productDetail .shareInfo .shareCtrl a {
    font-size: 0.6rem !important;
}
.productDetail .shareInfo .shareto {
    font-size: 0.6rem !important;
}
.newsDetail .shareInfo .shareCtrl a {
    font-size: 0.6rem !important;
}
.newsDetail .shareInfo .shareto {
    font-size: 0.6rem !important;
}
.fk_newsDetailNotFound {
    padding-top: 3.15rem;
}
.fk_notMallThemeNewDetail div.newsCustomPanel2 {
    height: auto;
}

.pdCommentSwitch {
    color: #666;
    background: #fff;
    height: 1.75rem;
    border: 1px solid #cecece;
}

.pdCommentSwitch .left {
    float: left;
    width: 1.75rem;
    height: 1.75rem;
    background-size: 1rem;
    background-position: 50% 50%;
}

.pdCommentSwitch .middle {
    float: left;
    height: 1.75rem;
    line-height: 1.75rem;
}

.pdCommentSwitch .right {
    float: right;
    width: 1.75rem;
    height: 1.75rem;
    background-size: 1rem;
    background-position: 50% 50%;
}

.liuyanIcon {
    background: url(/image/mall/liuyan.png?v=201711250607) no-repeat;
}

.shuomingIcon {
    background: url(/image/mall/shuoming.png?v=201711250607) no-repeat;
}

.rightArrow {
    background: url(/image/mall/jjtou.png?v=201711250607) no-repeat;
}

.pagenation .icon-pagePrev.icon-cnNewsP:before {
    content: '上一篇';
    font-family: '\5FAE\8F6F\96C5\9ED1', Helvetica, '黑体', Arial, Tahoma;
    font-size: 0.7rem;
    line-height: 1.3rem;
    margin-right: 0;
}
.pagenation .icon-pageNext.icon-cnNewsN:before {
    content: '下一篇';
    font-family: '\5FAE\8F6F\96C5\9ED1', Helvetica, '黑体', Arial, Tahoma;
    font-size: 0.7rem;
    line-height: 1.3rem;
    margin-left: 0;
}
.g_locale2052 .icon-pagePrev.icon-cnListP:before {
    content: '上一页';
    font-family: '\5FAE\8F6F\96C5\9ED1', Helvetica, '黑体', Arial, Tahoma;
    font-size: 0.7rem;
    line-height: 1.3rem;
    margin-right: 0;
}
.g_locale2052 .icon-pageNext.icon-cnListN:before {
    content: '下一页';
    font-family: '\5FAE\8F6F\96C5\9ED1', Helvetica, '黑体', Arial, Tahoma;
    font-size: 0.7rem;
    line-height: 1.3rem;
    margin-left: 0;
}
</style>
