var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _c(
      "div",
      {
        staticClass: "photoGroupDetailPanel",
        attrs: { id: _vm.productGroupDetailPanelId },
      },
      [
        _c(
          "div",
          {
            staticClass: "photoGroupDetailHeader detailHeaderColor",
            attrs: { id: _vm.productGroupDetailHeaderId },
          },
          [
            _vm.isShowBackBtn
              ? [
                  _c("span", {
                    staticClass:
                      "icon-gClose g_round g_iconMiddle photoGroupDetailBack icon-defaultColor",
                  }),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.isShowShare == 0
              ? [
                  _c("span", {
                    staticClass:
                      "icon-share g_iconMiddle g_round photoGroupDetailShare icon-defaultColor",
                    attrs: { id: "newsDetailShare" },
                  }),
                ]
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "photoGroupDetail" }, [
          _c(
            "div",
            {
              staticClass: "photoGroupPicPanel",
              attrs: { id: _vm.photoGroupPicPanelId },
            },
            [
              _c(
                "div",
                {
                  staticClass: "photoGroupPicList",
                  attrs: { id: _vm.photoGroupPicListId },
                },
                _vm._l(_vm.photoList, function (photo) {
                  return _c(
                    "div",
                    { key: photo.id, staticClass: "photoGroupPic" },
                    [
                      _c("div", {
                        staticClass:
                          "photoGroupPicImg photoGroup-img J_img_lazyload",
                        style: {
                          width: "100%",
                          height: _vm.computedPicHeight,
                          backgroundImage: `url(${_vm.loadingPath})`,
                        },
                        attrs: {
                          "src-original": photo.picThumbPath,
                          alt: photo.picThumbName,
                          datasrc: photo.picThumbPath,
                          "data-picid": photo.picThumbId,
                        },
                      }),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.photoList.length > 1
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "photoListBullet",
                        attrs: { id: "photoListBullet" },
                      },
                      [
                        _c(
                          "ul",
                          { staticClass: "bullets" },
                          _vm._l(_vm.photoList, function (photoItem, index) {
                            return _c("li", {
                              key: photoItem.id,
                              class: { on: index === 0 },
                            })
                          }),
                          0
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass:
                        "J_btn J_preBtn faisco-icons-M000217 f-btn f-preBtn faisco-icons fontIcon faisco-icons-back5",
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass:
                        "J_btn J_nextBtn faisco-icons-M000215 f-btn f-nextBtn faisco-icons fontIcon faisco-icons-forward1",
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "f-photoNumber" }, [
                      _c(
                        "span",
                        { staticClass: "J_curPhotoNumber this_page" },
                        [_vm._v("1")]
                      ),
                      _c("span", { staticClass: "pageLine" }, [_vm._v("/")]),
                      _c("span", { staticClass: "J_maxNum total_page" }, [
                        _vm._v(_vm._s(_vm.photoList.length)),
                      ]),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "photoGroupDetailContent" },
            [
              _c("h1", { staticClass: "title", class: _vm.titleRightShare }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.photoGroupInfo.name) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _vm.isShowDetail === 0
                ? [
                    _c("div", { staticClass: "separateLine" }),
                    _vm._v(" "),
                    _c("html-render", {
                      staticClass: "photoGroupDesc",
                      attrs: { html: _vm.photoGroupContent },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }