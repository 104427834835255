<template>
    <div id="fixedRightSideBtns">
        <slot></slot>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
};
</script>

<style>
#fixedRightSideBtns {
    position: fixed;
    z-index: 9;
    margin-left: 13.9rem;
    width: 2rem;
    bottom: 110px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

@media only screen and (min-width: 768px) {
    #fixedRightSideBtns {
        left: calc(50% - 8rem);
    }
}
</style>
