import { getFooterNavInfo, getFooterNavInfoV2 } from '@/api/footerNav';

/**
 * =================================================
 *
 * Constants
 *
 * =================================================
 */

const LOAD_FOOTER_NAV_INFO = 'LoadFooterNavInfo';
const LOAD_FOOTER_NAV_INFO_V2 = 'LoadFooterNavInfoV2';

export { LOAD_FOOTER_NAV_INFO };
/**
 * =================================================
 *
 * State
 *
 * =================================================
 */
const state = () => ({
    footerNavPattern: {},
    levelNavList: {},
    skinPattern: {},
    isGiveCoupon: false,
    mallMember: false,
    footerNavV2: {
        isSpider: false,
        msgBoardUrl: '',
        mallMember: false,
        orderParamList: [],
        colIconType: 1,
        serviceDisplay: false,
    },
});

/**
 * =================================================
 *
 * actions
 *
 * =================================================
 */
const actions = {
    loadFooterNavInfo({ commit, rootState }) {
        if (rootState.app.openThemeV3) {
            return getFooterNavInfo({
                _colId: rootState.app.colId,
                _footerNavSkin: window._reqFooterNavSkinId,
            }).then((data) => {
                commit(LOAD_FOOTER_NAV_INFO, data);
            });
        } else {
            return getFooterNavInfoV2().then((data) => {
                commit(LOAD_FOOTER_NAV_INFO_V2, data);
            });
        }
    },
};

/**
 * =================================================
 *
 * mutations
 *
 * =================================================
 */
const mutations = {
    [LOAD_FOOTER_NAV_INFO](state, { footerNavPattern, levelNavList, skinPattern, isGiveCoupon, mallMember }) {
        state.footerNavPattern = footerNavPattern;
        state.levelNavList = levelNavList;
        state.skinPattern = skinPattern;
        state.mallMember = mallMember;
        state.isGiveCoupon = isGiveCoupon;
    },
    [LOAD_FOOTER_NAV_INFO_V2](
        state,
        { isSpider, msgBoardUrl, mallMember, orderParamList, colIconType, serviceDisplay }
    ) {
        state.footerNavV2.isSpider = isSpider;
        state.footerNavV2.msgBoardUrl = msgBoardUrl;
        state.footerNavV2.mallMember = mallMember;
        state.footerNavV2.orderParamList = orderParamList;
        state.footerNavV2.colIconType = colIconType;
        state.footerNavV2.serviceDisplay = serviceDisplay;
    },
};

/* =================================================
 *
 * Getters
 *
 * =================================================
 */
const getters = {
    topNavList: (state) => state.levelNavList?.topNavList ?? [],
    childrenNavList: (state) => state.levelNavList?.childNavList ?? [],
    advance: (state) => state.skinPattern?.advance ?? {},
    skinAdvanceSwitch: (state) => state?.skinPattern?.ais ?? {},
    advanceUser: (state) => state.footerNavPattern?.advance ?? {},
    routineUser: (_, getters) => getters.advanceUser?.routine ?? {},
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
