<template>
    <div>
        <div class="memberBasicInfoArea">
            <div class="memberBasicInfo">
                <div class="icon-pnameIcon"><img id="memberHeadPic" /></div>
                <span class="memberName" v-text="memberNameText"></span>
                <span v-if="memberLevelAuthInfo.allow" class="memberLevelName" v-text="memberLevelName"></span>
            </div>
        </div>
        <ul class="modifyArea">
            <li v-if="isOpenKnowPayment" id="knowPayment">
                <div class="itemName">
                    <svg style="left: 0.5rem">
                        <use xlink:href="#jzm-vicon_mall" /></svg
                    ><span style="margin-left: 0.6rem" v-text="LS.minePayKnowProducts"></span>
                </div>
                <div class="clickIcon">
                    <span class="icon-clickIcon"></span>
                </div>
            </li>
            <li v-if="integralOpen" id="integral">
                <div class="itemName">
                    <span class="icon-integralIcon" :class="{ newProfileIcon: isNewTheme }"></span
                    ><span v-text="LS.integral_mine + itgName"></span>
                </div>
                <div class="clickIcon"><span class="icon-clickIcon"></span></div>
            </li>
            <template v-if="isCrownVer">
                <li v-if="openCollection" id="collection">
                    <div class="itemName">
                        <span class="icon-starIcon" :class="{ newProfileIcon: isNewTheme }"></span
                        ><span v-text="LS.my_collection"></span>
                    </div>
                    <div class="clickIcon"><span class="icon-clickIcon"></span></div>
                </li>
                <li v-if="openCoupon" id="coupon">
                    <div class="itemName">
                        <span class="icon-coupons" :class="{ newProfileIcon: isNewTheme }"></span
                        ><span v-text="LS.integral_mine + LS.coupons"></span>
                    </div>
                    <div class="clickIcon"><span class="icon-clickIcon"></span></div>
                </li>
            </template>
            <li v-if="isOpenMyForm" id="myForm" :_noFormRecord="noFormRecord">
                <div class="itemName">
                    <svg><use xlink:href="#jzm-v28" /></svg><span v-text="LS.mobi_member_myForm"></span>
                    <div class="remind" :class="hidden"></div>
                </div>
                <div class="clickIcon">
                    <span class="update_tips" :class="hidden" v-text="updateTips"></span>
                    <span class="icon-clickIcon"></span>
                </div>
            </li>
            <li v-if="memberBulletinOpen" id="latestNews" orderlist="1">
                <div class="itemName">
                    <span class="icon-noticeIcon" :class="{ newProfileIcon: isNewTheme }"></span
                    ><span v-text="bulletinTitle"></span>
                    <div id="bulletinTitle" :sessionMid="sessionMid" class="bulletinTitle" :class="newsReminds"></div>
                </div>
                <div class="clickIcon">
                    <span class="icon-clickIcon"></span>
                </div>
            </li>
            <li v-if="isCn && hasPrize && isOpenLottery" id="myreward">
                <div class="itemName">
                    <span class="icon-pInfoIcon"></span><span v-text="LS.mobi_member_MyReward"></span>
                </div>
                <div class="clickIcon">
                    <span class="icon-clickIcon"></span>
                </div>
            </li>
            <li id="personalInfo">
                <div class="itemName">
                    <span class="icon-pInfoIcon" :class="{ newProfileIcon: isNewTheme }"></span
                    ><span v-text="LS.mobi_membert_PersonalInfo"></span>
                </div>
                <div class="clickIcon">
                    <span class="icon-clickIcon"></span>
                </div>
            </li>
            <li id="password">
                <div class="itemName">
                    <span class="icon-cPswIcon" :class="{ newProfileIcon: isNewTheme }"></span
                    ><span v-text="LS.mobi_membert_ResetPassword"></span>
                </div>
                <div class="clickIcon">
                    <span class="icon-clickIcon"></span>
                </div>
            </li>
            <li v-if="isOtherLogin" id="changeOtherLoginAcct">
                <div class="itemName">
                    <span class="icon-bangding" :class="{ newProfileIcon: isNewTheme }"></span
                    ><span v-text="LS.mobi_membert_ChangeOtherLoginAcct"></span>
                </div>
                <div class="clickIcon">
                    <span class="icon-clickIcon"></span>
                </div>
            </li>
        </ul>
        <div class="onLogout" @click="onLogoutClick"
            ><span class="icon-cLogoutIcon"></span>{{ LS.mobi_member_onLogout }}</div
        >
    </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { SiteVerDef } from '@/def/ver';
import { profileLogout } from '../../method';
import { MemberPropDef } from '../../../memberDef';
import { checkBit } from '@/shared/fai';

export default {
    name: 'JzProfileV2Visitor',
    props: {
        moduleId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            newsReminds: '',
        };
    },
    computed: {
        ...mapState('app', ['LS', 'lcid', '_ver']),
        isCn() {
            return this.lcid == 2052;
        },
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        renderOptions() {
            return this.module.renderOptions || {};
        },
        integralOpen() {
            return this.renderOptions.integralOpen || false;
        },
        isOpenKnowPayment() {
            return this.renderOptions.isOpenKnowPayment || false;
        },
        openCollection() {
            return this.renderOptions.openCollection || false;
        },
        isOpenMyForm() {
            return this.renderOptions.isOpenMyForm || false;
        },
        hasPrize() {
            return this.renderOptions.hasPrize || false;
        },
        isOpenLottery() {
            return this.renderOptions.isOpenLottery || false;
        },
        isOtherLogin() {
            return this.renderOptions.isOtherLogin || '';
        },
        siteResRoot() {
            return this.renderOptions.siteResRoot || '';
        },
        sessionMid() {
            return this.renderOptions.sessionMid || 0;
        },
        memberBulletinOpen() {
            return this.renderOptions.memberBulletinOpen || false;
        },
        itgName() {
            return this.renderOptions.itgName || '';
        },
        openCoupon() {
            return this.renderOptions.openCoupon || false;
        },
        hidden() {
            return this.renderOptions.hidden || '';
        },
        bulletinTitle() {
            return this.renderOptions.bulletinTitle || '';
        },
        news_reminds_v3() {
            return this.renderOptions.news_reminds_v3 || '';
        },
        updateTips() {
            return this.renderOptions.updateTips || '';
        },
        isNewTheme() {
            return this.renderOptions.isNewTheme || false;
        },
        memberAcct() {
            return this.renderOptions.memberAcct || '';
        },
        memberLevelInfo() {
            return this.renderOptions.memberLevelInfo || {};
        },
        memberLevelName() {
            return this.memberLevelInfo.name || '';
        },
        memberInfo() {
            return this.renderOptions.memberInfo || {};
        },
        noFormRecord() {
            return this.LS.no_form_record || '';
        },
        memberName() {
            return this.memberInfo.name || '';
        },
        displayList() {
            return this.renderOptions.displayList || [];
        },
        nameProp() {
            let namPropList = this.displayList.filter((item) => {
                return item[MemberPropDef.Info.TYPE] == MemberPropDef.Type.SYS_NAME;
            });
            if (namPropList.length) {
                return namPropList[0];
            } else {
                return null;
            }
        },
        showMemberName() {
            let show = false;
            if (this.nameProp) {
                let { flag } = this.nameProp;
                if (!checkBit(flag, MemberPropDef.Flag.DISABLE)) {
                    show = true;
                }
            }
            return show;
        },

        memberNameText() {
            return !this.showMemberName ||
                this.memberName == null ||
                (this.memberName.isEmpty && this.memberName.isEmpty())
                ? this.memberAcct
                : this.memberName;
        },
        isCrownVer() {
            return (
                this._ver == SiteVerDef.CROWN ||
                this._ver == SiteVerDef.OEM_CROWN ||
                this._ver == SiteVerDef.CROWN_BUY_OUT
            );
        },
        memberLevelAuthInfo() {
            return this.renderOptions.memberLevelAuthInfo ?? {};
        },
    },
    methods: {
        onLogoutClick() {
            let urlSearchParams = new URLSearchParams(window.location.search);
            let returnUrl = urlSearchParams.get('returnUrl');
            let indexUrl = '/';
            profileLogout(indexUrl, returnUrl);
        },
    },
};
</script>
