var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "paginationDiv" + _vm.moduleId } }, [
    _c("div", { staticClass: "g_separator next_separator" }),
    _vm._v(" "),
    _c("div", { staticClass: "newsNext" }, [
      _vm.hasNext
        ? _c("div", [
            _vm._v(_vm._s(_vm.newsNextOne) + "："),
            _c("a", {
              staticClass: "newsLink",
              attrs: { hidefocus: "true", href: _vm.nextNewsUrl },
              domProps: { innerHTML: _vm._s(_vm.nextNewsTitle) },
              on: {
                click: function ($event) {
                  return _vm.setUrlArgToCookie($event, true)
                },
              },
            }),
          ])
        : _c(
            "div",
            { staticClass: "prevNextContent", style: _vm.paginationStyle },
            [
              _vm._v(_vm._s(_vm.newsNextOne) + "："),
              _c("span", [_vm._v(_vm._s(_vm.mobi_newslast))]),
            ]
          ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "newsPrev" }, [
      _vm.hasPrev
        ? _c("div", { staticClass: "prevNextContent" }, [
            _vm._v(_vm._s(_vm.newsPrevOne) + "："),
            _c("a", {
              staticClass: "newsLink",
              attrs: { hidefocus: "true", href: _vm.prevNewsUrl },
              domProps: { innerHTML: _vm._s(_vm.prevNewsTitle) },
              on: {
                click: function ($event) {
                  return _vm.setUrlArgToCookie($event, false)
                },
              },
            }),
          ])
        : _c(
            "div",
            { staticClass: "prevNextContent", style: _vm.paginationStyle },
            [
              _vm._v(_vm._s(_vm.newsPrevOne) + "："),
              _c("span", [_vm._v(_vm._s(_vm.mobi_newsFirst))]),
            ]
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }