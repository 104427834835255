var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.openThemeV3
    ? _c("sign-up-three", {
        attrs: { module: _vm.module, "module-id": _vm.moduleId },
      })
    : _c("sign-up-two", {
        attrs: { module: _vm.module, "module-id": _vm.moduleId },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }