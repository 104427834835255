// 鼠标点击时候隐藏输入框中提示信息，鼠标移开的时候显示提示信息
export function hidePlaceholder() {
    if (Fai.top._openThemeV3) {
        jm('#signupPage').parents('.g_web, .middleCenter').css('background', '#ffffff');
        jm('#mLogin').parents('.g_web, .middleCenter').css('background', '#ffffff');
    }
    var inputNode = document.querySelectorAll('.g_input');
    var textAreaNode = document.querySelectorAll('.g_textArea');

    var plhText = '';

    for (var i = 0; i < inputNode.length; i++) {
        inputNode[i].onfocus = function () {
            plhText = this.placeholder;
            this.placeholder = '';
            jm(this).removeClass('fk-inputFontColor');
            //by json
            //颜色注释掉的原因：影响到input内文字设置颜色的配置项
            //这里输出的颜色跟CSS输出的重复了
            //jm(this).css({
            //   "color":"#333",
            //});
        };
        inputNode[i].onblur = function () {
            this.placeholder = plhText;
            //失去焦点时，为其补充一个类fk-inputFontColor来达到输出颜色的效果
            //this.color = "rgb(51,51,51)";
            jm(this).addClass('fk-inputFontColor');
        };
    }

    for (let i = 0; i < textAreaNode.length; i++) {
        textAreaNode[i].onfocus = function () {
            plhText = this.placeholder;
            this.placeholder = '';
        };
        textAreaNode[i].onblur = function () {
            this.placeholder = plhText;
        };
    }
}
