import jzRequest from '@jz/request';
import { isDevEnv } from '@/shared/env';
const request = jzRequest.create({});

if (typeof window !== 'undefined') {
    if (isDevEnv) {
        addSessionParamsInterceptor(request);
    }
}

request.addResponseInterceptor({
    onFulfilled(response) {
        return response;
    },
    onRejected(error) {
        console.error('error.config.url', error.config.url);
        return Promise.reject(error);
    },
});

function addSessionParamsInterceptor(request) {
    let loginCookieValue = document.cookie.match(/_FSESSIONID=(\S+)/);
    if (loginCookieValue !== null) {
        loginCookieValue = loginCookieValue[1];
    }
    request.addRequestInterceptor({
        onFulfilled(config) {
            let token = '';
            if (typeof window !== 'undefined') {
                token = document?.getElementById('_TOKEN')?.getAttribute('value');
            }

            const extParams = {};
            if (!loginCookieValue) {
                console.warn('[DEV]: request/index.js: cookie缺少_FSESSIONID');
            }
            if (loginCookieValue !== null && token !== '') {
                extParams._FSESSIONID = loginCookieValue;
                extParams._TOKEN = token;
            }

            if (typeof config.params !== 'undefined') {
                config.params = {
                    ...config.params,
                    ...extParams,
                };
            } else {
                config.params = extParams;
            }
            return config;
        },
        onRejected(error) {
            return Promise.reject(error);
        },
    });
}

export default request;

export { request };

export function postByFormUrlEncoded(options) {
    return request.post(options.url, {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        ...options,
    });
}
