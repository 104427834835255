import { logDog } from '@/shared/log';
import { isWechat } from '@/shared/browser';
import { getWechatDemoFootSupport } from '@/api/footer';
let weChatAdIns = null;

const showCustomerServiceCodePopup = () => {
    jm('#g_body').append(
        `<div class="popupWXQrCode" id="popupWXQrCode">
            <div class="wxQrBg"></div>
            <div
                style="
                    position: absolute;
                    top: 50%;
                    width: 300px;
                    height: 393px;
                    margin-left: -150px;
                    margin-top: -11rem;
                    z-index: 1;
                    left: 50%;
                "
            >
                <img style="border-radius: 12px; overflow: hidden;" src="${window._resImageRoot}/image/AddWorkWechatAd.png" />
                <svg
                    style="
                        position: absolute;
                        width: 30px;
                        height: 30px;
                        bottom: -50px;
                        left: 50%;
                        margin-left: -15px;
                        z-index: 1;
                    "
                    id="customerServiceCodeCloseIcon"
                >
                    <use xlink:href="#jzm_icon_close3"></use>
                </svg>
                <a
                    id="customerServiceCode"
                    style="
                        width: 212px;
                        height: 44px;
                        position: absolute;
                        bottom: 71px;
                        left: 45px;
                        z-index: 1;
                    "
                    onclick="window.jumpWxService()"
                >
                </a>
            </div>
        </div>`
    );

    window.jumpWxService = () => {
        $('.fa-customer-service-consult__btn')[0]?.click();
    };

    document.querySelector('#customerServiceCode').addEventListener('click', () => {
        window.bizShared.context.fdpLog('jz_c_text_click', {
            jz_free_text0: '客服企微码',
            jz_version: window.bizShared.context.jzVersion,
        });
    });

    document.querySelector('#customerServiceCodeCloseIcon').addEventListener('click', () => {
        jm('#popupWXQrCode').hide();
    });

    jm('#g_body').append('<div id="popupWXQrCodeService"></div>');

    new Vue({
        el: '#popupWXQrCodeService',
        components: {
            CustomerServiceConsult: () =>
                import('@fk/fa-component-cus/lib/customer-service-consult').then((m) => m.default),
        },
        computed: {
            env() {
                let result = 3;
                if (window.Hosts?.home?.includes('.fff.com')) {
                    // 本地
                    result = 1;
                }
                // eslint-disable-next-line @jz/no-use-fkw-url
                if (window.Hosts?.home?.includes('.fkw.com.faidev.cc')) {
                    // dep
                    result = 2;
                }
                return result;
            },
            consultSrc() {
                return `//${window.Hosts?.portal}/qqQiyukf.jsp`;
            },
            requestDomain() {
                if (this.env !== 3) {
                    return '';
                }
                // 线上中台只支持 https
                // eslint-disable-next-line @jz/no-use-http-prefix
                return `https://${window.Hosts.mHome}/`;
            },
        },
        methods: {
            handleConsulting() {},
        },
        template: `
            <customer-service-consult 
                ta="378"
                :wx-work-ta="{ saleWxWorkTa: 103313, customerWxWorkTa: 103312 }"
                :env="env"
                consult-src="consultSrc"
                type="site"
                :request-domain="requestDomain"
                @consulting="handleConsulting"
            ></customer-service-consult>
        `,
    });

    return {
        show() {
            jm('#popupWXQrCode').show();
        },
        hide() {
            jm('#popupWXQrCode').hide();
        },
    };
};

const createWeChatAd = async () => {
    if (weChatAdIns != null) {
        return;
    }
    const data = await getWechatDemoFootSupport();
    if (!data.success) {
        console.log('getWechatDemoFootSupport failed', data);
        return;
    }
    const { wechatDemoFootSupportABTest, showCustomerServiceCode } = data;

    let wxQrCodePath = `${window._resImageRoot}/image/mobiFooterWechatQrCode.gif`;
    if (wechatDemoFootSupportABTest) {
        window.bizShared.context.fdpLog('jz_c_text_expo', {
            jz_free_text0: showCustomerServiceCode ? '客服企微码' : '建站公众号码',
            jz_version: window.bizShared.context.jzVersion,
        });
        if (showCustomerServiceCode) {
            weChatAdIns = showCustomerServiceCodePopup();
            return;
        }
        wxQrCodePath = `${window._resImageRoot}/image/mobiFooterWechatQrCode.png`;
    }
    jm('#g_body').append('<div class="popupWXQrCode" id="popupWXQrCode"></div>');
    weChatAdIns = new Vue({
        el: '#popupWXQrCode',
        data() {
            return {
                imgPath: `${window._resImageRoot}/image/wx/wxQrCodeBack.png`,
                wxQrCodePath,
                siteName: '免费建站',
                touchStartTime: 0,
            };
        },
        methods: {
            close() {
                jm('#popupWXQrCode').hide();
            },
            show() {
                jm('#popupWXQrCode').show();
            },
            touchstart() {
                this.touchStartTime = new Date().getTime();
            },
            touchend() {
                let time = new Date().getTime();
                let interval = time - this.touchStartTime;
                if (interval > 500 && isWechat()) {
                    let DOG_ID_FREE_ADV = 200010;
                    let DOG_SRC_WECHAT_LONG_PRESS = 14;
                    logDog(DOG_ID_FREE_ADV, DOG_SRC_WECHAT_LONG_PRESS);
                    window.bizShared.context.fdpLog('jz_c_text_click', {
                        jz_free_text0: '建站公众号码',
                        jz_version: window.bizShared.context.jzVersion,
                    });
                }
            },
        },
        template: `
            <div id='popupWXQrCode' class='popupWXQrCode'>
                <div class='wxQrBg J_close' @click="close"></div>
                <img :src="imgPath" class='J_bigPic bgPic' @touchstart="touchstart" @touchend="touchend">
                <div class='wxQrTip' v-text="siteName"></div>
                <img :src="wxQrCodePath" class='wxQrCodePic'>
            </div>
        `,
    });
};

// sourceType: 1: 底部技术支持, 2: 左侧免费广告
export const showWeChatAd = (sourceType) => {
    const source =
        {
            1: '底部技术支持',
            2: '左侧免费广告',
        }[sourceType] || '';
    if (!source) {
        return;
    }
    window.bizShared.context.fdpLog('jz_wx_mobi_gg_click', {
        jz_free_text0: source,
        jz_version: window.bizShared.context.jzVersion,
    });
    createWeChatAd().then(() => {
        Vue.nextTick(() => {
            weChatAdIns.show();
        });
    });
};

export const hideWeChatAd = () => {
    if (weChatAdIns) {
        weChatAdIns.hide();
    }
};

if (typeof window !== 'undefined') {
    window.addEventListener('load', () => {
        if (window.location.href.includes('showCustomerServiceCodePopup=1')) {
            showCustomerServiceCodePopup().show();
        }
    });
}
