import request, { postByFormUrlEncoded } from './request';
import { successHandle } from '@/shared/fai';
import { getRouterData } from '../features/router/universal';
export function getModulesByColId(colId, routeData = {}, query = {}) {
    return request
        .get(`/api/manage/module/getModules`, {
            params: {
                colId,
                extId: Fai.top._extId,
                ...routeData,
                ...query,
            },
        })
        .then(({ data }) => {
            return { msg: data.msg, modules: data.modules };
        });
}

export function getModulesByCusUrl(cusUrl = '', routeData = {}, query = {}) {
    return request
        .get(`/api/manage/module/getModules`, {
            params: {
                colId: -1,
                colCusUrl: cusUrl,
                extId: Fai.top._extId,
                pid: routeData.newProductId,
                ...routeData,
                id: routeData.newsId || routeData.productId,
                ...query,
            },
        })
        .then(({ data }) => {
            return { msg: data.msg, modules: data.modules };
        });
}

export function addModuleAPI(option) {
    return request
        .post(`/api/manage/module/add`, {
            params: {
                colId: option.params.colId,
                style: option.params.style,
                extId: Fai.top._extId,
                ...(option.params.flag || {}),
            },
            data: option.data,
        })
        .then(({ data }) => {
            if (successHandle(data, '', '系统错误', '', 3, 1)) {
                return data;
            }
            throw new Error('添加失败');
        });
}

export function getColMoules(colId, extId) {
    return request
        .get(`/ajax/colModuleManage_h.jsp?cmd=getWafNotCk_getColModules`, {
            params: {
                _colId: colId,
                extId,
            },
        })
        .then(({ data }) => data);
}

export function setModule(data) {
    return postByFormUrlEncoded({
        url: '/ajax/mobimodule_h.jsp?cmd=setWafCk_setBack',
        params: data,
    }).then(({ data }) => data);
}

export function getModuleInfoById(id, colId, extId, args = {}) {
    const routerData = getRouterData();
    return request
        .get('/api/manage/module/getModuleInfoById', {
            params: {
                moduleId: id,
                colId,
                extId,
                ...args,
                ...routerData,
            },
        })
        .then((res) => {
            return res.data;
        });
}

/**
 * module_h.jsp
 * @param {Object} data
 */
export function ajaxModule(data) {
    return request
        .post('/ajax/module_h.jsp', {
            data,
        })
        .then(({ data }) => data);
}

export function getMobiModuleListByStyle(styleId) {
    return request
        .post(`/ajax/module_h.jsp?cmd=getWafNotCk_getMobiModuleListByStyle&moduleStyle=${styleId}`)
        .then((res) => {
            return res.data;
        });
}
