import { changeHref } from '@/features/router/universal';
import { preprocessUrl } from './url';

export function navigateToLoginPage({ errno, returnUrl }) {
    changeHref(
        preprocessUrl({ path: '', oldPath: '' }) +
            createLoginPage({
                errno,
                returnUrl,
            })
    );
}

export function createLoginPage({ errno, returnUrl }) {
    if (!returnUrl && typeof window !== 'undefined') {
        returnUrl = window.location.href;
    }

    let url = 'login.jsp';

    const params = {};
    if (returnUrl !== undefined) {
        params.returnUrl = returnUrl;
    }
    if (errno !== undefined) {
        params.errno = errno;
    }

    return stringifyParams(url, params);
}

/**
 *
 * @param {string} url
 * @param {Object} params
 */
function stringifyParams(url, params) {
    const keys = Object.keys(params);
    if (keys.length === 0) {
        return url;
    }

    const query = keys
        .map((key) => {
            const value = params[key];
            if (value === undefined) {
                return '';
            }
            return `${key}=${encodeURIComponent(value)}`;
        })
        .join('&');

    return `${url}?${query}`;
}
