// 抽奖结果弹窗
let ResultPopup = {};
const CUSTOM = 1, // 自定义奖品
    PRODUCT = 2, // 产品
    INTEGRAL = 3, // 会员积分
    NOTHING = 4, // 安慰语
    TEMPLATE1 = 1, // 自定义模板1
    TEMPLATE2 = 2; // 自定义模板2
ResultPopup.component = {
    el: '#resultPopup',
    template: `
        <transition name="fade">
            <div v-show="!hidden" :class="!hidden ? 'result-fade-in' : 'result-fade-out'" class="fix-wrap-20201019">
                <div class="webLeft"></div>
                <div class="result-popup-mask">
                    <transition name="scale">
                        <div v-show="!hidden" :class="!hidden ? 'result-scale-in' : 'result-scale-out'" class="result-popup">
                            <!-- 自定义礼品 -->
                            <div v-if="result.type == ${CUSTOM} && result.templateType == ${TEMPLATE1}" class="result-type-custom-1">
                                <div class="content">
                                    <svg @click="hideResPopup" class="r-close">
                                        <use href="#jzm_icon_close3"></use>
                                    </svg>
                                    <div class="top">
                                        <div class="res-title">{{result.title}}</div>
                                        <div class="res-desc">{{result.prizeContent}}</div>
                                    </div>
                                    <div :style="showCode ? '' : 'left: 0;width: 100%;'" class="bottom">
                                        <img v-if="showCode" :src="result.code" alt="" class="code">
                                        <div :style="showCode ? '' : 'margin: 0 auto;'" class="b-right">
                                            <span :style="showCode ? '' : 'text-align: center;'" class="r-desc" :key="index" v-for="(item, index) in prizeRemarkList">{{item}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div @click="hideResPopup" class="confirm">确定</div>
                            </div>
                            
                            <div ref="contentWrap" v-else-if="result.type == ${CUSTOM} && result.templateType == ${TEMPLATE2}" class="result-type-custom-2">
                                <div ref="content" class="content">
                                    <svg @click="hideResPopup" class="r-close">
                                        <use href="#jzm_icon_close3"></use>
                                    </svg>
                                    <div class="top-title-img"></div>
                                    <div class="top">
                                        <img :src="result.prizeImg" alt="" class="t-img">
                                        <span class="t-desc">{{result.title}}</span>
                                        <span class="t-desc">{{result.prizeContent}}</span>
                                    </div>
                                    <span class="t-split"></span>
                                    <div :style="showCode ? '' : 'margin-left: 0;'" class="bottom">
                                        <img v-if="showCode" :src="result.code" alt="" class="code">
                                        <div :style="showCode ? '' : 'margin: 0 auto;'" class="b-right">
                                            <span :style="showCode ? '' : 'text-align: center;'" class="r-desc" :key="index" v-for="(item, index) in prizeRemarkList">{{item}}</span>
                                        </div>
                                    </div>
                                    </div>
                                <div @click="hideResPopup" class="confirm">确定</div>
                            </div>
                            
                            <!-- 产品 -->
                            <div ref="contentWrap" v-else-if="result.type == ${PRODUCT}" class="result-type-2">
                                <div ref="content" class="content">
                                    <svg @click="hideResPopup" class="r-close">
                                        <use href="#jzm_icon_close3"></use>
                                    </svg>
                                    <div class="top-title-img"></div>
                                    <div class="pro-title-text">{{result.title}}</div>
                                    <div class="pro-title-text">{{result.prizeContent}}</div>
                                    <div v-if="prize.tPicO != 2" class="pro-img-wrap">
                                        <img class="pro-img" :src="result.prizeImg" alt="">
                                    </div>
                                    <span class="pro-desc" :key="index" v-for="(item, index) in prizeRemarkList">{{item}}</span>
                                    <div @click="hideResPopup" class="confirm">确定</div>
                                </div>
                            </div>
                            
                            <!-- 会员积分 -->
                            <div ref="contentWrap" v-else-if="result.type == ${INTEGRAL}" class="result-type-3">
                                <div ref="content" class="content">
                                    <svg @click="hideResPopup" class="r-close">
                                        <use href="#jzm_icon_close3"></use>
                                    </svg>
                                    <div class="top-title-img"></div>
                                    <img v-if="showCoin" class="integral-img" :src="result.coinPrize" alt="">
                                    <div class="integral-title">{{result.prizeContent}}</div>
                                    <div class="integral-desc" :key="index" v-for="(item, index) in prizeRemarkList">{{item}}</div>
                                    <div @click="hideResPopup" class="confirm">确定</div>
                                </div>
                            </div>
                            
                            <!-- 安慰语 -->
                            <div ref="contentWrap" v-else-if="result.type == ${NOTHING}" class="result-type-4">
                                <div ref="content" class="content">
                                    <svg @click="hideResPopup" class="r-close">
                                        <use href="#jzm_icon_close3"></use>
                                    </svg>
                                    <div class="top-title-img"></div>
                                    <img v-if="showEmpty" class="empty-img" :src="result.emptyImg" alt="">
                                    <div class="empty-title">{{result.prizeContent}}</div>
                                    <div class="empty-desc" :key="index" v-for="(item, index) in prizeRemarkList">{{item}}</div>
                                    <div @click="hideResPopup" class="confirm">确定</div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
                <div class="webRight"></div>
            </div>
        </transition>
    `,
    name: 'resultPopup',
    methods: {
        // 控制滚动穿透
        switchScroll(hidden) {
            if (hidden) {
                // 恢复原属性
                jm('#g_body').css('overflow-y', 'auto');
            } else {
                jm('#g_body').css('overflow', 'hidden');
            }
        },
        hideResPopup() {
            this.hidden = true;
            this.switchScroll(true);
            setTimeout(() => {
                ResultPopup.destroy();
            }, 300);
        },
    },
};
ResultPopup.render = function (lot, prize) {
    $("<div id='resultPopup'></div>").appendTo('body');

    const HIT_PRIZE = `${Fai.top._resRoot}/image/v2/lottery/zp1.png`,
        COIN_PRIZE = `${Fai.top._resRoot}/image/v2/lottery/memberCredits.png`,
        CODE_PRIZE = `${Fai.top._resRoot}/image/v2/calculation_default_img.jpg`,
        BOX_PRIZE = `${Fai.top._resRoot}/image/v2/lottery/box.png`,
        DEFAULT_FLAG = 0, // 默认
        CUSTOM_FLAG = 1, // 自定义
        // eslint-disable-next-line no-unused-vars
        HIDE_FLAG = 2; // 隐藏

    let hitPrize = '',
        type = prize.type,
        templateType = prize.tt == 0 ? TEMPLATE1 : TEMPLATE2,
        coinPrize = '',
        defaultCode = '',
        defaultBox = '',
        showCode = false, // 显示二维码
        showCoin = false, // 显示会员积分图片
        showEmpty = false, // 显示未中奖图片
        defaultContent = '',
        defaultRemark = '';
    switch (type) {
        // 自定义产品
        case CUSTOM:
            defaultContent = prize.name;
            defaultRemark = `
                长按或扫描加好友\n
                提供会员账号领取奖励		
            `;
            if (templateType == TEMPLATE1) {
                if (lot.tPicO == DEFAULT_FLAG) {
                    defaultCode = CODE_PRIZE;
                    showCode = true;
                } else if (lot.tPicO == CUSTOM_FLAG) {
                    // 后台无返回
                    defaultCode = lot.tPicPath;
                    showCode = true;
                } else {
                    showCode = false;
                }
            } else if (templateType == TEMPLATE2) {
                if (lot.picO == DEFAULT_FLAG) {
                    hitPrize = HIT_PRIZE;
                } else if (lot.picO == CUSTOM_FLAG) {
                    hitPrize = lot.picPath;
                }
                if (lot.tPicO == DEFAULT_FLAG) {
                    defaultCode = CODE_PRIZE;
                    showCode = true;
                } else if (lot.tPicO == CUSTOM_FLAG) {
                    // 后台无返回
                    defaultCode = lot.tPicPath;
                    showCode = true;
                } else {
                    showCode = false;
                }
            }
            break;
        // 产品
        case PRODUCT:
            defaultContent = lot.pInfo.name;
            defaultRemark = '请在【会员中心-我的奖品】中查看';
            hitPrize = lot.pInfo.picPath;
            break;
        // 会员积分
        case INTEGRAL:
            defaultContent = '会员积分';
            defaultRemark = '请在【会员中心-我的奖品】中查看';
            if (prize.tPicO == DEFAULT_FLAG) {
                coinPrize = COIN_PRIZE;
                showCoin = true;
            } else if (prize.tPicO == CUSTOM_FLAG) {
                // 后台无返回
                coinPrize = lot.tPicPath;
                showCoin = true;
            } else {
                showCoin = false;
            }
            break;
        // 安慰语
        case NOTHING:
            defaultContent = '谢谢参与';
            defaultRemark = '';
            if (prize.tPicO == DEFAULT_FLAG) {
                defaultBox = BOX_PRIZE;
                showEmpty = true;
            } else if (prize.tPicO == CUSTOM_FLAG) {
                // 后台无返回
                defaultBox = lot.tPicPath;
                showEmpty = true;
            } else {
                showEmpty = false;
            }
            break;
    }

    const result = {
        type,
        templateType,
        title: '恭喜您抽中',
        prizeContent: prize.con == DEFAULT_FLAG ? defaultContent : prize.cnt,
        prizeRemark: prize.ron == DEFAULT_FLAG ? defaultRemark : prize.rak,
        prizeImg: hitPrize,
        coinPrize,
        code: defaultCode,
        emptyImg: defaultBox,
    };

    // 换行，且只显示2行
    let prizeRemarkList = result.prizeRemark
        .split('\n')
        .filter((v) => v.trim() != '')
        .map((m) => m.trim())
        .slice(0, 2);

    this.component.data = {
        showCode,
        showCoin,
        showEmpty,
        prize,
        result,
        hidden: false,
        prizeRemarkList,
    };
    this.vm = new Vue(this.component);

    // 外框底部灰色效果(自定义模板1除外)
    // if(this.vm.$refs.content) {
    // let contentHeight = this.vm.$refs.content.offsetHeight;
    // this.vm.$refs.contentWrap.style.height = `${contentHeight + 5}px`;
    // }

    this.component.methods.switchScroll(false);
};
ResultPopup.destroy = function () {
    if (this.vm) {
        $(this.vm.$el).remove();
        this.vm && this.vm.$destroy();
        this.vm = null;
    }
};

export default ResultPopup;
