<template>
    <member-login :module-id="moduleId"></member-login>
</template>
<script>
import MemberLogin from './module/index.vue';

export default {
    name: 'MemberLoginVisitor',
    components: {
        MemberLogin,
    },
    props: {
        moduleId: {
            type: Number,
            default: 0,
        },
    },
};
</script>
