import { MobileAuthPopupService, MOBILE_AUTH_MESSAGE_TYPES } from '@/services';
import { mobiIng } from '@/shared/fai';
import { getThemeColor } from '@/shared/theme-color';
import { addRequestPrefix } from '@/shared/util';

export function pbSaveBodyStyle() {
    // eslint-disable-next-line no-undef
    if (typeof g_saveBodyIdStyle != 'undefined' && g_saveBodyIdStyle != null) {
        // eslint-disable-next-line no-undef
        jm('#g_body').attr('style', g_saveBodyIdStyle);
        // eslint-disable-next-line no-undef
        g_saveBodyIdStyle = null;
    }
}

export function pbRemoveBodyStyle() {
    window.g_saveBodyIdStyle = jm('#g_body').attr('style');
    jm('#g_body').attr('style', '');
}

function pdComment(showAfterReview = false) {
    var creator = jm.trim(jm('#productCommentCreator').val());
    var comment = jm('#productCommentInput').val();
    var validateCode = jm('#validateCodeInput').val();
    var productId = jm('#productCommentInput').attr('productId');

    // 获取评论上传的图片的路径
    var imgs = jm('.J_productCommImg').find('img');
    var commImgList = [];
    if (imgs != null && typeof imgs != 'undefined') {
        var imgsLength = imgs.length;
        if (imgsLength > 0) {
            for (var i = 0; i < imgsLength; i++) {
                var tempImg = imgs.eq(i);
                if (tempImg != null && tempImg != 'undefined') {
                    var newImg = {};
                    var imgId = tempImg.attr('_id');
                    var imgName = tempImg.attr('_name');
                    var imgSize = tempImg.attr('_file_size');
                    var tempFileId = tempImg.attr('_file_id');
                    var fileWidth = Number(tempImg.attr('_fileWidth') || 0);
                    var fileHeight = Number(tempImg.attr('_fileHeight') || 0);
                    var fileType = Number(tempImg.attr('_fileType') || 0);
                    newImg['imgId'] = imgId;
                    newImg['imgName'] = imgName;
                    newImg['imgSize'] = imgSize;
                    newImg['tempFileId'] = tempFileId;
                    newImg['imgWidth'] = fileWidth;
                    newImg['imgHeight'] = fileHeight;
                    newImg['imgType'] = fileType;
                    commImgList.push(newImg);
                }
            }
        }
    }

    var urlStr =
        addRequestPrefix({ newPath: '/ajax', oldPath: 'ajax' }) + '/productComment_h.jsp?cmd=addWafCk_submitPC';
    var submitData = {
        validateCode,
        commentsObjId: productId,
        creator,
        commImgList: JSON.stringify(commImgList),
        comment,
    };

    Mobi.ing(LS.siteFormSubmitIng);
    //已限制了输入  不必检验最大长度
    jm.ajax({
        type: 'POST',
        url: urlStr,
        data: submitData,
        error() {
            Mobi.ing(LS.js_systemError);
            jm('#captchaChange').click();
            Mobi.logMonitor(5933, true);
        },
        success(result) {
            var data = jm.parseJSON(result);
            if (!data || !data.success) {
                jm('#captchaChange').click();
            }

            if (data.hasFW) {
                Mobi.ing(data.msg);
                return;
            }

            if (data.logMonitor) {
                Mobi.logMonitor(5933, true);
            }

            switch (data.msg) {
                case 1: //验证码错误
                    Mobi.ing(LS.js_captchaError);
                    break;
                case 2: //姓名错误
                    Mobi.ing(LS.creatorError);
                    break;
                case 3: //评论错误
                    Mobi.ing(LS.commentError);
                    break;
                case 4: //提交成功
                    Mobi.getCommentDiv('getWafNotCk_product', productId);
                    jm('.comm-find-tb-remove').remove();
                    if (showAfterReview === false) {
                        Mobi.ing(LS.commentSuccess, 1); // 评论成功
                    } else {
                        Mobi.ing(LS.commentSuccessNeedReview, 1); // 评论提交成功，请等待管理员审核
                    }
                    break;
                case 5: //参数错误，请重新填写
                    Mobi.ing(LS.paramError);
                    break;
                case 6: //评论仅对会员开通，请登录。
                    var turnToUrl = jm.encodeUrl(window.location.href);
                    window.location.href =
                        Mobi.preprocessUrl({ path: '', oldPath: '' }) +
                        'login.jsp?returnUrl=' +
                        turnToUrl +
                        '&errno=11';
                    break;
                case 7: //评论功能关闭。
                    Mobi.ing(LS.commentClosed);
                    break;
                case 8: //系统错误
                    Mobi.ing(LS.commentSubmitError);
                    break;
                case 9: //评论数据已超过总数量限制。
                    Mobi.ing(LS.commentCountLimit);
                    break;
                case 10: // 系统维护中
                    Mobi.ing(LS.commentMaintainIng);
                    break;
                default:
            }
        },
    });
}

export async function pdCommentAddCom() {
    var creator = jm.trim(jm('#productCommentCreator').val());
    var comment = jm('#productCommentInput').val();
    var commentMinLen = jm('#productCommentInput').attr('minlength');
    var validateCode = jm('#validateCodeInput').val();

    if (typeof creator != 'string') {
        Mobi.ing(LS.creatorTips);
        return;
    }
    if (typeof comment != 'string' || comment == '') {
        Mobi.ing(LS.commentNotEmpty);
        return;
    }
    if (comment.length < commentMinLen) {
        Mobi.ing(jm.format(LS.commentLenTips, jm.encodeHtml(commentMinLen)));
        return;
    }
    if (typeof validateCode != 'string' || validateCode == '') {
        Mobi.ing(LS.memberInputCaptcha);
        return;
    }

    const mobileAuthPopupService = new MobileAuthPopupService(getThemeColor());
    const { needAuth, showAfterReview } = await mobileAuthPopupService.needAuth(
        MOBILE_AUTH_MESSAGE_TYPES.PRODUCT_COMMENT
    );
    if (needAuth) {
        mobileAuthPopupService.createSubmitHandler(
            () => {
                pdComment(showAfterReview);
            },
            (err) => {
                mobiIng(err.msg);
            }
        );
        mobileAuthPopupService.show();
    } else {
        pdComment(showAfterReview);
    }
}
