import { isDevEnv, REQUEST_BASE_URL } from '@/shared/env';
export const getcanvasTool = function (canvas) {
    // 不提供不给过
    if (!canvas) {
        return;
    }
    var ctx = initCanvas(canvas);
    var baseFontSize;
    var canvasTool = {};
    canvasTool.ctx = ctx;
    canvasTool.getFactor = getFactor;
    canvasTool.getImgUrl = getImgUrl;
    canvasTool.loadImg = loadImg;
    canvasTool.baseFontSize = baseFontSize;
    canvasTool.initCanvas = initCanvas;
    canvasTool.drawText = drawText;
    canvasTool.Rect = Rect;
    canvasTool.Point = Point;
    canvasTool.drawRoundedRect = drawRoundedRect;
    canvasTool.Round = Round;
    canvasTool.drawRound = drawRound;
    canvasTool.drawRoundedRoundNeedClipEnd = drawRoundedRoundNeedClipEnd;
    canvasTool.drawRoundedRectNeedClipEnd = drawRoundedRectNeedClipEnd;
    // 产品相关
    canvasTool.PdCanvas = {};

    canvasTool.doCanvas = doCanvas;

    /*计算显示大小因子，根据设计稿大小来 designWidth： 设计稿大小 showWidth：显示大小单位rem，value：设计稿值*/
    function getFactor(designWidth, showWidth, value) {
        return baseFontSize * ((value / designWidth) * showWidth);
    }
    function getImgUrl(url) {
        var token = jm('#_TOKEN').attr('value');
        let host = isDevEnv ? REQUEST_BASE_URL : `${document.location.protocol}//${document.location.host}`;
        return host + '/api/manage/linkSetting/mobiImgTransfer?_TOKEN=' + token + '&imgUrl=' + jm.encodeUrl(url);
    }
    function loadImg(obj, callBack) {
        obj.onload = function () {
            callBack();
        };
    }
    /*初始化canvas 并返回ctx对象*/
    function initCanvas(canvas) {
        if (canvas == null) {
            return;
        }
        var ctx = canvas.getContext('2d');
        // 屏幕的设备像素比
        var devicePixelRatio = window.devicePixelRatio || 1;
        // 浏览器在渲染canvas之前存储画布信息的像素比
        var backingStoreRatio =
            ctx.webkitBackingStorePixelRatio ||
            ctx.mozBackingStorePixelRatio ||
            ctx.msBackingStorePixelRatio ||
            ctx.oBackingStorePixelRatio ||
            ctx.backingStorePixelRatio ||
            1;
        // canvas的实际渲染倍率
        var ratio = devicePixelRatio / backingStoreRatio;

        canvas.width = canvas.width * ratio;
        canvas.height = canvas.height * ratio;

        baseFontSize = jm('html').css('font-size');
        baseFontSize = Number(baseFontSize.substring(0, baseFontSize.indexOf('p'))) * ratio;

        return ctx;
    }
    /*只画两行*/
    /*
     * 参数列表 *为全必须
     * fontInfo{font, style, align, baseLine *}
     * placeInfo{maxWidth, textX, textY, offset *}
     * overHold *
     * underlineInfo{fontSize, color, uderLineWidth, uderLineAddY} 可选，可能存在不适用情况
     */
    function drawText(text, fontInfo, placeInfo, overHold, maxDrawCount, underlineInfo) {
        if (!text) {
            return;
        }
        // 文本绘制属性
        ctx.save();
        ctx.font = fontInfo.font;
        ctx.fillStyle = fontInfo.style;
        ctx.textAlign = fontInfo.align;
        ctx.textBaseline = fontInfo.baseLine;
        /*位置信息*/
        var maxWidth = placeInfo.maxWidth,
            textX = placeInfo.textX,
            textY = placeInfo.textY,
            texYOffset = placeInfo.offset;
        var lastSubStrIndex = 0,
            lineWidth = 0,
            drawCount = 1;

        if (!maxDrawCount) {
            maxDrawCount = 2; // 只绘制两行
        }
        for (var i = 0; i < text.length; i++) {
            lineWidth += ctx.measureText(text[i]).width;
            if (drawCount >= maxDrawCount) {
                // 绘制最后一行
                if (lineWidth + ctx.measureText(overHold).width > maxWidth) {
                    ctx.fillText(text.substring(lastSubStrIndex, i - 1) + overHold, textX, textY); //绘制截取部分
                    doDrawUnderLine(ctx.measureText(text.substring(lastSubStrIndex, i - 1) + overHold).width);
                    break;
                } else if (i == text.length - 1) {
                    ctx.fillText(text.substring(lastSubStrIndex, i + 1), textX, textY);
                    doDrawUnderLine(ctx.measureText(text.substring(lastSubStrIndex, i + 1)).width);
                    break;
                }
                continue;
            }
            if (lineWidth > maxWidth) {
                ctx.fillText(text.substring(lastSubStrIndex, i - 1), textX, textY); //绘制截取部分
                doDrawUnderLine(ctx.measureText(text.substring(lastSubStrIndex, i - 1)).width);
                drawCount++;
                textY += texYOffset;
                lineWidth = ctx.measureText(text[i]).width;
                lastSubStrIndex = i - 1;
                i--;
            }
            if (i == text.length - 1) {
                ctx.fillText(text.substring(lastSubStrIndex, i + 1), textX, textY);
                doDrawUnderLine(ctx.measureText(text.substring(lastSubStrIndex, i + 1)).width);
            }
        }
        function doDrawUnderLine(tempLineWidth) {
            if (underlineInfo) {
                //画下划线
                drawUnderLine(
                    textX,
                    textY + underlineInfo.uderLineAddY,
                    tempLineWidth,
                    underlineInfo.fontSize,
                    underlineInfo.color,
                    underlineInfo.uderLineWidth
                );
            }
        }
        ctx.restore();
    }
    function drawUnderLine(x, y, width, size, color, lineWidth) {
        x -= width / 2;
        y += size + lineWidth;
        ctx.save();
        ctx.beginPath();
        ctx.strokeStyle = color;
        ctx.lineWidth = lineWidth;
        ctx.moveTo(x, y);
        ctx.lineTo(x + width, y);
        ctx.stroke();
        ctx.restore();
    }
    function Rect(x, y, w, h) {
        return { x, y, width: w, height: h };
    }
    function Point(x, y) {
        return { x, y };
    }
    /*
     * styleInfo{fillStyle*, strokeStyle*, lineWidth}
     */
    function drawRoundedRect(rect, r, styleInfo, isClip) {
        //画圆角方块
        if (typeof styleInfo == 'string') {
            var styleTemp = styleInfo;
            styleInfo = {
                fillStyle: styleTemp,
                strokeStyle: styleTemp,
            };
        }
        var ptA = Point(rect.x + r, rect.y);
        var ptB = Point(rect.x + rect.width, rect.y);
        var ptC = Point(rect.x + rect.width, rect.y + rect.height);
        var ptD = Point(rect.x, rect.y + rect.height);
        var ptE = Point(rect.x, rect.y);
        ctx.save();
        ctx.beginPath();
        ctx.fillStyle = styleInfo.fillStyle;
        if (styleInfo.lineWidth) {
            ctx.lineWidth = styleInfo.lineWidth;
        }
        ctx.strokeStyle = styleInfo.strokeStyle;
        ctx.moveTo(ptA.x, ptA.y);
        ctx.arcTo(ptB.x, ptB.y, ptC.x, ptC.y, r);
        ctx.arcTo(ptC.x, ptC.y, ptD.x, ptD.y, r);
        ctx.arcTo(ptD.x, ptD.y, ptE.x, ptE.y, r);
        ctx.arcTo(ptE.x, ptE.y, ptA.x, ptA.y, r);

        ctx.stroke();
        ctx.fill();
        if (!isClip) {
            ctx.closePath();
            ctx.restore();
        } else {
            ctx.clip();
        }
    }
    function drawRoundedRectNeedClipEnd() {
        //使用clip切割时最后调用此方法用来结束drawRoundedRect的属性
        ctx.closePath();
        ctx.restore();
    }
    /*圆形begin*/
    function Round(x, y, r) {
        return { x, y, radius: r };
    }
    function drawRound(round, style, globalAlpha, isClip) {
        var avatarCentreX = round.x;
        var avatarCentreY = round.y;
        var avatarRadius = round.radius;
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(avatarCentreX, avatarCentreY);
        ctx.fillStyle = style;
        ctx.globalAlpha = globalAlpha;
        ctx.arc(avatarCentreX, avatarCentreY, avatarRadius, 0, Math.PI * 2, true);
        ctx.fill();
        if (!isClip) {
            ctx.closePath();
            ctx.restore();
        } else {
            ctx.clip();
        }
    }
    function drawRoundedRoundNeedClipEnd() {
        //使用clip切割时最后调用此方法用来结束drawRound的属性
        ctx.closePath();
        ctx.restore();
    }
    /*圆形end*/

    /*
  方法执行遍历options对象数组，执行顺序为数组顺序
  optiongs 对象数组。 示例结构如下
  [
    {
      type: 'image',
      data: {
        obj: 图片对象,
        x: 1,
        y: 1,
        w: 1,
        h: 1
      },
    },
    {
      type: 'text',
      data: {
        text: '文本',
        fontInfo: {
          font: "1px SourceHanSansCN-Regular", //字体
            style: "#363636",
            align: "left",
            baseLine: "top"
        },
        placeInfo: {
            maxWidth: 1,       			// 最大宽度
            textX: 1, 					// x坐标
            textY: 1,					// y坐标
            offset: 1 					// 行高
        },
        overHold: '超出替代文本',
        maxDrawCount: 1,				// 行数
        underlineInfo: {
          fontSize: 0,
          color: '#fff',
          uderLineWidth: 0,
          uderLineAddY: 0
        }
      }
    },
    {
      type: 'roundedRect',
      data: {
        areaInfo: {
          x: 1,
          y: 1,
          w: 1,
          h: 1
        },
        r: 1,
        areaStyleInfo: {
            fillStyle: gradientLinear,		// 渐变色或者颜色字符串
            strokeStyle: gradientLinear,	// 渐变色或者颜色字符串
            lineWidth: 1					// 线宽
        },
        clipInfo: {							// 可选，控制clip，提供到第几个位置（从0开始，并且在该对象index之后）开始前执行drawRoundedRectNeedClipEnd
          bengin: true,
          endArrayIndex: 1
        }
      }
    },
    {
      type: 'round',
      data: {
        roundInfo: {
          x: 1,
          y: 1,
          r: 1
        },
        style: '',
        globalAlpha: 1.0,					// 透明度
        clipInfo: {							// 可选，控制clip，提供到第几个位置（从0开始，并且在该对象index之后）开启前执行drawRoundedRoundNeedClipEnd
          bengin: true,
          endArrayIndex: 1
        }
      }
    },
    {
      type: 'productMarketing',
      data: {
        fontInfo: {
            font: "bold " + 10 + "px sans-serif",
            style: "#FC7228",
            align: "center",
            baseLine: "middle"
        },
        marketingRectInfo: {
            rectX: 1,
            rectY: 1,
            marginWidth: 1,
            height: 1,
            r: 1,
            bgStyle: '#FFF0C8'
        },
        marketingData: {					// 为undefined时outText不为空
          marketingStyle:(*必须sale、secKill、cutPrice、groupPrice),
          cutTimes: 0,
          groupNum: 0
        },
        outText: ''							// 文案，与marketingData独立
      }
    },
    {
      type: 'productPrice',
      data: {
        showInfo: {
            choiceCurrencyVal: choiceCurrencyVal,
            finallyShowPrices: {
                firstPrice: 1.0
            }
        },
        fontInfo: {
            choiceCurrencyValFont: 10+ "px sans-serif",
            priceFont: "bold " + 10 + "px sans-serif",
            style: "#FC7228",
            align: "left",
            baseLine: "alphabetic"
        },
        placeInfo: {
            maxWidth: 1,
            choiceCurrencyX: 1,
            choiceCurrencyY: 1
        },
        enhanceInfo: {					// 可选type 为2时只支持一个价格显示，可以别设置整数小数小数
           interval: -1,
           type: 2,
           decimalPlaceInfo: {
                font: "bold " + 10 + "px sans-serif"
           }
        }
      }
    },
    {
      type: 'productTechnicalSupport',
      data: {
        showInfo: {
            oem: false,
            showFaiscoSupport: true
        },
        fontInfo: {
            font: 10 + "px SourceHanSansCN-Regular",
            style: "#CACACA",
            align: "center",
            baseLine: "top"
        },
        placeInfo: {
            textX: 1,
            textY: 1
        }
      }
    }
  ]

  calbacks 为回调方法列表，里面放有回调index（0开始）表明在options第几个之前进行回调。可提供多个回调方法
  [
    {
      index: 0,
      callback: function (){

      }
    },
    {
      index: 0,
      callback: function (){

      }
    }
    ...
  ]
*/
    function doCanvas(options, callbacks) {
        var clipIndexBefore = -1;

        if (!options.length || options.length <= 0) {
            return;
        }
        for (var i = 0; i < options.length; i++) {
            if (callbacks && callbacks.length > 0) {
                for (var j = 0; j < callbacks.length; j++) {
                    var tempCallbackInfo = callbacks[j];
                    if (tempCallbackInfo.index == i) {
                        tempCallbackInfo.callback();
                    }
                }
            }

            if (i == clipIndexBefore) {
                drawRoundedRectNeedClipEnd();
                drawRoundedRoundNeedClipEnd();
            }
            var arrayInfo = options[i];
            if (!arrayInfo || !arrayInfo.type || !arrayInfo.data) {
                continue;
            }
            var type = arrayInfo.type || '';
            var data = arrayInfo.data || {};

            switch (type) {
                case 'image':
                    if (!data.obj) {
                        continue;
                    }
                    ctx.drawImage(data.obj, data.x, data.y, data.w, data.h);
                    break;
                case 'text':
                    drawText(
                        data.text,
                        data.fontInfo,
                        data.placeInfo,
                        data.overHold,
                        data.maxDrawCount,
                        data.underlineInfo
                    );
                    break;
                case 'roundedRect':
                    var tempIsClip = false;
                    if (data.clipInfo && data.clipInfo.bengin) {
                        tempIsClip = true;
                        clipIndexBefore = data.clipInfo.endArrayIndex || -1;
                    }
                    var tempAreaRect = Rect(data.areaInfo.x, data.areaInfo.y, data.areaInfo.w, data.areaInfo.h);
                    drawRoundedRect(tempAreaRect, data.r, data.areaStyleInfo, tempIsClip);
                    break;
                case 'round':
                    // eslint-disable-next-line no-redeclare
                    var tempIsClip = false;
                    if (data.clipInfo && data.clipInfo.bengin) {
                        tempIsClip = true;
                        clipIndexBefore = data.clipInfo.endArrayIndex || -1;
                    }
                    var tempRound = Round(data.roundInfo.x, data.roundInfo.y, data.roundInfo.r);
                    canvasTool.drawRound(tempRound, data.style, data.globalAlpha, tempIsClip);
                    break;
                case 'productMarketing':
                    canvasTool.PdCanvas.drawMarketing(
                        data.fontInfo,
                        data.marketingRectInfo,
                        data.marketingData,
                        data.outText
                    );
                    break;
                case 'productPrice':
                    canvasTool.PdCanvas.drawPrice(data.showInfo, data.fontInfo, data.placeInfo, data.enhanceInfo);
                    break;
                case 'productTechnicalSupport':
                    canvasTool.PdCanvas.drawTechnicalSupport(data.showInfo, data.fontInfo, data.placeInfo);
                    break;
            }
        }
    }
    // 产品相关
    /*
     * 参数列表 *为全必须
     * fontInfo{font, style, align, baseLine *}
     * rectInfo{rectX, rectY, marginWidth, height, r, bgStyle *}
     * marketingData 可选 {marketingStyle（*必须sale、secKill、cutPrice、groupPrice），cutTimes，groupNum}
     * outText 可选
     */
    canvasTool.PdCanvas.drawMarketing = function (fontInfo, rectInfo, marketingData, outText) {
        // 营销活动
        var text = outText || '';
        if (marketingData && !outText) {
            switch (marketingData.marketingStyle) {
                case 'sale':
                    text = marketingData.marketingName || '促销';
                    break;
                case 'secKill':
                    text = '秒杀价';
                    break;
                case 'cutPrice':
                    text = marketingData.cutTimes + '人帮砍';
                    break;
                case 'groupPrice':
                    text = marketingData.groupNum + '人团';
                    break;
            }
        }
        if (text == '') {
            return;
        }

        ctx.font = fontInfo.font;
        var rectX = rectInfo.rectX,
            rectY = rectInfo.rectY,
            rectWidth = rectInfo.marginWidth + ctx.measureText(text).width,
            rectHeight = rectInfo.height;
        var rect = Rect(rectX, rectY, rectWidth, rectHeight);
        drawRoundedRect(rect, rectInfo.r, rectInfo.bgStyle, true);
        ctx.save();
        ctx.font = fontInfo.font;
        ctx.fillStyle = fontInfo.style;
        ctx.textAlign = fontInfo.align;
        ctx.textBaseline = fontInfo.baseLine;
        ctx.fillText(text, rectX + rectWidth / 2, rectY + rectHeight / 2 + 2);
        drawRoundedRectNeedClipEnd();
        ctx.restore();
    };
    /*
* 参数列表 *为全必须
* showInfo {choiceCurrencyVal, finallyShowPrices{firstPrice*, secondPrice} *}
* fontInfo{choiceCurrencyValFont, priceFont, style, align, baseLine *}
* placeInfo{maxWidth, choiceCurrencyX, choiceCurrencyY *}
  enhanceInfo{interval, type, decimalPlaceInfo{font, interval}} type 为2时只支持一个价格显示，可以别设置整数小数小数
*/
    canvasTool.PdCanvas.drawPrice = function (showInfo, fontInfo, placeInfo, enhanceInfo) {
        var choiceCurrencyVal = showInfo.choiceCurrencyVal,
            finallyShowPrices = showInfo.finallyShowPrices;
        var choiceCurrencyX = placeInfo.choiceCurrencyX,
            finallyShowPricesX = choiceCurrencyX;
        var maxWidth = placeInfo.maxWidth,
            lineWidth = 0;
        var interval = 0.2 * baseFontSize,
            type = 1,
            decimal = '.00';
        if (enhanceInfo) {
            interval = enhanceInfo.interval || 0.2 * baseFontSize;
            type = enhanceInfo.type || 1;
        }

        ctx.save();
        ctx.font = fontInfo.choiceCurrencyValFont;
        ctx.fillStyle = fontInfo.style;
        ctx.textAlign = fontInfo.align;
        ctx.textBaseline = fontInfo.baseLine;
        // 计算价格位置，输出符号
        var choiceCurrencyValWidth = ctx.measureText(choiceCurrencyVal).width;
        finallyShowPricesX = choiceCurrencyX + interval + choiceCurrencyValWidth;
        ctx.fillText(choiceCurrencyVal, choiceCurrencyX, placeInfo.choiceCurrencyY);

        ctx.font = fontInfo.priceFont;
        var firstPrice = finallyShowPrices.firstPrice,
            secondPrice = finallyShowPrices.secondPrice;
        var showPrice = firstPrice;
        if (secondPrice) {
            showPrice = firstPrice + '~' + secondPrice;
        }
        if (type == 2) {
            showPrice = Math.floor(firstPrice) + '';
            decimal = '.' + firstPrice.toFixed(2).split('.')[1];
            ctx.save();
            ctx.font = enhanceInfo.decimalPlaceInfo.font;
            maxWidth = maxWidth - ctx.measureText('.00').width;
            ctx.restore();
        }

        // 兼容长价格
        if (choiceCurrencyValWidth + ctx.measureText(showPrice).width > maxWidth) {
            lineWidth = choiceCurrencyValWidth;
            for (var i = 0; i < showPrice.length; i++) {
                lineWidth += ctx.measureText(showPrice[i]).width;
                if (lineWidth > maxWidth && showPrice[i - 1] != '.') {
                    showPrice = showPrice.substring(0, i) + '···';
                    break;
                }
            }
        }
        // 输出价格
        ctx.fillText(showPrice, finallyShowPricesX, placeInfo.choiceCurrencyY);
        // 输出小数
        if (type == 2 && (showPrice + '').indexOf('···') == -1) {
            var decimalX = finallyShowPricesX + ctx.measureText(showPrice + '').width;
            ctx.font = enhanceInfo.decimalPlaceInfo.font;
            ctx.fillText(decimal, decimalX + (enhanceInfo.decimalPlaceInfo.interval || 0), placeInfo.choiceCurrencyY);
        }

        ctx.restore();
    };
    /*
     * 参数列表 *为全必须
     * showInfo {oem, showFaiscoSupport *}
     * fontInfo{font, style, align, baseLine *}
     * placeInfo{textX, textY *}
     */
    canvasTool.PdCanvas.drawTechnicalSupport = function (showInfo, fontInfo, placeInfo) {
        // oem不显示技术支持
        if (showInfo && (top._oem || showInfo.oem || !showInfo.showFaiscoSupport)) {
            return;
        }
        ctx.save();
        ctx.font = fontInfo.font;
        ctx.fillStyle = fontInfo.style;
        ctx.textAlign = fontInfo.align;
        ctx.textBaseline = fontInfo.baseLine;

        ctx.fillText('— 由凡科商城提供技术支持 —', placeInfo.textX, placeInfo.textY);
        ctx.restore();
    };

    /*没办法写成闭包的方式*/
    return canvasTool;
};
