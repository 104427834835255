let absAlignLine = {
    resize: {},
    commFunc: {},
};

//resize
var resize = absAlignLine.resize;
resize.showGap = 5;
resize.inTopLine = false;
resize.inRightLine = false;
resize.inBottomLine = false;
resize.inLeftLine = false;

resize.init = function (elem, helper, event, directionArr) {
    var compareForms = $('.form').not(elem[0]),
        elemRectData = helper[0].getBoundingClientRect();
    $('#absAlignTop, #absAlignRight, #absAlignBottom, #absAlignLeft').hide();

    if (resize.begin) {
        resize.elemParentData = elem.parent().offset();
        resize.compareData = getCompareData(compareForms); //up

        resize.keys = [];

        for (var key in resize.compareData) {
            if (Object.hasOwnProperty.call(resize.compareData, key)) {
                resize.keys.push(key);
            }
        }
    }

    compareAlignForm(elemRectData, resize.compareData, helper, elem, directionArr);
    resize.begin = false;
};

function compareAlignForm(elemRectData, compareData, helper, elem, directionArr) {
    $('#absAlignTop, #absAlignRight, #absAlignBottom, #absAlignLeft').css('opacity', 1); //resizeElem 边与 此时循环比较Elem边的距离差

    var gapTop = 0,
        gapRight = 0,
        gapBottom = 0,
        gapLeft = 0,
        //记录匹配的记录差
        saveGapTop = 0,
        saveGapRight = 0,
        saveGapBottom = 0,
        saveGapLeft = 0,
        //记录匹配高度
        saveTop = 0,
        saveRight = 0,
        saveBottom = 0,
        saveLeft = 0,
        //将所有匹配边的点进行数组保存，喜欢结束后依据此数据决定辅助线的长度或高度
        obtainTop = [elemRectData.left, elemRectData.right],
        obtainRight = [elemRectData.top, elemRectData.bottom],
        obtainBottom = [elemRectData.left, elemRectData.right],
        obtainLeft = [elemRectData.top, elemRectData.bottom],
        scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

    for (var i in compareData) {
        if (Object.hasOwnProperty.call(resize.compareData, i)) {
            var nowData = compareData[i];
            gapTop = elemRectData.top - nowData.top;
            gapRight = elemRectData.right - nowData.right;
            gapBottom = elemRectData.bottom - nowData.bottom;
            gapLeft = elemRectData.left - nowData.left; //进行匹配比较

            if ($.inArray('top', directionArr) != -1 && Math.abs(gapTop) < resize.showGap) {
                saveTop = nowData.top + scrollTop;
                obtainTop.push(nowData.left, nowData.right);
                saveGapTop = gapTop;
                resize.inTopLine = true;
            }

            if ($.inArray('right', directionArr) != -1 && Math.abs(gapRight) < resize.showGap) {
                saveRight = nowData.right;
                obtainRight.push(nowData.top, nowData.bottom);
                saveGapRight = gapRight;
                resize.inRightLine = true;
            }

            if ($.inArray('bottom', directionArr) != -1 && Math.abs(gapBottom) < resize.showGap) {
                saveBottom = nowData.bottom + scrollTop;
                obtainBottom.push(nowData.left, nowData.right);
                saveGapBottom = gapBottom;
                resize.inBottomLine = true;
            }

            if ($.inArray('left', directionArr) != -1 && Math.abs(gapLeft) < resize.showGap) {
                saveLeft = nowData.left;
                obtainLeft.push(nowData.top, nowData.bottom);
                saveGapLeft = gapLeft;
                resize.inLeftLine = true;
            }
        }
    } //浮动图片为特殊模块

    var specialElem = false;

    if (elem.hasClass('formStyle53')) {
        specialElem = true;
    } //进行吸附

    if (resize.inTopLine) {
        absAlignLine.commFunc.calLineSize('top', obtainTop);
        absAlignLine.commFunc.setAbsAlignLine('Top', saveTop);
        var helperTop = parseInt(helper.css('top').replace('px', '')) || 0;
        helper.css('top', helperTop - saveGapTop + 'px');
        resize.inTopLine = false;
        resize.showGap = 2;
    }

    if (resize.inRightLine) {
        absAlignLine.commFunc.calLineSize('right', obtainRight);
        absAlignLine.commFunc.setAbsAlignLine('Right', saveRight);
        var misTakeRight = specialElem ? 0 : 3.5;
        helper.css('width', helper.width() - saveGapRight + misTakeRight + 'px');
        resize.inRightLine = false;
        resize.showGap = 3;
    }

    if (resize.inBottomLine) {
        absAlignLine.commFunc.calLineSize('bottom', obtainBottom);
        absAlignLine.commFunc.setAbsAlignLine('Bottom', saveBottom);
        var misTakeBottom = specialElem ? 0 : 4;
        helper.css('height', helper.height() - saveGapBottom + misTakeBottom + 'px');
        resize.inBottomLine = false;
        resize.showGap = 3;
    }

    if (resize.inLeftLine) {
        absAlignLine.commFunc.calLineSize('left', obtainLeft);
        absAlignLine.commFunc.setAbsAlignLine('Left', saveLeft);

        var _misTakeBottom = specialElem ? 0 : -0.5;

        var helperLeft = parseInt(helper.css('left').replace('px', '')) || 0;
        helper.css('left', helperLeft - saveGapLeft + _misTakeBottom + 'px');
        resize.inLeftLine = false;
        resize.showGap = 2;
    }
} //获取所有模块的位置信息

function getCompareData(compareForms) {
    var alignFormsData = {},
        $winH = $(window).height();
    compareForms.each(function () {
        var _this = $(this);

        var _thisId = _this.attr('id');

        if (_thisId) {
            alignFormsData[_thisId] = _this[0].getBoundingClientRect(); //判断是否再可视区中

            if (alignFormsData[_thisId].top >= $winH || alignFormsData[_thisId].bottom <= 0) {
                return true;
            }
        }
    });
    return alignFormsData;
} //计算辅助线高或宽

absAlignLine.commFunc.calLineSize = function (dir, dataArr) {
    var minPoint = Math.min.apply(null, dataArr),
        maxPoint = Math.max.apply(null, dataArr);

    switch (dir) {
        case 'top':
            $('#absAlignTop').css({
                left: minPoint - 15 + 'px',
                width: maxPoint - minPoint + 30 + 'px',
            });
            break;

        case 'bottom':
            $('#absAlignBottom').css({
                left: minPoint - 15 + 'px',
                width: maxPoint - minPoint + 30 + 'px',
            });
            break;

        case 'level':
            $('#absAlignLevel').css({
                left: minPoint - 15 + 'px',
                width: maxPoint - minPoint + 30 + 'px',
            });
            break;

        case 'left':
            $('#absAlignLeft').css({
                top: minPoint - 15 + 'px',
                height: maxPoint - minPoint + 30 + 'px',
            });
            break;

        case 'right':
            $('#absAlignRight').css({
                top: minPoint - 15 + 'px',
                height: maxPoint - minPoint + 30 + 'px',
            });
            break;

        case 'vertical':
            $('#absAlignVertical').css({
                top: minPoint - 15 + 'px',
                height: maxPoint - minPoint + 30 + 'px',
            });
            break;

        default:
    }
}; //生成或定位辅助对齐线

absAlignLine.commFunc.setAbsAlignLine = function (direction, pos) {
    var body = $('body');

    if (body.find('.absAlign' + direction).length < 1) {
        var lineHtml = "<div id='absAlign" + direction + "' class='absAlign" + direction + "' ";

        switch (direction) {
            case 'Top':
            case 'Bottom':
            case 'Level':
                lineHtml += "style='top:" + pos + "px;' ";
                break;

            case 'Left':
            case 'Right':
            case 'Vertical':
                lineHtml += "style='right:" + pos + "px;' ";
                break;

            default:
        }

        lineHtml += '></div>';
        body.append(lineHtml);
    } else {
        switch (direction) {
            case 'Top':
                body.find('.absAlign' + direction).css({
                    top: pos + 'px',
                    display: 'block',
                });
                break;

            case 'Bottom':
                body.find('.absAlign' + direction).css({
                    top: pos - 1 + 'px',
                    display: 'block',
                });
                break;

            case 'Level':
                body.find('.absAlign' + direction).css({
                    top: pos + 'px',
                    display: 'block',
                });
                break;

            case 'Left':
                body.find('.absAlign' + direction).css({
                    left: pos + 'px',
                    display: 'block',
                });
                break;

            case 'Right':
                body.find('.absAlign' + direction).css({
                    left: pos - 1 + 'px',
                    display: 'block',
                });
                break;

            case 'Vertical':
                body.find('.absAlign' + direction).css({
                    left: pos + 'px',
                    display: 'block',
                });
                break;

            default:
        }
    }
};

export default absAlignLine;
