import { registerLogDogApi } from '@/shared/log';
import { registerIngApi } from '@/shared/fai';
import { registerPopupZone } from '@/features/popup-zone/visitor';

/**
 * 访客态顶层API
 * @param { import('vue') } Vue
 */
export function registerVisitorAPI(Vue) {
    registerLogDogApi(Vue);
    registerIngApi(Vue);
    registerPopupZone(Vue);
}
