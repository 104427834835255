var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "product_audio_player_wrapper",
      class: _vm.productAudioPlayerWrapperClass,
    },
    [
      _c(
        "div",
        { staticStyle: { position: "relative", display: "inline-block" } },
        [
          _c(_vm.imgTag, {
            tag: "component",
            staticClass:
              "product_audio_player_banner proDetailImg J_img_lazyload",
            class: _vm.imgZoomClass,
            attrs: { "src-original": _vm.picPath },
          }),
          _vm._v(" "),
          _vm.isShowTryTipsBtn
            ? _c("div", { staticClass: "product_audio_try_tips" }, [
                _vm._v(
                  "试听前" +
                    _vm._s(_vm.audioTryMin) +
                    "分钟，购买后可收听完整内容"
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isShowBuyTips
            ? _c("div", { staticClass: "product_audio_try_tips" }, [
                _vm._v("当前内容购买后才可收听"),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "product_audio_player" }, [
        _c("audio", {
          ref: "audio",
          on: {
            canplay: _vm.oncanplay,
            timeupdate: _vm.audioTimeUpdate,
            ended: _vm.audioEnd,
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "product_audio_player_progress_wrapper",
            style: _vm.progressWrapperStyle,
          },
          [
            _c(
              "div",
              {
                ref: "progressLine",
                staticClass: "product_auido_player_progress_base_line",
                on: _vm._d({}, [_vm.downEvent, _vm.controlAudioProgress]),
              },
              [
                _c("div", {
                  staticClass:
                    "product_audio_player_progress_checked g_oldThemeBgColor g_main_bgColor_v3",
                  style: _vm.checkLineStyle,
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass:
                    "product_audio_player_progress_hanlde g_oldThemeBgColor g_main_bgColor_v3",
                  style: _vm.handleStyle,
                  on: _vm._d({}, [_vm.downEvent, _vm.moveControlAudioProgress]),
                }),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "product_audio_player_time_area" }, [
              _c(
                "div",
                {
                  staticClass:
                    "product_audio_player_current_time product_audio_player_time",
                },
                [_vm._v(_vm._s(_vm._f("transTime")(_vm.currentTime)))]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "product_audio_player_all_time product_audio_player_time",
                },
                [_vm._v(_vm._s(_vm._f("transTime")(_vm.duration)))]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "product_audio_player_tool" }, [
          _c(
            "svg",
            {
              staticClass: "product_audio_player_float",
              style: _vm.floatStyle,
              on: { click: _vm.showWXfloatTips },
            },
            [_c("use", { attrs: { "xlink:href": "#jzm-vicon_switch" } })]
          ),
          _vm._v(" "),
          _c(
            "svg",
            {
              staticClass: "product_audio_player_retreat",
              on: { click: _vm.retreatHandler },
            },
            [_c("use", { attrs: { "xlink:href": "#jzm-vicon_retreat" } })]
          ),
          _vm._v(" "),
          _c(
            "svg",
            {
              staticClass:
                "product_audio_player_switch g_main_color_v3 g_oldThemeColor",
              style: _vm.switchStyle,
              on: { click: _vm.switchStatus },
            },
            [_c("use", { attrs: { "xlink:href": _vm.playStatusIcon } })]
          ),
          _vm._v(" "),
          _c(
            "svg",
            {
              staticClass: "product_audio_player_forward",
              on: { click: _vm.forwardHandler },
            },
            [_c("use", { attrs: { "xlink:href": "#jzm-vicon_forward" } })]
          ),
          _vm._v(" "),
          _c(
            "svg",
            {
              staticClass: "product_audio_player_multiple",
              on: { click: _vm.switchSpeedHandler },
            },
            [_c("use", { attrs: { "xlink:href": _vm.multipleIcon } })]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isShowTryTips,
                expression: "isShowTryTips",
              },
              {
                name: "dom-portal",
                rawName: "v-dom-portal",
                value: _vm.target,
                expression: "target",
              },
            ],
            staticClass: "product_audio_player_wx_tips",
          },
          [
            _c("span", { staticClass: "product_audio_player_wx_tips_text" }, [
              _c("span", [_vm._v("点击右上角“...”，选择")]),
              _vm._v(" "),
              _c(
                "svg",
                { staticClass: "product_audio_player_floatingwindow" },
                [
                  _c("use", {
                    attrs: { "xlink:href": "#jzm-vicon_floatingwindow" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("span", [_vm._v("边听边聊微信")]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "product_audio_player_wx_tips_hide_btn",
                on: { click: _vm.hideHanlder },
              },
              [_vm._v("我知道了")]
            ),
            _vm._v(" "),
            _c("svg", { staticClass: "product_audio_player_wx_tips_tri" }, [
              _c("use", { attrs: { "xlink:href": "#jzm-vicon_triangle" } }),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }