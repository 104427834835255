import { removeCtrlStyleCss, removeCtrlStyleCssList, setCtrlStyleCss, setCtrlStyleCssList } from '@/shared/style';

import { bindTouchAndClickEvent } from '@/shared/event';
import { getPreviewObject } from '@/shared/getPreviewObject';

export function removeAllSwipeMenuClass() {
    removeClass('moveAnimation');
    removeClass('moveRight');
    jm('#navbar').removeClass('open');
    jm('#navButton').removeClass('navButtonOpen');
    jm('#navExistOffPanel').removeClass('navButtonOpen');

    function removeClass(className) {
        jm('#webHeaderBox').removeClass(className);
        jm('#webBannerBox').removeClass(className);
        jm('#webContainerBox').removeClass(className);
        jm('#customerServiceDiv').removeClass(className);
        jm('#webFooter').removeClass(className);
        jm('#bgm_icon').removeClass(className);
    }
}

export function hideNavItemContainer() {
    jm('#navbarList').removeAttr('style');
    jm('#navbar').find('.navLeft').hide();
    jm('#navbar').find('.navRight').hide();
}

export function removeNavIconPositionFix() {
    var navbarList = jm('#navbarList'),
        navItemIcon = navbarList.find('.icon-navItemIcon');

    if (VITE_APP_MODE !== 'visitor') {
        navItemIcon.each(function (index, el) {
            let styleId = jm(el).parent().attr('id');
            removeCtrlStyleCssList('mobiStyleNav', styleId, [
                { cls: '.icon-navItemIcon', key: 'width' },
                { cls: '.icon-navItemIcon', key: 'height' },
            ]);
        });
    }
}

export function hideOfficalNavSubMenu(remove, skinPattern) {
    var navbar = jm('#navbar'),
        type = checkSubMenuType(skinPattern),
        layoutId = Fai.top._templateLayoutId,
        subMenuAnimation = getSecMenuAnimationName(skinPattern);

    if (type == 'fold' || type == 'fold2') {
        removeCtrlStyleCssList('mobiStyleNav', '', [{ cls: '.subMenuOpen', keu: 'background-color' }]);
    }

    navbar.removeClass('jz_navbar_sub_menu_open_1');
    navbar.find('.subMenuOpen').removeClass('subMenuOpen');
    navbar.find('.jz_navbar_sub_menu_open').removeClass('jz_navbar_sub_menu_open');
    navbar.data('subMenuOpen', 0);

    if (
        (layoutId == 4 || subMenuAnimation == 'translationLeft') &&
        subMenuAnimation == 'translationLeft' &&
        !remove &&
        !(!!skinPattern && (skinPattern.layout == 3 || skinPattern.layout == 8) && type == 'fold')
    ) {
        jm('.nav_offical_sub_menu_wrap').css({
            left: '100%',
            width: '0',
            height: '0',
        });
    } else if (type == 'panel2' && !remove) {
        jm('.nav_offical_sub_menu_wrap').css({
            top: '100%',
        });
    } else {
        jm('.nav_offical_sub_menu_wrap').remove();
    }
}

/* 若增加了主题，可以修改这里选择二级导航的类型 */
export function checkSubMenuType(skinPattern) {
    return Fai.top._openThemeV3 ? checkThemeV3() : checkThemeV1();
    function checkThemeV3() {
        var type = 'fold';
        switch (skinPattern && skinPattern.advance.routine.subMenuType) {
            case 1:
                type = 'panel';
                break; // 左右切换动画
            case 2:
                type = 'fold';
                break; // 折叠
            case 3:
                type = 'bubble_horizon';
                break; // 水平便签
            case 4:
                type = 'bubble';
                break; // 垂直便签
            case 5:
                type = 'dragDown';
                break; // 下拉标签
            case 6:
                type = 'fold2';
                break; // 折叠带圆点
        }

        return type;
    }

    function checkThemeV1() {
        var type = 'fold',
            layoutId = Fai.top._templateLayoutId,
            panel_tmpIdList = [1022, 1006],
            bubble_horizon_tmpIdList = [1016, 1008],
            dragDown_tmpIdList = [1005, 1019],
            fold2_tmpIdList = [1010, 1020, 326];
        //这些特殊的之后配进主题样式里面

        switch (layoutId) {
            case 2:
                type = 'fold';
                break;
            case 0:
            case 3:
            case 6:
                type = 'bubble';
                break;
            case 4:
                type = 'panel';
                break; // 左右切换动画
            case 1:
                type = 'panel2';
                break; // 切换动画上下切换
            case 7:
                type = 'fold2';
                break; // 二级菜单带圆点。
            case 8:
                type = 'dragDown';
                break;
        }

        // layout不为4的特殊主题（优先级更高）
        if (panel_tmpIdList.indexOf(Fai.top._templateFrameId) > -1) {
            type = 'panel';
        } else if (bubble_horizon_tmpIdList.indexOf(Fai.top._templateFrameId) > -1) {
            type = 'bubble_horizon';
        } else if (dragDown_tmpIdList.indexOf(Fai.top._templateFrameId) > -1) {
            type = 'dragDown';
        } else if (fold2_tmpIdList.indexOf(Fai.top._templateFrameId) > -1) {
            type = 'fold2';
        }

        return type;
    }
}

/* 二级导航动画类名 */
export function getSecMenuAnimationName(skinPattern) {
    var className = '';
    if (!Fai.top._openThemeV3) {
        className += Fai.top._templateFrameId;
    } else {
        // 预留3.0动画接口
        switch (skinPattern && skinPattern.advance.routine.subMenuType) {
            case 1:
                className += 'translationLeft';
                break;
            case 2:
            case 3:
            case 5:
            case 8:
        }
    }

    return className;
}

export function initOfficalSubMenu(skinPattern) {
    var type = checkSubMenuType(skinPattern),
        subMenuClassName = 'jz_subMenu_' + getSecMenuAnimationName(skinPattern),
        $navbar = jm('#navbar');

    if (!Fai.top._openThemeV3) {
        $navbar.removeClass('jz_subMenu_' + skinPattern);
    }

    hideOfficalNavSubMenu(true, skinPattern);
    $navbar
        .removeClass(
            'jz_subMenu_fold jz_subMenu_fold2 jz_subMenu_bubble jz_subMenu_bubble_horizon jz_subMenu_panel jz_subMenu_panel2 jz_subMenu_dragDown jz_subMenu_bar jz_subMenu_translationLeft'
        )
        .addClass('jz_subMenu_' + type);

    $navbar.removeClass(subMenuClassName).addClass(subMenuClassName);
}

function removeClass(className) {
    jm('#webHeaderBox').removeClass(className);
    jm('#webBannerBox').removeClass(className);
    jm('#webContainerBox').removeClass(className);
    jm('#customerServiceDiv').removeClass(className);
    jm('#webFooter').removeClass(className);
    jm('#bgm_icon').removeClass(className);
    jm('.J_newsBottom').removeClass(className);
}

export function resetNavBarState(layoutId) {
    fixNavAnimation();
    jm('#navbar').removeClass('open');
    jm('#navExistOffPanel').removeClass('navPanelOpen');
    jm('#navButton').removeClass('navButtonOpen');
    removeClass('moveRight');
    if (layoutId === 2) {
        jm('#webTop').css('height', '');
    }
}

export function navSwipeMenu(layoutId) {
    var menubtnclass = 'navButton',
        hasClick = false; // 是否曾经点过
    addClass('moveAnimation');

    function addClass(className) {
        //注释掉webHeaderBox的动画特效，因为这个特效会在手机转屏时候出现计算高度错误
        //jm("#webHeaderBox").addClass(className);
        jm('#webBannerBox').addClass(className);
        jm('#webContainerBox').addClass(className);
        jm('#customerServiceDiv').addClass(className);
        jm('#webFooter').addClass(className);
        jm('#bgm_icon').addClass(className);
        jm('.J_newsBottom').addClass(className);
    }

    function bindEvent() {
        var navbarClassName = getPreviewObject('navbar').className;
        if (navbarClassName.indexOf('open') !== -1) {
            resetNavBarState(layoutId);
        } else {
            jm('#navbar').addClass('open');
            jm('#navExistOffPanel').addClass('navPanelOpen');
            jm('#navButton').addClass('navButtonOpen');
            addClass('moveRight');

            // fix 字体图标跟图片混用下位置不对齐情况
            if (!hasClick) {
                fixNavIconPosition();
                hasClick = true;
            }

            if (layoutId === 2) {
                jm('#webTop').css('height', '100%');
            }
        }
    }

    //设定nav高度
    if (layoutId != 5 && layoutId != 2 && layoutId !== 8) {
        autoSetNavBarHeight(layoutId);
    }

    bindTouchAndClickEvent(
        jm('#' + menubtnclass),
        function () {
            bindEvent();
            return false;
        },
        'show'
    );

    bindTouchAndClickEvent(jm('#navExistOffPanel'), function () {
        bindEvent();
        return false;
    });

    // fix 文章详情显示顶部和底部评论事，导航动画使 webcontainer transfrom，导致底部评论 fix 失效
    jm('.J_newsBottom').appendTo(jm('#g_web'));
}

/*
    0：标签型 -- 横幅上面（左右滑动）
    1：标签型 -- 在底部（左右滑动）
    2：抽屉型 -- 左展开、右展开、全铺、半铺
    4：悬浮型 -- 全铺面板弹出
    3：标签型 -- 横幅上面（下拉panel）
    6：标签型 -- 横幅下面（左右滑动）
    7：悬浮型 -- 左展开弹出
    8：抽屉型 -- 向下展开一行（左右滑动）
*/

export function showNavItemContainer() {
    hideNavItemContainer();
    removeNavIconPositionFix();
    const navBarDom = getPreviewObject('navbar');
    if (!navBarDom) {
        return;
    }

    var itemContainerDom = getPreviewObject('navbarList'),
        itemContainer = itemContainerDom,
        navItemsWidth = 0,
        navItemArray = navBarDom.querySelectorAll('.navItem'),
        navLineArray = navBarDom.querySelectorAll('.itemSep'),
        $navBarDom = jm(navBarDom),
        $itemContainerDom = jm(itemContainerDom),
        navBarOffset = $navBarDom.offset(),
        arrowOpen = $navBarDom.attr('_arrowopen') === 'true' || !jm.os.supportsTouch,
        docLeft = jm('#g_web').offset().left;

    for (var i = 0; i < navItemArray.length; i++) {
        navItemsWidth += navItemArray[i].offsetWidth;
    }
    for (var j = 0; j < navLineArray.length; j++) {
        navItemsWidth += navLineArray[j].offsetWidth;
    }

    // 这里执行时计算的宽度不正确是由于二级图标的样式还没出来，这里就算计算了，正确应该是在inc把二级图标拼出来
    navItemsWidth = navItemsWidth + navItemArray.length + 0.7 * window._htmlFontSize;
    let centerWidth = parseInt(jm(navBarDom).width());
    if (navItemsWidth > centerWidth) {
        if (navigator.appVersion.match(/MSIE [\d.]+/)) {
            if (parseInt(navigator.appVersion.match(/MSIE ([\d.]+)/)[1]) === 10) {
                navItemsWidth = navItemsWidth + navItemArray.length * 2;
            }
        }

        jm(itemContainer)
            .css('width', navItemsWidth + 'px')
            .removeClass('navbarList-horizontalLayout');
        var startX = jm.getCookie('startX') ? parseInt(jm.getCookie('startX')) : 0;
        if (isNaN(startX)) {
            startX = 0;
        }
        if (jm.os.supportsTouch) {
            var myScroll = new iScroll(navBarDom, {
                x: startX,
                scrollX: true,
                scrollY: false,
                mouseWheel: true,
                remarkXCoordinate: true,
                remarkNavLeftRight,
                onScrollEnd,
            });

            remarkNavLeftRight(startX, myScroll);

            //左右滑动
            // eslint-disable-next-line no-inner-declarations
            function remarkNavLeftRight(startX, myScroll) {
                var navTouchLeft = myScroll.wrapper.querySelector('.navTouchLeft'),
                    navTouchRight = myScroll.wrapper.querySelector('.navTouchRight');

                if (startX < 0) {
                    navTouchLeft.style.display = 'block';
                } else {
                    navTouchLeft.style.display = 'none';
                }

                if (myScroll.maxScrollX === myScroll.x) {
                    navTouchRight.style.display = 'none';
                } else {
                    navTouchRight.style.display = 'block';
                }
            }

            // eslint-disable-next-line no-inner-declarations
            function onScrollEnd() {
                arrowOpen && handleArrow();
            }

            $navBarDom.on('swipe', function () {
                hideOfficalNavSubMenu();
            });
        }

        // 滑动箭头逻辑
        if (arrowOpen) {
            var navLeft = jm(navBarDom).find('.navLeft'),
                navRight = jm(navBarDom).find('.navRight'),
                leftX = centerWidth - navItemsWidth;
            if (startX < 0) {
                navLeft.css('display', 'block');
                jm(itemContainer).css('transform', 'translate3d(' + leftX + 'px, 0px, 0px) scale(1)');
            }

            navRight.css('display', 'block');

            j = jm.getCookie('startX_p') ? parseInt(jm.getCookie('startX_p')) : 0;
            var max = 0;
            var distance = 0;
            var itemLine;
            if (jm(navLineArray[0]).css('display')) {
                itemLine = 0;
            } else {
                itemLine = jm(navBarDom).find('.itemSep').width() / 2;
            }
            var parent = navBarDom.offsetLeft;
            var p = [];
            jm(navBarDom)
                .find('.navItem')
                .each(function (i) {
                    p[i] = this.offsetLeft - parent;
                    max++;
                });

            navRight.unbind('click');
            navLeft.unbind('click');

            navRight.on('click', function (e) {
                e.stopPropagation();
                navRightMove(this, 'click');

                if (jm('.nav_offical_sub_menu_wrap').length > 0) {
                    hideOfficalNavSubMenu();
                }
            });

            navLeft.on('click', function (e) {
                e.stopPropagation();
                navLeftMove(this, 'click');
                if (jm('.nav_offical_sub_menu_wrap').length > 0) {
                    hideOfficalNavSubMenu();
                }
            });
        }

        // 当被遮挡，tab要自动移动到完全显示   @ken 20170919
        $navBarDom.on('click.nav', '.navItem', function () {
            var $This = jm(this),
                itemOffset = $This.offset(),
                left = jm.os.supportsTouch ? myScroll.x : jm(itemContainer).offset().left,
                itemWidth = parseInt($This.width());

            if (navBarOffset.right < itemOffset.right) {
                // 右面遮挡 向右移动一下
                if ($This.closest('.nav_1_level').length < 1) {
                    jm.cookie('startX', left - itemWidth, { path: '/' });
                }
            } else if (navBarOffset.left > itemOffset.left) {
                // 左边遮挡 向左移动
                if ($This.closest('.nav_1_level').length < 1) {
                    jm.cookie('startX', left + itemWidth, { path: '/' });
                }
            }
        });

        // eslint-disable-next-line no-inner-declarations
        function handleArrow() {
            var itemContainerOffset = $itemContainerDom.offset();
            var itemContainerLeft = itemContainerOffset.left - docLeft;
            // 右面还有栏目，出现右箭头
            Math.abs(itemContainerLeft) + centerWidth < navItemsWidth && navRight.css('display', 'block');

            // 已经在最左的栏目了，隐藏左箭头
            itemContainerLeft >= -1 && navLeft.css('display', 'none');

            // 左main还有栏目，出现左箭头
            itemContainerLeft < 0 && navLeft.css('display', 'block');

            //已经到了最右边的栏目了，隐藏右箭头
            Math.abs(itemContainerLeft) + centerWidth + 1 >= navItemsWidth && navRight.css('display', 'none');
        }

        // eslint-disable-next-line no-inner-declarations
        function navLeftMove(that, type) {
            var itemContainerOffset = $itemContainerDom.offset();
            jm(that).unbind(type);

            // 极值保护
            j >= p.length - 1 && (j = p.length - 2);
            j < 1 && (j = 1);
            distance = p[j] - p[j - 1];
            j == 1 && (distance -= itemLine); // 若等于1，则让导航滑到最左
            j--;

            var minLeft = itemContainerOffset.left - docLeft + distance;
            minLeft > 0 && (minLeft = 0);
            $itemContainerDom.css('transform', 'translate3d(' + minLeft + 'px, 0px, 0px) scale(1)');

            // 跳转后也回到这个位置
            jm.cookie('startX', minLeft, { path: '/' });
            jm.cookie('startX_p', j, { path: '/' });

            setTimeout(function () {
                handleArrow();

                jm(that).bind(type, function () {
                    navLeftMove(this, type);
                });
            }, 300);
        }

        // eslint-disable-next-line no-inner-declarations
        function navRightMove(that, type) {
            var itemContainerOffset = $itemContainerDom.offset();
            jm(that).unbind(type); // 函数节流，先取消事件，1s后再绑定事件

            // 极值保护
            j >= p.length - 1 && (j = p.length - 2);
            distance = p[j + 1] - p[j];
            j == 0 && (distance -= itemLine);
            j++;
            j == max && (j = max - 1);

            var maxLeft = itemContainerOffset.left - docLeft - distance;
            if (Math.abs(maxLeft) + centerWidth > navItemsWidth) {
                maxLeft = 0 - (navItemsWidth - centerWidth);
            }

            $itemContainerDom.css('transform', 'translate3d(' + maxLeft + 'px, 0px, 0px) scale(1)');

            // 跳转后也回到这个位置
            jm.cookie('startX', maxLeft, { path: '/' });
            jm.cookie('startX_p', j, { path: '/' });

            setTimeout(function () {
                handleArrow();

                jm(that).bind(type, function () {
                    navRightMove(this, type);
                });
            }, 300);
        }
    }
    jm(itemContainer).removeClass('navbarList-horizontalLayout'); //
}

function fixNavAnimation() {
    var fixThemes = [1028, 1000];

    if (fixThemes.includes(Fai.top._templateFrameId)) {
        var navbar = jm('#navbar');
        navbar.removeClass('banNavAnimationDelay');

        if (navbar[0].scrollTop > 0) {
            navbar.addClass('banNavAnimationDelay');
        }
    }
}

// fix 字体图标跟图片混用下位置不对齐情况，强制让图片以字体图标为准
export function fixNavIconPosition() {
    var layoutId = Fai.top._templateLayoutId,
        NAV_LEFT = 2, // 导航在左边；例如T311
        NAV_FIX = 4, // 导航在FIX；例如T306
        NAV_HEAD = 5, // 导航在头部下面；例如T326
        NAV_LEFT_FIX = 7; // 导航在左边按钮FIX，；例如T1003

    var navbarList = jm('#navbarList'),
        imgIcon = navbarList.find('.J_imgIcon'),
        fontIcon = navbarList.find('.J_fontIcon'),
        navItemIcon = navbarList.find('.icon-navItemIcon'),
        fontIconContainerW, // 图标容器的宽度
        fontIconWidth, // 字体图标的宽度
        fontIconHeight,
        firstFontIco,
        styleId;

    removeNavIconPositionFix();

    if (!(layoutId == NAV_LEFT || layoutId == NAV_FIX || layoutId == NAV_LEFT_FIX || layoutId == NAV_HEAD)) {
        return;
    }

    if (fontIcon.length < 1 || imgIcon.length < 1) {
        return;
    }
    // 先设置为auto,inline-block,让容器被字体图标撑开，再算字体图标宽度
    // 用addClass代替行内式的方式是为了避免修改元素的行内式，width与display属性，若真的有行内式，则需要fk-fixNavIconStyle里面的样式加！important，不会影响体验
    firstFontIco = jm(fontIcon[0]);
    fontIconContainerW = parseFloat(fontIcon[0].offsetWidth);
    firstFontIco.addClass('fk-fixNavIconStyle');
    fontIconWidth = parseFloat(fontIcon[0].offsetWidth);
    fontIconHeight = parseFloat(fontIcon[0].offsetHeight);
    firstFontIco.removeClass('fk-fixNavIconStyle');

    // 容器宽度比字体图标宽度大的情况下不用修复。
    if (fontIconContainerW > fontIconWidth) {
        return;
    }

    navItemIcon.each(function (index, el) {
        styleId = jm(el).parent().attr('id');
        setCtrlStyleCssList('mobiStyleNav', styleId, [
            {
                cls: '.icon-navItemIcon',
                key: 'width',
                value: fontIconWidth + 'px',
            },
            {
                cls: '.icon-navItemIcon',
                key: 'height',
                value: fontIconHeight + 'px',
            },
        ]);
    });
}

function autoSetNavBarHeight(layoutId) {
    var tipsHeight = $('#webTips')[0] ? $('#webTips')[0].offsetHeight : 0,
        viewHeight = 0,
        customerServiceHeight = Fai.top._openOnlineService ? parseInt(jm('#customerServiceDiv').height()) : 0,
        navbarPro = 4 + parseInt(jm('#navbar').css('bottom')?.replace?.('px', ''));
    if (jm.os.iphone) {
        if (layoutId == 4) {
            navbarPro = Fai.top._openOnlineService ? parseInt(jm('#customerServiceDiv').height()) + 35 : navbarPro;
            viewHeight = window.innerHeight - navbarPro - tipsHeight - customerServiceHeight;
        }
    } else if (jm.os.android) {
        viewHeight = window.screen.height / window.devicePixelRatio - window.screenTop; //这样计算百度和谷歌浏览器最后算的高度不对，这里等反馈再看，只处理对应布局
        if (layoutId == 4) {
            navbarPro = Fai.top._openOnlineService ? parseInt(jm('#customerServiceDiv').height()) + 35 : navbarPro;
            viewHeight = window.innerHeight - navbarPro - customerServiceHeight;
        }
    } else if (!jm.os.supportsTouch) {
        const $preview = jm('#preview');
        const previewHeight = parseInt($preview.height(), 10);
        viewHeight = previewHeight - tipsHeight;
        if (layoutId == 4) {
            navbarPro = Fai.top._openOnlineService ? parseInt(jm('#customerServiceDiv').height()) + 35 : navbarPro; //为了不影响其他的，先这样算
            viewHeight = previewHeight - navbarPro - customerServiceHeight;
        }
    }
    if (viewHeight > 0) {
        if (layoutId == 4) {
            var navbarListHeight =
                parseInt(jm('#navbarList').height()) +
                parseInt(jm('#navbarList').css('padding-bottom')?.replace?.('px', ''));
            if (navbarListHeight > viewHeight) {
                jm('#navbar').css('height', viewHeight + 'px');
            }
        } else {
            jm('#navbar').css('height', viewHeight + 'px');
        }
    }
}

export function setNavStyleCss(colId, cls, key, value) {
    setCtrlStyleCss('mobiStyleNav', 'navbar_' + colId, cls, key, value);
}

export function removeNavStyleCss(colId, cls, key) {
    removeCtrlStyleCss('mobiStyleNav', 'navbar_' + colId, cls, key);
}
