import { getLanCode } from './fai';

export class ValidateCode {
    static change(obj, type) {
        const lanCode = getLanCode();
        const lanCodePrefix = lanCode === '' ? '/' : `/${lanCode}/`;
        jm(obj).attr(
            'src',
            lanCodePrefix + 'validateCode.jsp?' + Math.random() * 1000 + '&validateCodeRegType=' + type
        );
    }
}
