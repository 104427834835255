import { mapGetters, mapMutations, mapState } from 'vuex';
import { initNewsEvent, newsGroupHeaderBorder } from '@/modules/news/util';
import { showTopTips } from '@/shared/tips';
import { LinkSettingUtil } from '@/shared/linkSetting/index';
import { allFixWXPA } from '@/system/shared/mobiPATipFix';
import { fixNewsDetailVideo, changeIconBg, fixFormMiddleContentBottom, fixModuleTop } from '@/system/shared/newsDetail';
import { setHrefEventHasReqArgs } from '@/modules/comm';
import { getRouterData } from '@/features/router/universal';
import { memberLogin1 } from '@/shared/member';
import { changeHref } from '@/features/router/universal';

import { encodeHtmlJs } from '@/shared/util';
import { MOBILE_AUTH_TIPS } from '@/services';
export const newsDetailMixins = {
    computed: {
        ...mapGetters(['getModuleById', 'currentPageModules']),
        ...mapState('app', ['realNameAuth']),
        ...mapState(['mobiTipList']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        options() {
            return this.module.renderOptions || {};
        },
        content() {
            return this.module.content;
        },
        newsInfo() {
            return this.options.newsInfo;
        },
        newsId() {
            return this.newsInfo.id;
        },
        pageElementList() {
            //页面元素列表
            return this.content.pageElementList;
        },
        firstPageSize() {
            //页面元素列表
            return this.content.firstPageSize;
        },
        subTitle() {
            return this.newsInfo.subTitle || '';
        },
        allowSubmitMessage() {
            const visitorMode = VITE_APP_MODE === 'visitor';
            if (visitorMode) {
                return this.realNameAuth.allowSubmitMessage;
            }
            return true;
        },
    },
    mounted() {
        this.initRealNameAuthTips();
    },
    methods: {
        ...mapMutations(['addMobiTips']),
        init() {
            let routerDate = getRouterData();
            let { returnUrl = '' } = routerDate;

            let { moduleId, options, content } = this;
            let { hasThirdCode, viewAllow, shareData, newsInfo, grapicShareInfo, isOpen, showCaptcha, isLogin } =
                options;
            if ($.isEmptyObject(newsInfo)) {
                return false;
            }
            if (!viewAllow && !(VITE_APP_MODE !== 'visitor')) {
                if (isLogin) {
                    changeHref('/?errno=14');
                } else {
                    memberLogin1(13);
                }
                return true;
            }
            Fai.top._shareData = shareData;
            initNewsEvent({
                moduleId,
                pageStyle: content.pageStyle,
                templateDesignType: window.templateDesignType,
                returnUrl: returnUrl != '' ? encodeHtmlJs(returnUrl) : '',
            });
            newsGroupHeaderBorder();
            if (hasThirdCode) {
                // 20171115 判断分销免费域名是否有第三方代码，有的话就给提示
                Fai.top._oem ? showTopTips() : showTopTips(3);
            }
            Fai.top._shareDataNewsDetail = grapicShareInfo; // 添加 图文分享信息 qjie 2019.11.11
            LinkSettingUtil.switchJump();
            if (isOpen) {
                // 开启评论
                showCaptcha && (Mobi._needFaitest = true);
                $('#g_web').append($(`#module${moduleId} .J_newsBottom`));
            }
            // 修复由于开启评论后，底部有一个输入框占位，关闭评论时，将会出现一个空白区域
            fixFormMiddleContentBottom(moduleId, isOpen);
            // 由于有些主题的 form 样式会设置 top 属性，这会影响详情页的布局
            fixModuleTop(moduleId);
            allFixWXPA(2, moduleId, isOpen);
            fixNewsDetailVideo();
            changeIconBg();
            setHrefEventHasReqArgs(jm(`#module${moduleId}`));
        },
        initRealNameAuthTips() {
            if (VITE_APP_MODE === 'visitor') {
                if (this.realNameAuth.allowShowMessage === false) {
                    this.addMobiTips(MOBILE_AUTH_TIPS);
                }
            }
        },
    },
};
