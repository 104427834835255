<template>
    <div :id="'mProductList' + moduleId" class="mProductList prContainer styleForm1">
        <template v-for="(item, index) in list">
            <div
                :key="'pr1_0_' + index"
                class="mProductTileForm J_product_item"
                :title="manageMode && item.top != 0 ? '该产品已置顶' : ''"
                :productid="item.id"
            >
                <a :href="item.mobiHtmlUrl">
                    <div class="J_prImgContainer imgClass">
                        <span class="imageMiddle"></span>
                        <inner-wrap :item="item">
                            <div class="J_prImgWrap" style="position: relative">
                                <div
                                    v-if="options.salePromotionOpen"
                                    class="g_main_bgColor_v3 g_bgColor f-productListTopPromotion"
                                    >{{ item.saleFlagName }}</div
                                >
                                <span
                                    v-if="options.openKnowPayment && item.productType == 2 && isShowLabel"
                                    class="quality_content_label"
                                    >{{ item.labelText }}</span
                                >
                                <img
                                    :alt="item.name"
                                    :class="picStretchClass"
                                    :src="options.loadingPath"
                                    :src-original="item.newPath"
                                    class="J_img_lazyload style1Img"
                                    :style="getPicStyle(item)"
                                />
                                <div v-if="options.showSoldOut" class="soldOutBox"
                                    ><img class="soldOut big" :src="options.soldOutImg"
                                /></div>
                            </div>
                        </inner-wrap>
                    </div>
                    <div :class="paramCollectionCls" :style="paramCollectionStyle" class="paramCollection">
                        <p v-if="showProName" :class="paramNameCls" class="g_link paramName">
                            <span v-if="item.isTimedAdded" class="g_oldThemeBgColor g_main_bgColor_v3 m_timedAddTag"
                                >即将开售</span
                            >
                            <span v-html="getProName(item.name)"></span>
                            <mcart
                                v-if="getShowMallCart(item) && !item.isShowPrice"
                                :options="options"
                                :pid="item.id"
                                :type="1"
                                :text="item.shopBtnTypeText"
                                :form-id="item.productFormId"
                                :cart2="true"
                            />
                        </p>
                        <prop-panel :info="item"></prop-panel>
                    </div>
                </a>
            </div>
        </template>
    </div>
</template>

<script>
import { productResultMixins } from '../mixins';
import mcart from './mcart.vue';
import propPanel from './propPanel.vue';
import { encodeHtml } from '@/shared/util';
export default {
    name: 'Type1',
    components: {
        mcart,
        propPanel,
        'inner-wrap': {
            template: `
                <div v-if="manageMode" class='fk-productListImgWrap'>
                    <div v-if="item.top != 0" class='f-productListTopFlag' >置顶</div>
                    <slot />
                </div>
                <span v-else>
                    <slot />
                </span>
            `,
            inject: ['manageMode'],
            props: {
                item: {
                    type: Object,
                    default() {
                        return {};
                    },
                },
            },
        },
    },
    mixins: [productResultMixins],
    inject: ['moduleId', 'manageMode'],
    props: {
        list: {
            type: Array,
            default() {
                return [];
            },
        },
        isShowLabel: {
            type: Number,
        },
    },
    computed: {
        picSetting() {
            return this.content.picSetting;
        },
        fontSetting() {
            return this.content.fs;
        },
        showProName() {
            return this.fontSetting.spn;
        },
        picSizeSet() {
            return this.picSetting.picSizeSet;
        },
        nameWrap() {
            return this.fontSetting.nw;
        },
        textAlignType() {
            return this.fontSetting.ta;
        },
        picCompress() {
            return this.picSetting.picCompress;
        },
        picStretchClass() {
            return this.picCompress == 1 ? 'stretchImg' : '';
        },
        paramCollectionStyle() {
            if (this.picSizeSet != 1) {
                return {};
            }
            return {
                overflow: 'hidden',
                'text-overflow': 'ellipsis',
            };
        },
        paramCollectionCls() {
            return this.picSizeSet != 1 ? 'defaultWidth' : '';
        },
        paramNameCls() {
            let cls = [];
            cls.push(this.nameWrap ? 'nameWrap' : 'paramNowrap');
            cls.push(this.textAlignType == 0 ? 'textAlign_center' : 'textAlign_left');
            return cls;
        },
    },
    methods: {
        getPicStyle(item) {
            if (this.picSizeSet != 1) {
                return {};
            }
            return {
                width: item.picWidth + 'px',
                height: item.picHeight + 'px',
            };
        },
        getProName(proName) {
            let searchword = this.getKeyInRounter('searchword');
            return this.highLightKeyword(encodeHtml(proName), searchword);
        },
        getIsOpenKnowPd(item) {
            let isKnowProduct = item.productType == 2; // 知识付费
            return this.options.openKnowPayment && isKnowProduct;
        },
        getShowMallCart(item) {
            let show = false;
            let { mall_cart, mallOpen, _sessionMemberId, booking_system, productFormShow } = this.options;
            let { MemberPrivileges } = item;
            if (mall_cart && mallOpen) {
                if ((!MemberPrivileges && _sessionMemberId != 0) || MemberPrivileges) {
                    show = true;
                }
            }
            if (this.content.type == 3) {
                if (booking_system && this.bookingOpen) {
                    show = false;
                }
            } else {
                if (booking_system && this.bookingOpen) {
                    show = true;
                } else if (this.options.productFormOpen && productFormShow == 1) {
                    show = true;
                }
            }

            return show && !this.getIsOpenKnowPd(item);
        },
    },
};
</script>

<style>
.form .formMiddle .mProductTileForm .imgClass {
    font-size: 0;
} /*修复产品筛选结果模块，样式1访客态出现空白间隙*/
</style>
