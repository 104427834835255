// @ts-check
import { MobiModule } from '@/modules/Module';
import MemberSignuVisitor from './visitor.vue';
import { MODULE_STYLE } from '@/def/module';

class MemberSignup extends MobiModule {
    constructor() {
        super(MODULE_STYLE.MEMBER_SIGNUP, { system: true });
    }

    /** @override */
    render() {
        return MemberSignuVisitor;
    }
}

export const MemberSingUp = new MemberSignup();
