var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mProductList prContainer styleForm1",
      attrs: { id: "mProductList" + _vm.moduleId },
    },
    [
      _vm._l(_vm.list, function (item, index) {
        return [
          _c(
            "div",
            {
              key: "pr1_0_" + index,
              staticClass: "mProductTileForm J_product_item",
              attrs: {
                title: _vm.manageMode && item.top != 0 ? "该产品已置顶" : "",
                productid: item.id,
              },
            },
            [
              _c("a", { attrs: { href: item.mobiHtmlUrl } }, [
                _c(
                  "div",
                  { staticClass: "J_prImgContainer imgClass" },
                  [
                    _c("span", { staticClass: "imageMiddle" }),
                    _vm._v(" "),
                    _c("inner-wrap", { attrs: { item: item } }, [
                      _c(
                        "div",
                        {
                          staticClass: "J_prImgWrap",
                          staticStyle: { position: "relative" },
                        },
                        [
                          _vm.options.salePromotionOpen
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "g_main_bgColor_v3 g_bgColor f-productListTopPromotion",
                                },
                                [_vm._v(_vm._s(item.saleFlagName))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.options.openKnowPayment &&
                          item.productType == 2 &&
                          _vm.isShowLabel
                            ? _c(
                                "span",
                                { staticClass: "quality_content_label" },
                                [_vm._v(_vm._s(item.labelText))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "J_img_lazyload style1Img",
                            class: _vm.picStretchClass,
                            style: _vm.getPicStyle(item),
                            attrs: {
                              alt: item.name,
                              src: _vm.options.loadingPath,
                              "src-original": item.newPath,
                            },
                          }),
                          _vm._v(" "),
                          _vm.options.showSoldOut
                            ? _c("div", { staticClass: "soldOutBox" }, [
                                _c("img", {
                                  staticClass: "soldOut big",
                                  attrs: { src: _vm.options.soldOutImg },
                                }),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "paramCollection",
                    class: _vm.paramCollectionCls,
                    style: _vm.paramCollectionStyle,
                  },
                  [
                    _vm.showProName
                      ? _c(
                          "p",
                          {
                            staticClass: "g_link paramName",
                            class: _vm.paramNameCls,
                          },
                          [
                            item.isTimedAdded
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "g_oldThemeBgColor g_main_bgColor_v3 m_timedAddTag",
                                  },
                                  [_vm._v("即将开售")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.getProName(item.name)),
                              },
                            }),
                            _vm._v(" "),
                            _vm.getShowMallCart(item) && !item.isShowPrice
                              ? _c("mcart", {
                                  attrs: {
                                    options: _vm.options,
                                    pid: item.id,
                                    type: 1,
                                    text: item.shopBtnTypeText,
                                    "form-id": item.productFormId,
                                    cart2: true,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("prop-panel", { attrs: { info: item } }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }