export const SiteVerDef = {
    NULL: -1, // 用于保护(返回得不到版本)
    FREE: 10, // 直销：免费版
    MALLFREE: 11, // 直销：商城免费版
    //TRIAL :20,// 直销：试用版
    COOP: 25, // 直销：合作版 20170301
    START: 27, // 直销：入门版 20180801
    STD: 30, // 直销：标准版
    STD_BUY_OUT: 31, // 直销：标准版买断
    POP: 40, // 直销：专业版	20161201之前叫推广版
    POP_BUY_OUT: 41, // 直销：网站推广版买断
    CROWN: 45, // 直销：网站皇冠版，官网类型 20180620才上线
    CROWN_BUY_OUT: 46, // 直销：网站皇冠版买断
    BIZ: 50, // 直销：商城版	20161201之前叫商务版
    ULT: 51, // 直销：商城旗舰版
    PREM: 52, // 直销：进阶版
    MULT_MERCH: 60, // 直销：商城多商户版
    DMD: 65, // 直销：商城钻石版

    OEM_TRI: 110, // 分销：试用版
    OEM_MALL_TRI: 115, // 分销：商城试用版
    OEM_LOW: 120, // 分销：初级版
    OEM_MID: 130, // 分销：中级版
    OEM_ADV: 140, // 分销：高级版
    OEM_CROWN: 145, // 分销：至尊版 ，官网类型20180801 , 对标直销皇冠版
    OEM_EXP: 150, // 分销：商城标准版 20170901前是 尊贵版
    OEM_PREM: 155, // 分销：商城高级版
    OEM_EXT: 160, // 分销：商城豪华版 20170901前是 至尊版
    OEM_MULT_MERCH: 170, // 分销：商城商户进驻版
};
