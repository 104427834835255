var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _c(
      "div",
      { staticClass: "newsDetail", attrs: { id: "newsDetail" + _vm.moduleId } },
      [
        _vm.options.hideNews
          ? _c("div", { staticClass: "newsDetail_hide" }, [
              _c("div", { staticClass: "newsDetail_hide--img" }),
              _c("p", { staticClass: "newsDetail_hide--text" }, [
                _vm._v(_vm._s(_vm.options.jz_newsAudit_tips)),
              ]),
            ])
          : [
              _vm.cusTitleStyleOn
                ? _c("div", { staticClass: "title", style: _vm.titleStyle }, [
                    _c("span", { staticClass: "titleText" }, [
                      _vm._v(_vm._s(_vm.newsInfo.title)),
                    ]),
                  ])
                : _c("h1", { staticClass: "title" }, [
                    _c("span", { staticClass: "titleText" }, [
                      _vm._v(_vm._s(_vm.newsInfo.title)),
                    ]),
                  ]),
              _vm._v(" "),
              _c("div", { staticClass: "g_separator separatorLine" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "richContent content" },
                [
                  _c("html-render", {
                    attrs: { html: _vm.newsContent, "ref-name": "newsContent" },
                  }),
                  _vm._v(" "),
                  _vm.totalSize > 1
                    ? _c("pagenation", {
                        attrs: {
                          "module-id": _vm.moduleId,
                          "total-size": _vm.totalSize,
                          "page-size": 1,
                          pageno: _vm.pageno,
                          "open-theme-v3": false,
                          "ajax-pagenation": true,
                        },
                        on: { "on-change": _vm.changePage },
                        nativeOn: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.viewList.length > 0
                ? _c(
                    "div",
                    { staticClass: "attachList" },
                    _vm._l(_vm.viewList, function (item, index) {
                      return _c("attach", {
                        key: "attach_" + index,
                        attrs: { info: item },
                      })
                    }),
                    1
                  )
                : _vm._e(),
            ],
        _vm._v(" "),
        _vm.shareOn
          ? [
              _vm.isMicromessenger
                ? [
                    _c("div", { staticClass: "sharePosition1" }, [
                      _c(
                        "div",
                        {
                          staticClass: "shareToFriendDiv",
                          attrs: { id: "shareToFriend" },
                          on: { click: _vm.showBg },
                        },
                        [
                          _c("div", { staticClass: "shareToFriendIcon" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "shareToFriend" }, [
                            _vm._v("发送给朋友"),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "sharePosition2" }, [
                      _c(
                        "div",
                        {
                          staticClass: "shareToWeiXinDiv",
                          attrs: { id: "shareToWeiXin" },
                          on: { click: _vm.showBg },
                        },
                        [
                          _c("div", { staticClass: "shareToWeiXinIcon" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "shareToWeiXin" }, [
                            _vm._v("发送到朋友圈"),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                : _c("div", { staticClass: "shareInfo" }, [
                    _c("div", { staticClass: "shareCtrl shareto" }, [
                      _vm._v(_vm._s(_vm.shareLs) + " : "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "shareCtrl sharePanel",
                        attrs: { id: "sharePanel" },
                      },
                      [
                        _vm._l(_vm.printShareLink, function (shareItem, index) {
                          return [
                            shareItem.icon == "Weixin"
                              ? _c(
                                  "a",
                                  {
                                    key: "wx_" + index,
                                    attrs: {
                                      id: "shareLinkWeixin",
                                      hidefocus: "true",
                                      title: _vm.shareLs + shareItem.n,
                                      href: _vm.isSpider
                                        ? null
                                        : "javascript:;",
                                    },
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "shareIcon",
                                      class: shareItem.icon,
                                    }),
                                  ]
                                )
                              : shareItem.icon == "qq_share"
                              ? _c(
                                  "a",
                                  {
                                    key: "qq_" + index,
                                    attrs: {
                                      id: "shareLinkQQ",
                                      hidefocus: "true",
                                      "hide-focus": "true",
                                      title: _vm.shareLs + shareItem.n,
                                      href: _vm.isSpider
                                        ? null
                                        : "javascript:;",
                                    },
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "shareIcon",
                                      class: shareItem.icon,
                                    }),
                                  ]
                                )
                              : _c(
                                  "a",
                                  {
                                    key: "other_" + index,
                                    attrs: {
                                      hidefocus: "true",
                                      "hide-focus": "true",
                                      title: _vm.shareLs + shareItem.n,
                                      href: _vm.isSpider
                                        ? null
                                        : "javascript:;",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.jumptoShareUrl(shareItem.url)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "shareIcon",
                                      class: shareItem.icon,
                                    }),
                                  ]
                                ),
                            _vm._v(" "),
                            index == 2
                              ? _c(
                                  "a",
                                  {
                                    key: "index_" + index,
                                    attrs: { "hide-focus": "true" },
                                    on: { click: _vm.showMore },
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "shareIcon showIcon",
                                      attrs: { id: "showOrHide" },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "clearfloat",
                staticStyle: { height: "5px" },
              }),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.options.isOpen && _vm.realNameAuth.allowShowMessage
          ? _c(
              "div",
              {
                staticClass: "pdCommentSwitch",
                staticStyle: { width: "90%", margin: "0 auto", clear: "both" },
                on: { click: _vm.getCommentDiv },
              },
              [
                _c("div", { staticClass: "left liuyanIcon" }),
                _vm._v(" "),
                _c("div", { staticClass: "middle" }, [
                  _vm._v(
                    _vm._s(_vm.productCumulativeCommentsLs) +
                      "(" +
                      _vm._s(_vm.options.totalSize) +
                      ")"
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "right rightArrow" }),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showPrevAndNext
          ? _c("div", { attrs: { id: "prevAndNextDiv" } }, [
              _c("table", { staticClass: "paginateTable" }, [
                _c("tbody", [
                  _c("tr", [
                    _c(
                      "td",
                      {
                        attrs: {
                          colspan: "2",
                          align: "center",
                          valign: "middle",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "pagenation",
                            attrs: {
                              id: "singleNewspagenation" + _vm.moduleId,
                            },
                          },
                          [
                            _vm.hasPrev
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "pagePrev icon-pagePrev",
                                    class: _vm.pagenPrevStyle,
                                  },
                                  [
                                    _c("a", {
                                      attrs: {
                                        hidefocus: "true",
                                        href: _vm.prevNewsUrl,
                                        newsid: _vm.prevReqArgs.id,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setUrlArgToCookie(
                                            $event,
                                            false
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                )
                              : _c("div", {
                                  staticClass:
                                    "pagePrev icon-pagePrev pageDisable",
                                  class: _vm.pagenPrevStyle,
                                }),
                            _vm._v(" "),
                            _vm.hasNext
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "pageNext icon-pageNext",
                                    class: _vm.pagenNextStyle,
                                  },
                                  [
                                    _c("a", {
                                      attrs: {
                                        hidefocus: "true",
                                        newsid: _vm.nextReqArgs.id,
                                        href: _vm.nextNewsUrl,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setUrlArgToCookie(
                                            $event,
                                            true
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                )
                              : _c("div", {
                                  staticClass:
                                    "pageNext icon-pageNext pageDisable",
                                  class: _vm.pagenNextStyle,
                                }),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.showMobiViewer
          ? _c("image-viewer", {
              attrs: {
                "z-index": _vm.zIndex,
                "initial-index": _vm.initialIndex,
                "on-close": _vm.onClose,
                "url-list": _vm.images,
                "is-mobi": true,
                "content-terminal": "mobi",
                "manage-mode": _vm.manageMode,
                "document-event-el": _vm.documentEventEl,
                "append-to-container-id": "g_body",
                "append-to-body": false,
                "disable-long-press": _vm.banCopyTextImg,
              },
            })
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }