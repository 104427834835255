<template>
    <module-frame :key="pageno" :module-id="moduleId">
        <div :class="matcherFormCls">
            <template v-if="!disableSort">
                <div v-for="(val, index) in sortOrder" :key="'sortItem_' + index" class="fk_item">
                    <template v-if="sortName == val">
                        <a :href="setSortHref(val)">
                            <div class="g_main_color_v3 g_mainColor propName">{{ getPrintName(val) }}</div>
                            <div
                                :class="desc ? 'icon-matchDownIcon' : 'icon-matchUpIcon'"
                                class="g_main_color_v3 g_mainColor matchUpDownIcon"
                            ></div>
                        </a>
                    </template>
                    <a v-else :href="setSortHref(val)">
                        <div class="propName fk_item_noSelect">{{ getPrintName(val) }}</div>
                    </a>
                </div>
            </template>
            <div v-if="isScreenOpen && options.couponId == 0" id="screenButton" class="item_right">
                <template v-if="isEdit2">
                    <a class="J_condition screen fk_item_noSelect">{{ mobi_productScreening }}</a>
                    <a class="J_condition icon-ScreenIcon screenIcon fk_item_noSelect"></a>
                </template>
                <template v-else>
                    <div class="J_condition screen fk_item_noSelect">{{ mobi_productScreening }}</div>
                    <div class="J_condition icon-ScreenIcon screenIcon fk_item_noSelect"></div>
                </template>
            </div>
            <div
                v-if="isScreenOpen && options.couponId != 0 && options.appointProductSize > 20"
                id="screenButton"
                class="item_right"
            >
                <div class="screen fk_item_noSelect">{{ mobi_productScreening }}</div>
                <div class="icon-ScreenIcon screenIcon fk_item_noSelect"></div>
            </div>
        </div>
        <div v-if="!disableSort || isScreenOpen" class="g_separator separatorLine"></div>
        <template v-if="searchList.length > 0">
            <component :is="typeComponentMap" :list="searchList" :is-show-label="isShowLabel" />
        </template>
        <div v-else class="fk-noProduct">{{ noResultFound }}</div>
        <!-- 筛选面板 -->
        <div id="productScreenFilterPanel" class="productScreenFilterPanel" style="display: none">
            <div id="basePanel" class="productScreenFilterContentPanel">
                <div id="baseScreenFilterContenter" class="productScreenFilterContenter">
                    <div id="backContent" class="productScreenFilterTitle" style="display: none">
                        <div class="icon-screenFilterRt screenFilterIcon"></div>
                        <div class="productScreenFilterTi">{{ mobi_productScreening }}</div>
                    </div>
                </div>
            </div>
        </div>
        <pagenation
            v-if="!(pageno == 1 && options.totalSize == 0)"
            :module-id="moduleId"
            :total-size="options.totalSize"
            :page-size="module.content.count"
            :pageno="pageno"
            :open-theme-v3="openThemeV3"
            :ajax-pagenation="true"
            @on-change="changePage"
            @click.native.stop=""
        >
        </pagenation>
    </module-frame>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import { getRouterData } from '@/features/router/universal';
import { COL } from '@/def/col';
import { productResultMixins } from './mixins';
import { bindImgLazyLoad } from '@/shared/imageLazyload';
import { SearchJumpProductUtils, addProductScreenFilterModel } from '../util';
import { setPrImageMaxHeight } from '@/modules/shared/photo';
import { bookingPanelClickEvent } from '@/modules/shared/product/product';
import { getPageProductResultData } from '@/api/module/productResult';
import ModuleFrame from '@/modules/frame/index.vue';
import type3 from './components/type3.vue';
import type1 from './components/type1.vue';
import pagenation from '@/components/pagenation.vue';
import { getJumpModuleCookieByType } from '@/modules/comm';
export default {
    name: 'ProductResult',
    components: {
        ModuleFrame,
        type3,
        type1,
        pagenation,
    },
    mixins: [productResultMixins],
    provide() {
        return {
            moduleId: this.moduleId,
            manageMode: VITE_APP_MODE !== 'visitor',
            lcid: this.lcid,
        };
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        let routerData = getRouterData();
        if (routerData._reqArgs && VITE_APP_MODE === 'visitor') {
            try {
                const reqData = JSON.parse(routerData._reqArgs);
                const { args = {} } = reqData;
                Object.assign(routerData, args);
            } catch (e) {
                console.error(e);
            }
        }
        let { desc } = routerData;

        return {
            manageMode: VITE_APP_MODE !== 'visitor',
            tempDesc: desc == 'true',
            routerData,
        };
    },
    computed: {
        ...mapState('app', ['openThemeV3', 'lcid', 'LS']),
        ...mapGetters(['getModuleById']),
        pageno: {
            get() {
                return this.options.pageno || 1;
            },
            set(val) {
                this.options.pageno = val;
            },
        },
        matcherFilter() {
            // 产品默认排序条件
            return this.content.mf;
        },
        screenFilter() {
            // 产品自定义浏览顺序设置 或者叫做产品筛选排序功能
            return this.content.sf;
        },
        sortMode() {
            // 产品开启排序模式选择 (0:默认, 1:不开启, 2:自定义)
            return this.matcherFilter.sm;
        },
        sortType() {
            return this.matcherFilter.st;
        },
        sortName() {
            return VITE_APP_MODE !== 'visitor'
                ? this.routerData.sortName || this.matcherFilter.sn
                : this.screenOptionCond.sortName;
        },
        disableSort() {
            // 不开启：排序方式选择
            return this.sortMode == 1;
        },
        screenOpen() {
            //是否开启 (0关闭,1开启)
            return this.screenFilter.sOpen;
        },
        isScreenOpen() {
            return this.screenOpen == 1;
        },
        isEdit2() {
            return this.content.prIsEdit2;
        },

        matcherFormCls() {
            return !this.disableSort || this.isScreenOpen ? 'matcherForm' : '';
        },
        sortOrder() {
            const DEFAULT = 0;
            const CUSTOM = 2;
            let order = [];
            switch (this.sortMode) {
                case DEFAULT:
                    order = ['name', 'addedTime', 'mallPrice'];
                    break;
                case CUSTOM:
                    order = this.matcherFilter.so;
                    break;

                default:
                    break;
            }
            return order;
        },
        desc() {
            return typeof this.routerData.desc != 'undefined' ? this.tempDesc : this.sortType == 1;
        },
        typeComponentMap() {
            return `type${this.content.type}`;
        },
        mobi_productScreening() {
            return this.LS.mobi_productScreening;
        },
        noResultFound() {
            return this.LS.noResultFound;
        },
        renderOptions() {
            return this.module.renderOptions || {};
        },
        screenOptionCond() {
            return this.renderOptions.screenOptionCond || {};
        },
        isShowLabel() {
            return this.module.content.cts;
        },
    },
    watch: {
        searchList() {
            let routerData = getRouterData();
            let { sortName = 'name', desc } = routerData;
            this.routerData = routerData;
            this.sortName = sortName;
            this.tempDesc = desc == 'true';
            this.initImgEvent();
        },
        module: {
            async handler() {
                await Vue.nextTick();
                this.init();
            },
            deep: true,
        },
    },
    mounted() {
        if (!VITE_APP_MODE !== 'visitor') {
            const pageNoStr = getJumpModuleCookieByType(this.moduleId);
            if (pageNoStr) {
                let cachePageNo = parseInt(pageNoStr);
                this.changePage(cachePageNo);
            }
        }
        this.init();
    },
    methods: {
        async changePage(pageno) {
            var self = this;
            let { searchType, nSL, searchword, psId, groupId, psc } = this.routerData;
            let moduleId = this.moduleId;
            let param = {
                pageno,
                pageSize: this.module.content.count,
                searchModuleId: psId,
                moduleId: moduleId,
                searchword,
                searchType,
                nSL,
                psc,
            };
            if (groupId) {
                param.groupId = groupId;
            }

            if (!(VITE_APP_MODE !== 'visitor')) {
                if (param.groupId == null && this.screenOptionCond.groupId != null) {
                    param.groupId = this.screenOptionCond.groupId;
                }
            }

            param[`m${moduleId}pageno`] = pageno;

            param.sortName = this.screenOptionCond.sortName;
            param.desc = this.screenOptionCond.desc;

            let result = await getPageProductResultData(param);
            let { success, data } = result;
            let { searchList } = data;
            if (success) {
                this.$nextTick(() => {
                    self.pageno = parseInt(pageno);
                    self.options.searchList = searchList;
                });
            }
        },
        setSortHref(sortName = '') {
            return this.options.sortOrderUrl[sortName];
        },
        getPrintName(fieldName) {
            return {
                mallPrice: this.LS.g_price,
                name: this.LS.productResultSName,
                addedTime: this.LS.mobi_productMatcherTime,
                sales: this.LS.g_sales,
            }[fieldName];
        },
        initImgEvent() {
            // 懒加载、幻灯片
            this.$nextTick(() => {
                bindImgLazyLoad(`module${this.moduleId}`);
            });
        },
        init() {
            let { moduleId, searchList } = this;
            let { booking_system, bookingOpen } = this.options;

            this.initImgEvent();

            SearchJumpProductUtils.bindJumpSearchProductEvent(moduleId, COL.SYS_PRODUCT_RESULT, {
                page: this.pageno,
            });

            if (searchList.length > 0) {
                Fai.top.productList = searchList;
                if (this.content.picSetting.picCompress == 1) {
                    setPrImageMaxHeight(moduleId, '.J_prImgWrap');
                }
                searchList.forEach((item) => {
                    if (booking_system && bookingOpen) {
                        bookingPanelClickEvent(`proListBookingBtn${item.id}`, 1);
                    }
                });
            }
            const routerData = this.options.screenOptionCond;
            const { mid = 0, psId = '', sortName, groupId = 0, psc = '', keyword } = routerData;
            addProductScreenFilterModel(moduleId, {
                mid: Number(mid),
                psId: Number(psId),
                desc: typeof routerData.desc === 'undefined' ? routerData.desc : true,
                sortName,
                groupId: Number(groupId),
                psc,
                keyword,
            });
            SearchJumpProductUtils.removeJumpSearchProductInfo(moduleId);
        },
    },
};
</script>

<style lang="scss">
.fk-noProduct {
    width: 100%;
    color: #707070;
    text-align: center;
    padding: 5rem 0rem;
    font-size: 0.7rem;
    line-height: 150%;
}
.matcherForm {
    margin: 0.5rem 0.75rem;
    height: 1.2rem;
}
.matcherForm .fk_item {
    float: left;
    width: 3.95rem;
    font-size: 0.8rem;
}
.matcherForm .propName {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 2.7rem;
    display: inline-block;
    vertical-align: middle;
}
.matcherForm .icon-matchDownIcon::before {
    content: '\e656';
    font-size: 0.9rem;
    margin-left: 0.1rem;
    vertical-align: middle;
}
.matcherForm .icon-matchUpIcon::before {
    content: '\e701';
    font-size: 0.9rem;
    margin-left: 0.1rem;
    vertical-align: middle;
}
.matcherForm .icon-ScreenIcon::before {
    content: '\e900';
    font-size: 0.9rem;
    vertical-align: middle;
}
.matcherForm .matchUpDownIcon {
    width: 1rem;
    display: inline-block;
}
.matcherForm .item_right {
    font-size: 0.8rem;
    max-width: 3.5rem;
    position: absolute;
    right: 0.75rem;
}
.matcherForm .screen {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 2rem;
    display: inline-block;
    vertical-align: middle;
    color: #636363;
}
.matcherForm .item_right .screenIcon {
    display: inline-block;
    color: #636363;
}

.fk-productListImgWrap .f-productListTopFlag {
    z-index: 10;
    position: absolute;
    top: 0;
    right: 0;
    width: 36px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    text-align: center;
    color: #fff;
    background-color: #fa3b00;
}
.f-productListTopPromotion {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    text-align: center;
    color: #fff;
    background-color: #fa3b00;
    padding-left: 5px;
    padding-right: 5px;
}

.productHotTextListTable div.f-productListTopFlag,
.productTextListTable div.f-productListTopFlag {
    display: inline-block;
    width: 36px;
    margin-right: 4px;
    line-height: 20px;
    text-align: center;
    color: #fff;
    background-color: #fa3b00;
    font-size: 12px;
}
.fk-productListImgWrap {
    display: inline-block;
    position: relative;
    vertical-align: top;
}
.fk-productListTitle .fk-productListImgWrap {
    vertical-align: bottom;
}
.productWaterFall .fk-productListImgWrap {
    display: block;
}

.mProductList .paramNowrap {
    white-space: nowrap;
    table-layout: fixed;
    display: block;
}

.mProductTileForm {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    text-align: center;
    width: 47%;
    margin-left: 1.5%;
    margin-right: 1.5%;
}

.productScreenFilterPanel .productScreenFilterContentPanel {
    width: 13.5rem;
    max-width: 18rem;
    background-color: #fff;
    position: absolute;
    right: 0rem;
    height: 100%;
}
.productScreenFilterPanel .productScreenFilterContenter {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    position: absolute;
    -webkit-overflow-scrolling: touch;
    left: 0;
    bottom: 3rem;
    top: 0rem;
}
.productScreenFilterPanel .productScreenFilterOptionContenter {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    position: absolute;
    -webkit-overflow-scrolling: touch;
    left: 0;
    bottom: 0rem;
    top: 0rem;
}
.productScreenFilterPanel .productScreenFilterTitle {
    padding-top: 0.5rem;
    height: 2rem;
    border-bottom: 0.4rem solid #f0f0f0;
}
.productScreenFilterPanel .productScreenFilterTitle .icon-screenFilterRt::before {
    content: '\e60e';
    font-size: 1.6rem;
    color: #636363;
}
.productScreenFilterPanel .productScreenFilterTitle .screenFilterRtIcon {
    float: left;
    width: 2rem;
}
.productScreenFilterPanel .productScreenFilterTitle .screenFilterIcon {
    float: left;
    width: 2rem;
}
.productScreenFilterPanel .productScreenFilterTitle .productScreenFilterTi {
    color: #333;
    text-align: center;
    font-size: 1rem;
    float: left;
    width: 70%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.productScreenFilterPanel .productScreenFilterLib {
    border-bottom: 0.4rem solid #f0f0f0;
    padding: 0.6rem 0rem 0.6rem 0.5rem;
    height: 1.2rem;
}
.productScreenFilterPanel .productScreenFilterLib .productScreenLibLeft {
    color: #666;
    font-size: 0.8rem;
    float: left;
    width: 4rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.productScreenFilterPanel .productScreenFilterLib .productScreenLibRight {
    font-size: 0.8rem;
    float: right;
    width: 8rem;
}
.productScreenFilterPanel .productScreenFilterLib .productScreenLibRight div {
    display: inline-block;
}
.productScreenFilterPanel .productScreenFilterLib .productScreenLibName {
    float: right;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 5rem;
}
.productScreenFilterPanel .icon-libNextMore::before {
    float: right;
    content: '\e672';
    color: #666;
    font-size: 1rem;
}
.productScreenFilterPanel .libNextMoreIcon {
    float: right;
    vertical-align: middle;
    padding-top: 0.1rem;
}
.productScreenFilterPanel .icon-nextMore::before {
    content: '\e672';
    color: #666;
    font-size: 1rem;
    vertical-align: bottom;
}
.productScreenFilterPanel .nextMoreIcon {
    float: right;
    padding-top: 0.1rem;
}
.productScreenFilterPanel .productScreenFilterContent .g_separator {
    border-bottom: 1px solid #e0e0e0;
    margin: 0rem 0.2rem;
}
.productScreenFilterPanel .productScreenFilterContent {
    background-color: #fff;
}
.productScreenFilterPanel .productScreenFilterContentScoll {
}
.productScreenFilterPanel .noScreenProp {
    color: #666;
    padding: 5rem 0rem;
    text-align: center;
    font-size: 0.7rem;
}
.productScreenFilterPanel .productScreenFilterLine {
    padding: 0.7rem 0rem 0rem 0.5rem;
    height: 1.8rem;
    font-size: 0.8rem;
    color: #666;
}
.productScreenFilterPanel .productScreenFilterLineCheck {
    padding: 0.7rem 0.5rem 0rem;
    height: 1.8rem;
    font-size: 0.8rem;
    color: #666;
    position: relative;
    border-width: 0.1rem;
    border-style: solid;
    border-color: #65cb0a;
}
.productScreenFilterPanel .productScreenFilterLineCheck .productScreenFilterLineLf {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 6rem;
}
.productScreenFilterPanel .productScreenFilterLineCheck .productScreenFilterLineLfLong {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 10rem;
}
.entry-trangle {
    border-color: transparent #65cb0a transparent transparent;
    border-style: solid;
    border-width: 1.5rem 1.5rem 0 0;
    bottom: 0;
    height: 0;
    width: 0;
    position: absolute;
    right: 0;
}
.productScreenFilterPanel .icon-check::before {
    content: '\e777';
    color: #fff;
    font-size: 1rem;
    font-weight: 900;
    vertical-align: bottom;
}
.productScreenFilterPanel .checkIcon {
    right: 0;
    position: absolute;
}
.productScreenFilterPanel .productScreenFilterLine .productScreenFilterLineLf {
    float: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 6rem;
}
.productScreenFilterPanel .productScreenFilterLine .productScreenFilterLineLfLong {
    float: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 10rem;
}
.productScreenFilterPanel .productScreenFilterLine .productScreenFilterLineRt {
    float: right;
}
.productScreenFilterPanel .productScreenFilterLine .productScreenFilterOption {
    float: right;
    white-space: nowrap;
    overflow: hidden;
    max-width: 5rem;
}
.productScreenFilterPanel .productScreenFilterBottom {
    position: absolute;
    background-color: #fff;
    bottom: 0rem;
    width: 100%;
}
.productScreenFilterPanel .productScreenFilterButton {
    color: #fff;
    font-size: 0.8rem;
    height: 1.6rem;
    margin: 0.5rem 0.4rem;
    padding-top: 0.4rem;
    text-align: center;
}

.jz_themeV3 {
    /*产品筛选结果模块 begin */
    .matcherForm {
        line-height: 1.2rem;
    }

    .matcherForm .fk_item {
        font-size: 0.65rem;
        color: #333;
    }

    .matcherForm .item_right {
        font-size: 0.65rem;
        color: #333;
    }

    .productScreenFilterPanel .productScreenFilterTitle .productScreenFilterTi {
        height: 1.6rem;
        line-height: 1.3rem;
        font-size: 0.7rem;
        color: #333;
    }

    .productScreenFilterPanel .productScreenFilterTitle .icon-screenFilterRt:before {
        font-size: 1.2rem;
    }

    .productScreenFilterPanel .productScreenFilterLine {
        font-size: 0.6rem;
    }

    .productScreenFilterPanel .productScreenFilterLine .productScreenFilterLineLf {
        margin-top: 0.25rem;
    }

    .productScreenFilterPanel .productScreenFilterLine .productScreenFilterOption {
        margin-top: 0.25rem;
    }

    .productScreenFilterPanel .productScreenFilterLineCheck {
        border-width: 1px;
    }

    .productScreenFilterPanel .productScreenFilterContent .g_separator {
        border-color: #ebebeb;
    }

    .productScreenFilterPanel .productScreenFilterLine .productScreenFilterLineLfLong {
        margin-top: 0.25rem;
    }

    .productScreenFilterPanel .productScreenFilterLineCheck .productScreenFilterLineLf {
        font-size: 0.6rem;
        margin-top: 0.2rem;
    }

    .productScreenFilterPanel .productScreenFilterLineCheck .productScreenFilterLineLfLong {
        margin-top: 0.2rem;
        font-size: 0.6rem;
    }

    .productScreenFilterPanel .checkIcon {
        line-height: 0.7rem;
    }

    .productScreenFilterPanel .productScreenFilterLib .productScreenLibLeft {
        font-size: 0.6rem;
        color: #666;
        margin-top: 0.25rem;
    }

    .productScreenFilterPanel .productScreenFilterLib .productScreenLibRight .productScreenLibName {
        font-size: 0.6rem;
        margin-top: 0.25rem;
    }

    .productScreenFilterPanel .libNextMoreIcon {
        padding-top: 0.2rem;
    }

    .productScreenFilterPanel .nextMoreIcon {
        padding-top: 0.2rem;
    }

    .productScreenFilterPanel .icon-nextMore:before {
        content: '\ea9e';
    }

    .productScreenFilterPanel .icon-libNextMore:before {
        content: '\ea9e';
    }

    .productScreenFilterPanel .productScreenFilterButton {
        border-radius: 0.1rem;
    }
    .mProductList .paramNowrap {
        white-space: nowrap;
        table-layout: fixed;
        display: block;
    }
    .mProductList .bookingBtnPlaceholder {
        display: inline-block;
        height: 0.9rem;
    }
    #mProductList3 .mProductTileForm .bookingBtnPlaceholder {
        display: block;
        height: 0.25rem;
    }
}
</style>
