<template>
    <member-profile :module-id="moduleId"></member-profile>
</template>
<script>
import MemberProfile from './module/index.vue';
export default {
    name: 'ProfileVisitor',
    components: {
        MemberProfile,
    },
    props: {
        moduleId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {};
    },
};
</script>
