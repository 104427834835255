var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "module-frame",
    { attrs: { "module-id": _vm.moduleId } },
    [
      _c("div", { attrs: { id: "profile" } }, [
        _c("div", { staticClass: "loginHeader webHeaderBg" }, [
          _c("a", {
            staticClass: "g_close icon-gClose",
            on: { click: _vm.closeClick },
          }),
          _vm._v(" "),
          _c("span", {
            staticClass: "title pageTitle",
            domProps: { textContent: _vm._s(_vm.LS.col_member_center) },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "profileContent" },
          [
            _vm.sessionMid == 0 && !_vm.manageMode
              ? [
                  _c("div", { staticClass: "noProduct icon-noLoginIcon" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "noProductTips" }, [
                    _vm._v(_vm._s(_vm.LS.mobi_member_NoLogin) + "!"),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "mallButton g_button",
                    domProps: { textContent: _vm._s(_vm.LS.memberLogin) },
                    on: { click: _vm.mallButtonClick },
                  }),
                ]
              : _vm.sessionMid == 0 && _vm.manageMode
              ? [
                  _c("jz-profile-manage", {
                    attrs: { "module-id": _vm.moduleId },
                  }),
                ]
              : [
                  _c("jz-profile-visitor", {
                    attrs: { "module-id": _vm.moduleId },
                  }),
                ],
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _vm.memberBulletinOpen
        ? [
            _c(
              "div",
              {
                staticClass: "J_cusBack modifyProfile",
                attrs: { id: "mBulletinConten" },
              },
              [
                _c("div", { staticClass: "loginHeader webHeaderBg" }, [
                  _c("span", {
                    staticClass: "g_close icon-gClose",
                    on: { click: _vm.mBulletinContenCloseClick },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "title pageTitle" }, [
                    _vm._v(_vm._s(_vm.bulletinTitle)),
                  ]),
                ]),
                _vm._v(" "),
                _vm.isNoBulletin
                  ? _c("div", { staticClass: "mallThemeBulletinCotentBg" })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", {
                  class: _vm.bulletinCotentClass,
                  domProps: { innerHTML: _vm._s(_vm.bulletinContent) },
                }),
              ]
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.isOpenMyForm
        ? [
            _c("div", { attrs: { id: "formList" } }, [
              _c(
                "div",
                { staticClass: "loginHeader webHeaderBg profileLoginHeader" },
                [
                  _c("span", {
                    staticClass: "g_close icon-gClose",
                    on: { click: _vm.formListCloseClick },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "title pageTitle" }),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "profileContent profile_content_v3" }, [
                _c("ul", { staticClass: "area" }),
              ]),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.isOpenKnowPayment
        ? [
            _c("div", { attrs: { id: "knowPayList" } }, [
              _c(
                "div",
                { staticClass: "loginHeader webHeaderBg profileLoginHeader" },
                [
                  _c("span", {
                    staticClass: "g_close icon-gClose",
                    on: { click: _vm.knowPayListCloseClick },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "title pageTitle" }),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "profileContent profile_content_v3" }, [
                !_vm.isOnlyFinishedOrder
                  ? _c(
                      "div",
                      { staticClass: "know_pay_list_kind" },
                      _vm._l(_vm.orderNavs, function (orderNav, index) {
                        return _c(
                          "div",
                          {
                            staticClass:
                              "know_pay_list_kind_item g_color g_main_color_v3",
                            class: {
                              know_pay_list_kind_item_select:
                                index === _vm.selectOrderNavIndex,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.changeOrderNav(index)
                              },
                            },
                          },
                          [_vm._v(_vm._s(orderNav))]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", {
                  staticStyle: {
                    height: "0.4266rem",
                    "background-color": "#f4f4f4",
                  },
                }),
                _vm._v(" "),
                _vm.curKnowList.length
                  ? _c(
                      "ul",
                      { staticClass: "productList" },
                      [
                        _vm._l(_vm.curKnowList, function (item) {
                          return [
                            _c("li", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: item.jumpUrl,
                                    onclick: _vm.getUrlArgsOnclickStr(item),
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "know_pay_list_item" },
                                    [
                                      _c("div", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "know_pay_list_item_header",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "订单号：" + _vm._s(item.code)
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "know_pay_list_item_pay_status g_color g_main_color_v3",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getPayStatusText(
                                                      false,
                                                      item.payStatus
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "know_pay_list_item_main",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                position: "relative",
                                                border: "solid 1px #eee",
                                                "margin-right": "0.7893rem",
                                              },
                                            },
                                            [
                                              _c("div", {
                                                staticClass:
                                                  "know_pay_list_item_product_img",
                                                style:
                                                  "background-image: url(" +
                                                  item.picPath +
                                                  ")",
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-size": "0.4rem",
                                                    color: "#fff",
                                                    "background-color":
                                                      "rgba(0, 0, 0, 0.5)",
                                                    "border-radius": "0.1rem",
                                                    padding: "0.1rem 0.2rem",
                                                    position: "absolute",
                                                    bottom: "0.2rem",
                                                    right: "0.2rem",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                " +
                                                      _vm._s(item.labelText) +
                                                      "\n                                            "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "know_pay_list_item_product_info",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "know_pay_list_item_product_name",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.productName)
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "know_pay_list_item_product_buy_time",
                                                },
                                                [
                                                  _vm._v(
                                                    "购买时间：" +
                                                      _vm._s(
                                                        _vm.formatDate(
                                                          item.payTime
                                                        )
                                                      )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "know_pay_list_item_product_price g_color g_main_color_v3",
                                                },
                                                [
                                                  _vm._v(
                                                    "¥" +
                                                      _vm._s(
                                                        item.price.toFixed(2)
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", {
                              staticStyle: {
                                height: "0.4266rem",
                                "background-color": "#f4f4f4",
                              },
                            }),
                          ]
                        }),
                      ],
                      2
                    )
                  : _c("div", { staticClass: "empty_tips" }, [
                      _c("div", { staticClass: "img" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "text" }, [
                        _vm._v("无购买记录"),
                      ]),
                    ]),
              ]),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.isOpenMyForm
        ? [
            _c(
              "div",
              {
                class: { form_detail_preview: _vm.formPreviewState },
                attrs: { id: "formDetail" },
              },
              [
                _c(
                  "div",
                  { staticClass: "loginHeader webHeaderBg profileLoginHeader" },
                  [
                    _c("span", {
                      staticClass: "g_close icon-gClose",
                      on: { click: _vm.formDetailCloseClick },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "title pageTitle" }),
                  ]
                ),
                _vm._v(" "),
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isEditState,
                      expression: "!isEditState",
                    },
                  ],
                  staticClass: "profileContent profile_content_v3",
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isEditState,
                        expression: "isEditState",
                      },
                    ],
                  },
                  [
                    _vm.isEditState
                      ? _c("form-detail-edit", {
                          on: { "submit-success": _vm.onEditSuccess },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.formPreviewState
                  ? _c(
                      "button",
                      {
                        staticClass: "form_edit_button",
                        on: { click: _vm.setEditState },
                      },
                      [_vm._v(_vm._s(_vm.LS.edit_form_result))]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "J_cusBack modifyProfile",
          attrs: { id: "modifyPersInfo" },
        },
        [
          _c("div", { staticClass: "loginHeader webHeaderBg" }, [
            _c("span", {
              staticClass: "g_close icon-gClose",
              on: { click: _vm.modifyPersInfoCloseClick },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "title pageTitle" }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "profileContent" },
            [
              _c("div", { staticClass: "g_globalLine" }, [
                _c("span", { staticClass: "icon-acctIcon g_mainColor" }),
                _vm._v(" "),
                _c("input", {
                  staticClass: "acct g_input",
                  class: _vm.modifyDisCls,
                  attrs: {
                    type: "text",
                    maxlength: "20",
                    disabled: _vm.modifyDisabled,
                  },
                  domProps: { value: _vm.memberAcct },
                }),
                _vm._v(" "),
                _vm.canResetAcct
                  ? _c("div", {
                      staticClass: "acctDes",
                      domProps: {
                        textContent: _vm._s(_vm.LS.memberProfile_onlyResetOnce),
                      },
                    })
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm._l(_vm.displayList, function (item) {
                return [
                  item.isMobile && (!_vm.isCn || !_vm.signByMobile)
                    ? _c(
                        "div",
                        {
                          key: item.fieldKey,
                          staticClass: "g_globalLine_wrap",
                          attrs: { id: "editMobile" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "g_globalLine mobileLine" },
                            [
                              _c("div", {
                                staticClass: "mobileCtVal",
                                domProps: {
                                  textContent: _vm._s(_vm.mobileCtCode),
                                },
                              }),
                              _c("div", { staticClass: "mobileCtBorder" }),
                              _c(
                                "select",
                                {
                                  staticClass:
                                    "g_input itemInput infoItem select_v3 icon-select",
                                  class: item.mustItemClass,
                                  attrs: { id: "mobileCt", name: "mobileCt" },
                                  on: {
                                    click: _vm.mobileCtClick,
                                    blur: _vm.mobileCtBlur,
                                    change: _vm.mobileCtChange,
                                  },
                                },
                                _vm._l(_vm.mobileCtNameList, function (ct) {
                                  return _c(
                                    "option",
                                    {
                                      key: ct.lskey,
                                      domProps: { value: ct.ctName },
                                    },
                                    [_vm._v(_vm._s(ct.ctShowName + ct.ctCode))]
                                  )
                                }),
                                0
                              ),
                              _c("span", {
                                class: item.customPropName,
                                domProps: { textContent: _vm._s(item.name) },
                              }),
                              _c("input", {
                                staticClass: "g_input itemInput infoItem",
                                class: item.mustItemClass,
                                attrs: {
                                  id: "mobile",
                                  type: "text",
                                  maxlength: "11",
                                  placeholder: item.propPlaceholder,
                                  name: item.fieldKey,
                                },
                                domProps: { value: _vm.preMobile },
                              }),
                              _vm._v(" "),
                              _c("span", { class: item.itemIcon }),
                            ]
                          ),
                        ]
                      )
                    : _c(
                        "div",
                        {
                          key: item.fieldKey,
                          staticClass: "g_globalLine_wrap",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "g_globalLine",
                              class: _vm.fileClass(item),
                            },
                            [
                              item.isMobile && _vm.signByMobile
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "editMobileBtn editMobileBtnThemev1",
                                      attrs: { id: "editMobileBtn" },
                                      on: { click: _vm.editMobileBtnClick },
                                    },
                                    [_vm._v("修改")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.pt == 0
                                ? [
                                    JSON.parse(item.pce).mc == 0
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "g_input",
                                            staticStyle: {
                                              display: "inline-block",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.values || "-"))]
                                        )
                                      : _c("input", {
                                          staticClass:
                                            "g_input itemInput infoItem",
                                          class:
                                            item.disClass +
                                            " " +
                                            item.mustItemClass,
                                          attrs: {
                                            type: "text",
                                            name: item.fieldKey,
                                            maxlength: "50",
                                            placeholder: item.propPlaceholder,
                                            propTip: item.propTip,
                                            disabled: item.disable,
                                          },
                                          domProps: { value: item.values },
                                        }),
                                    _vm._v(" "),
                                    _c("span", { class: item.itemIcon }),
                                  ]
                                : item.pt == 1
                                ? [
                                    JSON.parse(item.pce).mc == 0
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "g_input",
                                            staticStyle: {
                                              display: "inline-block",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.values || "-"))]
                                        )
                                      : _c(
                                          "select",
                                          {
                                            staticClass:
                                              "g_input itemInput infoItem",
                                            class: item.mustItemClass,
                                            attrs: {
                                              name: item.fieldKey,
                                              placeholder: item.propPlaceholder,
                                              propTip: item.propTip,
                                            },
                                          },
                                          [
                                            _c("option", [
                                              _vm._v(
                                                _vm._s(
                                                  item.must
                                                    ? _vm.LS
                                                        .mobi_member_PleaseInput +
                                                        " " +
                                                        item.name +
                                                        _vm.LS.mobi_member_Must
                                                    : _vm.LS
                                                        .mobi_member_PleaseInput +
                                                        " " +
                                                        item.name
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _vm._l(
                                              item.propOptions,
                                              function (prop) {
                                                return _c("option", {
                                                  key: prop,
                                                  domProps: {
                                                    value: prop,
                                                    selected:
                                                      item.values == prop,
                                                    textContent: _vm._s(prop),
                                                  },
                                                })
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                    _vm._v(" "),
                                    _c("span", { class: item.itemIcon }),
                                  ]
                                : item.pt == 2
                                ? [
                                    _c(
                                      "div",
                                      { staticClass: "member_file_tips" },
                                      [_vm._v(_vm._s(_vm.getFileTips(item)))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "member_file_contentwrap",
                                        attrs: {
                                          id:
                                            "member_file_contentwrap-" +
                                            item.id,
                                        },
                                      },
                                      [
                                        _c("input", {
                                          staticClass:
                                            "itemEdit infoItem member_file_input",
                                          class: item.mustItemClass,
                                          attrs: {
                                            id: item.fieldKey,
                                            name: item.fieldKey,
                                            propTip: item.propTip,
                                            "data-name": item.id,
                                          },
                                          domProps: { value: item.values },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "member_file_btn",
                                            class:
                                              _vm.hideAddFileIconClass(item),
                                          },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "member_file_btnupload uploadify-button",
                                            }),
                                            _vm._v(" "),
                                            _c("span", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.addFileText
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "member_file_content",
                                            class:
                                              _vm.showFileDetailClass(item),
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "member_file_meta",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "member_file_name",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.getName(item))
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "member_file_size",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.getSize(item))
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "member_file_icon",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass:
                                                      "member_file_close",
                                                    class:
                                                      _vm.hideDeleteIconClass(
                                                        item
                                                      ),
                                                  },
                                                  [
                                                    _c("use", {
                                                      attrs: {
                                                        "xlink:href":
                                                          "#jzm-v25",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    ref: "nofllow",
                                                    refInFor: true,
                                                    staticClass:
                                                      "member_file_preview_link",
                                                    attrs: {
                                                      href: "javascript:void(0)",
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "svg",
                                                      {
                                                        staticClass:
                                                          "member_file_preview",
                                                      },
                                                      [
                                                        _c("use", {
                                                          attrs: {
                                                            "xlink:href":
                                                              "#jzm-vicon_preview",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    ref: "nofllow",
                                                    refInFor: true,
                                                    staticClass:
                                                      "member_file_download_link",
                                                    attrs: {
                                                      href: _vm.getDownloadUrl(
                                                        item
                                                      ),
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "svg",
                                                      {
                                                        staticClass:
                                                          "member_file_download",
                                                      },
                                                      [
                                                        _c("use", {
                                                          attrs: {
                                                            "xlink:href":
                                                              "#jzm-v16",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              item.customPropName != ""
                                ? _c("span", {
                                    class: item.customPropName,
                                    domProps: {
                                      textContent: _vm._s(item.name),
                                    },
                                  })
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                ]
              }),
              _vm._v(" "),
              _c("input", {
                staticClass: "g_button sendIcon profileSubmit",
                class: { itemInput: _vm.manageMode },
                attrs: { type: "button", value: _vm.LS.mobi_avatarSave },
                on: { click: _vm.profileSubmitClick },
              }),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "loginErrorLine" }, [
        _c("div", { staticClass: "errorTips" }),
      ]),
      _vm._v(" "),
      _vm.signByMobile
        ? [
            _c(
              "div",
              {
                staticClass: "J_cusBack mobilePanel modifyPersInfo",
                staticStyle: { display: "none" },
                attrs: { id: "editMobilePanel" },
              },
              [
                _c(
                  "div",
                  { staticClass: "loginHeader webHeaderBg profileLoginHeader" },
                  [
                    _c("span", {
                      staticClass: "g_close icon-gClose",
                      on: { click: _vm.editMobilePanelCloseClick },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "title pageTitle" }, [
                      _vm._v("修改手机号"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "profileContent" }, [
                  _c("div", { staticClass: "m_pointBoxContainer" }, [
                    _c("div", { attrs: { id: "inputMobile" } }, [
                      _c("div", { staticClass: "defMobi" }, [
                        _vm._v("当前手机号：" + _vm._s(_vm.preMobile)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "f_editArea" }, [
                      _c(
                        "select",
                        {
                          staticClass:
                            "itemInput infoItem select_v3 icon-select mustItem mobileCtNew",
                          attrs: { id: "mobileCt" },
                          on: {
                            click: _vm.mobileCtClick,
                            blur: _vm.mobileCtBlur,
                            change: _vm.mobileCtChange,
                          },
                        },
                        _vm._l(_vm.mobileCtNameList, function (ct) {
                          return _c("option", {
                            key: ct.ctName,
                            domProps: {
                              value: ct.ctName,
                              selected: _vm.mobileCtCode == ct.ctCode,
                              textContent: _vm._s(ct.ctCode),
                            },
                          })
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c("input", {
                        staticClass: "g_input editInput mobileCtInput",
                        attrs: {
                          id: "mobile",
                          maxlength: "11",
                          placeholder: "请输入新的手机号码",
                        },
                        domProps: { value: _vm.preMobile },
                        on: { input: _vm.mobileCtInput },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "cleanBtn",
                          style: {
                            display: _vm.preMobile == "" ? "none" : "",
                          },
                          attrs: { id: "cleanBtn" },
                          on: { click: _vm.cleanBtnClick },
                        },
                        [_vm._v("x")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: { display: "none" },
                        attrs: { id: "mobileValidate" },
                      },
                      [
                        _c("div", { staticClass: "validateCodeMsg" }, [
                          _vm._v(" 请输入"),
                          _c("span", { attrs: { id: "newMobile" } }),
                          _vm._v("收到的短信验证码 "),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "f_editArea" }, [
                          _c("div", { staticClass: "validateCode" }, [
                            _vm._v("验证码"),
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            staticClass: "g_input editInput validateCodeInput",
                            attrs: {
                              id: "validateCodeInput",
                              maxlength: "8",
                              placeholder: "请输入验证码",
                            },
                            on: { input: _vm.validateCodeInput },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "cleanBtn cleanBtnValidateCode",
                              staticStyle: { display: "none" },
                              attrs: { id: "cleanValidateCodeInput" },
                              on: { click: _vm.cleanValidateCodeInputClick },
                            },
                            [_vm._v("x")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "sendValidateCode sendValidateCode1",
                              attrs: {
                                id: "messageAuthCodeClick",
                                title: _vm.LS.mobi_getMobileCode,
                              },
                              on: { click: _vm.messageAuthCodeClick },
                            },
                            [_vm._v(_vm._s(_vm.LS.mobi_getMobileCode))]
                          ),
                          _vm._v(" "),
                          _c("div", {
                            staticStyle: { display: "none" },
                            attrs: { id: "showCaptcha" },
                          }),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "f_saveBtn mobileCtBtn g_bgColor un-change",
                        attrs: { id: "saveMobileBtn" },
                        on: { click: _vm.saveMobileBtnClick },
                      },
                      [_vm._v("确定")]
                    ),
                  ]),
                ]),
              ]
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "J_cusBack modifyProfile", attrs: { id: "modifyPsw" } },
        [
          _c("div", { staticClass: "loginHeader webHeaderBg" }, [
            _c("span", {
              staticClass: "g_close icon-gClose",
              on: { click: _vm.modifyProfileCloseClick },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "title pageTitle" }, [
              _vm._v(_vm._s(_vm.LS.mobi_membert_ResetPassword)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "profileContent" }, [
            _vm.needOldPwd
              ? _c("div", { staticClass: "g_globalLine" }, [
                  _c("input", {
                    staticClass: "g_input itemInput mustItem",
                    attrs: {
                      id: "oldPsw",
                      type: "password",
                      autocomplete: "off",
                      maxlength: _vm.MemberDef.Limit.PWD_MAXLEN,
                      placeholder: _vm.LS.mobi_member_OldPassword,
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "icon-pswIcon g_mainColor" }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "g_globalLine" }, [
              _c("input", {
                staticClass: "g_input itemInput mustItem",
                attrs: {
                  id: "newPsw",
                  type: "password",
                  autocomplete: "off",
                  maxlength: _vm.MemberDef.Limit.PWD_MAXLEN,
                  placeholder: _vm.LS.mobi_memberProfile_password,
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-pswIcon g_mainColor" }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "g_globalLine" }, [
              _c("input", {
                staticClass: "g_input itemInput mustItem",
                attrs: {
                  id: "confirmPsw",
                  type: "password",
                  autocomplete: "off",
                  maxlength: _vm.MemberDef.Limit.PWD_MAXLEN,
                  placeholder: _vm.LS.mobi_memberSignup_retypePassword,
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-pswIcon g_mainColor" }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "g_globalLine" }, [
              _c("input", {
                staticClass: "g_button profileSubmit",
                class: { sendIcon: !_vm.manageMode },
                attrs: {
                  type: "button",
                  value: _vm.LS.mobi_avatarSave,
                  disabled: "",
                },
                on: { click: _vm.modifyPswSumbitClick },
              }),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "J_cusBack viewMyReward",
          attrs: { id: "viewMyReward" },
        },
        [
          _c(
            "div",
            { staticClass: "loginHeader webHeaderBg profileLoginHeader" },
            [
              _c("span", {
                staticClass: "g_close icon-gClose",
                on: { click: _vm.viewMyRewardCloseClick },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "title pageTitle" }),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "view-reward-content" }, [
            _c("ul", { staticClass: "reward-list" }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "J_cusBack modifyProfile",
          attrs: { id: "modifyIntegral" },
        },
        [
          _c("div", { staticClass: "loginHeader webHeaderBg" }, [
            _c("span", {
              staticClass: "g_close icon-gClose",
              on: { click: _vm.modifyIntegralCloseClick },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "title pageTitle" }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "profileContent" }, [
            _c("div", { staticClass: "itgPanel" }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "J_cusBack modifyProfile",
          attrs: { id: "modifyIntegralRule" },
        },
        [
          _c(
            "div",
            { staticClass: "loginHeader webHeaderBg profileLoginHeader" },
            [
              _c("span", {
                staticClass: "g_close icon-gClose",
                on: { click: _vm.modifyIntegralRuleCloseClick },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "title pageTitle" }),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "profileContent profile_content_v3" }, [
            _c("div", { staticClass: "itgPanelRule" }),
            _vm._v(" "),
            _c("span", { domProps: { innerHTML: _vm._s(_vm.richContent) } }),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.isCrownVer
        ? [
            _vm.openCollection
              ? _c(
                  "div",
                  {
                    staticClass: "J_cusBack modifyProfile collectionV2",
                    attrs: { id: "modifyCollection" },
                  },
                  [
                    _c("div", { staticClass: "loginHeader webHeaderBg" }, [
                      _c("span", {
                        staticClass: "g_close icon-gClose",
                        on: { click: _vm.modifyCollectionCloseClick },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "title pageTitle" }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "profileContent" }, [
                      _c("img", {
                        staticStyle: { display: "none" },
                        attrs: { src: _vm.imgRoot, alt: "" },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "collectionList" }),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.openCoupon
              ? _c(
                  "div",
                  {
                    staticClass: "J_cusBack modifyProfile",
                    attrs: { id: "modifyCoupon" },
                  },
                  [
                    _c("div", { staticClass: "loginHeader webHeaderBg" }, [
                      _c("span", {
                        staticClass: "g_close icon-gClose",
                        on: { click: _vm.modifyCouponCloseClick },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "title pageTitle" }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "couponListPanel" }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isOpenCutPrice && !_vm.manageMode && _vm.isCn
              ? _c(
                  "div",
                  {
                    staticClass: "J_cusBack modifyProfile",
                    attrs: { id: "modifyCutPrice" },
                  },
                  [
                    _c("div", { staticClass: "loginHeader webHeaderBg" }, [
                      _c("span", {
                        staticClass: "g_close icon-gClose",
                        on: { click: _vm.modifyCutPriceCloseClick },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "title pageTitle" }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "cutPriceListPanel" }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isOpenGroupby && !_vm.manageMode && _vm.isCn
              ? _c(
                  "div",
                  {
                    staticClass: "J_cusBack modifyProfile",
                    attrs: { id: "modifyGroupBuy" },
                  },
                  [
                    _c("div", { staticClass: "loginHeader webHeaderBg" }, [
                      _c("span", {
                        staticClass: "g_close icon-gClose",
                        on: { click: _vm.modifyGroupBuyCloseClick },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "title pageTitle" }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "groupBuyListPanel" }),
                  ]
                )
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "J_cusBack modifyProfile",
          attrs: { id: "editMemberHeadPic" },
        },
        [
          _c("div", { staticClass: "loginHeader" }, [
            _c("span", {
              staticClass: "g_close icon-gClose",
              on: { click: _vm.editMemberHeadPicCloseClick },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "picArea", attrs: { id: "picArea" } }, [
            _c("img", { attrs: { id: "headPic", _src: _vm.imgPath } }),
            _vm._v(" "),
            _c("div", { staticClass: "cover cover1" }),
            _vm._v(" "),
            _c("div", { staticClass: "cover cover2" }),
            _vm._v(" "),
            _c("img", {
              staticClass: "coverImg",
              staticStyle: { opacity: "0.5" },
              attrs: {
                id: "coverImg",
                src: _vm.resImageRoot + "/image/member/layer.png",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "cover cover4" }),
            _vm._v(" "),
            _c("div", { staticClass: "cover cover5" }),
            _vm._v(" "),
            _c("div", { staticClass: "coverRing" }),
            _vm._v(" "),
            _c("div", { staticClass: "coverBox", attrs: { id: "coverBox" } }, [
              _c("div", {
                staticClass: "dragPoint",
                staticStyle: { top: "-3px", left: "-3px" },
                attrs: { id: "dragLeftTop" },
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "dragPoint",
                staticStyle: {
                  top: "-3px",
                  right: "-3px",
                  cursor: "ne-resize",
                },
                attrs: { id: "dragRightTop" },
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "dragPoint",
                staticStyle: {
                  bottom: "-3px",
                  left: "-3px",
                  cursor: "ne-resize",
                },
                attrs: { id: "dragLeftBot" },
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "dragPoint",
                staticStyle: { bottom: "-3px", right: "-3px" },
                attrs: { id: "dragRightBot" },
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "dragPoint",
                style: _vm.dragTopCenterStyle,
                attrs: { id: "dragTopCenter" },
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "dragPoint",
                style: _vm.dragRightCenterStyle,
                attrs: { id: "dragRightCenter" },
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "dragPoint",
                style: _vm.dragLeftCenterStyle,
                attrs: { id: "dragLeftCenter" },
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "dragPoint",
                style: _vm.dragBotCenterStyle,
                attrs: { id: "dragBotCenter" },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "editHeadPicFooter" }, [
            _c("div", { staticClass: "editBtn modifyBtn" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.LS.mobi_avatarReplace) +
                  "\n                "
              ),
              _c("span", {
                staticClass: "fileUploadBtn",
                staticStyle: { width: "5rem" },
                attrs: { id: "file_upload" },
              }),
            ]),
            _vm._v(" "),
            _c("div", {
              staticClass: "editBtn saveBtn",
              attrs: { oldimgid: _vm.oldimgid, mid: _vm.sessionMid },
              domProps: { textContent: _vm._s(_vm.LS.mobi_avatarSave) },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "J_cusBack modifyProfile",
          staticStyle: { display: "none" },
          attrs: { id: "bindOtherAcct" },
        },
        [
          _c("div", { staticClass: "loginHeader webHeaderBg" }, [
            _c("span", {
              staticClass: "g_close icon-gClose",
              attrs: { id: "bindAcctBack" },
            }),
            _vm._v(" "),
            _c("span", {
              staticClass: "title pageTitle",
              domProps: {
                textContent: _vm._s(_vm.LS.mobi_member_OtherLgnBindAcct),
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "loginContent" }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "J_cusBack modifyProfile",
          staticStyle: { display: "none" },
          attrs: { id: "mSignUp" },
        },
        [
          _c("div", { staticClass: "loginHeader webHeaderBg" }, [
            _c("span", {
              staticClass: "g_close icon-gClose",
              attrs: { id: "signUpBack" },
            }),
            _vm._v(" "),
            _c("span", {
              staticClass: "title pageTitle",
              domProps: { textContent: _vm._s(_vm.LS.col_member_signup) },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "loginContent" }),
          _vm._v(" "),
          _c("div", { staticClass: "checkEmail", attrs: { id: "checkEmail" } }),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "emailAddr",
              staticStyle: { display: "none" },
              attrs: { id: "emailAddr", target: "_blank" },
            },
            [_vm._v("地址")]
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }