/*
* 2015/5/28  mobi弹出层
* triggerId  触发ID
  panelId   显示面板ID
  pageHtml  显示的HTML代码
  （panelId 或者 pageHtml二者要有其一）
  pageBg    面板背景
  direction 面板出现方向
  callback  回调函数  只在第一次点击的时候才会执行


  initwebpage 中appendPage 方法已经被抽出来，让外部可以调用这个方法，直接触，不经过click事件的绑定   ---------zhj
*/

import { bindTouchAndClickEvent } from '@/shared/event';
import { bindTransitionEnd, initShare } from '@/features/global-mobi-function/methods/share/index';
import { ing, ingTips } from '@/shared/tips';
import { copyDataToClipboard } from '@/shared/clipboard';

export function showPanel(options, fromInitWebPage) {
    var pageId = options.triggerId,
        panelId = options.panelId,
        pageHtml = options.pageHtml,
        pageBg = options.pageBg,
        closeWebPage = options.closeWebPage,
        closeCallBack = options.closeCallBack || function () {},
        direction = options.direction || 'right',
        redo = options.redo || false,
        pdThemev3 = '',
        callback = options.callback;
    fromInitWebPage = fromInitWebPage || false;

    jm('#g_body').css('overflow-y', 'hidden');

    var JMTagetId,
        JMWebPagePanel = jm('#webPagePanel' + pageId);

    /* qjie emmm，去除详情页中面板覆盖导致一键分享海报点击不了的情况 start*/

    var panelList = ['newsDetailShare', 'newsDetailV3Share', 'productDetailShare', 'graphicPosterItem'];
    var oneKeySharing = 'oneKeySharing';

    if (panelList.indexOf(pageId) != -1) {
        // 删除oneKeySharing
        let removeItem = jm('#webPagePanel' + oneKeySharing);
        jm('#' + removeItem).unbind('click.initWebPage'); // 解绑事件
        if (removeItem.length !== 0) {
            removeItem.remove();
        }
    } else if (pageId == 'oneKeySharing') {
        panelList.forEach(function (panel) {
            let removeItem = jm('#webPagePanel' + panel);
            jm('#' + removeItem).unbind('click.initWebPage'); // 解绑事件
            if (removeItem.length !== 0) {
                removeItem.remove();
            }
        });
    }
    /* qjie emmm，去除详情页中面板覆盖导致一键分享海报点击不了的情况 end*/

    if (redo && JMWebPagePanel.length) {
        JMWebPagePanel.remove();
    }
    if (Fai.top._colInfo.id == 20 && Fai.top._openThemeV3) {
        pdThemev3 = ' pdThemev3';
    }
    if (pageHtml) {
        JMTagetId = jm(pageHtml);
        JMTagetId = jm('#' + JMTagetId[0].id);
    }

    var directionClass = 'webPagePanelRight';
    if (direction === 'left') {
        directionClass = 'webPagePanelLeft';
    } else if (direction === 'top') {
        directionClass = 'webPagePanelTop';
    } else if (direction === 'bottom') {
        directionClass = 'webPagePanelBottom';
    }

    var webPagePanelBg = '';
    if (pageBg) {
        webPagePanelBg = "<div class='webPagePanelBg' style='z-index:999'></div>";
    }
    var scrollTop = 0;

    // 由于手机浏览器有自己各自的视频播放器实现, 播放视频时及播放视频后的视频播放器会脱离文档流, 常驻在页面最顶, 挡住产品购物选项弹层
    // 这里加上判断如果是购物选项弹层, 就把页面中的所有video tag隐藏掉, 在弹层关闭后重新显示video tag
    if (panelId == 'productMallOptionPanel') {
        var videoTags = jm('video');
        videoTags.css('display', 'none');
    }

    if (JMWebPagePanel.length == 0 || JMTagetId == undefined || redo) {
        jm('#g_web').append(
            "<div id='webPagePanel" +
                pageId +
                "' class='webPagePanel" +
                pdThemev3 +
                "' style='z-index:1000'>" +
                webPagePanelBg +
                '</div>'
        );
        JMWebPagePanel = jm('#webPagePanel' + pageId);
        if (pageHtml) {
            JMWebPagePanel.append(pageHtml);
            JMTagetId = jm(pageHtml);
            //jm很奇怪，jm("<div id='id'></div>")这样设置class不行，
            JMTagetId = jm('#' + JMTagetId[0].id);
        } else {
            if (!panelId) alert('panelId is null');
            JMTagetId = jm('#' + panelId);
            JMWebPagePanel.append(JMTagetId);
        }

        JMTagetId.addClass(directionClass);
        JMTagetId.addClass('webPagePanel');
        JMWebPagePanel.show();
        JMTagetId.show();

        setTimeout(function () {
            JMTagetId.addClass('webPagePanelShow');
        });

        // 添加防止背景滚动 --- ios兼容
        if (pageId == 'graphicPosterItem') {
            JMTagetId.unbind('touchmove.graphicPosterItem').bind(
                'touchmove.graphicPosterItem',
                function (e) {
                    e.preventDefault();
                },
                false
            );
        }

        if (pageBg) {
            bindTouchAndClickEvent(jm('#webPagePanel' + pageId + ' .webPagePanelBg'), function () {
                JMTagetId.removeClass('webPagePanelShow');
                JMTagetId.removeClass('webPagePanelShow');
                jm('#g_body').css({ overflow: '', height: '', top: '' });
                jm('#g_body').css('overflow-y', '');
                jm('html').css({ overflow: '' });
                jm('#g_web').removeClass('gwNoScroll');
                jm('#webPagePanel' + pageId).css('z-index', '-1');
                //如果是图文海报的图层，则直接删掉
                if (pageId == 'graphicPosterItem') {
                    jm('#webPagePanel' + pageId).remove();
                }
                window.scrollTo(0, scrollTop);
                return false;
            });
        }

        var closeNodes = closeWebPage.split(',');
        for (var i = 0; i < closeNodes.length; i++) {
            var closeNode = closeNodes[i].trim();
            bindTouchAndClickEvent(jm(closeNode), function () {
                JMTagetId.removeClass('webPagePanelShow');
                jm('#g_body').css({ overflow: '', height: '', top: '' });
                jm('#g_body').css('overflow-y', ''); //修复ie11浏览器无法清掉overflow-y的bug
                jm('html').css({ overflow: '' });
                jm('#g_web').removeClass('gwNoScroll');
                jm('#webPagePanel' + pageId).css('z-index', '-1');
                jm('#webPagePanel' + pageId).css('display', 'none'); // 关闭黑色背景，不然黑色背景会停顿，再消失
                // 如果是图文海报的图层，则直接删掉
                if (pageId == 'graphicPosterItem') {
                    jm('#webPagePanel' + pageId).remove();
                    JMTagetId.unbind('touchmove.graphicPosterItem');
                }
                window.scrollTo(0, scrollTop);

                //返回时关闭软键盘，避免键盘对页面排版的影响
                if (pageId == 'userCommentId' || pageId == 'postComment') {
                    jm('input,textarea').each(function (k, v) {
                        v.blur();
                    });
                }

                if (typeof closeCallBack == 'function') {
                    closeCallBack();
                }
                return false;
            });
            //绑定一个click事件，用于适应pc端点击事件
            jm(closeNode).unbind('click');
            jm(closeNode).bind('click', function () {
                JMTagetId.removeClass('webPagePanelShow');
                jm('#g_body').css({ overflow: '', height: '', top: '' });
                jm('#g_body').css('overflow-y', '');
                jm('html').css({ overflow: '' });
                jm('#g_web').removeClass('gwNoScroll');
                jm('#webPagePanel' + pageId).css('z-index', '-1');
                jm('#webPagePanel' + pageId).css('display', 'none'); // 关闭黑色背景，不然黑色背景会停顿，再消失
                // 如果是图文海报的图层，则直接删掉
                if (pageId == 'graphicPosterItem') {
                    jm('#webPagePanel' + pageId).remove();
                    JMTagetId.unbind('touchmove.graphicPosterItem');
                }
                window.scrollTo(0, scrollTop);

                //返回时关闭软键盘，避免键盘对页面排版的影响
                if (pageId == 'userCommentId' || pageId == 'postComment') {
                    jm('input,textarea').each(function (k, v) {
                        v.blur();
                    });
                }

                if (typeof closeCallBack == 'function') {
                    closeCallBack();
                }
                return false;
            });
        }

        bindTransitionEnd(JMTagetId, function () {
            if (!JMTagetId.hasClass('webPagePanelShow')) {
                JMWebPagePanel.hide();
            }
        });
        if (callback && !fromInitWebPage) {
            callback();
        }
    } else {
        JMWebPagePanel.show();
        JMTagetId.addClass(directionClass);
        setTimeout(function () {
            JMTagetId.addClass('webPagePanelShow');
        }, 0);
    }
    /*下面设置主要为了兼容移动设备，（主要是UC浏览器在设置一些外联样式时失效，只能改成内联样式） */
    scrollTop = window.scrollY;
    let height = jm('#g_web').height();
    JMWebPagePanel.css('height', height + 'px');
    if (panelList.indexOf(pageId) != -1 || pageId == 'oneKeySharing') {
        JMWebPagePanel.css('z-index', '10000');
    } else {
        JMWebPagePanel.css('z-index', '1000');
    }
    initShare('shareLinkWeixin', '', 'shareLinkQQ');
    jm('#copy_url').bind('click', function () {
        copyDataToClipboard({
            data: jm(this).attr('data-clipboard-text'),
            sCb() {
                ing('复制成功', 1);
            },
            fCb() {
                if (navigator.userAgent.indexOf('UCBrowser') > -1) {
                    ing('复制成功', 1);
                } else {
                    ingTips('该浏览器不支持复制链接的功能，请手动复制');
                }
            },
        });
    });
}
