<template>
    <module-frame :module-id="moduleId">
        <div :id="idSelector" class="newsList newsMore" :class="newsListClass">
            <div
                v-if="picNum === 5"
                :id="'J_newsListPanel' + moduleId"
                class="J_newsListPanel f-cardGraphicNews"
                picNum="5"
            >
                <div class="J_newsListSlidePanel f-cardGraphicNewsSlide" picNum="5">
                    <div v-if="newsList.length === 0" class="fk-noProduct">
                        {{ productNoResultText }} <br />
                        {{ productRescreenText }}
                    </div>
                    <div v-if="hasTwoColumnArrangeWay" class="newsArrayPanel" :class="['column' + newsArrangeWay]">
                        <template v-for="(newsItem, i) in newsList">
                            <news-more-item
                                :key="newsItem.id"
                                :index="i"
                                :news-item="newsItem"
                                v-bind="newsMoreItemProps"
                            ></news-more-item>
                            <div
                                v-if="hasTwoColumnArrangeWay && (isNeedTwoColumn(i) || isNeedcSeparatorLine(i))"
                                :key="newsItem.id + 'c2_sep'"
                                class="c2_separatorLine"
                            ></div>
                            <div
                                v-if="picNum != 5 && picNum != 6 && picNum != 7 && !hasTwoColumnArrangeWay"
                                :key="newsItem.id + 'g_sep'"
                                class="g_separator separatorLine"
                            ></div>
                        </template>
                    </div>
                    <template v-else>
                        <template v-for="(newsItem, i) in newsList">
                            <news-more-item
                                :key="newsItem.id"
                                :index="i"
                                :news-item="newsItem"
                                v-bind="newsMoreItemProps"
                            ></news-more-item>
                            <div
                                v-if="hasTwoColumnArrangeWay && (isNeedTwoColumn(i) || isNeedcSeparatorLine(i))"
                                :key="newsItem.id + 'c2_sep'"
                                class="c2_separatorLine"
                            ></div>
                            <div
                                v-if="picNum != 5 && picNum != 6 && picNum != 7 && !hasTwoColumnArrangeWay"
                                :key="newsItem.id + 'g_sep'"
                                class="g_separator separatorLine"
                            ></div>
                        </template>
                    </template>
                </div>
            </div>
            <template v-else>
                <div v-if="newsList.length === 0" class="fk-noProduct">
                    {{ productNoResultText }} <br />
                    {{ productRescreenText }}
                </div>
                <template v-if="hasTwoColumnArrangeWay">
                    <template v-if="picNum !== 0">
                        <news-more-item :news-item="newsList[0]" v-bind="newsMoreItemProps"></news-more-item>
                        <div class="newsArrayPanel" :class="['column' + newsArrangeWay]">
                            <template v-for="(newsItem, i) in newsList.slice(1)">
                                <news-more-item
                                    :key="newsItem.id"
                                    :index="i + 1"
                                    :news-item="newsItem"
                                    v-bind="newsMoreItemProps"
                                ></news-more-item>
                                <div
                                    v-if="
                                        hasTwoColumnArrangeWay &&
                                        (isNeedTwoColumn(i + 1) || isNeedcSeparatorLine(i + 1))
                                    "
                                    :key="newsItem.id + 'c2_sep'"
                                    class="c2_separatorLine"
                                ></div>
                                <div
                                    v-if="picNum != 5 && picNum != 6 && picNum != 7 && !hasTwoColumnArrangeWay"
                                    :key="newsItem.id + 'g_sep'"
                                    class="g_separator separatorLine"
                                ></div>
                            </template>
                        </div>
                    </template>
                    <template v-else>
                        <div class="newsArrayPanel" :class="['column' + newsArrangeWay]">
                            <template v-for="(newsItem, i) in newsList">
                                <news-more-item
                                    :key="newsItem.id"
                                    :index="i"
                                    :news-item="newsItem"
                                    v-bind="newsMoreItemProps"
                                ></news-more-item>
                                <div
                                    v-if="hasTwoColumnArrangeWay && (isNeedTwoColumn(i) || isNeedcSeparatorLine(i))"
                                    :key="newsItem.id + 'c2_sep'"
                                    class="c2_separatorLine"
                                ></div>
                                <div
                                    v-if="picNum != 5 && picNum != 6 && picNum != 7 && !hasTwoColumnArrangeWay"
                                    :key="newsItem.id + 'g_sep'"
                                    class="g_separator separatorLine"
                                ></div>
                            </template>
                        </div>
                    </template>
                </template>
                <template v-else>
                    <template v-for="(newsItem, i) in newsList">
                        <news-more-item
                            :key="newsItem.id"
                            :index="i"
                            :news-item="newsItem"
                            v-bind="newsMoreItemProps"
                        ></news-more-item>
                        <div
                            v-if="hasTwoColumnArrangeWay && (isNeedTwoColumn(i) || isNeedcSeparatorLine(i))"
                            :key="newsItem.id + 'c2_sep'"
                            class="c2_separatorLine"
                        ></div>
                        <div
                            v-if="picNum != 5 && picNum != 6 && picNum != 7 && !hasTwoColumnArrangeWay"
                            :key="newsItem.id + 'g_sep'"
                            class="g_separator separatorLine"
                        ></div>
                    </template>
                </template>
            </template>
        </div>
        <pagenation
            v-if="showPagination && newsTotal > 1"
            :module-id="moduleId"
            :total-size="newsTotal"
            :page-size="count"
            :pageno="pageno"
            :open-theme-v3="openThemeV3"
            :ajax-pagenation="true"
            @on-change="changePage"
        >
        </pagenation>
    </module-frame>
</template>

<script>
import {
    initModuleCardGraphicNewsList,
    initModuleNewsListItemManage,
    initModuletimeAxisNewsList,
} from '@/modules/news/util';
import { mapGetters, mapState } from 'vuex';
import { MODULE_ID } from './constants';
import ModuleFrame from '@/modules/frame/index.vue';
import NewsMoreItem from './components/news-more-item.vue';
import pagenation from '@/components/pagenation.vue';
import { changeHref } from '@/features/router/universal';
import { getRouterData } from '@/features/router/universal';

import { getSiteMultiLanguageUrl } from '@/shared/url';
import { getLanCode } from '@/shared/fai';
import { handleSetUrlArgToCookie } from '@/modules/comm';

export default {
    name: 'NewsMore',
    components: {
        ModuleFrame,
        NewsMoreItem,
        pagenation,
    },
    data() {
        return {
            moduleId: MODULE_ID,
            pageno: 1,
            manageMode: VITE_APP_MODE !== 'visitor',
        };
    },
    computed: {
        ...mapGetters(['getModuleById']),
        ...mapState('app', ['LS', 'openThemeV3']),
        newsList() {
            return this.module?.extInfo?.newsList ?? [];
        },
        newsTotal() {
            return this.module?.extInfo?.newsTotal ?? 0;
        },
        siteProfFlag() {
            return this.module?.extInfo?.siteProfFlag ?? 0;
        },
        module() {
            return this.getModuleById(this.moduleId);
        },
        moduleContent() {
            return this.module?.content ?? {};
        },
        picNum() {
            return this.moduleContent?.pn ?? 0;
        },
        showLine() {
            return this.moduleContent.sl;
        },
        topVisible() {
            return !this.moduleContent?.tfs?.ih ?? false;
        },
        idSelector() {
            return `newsList${this.moduleId}`;
        },
        newsListClass() {
            return this.picNum === 7 ? 'newsList7' : '';
        },
        productNoResultText() {
            return this.LS.mobi_productNoResult;
        },
        productRescreenText() {
            return this.LS.mobi_productRescreen;
        },
        newsSelect() {
            return this.moduleContent?.ns ?? false;
        },
        topSignType() {
            return this.moduleContent?.tfs?.tt ?? 1;
        },
        mixStyleType() {
            return this.moduleContent?.ps ?? false;
        },
        topicPicPosition() {
            return this.moduleContent?.tPosition ?? 2;
        },
        picSizeType() {
            return this.moduleContent?.picSizeType ?? 1;
        },
        customWidth() {
            return this.moduleContent?.tSize?.picWidth ?? 50;
        },
        customHeight() {
            return this.moduleContent?.tSize?.picHeight ?? 50;
        },
        showComment() {
            return this.moduleContent?.ncs ?? false;
        },
        showAuthor() {
            return this.moduleContent?.nas ?? false;
        },
        hideType() {
            return this.moduleContent?.t ?? false;
        },
        hideDate() {
            return this.moduleContent?.d ?? true;
        },
        newsArrangeWay() {
            return this.moduleContent?.naw ?? 1;
        },
        count() {
            return this.moduleContent?.c ?? 5;
        },
        hasArrangeWay() {
            return this.picNum == 0 || this.picNum == 1 || this.picNum == 3; //当picNum为0 1 3 时才有新的排列样式
        },
        hasTwoColumnArrangeWay() {
            return this.hasArrangeWay && this.newsArrangeWay === 2;
        },
        newsMoreItemProps() {
            return {
                size: this.newsList.length,
                showLine: this.showLine,
                topVisible: this.topVisible,
                newsSelect: this.newsSelect,
                topSignType: this.topSignType,
                mixStyleType: this.mixStyleType,
                topicPicPosition: this.topicPicPosition,
                picSizeType: this.picSizeType,
                customWidth: this.customWidth,
                customHeight: this.customHeight,
                showComment: this.showComment,
                siteProfFlag: this.siteProfFlag,
                showAuthor: this.showAuthor,
                hideDate: this.hideDate,
                newsArrangeWay: this.newsArrangeWay,
                count: this.count,
                picNum: this.picNum,
                hideType: this.hideType,
                openRemoveUrlArgs: this.module._openRemoveUrlArgs,
            };
        },
        newsListRealLen() {
            return this.newsTotal > this.count ? this.count : this.newsTotal;
        },
        showPagination() {
            return this.moduleContent?.pageIcon ?? false;
        },
        mid() {
            return this.module?.extInfo?.mid;
        },
        groupId() {
            return this.module?.extInfo?.groupId;
        },
    },
    watch: {
        module: {
            deep: true,
            handler() {
                this.$nextTick(() => {
                    this.activateDom();
                });
            },
        },
    },
    created() {
        if (VITE_APP_MODE !== 'visitor') {
            this.$store.commit('batchUpdateNewsList', this.newsList);
        }
    },
    mounted() {
        const routerData = getRouterData();
        this.pageno = parseInt(routerData[`m${this.moduleId}pageno`] || 1, 10);
        this.activateDom();
        this.initSEOCookie();
    },
    methods: {
        initSEOCookie() {
            if (VITE_APP_MODE === 'visitor') {
                const { _reqArgs = '' } = getRouterData();
                if (!_reqArgs) {
                    return;
                }
                const args = this.getReqArgsData(_reqArgs);
                handleSetUrlArgToCookie(args);
            } else {
                handleSetUrlArgToCookie(getRouterData());
            }
        },
        getReqArgsData(reqArgs = '') {
            if (typeof reqArgs === 'string') {
                const str = this.decodeReqArgs(reqArgs);
                if (!str) {
                    return {};
                }
                return JSON.parse(str)?.args || {};
            } else {
                return reqArgs;
            }
        },
        decodeReqArgs(reqArgs = '') {
            return window.decodeURIComponent(window.decodeURIComponent(reqArgs));
        },
        activateDom() {
            if (this.picNum === 5) {
                initModuleCardGraphicNewsList({
                    moduleId: this.moduleId,
                    picNum: this.picNum,
                });
            } else if (this.picNum === 7) {
                initModuletimeAxisNewsList({
                    moduleId: this.moduleId,
                    picNum: this.picNum,
                });
            }
            if (VITE_APP_MODE !== 'visitor') {
                const lnUrl = getSiteMultiLanguageUrl();
                initModuleNewsListItemManage({
                    newsParent: `newsList${this.module.id}`,
                    news: 'newsLine',
                    newsChild: 'lineBody',
                    moduleId: this.module.id,
                    frameSrcUrl: `${lnUrl}/manage_v2/newsEdit.jsp?ram=${Math.random()}&mobiSettingState=1`,
                    newsSelect: this.newsSelect,
                });
            }
        },
        changePage(pageno) {
            const { groupId = this.groupId, _reqArgs, mid = this.mid } = getRouterData();
            const lanCode = getLanCode();

            const lanCodePrefix = lanCode === '' ? '/' : `/${lanCode}/`;

            let url = `${lanCodePrefix}nr.jsp?m${this.module.id}pageno=${pageno}&groupId=${groupId}&mid=${mid}#module${this.module.id}`;
            if (_reqArgs) {
                url += `&_reqArgs=${_reqArgs}`;
            }
            changeHref(url);
        },
        isNeedTwoColumn(index) {
            return (
                (index % 2 == 1 && this.picNum == 0) ||
                (index % 2 == 0 && index != 0 && this.picNum == 1) ||
                (index % 2 == 0 && index != 0 && this.picNum == 3)
            );
        },
        isNeedcSeparatorLine(index) {
            return (
                (index == this.newsListRealLen - 1 && this.picNum == 0) ||
                (index == this.newsListRealLen - 1 && index != 0 && this.picNum == 1) ||
                (index == this.newsListRealLen - 1 && index != 0 && this.picNum == 3)
            );
        },
    },
};
</script>

<style>
.newsMore.newsList .newsElementsPanel .ne_newsAuthor .newsAuthorName {
    margin-left: 0.8rem;
}
</style>
