<template>
    <div
        :id="newsLineId"
        :topClassname="topClassName"
        :topSwitch="topSwitch"
        :newsId="id"
        :newsName="title"
        class="largeImageNewsStyle newsLine J_picNum"
        :class="lineClass"
        picnum="6"
    >
        <div :id="lineBodyId" class="lineBody">
            <div class="mixNewsStyleImgBox6">
                <a
                    class="f_mixNewsStyleImgBox6_link"
                    hidefocus="true"
                    :href="href"
                    :target="target"
                    :onclick="onclickStr"
                >
                    <div v-if="!pictureId" id="newsTitlePic" :alt="alt" class="newsTitlePic icon-noFigureB"></div>
                    <div v-else id="newsTitlePic" :alt="alt" class="newsTitlePic" :style="newsTitlePicStyle"></div>

                    <div class="mixNewsStyleTitleContainer6">
                        <div class="mixNewsStyleTitlePanel">
                            <div v-if="!hideType && groupName !== ''" class="newsSortPanel">
                                <div class="newsSort">{{ groupName }}</div>
                            </div>
                            <div :class="[showLine ? 'mixNewsStyleTitle1' : 'mixNewsStyleTitle2']">
                                <div :class="topFlagClass"></div>{{ title }}
                            </div>
                            <div class="separatorLine"></div>
                            <div class="mixNewsStyleSummary">{{ summary }}</div>
                        </div>
                        <div
                            v-if="showNewsElementsRequire"
                            class="newsElementsPanel g_newsElementsPanel J_newsElementsPanel"
                            @click="gotoHref"
                        >
                            <div v-if="showCommentRequire" class="ne_newsComment faisco-icons-comment">
                                <span class="newsCommentCount">{{ totalNewsCommentCount }}</span>
                            </div>
                            <div
                                v-if="showCommentRequire && showAuthorRequire"
                                class="ne_separatorLine g_ne_separatorLine"
                            ></div>
                            <div
                                v-if="showAuthorRequire"
                                class="ne_newsAuthor faisco-icons-author"
                                :style="newsAuthorStyle"
                            >
                                <span class="newsAuthorName">{{ newsAuthor }}</span>
                            </div>
                            <div v-if="showDateRequire" class="ne_newsTime">{{ formattedNewsDate }}</div>
                        </div>
                        <template v-else>
                            <div v-if="showCommentRequire" class="ne_newsComment faisco-icons-comment">
                                <span class="newsCommentCount">{{ totalNewsCommentCount }}</span>
                            </div>
                            <div
                                v-if="showAuthorRequire"
                                class="ne_newsAuthor faisco-icons-author"
                                :style="newsAuthorStyle"
                            >
                                <span class="newsAuthorName">{{ newsAuthor }}</span>
                            </div>
                            <div v-if="showDateRequire" class="ne_newsTime">{{ formattedNewsDate }}</div>
                        </template>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import newsMoreMixin from '../mixins/news-more-mixin';

export default {
    name: 'NewsMoreItemStyle6',
    mixins: [newsMoreMixin],
    data() {
        return {};
    },
};
</script>

<style scoped lang="scss">
/*大图遮罩样式*/
.newsList .largeImageNewsStyle .isNewAddModule .mixNewsStyleTitleContainer6 {
    background: rgba(0, 0, 0, 0.5);
}
.newsList .largeImageNewsStyle .isNewAddModule .mixNewsStyleImgBox6 .newsElementsPanel .ne_newsComment {
    margin-left: 0;
    margin-right: 0.5rem;
}
.newsList .isNewAddModule .newsElementsPanel {
    letter-spacing: 0;
}
.newsList .largeImageNewsStyle .isNewAddModule .mixNewsStyleTitleContainer6 .mixNewsStyleTitle1 {
    font-size: 0.7rem;
    max-width: 9.75rem;
    text-align: center;
    margin: 0 auto;
}
.newsList .largeImageNewsStyle .isNewAddModule .mixNewsStyleSummary {
    opacity: 0.8;
    font-size: 0.55rem;
    color: #ffffff;
    max-height: none;
    box-sizing: border-box;
}
.newsList .largeImageNewsStyle .isNewAddModule .mixNewsStyleTitlePanel {
    border-radius: 2.5rem;
    margin-top: 2.325rem;
    padding-top: 0;
}
.newsList .largeImageNewsStyle .isNewAddModule .f_mixNewsStyleImgBox6_link {
    border-radius: 0.2rem;
}
.newsList .largeImageNewsStyle .mixNewsStyleImgBox6 {
    padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}
.newsList .largeImageNewsStyle .isNewAddModule .mixNewsStyleTitleContainer6 .mixNewsStyleTitle1 {
    display: -webkit-box;
    max-height: none;
    max-width: none;
}
.newsList .largeImageNewsStyle .lineBody .noDescriptionLine {
    white-space: unset;
}
.newsList .isNewAddModule .newsElementsPanel .ne_newsTime.fix1 {
    line-height: normal;
}
.isNewAddModule .newsList .largeImageNewsStyle .lineBody {
    padding: 0.75rem;
    padding-bottom: 0;
}
.isNewAddModule .newsList .largeImageNewsStyle {
    margin-bottom: 0;
}
.isNewAddModule .newsList .largeImageNewsStyle .mixNewsStyleImgBox6 {
    padding: 0;
    padding-bottom: 0.1rem;
}
.isNewAddModule .newsList .largeImageNewsStyle .mixNewsStyleTitleContainer6 {
    max-height: none;
    display: flex;
    align-items: center;
}
.isNewAddModule .newsList .largeImageNewsStyle .f_mixNewsStyleImgBox6_link {
    max-height: none;
}
.isNewAddModule .newsList .largeImageNewsStyle .mixNewsStyleTitlePanel {
    width: 100%;
    padding: 0.7rem 1.5rem;
    margin-top: 0;
}
</style>
