<template>
    <module-frame :module-id="moduleId">
        <div id="memberFdPwdStepOne">
            <div class="loginHeader webHeaderBg">
                <a :href="returnUrl" class="g_close icon-gClose"></a>
                <span class="title pageTitle" v-text="LS.mobi_memberDialogFwdStepOneTitle"></span>
            </div>
            <div class="loginContent">
                <template v-if="!needMobileAuth && !needMailAuth">
                    <div class="g_globalLine">
                        <input
                            id="macct"
                            class="itemEdit g_input mustItem"
                            type="text"
                            :disabled="manageMode"
                            :placeholder="macctPlaceholder"
                        />
                        <span class="icon-userIcon g_mainColor"></span>
                    </div>
                    <div class="g_globalLine J_memberFdPwdBtn">
                        <input
                            type="button"
                            class="g_button sendIcon"
                            :value="LS.mobi_memberDialogNextStep"
                            :disabled="manageMode"
                            @click="memberFdPwdClick"
                        />
                    </div>
                </template>
                <template v-else-if="findPwByMobileOpen || findPwByMailOpen">
                    <div class="g_globalLine">
                        <input
                            id="acquirerInput"
                            class="itemEdit g_input mustItem"
                            type="text"
                            style="margin-left: 0; padding-left: 0.75rem"
                            :maxlength="MemberDef.Limit.ACCT_MAXLEN"
                            :placeholder="tipsPlaceHolder"
                        />
                    </div>
                    <div class="g_globalLine" style="display: inline">
                        <input
                            id="memCodeInput"
                            type="text"
                            maxlength="6"
                            class="itemEdit g_input mustItem"
                            :style="memCodeInputStyle"
                            :placeholder="LS.msgBoardValidateCode"
                        /><span
                            id="acquireCode"
                            class="acquireCode"
                            @click="acquireCodeClick"
                            v-text="LS.mobi_getMobileCode"
                        ></span>
                        <input id="memCodeSign" style="display: none" disabled />
                    </div>
                    <div class="g_globalLine J_memberFdPwdBtn">
                        <input
                            type="button"
                            class="g_button sendIcon"
                            :value="LS.mobi_memberDialogNextStep"
                            :disabled="manageMode"
                            @click="memberNewFdPwdStepTwoClick"
                        />
                    </div>
                </template>
                <template v-else>
                    <div class="g_globalLine">
                        <input
                            id="macct"
                            class="itemEdit g_input mustItem"
                            type="text"
                            :maxlength="MemberDef.Limit.ACCT_MAXLEN"
                            :placeholder="macctPlaceholder"
                        />
                        <span class="icon-userIcon g_mainColor"></span>
                    </div>
                    <div class="g_globalLine J_memberFdPwdBtn">
                        <input
                            type="button"
                            class="g_button sendIcon"
                            :value="LS.mobi_memberDialogNextStep"
                            :disabled="manageMode"
                            @click="memberFdPwdClick"
                        />
                    </div>
                </template>
                <div class="J_showFindPwWay g_globalLine" style="margin-top: 1rem"></div>
            </div>
        </div>
    </module-frame>
</template>
<script>
import ModuleFrame from '@/modules/frame/index.vue';
import { mapGetters, mapState } from 'vuex';

import { MemberDef } from '../../memberDef';
import { memberFdPwd, acquireCode, memberNewFdPwdStepTwo } from '../method';

let manageMode = VITE_APP_MODE !== 'visitor';

export default {
    name: 'FindPwd',
    components: {
        ModuleFrame,
    },
    props: {
        moduleId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            MemberDef,
            manageMode,
        };
    },
    computed: {
        ...mapState('app', ['LS', 'lcid']),
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId) || {};
        },
        renderOptions() {
            return this.module.renderOptions || {};
        },
        findPwByMobileOpen() {
            return this.renderOptions.findPwByMobileOpen || false;
        },
        findPwByMailOpen() {
            return this.renderOptions.findPwByMailOpen || false;
        },
        authType() {
            return this.renderOptions.authType;
        },
        returnUrl() {
            return this.renderOptions.returnUrl || '/';
        },
        needMailAuth() {
            return this.authType == MemberDef.AuthType.NEED_MAIL_AUTH;
        },
        needMobileAuth() {
            return this.authType == MemberDef.AuthType.NEED_MOBILE_AUTH;
        },
        pleaseInput() {
            return this.LS.mobi_member_PleaseInput || '';
        },
        macctPlaceholder() {
            return this.pleaseInput + this.LS.mobi_member_Account;
        },
        tipsPlaceHolder() {
            let placeHolder = '';
            if (this.findPwByMobileOpen && this.findPwByMailOpen) {
                placeHolder = this.LS.memberDialogPlzEnterBoth;
            } else {
                if (this.findPwByMobileOpen) {
                    placeHolder = this.LS.memberDialogPlzEnterMobile;
                } else if (this.findPwByMailOpen) {
                    placeHolder = this.LS.memberDialogPlzEnterMail;
                }
            }
            return placeHolder;
        },
        memCodeInputStyle() {
            return {
                'margin-left': '0',
                width: ' 55%',
                'padding-left': '0.75rem',
            };
        },
    },
    methods: {
        memberFdPwdClick() {
            memberFdPwd(this.lcid, this.returnUrl);
        },
        acquireCodeClick() {
            let sec = jm('.acquireCode').attr('sec') || 0;
            if (sec == '' || sec == null || sec == 0) {
                acquireCode(this.findPwByMobileOpen, this.findPwByMailOpen);
            }
        },
        memberNewFdPwdStepTwoClick() {
            memberNewFdPwdStepTwo();
        },
    },
};
</script>
<style lang="scss">
.mobiCol22 {
    #webTopBox,
    #webHeader,
    #webBannerBox,
    #webFooterBox,
    #bgMusicWrapper,
    #fixedRightSideBtns,
    #p_footer_nav_wrap {
        display: none;
    }
    .loginContent {
        padding: 0.75rem 0.5rem 0.75rem 0.5rem;
    }
    .loginHeader,
    .ShipDetailsHeader,
    .BanksDetailHeader {
        text-align: center;
        height: 2.25rem;
        line-height: 2.25rem;
        margin: 0;
        padding: 0;
        position: relative;
    }
}
.g_locale2052 .itemEdit {
    padding-left: 3.5rem;
}
.jz_themeV2 {
    .signUpContent .signupSubmit,
    .loginContent .sendIcon {
        margin-bottom: 0;
        font-weight: normal;
        font-size: 0.85rem;
    }
}
</style>
