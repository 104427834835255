<template>
    <div id="navButton" class="navButton is_initing">
        <div class="navButton-icon icon-"></div>
        <div class="J_hamburger u_hamburger icon-"></div>
    </div>
</template>

<script>
export default {
    name: 'NavBtn',
    data() {
        return {};
    },
};
</script>
