<template>
    <component :is="component"></component>
</template>

<script>
import { mapState } from 'vuex';
import PhotoGroupDetailV3 from './v3/photo-group-detail.vue';
import PhotoGroupDetailV1 from './v1/photo-group-detail.vue';
import { logJZFdp } from '@/api/logDog';

export default {
    name: 'PhotoGroupDetail',
    computed: {
        ...mapState('app', ['openThemeV3']),
        component() {
            return this.openThemeV3 ? PhotoGroupDetailV3 : PhotoGroupDetailV1;
        },
    },
    mounted() {
        this.logVisitorPhotoGroup();
    },
    methods: {
        logVisitorPhotoGroup() {
            logJZFdp('jz_album_pages_visit', {
                jz_content_terminal: 'mobi',
            });
        },
    },
};
</script>
