import request from './request';

export function getGroupNavList() {
    return request.get('/ajax/photoGroupNav_h.jsp?cmd=getWafNotCk_getGroupNavList').then(({ data }) => data);
}

export function getPhotoGroupList(data) {
    return request.post('/ajax/module_h.jsp?cmd=getWafNotCk_getPhotoGroupList', {
        data,
    });
}

export function setSingle(params = {}) {
    return request
        .get('/ajax/productGroup_h.jsp?cmd=setWafCk_setSingle', {
            params,
        })
        .then(({ data }) => data);
}
