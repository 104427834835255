import { addRequestPrefix, encodeUrl } from '@/shared/util';
import { QuickIn } from '../../utils/quick-in';
import { SimpleDrag } from '../../utils/simple-drag';
import { changeHref } from '@/features/router/universal';
export function initProductGroupV3(pgStyle, pgType, manageMode) {
    if (pgType == 1 || pgStyle == 0) {
        return false;
    }

    var productGroupPanel = jm('.productNewGroup');
    var firstGroup = jm('.firstGroup');
    var prUrl = productGroupPanel.attr('_prUrl') || '';
    var firstGroupItem, navWidth, itemWidth, itemEl, turnRight;

    firstGroup = productGroupPanel.find('.firstGroup');
    jm('#webFooter').find('.footerMenu').hide();
    jm('#webFooter').find('.technical').hide();
    var isOpenFooterNav = jm('#p_footer_nav_wrap').css('display') == 'block';

    var contentHeight;
    if (isOpenFooterNav) {
        // 如果开启了底部导航，需要减去底部导航的高度
        contentHeight = jm('#preview').height() - jm('.sysSearch').height() - jm('#J_footer_nav').height() + 'px';
        productGroupPanel.height(contentHeight);
        firstGroup.css({ height: contentHeight, 'min-height': contentHeight });
    } else {
        contentHeight = jm('#preview').height() - jm('.sysSearch').height() - 1 + 'px';
        productGroupPanel.height(contentHeight);
        firstGroup.css({ height: contentHeight, 'min-height': contentHeight });
    }

    // 等比例缩放图片，并且各种居中。临上线各种改T.T
    var itemImages = jm(productGroupPanel).find('.J_itemImage');
    for (var i = 0; i < itemImages.length; i++) {
        var itemImage = itemImages[i];
        scaleImageV3(itemImage);
    }

    // fix屏幕高度
    // productGroupPanel.css("height", (windowHeight - PSMHeight) + "px");
    firstGroupItem = productGroupPanel.find('.firstGroup .firstGroupItem');

    // 绑定点击一级分类切换二级分类事件
    firstGroupItem.off('.productNewGroup').on('click.productNewGroup', function () {
        var checkedItem = productGroupPanel.find('.firstGroup .checkedItem'),
            checkedItemSecondPanel = productGroupPanel.find('.unFirstGroup .checkedPanel');
        var currentItem = jm(this),
            currentItemId = jm(currentItem).attr('data-id'),
            currentItemSecondPanel = jm('#sed_' + currentItemId),
            hideScollBar = jm('.hideScollBar'),
            timer;

        // 修复有三级分类时候，二级分类跳转页面不正常的bug
        if (productGroupPanel.find('.J_hasThreeGroup').length != 0) {
            // 如果有三级分类的时候
            //如果当前点击的一级分类下属没有分类，那就跳转到这个分类的产品筛选结果页
            if (!currentItemSecondPanel.html() && prUrl) {
                changeHref(prUrl + '?groupId=' + currentItemId);
                return false;
            }
            // 如果没有三级分类的时候
            //如果当前点击的一级分类下属没有分类，那就跳转到这个分类的产品筛选结果页
            if (
                (currentItemSecondPanel.html() === '' ||
                    !Array.from(currentItemSecondPanel.find('.f-groupImgWrap')).some((ele) => !!jm(ele).html())) &&
                prUrl
            ) {
                changeHref(prUrl + '?groupId=' + currentItemId);
                return false;
            }
        }

        if (jm(this).hasClass('checkedItem')) {
            return false;
        }

        checkedItem.removeClass('checkedItem');
        checkedItemSecondPanel.removeClass('checkedPanel');
        currentItem.addClass('checkedItem');
        currentItemSecondPanel.addClass('checkedPanel');
        hideScollBar[0].scrollTop = 0;
        // 删除商城主题色
        checkedItem.find('.firstGroupName').removeClass('f-fontColor');
        currentItem.find('.firstGroupName').addClass('f-fontColor');

        turnRight = 0;
        currentItem.find('.J_navSelect').addClass('fk-navSelect');
        turnRight = jm('body')[0].clientWidth / 2 - currentItem[0].getBoundingClientRect().left;
        clearInterval(timer);
        if (turnRight > 0) {
            timer = setInterval(function () {
                if (turnRight > 0) {
                    turnRight--;
                    firstGroup[0].scrollLeft--;
                } else {
                    clearInterval(timer);
                }
            }, 1);
        } else {
            timer = setInterval(function () {
                if (turnRight < 0) {
                    turnRight++;
                    firstGroup[0].scrollLeft++;
                } else {
                    clearInterval(timer);
                }
            }, 1);
        }
    });

    // 绑定粗糙的拖拽控件
    if (pgStyle != 5 && pgStyle != 6) {
        SimpleDrag.__create(productGroupPanel.find('.firstGroupList'));
    } else {
        navWidth = 0;
        firstGroupItem.each(function (index, el) {
            itemEl = jm(el);
            itemWidth = el.offsetWidth + 2 + parseFloat(itemEl.css('margin-right').replace('px', '')); // +2是为了去除四舍五入的情况
            navWidth += itemWidth;
        });

        productGroupPanel.find('.firstGroupList').width(navWidth + 'px');
    }

    if (!manageMode) {
        return;
    }

    // 增加修改图片的快捷入口
    var items = jm(productGroupPanel).find('.J_groupItem');
    var quickInOptions = {
        key: 'productGroup',
        targets: items,
        type: QuickIn.TYPE.FU,
        popupWindowOption: {
            settings: {
                title: '添加图片',
                type: ['jpg', 'jpeg', 'bmp', 'png', 'gif'],
                imgMode: 2,
                maxChoiceList: 1,
                netFileMode: true,
                openIcon: true,
                tabIndex: 0,
                group: 'thumbImg',
            },
            closeFunc: editImageV3,
        },
    };

    if (pgStyle != 2) {
        QuickIn.__createInstance(quickInOptions);
    }
}

function editImageV3(target, uploadResult) {
    if (target.length == 0 || !uploadResult.fileId || uploadResult.fileId.length == 0) {
        return;
    }

    var groupId = parseInt($(target).parent().attr('data-id'));
    var fileId = uploadResult.fileId;
    var filePath = uploadResult.filePath;
    var isDefaultColor = uploadResult.isDefaultColor;
    var color = uploadResult.color;
    var url =
        addRequestPrefix({ newPath: '/ajax', oldPath: 'ajax' }) +
        '/productGroup_h.jsp?cmd=setWafCk_setSingle&id=' +
        groupId +
        '&mIcon=' +
        fileId;
    if (!isDefaultColor) {
        url = url + '&mIconColor=' + encodeUrl(color);
    }

    $.ajax({
        url,
        type: 'get',
        error() {
            Vue.prototype.$ing('服务繁忙，请稍候重试', false);
        },
        success(result) {
            var data = $.parseJSON(result);
            if (data.success) {
                var image = $(target).parent().find('.J_itemImage');
                var imageParent = $(image).parent();
                var imgClass = 'f-itemImg';

                imageParent.empty();
                if (top.Fai.isFontIcon(fileId)) {
                    if (!isDefaultColor) {
                        imageParent.append(
                            "<span class='J_itemImage " +
                                imgClass +
                                ' ' +
                                filePath +
                                "' style='color:" +
                                color +
                                "'></span>"
                        );
                    } else {
                        imageParent.append("<span class='J_itemImage " + imgClass + ' ' + filePath + "'></span>");
                    }
                } else {
                    imageParent.append(
                        "<img  class='J_itemImage " +
                            imgClass +
                            "' src='" +
                            filePath +
                            "' data-w='" +
                            uploadResult.fileWidth +
                            "' data-h='" +
                            uploadResult.fileHeight +
                            "' />"
                    );
                }

                if ($(imageParent).find('.J_itemImage').length > 0) {
                    scaleImageV3($(imageParent).find('.J_itemImage')[0]);
                }

                Vue.prototype.$ing(data.msg, true);
            } else {
                Vue.prototype.$ing(data.msg, true);
            }
        },
    });
}

function scaleImageV3(image) {
    var imageW = parseInt(jm(image).attr('data-w')),
        imageH = parseInt(jm(image).attr('data-h')),
        width = '2.625rem',
        height = '2.625rem';

    if (imageW >= imageH) {
        jm(image).css('width', width).css('height', 'auto').css('vertical-align', 'middle');
    } else if (imageH > imageW) {
        jm(image).css('width', 'auto').css('height', height).css('display', 'block').css('margin', '0 auto');
    }
}
