<template>
    <module-frame :module-id="moduleId">
        <div id="mLogin">
            <div class="loginHeader webHeaderBg profileLoginHeader">
                <a class="g_close icon-gClose" @click="closeClick"></a>
                <span class="title pageTitle" v-text="LS.col_member_login"></span>
            </div>
            <div v-if="manageMode" class="notice">您目前处于网站管理状态，请先退出再登录</div>
            <div class="loginPersonIcon">
                <div class="personPic">
                    <div class="icon-personPic"></div>
                </div>
            </div>
            <div class="loginContent mloginContent">
                <div class="g_globalLine forAcctLogin">
                    <input
                        id="user"
                        type="text"
                        class="loginName itemEdit g_input mustItem loginMustItem"
                        :maxlength="memberDef.Limit.ACCT_MAXLEN"
                        :placeholder="loginNamePlaceHolder"
                    />
                    <span class="icon-userIcon g_mainColor"></span>
                </div>
                <div class="g_globalLine forAcctLogin">
                    <input
                        id="password"
                        type="password"
                        autocomplete="off"
                        :maxlength="memberDef.Limit.PWD_MAXLEN"
                        class="loginPassword itemEdit g_input mustItem loginMustItem"
                        :placeholder="pleaseInputStr + LS.mobi_member_Password"
                    />
                    <span class="icon-pswIcon g_mainColor"></span>
                </div>
                <template v-if="signUpbyMobile">
                    <div class="g_globalLine forFastLogin">
                        <input
                            id="acquirerInput"
                            class="itemEdit loginTel g_input mustItem"
                            type="text"
                            :placeholder="LS.memberDialogPlzEnterMobile"
                        />
                        <span class="icon-fastUserIcon g_mainColor"></span>
                    </div>
                    <div class="g_globalLine forFastLogin" style="font-size: 0">
                        <input
                            id="memCodeInput"
                            class="loginTelPassword itemEdit g_input mustItem"
                            :placeholder="LS.msgBoardValidateCode"
                        />
                        <span class="icon-fastCodeIcon g_mainColor"></span>
                        <div
                            id="acquireCode"
                            :class="acquireCodeClass"
                            @click="loginAcquireCodeClick"
                            v-text="LS.mobi_getMobileCode"
                        ></div>
                        <input id="memCodeSign" style="display: none" />
                        <div id="memCodeSplitLine"></div>
                    </div>
                </template>
                <div class="loginCaptchaCtrl g_globalLine">
                    <input
                        id="loginCaptcha"
                        type="text"
                        class="captchaText g_input"
                        maxlength="4"
                        :placeholder="LS.mobi_EnterVerificationCode"
                    />
                    <img id="loginCaptchaImg" class="captchaImg" :src="loginCaptchaImgSrc" @click="validateCodeClick" />
                    <span class="icon-loginCaptchaTip"></span>
                </div>
                <div v-if="mbAutoLogin">
                    <div class="autoLoginCtrl g_globalLine" style="text-align: left">
                        <input id="autoLogin" type="checkBox" class="autoLoginCheck" />
                        <label for="autoLogin" class="autoLoginLabel" @click="checkLabelClick">
                            <span class="icon-uncheckIcon"></span>{{ LS.memberLogin_rememberMe }}
                        </label>
                    </div>
                </div>
                <div class="g_globalLine forAcctLogin">
                    <input
                        type="button"
                        class="g_button sendIcon g_main_bgColor_v3 fpw_btn"
                        :value="LS.memberLoginLoginV3"
                        @click="memberLoginClick"
                    />
                </div>
                <template v-if="signUpbyMobile">
                    <div class="g_globalLine forFastLogin">
                        <input
                            type="button"
                            class="g_button sendIcon g_main_bgColor_v3 fpw_btn"
                            :value="LS.memberLoginLoginV3"
                            @click="fastLoginClick"
                        />
                    </div>
                </template>
                <div class="loginBottom g_globalLine" :style="loginBottomStyle">
                    <template v-if="signUpbyMobile">
                        <div :style="fastLoginStyle">
                            <span
                                id="mobileFastLoginToggle"
                                :style="styleOnlyTwoProp"
                                class="g_mainColor forAcctLogin loginToggle loginOption"
                                @click="mobileFastLoginToggleClick"
                                v-text="LS.mobileFastLogin"
                            ></span>
                            <span
                                id="acctLoginToggle"
                                :style="styleOnlyTwoProp"
                                class="g_mainColor forFastLogin loginToggle loginOption"
                                @click="acctLoginToggleClick"
                                v-text="LS.memberAcctLogin"
                            ></span>
                            <a
                                v-if="findPwdOpen"
                                :href="findPwdUpUrl"
                                class="g_mainColor freeRegister loginOption"
                                v-text="LS.memberFindPwdTwo"
                            ></a>
                            <a
                                v-if="isCn"
                                :style="styleOnlyTwoPropMarginLeft"
                                class="g_mainColor freeRegister loginOption"
                                :href="signUpUrl"
                                v-text="LS.memberLogin_freeReg"
                            ></a>
                        </div>
                        <div v-if="!isCn" style="display: flex; margin-top: 0.5rem">
                            <a
                                :style="styleOnlyTwoPropMarginLeft"
                                class="g_mainColor freeRegister loginOption"
                                :href="signUpUrl"
                                v-text="LS.memberLogin_freeReg"
                            ></a>
                        </div>
                    </template>
                    <template v-else>
                        <a
                            v-if="findPwdOpen"
                            :href="findPwdUpUrl"
                            class="g_mainColor freeRegister loginOption"
                            v-text="LS.memberFindPwdTwo"
                        ></a>
                        <a
                            class="g_mainColor freeRegister loginOption"
                            :href="signUpUrl"
                            v-text="LS.memberLogin_freeReg"
                        ></a>
                    </template>
                </div>
                <template v-if="qqOpen || sinaOpen || wxOpen">
                    <div class="oLgnPanel">
                        <div class="head">
                            <div class="leftLine"></div>
                            <div class="middle" v-text="LS.mobi_member_OtherLgnV3"></div>
                            <div class="rightLine"></div>
                        </div>
                        <div class="oLgn">
                            <div v-if="qqOpen" id="qqLgn" class="qqLgnPanel icon-qqLgnPanel" style="margin-left: 0rem">
                                <div class="desc" v-text="LS.mobi_member_OtherLgnQQ"></div>
                            </div>
                            <div
                                v-if="sinaOpen"
                                id="sinaLgn"
                                class="sinaLgnPanel icon-sinaLgnPanel"
                                :style="sinaLgnStyle"
                            >
                                <div class="desc" v-text="LS.mobi_member_OtherLgnWB"></div>
                            </div>
                            <div v-if="wxOpen" id="wxLgn" class="wxLgnPanel icon-wxLgnPanel" :style="wxLgnStyle">
                                <div class="desc" v-text="LS.mobi_member_OtherLgnWX"></div>
                            </div>
                            <div v-if="mobiWxOpen" id="wxLgn" class="wxLgnPanel icon-wxLgnPanel" style="display: none">
                                <div class="desc" v-text="LS.mobi_member_OtherLgnWX"></div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div id="fillAcctInfo" style="display: none">
            <div class="loginHeader webHeaderBg profileLoginHeader">
                <span id="fillInfoBack" class="g_close icon-gClose"></span>
                <span class="title pageTitle" v-text="LS.mobi_member_OtherLgnFillAcct"></span>
            </div>
            <div class="loginContent"></div>
        </div>
        <div id="bindOrLogin" style="display: none">
            <div class="loginHeader webHeaderBg profileLoginHeader">
                <span id="bindOrLoginBack" class="g_close icon-gClose"></span>
                <span class="title pageTitle" v-text="LS.mobi_member_OtherLgnMode"></span>
            </div>
            <div class="loginContent"></div>
        </div>
        <div id="bindNow" style="display: none">
            <div class="loginHeader webHeaderBg profileLoginHeader">
                <span id="bindNowBack" class="g_close icon-gClose"></span>
                <span class="title pageTitle" v-text="LS.mobi_member_OtherLgnBindAcct"></span>
            </div>
            <div class="loginContent"></div>
        </div>
        <div id="mSignUp" style="display: none">
            <div class="loginHeader webHeaderBg profileLoginHeader">
                <span id="signUpBack" class="g_close icon-gClose"></span>
                <span class="title pageTitle" v-text="LS.col_member_signup"></span>
            </div>
            <div class="loginContent"></div>
            <div id="checkEmail" class="checkEmail"></div>
            <a id="emailAddr" class="emailAddr" style="display: none" target="_blank">地址</a>
        </div>
    </module-frame>
</template>
<script>
import ModuleFrame from '@/modules/frame/index.vue';
import { mapGetters, mapState } from 'vuex';

import { MemberDef } from '../../memberDef';
import { returnBack } from '../../util';
import {
    loginAcquireCode,
    changeValidateCode,
    memberLogin,
    fastLogin,
    mobileFastLoginToggle,
    acctLoginToggle,
    initQQLogin,
    initWBLogin,
    wxLogin,
    checkHaveMultipleMember,
    manageLoginV3,
    memberOtherBindOrLogin,
    wbWinListener,
    qqWinListener,
    wxOpenIdLogin,
    loadReturnUrlForLogin,
} from '../method';
import { checkLabel, checkMustItem } from '../../method';
import { changeHref } from '@/features/router/universal';
import { showMemberLoginMsg } from '@/shared/member';
import { hidePlaceholder } from '@/shared/misc/placeholder';
let manageMode = VITE_APP_MODE !== 'visitor';
export default {
    name: 'LoginV3',
    components: {
        ModuleFrame,
    },
    props: {
        moduleId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            normalTurnBack: true,
            manageMode,
            memberDef: MemberDef,
        };
    },
    computed: {
        ...mapState('app', ['LS', 'lcid', 'aid']),
        ...mapGetters(['getModuleById']),
        moduleInfo() {
            return this.getModuleById(8) || {};
        },
        renderOptions() {
            return this.moduleInfo.renderOptions || {};
        },
        wxOpen() {
            return this.renderOptions.wxOpen || false;
        },
        mobiWxOpen() {
            return this.renderOptions.mobiWxOpen || false;
        },
        qqOpen() {
            return this.renderOptions.qqOpen || false;
        },
        sinaOpen() {
            return this.renderOptions.sinaOpen || false;
        },
        signUpByMail() {
            return this.renderOptions.signUpByMail || false;
        },
        signUpbyMobile() {
            return this.renderOptions.signUpByMobile || false;
        },
        findPwdOpen() {
            return this.renderOptions.findPwdOpen || false;
        },
        fromMerchants() {
            return this.renderOptions.fromMerchants || false;
        },
        mbAutoLogin() {
            return this.renderOptions.mbAutoLogin || false;
        },
        memJumpOpen() {
            return this.renderOptions.memJumpOpen || false;
        },
        noRemark() {
            return this.renderOptions.noRemark || false;
        },
        aesKey() {
            return this.renderOptions.aesKey || '';
        },
        authType() {
            return this.renderOptions.authType || 0;
        },
        qqAppId() {
            return this.renderOptions.qqAppId || '';
        },
        qqMobiReUri() {
            return this.renderOptions.qqMobiReUri || '';
        },
        sinaReUri() {
            return this.renderOptions.sinaReUri || '';
        },
        displayList() {
            return this.renderOptions.displayList || [];
        },
        wxLoginUrl() {
            return this.renderOptions.wxLoginUrl || '';
        },
        appId() {
            return this.renderOptions.appId || '';
        },
        encryptKey() {
            return this.renderOptions.encryptKey || '';
        },
        _siteDemo() {
            return this.renderOptions._siteDemo || false;
        },
        loginType() {
            return this.renderOptions.loginType || '';
        },
        loginTypeMode() {
            return this.renderOptions.loginTypeMode || false;
        },
        memberInfo() {
            return this.renderOptions.memberInfo;
        },
        migrateWJZ() {
            return this.renderOptions.migrateWJZ || '';
        },
        AppKey() {
            return this.renderOptions.AppKey || '';
        },
        downloanMid() {
            return this.renderOptions.downloanMid || 0;
        },
        downloanFid() {
            return this.renderOptions.downloanFid || 0;
        },
        fromHd() {
            return this.renderOptions.fromHd || false;
        },
        signUpUrl() {
            return this.renderOptions.signUpUrl || '';
        },
        findPwdUpUrl() {
            return this.renderOptions.findPwdUpUrl || '';
        },
        turnBackUrl() {
            return this.renderOptions.turnBackUrl || '/';
        },
        encodeReturnUrl() {
            return this.renderOptions.turnBackUrl || '/';
        },
        lotteryId() {
            return this.renderOptions.lotteryId || 0;
        },
        phoneNumber() {
            return this.renderOptions.phoneNumber || '';
        },
        returnUrl() {
            return this.renderOptions.returnUrl || '/';
        },
        pleaseInputStr() {
            return this.LS.mobi_member_PleaseInput || '';
        },
        lanCode() {
            return this.renderOptions.lanCode || 'cn';
        },
        loginNamePlaceHolder() {
            let placeholder = '';
            if (this.signUpbyMobile) {
                placeholder = this.LS.memberLogin_acct_mobile;
            } else if (this.signUpbyMail) {
                placeholder = this.LS.memberLogin_acct_mail;
            } else {
                placeholder = this.pleaseInputStr + this.LS.mobi_member_Account;
            }
            return placeholder;
        },
        acquireCodeClass() {
            return VITE_APP_MODE !== 'visitor' ? [''] : ['g_input'];
        },
        loginCaptchaImgSrc() {
            return `/validateCode.jsp?v=${Number(Math.random() * 1000)}`;
        },
        loginBottomStyle() {
            let style = {};
            if ((this.findPwdOpen || this.signUpbyMobile) && this.lcid != 0) {
                style['display'] = 'block';
                style['text-align'] = 'center';
            }
            return style;
        },
        fastLoginStyle() {
            let style = {};
            if (this.lcid != 0 && this.findPwdOpen) {
                style['display'] = 'flex';
                style['justify-content'] = 'center';
            }
            return style;
        },
        fastLoginNeedPadding() {
            return this.LS.mobileFastLogin.trim().length < 16;
        },
        styleOnlyTwoProp() {
            let style = {};
            if (!this.findPwdOpen && this.fastLoginNeedPadding) {
                style['padding'] = '0 1.3rem';
            }
            return style;
        },
        styleOnlyTwoPropMarginLeft() {
            let style = {};
            if (!this.findPwdOpen && this.fastLoginNeedPadding) {
                style['margin-left'] = '0.5rem';
            }
            return style;
        },
        sinaLgnStyle() {
            let style = {};
            if (!this.qqOpen) {
                style['margin-left'] = '0px';
            }
            return style;
        },
        wxLgnStyle() {
            let style = {};
            if (!this.qqOpen && !this.sinaOpen) {
                style['margin-left'] = '0px';
            }
            return style;
        },
        signUpbyMail() {
            return this.renderOptions.signUpByMail || false;
        },
        uid() {
            return this.renderOptions.uid || '';
        },
        faiOpenId() {
            return this.renderOptions.faiOpenId;
        },
        faiOpenIdGreen() {
            return this.renderOptions.faiOpenIdGreen;
        },
        avator() {
            return this.renderOptions.avator;
        },
        uname() {
            return this.renderOptions.uname;
        },
        isloginJump() {
            return this.renderOptions.isloginJump || false;
        },
        isCn() {
            return this.lcid == '2052';
        },
    },
    mounted() {
        this.memberLoginTips();
        if (!(VITE_APP_MODE !== 'visitor')) {
            if (this.isloginJump) {
                // 处理第三方已经登录，后面再登录不上问题
                changeHref(this.returnUrl);
            }
            let urlSearchParams = new URLSearchParams(document.location.search);
            let returnUrl = urlSearchParams.get('returnUrl') || '';
            let noReturn = urlSearchParams.get('noReturn');
            if (returnUrl == '' && !noReturn && !this.fromHd) {
                let href = loadReturnUrlForLogin(document.location.href);
                changeHref(href);
            }
            this.handleLoginType();
        }
        if (!(VITE_APP_MODE !== 'visitor') && !this._siteDemo) {
            if (this.qqOpen) {
                initQQLogin(
                    this.qqAppId,
                    this.qqMobiReUri,
                    this.displayList,
                    this.noRemark,
                    MemberDef.Limit.REMARK_MAXLEN,
                    'qqLgn',
                    this.aesKey,
                    this.returnUrl
                );
            }
            if (this.sinaOpen) {
                initWBLogin(
                    this.AppKey,
                    this.sinaReUri,
                    this.displayList,
                    this.noRemark,
                    MemberDef.Limit.REMARK_MAXLEN,
                    'sinaLgn',
                    this.aesKey
                );
            }
            if (this.wxOpen) {
                wxLogin(
                    'wxLgn',
                    this.aid,
                    this.lanCode,
                    this.wxLoginUrl,
                    this.appId,
                    this.encryptKey,
                    this.aesKey,
                    manageMode,
                    this._siteDemo,
                    this.migrateWJZ,
                    '',
                    '',
                    this.returnUrl
                );
            }
        }
        hidePlaceholder();
        checkHaveMultipleMember(this.authType);
        checkMustItem('.loginContent');
        manageLoginV3();
    },
    methods: {
        closeClick() {
            if (this.normalTurnBack) {
                returnBack();
            } else {
                let href = '';
                if (this.memJumpOpen) {
                    href = 'index.jsp';
                } else if (!this.fromHd) {
                    href = this.turnBackUrl;
                }
                changeHref(href);
            }
        },
        loginAcquireCodeClick() {
            if (!(VITE_APP_MODE !== 'visitor')) {
                let countdown = jm('#acquireCode').attr('countdown');
                if (countdown == '' || countdown == null) {
                    loginAcquireCode();
                }
            }
        },
        validateCodeClick() {
            changeValidateCode(jm('#loginCaptchaImg'));
        },
        checkLabelClick(e) {
            checkLabel(e.target);
        },
        memberLoginClick() {
            if (!(VITE_APP_MODE !== 'visitor')) {
                if (this.downloanMid == '' || (this.downloanFid == '' && !this.fromHd)) {
                    memberLogin(this.returnUrl, null, null, this.fromMerchants, this.lotteryId, this.phoneNumber);
                } else {
                    memberLogin(
                        this.returnUrl,
                        this.downloanMid,
                        this.downloanFid,
                        this.fromMerchants,
                        this.lotteryId,
                        this.phoneNumber
                    );
                }
            }
        },
        fastLoginClick() {
            if (!(VITE_APP_MODE !== 'visitor')) {
                if (this.downloanMid == '' || this.downloanFid == '') {
                    fastLogin(this.returnUrl, null, null, this.fromMerchants);
                } else {
                    fastLogin(this.returnUrl, this.downloanMid, this.downloanFid, this.fromMerchants);
                }
            }
        },
        mobileFastLoginToggleClick() {
            mobileFastLoginToggle();
        },
        acctLoginToggleClick() {
            acctLoginToggle();
        },
        handleLoginType() {
            if (this.loginType == 'other_qq') {
                if (this.loginTypeMode && (this.memberInfo == null || Object.keys(this.memberInfo).length == 0)) {
                    memberOtherBindOrLogin(
                        this.displayList,
                        this.noRemark,
                        MemberDef.Limit.REMARK_MAXLEN,
                        this.uid,
                        this.uname,
                        this.avator,
                        this.loginType,
                        this.returnUrl
                    );
                } else {
                    qqWinListener(
                        this.displayList,
                        this.noRemark,
                        MemberDef.Limit.REMARK_MAXLEN,
                        this.uid,
                        this.uname,
                        this.avator,
                        this.returnUrl
                    );
                }
            } else if (this.loginType == 'other_wb') {
                if (this.loginTypeMode && (this.memberInfo == null || Object.keys(this.memberInfo).length == 0)) {
                    memberOtherBindOrLogin(
                        this.displayList,
                        this.noRemark,
                        MemberDef.Limit.REMARK_MAXLEN,
                        this.uid,
                        this.uname,
                        this.avator,
                        this.loginType,
                        this.returnUrl
                    );
                } else {
                    wbWinListener(
                        this.displayList,
                        this.noRemark,
                        MemberDef.Limit.REMARK_MAXLEN,
                        this.uid,
                        this.uname,
                        this.avator,
                        this.returnUrl
                    );
                }
            } else if (this.loginType == 'other_wx') {
                if (!this.loginTypeMode || this.faiOpenIdGreen) {
                    wxOpenIdLogin(
                        this.displayList,
                        this.noRemark,
                        MemberDef.Limit.REMARK_MAXLEN,
                        this.uid,
                        this.uname,
                        this.avator,
                        this.returnUrl,
                        this.faiOpenIdGreen,
                        this.faiOpenId
                    );
                } else {
                    memberOtherBindOrLogin(
                        this.displayList,
                        this.noRemark,
                        MemberDef.Limit.REMARK_MAXLEN,
                        this.uid,
                        this.uname,
                        this.avator,
                        this.loginType,
                        this.returnUrl,
                        this.faiOpenIdGreen,
                        this.faiOpenId
                    );
                }
            }
        },

        memberLoginTips() {
            let errno = 0;
            if (VITE_APP_MODE !== 'visitor') {
                let route = this.$store.state.route;
                let { data = {} } = route.query;
                let urlSearchParams = JSON.parse(data);
                errno = urlSearchParams['errno'];
            } else {
                let urlSearchParams = new URLSearchParams(document.location.search);
                errno = urlSearchParams.get('errno');
            }
            if (errno != null && (this.loginType == '' || this.loginType == null)) {
                showMemberLoginMsg(errno, false);
            }
        },
    },
};
</script>
