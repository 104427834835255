<template>
    <component :is="component" v-bind="props" />
</template>

<script>
import newsMoreMixin from '../mixins/news-more-mixin';
import itemStyle1 from './item-style-1.vue';
import itemStyle2 from './item-style-2.vue';
import itemStyle3 from './item-style-3.vue';
import itemStyle4 from './item-style-4.vue';
import itemStyle5 from './item-style-5.vue';
import itemStyle6 from './item-style-6.vue';
import itemStyle7 from './item-style-7.vue';
import itemStyleOther from './item-style-other.vue';

export default {
    name: 'NewsMoreItem',
    components: {
        itemStyle1,
        itemStyle2,
        itemStyle3,
        itemStyle4,
        itemStyle5,
        itemStyle6,
        itemStyle7,
        itemStyleOther,
    },
    mixins: [newsMoreMixin],
    data() {
        return {};
    },
    computed: {
        props() {
            return this.$props;
        },
        component() {
            let itemStyle = `itemStyle${this.picNum}`;
            let showPicture = this.showPicture;
            if ([1, 3, 4].includes(this.picNum) && this.index > 0) {
                showPicture = false;
            }
            if (!showPicture && this.picNum !== 4) {
                itemStyle = 'itemStyleOther';
            }
            return itemStyle;
        },
    },
};
</script>

<style></style>
