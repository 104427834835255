var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showHeader,
          expression: "showHeader",
        },
      ],
      class: _vm.headerClass,
      attrs: { id: "webHeader_v3", styleid: _vm.headStyleId },
    },
    [
      _c(
        "div",
        {
          staticClass: "fixedBox J_headerFixBox",
          attrs: { id: "web_header_fixed_box" },
        },
        [
          _c(
            "div",
            {
              staticClass: "headBg_v3 g_main_bgColor_v3",
              attrs: { id: "headBg_v3" },
            },
            [
              !_vm.isRegularStyle || _vm.manageMode ? [_vm._m(0)] : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "header_v3",
                  style: _vm.headOpacity,
                  attrs: { id: "header_v3" },
                },
                [
                  _vm.headStyleId != 2 || _vm.manageMode
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "headIcon_v3",
                            attrs: { id: "headIcon_v3", pw: "160", ph: "160" },
                          },
                          [
                            _c(
                              "a",
                              _vm._b(
                                {},
                                "a",
                                {
                                  href: _vm.logoJumpUrl,
                                  click: _vm.logoOnclickStr,
                                },
                                false
                              ),
                              [_c("div", { staticClass: "f-logo" })]
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.headStyleId > 2 || _vm.manageMode
                    ? [
                        _c(
                          "div",
                          {
                            class: _vm.headTitleClass,
                            attrs: { id: "headTitle_v3" },
                          },
                          [
                            _c(
                              "h1",
                              {
                                staticClass:
                                  "headTitleV3H1 headTitleV3H1TextAlign",
                                attrs: {
                                  id: "headTitleV3H1",
                                  title: _vm.hoverTips,
                                },
                              },
                              [
                                _c(
                                  "a",
                                  _vm._b(
                                    {
                                      domProps: {
                                        textContent: _vm._s(_vm.titleContent),
                                      },
                                    },
                                    "a",
                                    {
                                      href: _vm.titleUrl,
                                      click: _vm.titleOnclickStr,
                                    },
                                    false
                                  )
                                ),
                                _vm._v(" "),
                                _vm.manageMode
                                  ? [
                                      _c("input", {
                                        attrs: {
                                          id: "headTitleInputV3",
                                          autocomplete: "off",
                                          maxlength: "50",
                                          autofocus: "autofocus",
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "headTitleEditPen",
                                        attrs: { title: "点击修改网站标题" },
                                      }),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.headStyleId == 1 ||
                  _vm.headStyleId == 2 ||
                  (_vm.headStyleId == 5 && _vm.hideSearchBox == 0) ||
                  _vm.manageMode
                    ? [
                        _c(
                          "div",
                          {
                            class: _vm.searchBoxClass,
                            attrs: { id: "searchBox" },
                          },
                          [
                            _vm.showDropMenu && _vm.dropMenuList.length > 0
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "jsb-showRange-block" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "title-top-defaultTitle sb-searchRange",
                                        },
                                        [_vm._v(_vm._s(_vm.otherTitle))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "arrow-svgDiv" },
                                        [
                                          _c(
                                            "svg",
                                            { staticClass: "sb-icon-arrow" },
                                            [
                                              _c("use", {
                                                attrs: {
                                                  fill: "#808080",
                                                  "xlink:href":
                                                    "#jzm-vicon_arrow_panel",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass:
                                      "sb-f_icon_conter f_icon_conter faisco-icons-S000290 g_main_color_v3",
                                  }),
                                  _vm._v(" "),
                                  _c("input", {
                                    staticClass: "topSearch all-top-search",
                                    attrs: {
                                      id: "topSearch",
                                      placeholder: _vm.searchTips,
                                    },
                                  }),
                                ]
                              : [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "jsb-showRange-block",
                                      staticStyle: { display: "none" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "title-top-defaultTitle sb-searchRange",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(_vm.otherTitle) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "arrow-svgDiv" },
                                        [
                                          _c(
                                            "svg",
                                            { staticClass: "sb-icon-arrow" },
                                            [
                                              _c("use", {
                                                attrs: {
                                                  fill: "#808080",
                                                  "xlink:href":
                                                    "#jzm-vicon_arrow_panel",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    staticClass: "topSearch",
                                    attrs: {
                                      id: "topSearch",
                                      placeholder: _vm.searchTips,
                                    },
                                  }),
                                  _c("i", { class: _vm.topSearchIconClass }),
                                ],
                          ],
                          2
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.headStyleId < 5 || _vm.isInnerPage || _vm.manageMode
                    ? [
                        _c(
                          "div",
                          {
                            class: _vm.functionGroupClass,
                            attrs: { id: "functional_group" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "contentBox" },
                              [
                                _vm.iconType == 0 || _vm.manageMode
                                  ? [
                                      _c("div", { class: _vm.loginClass }, [
                                        _c(
                                          "a",
                                          { attrs: { href: _vm.profileUrl } },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "faisco-icons-S000288",
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                (_vm.iconType == 1 && _vm.mallOpen) ||
                                _vm.manageMode
                                  ? [
                                      _c("div", { class: _vm.showCarV3Class }, [
                                        _c(
                                          "a",
                                          { attrs: { href: _vm.mcartUrl } },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "faisco-icons-S000292",
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.iconType == 2 || _vm.manageMode
                                  ? [
                                      _c("div", { class: _vm.mallNavClass }, [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: _vm.productGroupBtnHref,
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "faisco-icons-S000291",
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.iconType == 3 ||
                                _vm.isInnerPage ||
                                _vm.manageMode
                                  ? [
                                      _c(
                                        "div",
                                        { class: _vm.headerSearchIconClass },
                                        [_vm._m(1)]
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.openLanInfoList.length > 1 &&
                                (_vm.lanSwitch == 1 || _vm.manageMode)
                                  ? [
                                      _c(
                                        "div",
                                        { class: _vm.headLanSwitchIconClass },
                                        [
                                          _vm._m(2),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "languageSelect",
                                              attrs: { id: "languageSelect" },
                                            },
                                            [
                                              _c("div", {
                                                staticClass: "arrow",
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "lanContainer" },
                                                _vm._l(
                                                  _vm.openLanInfoList,
                                                  function (lan) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: lan.id,
                                                        staticClass: "lanItem",
                                                        class: {
                                                          [`lanCode${lan.lcid}`]: true,
                                                        },
                                                        attrs: {
                                                          value: lan.url,
                                                          lanCode:
                                                            _vm.createLanUrl(
                                                              lan.lanCode
                                                            ),
                                                          currentLan:
                                                            lan.lcid ===
                                                            _vm.lcid
                                                              ? lan.lcid
                                                              : void 0,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "lanTitle",
                                                            class: {
                                                              [`title<${lan.lcid}>`]: true,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(lan.name)
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isInnerPage || _vm.manageMode
                                  ? [
                                      _c(
                                        "div",
                                        { staticClass: "innerPageItem" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "iconTitle",
                                              attrs: { href: _vm.profileUrl },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm.col_member_center
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.headStyleId > 4 || _vm.manageMode
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "functional_group2",
                            attrs: { id: "functional_group2" },
                          },
                          [
                            _vm.topLabel === 0 || _vm.manageMode
                              ? [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "head_allItem funItem",
                                      class: { hidden: _vm.topLabel === 1 },
                                      attrs: { href: _vm.prUrl },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "icon-allItem",
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "header_icon" },
                                        [_vm._v(_vm._s(_vm.allProductNum))]
                                      ),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "itemText" }, [
                                        _vm._v(_vm._s(_vm.mobi_allGoods)),
                                      ]),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "head_member funItem",
                                attrs: { href: _vm.profileUrl },
                              },
                              [
                                _c("span", {
                                  staticClass: "header_icon icon-item",
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "itemText" }, [
                                  _vm._v(_vm._s(_vm.col_member_center) + ">"),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.mallOpen || _vm.manageMode
                              ? [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "head_myOrder funItem",
                                      class: { hidden: !_vm.mallOpen },
                                      attrs: { href: _vm.mlistUrl },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "header_icon icon-item",
                                      }),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "itemText" }, [
                                        _vm._v(_vm._s(_vm.member_center_order)),
                                      ]),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bg_content" }, [
      _c("div", { staticClass: "bg_content_color_box g_main_bgColor_v3" }),
      _vm._v(" "),
      _c("div", { staticClass: "bg_content_white_box" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "topSearch" }, [
      _c("i", { staticClass: "faisco-icons-S000290" }),
      _c("span", { staticClass: "iconTitle" }, [_vm._v("搜索")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "languaSwitch", attrs: { id: "languaSwitch" } },
      [_c("i", { staticClass: "faisco-icons-S000289" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }