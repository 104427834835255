import { isNull, popupWindowVersionTwo, popupWindow, getLanCode } from '@/shared/fai';
export default (options) => {
    var saveBeforePopup = true;
    if (!isNull(options.saveBeforePopup)) {
        saveBeforePopup = options.saveBeforePopup;
    }
    if (saveBeforePopup && Vue.prototype.$designer.checkSaveBar()) {
        return;
    }
    if ($.isFunction(Mobi.getPreviewWindow().Mobi.removeAllEditLayer)) {
        Mobi.getPreviewWindow().Mobi.removeAllEditLayer();
    }

    let url = options.frameSrcUrl;
    if (isRelative(url)) {
        const lanCode = getLanCode();
        if (lanCode && !url.startsWith(lanCode) && !url.startsWith(`/${lanCode}`)) {
            const lanCodePrefix = `/${lanCode}/`;
            let managePrefix = '';
            if (options.version !== 2 && url.includes('manage/') === false) {
                managePrefix = 'manage/';
            }
            options.frameSrcUrl = (lanCodePrefix + managePrefix + url).replace(/\/\//g, '/');
        }
    }

    if (options.version == 2) {
        return popupWindowVersionTwo.createPopupWindow(options);
    } else {
        return popupWindow(options);
    }
};

function isRelative(url = '') {
    return url.startsWith('http') === false && url.startsWith('//') === false;
}
