<template>
    <product-group-detail-catalog-view v-bind="$attrs" />
</template>

<script>
import ProductGroupDetailCatalogView from '../../components/catalog.vue';
import { initProductGroup } from '../scripts/initial';
import propsMixin from '../../mixins/props';

export default {
    name: 'ProductGroupDetailV1CatalogView',
    components: {
        ProductGroupDetailCatalogView,
    },
    mixins: [propsMixin],
    data() {
        return {};
    },
    mounted() {
        initProductGroup(this.groupStyle, this.productGroupType, VITE_APP_MODE !== 'visitor');
    },
};
</script>

<style></style>
