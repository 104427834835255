<template>
    <div class="float_effect" @click="jumpClickHandler">
        <div
            v-if="styleType === 1"
            class="float_effect_style float_effect_style_1 g_main_bgColor_v3 g_oldThemeBgColor"
            :style="iconBgStyle"
        >
            <svg v-if="iconType !== 3" class="preview_icon" :style="iconStyle">
                <use :xlink:href="svgIconId" />
            </svg>
            <img v-else-if="imgSrc" :src="imgSrc" class="preview_icon" />
        </div>
        <div
            v-if="styleType === 2"
            class="float_effect_style float_effect_style_2 g_main_bgColor_v3 g_oldThemeBgColor"
            :style="iconBgStyle"
        >
            <svg v-if="iconType !== 3" class="preview_icon" :style="iconStyle">
                <use :xlink:href="svgIconId" />
            </svg>
            <img v-else-if="imgSrc" :src="imgSrc" class="preview_icon" />
        </div>
        <div v-if="styleType === 3" class="float_effect_style float_effect_style_3" :style="iconBgStyle">
            <svg v-if="iconType !== 3" class="preview_icon" :style="iconStyle">
                <use :xlink:href="svgIconId" />
            </svg>
            <img v-else-if="imgSrc" :src="imgSrc" class="preview_icon" />
        </div>
    </div>
</template>

<script>
import { setUrlArgToCookieAndJump } from '@/modules/comm';
export default {
    name: 'FloatEffectIcon',
    props: {
        // eslint-disable-next-line vue/require-default-prop
        iconType: {
            type: Number,
        },
        // eslint-disable-next-line vue/require-default-prop
        imgSrc: {
            type: String,
        },
        // eslint-disable-next-line vue/require-default-prop
        styleType: {
            type: Number,
        },
        // eslint-disable-next-line vue/require-default-prop
        iconBackgroundColor: {
            type: String,
        },
        // eslint-disable-next-line vue/require-default-prop
        iconBackgroundColorType: {
            type: Number,
        },
        // eslint-disable-next-line vue/require-default-prop
        iconColor: {
            type: String,
        },
        // eslint-disable-next-line vue/require-default-prop
        iconColorType: {
            type: Number,
        },
        // eslint-disable-next-line vue/require-default-prop
        jumpUrl: {
            type: String,
        },
        // eslint-disable-next-line vue/require-default-prop
        jumpInfo: {
            type: Object,
        },
    },
    computed: {
        svgIconId() {
            const defaultSvgOtions = {
                0: '#jzm-vphone_1',
                1: '#jzm-vmessage',
                2: '#jzm-vcustomer_service',
            };
            return defaultSvgOtions[this.iconType];
        },
        iconBgStyle() {
            const IS_DEFAULT_ICON_BACKGROUND_COLOR = this.iconBackgroundColorType === 0;

            return IS_DEFAULT_ICON_BACKGROUND_COLOR
                ? {}
                : {
                      'background-color': this.iconBackgroundColor,
                  };
        },
        iconStyle() {
            const IS_DEFAULT_ICON_COLOR = this.iconColorType === 0;

            return IS_DEFAULT_ICON_COLOR
                ? {}
                : {
                      color: this.iconColor,
                  };
        },
        hasLink() {
            // 不是链接控件中无的情况
            return this.jumpInfo.t !== -1;
        },
    },
    methods: {
        jumpClickHandler() {
            if (!this.jumpUrl) {
                return;
            }

            // 先处理管理态拦截，其余正常跳转
            if (VITE_APP_MODE !== 'visitor') {
                if (this.jumpUrl.includes('http') || this.jumpUrl.includes('https')) {
                    Fai.top.Fai.ing('管理态不支持跳转外部链接，请通过“预览”进行访问', true);
                    return;
                }

                // 触发大保存，先保存
                if (this.hasLink && this.$designer.checkSaveBar()) {
                    return;
                }
            }
            setUrlArgToCookieAndJump(this.jumpUrl, this.jumpInfo.reqArgs);
        },
    },
};
</script>

<style>
.float_effect .preview_icon {
    width: 1rem;
    height: 1rem;
    color: #fff;
}
.float_effect .float_effect_style {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 0.08rem;
}

.fixed_iphoneX_footer .float_effect {
    bottom: 7.5rem;
}

.float_effect {
    cursor: pointer;
    margin: 0 0.3rem 0.43rem 0;
}

.float_effect .float_effect_style_1 {
    border-radius: 50%;
}

.float_effect .float_effect_style_3 {
    border-radius: 50%;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
    background-color: #fff;
}
.float_effect .float_effect_style_3 .preview_icon {
    color: #000;
}
</style>
