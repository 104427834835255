import { render, staticRenderFns } from "./item-style-4.vue?vue&type=template&id=ed03d078&scoped=true&"
import script from "./item-style-4.vue?vue&type=script&lang=js&"
export * from "./item-style-4.vue?vue&type=script&lang=js&"
import style0 from "./item-style-4.vue?vue&type=style&index=0&id=ed03d078&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed03d078",
  null
  
)

export default component.exports