/*
*@function 三行滑动地区(日期)的选择控件 分为两个闭包js：Dialog和MobileSelectArea
*@version {create} 2016-05-17
*@author {create} wjq
*@param:
##init:`0||1`
    0为空,true时默认选中第一项,默认1
##trigger:
    触发弹窗的DOM元素 ，可以是input或其他
##value:
    初始值,
#level: `int`
    级别数，默认是3级的
#separator: `,`
    id值分隔符
#eventName:`tap|click`
    触发事件名称，默认click,使用zeptojs的可以用tap事件
#callback:function(scroller,text,value)
第一个是容器，第二个是选中后的text值，第三个参数是选中后的id。
并且this指向当前对象。
选中后的回调，默认有填充trigger的value值，以及赋值它后面紧跟着的hidden的value值，以逗号分隔id，空格分隔文字
*/
import { site_cityUtil } from '@/shared/city';

let $ = {};
if (typeof window !== 'undefined') {
    $ = window.jm;
    $.fn.Dialog = function (settings) {
        var list = [];
        $(this).each(function () {
            var dialog = new Dialog();
            var options = $.extend(
                {
                    trigger: $(this),
                },
                settings
            );
            dialog.init(options);
            list.push(dialog);
        });
        return list;
    };
    $.Dialog = function (settings) {
        //这里可以拓展很多种形式的ui,参数可以从MobileSelectArea倒传过来。。。
        if (settings.type === 'confirm') {
            var dialog = new Dialog();
            var html = '<div class="fkarea-ui-confirm-title">' + settings.content + '</div>';
            var action = '';
            if (!settings.buttons) {
                settings.buttons = [
                    {
                        no: '取消',
                    },
                    {
                        yes: '完成',
                    },
                ];
            }
            var btnstr = '';
            for (var i = 0, l = settings.buttons.length; i < l; i++) {
                var item = settings.buttons[i];
                if (item.no) {
                    btnstr += '<td><button class="fkarea-ui-confirm-no" data-type="no">' + item.no + '</button></td>';
                }
                if (item.yes) {
                    btnstr +=
                        '<td><button class="fkarea-ui-confirm-submit " data-type="yes">' + item.yes + '</button></td>';
                }
            }
            action = '<table class="fkarea-ui-dialog-action"><tr>' + btnstr + '</tr></table>';
            html = action + html;
            var options = $.extend(
                {
                    target: html,
                    animate: true,
                    show: true,
                    fixed: true,
                    mask: true,
                    className: 'fkarea-ui-alert',
                    afterHide() {
                        this.dispose();
                    },
                    beforeShow(c) {
                        dialog.touch($('.fkarea-ui-confirm-submit', c), function () {
                            settings.callback && settings.callback.call(dialog, 'yes', c);
                            $('body').css({
                                overflow: 'auto',
                            });
                        });
                        dialog.touch($('.fkarea-ui-confirm-no', c), function () {
                            settings.callback && settings.callback.call(dialog, 'no', c);
                            $('body').css({
                                overflow: 'auto',
                            });
                        });
                    },
                },
                settings
            );
            dialog.init(options);
        }
    };
    /*
    buttons :[{yes:"完成"},{no:'取消'}]
    */
    $.confirm = function (content, buttons, callback, settings) {
        var options = {};
        var defaults = {
            zIndex: 1001,
            type: 'confirm',
        };
        if (typeof content == 'object') {
            options = $.extend(defaults, content);
        } else {
            options = $.extend(defaults, {
                content,
                buttons,
                callback,
            });
        }
        $.Dialog($.extend(options, settings));
    };
}

const Dialog = function () {
    var rnd = Math.random().toString().replace('.', '');
    this.id = 'dialog_' + rnd;
    this.settings = {};
    this.settings.titleTpl = $('<div class="fkarea-ui-dialog-title"></div>');
    this.timer = null;
    this.showed = false;
    this.mask = $();
};
Dialog.prototype = {
    init(settings) {
        this.settings = $.extend(
            {
                fixed: false, //是否固定位置，
            },
            this.settings,
            settings
        );
        $('.fkarea-ui-dialog-mask').length > 0 && $('.fkarea-ui-dialog-mask').remove();
        if (this.settings.mask) {
            this.mask = $('<div class="fkarea-ui-dialog-mask"/>');
            $('#g_body').append(this.mask);
        }
        $('.fkarea-ui-dialog').length > 0 && $('.fkarea-ui-dialog').remove();
        $('#g_body').append('<div class="fkarea-ui-dialog" id="' + this.id + '"></div>');
        this.dialogContainer = $('#' + this.id);
        var zIndex = this.settings.zIndex || 10;
        this.dialogContainer.css({
            zIndex,
        });
        if (this.settings.className) {
            this.dialogContainer.addClass(this.settings.className);
        }
        this.mask.css({
            zIndex: zIndex - 1,
        });
        if (this.settings.title) {
            this.dialogContainer.append(this.settings.titleTpl);
            this.settings.titleTpl.html(this.settings.title);
        }
        this.bindEvent();
        if (this.settings.show) {
            this.show();
        }
    },
    touch(obj, fn) {
        var move;
        $(obj).on('click', click);

        function click(e) {
            return fn.call(this, e);
        }
        $(obj)
            .on('touchmove', function (e) {
                move = true;
                e.preventDefault();
                e.stopPropagation();
            })
            .on('touchend', function (e) {
                e.preventDefault();
                if (!move) {
                    var returnvalue = fn.call(this, e, 'touch');
                    if (!returnvalue) {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }
                move = false;
            });
    },
    bindEvent() {
        var _this = this;
        if (this.settings.trigger) {
            $(this.settings.trigger).click(function () {
                _this.show();
            });
            _this.touch($(this.settings.trigger), function () {
                _this.show();
            });
        }
        $(this.dialogContainer).on('click', '.js-dialog-close', function () {
            _this.hide();
            return false;
        });
        $(this.dialogContainer).on('hide', function () {
            _this.hide();
        });
    },
    dispose() {
        this.dialogContainer.remove();
        this.mask.remove();
        this.timer && clearInterval(this.timer);
    },
    hide() {
        var _this = this;
        if (_this.settings.beforeHide) {
            _this.settings.beforeHide.call(_this, _this.dialogContainer);
        }
        this.showed = false;
        this.mask.hide();

        this.timer && clearInterval(this.timer);
        if (this.settings.animate) {
            this.dialogContainer.removeClass('fkarea-zoomIn').addClass('fkarea-zoomOut');
            setTimeout(function () {
                _this.dialogContainer.hide();
                if (typeof _this.settings.target === 'object') {
                    $('body').append(_this.dialogContainer.hide());
                }
                if (_this.settings.afterHide) {
                    _this.settings.afterHide.call(_this, _this.dialogContainer);
                }
            }, 500);
        } else {
            this.dialogContainer.hide();
            if (typeof this.settings.target === 'object') {
                $('body').append(this.dialogContainer);
            }
            if (this.settings.afterHide) {
                this.settings.afterHide.call(this, this.dialogContainer);
            }
        }
    },
    show() {
        if (typeof this.settings.target === 'string') {
            if (/^(\.|#\w+)/gi.test(this.settings.target)) {
                this.dailogContent = $(this.settings.target);
            } else {
                this.dailogContent = $('<div>' + this.settings.target + '</div>');
            }
        } else {
            this.dailogContent = this.settings.target;
        }
        this.mask.show();

        this.mask.bind('touchmove', function (e) {
            e.preventDefault();
        });
        $('body').css({
            overflow: 'hidden',
        });
        this.dailogContent.show();
        this.dailogContent.bind('touchmove', function (e) {
            e.preventDefault();
        });
        this.height = this.settings.height || 'auto'; //this.dialogContainer.height();
        this.width = this.settings.width || 'auto'; //this.dialogContainer.width();
        this.dialogContainer.append(this.dailogContent).show().css({
            height: this.height,
            width: this.width,
        });
        if (this.settings.beforeShow) {
            this.settings.beforeShow.call(this, this.dialogContainer);
        }
        this.showed = true;
        //  $(this.settings.trigger).blur();

        this.setPosition();
        var _this = this;
        // $.alert(this.settings.clientWidth)
        this.timer && clearInterval(this.timer);
        if (this.settings.fixed) {
            this.timer = setInterval(function () {
                _this.setPosition();
            }, 1000);
        }
        if (this.settings.animate) {
            this.dialogContainer.addClass('fkarea-zoomIn').removeClass('fkarea-zoomOut').addClass('fkarea-animated');
        }
    },
    setPosition() {
        if (this.showed) {
            var _this = this;
            this.dialogContainer.show();
            this.height = this.settings.height;
            this.width = this.settings.width;
            if (isNaN(this.height)) {
                this.height =
                    (this.dialogContainer.outerHeight && this.dialogContainer.outerHeight()) ||
                    this.dialogContainer.height();
            }
            if (isNaN(this.width)) {
                this.width =
                    (this.dialogContainer.outerWidth && this.dialogContainer.outerWidth()) ||
                    this.dialogContainer.width();
            }
            var clientHeight =
                this.settings.clientHeight || document.documentElement.clientHeight || document.body.clientHeight;
            var mt = this.height / 2;
            var top = clientHeight / 2 - mt;
            top = Math.floor(Math.max(0, top));
            var position = 'absolute';
            var maxWidth = '768px';
            if (jm && jm('#g_web') && jm('#g_web').length > 0) {
                maxWidth = jm('#g_web').css('max-width');
            }
            if (_this.settings.fixed) {
                position = 'fixed';
            }
            _this.dialogContainer.css({
                position,
                top,
                width: '100%',
                left: 0,
                right: 0,
                maxWidth,
                margin: '0px auto',
            });
        }
    },
};

export const MobileSelectArea = function () {
    var rnd = Math.random().toString().replace('.', '');
    this.id = 'scroller_' + rnd;
    this.scroller;
    this.data;
    this.index = 0;
    this.value = [0, 0, 0];
    this.oldvalue;
    this.oldtext = [];
    this.text = ['', '', ''];
    this.level = 3;
    this.mtop = 30;
    this.separator = '';
};
MobileSelectArea.prototype = {
    init(settings) {
        this.settings = $.extend(
            {
                eventName: 'click',
            },
            settings
        );
        this.lcid = parseInt(this.settings.lcid);
        this.trigger = $(this.settings.trigger);
        this.settings.init == undefined ? (this.init = 1) : (this.init = 0); //0为空,1时默认选中第一项
        let level = parseInt(this.settings.level);
        this.level = level > 0 ? level : 3;
        this.trigger.attr('readonly', 'readonly');
        this.value = (this.settings.value && this.settings.value.split(',')) || [0, 0, 0];
        this.text = this.settings.text || this.trigger.val().split(' ') || ['', '', ''];
        this.oldvalue = this.value.concat([]);
        this.oldtext = this.text.concat([]);
        this.clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
        this.clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
        this.bindEvent();
    },
    getData() {
        if (typeof this.settings.data == 'object') {
            this.data = this.settings.data;
        }
    },
    bindEvent() {
        var _this = this;
        this.trigger[_this.settings.eventName](async function () {
            var dlgContent = '';
            for (var i = 0; i < _this.level; i++) {
                dlgContent += '<div></div>';
            }
            if (_this.lcid != 2052 && _this.lcid != 1028) {
                _this.buttons = [
                    {
                        no: 'cancel',
                    },
                    {
                        yes: 'fulfil',
                    },
                ];
            } else {
                _this.buttons = null;
            }
            $.confirm(
                '<div class="fkarea-ui-scroller-mask"><div id="' +
                    _this.id +
                    '" class="ui-scroller">' +
                    dlgContent +
                    '<p></p></div></div>',
                _this.buttons,
                function (t) {
                    if (t == 'yes') {
                        _this.submit();
                    }
                    if (t == 'no') {
                        _this.cancel();
                    }
                    this.dispose();
                },
                {
                    width: 320,
                    height: 215,
                }
            );
            _this.scroller = $('#' + _this.id);
            _this.getData();
            await _this.format();
            var start = 0,
                end = 0;

            _this.scroller.children().off('mousedown.area-scroller').on('mousedown.area-scroller', areaResizeMouseDown);

            function areaResizeMouseDown(event) {
                event = event || window.event;
                start = event.pageY;
                $(window).off('mousemove.area-scroller').on('mousemove.area-scroller', areaResizeMouseMove);
                $(window).off('mouseup.area-scroller').on('mouseup.area-scroller', areaResizeMouseStop);
                event.preventDefault();
            }

            function areaResizeMouseMove(event) {
                event = event || window.event;
                end = event.pageY;
                var diff = end - start;
                var dl = $(event.target).parent();
                if (dl[0].nodeName != 'DL') {
                    return;
                }
                var top = parseInt(dl.css('top') || 0) + diff;

                if (top < -$(dl).height() + 60) {
                    top = -$(dl).height() + 60;
                }

                dl.css('top', top + 'px');
                start = end;
                event.preventDefault();
            }

            async function areaResizeMouseStop(event) {
                event = event || window.event;
                end = event.pageY;

                $(window).off('mousemove.area-scroller');
                $(window).off('mouseup.area-scroller');
                var diff = end - start;
                var dl = $(event.target).parent();
                if (dl[0].nodeName != 'DL') {
                    return;
                }
                var i = $(dl.parent()).index();
                var top = parseInt(dl.css('top') || 0) + diff - 30; //这里的之所以减30,是与要显示几列地址有关。
                if (top > _this.mtop) {
                    top = _this.mtop;
                }
                if (top < -$(dl).height() + 60) {
                    top = -$(dl).height() + 60;
                }
                var mod = top / _this.mtop;
                var mode = Math.round(mod);
                var index = Math.abs(mode) + 1;
                if (mode == 1) {
                    index = 0;
                }

                _this.value[i] = $(dl.children().get(index)).attr('ref');
                _this.value[i] == 0 ? (_this.text[i] = '') : (_this.text[i] = $(dl.children().get(index)).html());
                for (var j = _this.level - 1; j > i; j--) {
                    _this.value[j] = 0;
                    _this.text[j] = '';
                }
                if (!$(dl.children().get(index)).hasClass('focus')) {
                    await _this.format();
                }
                $(dl.children().get(index)).addClass('focus').siblings().removeClass('focus');
                dl.css('top', mode * _this.mtop + 'px');
                event.preventDefault();
            }

            _this.scroller.children().bind('touchstart', function (e) {
                start = (e.changedTouches || e.originalEvent.changedTouches)[0].pageY;
            });
            _this.scroller.children().bind('touchmove', function (e) {
                end = (e.changedTouches || e.originalEvent.changedTouches)[0].pageY;
                var diff = end - start;
                var dl = $(e.target).parent();
                if (dl[0].nodeName != 'DL') {
                    return;
                }
                var top = parseInt(dl.css('top') || 0) + diff;

                if (top < -$(dl).height() + 60) {
                    top = -$(dl).height() + 60;
                }

                dl.css('top', top + 'px');
                start = end;
                return false;
            });
            _this.scroller.children().bind('touchend', async function (e) {
                end = (e.changedTouches || e.originalEvent.changedTouches)[0].pageY;
                var diff = end - start;
                var dl = $(e.target).parent();
                if (dl[0].nodeName != 'DL') {
                    return;
                }
                var i = $(dl.parent()).index();
                var top = parseInt(dl.css('top') || 0) + diff - 30;
                if (top > _this.mtop) {
                    top = _this.mtop;
                }
                if (top < -$(dl).height() + 60) {
                    top = -$(dl).height() + 60;
                }
                var mod = top / _this.mtop;
                var mode = Math.round(mod);
                var index = Math.abs(mode) + 1;
                if (mode == 1) {
                    index = 0;
                }

                _this.value[i] = $(dl.children().get(index)).attr('ref');
                _this.value[i] == 0 ? (_this.text[i] = '') : (_this.text[i] = $(dl.children().get(index)).html());
                for (var j = _this.level - 1; j > i; j--) {
                    _this.value[j] = 0;
                    _this.text[j] = '';
                }
                if (!$(dl.children().get(index)).hasClass('focus')) {
                    await _this.format();
                }
                $(dl.children().get(index)).addClass('focus').siblings().removeClass('focus');
                dl.css('top', mode * _this.mtop + 'px');
                return false;
            });
            return false;
        });
    },
    async format() {
        await this.f(this.data);
    },
    async f(data) {
        var _this = this;
        var item = data;
        if (!item) {
            item = [];
        }
        var str = '<dl><dd ref="0">——</dd>',
            childData,
            top = _this.mtop;
        if (_this.index !== 0 && _this.value[_this.index - 1] == '0' && this.init == 0) {
            str = '<dl><dd ref="0" class="focus">——</dd>';
            _this.value[_this.index] = 0;
            _this.text[_this.index] = '';
        } else {
            if (_this.value[_this.index] == '0') {
                str = '<dl><dd ref="0" class="focus">——</dd>';
            }
            if (item.length > 0 && this.init == 1) {
                str = '<dl>';
                let pid = item[0].pid || 0;
                let id = item[0].id || 0;
                if (_this.lcid == 2052 || _this.lcid == 1028) {
                    childData = await site_cityUtil.getCities(item[0].id);
                } else {
                    childData = await site_cityUtil.getCitiesEn(item[0].id);
                }
                if (!_this.value[this.index]) {
                    _this.value[this.index] = id;
                    _this.text[this.index] = item[0].name;
                }
                str += '<dd pid="' + pid + '" class="' + cls + '" ref="' + id + '">' + item[0].name + '</dd>';
            }
            for (var j = _this.init, len = item.length; j < len; j++) {
                let pid = item[j].pid || 0;
                let id = item[j].id || 0;
                var cls = '';
                if (_this.value[_this.index] == id) {
                    cls = 'focus';
                    if (_this.lcid == 2052 || _this.lcid == 1028) {
                        childData = await site_cityUtil.getCities(item[j].id);
                    } else {
                        childData = await site_cityUtil.getCitiesEn(item[j].id);
                    }
                    top = _this.mtop * -(j - _this.init);
                }
                str += '<dd pid="' + pid + '" class="' + cls + '" ref="' + id + '">' + item[j].name + '</dd>';
            }
        }
        str += '</dl>';
        var newdom = str;
        var child = _this.scroller.children();
        $(child[_this.index]).html(newdom);
        $(child[_this.index])
            .children()
            .css('top', top + 30 + 'px');
        _this.index++;
        if (_this.index > _this.level - 1) {
            _this.index = 0;
            return;
        }
        await _this.f(childData);
    },
    submit() {
        this.oldvalue = this.value.concat([]);
        this.oldtext = this.text.concat([]);
        if (this.trigger[0].nodeType == 1) {
            //input
            this.trigger.val(this.text.join(this.separator));
            this.trigger.attr('data-value', this.value.join(','));
        }
        //this.trigger.next(':hidden').val(this.value.join(','));
        this.settings.callback && this.settings.callback.call(this, this.scroller, this.text, this.value);
    },
    cancel() {
        this.value = this.oldvalue.concat([]);
        this.text = this.oldtext.concat([]);
    },
};
