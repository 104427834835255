import request from './request/index';

function post(options) {
    return request.post(options.url, {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        ...options,
    });
}

export function visited(data) {
    return post({
        url: '/ajax/mobistatistics_h.jsp?cmd=wafNotCk_visited',
        data,
    }).then(({ data }) => data);
}

export function getTimeData(options) {
    return post({
        url: '/ajax/mobistatistics_h.jsp?cmd=wafNotCk_timeDataNoAuth',
        ...options,
    }).then(({ data }) => data);
}
