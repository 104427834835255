export function copyDataToClipboard({ data, sCb, fCb }) {
    let e = document.createElement('textarea');
    e.value = data;
    document.body.appendChild(e);
    e.setAttribute('readonly', '');
    e.select();
    e.setSelectionRange(0, e.value.length);
    let isSuccessed = false;
    try {
        isSuccessed = document.execCommand('copy');
    } catch (account) {
        fCb();
    } finally {
        document.body.removeChild(e);
    }
    if (isSuccessed) {
        sCb();
    } else {
        fCb();
    }
}
