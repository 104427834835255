<template>
    <div v-if="renderTopTips.length" id="webTips" class="webTips jz-webTips" :style="webTipsStyle">
        <div id="mobitips" class="mobitips">
            <div class="mobiScrollbar" :style="mobiScrollbarStyle">
                <div class="j-marqueeContainer J_marqueeContainer">
                    <ul id="mobiTipsMarquee" class="marquee J_marquee">
                        <!-- 直出提示 -->
                        <li v-for="mobiTip in mobiTipList" v-html="mobiTip"></li>
                        <!-- load之后触发的提示 -->
                        <template v-for="item in topTips">
                            <li v-if="item.type == undefined || item.type == 0" style="color: red; margin-left: 20px"
                                >免费域名不支持调用第三方代码，请使用已购买的域名进行访问&nbsp;&nbsp;&nbsp;</li
                            >
                            <li
                                v-else-if="item.type == 1 && httpInCodeCount"
                                style="color: #ff8e1e; font-size: 13px; text-align: 0; margin-left: 20px"
                                >网站内插入的代码存在非HTTPS链接，该链接无法正常加载显示，请替换成HTTPS链接或使用非HTTPS方式访问&nbsp;&nbsp;&nbsp;</li
                            >
                            <li v-else-if="item.type == 2" style="color: red; margin-left: 20px"
                                >免费域名不支持插件代码&nbsp;&nbsp;&nbsp;</li
                            >
                            <li v-else-if="item.type == 3" style="color: red; margin-left: 20px"
                                >基于安全原因，2018年4月11号起新注册的用户免费域名访问不支持插件代码和第三方代码，请<a
                                    style="color: #5874d8"
                                    onclick="Mobi.logCorpDog(200848, 5)"
                                    :href="urlForBindDevDomain"
                                    target="_blank"
                                    >绑定独立域名</a
                                >进行访问或<a
                                    style="color: #5874d8"
                                    onclick="Mobi.logCorpDog(200848, 2)"
                                    :href="urlForApplyFaiscoAuth"
                                    target="_blank"
                                    >申请凡科认证</a
                                ></li
                            >
                        </template>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { topTipMarquee } from '@/preview/scripts/init-page';
export default {
    data() {
        return {
            urlForBindDevDomain: '',
            urlForApplyFaiscoAuth: '',
        };
    },
    computed: {
        ...mapState(['topTips', 'mobiTipList', 'httpInCodeCount']),
        ...mapState('app', ['openThemeV3']),
        webTipsStyle() {
            return VITE_APP_MODE !== 'visitor'
                ? {
                      display: 'none',
                  }
                : {};
        },
        mobiScrollbarStyle() {
            return {};
        },
        renderTopTips() {
            return [...this.topTips, ...this.mobiTipList];
        },
    },
    watch: {
        mobiTipList: {
            deep: true,
            handler() {
                this.$nextTick(() => {
                    topTipMarquee();
                });
            },
        },
    },
    mounted() {
        this.urlForBindDevDomain = '//' + Fai.top._homeHost + '/marketing/domain.html'; //绑定独立域名
        this.urlForApplyFaiscoAuth = '//' + Fai.top._portalHost + '/portal.jsp#appId=setAuth'; //申请凡科认证
        topTipMarquee();
    },
};
</script>

<style></style>
