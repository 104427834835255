import { isWechat } from '@/shared/browser';
import { logJZFdp } from '@/api/logDog';
import { isPC } from '@/shared/util';
export function jzTechSupportClick(isFooter) {
    logJZFdp('jz_tech_support_clik', {
        jz_free_text1: isPC() ? 'PC' : '手机',
        jz_free_text2: isWechat() ? '微信浏览器' : '非微信浏览器',
        jz_free_text0: isFooter ? '底部技术支持' : '免费站点广告',
        jz_free_text4: window._isFreeVer ? '免费站点' : '付费站点',
    });
}
