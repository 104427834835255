/*
  文档节点类型：
  Node.ELEMENT_NODE = 1，An Element node like <p> or <div>.
  Node.ATTRIBUTE_NODE = 2，	An Attribute of an Element.
  Node.TEXT_NODE = 3，	The actual Text inside an Element or Attr.
  Node.DOCUMENT_NODE = 9，	A Document node.
  Node.DOCUMENT_TYPE_NODE = 10，   such as <!DOCTYPE html>.
  Node.DOCUMENT_FRAGMENT_NODE = 11，A DocumentFragment node
  更多查看：https://developer.mozilla.org/en-US/docs/Web/API/Node/nodeType
*/

/**
 * 判断当前节点是否为元素节点
 * @param {Element} el 当前节点
 * @returns {Boolean} 是否为元素节点
 */
export const isHtmlElement = (el) => {
    return el && el.nodeType == Node.ELEMENT_NODE;
};

/**
 *判断当前节点是否为 a 标签
 *
 * @param {Element} el 当前节点
 * @returns {Boolean} 是否为a标签
 */
export const isLinkHtmlElement = (el) => {
    return isHtmlElement(el) && el.tagName == 'A';
};

/**
 * 在一定范围内当前节点的父节点是否有a标签
 * @param {Element} el  当前节点
 * @param {Element} endParent 最高父节点
 * @returns {Element} 返回节点，是a节点则返回，否则为null
 */
export const findParentLinkNode = (el, endParent = document.body) => {
    if (!isHtmlElement(el)) return null;
    let parentNode = el.parentNode;
    let parentNodeIsA = isLinkHtmlElement(parentNode);
    if (parentNodeIsA) {
        return parentNode;
    } else {
        return parentNode == endParent ? null : findParentLinkNode(parentNode, endParent);
    }
};
