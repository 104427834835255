<template>
    <module-frame :module-id="moduleId">
        <div v-if="showFixTopHeadHeight" id="fixTopHeadHeight" style="height: 2.25rem"></div>
        <div :id="'newsNewDetailPanel' + moduleId" class="newsNewDetailPanel" :class="headToolCls">
            <news-detail-header :version="2" />
            <news-detail-content :info="newsInfo" :version="2" />
        </div>
        <div v-if="isOpen && allowSubmitMessage" class="newsBottom J_newsBottom">
            <div id="postComment" class="postComment"
                ><span class="icon-postComm"></span>{{ mobi_productPostCommentLs }}</div
            >
        </div>
        <comment />
    </module-frame>
</template>
<script>
//////  ModuleNewNewsDetail.java 迁移至此。

import { mapGetters, mapMutations, mapState } from 'vuex';
import ModuleFrame from '@/modules/frame/index.vue';
import NewsDetailHeader from '@/system/newsDetail/newsDetailV3/module/component/header.vue';
import NewsDetailContent from '@/system/newsDetail/newsDetailV3/module/component/content.vue';
import comment from '@/system/newsDetail/newsDetailV3/module/component/comment.vue';

import { showTopTips } from '@/shared/tips';
import { initNewsEvent } from '@/modules/news/util';
import { LinkSettingUtil } from '@/shared/linkSetting/index';
import { fixNewsDetailVideo, fixModuleTop } from '@/system/shared/newsDetail';
import { allFixWXPA } from '@/system/shared/mobiPATipFix';
import { getCommentsInfo } from '@/modules/shared/comment';
import { encodeHtmlJs } from '@/shared/util';
import { MOBILE_AUTH_TIPS } from '@/services';
export default {
    name: 'NewNewsDetail',
    components: {
        ModuleFrame,
        NewsDetailHeader,
        NewsDetailContent,
        comment,
    },
    provide() {
        return {
            moduleId: this.moduleId,
            openThemeV3: this.openThemeV3 || false,
            manageMode: VITE_APP_MODE !== 'visitor',
            style: this.module.style,
        };
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            manageMode: VITE_APP_MODE !== 'visitor',
        };
    },
    computed: {
        ...mapState('app', ['LS', 'realNameAuth']),
        ...mapState(['mobiTipList']),
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        options() {
            return this.module.renderOptions || {};
        },
        content() {
            return this.module.content;
        },
        newsInfo() {
            return this.options.newsInfo;
        },
        newsId() {
            return this.newsInfo.id;
        },
        showFixTopHeadHeight() {
            return this.options.indepentheadShow && this.options.isHeadFixTheme;
        },
        pageElementList() {
            //页面元素列表
            return this.content.pageElementList;
        },
        shareOn() {
            // 开启分享
            return this.content.ss == 0;
        },
        isOpen() {
            //是否开通评论
            return this.options.isOpen || false;
        },
        hasHeadTool() {
            let { showHomePage, sbb } = this.pageElementList;
            return showHomePage == 0 || sbb == 0 || this.shareOn;
        },
        headToolCls() {
            return !this.hasHeadTool ? 'is_no_head_tool' : '';
        },
        mobi_productPostCommentLs() {
            return this.LS.mobi_productPostComment;
        },
        allowSubmitMessage() {
            const visitorMode = VITE_APP_MODE === 'visitor';
            if (visitorMode) {
                return this.realNameAuth.allowSubmitMessage;
            }
            return true;
        },
    },
    mounted() {
        let { moduleId, options, returnUrl, content } = this;
        let { hasThirdCode, shareData, newsInfo, grapicShareInfo, isOpen, showCaptcha } = options;
        if ($.isEmptyObject(newsInfo)) {
            return false;
        }
        Fai.top._shareData = shareData;
        if (hasThirdCode) {
            // 20171115 判断分销免费域名是否有第三方代码，有的话就给提示
            Fai.top._oem ? showTopTips() : showTopTips(3);
        }
        initNewsEvent({
            moduleId,
            pageStyle: content.pageStyle,
            templateDesignType: window.templateDesignType,
            returnUrl: returnUrl != '' ? encodeHtmlJs(returnUrl) : '',
        });
        Fai.top._shareDataNewsDetail = grapicShareInfo; // 添加 图文分享信息 qjie 2019.11.11
        LinkSettingUtil.switchJump();
        if (isOpen) {
            // 开启评论
            showCaptcha && (Mobi._needFaitest = true);
            $('#g_web').append($(`#module${moduleId} .J_newsBottom`));
        }
        // 由于有些主题的 form 样式会设置 top 属性，这会影响详情页的布局
        fixModuleTop(moduleId);
        allFixWXPA(2, moduleId, isOpen);
        fixNewsDetailVideo();

        getCommentsInfo('getWafNotCk_newNews', this.newsId, 1, this.moduleId);

        this.initRealNameAuthTips();
    },
    methods: {
        ...mapMutations(['addMobiTips']),
        initRealNameAuthTips() {
            if (VITE_APP_MODE === 'visitor') {
                if (this.realNameAuth.allowShowMessage === false) {
                    this.addMobiTips(MOBILE_AUTH_TIPS);
                }
            }
        },
    },
};
</script>

<style lang="scss">
.mobiCol21 .webHeader,
.mobiCol21 .webFooter,
.mobiCol21 #footer,
.mobiCol21 .webTopBox {
    display: none;
}
.mobiCol21 .formMiddleContent19 {
    margin-bottom: 2rem;
}

.productNameSwipBox,
.newsInfoTitle {
    position: absolute;
    width: 100%;
    bottom: 0;
    box-sizing: border-box;
    padding: 0.9rem 0.8rem;
    text-align: left;
    color: #fff;
    font-size: 0.8rem;
    background: rgba(19, 19, 19, 0.5);
    background: linear-gradient(rgba(76, 76, 76, 0) 0%, rgba(19, 19, 19, 0.5) 100%);
}
</style>
