<template>
    <news-detail-v3 :module-id="moduleId"></news-detail-v3>
</template>
<script>
import NewsDetailV3 from './module/index.vue';
import { clueLogMixin } from '../util.js';

export default {
    name: 'NewsDetailV3Visitor',
    components: {
        NewsDetailV3,
    },
    mixins: [clueLogMixin],
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {};
    },
};
</script>
