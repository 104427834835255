import { MODULE_STYLE } from '@/def/module';
import { showThisModule } from '../manage-module/module-tools';

/**
 *
 * @param {number} moduleId
 */
export async function moveSpecialModuleInNewPack(moduleId, parentId, extInfo = {}) {
    const insertIdx = extInfo.insertIdx || getInsertIdx(moduleId);

    const params = {
        insertIdx,
        parentId,
        style: MODULE_STYLE.PACK,
        params: {
            name: '自由容器',
        },
    };

    const { position, originalParentId } = extInfo;
    if (!Number.isNaN(Number(originalParentId))) {
        params.originalParentId = originalParentId;
    }

    const parent = await window.$store.dispatch('addModule', params);
    addModuleToPack(parent.id, moduleId, { originalParentId, position });
    return { parent, insertIdx };
}

export function addModuleToPack(packId, moduleId, extInfo = {}) {
    $(`#module${moduleId}`).remove();
    const params = {
        parentId: packId,
        moduleId,
    };
    const { originalParentId, position } = extInfo;
    if (!Number.isNaN(Number(originalParentId))) {
        params.originalParentId = originalParentId;
    }
    if (position) {
        params.top = position.top;
        params.left = position.left;
    }
    window.$store.commit('addChildModule', params);
    showThisModule(packId);
}

export function checkPackModuleIsEmpty(containerId) {
    var packModule = $('#module' + containerId),
        packContentList = packModule.find('.J_packContentList'),
        packBtnWrap = packModule.find('.J_packEditBtn');

    if (packContentList.find('.J_formPackContent .form').length > 0) {
        packBtnWrap.hide();
        return;
    } else {
        packBtnWrap.show();
    }
}

// 删除模块组内模块
export function removeModuleInPack(packId, moduleId) {
    var packModule = $('#module' + packId),
        moduleAttr;

    packModule.find('#module' + moduleId).css({
        top: '',
        left: '',
        position: '',
    });

    moduleAttr = window.$store.state.manage.moduleAttr;
    moduleAttr.content = moduleAttr.content || {};
    moduleAttr.contentChange = true;
    if (packModule.parents('.formStyle50').length) {
        let labelModuleId = parseInt(packModule.parents('.formStyle50').attr('id').replace('module', ''));
        updateLabelModule(moduleId, packId, labelModuleId, 'hide');
    } else if (packModule.parents('.formStyle58').length) {
        let labelModuleId = parseInt(packModule.parents('.formStyle58').attr('id').replace('module', ''));
        updateLabelModule(moduleId, packId, labelModuleId, 'hide');
    }

    checkPackModuleIsEmpty(packId);
    Vue.prototype.$designer.styleChanged();
}

function updateLabelModule(id, packModuleId, labelModuleId, type) {
    id = parseInt(id);
    packModuleId = parseInt(packModuleId);
    if (type == 'add') {
        let labelModuleData = Fai.top.labelModuleData['module' + labelModuleId];
        var exit = false;
        for (var i = 0; i < labelModuleData.length; i++) {
            var tmpData = labelModuleData[i];
            if (tmpData.id == id) {
                exit = true;
                break;
            }
        }
        if (exit) {
            return;
        }
        labelModuleData.forEach(function (val) {
            if (val.id == id) {
                exit = true;
            }
        });

        labelModuleData.push({
            id,
            inPack: packModuleId,
        });
    } else if (type == 'hide') {
        let labelModuleData = Fai.top.labelModuleData['module' + labelModuleId];
        labelModuleData.forEach(function (val, index) {
            if (val.id == id) {
                labelModuleData.splice(index, 1);
            }
        });
    }
}

/**
 * 从 0 开始算起位置
 * @param {number} moduleId
 * @returns
 */
function getInsertIdx(moduleId) {
    const $visibleModules = $('#webModuleContainer').children('.form');
    let visibleIdx = Array.from($visibleModules).findIndex((el) => el.getAttribute('id') === `module${moduleId}`);
    if (visibleIdx <= 0) {
        // 首位
        return 0;
    } else if (visibleIdx > $visibleModules.length - 1) {
        // 末位
        return $visibleModules.length - 1;
    } else {
        // 中间情况，获取前一位可视区的模块ID, 计算出在真实ID列表里的排序位置再加一
        const prevModuleId = parseInt($visibleModules[visibleIdx - 1].getAttribute('id').replace('module', ''));
        const currentPageModuleIds = window.$store.state.currentPageModuleIds;
        return currentPageModuleIds.findIndex((id) => id === prevModuleId) + 1;
    }
}
