import { getUrlParamObj } from '@/shared/url';

/**
 *
 *  同构(管理态和访客态)刷新页面
 * @export
 */
export function reloadPage() {
    if (VITE_APP_MODE !== 'visitor') {
        window.$router.go(0);
    } else {
        window.location.reload();
    }
}
/**
 *  同构(管理态和访客态)跳转页面，直接传入href即可
 * @param href { string }
 * @param extInfo { Object }
 * @export
 */
export function changeHref(href, extInfo) {
    if (VITE_APP_MODE !== 'visitor') {
        window.$store.commit('manage/updateDesignerData', {
            panelType: '',
            currentPanel: '',
            panelOpen: false,
            moduleId: -1,
            styleId: -1,
        });
        window.$store.commit('panelSetting/updateMiddelLoadCount');
        window._designerPanelVueIns &&
            window._designerPanelVueIns.clearCache &&
            window._designerPanelVueIns.clearCache();
        window.$router.changeHref(href, extInfo);
    } else {
        window.location.href = href;
    }
}

const removeDuplicatedQuery = function (query = {}) {
    Object.keys(query).forEach((key) => {
        if (Array.isArray(query[key]) && query[key].length > 1) {
            query[key] = query[key][0];
        }
    });
};

/**
 *
 *  同构(管理态和访客态)获取路由hash信息
 * @export
 */
export const getRouterData = function () {
    let search = typeof location != 'undefined' ? location.search : '';
    if (VITE_APP_MODE !== 'visitor') {
        const queryData = JSON.parse(window.$router.currentRoute.query.data ?? '{}');

        removeDuplicatedQuery(queryData);

        // SEO模式,会把url参数放到_reqArgs，TODO，管理态应该不用SEO优化
        if ('_reqArgs' in queryData) {
            let reqArgs = queryData._reqArgs;
            return JSON.parse(decodeURIComponent(decodeURIComponent(reqArgs ?? '{}'))).args ?? {};
        } else {
            // 普通模式
            return queryData;
        }
    } else {
        return getUrlParamObj(search);
    }
};
/**
 *
 *  同构(管理态和访客态)返回上一页方法
 * @export
 */
export const goBack = () => {
    if (VITE_APP_MODE !== 'visitor') {
        window.$router.back();
    } else {
        history.back();
    }
};

export function getRouterHistory() {
    if (VITE_APP_MODE !== 'visitor') {
        return window.$store.state.route.history;
    } else {
        return window.history;
    }
}

function _reloadPreview() {
    window.$store.commit('panelSetting/updateMiddelLoadCount');
    window.$router.replace({
        query: {
            ...window.$router.currentRoute.query,
            ...{ ts: +new Date() },
        },
    });
}

export function reloadPreview() {
    if (VITE_APP_MODE !== 'visitor') {
        if (Fai.top._changeStyleNum > 0) {
            Vue.prototype.$designer.saveStyle({
                fun() {
                    _reloadPreview();
                },
            });
        } else {
            _reloadPreview();
        }
    } else {
        window.location.reload();
    }
}
