var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isShowFooterNav
    ? _c(_vm.FooterNav, { tag: "component" })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }