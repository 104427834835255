import { logDog, logBssFodder } from '@/shared/log';
import { context } from '@jz/utils';
import { popupWindowVersionTwo, ing } from '@/shared/fai';
import { encodeUrl, getSiteMultiLanguageUrl } from '@/shared/url';
import { getCookieFlag, setCookieFlag } from '@/shared/flag';
import { isDevEnv, REQUEST_BASE_URL } from '@/shared/env';
/**
 * fai file upload v2
 * function: fileUpload2
 */
const _fileUpload2Old = function (buttonId, options, callback) {
    logDog(200188, 1);
    if (buttonId) {
        $(buttonId)
            .unbind('click')
            .bind('click', function () {
                popupWindowVersionTwo.createPopupWindow(fileUploadExtend(options, callback));
            });
    } else {
        popupWindowVersionTwo.createPopupWindow(fileUploadExtend(options, callback));
    }
};

const _fileUpload2New = function (buttonId, options, callback) {
    logDog(200188, 1);
    if (buttonId) {
        $(buttonId)
            .unbind('click')
            .bind('click', function () {
                fileUpload(options, callback);
            });
    } else {
        fileUpload(options, callback);
    }

    function fileUpload(options, callback) {
        try {
            doFileupload(options, callback);
        } catch (e) {
            ing('系统出错，请联系管理员。');
            console && console.error(e);
        }
    }

    function doFileupload(options, onSuccess) {
        if ($.isEmptyObject(window.MaterialLibrary)) {
            return;
        }

        // 判断是从logo、横幅、产品详情进来的
        var formCase;
        switch (options.formCase) {
            case 'logo':
                formCase = 1;
                break;
            case 'banner':
                formCase = 2;
                break;
            case 'productDetail':
                formCase = 3;
                break;
            default:
                formCase = 0;
                break; // 默认是0
        }

        // 判断分组
        var groupId;
        switch (options.group) {
            case 'none':
                groupId = -1;
                break;
            case 'banner':
                groupId = 0;
                break;
            case 'background':
                groupId = 1;
                break;
            case 'image':
                groupId = 2;
                break;
            case 'simple':
                groupId = 3;
                break;
            case 'icon':
                groupId = 4;
                break;
            case 'fodder':
                groupId = 5;
                break;
            case 'mult':
                groupId = 6;
                break;
            case 'video':
                groupId = 7;
                break;
            case 'thumbImg':
                groupId = 8;
                break;
            default:
                groupId = 6;
                break; // 默认是6 素材图、插图
        }
        // 自动补全svg类型
        if ([2, 3, 4, 5, 8].indexOf(groupId) > -1) {
            if (options.type && options.type.length > 0 && options.type.indexOf('png') < 0) {
                options.type.push('png');
            }
        }
        if ([2, 3, 4, 5, 8].indexOf(groupId) > -1) {
            if (options.type && options.type.length > 0 && options.type.indexOf('svg') < 0) {
                options.type.push('svg');
            }
        }
        //兼容旧文件素材弹窗
        var tabPaneIndex = typeof options.tabIndex !== 'undefined' ? options.tabIndex : options.openIcon ? 1 : 0;
        if (tabPaneIndex == 2 && !options.openIcon) {
            tabPaneIndex = 0;
        } else if (tabPaneIndex != 0) {
            tabPaneIndex = 1;
        }
        if (options.group == 'none') {
            tabPaneIndex = 0;
        }

        if (options.recommandSize && options.recommandSize !== '' && !options.customDescription) {
            options.customDescription = `只能添加
                ${options.type.join(',')}
                ${
                    options.maxSize
                        ? `，大小不超过
                ${options.maxSize}
                MB`
                        : ``
                }
                ，建议尺寸为：
                ${options.recommandSize}
                像素。`;
        }

        var guidance = options.guidance;
        if (typeof guidance == 'undefined') {
            // 'jpg', 'jpeg', 'bmp', 'png', 'gif' 'svg' 代表是图片类型，显示快图引导的tab
            if (options && options.type) {
                if (
                    options.type.indexOf('jpg') != -1 ||
                    options.type.indexOf('bmp') != -1 ||
                    options.type.indexOf('png') != -1 ||
                    options.type.indexOf('gif') != -1 ||
                    options.type.indexOf('svg') != -1 ||
                    options.type.indexOf('jpeg') != -1
                ) {
                    guidance = true;
                } else {
                    guidance = false;
                }
            } else {
                guidance = false;
            }
        }
        var isImg = false;
        if (options && options.type) {
            if (
                options.type.indexOf('jpg') != -1 ||
                options.type.indexOf('bmp') != -1 ||
                options.type.indexOf('png') != -1 ||
                options.type.indexOf('gif') != -1 ||
                options.type.indexOf('svg') != -1 ||
                options.type.indexOf('jpeg') != -1
            ) {
                isImg = true;
            } else {
                isImg = false;
            }
        } else {
            isImg = false;
        }

        const isFreeVer = [10, 11].includes(window._siteVer);
        if (groupId === 0) {
            groupId = 1;
        }

        const domain = isDevEnv ? REQUEST_BASE_URL : '';
        // 业务逻辑
        context.MaterialLibrary.App.render({
            data: {
                inBatches: isImg ? false : null, //图片不走分片
                groupId,
                formCase,
                guidance,
                title: options.title,
                materialTypes: options.type,
                fileUploadSizeLimit: isFreeVer
                    ? 1
                    : options.maxSize ||
                      (isImg && Fai.top.file_size_limit > 5 ? Fai.top?.SINGLE_IMG_MAX : Fai.top.file_size_limit),
                choosedMaterialIdsLimit: options.maxChoiceList || 1,
                isUseWatermark: getCookieFlag(28) || false,
                tabPaneIndex,
                popUpWinZIndex: options.popUpWinZIndex,
                customDescription: options.customDescription || '',
                isShowUseOriginImg: options.isShowUseOriginImg || false, // 是否展示原图选项
                useOriginImg: options.useOriginImg || false, // 是否选择原图
                // 素材库接口
                materialLibraryUrl: options.materialLibraryUrl || `${domain}/ajax/materialLibrary_h.jsp`,
                // 图片接口
                siteImageUrl: options.siteImageUrl || `${domain}/ajax/siteImage_h.jsp`,
                // 文件夹接口
                siteImageGroupUrl: options.siteImageGroupUrl || `${domain}/ajax/siteImageGroup_h.jsp`,
                // 上传图片接口
                advanceUploadUrl: options.advanceUploadUrl || `${domain}/ajax/advanceUpload.jsp?isMobi=true`,
                // 搜索图片素材的接口
                siteSearchImageUrl: options.siteSearchImageUrl || `${domain}/ajax/materialLibrary_h.jsp`,
            },
            token: context._token,
            methods: {
                onConfirm(data) {
                    if (data.data && data.data.length > 0) {
                        data.data.forEach(function (item) {
                            if (typeof item.filePath != 'undefined' && item.filePath != '') {
                                $.ajax({
                                    async: false,
                                    type: 'post',
                                    url: `${domain}/ajax/mobi_h.jsp?cmd=wafNotCk_addVideoFirstImg`,
                                    data: 'url=' + item.filePath,
                                    success(result) {
                                        var resData = jQuery.parseJSON(result);
                                        if (resData.success) {
                                            item.videoFirstImg = resData.videoImgUrl;
                                        }
                                    },
                                });
                            }
                            if (typeof item.categoryId != 'undefined') {
                                logDog(200748, item.categoryId + 1);
                            }

                            if (item.isFodder) {
                                var logBssFodderData = {};
                                logBssFodderData.firstSort = item.categoryId;
                                logBssFodderData.secondSort = item.topicIds[0];
                                logBssFodderData.fodderId = item.fodderId;
                                logBssFodderData.logType = 2; //1为曝光，2为使用，3为搜索

                                logBssFodder(logBssFodderData);
                            }
                        });
                    }
                    onSuccess(JSON.stringify(data));
                },
                onClose() {},
                onCancel() {},
                onSettingWatermark() {
                    popupWindowVersionTwo.createPopupWindow({
                        title: '设置水印效果',
                        frameSrcUrl:
                            (context._siteDomain
                                ? context._siteDomain
                                : context._global
                                ? context._global.siteDomain
                                : '') +
                            '/manage/watermarkEdit.jsp?status=1&ram=' +
                            Math.random(),
                        width: 528,
                        height: 710,
                        version: 2,
                        popUpWinZIndex: 10001,
                    });
                },
                onSettingIsUseWatermark(data) {
                    if (data.isUseWatermark) {
                        setCookieFlag(28, true);
                    } else {
                        setCookieFlag(28, false);
                    }
                },
                onPictureMoving() {
                    popupWindowVersionTwo.createPopupWindow({
                        title: '图片搬家',
                        frameSrcUrl:
                            (context._siteDomain
                                ? context._siteDomain
                                : context._global
                                ? context._global.siteDomain
                                : '') +
                            '/manage/imgSpiderEdit.jsp?ram=' +
                            Math.random() +
                            '&fileGroupId=' +
                            0,
                        width: 680,
                        height: 620,
                        version: 2,
                        closeFunc: context.MaterialLibrary.App.updateFilesFirst,
                        popUpWinZIndex: 10001,
                    });
                    logDog(200468, 3);
                },
                onLogBssFodder(e) {
                    logBssFodder(e);
                },
                message(e) {
                    ing(e.message, true);
                },
                // 使用控件入口进行log操作
                onLogDog(e) {
                    logDog(e.id, e.src);
                },
                dispatchCategoryChange(data) {
                    if (data.categoryId > -1) {
                        logDog(200748, data.categoryId + 8);
                    }
                },
            },
        });
    }
};

export const fileUpload2 = function (buttonId, options, callback) {
    if (context.MaterialLibrary && !options.useOld) {
        _fileUpload2New(buttonId, options, callback);
    } else {
        _fileUpload2Old(buttonId, options, callback);
    }
};

const fileUploadExtend = function (options, callback) {
    var settings = {
        title: '添加文件',
        type: [],
        wxMateril: false, //是否是微信图文
        siteDomain: '', //site域名
        maxSize: 1, //单位 MB
        maxUploadList: 1, //单位 个 最大单次文件上传数
        maxChoiceList: 1, //单位 个 文件选择列表最大返回数
        imgMode: 2, //图片缩放模式
        imgMaxWidth: 2048, //单位 px
        imgMaxHeight: 2048, //单位 px
        netFileMode: false, //使用添加网络文件模式 @return 图片地址及对应的md5唯一ID 注意callBack的处理,跟其他两个tab返回的参不一样
        recommandSize: '', //添加修改图片提升的推荐尺寸（没有传不展示）
        useOldIcon: false, //是否使用旧图标库
        oldIcon: {
            //之前选择的图标，可以是一个回调函数，用于在点击时得到正确的oldIcon，回调函数用于传入了buttonId绑定事件的情况，直接返回oldIcon对象即可
            //filePath,charContent,content任传一个，用于搜索，如果搜索失败则不会进行选择
            filePath: '', //之前选择图标的filePath(对于字体图标来说filePath与className相同)
            color: '', //之前选择图标的color
            charContent: '', //字符型的图标
            content: '', //content
        },
    };

    if (options) {
        $.extend(settings, options);
        if (options.oldIcon && typeof options.oldIcon === 'function') {
            settings.oldIcon = options.oldIcon() || settings.oldIcon;
        }
    }
    //2015-04-15 暂时处理 只要是上传图片都不能超多5M（还没断点续传的原因）
    if (settings.title === '添加图片' && settings.maxSize > 5) {
        settings.maxSize = 5;
    }

    // 拿免费版，始终设成1Mb
    if (context._ver != 'undefined' && (context._ver == 10 || context._ver == 11)) {
        settings.maxSize = 1;
    }
    var tmpNewTitle = settings.title;
    if (settings.type.length > 0) {
        // 免费版
        if (context._ver != 'undefined' && context._ver == 10) {
            tmpNewTitle +=
                "<span style='font-size:12px; color:#666;'> (&nbsp;只能添加" +
                settings.type.join(',') +
                ', 免费版大小不超过' +
                settings.maxSize +
                'MB&nbsp;';
            if (settings.recommandSize != '') {
                tmpNewTitle += '，建议尺寸为：' + settings.recommandSize + '像素&nbsp;';
            }
            tmpNewTitle += ')</span>';
        } else {
            tmpNewTitle +=
                "<span style='font-size:12px; color:#666;'> (&nbsp;只能添加" +
                settings.type.join(',') +
                ', 大小不超过' +
                settings.maxSize +
                'MB&nbsp;';
            if (settings.recommandSize != '') {
                tmpNewTitle += '，建议尺寸为：' + settings.recommandSize + '像素&nbsp;';
            }
            tmpNewTitle += ')</span>';
        }
    } else {
        tmpNewTitle +=
            "<span style='font-size:12px; color:#666;'> (&nbsp;上传大小不超过" + settings.maxSize + 'MB&nbsp;';
        if (settings.recommandSize != '') {
            tmpNewTitle += '，建议尺寸为：' + settings.recommandSize + '像素&nbsp;';
        }
        tmpNewTitle += ')</span>';
    }

    var frameUrl =
        context._siteDomain +
        '/manage/fileUploadV2.jsp?settings=' +
        encodeUrl($.toJSON(settings)) +
        '&ram=' +
        Math.random() +
        '&crossiframe=' +
        encodeUrl(getSiteMultiLanguageUrl()) +
        '&wxMateril=' +
        settings.wxMateril;

    var tmpPopupWindowOptions = {
        title: tmpNewTitle,
        frameSrcUrl: frameUrl,
        width: 950,
        height: 690,
        saveBeforePopup: false,
        callArgs: 'add',
        closeFunc: callback,
        popUpWinClass: 'fileUploadV2',
    };

    return tmpPopupWindowOptions;
};
