import { VideoResourceHandler, AudioResourceHandler } from '@jz/biz-shared';
import { mobiIng } from './fai';

let _videoResourcesHandler = null;
let _audioResourcesHandler = null;

function isNodeEnv() {
    return typeof document === 'undefined';
}

let _tipsShowed = false;
function tips() {
    if (_tipsShowed === true) return;
    let tips = '';
    if (window._manageMode) {
        if (window._oem) {
            tips = '您的音视频播放量大，页面加载缓慢，请联系网站管理员';
        } else {
            tips = `您的音视频播放量大，页面加载缓慢，<a class='tips-link' href="//${window.Hosts.jzHome}/blog/1247639" target="_blank">详情可了解>></a>`;
        }
        Fai.ing(tips);
    } else {
        tips = '网站音视频加载缓慢，请联系网站管理员';
        mobiIng(tips);
    }
    _tipsShowed = true;
}

export function initVideoResourceHandler() {
    if (_videoResourcesHandler !== null) return _videoResourcesHandler;
    const el = isNodeEnv() ? {} : document.getElementsByTagName('body')[0];
    _videoResourcesHandler = new VideoResourceHandler(el, {
        timeout: 10000,
        timeExceed() {
            tips();
        },
    });
    _videoResourcesHandler.delayLoad();
    return _videoResourcesHandler;
}

export function initAudioResourceHandler() {
    if (_audioResourcesHandler !== null) return _audioResourcesHandler;
    const el = isNodeEnv() ? {} : document.getElementsByTagName('body')[0];
    _audioResourcesHandler = new AudioResourceHandler(el, {
        timeout: 10000,
        timeExceed() {
            tips();
        },
    });
    _audioResourcesHandler.delayLoad();
    return _audioResourcesHandler;
}

export function getVideoResourceHandler() {
    return initVideoResourceHandler();
}

export function getAudioResourceHandler() {
    return initAudioResourceHandler();
}
