<template>
    <div class="J_navHorizon f-navHorizon" :class="[navStyleClass]">
        <div class="firstGroup">
            <div class="firstGroupList">
                <div
                    v-for="firstGroup in firstGroupList"
                    :key="firstGroup.id"
                    class="firstGroupItem"
                    :class="{ checkedItem: firstGroup.id === selectGroup }"
                    :data-id="firstGroup.id"
                >
                    <div
                        class="firstGroupName"
                        :class="{
                            'f-fontColor': firstGroup.id === selectGroup,
                        }"
                    >
                        {{ firstGroup.name }}
                        <div
                            class="f-bgColor J_navSelect"
                            :class="{
                                'fk-navSelect': firstGroup.id === selectGroup,
                            }"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProductGroupDetailHorizonalNav',
    props: {
        firstGroupList: {
            type: Array,
            default: () => [],
        },
        selectGroup: {
            type: Number,
            default: -1,
        },
    },
};
</script>

<style></style>
