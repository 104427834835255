import { getPrintBannerInfo } from '@/api/banner';

/**
 * =================================================
 *
 * Constants
 *
 * =================================================
 */

const LOAD_BANNER_INFO = 'LoadBannerInfo';

export { LOAD_BANNER_INFO };
/**
 * =================================================
 *
 * State
 *
 * =================================================
 */
const state = () => ({
    moduleData: null,
});

/**
 * =================================================
 *
 * actions
 *
 * =================================================
 */
const actions = {
    loadBannerInfo({ commit }, colId) {
        return getPrintBannerInfo(colId)
            .then((response) => {
                commit(LOAD_BANNER_INFO, {
                    moduleData: response.data.data,
                });
            })
            .catch((value) => {
                console.log(value);
            });
    },
};

/**
 * =================================================
 *
 * mutations
 *
 * =================================================
 */
const mutations = {
    [LOAD_BANNER_INFO](state, payload) {
        const { moduleData } = payload;
        state.moduleData = moduleData;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
