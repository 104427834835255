/**
 * @fileoverview
 * 百度商桥
 */

import { logDog } from '@/shared/log';

var _tryBridgeOpenCheck = false;

function hasOpenFaiscoBaiduBridge() {
    //接入我们的百度商桥才隐藏默认的图标
    if (!jm('ins#newBridge').length) {
        if (!_tryBridgeOpenCheck) {
            setTimeout(function () {
                hasOpenFaiscoBaiduBridge();
            }, 3000);
            _tryBridgeOpenCheck = true;
        }
        return;
    }
    if ((Fai.top._openThemeV3 && Fai.top._colInfo.id == 20) || (!Fai.top._openThemeV3 && Fai.top._mallService == 2)) {
        //商城主题
        for (var i in Fai.top._serviceList) {
            if (Fai.top._serviceList[i].serviceType == 2) {
                if (Fai.top._serviceList[i].showType == true) {
                    if ('_other' in Fai.top && !Fai.top._other.hc.h && Fai.top._hasHmBaidu) {
                        jm('ins#newBridge').show();
                    } else {
                        jm('ins#newBridge').hide();
                    }
                    break;
                } else {
                    if (jm("a[href*='Mobi.showBaiduBridge']").length < 1) {
                        jm('ins#newBridge').show();
                        break;
                    }
                }
            } else {
                if (jm("a[href*='Mobi.showBaiduBridge']").length < 1) {
                    jm('ins#newBridge').show();
                }
            }
        }
    } else {
        //非商城主题
        if (jm("a[href*='Mobi.showBaiduBridge']").length < 1) {
            jm('ins#newBridge').show();
        } else {
            jm('ins#newBridge').hide();
        }
    }
    //百度商桥统计
    jm('#newBridge #nb_icon_wrap')
        .off('click.log')
        .on('click.log', function () {
            logDog(200171, 5);
        });
}

export { hasOpenFaiscoBaiduBridge };
