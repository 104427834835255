var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "largeImageNewsStyle newsLine J_picNum",
      class: _vm.lineClass,
      attrs: {
        id: _vm.newsLineId,
        topClassname: _vm.topClassName,
        topSwitch: _vm.topSwitch,
        newsId: _vm.id,
        newsName: _vm.title,
        picnum: "6",
      },
    },
    [
      _c("div", { staticClass: "lineBody", attrs: { id: _vm.lineBodyId } }, [
        _c("div", { staticClass: "mixNewsStyleImgBox6" }, [
          _c(
            "a",
            {
              staticClass: "f_mixNewsStyleImgBox6_link",
              attrs: {
                hidefocus: "true",
                href: _vm.href,
                target: _vm.target,
                onclick: _vm.onclickStr,
              },
            },
            [
              !_vm.pictureId
                ? _c("div", {
                    staticClass: "newsTitlePic icon-noFigureB",
                    attrs: { id: "newsTitlePic", alt: _vm.alt },
                  })
                : _c("div", {
                    staticClass: "newsTitlePic",
                    style: _vm.newsTitlePicStyle,
                    attrs: { id: "newsTitlePic", alt: _vm.alt },
                  }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mixNewsStyleTitleContainer6" },
                [
                  _c("div", { staticClass: "mixNewsStyleTitlePanel" }, [
                    !_vm.hideType && _vm.groupName !== ""
                      ? _c("div", { staticClass: "newsSortPanel" }, [
                          _c("div", { staticClass: "newsSort" }, [
                            _vm._v(_vm._s(_vm.groupName)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class: [
                          _vm.showLine
                            ? "mixNewsStyleTitle1"
                            : "mixNewsStyleTitle2",
                        ],
                      },
                      [
                        _c("div", { class: _vm.topFlagClass }),
                        _vm._v(
                          _vm._s(_vm.title) + "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "separatorLine" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "mixNewsStyleSummary" }, [
                      _vm._v(_vm._s(_vm.summary)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.showNewsElementsRequire
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "newsElementsPanel g_newsElementsPanel J_newsElementsPanel",
                          on: { click: _vm.gotoHref },
                        },
                        [
                          _vm.showCommentRequire
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "ne_newsComment faisco-icons-comment",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "newsCommentCount" },
                                    [_vm._v(_vm._s(_vm.totalNewsCommentCount))]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCommentRequire && _vm.showAuthorRequire
                            ? _c("div", {
                                staticClass:
                                  "ne_separatorLine g_ne_separatorLine",
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showAuthorRequire
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "ne_newsAuthor faisco-icons-author",
                                  style: _vm.newsAuthorStyle,
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "newsAuthorName" },
                                    [_vm._v(_vm._s(_vm.newsAuthor))]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showDateRequire
                            ? _c("div", { staticClass: "ne_newsTime" }, [
                                _vm._v(_vm._s(_vm.formattedNewsDate)),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : [
                        _vm.showCommentRequire
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "ne_newsComment faisco-icons-comment",
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "newsCommentCount" },
                                  [_vm._v(_vm._s(_vm.totalNewsCommentCount))]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showAuthorRequire
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "ne_newsAuthor faisco-icons-author",
                                style: _vm.newsAuthorStyle,
                              },
                              [
                                _c("span", { staticClass: "newsAuthorName" }, [
                                  _vm._v(_vm._s(_vm.newsAuthor)),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showDateRequire
                          ? _c("div", { staticClass: "ne_newsTime" }, [
                              _vm._v(_vm._s(_vm.formattedNewsDate)),
                            ])
                          : _vm._e(),
                      ],
                ],
                2
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }