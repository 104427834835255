<script lang="jsx">
import ImgGroupItem from './img-group-item.vue';
import { GROUP_STYLE } from '../constants';

export default {
    name: 'ProductGroupDetailPanel',
    components: {
        ImgGroupItem,
    },
    props: {
        groupStyle: {
            type: Number,
            default: -1,
            required: true,
        },
        firstGroupList: {
            type: Array,
            default: () => [],
            required: true,
        },
        prUrl: {
            type: String,
            default: '',
            required: true,
        },
        topLevel: {
            type: Number,
            default: -1,
            required: true,
        },
        horizonal: {
            type: Boolean,
            default: false,
        },
        hasAuth: {
            type: Boolean,
            default: false,
        },
        resRoot: {
            type: String,
            default: '',
        },
        secondGroupMap: {
            type: Object,
            default: () => ({}),
        },
        selectGroup: {
            type: Number,
            default: -1,
        },
        panelOptions: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            manageMode: VITE_APP_MODE !== 'visitor',
        };
    },
    computed: {
        sedGroundStyle() {
            return this.isImageStyle ? {} : { width: '98%', paddingLeft: '2%' };
        },
        isImageStyle() {
            return this.groupStyle !== GROUP_STYLE.TEXT;
        },
        hasAuthToShowAddBtn() {
            return VITE_APP_MODE !== 'visitor' && this.hasAuth;
        },
    },
    methods: {
        addBtnView() {
            if (this.hasAuthToShowAddBtn) {
                return <div class="fk-addGroupTip">右上角“管理分类”可添加分类</div>;
            } else {
                return '';
            }
        },
        oneLevelPanel() {
            const addBtnView = this.addBtnView();

            const firstGroupList = this.firstGroupList.map((firstGroup) => {
                const href = this.getGroupUrl(firstGroup.id);
                return (
                    <li class="f-firstGroupItem" data-id={firstGroup.id}>
                        <a href={href}>
                            <span class="f-name">{firstGroup.name}</span>
                        </a>
                    </li>
                );
            });
            const groupStyle2 = (
                <div class="f-oneLevelGroudVertical">
                    {firstGroupList.length <= 0 ? addBtnView : <ul>{firstGroupList}</ul>}
                </div>
            );
            const imgGroupItems = this.firstGroupList.map((firstGroup) => {
                return (
                    <img-group-item
                        key={firstGroup.id}
                        group-item={firstGroup}
                        group-style={this.groupStyle}
                        panel-options={this.panelOptions}
                        resRoot={this.resRoot}
                    />
                );
            });
            const groupStyle3 = (
                <div class="f-oneLevelGroudHorizon">{imgGroupItems.length <= 0 ? addBtnView : imgGroupItems}</div>
            );

            let panel = this.groupStype === 2 ? groupStyle2 : groupStyle3;
            return panel;
        },
        level2() {
            const addBtnView = this.addBtnView();

            let list = [];
            for (let parentId in this.secondGroupMap) {
                parentId = parseInt(parentId, 10);
                let collection = this.secondGroupMap[parentId];
                let className = `secondGroupPanel ${parentId === this.selectGroup ? 'checkedPanel' : ''}`;
                const imgGroupItems = collection.map((groupItem) => {
                    return (
                        <img-group-item
                            group-item={groupItem}
                            group-style={this.groupStyle}
                            panel-options={this.panelOptions}
                            res-root={this.resRoot}
                        />
                    );
                });

                list.push(
                    <div id={'sed_' + parentId} class={className}>
                        <div class="secondGroupItem f-firstItem">
                            <div class="f-groupImgWrap J_sedGroundFlag">
                                {imgGroupItems.length <= 0 ? addBtnView : imgGroupItems}
                            </div>
                        </div>
                    </div>
                );
            }
            return list;
        },
        level3() {
            const addBtnView = this.addBtnView();
            let list = [];
            for (let parentId in this.secondGroupMap) {
                parentId = parseInt(parentId, 10);
                let collection = this.secondGroupMap[parentId];
                let className = `secondGroupPanel ${parentId === this.selectGroup ? 'checkedPanel' : ''}`;

                let thridList = [];
                for (let i = 0; i < collection.length; i++) {
                    let secondGroup = collection[i];
                    let className = `
                        secondGroupItem
                        ${i === 0 ? 'f-firstItem' : ''}
                        ${secondGroup.thirdGroupList && secondGroup.thirdGroupList.length >= 1 ? 'J_hasThreeGroup' : ''}
                    `;
                    const imgGroupItems = secondGroup.thirdGroupList.map((groupItem) => {
                        return (
                            <img-group-item
                                group-item={groupItem}
                                group-style={this.groupStyle}
                                panel-options={this.panelOptions}
                                res-root={this.resRoot}
                            />
                        );
                    });
                    thridList.push(
                        <div id={'sed_' + parentId} class={className}>
                            <div class="J_sedGroundName f-sedGroundName">{secondGroup.name}</div>
                            <div class="f-groupImgWrap J_sedGroundFlag" style={this.sedGroundStyle}>
                                {imgGroupItems.length <= 0 ? addBtnView : imgGroupItems}
                            </div>
                        </div>
                    );
                }

                list.push(
                    <div id={'sed_' + parentId} class={className}>
                        {thridList.length <= 0 ? addBtnView : thridList}
                    </div>
                );
            }
            return list;
        },
        restPanel() {
            const className = this.horizonal ? 'unFirstGroup f-unFirstGroupHorizon' : 'unFirstGroup';
            const items = this.topLevel === 2 ? this.level2() : this.level3();
            return (
                <div class={className}>
                    <div class="hideScollBar">{items}</div>
                </div>
            );
        },
        getGroupUrl() {
            return `${this.prUrl}?groupId=${this.groupId}`;
        },
    },
    render() {
        const oneLevelPanel = this.oneLevelPanel();
        const restPanel = this.restPanel();
        const result = this.topLevel === 1 ? oneLevelPanel : restPanel;
        return result;
    },
};
</script>

<style></style>
