import { initImgNoSelect } from '@/shared/util';

export const registerBanCopyDirective = (Vue) => {
    // 移动端禁止复制图片
    Vue.directive('ban-copy', {
        inserted(el) {
            if (!Fai.top.banCopyTextImg) {
                return;
            }
            initImgNoSelect(el);
        },
        update(el) {
            if (!Fai.top.banCopyTextImg) {
                return;
            }
            initImgNoSelect(el);
        },
    });
};
