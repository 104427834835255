var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "ad_component_loverLeft",
      on: {
        mouseenter: function ($event) {
          $event.stopPropagation()
          return _vm.mouseenterAction.apply(null, arguments)
        },
        mouseleave: _vm.mouseleaveAction,
      },
    },
    [
      _c("div", { staticClass: "ad_loverLeft_inner" }, [
        _c(
          "div",
          { staticClass: "ad_loverLeft_header", on: { click: _vm.closeAd } },
          [
            _vm.closeImg
              ? _c("img", {
                  staticClass: "ad_loverLeft_header__close",
                  attrs: { src: _vm.config.closeImg },
                })
              : _c("a", {
                  staticClass: "ad_loverLeft_header__close",
                  attrs: { href: "javascript:void(0)" },
                }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ad_loverLeft_body" },
          [
            _c(
              "a",
              {
                staticClass: "ad_loverLeft_link",
                attrs: { href: _vm.siteUpdateUrl, target: "_blank" },
                on: { click: _vm.clickAd },
              },
              [_c("img", { attrs: { src: _vm.adImg, alt: _vm.adAlt } })]
            ),
            _vm._v(" "),
            _vm._t("default"),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }