import { logJZFdp } from '@/api/logDog';
import { mobiIng } from '@/shared/fai';
import { logErr } from '@/shared/log';
import { changeHref } from '@/features/router/universal';
import MDialog from '@/components/dialog/m-dialog.vue';
import { checkMemberSameLogin } from '@/api/member';
import { preprocessUrl } from '@/shared/url';

/**
 * [handleFdpOfMemberBrowse 处理mobi会员分组fdp]
 * @param  {Object} options [description]
 * @return {[type]}         [description]
 */
export const handleFdpOfMemberBrowse = function (options = {}) {
    const fdpParams = getFdpParamsOfMemberBrowse(options);
    logJZFdp('jz_member_privileges_browsing', fdpParams);
};

/**
 * [getFdpParamsOfMemberBrowse 获取mobi会员分组fdp]
 * @param  {[Object]} options [description]
 * @return {[Object]}         [description]
 */
export const getFdpParamsOfMemberBrowse = function (options) {
    const { memberScanType, memberLevelId, jz_version, jz_content_terminal, jz_free_int2, onlyMember } = options;
    let fdpParams = {
        jz_version,
        jz_content_terminal,
        jz_free_int2,
    };
    switch (memberScanType) {
        case 0:
            fdpParams['jz_free_int1'] = 1;
            break;
        case 1:
            if (memberLevelId.indexOf('only') == -1) {
                fdpParams['jz_free_int1'] = 2;
                fdpParams['jz_free_bool1'] = false;
            } else {
                fdpParams['jz_free_int1'] = 2;
                fdpParams['jz_free_bool1'] = true;
            }
            break;
        case 2:
            fdpParams['jz_free_int1'] = 3;
            break;
    }

    if (onlyMember) {
        fdpParams['jz_free_int1'] = 4;
    }

    return fdpParams;
};

export const memberLogin1 = function (errno) {
    changeHref(
        preprocessUrl({ path: '', oldPath: '' }) +
            'login.jsp?returnUrl=' +
            jm.encodeUrl(jm.getUrlRoot(Fai.top.location.href)) +
            '&errno=' +
            errno
    );
};

export const showMemberLoginMsg = async function (errno, logOther) {
    var msg = '';
    if (errno == 1) {
        msg = LS.memberInputAcct;
        document.getElementById('user').focus();
    } else if (errno == 2) {
        msg = LS.memberInputPwd;
        document.getElementById('password').focus();
    } else if (errno == 3) {
        msg = LS.memberInputCaptcha;
    } else if (errno == 4) {
        msg = LS.memberDialogPlzEnterMobile;
    } else if (errno == 5) {
        msg = LS.memberDialogEnterCode;
    } else if (errno == 11) {
        msg = LS.memberLoginFirst;
    } else if (errno == 12) {
        msg = LS.memberLoginSignup;
    } else if (errno == 13) {
        msg = LS.memberLoginToView;
    } else if (errno == 14) {
        msg = LS.memberLoginNoPermission;
    } else if (errno == -3) {
        msg = LS.memberPwdError;
    } else if (errno == -301) {
        msg = LS.memberCaptchError;
    } else if (errno == -302) {
        msg = LS.memberPwdError;
    } else if (errno == -303) {
        msg = LS.memberNoAuth;
    } else if (errno == -305) {
        msg = LS.memberPwdError;
    } else if (errno == -307) {
        msg = LS.loginUseAcct;
    } else if (errno == -308) {
        msg = LS.memberDialogGetCodeFirst;
    } else if (errno == -2003) {
        msg = '该账号已多次登录失败，请更换账号重试';
    } else if (errno == -2004) {
        msg = '账号/密码有误，请重新输入';
    } else if (errno == -3001) {
        msg = LS.attachLoginTips;
    } else {
        var errStr = 'mobi login err;rt=' + errno + ';';
        if (logOther && logOther.length > 0) {
            errStr += 'other=' + logOther + ';';
        }
        logErr(errStr);
        msg = LS.memberLoginError;
    }
    var msgCtrl = jm('.loginErrorLine');

    if (errno == 13) {
        const { success, showTips } = await checkMemberSameLogin();
        if (success === true && showTips === true) {
            showSameLoginTips();
            return;
        }
    }

    msgCtrl.show();
    mobiIng(msg);
    setTimeout(function () {
        msgCtrl.hide();
    }, 3000);
};

export const showSameLoginTips = function () {
    // 弹出提示同时登录
    const component = new Vue(MDialog);
    component.$slots.content = '您的账号已在另一台设备登录，如需继续浏览请重新登录';
    const dialogEl = component.$mount().$el;
    document.getElementById('preview').prepend(dialogEl);
    return;
};
