var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.floatZoneContanier && _vm.isRender && !_vm.isEdit
    ? _c(
        "div",
        {
          staticClass: "floatZoneContainer",
          attrs: { id: "floatZoneContainer" },
        },
        [
          _c(
            "module-frame",
            { attrs: { "module-id": _vm.moduleId } },
            _vm._l(_vm.renderModules, function (module) {
              return _vm.getIsShowModule(module)
                ? _c(_vm.componentsTable[module.style], {
                    key: module.id,
                    tag: "component",
                    attrs: { "module-id": module.id },
                  })
                : _vm._e()
            }),
            1
          ),
        ],
        1
      )
    : _vm.floatZoneContanier && _vm.isRender
    ? _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
        _c(
          "div",
          { staticClass: "float_zone_wrap" },
          _vm._l(_vm.renderModules, function (module) {
            return _c(_vm.componentsTable[module.style], {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.getIsShowModule(module),
                  expression: "getIsShowModule(module)",
                },
              ],
              key: module.id,
              tag: "component",
              attrs: { "module-id": module.id },
            })
          }),
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }