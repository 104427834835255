import request from './request';

export const getWebsiteAdInfo = () => {
    let url = VITE_APP_MODE !== 'visitor' ? `/api/manage/` : `/api/guest/`;

    url += 'ad/getWebsiteAdInfo';

    return request.get(url, {}).then(({ data }) => {
        return data;
    });
};
