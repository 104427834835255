import { ing, mobiIng } from '@/shared/fai';

import { getColModuleIdList } from '@/api/linkSetting';
import { closeNavV3 } from '@/shared/nav';
import { getMainNavLayoutId } from '@/shared/nav';
import { getPreviewObject } from '@/shared/getPreviewObject';
import { changeHref } from '@/features/router/universal';

//类似锚点链接 跳转到页面模块的位置
export const jumpToModulePosition = function (jumpToModuleOption) {
    var moduleId = jumpToModuleOption.jumpModuleId;
    var url = jumpToModuleOption.colUrl;
    var colId = jumpToModuleOption.colId;

    var module = jm('#webModuleContainer #module' + moduleId);

    var hasModule = false;

    // 当前页面找不到模块
    if (module.length < 1) {
        // 如果没有该模块 并且已经是在对应的页面下，那么模块被删除或隐藏了
        let htmlUrl = '/h-col-' + Fai.top._pageId + '.html';
        if (Fai.top._openStaticUrl) {
            htmlUrl = `/sys-col-${Fai.top._pageId}.html`;
        }
        if (url == '/col.jsp?id=' + Fai.top._pageId || url == htmlUrl) {
            if (VITE_APP_MODE !== 'visitor') {
                ing('该模块已被删除或隐藏', true);
            } else {
                mobiIng(LS.linkPositionNotFound, 3);
            }
            return;
        } else {
            // 如果不是当前页面，先去获取要跳转的页面里面有没有模块，有的话就跳转，没有的话跳转页面就不跳转页面
            if (colId != -1) {
                getColModuleIdList(colId, Fai.top._extId)
                    .then((result) => {
                        for (var i = 0; i < result.moduleListId.length; i++) {
                            // 如果页面存在模块
                            if (result.moduleListId[i] == parseInt(moduleId)) {
                                hasModule = true;
                                break;
                            }
                        }
                        // 如果要跳转的页面存在模块，则跳转页面
                        if (hasModule) {
                            if (VITE_APP_MODE == 'visitor') {
                                // 跳转到对应的页面并且加上锚点
                                document.location.href = url + '#fai_' + moduleId;
                            } else {
                                // 跳转到指定页面，跳转到该模块位置
                                localStorage.setItem('mAnchorId', moduleId);
                                changeHref(url);
                            }
                        } else {
                            // 如果要跳转的页面不存在模块，直接在当前页面提示模块不存在
                            if (VITE_APP_MODE !== 'visitor') {
                                ing('该模块已被删除或隐藏', true);
                            } else {
                                mobiIng(LS.linkPositionNotFound, 3);
                            }
                            return;
                        }
                    })
                    .catch(() => {
                        ing('加载失败', true);
                    });
            }
        }
        return;
    } else if (
        (module.length >= 1 && module.parents('.formStyle50').length > 0) ||
        (module.length >= 1 && module.parents('.formStyle51').length > 0) ||
        (module.length >= 1 && module.parents('.formStyle58').length > 0)
    ) {
        if (VITE_APP_MODE !== 'visitor') {
            ing('此链接已失效', true);
        } else {
            mobiIng(LS.linkFailure, 2);
        }
        return;
    }

    jumpAnimation(moduleId);
};

export const jumpAnimation = function (moduleId) {
    var scrollTop;

    // 更改为 offsetTop 后，是相对于父元素的，获取 webContainerBox 的 offsetTop，scrollTop = parentOffsetTop + childOffsetTop
    let webContainerEl = document.getElementById('webContainerBox');
    let webContainerOffsetTop = webContainerEl.offsetTop;

    let $fixedBox = jm('#web_header_fixed_box');
    if (!$fixedBox.length) {
        $fixedBox = jm('#webHeaderBox');
    }
    let fixedBoxHeight = 0;
    // 顶部是固定的需要加上顶部的高度
    if ($fixedBox.css('position') == 'fixed') {
        fixedBoxHeight = isNaN(Number($fixedBox.height())) ? 0 : $fixedBox.height();
    }

    const getModuleScrollTop = () => {
        let moduleNodeEl = document.getElementById(`module${moduleId}`);
        let moduleNodeOffsetTop = moduleNodeEl.offsetTop;
        let _scrollTop = 0;
        // 更改为 offsetTop 后，是相对于父元素的，获取 webContainerBox 的 offsetTop，scrollTop = parentOffsetTop + childOffsetTop
        _scrollTop = moduleNodeOffsetTop + webContainerOffsetTop;
        if (moduleNodeOffsetTop > 0) {
            // && window._openThemeV3
            _scrollTop = _scrollTop - parseInt(fixedBoxHeight);
        }
        return _scrollTop;
    };

    var speed;
    var timer;
    var scrollTopTmp = getModuleScrollTop();
    var sum = 0;
    timer = setInterval(function () {
        scrollTopTmp = getModuleScrollTop();
        if (!isNaN(scrollTopTmp)) {
            speed = scrollTopTmp / 20;
            scrollTop = parseInt(sum + speed);
            sum = scrollTop;
            // 这里由原先的body改为g_web
            let webBody = document.getElementById('g_web');
            if (webBody) {
                webBody.scrollTop = scrollTop;
            }
            // document.body.scrollTop = scrollTop;
            // document.documentElement.scrollTop = scrollTop;
            if (scrollTop == 0 || scrollTop >= scrollTopTmp - speed) {
                clearInterval(timer);
            }
        } else {
            clearInterval(timer);
        }
    }, 1000 / 90);

    // 定位到指定模块之后，让导航的面板收起
    if (Fai.top._openThemeV3) {
        var layoutId = parseInt(getMainNavLayoutId());
        closeNavV3(layoutId);
    } else {
        clickJumpremoveAllSwipeMenuClass();
    }
};

// 让导航面板收起

export function clickJumpremoveAllSwipeMenuClass() {
    var layoutId = Fai.top._templateLayoutId;
    var navbarClassName = getPreviewObject('navbar').className;
    if (navbarClassName.indexOf('open') !== -1) {
        jm('#navbar').removeClass('open');
        jm('#navExistOffPanel').removeClass('navPanelOpen');
        jm('#navButton').removeClass('navButtonOpen');
        jumpRemoveClass('moveRight');
        if (layoutId === 2) {
            jm('#webTop').css('height', '');
        }
    }

    function jumpRemoveClass(className) {
        jm('#webHeaderBox').removeClass(className);
        jm('#webBannerBox').removeClass(className);
        jm('#webContainerBox').removeClass(className);
        jm('#customerServiceDiv').removeClass(className);
        jm('#webFooter').removeClass(className);
        jm('#bgm_icon').removeClass(className);
    }
}
