const MODULE_STYLE = {
    SYS_FOOTER: -1, // 底部
    SYS: 1, // 系统
    RICH: 2, // 富文本
    PRODUCT_LIST: 3, // 产品展示
    PRODUCT_DETAIL: 4, // 产品详情
    PRODUCT_RESULT: 5, // 产品筛选结果
    NEWS_LIST: 6, // 文章列表
    NEWS_DETAIL: 7, // 文章详情
    NEWS_RESULT: 8, // 新闻筛选结果
    MAP: 9, // 地图
    SYS_MSG_BOARD: 10, // 留言版
    PHOTO_LIST: 11, // 图片展示
    MEMBER_LOGIN: 12, // 会员登录
    MOBI_FORM: 13, // 在线表单
    MEMBER_SIGNUP: 14, // 会员注册
    MEMBER_PROFILE: 15, // 会员中心
    SCROLL_NOTICE: 16, // 滚动公告
    MALL_CART: 17, // 购物车
    MALL_ORDER_SETTLE: 18, // 订单结算
    MALL_ORDER_LIST: 19, // 订单列表（我的订单）
    MALL_ORDER_DETAIL: 20, // 订单详情
    LINK: 21, // 图文链接
    VOTE: 22, // 在线投票
    TOTAL_SEAECH: 23, // 全站搜索
    TOTAL_SEARCH_RESULT: 24, // 全站搜索结果
    PRODUCT_GROUP: 25, // 产品分类
    PRODUCT_SEARCH: 26, // 产品搜索
    PRODUCT_SEARCH_RESULT: 27, // 产品搜索结果
    NEWS_GROUP: 28, // 文章导航
    MEMBER_INTEGRAL_LIST: 29, // 会员积分
    ONLINE_VIDEO: 30, // 在线视频
    CUBE_NAVIGATION: 31, // 魔方导航
    INSERT_CODE: 32, // 插件代码模块
    FILE_DOWNLOAD: 33, // 文件下载模块
    PHOTO_GROUP: 34, //图册目录
    PRODUCT_NEW_DETAIL: 35, // 新产品详情
    NEWS_NEW_DETAIL: 36, //  新新闻详情
    PHOTO_CAROUSEL: 37, //  图册轮播
    MEMBER_FIND_PWD: 38, //  忘记密码
    SYS_PRODUCT_NEW_GROUP: 39, // 新产品分类
    AD_PICTURE: 40, // 广告图
    MULTI_PHOTO_LIST: 41, //列表多图
    CAROUSEL_MULTI_PHOTO: 42, //轮播多图
    PHOTOGROUP_DETAIL: 43, //图册详情
    COUPON: 44, // 优惠券
    SYS_REFUND_LIST: 45, // 订单退货列表
    SYS_REFUND_DETAIL: 46, // 订单退货详情
    PHOTO_GROUP_NAV: 47, // 图册导航
    SYS_PHOTOGROUP_RESULT: 48, // 图册筛选结果
    NEWS_DETAIL_V3: 49, // 文章详情页 3.0 版本
    HORIZON_TAB: 50, // 横向标签模块
    PACK: 51, // 自由容器
    SIMPLE_TEXT: 52, // 简易文本
    FLOAT_IMG: 53, //浮动图片
    FLOAT_BTN: 54, // 按钮模块
    SYS_BOOKING_SUBMIT: 55, //预约面板
    GROUP_BUY: 56, //拼团模块
    SYS_GROUP_BUY_DETAIL: 57, //拼团详情
    FOLD: 58, //折叠容器
    CUT_PRICE: 59,
    SYS_CUT_PRICE_DETAIL: 60,
    SYS_COUPON_PROMOTION: 61, //优惠券推广
    POPUP_ZONE: 62, // 弹窗容器
    SYS_PROMOTER_WELCOME: 63, // 推广员欢迎页
    ONLINE_MAP: 64, //在线地图
    SYS_PROMOTER_INDEX: 65, // 推广员中心（首页）
    SYS_PROMOTER_ORDER: 66, // 推广订单（推广员）
    SYS_PROMOTER_INVITE: 67, // 我的邀请（推广员）
    SYS_PROMOTER_FLOW: 68, // 收支明细（推广员）
    SYS_PROMOTER_WITHDRAW: 69, // 提现（推广员）
    SYS_PROMOTER_WITHDRAW_MSG: 70, // 提现通知（推广员）
    SYS_PROMOTER_WITHDRAW_MSG_DETAIL: 71, // 提现通知详情（推广员）
    SEC_KILL: 72, // 秒杀模块
    SYS_PROMOTER_CUSTOMERS: 73, //客户列表(推广员)
    CUSTOM_SEARCH: 74, // 信息查询
    SYS_CUSTOM_SEARCH_RESULT: 75, // 信息查询结果
    SYS_MEMBER_DEPOSIT: 76, // 我的余额首页
    SYS_DEPOSIT_TOPUP: 77, // 我的余额充值页
    SYS_DEPOSIT_TOPUP_LIST: 78, // 我的余额充值列表页
    SYS_DEPOSIT_CONSUME_LIST: 79, // 我的余额消费列表页
    SYS_DEPOSIT_INFO: 80, // 充值信息页
    SYS_DEPOSIT_PWD: 81, // 充值密码设置页
    SYS_PROMOTER_PD: 82, // 推广产品页
    INTEGRAL_MALL: 83, // 积分商城模块
    SYS_FORM_POPUP: 84, // 表单弹层
    TURNPLATE: 85, // 抽奖转盘
    WECHAT_PACKET: 86, // 微信红包
    ANSWER_ACT: 87, // 答题活动
    SIGN_IN_ACT: 88, // 签到活动
    EARNEST_SALE: 89, // 定金预售模块
    SYS_MEMBER_SHIP_CARD_LIST: 90, // 定金预售模块
    SYS_MEMBER_SHIP_CARD_DETAIL: 91, // 会员卡详情
    SYS_RICH_PAGE: 92, // 接收并显示富文本信息
    SYS_COMBINED_PURCHASE_DETAIL: 93, // 组合套餐详情
    WX_ADD_FANS: 94, // 微信吸粉模块
    COMBINED_PURCHASE: 95, //组合套餐模块
    VIDEO_SHOPPING: 96, //短视频购物模块
    SYS_VIDEO_SHOPPING_DETAIL: 97, //短视频购物详情
    COUNT_DOWN: 98,
    CALCULATION_QUOTATION: 99, //报价计算模块
    SYS_MERCHANTS_APPLY: 100, //多商户申请
    SYS_MERCHANTS: 101, //多商户模块
    SYS_SUBMERCHANT_DETAIL: 102, //子商户详情页

    FLOAT_ZONE: 103, // 悬浮容器
    SYS_SUBMERCHANT_LOGIN: 104, // 子商户登录(结果)页
    SYS_MEMBER_INFO: 105, // 会员资料

    SYS_PROMOTER_CUS_DETAIL: 106, //推广员客户详情页

    SYS_COMMUNITY_GROUP: 107, // 社区团购模块
    SYS_COMMUNITY_GROUP_BUYER_ACTIV_DETAIL: 108, // 社区团购买家活动详情页
    SYS_COMMUNITY_GROUP_BUYER_ACTIV_LIST: 109, // 社区团购买家活动列表页
    SYS_COMMUNITY_GROUP_GROUPER_LIST: 110, // 社区团购附近团长页
    SYS_COMMUNITY_GROUP_SIGNUP: 111, // 社区团购团长申请页
    SYS_COMMUNITY_GROUP_INDEX: 112, // 社区团购团长入口

    SYS_PROMOTER_ORDER_DETAIL: 113, // 推广订单详情
    SYS_PROMOTER_ORDER_FLOW: 114, // 推广订单详情物流

    SYS_SCAN_PAY: 115, // 当面付
};

const memberModuleStyle = [
    MODULE_STYLE.MEMBER_FIND_PWD,
    MODULE_STYLE.MEMBER_LOGIN,
    MODULE_STYLE.MEMBER_PROFILE,
    MODULE_STYLE.MEMBER_SIGNUP,
    MODULE_STYLE.SYS_MEMBER_INFO,
];

const newsDetailModuleStyle = [MODULE_STYLE.NEWS_DETAIL, MODULE_STYLE.NEWS_DETAIL_V3, MODULE_STYLE.NEWS_NEW_DETAIL];

const productDetailModuleStyle = [MODULE_STYLE.PRODUCT_DETAIL, MODULE_STYLE.PRODUCT_NEW_DETAIL];

const viewAllowDetailModuleStyle = [...newsDetailModuleStyle, ...productDetailModuleStyle];

const RecommendedNewsListModuleId = 70;
const RecommendProductModuleId = 71;

const NewsDetailV3ModuleId = 27;

function isNewsDetailModule(style) {
    return newsDetailModuleStyle.includes(style);
}

function isProductDetailSystemModule(style) {
    return productDetailModuleStyle.includes(style);
}

function hasUeMap(style) {
    return [
        MODULE_STYLE.RICH,
        MODULE_STYLE.NEWS_DETAIL,
        MODULE_STYLE.NEWS_DETAIL_V3,
        MODULE_STYLE.PRODUCT_DETAIL,
        MODULE_STYLE.PRODUCT_NEW_DETAIL,
        MODULE_STYLE.PHOTOGROUP_DETAIL,
    ].includes(style);
}

export {
    MODULE_STYLE,
    memberModuleStyle,
    viewAllowDetailModuleStyle,
    productDetailModuleStyle,
    RecommendedNewsListModuleId,
    RecommendProductModuleId,
    NewsDetailV3ModuleId,
    isNewsDetailModule,
    isProductDetailSystemModule,
    hasUeMap,
};
