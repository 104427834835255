import request from './request';

export function getFloatEffectInfo() {
    return request.get('/api/manage/floatEffect/getFloatEffect', {});
}

export function initFloatZone(options) {
    return request.post('/api/manage/floatEffect/addFloatZoneModule', {
        params: options.params,
    });
}

export function getFloatZone(options) {
    return request.get('/api/manage/floatEffect/getFloatZoneModule', {
        params: options.params,
    });
}

export function saveFloatZone(options) {
    return request.post('/api/manage/floatEffect/setFloatZoneModule', {
        params: options.params,
        data: options.data,
    });
}
