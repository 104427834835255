var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !(_vm.isShowBooking || _vm.isShowPdForm)
    ? _c("span", {
        staticClass:
          "J_fk-mallCart faiscoIconsGwc icon-gwc g_color g_main_color_v3",
        style: _vm.mallCartStyle,
        attrs: { id: _vm.pid },
      })
    : _c("span", { staticClass: "is_cart2" }, [
        _vm.cart2
          ? _c("span", { staticClass: "bookingBtnPlaceholder" })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "g_button proListBookingBtn g_main_bgColor_v3",
            attrs: { id: "proListBookingBtn" + _vm.pid, _pid: _vm.pid },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.productFormBtnClick.apply(null, arguments)
              },
            },
          },
          [_vm._v(_vm._s(_vm.text))]
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }