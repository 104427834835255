import './setPublicPath.js';
import Vue from 'vue';
import Vuex from 'vuex';
import Preview from './preview/index.vue';
import { getStoreConfig } from './store/index.js';
import { registerLogDogApi } from '@/shared/log';
import { resignerJZFunc } from '@/shared/fixSiteFunc';
import { registerMobiVisitorFunction } from '@/features/global-mobi-function/visitor';
import { registerIngApi } from './shared/fai.js';
import { registerAnimateObseverDirective } from './directives/animate-obsever/index.js';
import { registerGlobalDirectives } from './directives/vistor.js';
import emptyDirective from '@/preview/empty-directive';
import { installModal } from '@jz/biz-shared';
// 引入访客态svg
import { registerVisitorAPI } from './preview/contextAPI.js';

import { registerMobileAuthPopupInVue } from './services/index.js';

// 挂载vue到全局，给建站旧组件使用
if (typeof window === 'object') {
    window.Vue = Vue;
    window.Vuex = Vuex;
    Vue.prototype.$modulesEventBus = new Vue(); // eventBus

    emptyDirective.forEach(function (directive) {
        Vue.directive(directive, {});
    });
}
resignerJZFunc();
registerMobiVisitorFunction();

// render
export function createApp() {
    if (typeof window === 'object') {
        installModal(Vue);
    }
    // vuex store
    Vue.use(Vuex);
    const store = new Vuex.Store(getStoreConfig());

    if (typeof window === 'object') window.$store = store;

    registerVisitorAPI(Vue);

    // directive
    registerGlobalDirectives(Vue);
    registerAnimateObseverDirective(Vue);

    registerLogDogApi(Vue);
    registerIngApi(Vue);

    registerMobileAuthPopupInVue(Vue);

    if (typeof window != 'undefined') {
        let urlParam = new URLSearchParams(location.search);
        let isPreview = urlParam.get('_preview');
        if (isPreview) {
            sessionStorage.setItem('_preview', true);
        }
    }

    let app = null;

    app = new Vue({
        store,
        render: (h) => h(Preview),
    });

    return { app, store };
}
