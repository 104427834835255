<template>
    <module-frame :module-id="moduleId">
        <div :id="productGroupDetailPanelId" class="photoGroupDetailPanel detailPanelV3">
            <div :id="productGroupDetailHeaderId" class="photoGroupDetailHeader detailHeaderColor">
                <template v-if="isRegularHeadStyle || manageMode">
                    <span
                        v-if="isShowBackBtn"
                        class="icon-gClose g_round g_iconMiddle photoGroupDetailBack icon-defaultColor"
                    ></span>
                    <template v-if="isShowShare && isShowHome == 0">
                        <a :href="homeLink">
                            <span
                                id="newsDetailIndex"
                                class="icon-share g_iconMiddle g_round photoGroupDetailIndex icon-defaultColor"
                            >
                            </span>
                        </a>
                    </template>
                    <template v-if="!isShowShare && isShowHome == 0">
                        <a :href="homeLink">
                            <span
                                id="newsDetailIndex"
                                style="right: 0.45rem"
                                class="icon-share g_iconMiddle g_round photoGroupDetailIndex icon-defaultColor"
                            ></span>
                        </a>
                    </template>
                </template>
                <template v-if="(isShowShare && isRegularHeadStyle) || (isShowShare && manageMode)">
                    <span
                        id="newsDetailShare"
                        class="icon-share g_iconMiddle g_round photoGroupDetailShare icon-defaultColor"
                    ></span>
                </template>
            </div>
            <div class="photoGroupDetail">
                <div :id="photoGroupPicPanelId" class="photoGroupPicPanel">
                    <div :id="photoGroupPicListId" class="photoGroupPicList">
                        <div v-for="photo in photoList" :key="photo.id" :class="photoGroupPicClass">
                            <div
                                class="photoGroupPicImg photoGroup-img J_img_lazyload"
                                :src-original="photo.picThumbPath"
                                :alt="photo.picThumbName"
                                :datasrc="photo.picThumbPath"
                                :data-picid="photo.picThumbId"
                                :style="{
                                    width: '100%',
                                    height: computedPicHeight,
                                    backgroundImage: `url(${loadingPath})`,
                                }"
                            >
                            </div>
                        </div>
                    </div>
                    <template v-if="photoList.length > 1">
                        <div id="photoListBullet" class="photoListBullet">
                            <ul class="bullets">
                                <li
                                    v-for="(photoItem, index) in photoList"
                                    :key="photoItem.id"
                                    :class="{ on: index === 0 }"
                                >
                                </li>
                            </ul>
                        </div>
                        <div
                            class="J_btn J_preBtn faisco-icons-M000217 f-btn f-preBtn faisco-icons fontIcon faisco-icons-back5"
                        ></div>
                        <div
                            class="J_btn J_nextBtn faisco-icons-M000215 f-btn f-nextBtn faisco-icons fontIcon faisco-icons-forward1"
                        ></div>
                        <div class="f-photoNumber">
                            <span class="J_curPhotoNumber this_page">1</span>
                            <span class="pageLine">/</span>
                            <span class="J_maxNum total_page">{{ photoList.length }}</span>
                        </div>
                    </template>
                </div>
                <div class="photoGroupDetailContent">
                    <div class="title" :class="titleRightShare">
                        <h1 class="one_line_title">
                            {{ photoGroupInfo.name }}
                        </h1>
                        <template v-if="isShowShare && !isRegularHeadStyle">
                            <span
                                id="newsDetailShare"
                                class="icon-share mallPhotoShareIcon g_iconMiddle g_round photoGroupDetailShare icon-defaultColor"
                            ></span>
                        </template>
                    </div>
                    <template v-if="isShowDetail === 0">
                        <div class="separateLine"></div>
                        <div class="photo_detail_title">
                            {{ photoGroupDetailV3Text }}
                        </div>
                        <div v-if="isEmptyPhotoGroupContent" class="no_detail_img_wrap">
                            <div class="no_detail_img">
                                <img :src="noDetailImg" alt="" />
                            </div>
                            <p class="no_detail_text">
                                {{ hasNoDetailText }}
                            </p>
                        </div>
                        <html-render class="photoGroupDesc" :html="photoGroupContent"></html-render>
                    </template>
                </div>
            </div>
        </div>
    </module-frame>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { getLanCode } from '@/shared/fai';
import { bindImgLazyLoad } from '@/shared/imageLazyload';
import { LinkSettingUtil } from '@/shared/linkSetting';
import { scrollFadeInV3, setHrefEventHasReqArgs } from '@/modules/comm';
import { initPhotoGroupSwipe, photoGroupContentInitV3, PhotoGroupDetailSharePanel } from '../utils';

import { initPictureSlidesV4 } from '@/shared/photo/slides';
import { MODULE_ID } from '../constants';

import ModuleFrame from '@/modules/frame/index.vue';

import HtmlRender from '@/components/html-render/index.vue';

export default {
    components: {
        ModuleFrame,
        HtmlRender,
    },
    data() {
        return {
            moduleId: MODULE_ID,
            manageMode: VITE_APP_MODE !== 'visitor',
            shareData: {},
            sharePanelInstance: {},
        };
    },
    computed: {
        ...mapGetters(['getModuleById']),
        ...mapState('app', ['lcid', 'isRegularHeadStyle', 'loadingPath', 'LS', 'resRoot']),
        ...mapState('panelSetting', ['headHideV3', 'independentSetHead', 'independentSetHeadHide']),
        pageLoading() {
            return VITE_APP_MODE !== 'visitor' ? this.$store.state.manage.pageState?.pageLoading : false;
        },
        module() {
            return this.getModuleById(this.moduleId);
        },
        moduleExtInfo() {
            return this.module?.extInfo ?? {};
        },
        photoList() {
            return this.moduleExtInfo?.photoList ?? [];
        },
        photoGroupInfo() {
            return this.moduleExtInfo?.photoGroupInfo ?? {};
        },
        picHeight() {
            return this.moduleExtInfo?.picHeight ?? '';
        },
        picWidth() {
            return this.moduleExtInfo?.picWidth ?? '';
        },
        grapicShareInfo() {
            return this.moduleExtInfo?.grapicShareInfo ?? {};
        },
        slideList() {
            return this.moduleExtInfo?.slideList ?? [];
        },
        photoGroupDetailV3Text() {
            return this.LS.photoGroupDetailV3;
        },
        hasNoDetailText() {
            return this.LS.hasNoDetail;
        },
        productGroupDetailPanelId() {
            return `photoGroupDetailPanel${this.moduleId}`;
        },
        productGroupDetailHeaderId() {
            return `photoGroupDetailHeader${this.moduleId}`;
        },
        isShowBackBtn() {
            return (this.module?.content?.sbb ?? 0) === 0;
        },
        isShowDetail() {
            return this.module?.content?.sd ?? 0;
        },
        isShowShare() {
            return (this.module?.content?.ss ?? 1) === 0;
        },
        isShowHome() {
            return this.module?.content?.hs ?? 0;
        },
        linkLang() {
            const lanCode = getLanCode(this.lcid || 2052);
            return lanCode !== '' ? `/${getLanCode(this.lcid || 2052)}` : '';
        },
        homeLink() {
            return `${this.linkLang}/index.jsp`;
        },
        photoGroupPicPanelId() {
            return `photoGroupPicPanel${this.moduleId}`;
        },
        photoGroupPicListId() {
            return `photoGroupPicList${this.moduleId}`;
        },
        onephotoList() {
            return this.photoList.length === 1 ? 'onephotoList' : '';
        },
        photoGroupPicClass() {
            return `photoGroupPic ${this.onephotoList}`;
        },
        titleRightShare() {
            return (this.isShowShare && !this.isRegularHeadStyle) || (this.isShowShare && VITE_APP_MODE !== 'visitor')
                ? 'photo_title_flex'
                : '';
        },
        isEmptyPhotoGroupContent() {
            return (
                this.photoGroupContent === '<p><br /></p>' ||
                this.photoGroupContent === '<p></p>' ||
                this.photoGroupContent === ''
            );
        },
        photoGroupContent() {
            let content = this.photoGroupInfo?.content ?? '';
            if (content !== '') {
                content = content.replace(/<video/g, "<video preload='none'");
            }
            return content;
        },
        computedPicHeight() {
            return `${Math.ceil(16 * ((this.picHeight * 1.0) / this.picWidth))}rem`;
        },
        noDetailImg() {
            return `${this.resRoot}/image/v2/defaultIcon04.png?v=201811291709`;
        },
    },
    watch: {
        isShowShare(show) {
            this.$nextTick(() => {
                if (show) {
                    this.sharePanelInstance.render();
                } else {
                    this.sharePanelInstance.destroy();
                }
            });
        },
        'moduleExtInfo.shareLinkList'() {
            this.$nextTick(() => {
                this.createSharePanel();
            });
        },
        'module.content': {
            deep: true,
            handler() {
                this.$nextTick(this.init);
            },
        },
    },
    mounted() {
        setTimeout(() => {
            this.init();
        }, 500);
        window.addEventListener('resize', this.updateSwipe);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateSwipe);
    },
    methods: {
        updateSwipe() {
            if (this.photoList.length > 1) {
                initPhotoGroupSwipe(`photoGroupPicPanel${this.moduleId}`);
            }
        },
        init() {
            if (this.photoList.length > 1) {
                initPhotoGroupSwipe(`photoGroupPicPanel${this.moduleId}`);
            }

            this.initSlide();

            scrollFadeInV3(`photoGroupDetailHeader${this.moduleId}`, `photoGroupPicPanel${this.moduleId}`, 0);

            LinkSettingUtil.switchJump();
            this.initShareInfo();
            if (
                (this.isShowShare && this.isRegularHeadStyle) ||
                (this.isShowShare && VITE_APP_MODE !== 'visitor') ||
                (this.isShowShare && !this.isRegularHeadStyle)
            ) {
                this.createSharePanel();
            }
            bindImgLazyLoad(`module${this.moduleId}`);
            setTimeout(() => {
                photoGroupContentInitV3(this.moduleId);
            }, 500);

            setHrefEventHasReqArgs(jm(`#module${this.moduleId}`));
        },
        transformPicHeight(photoItem) {
            return {
                ...photoItem,
                picHeight: Math.ceil(16 * ((photoItem.picHeight * 1.0) / photoItem.picWidth) + 'rem'),
            };
        },
        initPhotoGroupShareData() {
            let browserTitle = this.moduleExtInfo.browserTitle;
            let shareLinkList = this.moduleExtInfo.shareLinkList;
            let shareUrl = this.moduleExtInfo.shareUrl;
            this.shareData = {
                browserTitle: browserTitle,
                shareUrl,
                shareLinkList,
            };
        },
        initSlide() {
            const slideOption = {
                fromCurentInx: true,
                captionDetailClass: 'caption_detail-photoGroup',
                contentTerminal: 'mobi-图册目录-图册详情',
            };
            initPictureSlidesV4(`#photoGroupPicList${this.moduleId} .photoGroupPicImg`, this.slideList, slideOption);
        },
        initShareInfo() {
            Fai.top._shareDataPhotoDetail = this.grapicShareInfo;
        },
        createSharePanel() {
            this.initPhotoGroupShareData();
            this.sharePanelInstance = new PhotoGroupDetailSharePanel(this.shareData);
            this.sharePanelInstance.render();
        },
    },
};
</script>

<style lang="scss">
@import '../photo-group-detail.scss';
</style>
