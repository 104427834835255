var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.render
    ? _c("div", { class: _vm.classList }, [
        _c("div", {
          staticClass: "webBackground webBackgroundFix",
          style: _vm.webBodyBackgroundStyle,
          attrs: { id: "webBodyBackground" },
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "navExistOffPanel",
          attrs: { id: "navExistOffPanel" },
        }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }