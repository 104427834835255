function getModuleAnchorId() {
    return VITE_APP_MODE !== 'visitor'
        ? JSON.parse(window.$router.currentRoute.query.data || '{}').mAnchorId?.replace("'", '')
        : new URLSearchParams(location.search).get('mAnchorId');
}

export function jumpAnchorModule(moduleId = getModuleAnchorId()) {
    if (moduleId && jm('#module' + moduleId).length) {
        var offsetTop = parseInt(jm('#module' + moduleId).offset().top - jm(`#preview`).offset().top),
            moduleHeight = parseInt(jm('#module' + moduleId).offset().height),
            scrollTop,
            bodyHeight = parseInt(jm('body').height()),
            bodyCenter = parseInt(bodyHeight / 2);
        // 模块在第一屏中间上方
        if (bodyCenter > offsetTop) {
            scrollTop = 0;
            // 模块在第一屏中间下方
        } else if (bodyHeight > offsetTop) {
            scrollTop = offsetTop - bodyCenter + moduleHeight;
            // 超过第一屏
        } else {
            scrollTop = offsetTop - bodyCenter + parseInt(moduleHeight / 2);
        }

        jm('#g_web')[0].scrollTop = scrollTop;
    }
}
