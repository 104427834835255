import request from './request';

export function getVideoDialog(moduleId, content, moduleV2) {
    let data = {},
        url = VITE_APP_MODE !== 'visitor' ? `/api/manage/` : `/api/guest/`;

    if (moduleV2) {
        url += `video/getMobiVideoDialog`;
        data.content = content;
    } else {
        url += `video/getFLvDialog`;
    }
    data.moduleId = moduleId;

    return request
        .post(url, {
            data,
        })
        .then(({ data }) => {
            return data;
        });
}

export function getVideoFirstImg(data) {
    let url = `/api/manage/video/getFLvFirstImg`;

    return request
        .post(url, {
            data,
        })
        .then(({ data }) => {
            return data;
        });
}
