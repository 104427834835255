<template>
    <photo-group-result :module-id="moduleId"></photo-group-result>
</template>
<script>
import PhotoGroupResult from './index.vue';
export default {
    name: 'PhotoGroupResultVisitor',
    components: {
        PhotoGroupResult,
    },
    props: {
        moduleId: {
            type: Number,
            default: 0,
        },
    },
};
</script>
