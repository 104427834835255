export function bindClickEvent(dom, nameSpace, handler) {
    var $dom = jm(dom);

    if (jm.os.supportsTouch) {
        var touchstartEvt = 'touchstart.' + nameSpace,
            touchmoveEvt = 'touchmove.' + nameSpace,
            touchendEvt = 'touchend.' + nameSpace;

        $dom.off(touchstartEvt).on(touchstartEvt, function () {
            var isMove = false;

            $dom.off(touchmoveEvt).on(touchmoveEvt, function () {
                isMove = true;
            });

            $dom.off(touchendEvt).on(touchendEvt, function () {
                $dom.off(touchendEvt).off(touchmoveEvt);

                if (!isMove) {
                    return handler.apply(this, arguments);
                }
            });
        });
    } else {
        var clickEvt = 'click.' + nameSpace;
        $dom.off(clickEvt).on(clickEvt, handler);
    }
}

export function bindTapEvent(dom, nameSpace, handler) {
    var eventMap = jm.os.supportsTouch
            ? { down: 'touchstart', up: 'touchend', move: 'touchmove' }
            : { down: 'mousedown', up: 'mouseup', move: 'mousemove' },
        touchUp = eventMap.up + '.' + nameSpace,
        touchDown = eventMap.down + '.' + nameSpace,
        touchMove = eventMap.move + '.' + nameSpace,
        $dom = jm(dom);

    $dom.off(touchDown).on(touchDown, function () {
        var isMove = false;

        $dom.off(touchMove).on(touchMove, function () {
            isMove = true;
        });

        $dom.off(touchUp).on(touchUp, function () {
            $dom.off(touchUp).off(touchMove);

            if (!isMove) {
                return handler.apply(this, arguments);
            }
        });
    });
}

export const bindTouchAndClickEvent = function (jmObj, callback, ns) {
    //ns：事件命名空间
    var nameSpace = ns ? '.' + ns : '';
    if (jm.os.supportsTouch) {
        jmObj.unbind('touchstart' + nameSpace);
        jmObj.bind('touchstart' + nameSpace, callback);
    } else {
        jmObj.unbind('mousedown' + nameSpace);
        jmObj.bind('mousedown' + nameSpace, callback);
    }
};

export function onTouchAndClickEvent(jmObj, callback) {
    if (jm.os.supportsTouch) {
        jm('body').off('touchstart', jmObj);
        jm('body').on('touchstart', jmObj, callback);
    } else {
        jm('body').off('mousedown', jmObj);
        jm('body').on('mousedown', jmObj, callback);
    }
}
