import request from '../request';

export function addMsgBoardData(data) {
    return request
        .post('/ajax/msgBoard_h.jsp', {
            data: 'cmd=addWafCk_add&msgBdData=' + data,
        })
        .then(({ data }) => {
            return data;
        });
}

export function getMsgBoardInfo(data) {
    return request.get('/api/manage/msgBoard/getMsgBoardInfo', { params: data }).then(({ data }) => data);
}

export function setPublicMsg({ id, flag, status }) {
    return request
        .post('/ajax/msgBoard_h.jsp', {
            params: {
                cmd: 'setWafCk_singlePub',
                id,
                [`flag${flag}`]: status,
            },
        })
        .then(({ data }) => data);
}

export function delMsg(id) {
    return request
        .post('/ajax/msgBoard_h.jsp', {
            params: {
                cmd: 'delWafCk_del',
                id,
            },
        })
        .then(({ data }) => data);
}
