var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "webHeader webHeaderBg",
      style: _vm.headerBgStyle,
      attrs: { id: "webHeader" },
    },
    [
      _vm.mallHeaderBg.img
        ? _c("span", {
            staticStyle: { display: "hidden" },
            attrs: { hideFileUrl: _vm.mallHeaderBg.img },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isHomePage
        ? [
            _c(
              "div",
              {
                class: _vm.mallThemeHeaderClass,
                attrs: { id: "mallThemeHeader" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "headerBgArea",
                    style: _vm.mallHeaderBgStyle,
                    attrs: { id: "headerBgArea" },
                  },
                  [
                    _c("div", { staticClass: "jz_top_wrapper J_top_wrapper" }, [
                      _c(
                        "a",
                        _vm._b(
                          {
                            staticClass: "J_logoImg f_logo",
                            style: _vm.logoImgStyle,
                            attrs: { id: "pageLogoImg" },
                          },
                          "a",
                          {
                            href: _vm.logoJumpUrl,
                            rel: _vm.logoNofollow,
                          },
                          false
                        )
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "f_input_box" }, [
                        _c("i", {
                          staticClass: "f_icon_conter faisco-icons-S000182",
                        }),
                        _vm._v(" "),
                        _c("input", {
                          attrs: { placeholder: _vm.searchGoText },
                          on: { click: _vm.popupSearch },
                        }),
                      ]),
                      _vm._v(" "),
                      _vm.titleTopIcon === 0
                        ? _c(
                            "a",
                            { attrs: { href: _vm.productGroupBtnHref } },
                            [_c("i", { staticClass: "faisco-icons-S000183" })]
                          )
                        : _vm.titleTopIcon === 1
                        ? _c("a", { attrs: { href: _vm.profileUrl } }, [
                            _c("i", { staticClass: "faisco-icons-S000040" }),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "headerHandlerArea" }, [
                  _c("div", { staticClass: "logoArea" }, [
                    _c(
                      "a",
                      _vm._b(
                        {},
                        "a",
                        {
                          href: _vm.logoJumpUrl,
                          rel: _vm.logoNofollow,
                        },
                        false
                      ),
                      [
                        _c(
                          "span",
                          {
                            staticClass: "pageLogo",
                            class: _vm.pageLogoClass,
                            style: _vm.pageLogoStyle,
                            attrs: {
                              id: "pageLogoMall",
                              sid: _vm.logoStyleId,
                              pw: _vm.pw,
                              ph: _vm.ph,
                            },
                          },
                          [
                            _c("div", {
                              staticClass: "J_logoImg logoImg",
                              style: _vm.logoImgStyle,
                              attrs: { id: "pageLogoImgMall" },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "titleMallArea" }, [
                    _c("div", { staticClass: "titleArea" }, [
                      _c(
                        "span",
                        { staticClass: "pageTitle", style: _vm.titleFontStyle },
                        [
                          _c("h1", { style: _vm.pageTitleH1Style }, [
                            _c(
                              "a",
                              {
                                style: _vm.jumpTitleFontStyle,
                                attrs: {
                                  id: "pageTitleMall",
                                  rel: _vm.titleNofollow,
                                  href: _vm.jumpTitleUrl,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.title) +
                                    "\n                                "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "mallUserBtnArea",
                        staticStyle: { display: "flex" },
                      },
                      [
                        _c(
                          "a",
                          {
                            class: _vm.allProductsClass,
                            style: _vm.allProductStyle,
                            attrs: { href: _vm.prUrl, hidefocus: "true" },
                          },
                          [
                            _c("div", { staticClass: "headerMenuNum" }, [
                              _c("span", {
                                staticClass: "icon-allProductIcon",
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "allProductNumText" }, [
                                _vm._v(_vm._s(_vm.allProductNum)),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "headerMenuText" }, [
                              _vm._v(_vm._s(_vm.mobiAllGoodsText)),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            class: _vm.userCenterClass,
                            style: _vm.userCenterStyle,
                            attrs: { hidefocus: "true", href: _vm.profileUrl },
                          },
                          [
                            _c("div", {
                              staticClass: "headerMenuIcon icon-headerMenuIcon",
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "headerMenuText" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.colMemberCenterText) +
                                  "\n                            "
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.mallOpen
                          ? _c(
                              "a",
                              {
                                staticClass: "headerItemMenu myOrder",
                                style: _vm.userCenterStyle,
                                attrs: { href: _vm.mlistUrl },
                              },
                              [
                                _c("div", {
                                  staticClass:
                                    "headerMenuIcon icon-headerMenuIcon",
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "headerMenuText" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.memberCenterOrderText) +
                                      "\n                            "
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "headerMenuBg" }),
                  ]),
                ]),
              ]
            ),
          ]
        : [
            _c("div", {
              class: _vm.mallThemeTopBarBgClass,
              style: _vm.mallHeaderBgStyle,
              attrs: { id: "mallThemeTopBarBg" },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                class: _vm.mallThemeTopBarClass,
                attrs: { id: "mallThemeTopBar" },
              },
              [
                _c("div", { staticClass: "jz_top_wrapper J_top_wrapper" }, [
                  _c("a", {
                    staticClass: "J_to_back f_back faisco-icons-S000108",
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "f_input_box" }, [
                    _c("i", {
                      staticClass: "f_icon_conter faisco-icons-S000182",
                    }),
                    _vm._v(" "),
                    _c("input", {
                      attrs: { placeholder: _vm.searchGoText },
                      on: { click: _vm.popupSearch },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "f_top_right",
                      attrs: { href: _vm.indexUrl },
                    },
                    [_c("i", { staticClass: "f_home faisco-icons-S0000196" })]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "logoTitleSide" }, [
                  _c("div", { staticClass: "logoArea" }, [
                    _c(
                      "span",
                      {
                        staticClass: "pageLogo",
                        class: _vm.pageLogoClass,
                        attrs: {
                          id: "pageLogoMall",
                          sid: _vm.logoStyleId,
                          pw: _vm.pw,
                          ph: _vm.ph,
                        },
                      },
                      [
                        _c(
                          "a",
                          _vm._b(
                            { attrs: { alt: "", hidefocus: "true" } },
                            "a",
                            {
                              href: _vm.logoJumpUrl,
                              rel: _vm.logoNofollow,
                            },
                            false
                          ),
                          [
                            _c("div", {
                              staticClass: "J_logoImg logoImg",
                              style: _vm.logoImgStyle,
                              attrs: { id: "pageLogoImgMall" },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "titleArea" }, [
                    _c("h1", { style: _vm.titleAreaStyle }, [
                      _c(
                        "a",
                        _vm._b(
                          { attrs: { hidefocus: "true" } },
                          "a",
                          {
                            href: _vm.jumpTitleUrl,
                            rel: _vm.titleNofollow,
                            style: _vm.jumpTitleFontStyle,
                          },
                          false
                        ),
                        [
                          _c(
                            "span",
                            {
                              staticClass: "pageTitle",
                              attrs: { id: "pageTitleMall" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.title) +
                                  "\n                            "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "toolMenuSide" }, [
                  _c("div", { staticClass: "userEnterAera" }, [
                    _c(
                      "a",
                      {
                        staticClass: "userEnterLink",
                        attrs: { href: _vm.profileUrl, hidefocus: "true" },
                      },
                      [
                        _c("span", {
                          staticClass: "userEnterIcon icon-userEnterIcon",
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "userEnterText" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.colMemberCenterText) +
                              "\n                        "
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "seacherAera",
                      on: { click: _vm.popupSearch },
                    },
                    [
                      _c("span", {
                        staticClass: "seacherBtnIcon icon-seacherBtnIcon",
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "seacherAeraText" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.searchGoText) +
                            "\n                    "
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
          ],
      _vm._v(" "),
      _vm.designMode || (!_vm.isMallTheme && !_vm.designMode)
        ? _c(
            "div",
            {
              staticClass: "header-forLogo header",
              class: _vm.headerClass,
              style: _vm.headerStyle,
              attrs: { id: "header" },
            },
            [
              _vm.matchLogoAlign
                ? [
                    _c(
                      "span",
                      {
                        staticClass: "pageTitle",
                        style: _vm.titleFontStyle,
                        attrs: { id: "pageTitle" },
                      },
                      [
                        _c("h1", { style: _vm.pageTitleH1Style }, [
                          _c(
                            "a",
                            {
                              style: _vm.jumpTitleFontStyle,
                              attrs: {
                                href: _vm.jumpTitleUrl,
                                rel: _vm.titleNofollow,
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.title) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isHiddenLogo,
                            expression: "!isHiddenLogo",
                          },
                        ],
                        staticClass: "pageLogo",
                        class: _vm.pageLogoClass,
                        attrs: {
                          id: "pageLogo",
                          sid: _vm.logoStyleId,
                          pw: _vm.pw,
                          ph: _vm.ph,
                        },
                      },
                      [
                        _c(
                          "a",
                          _vm._b(
                            {},
                            "a",
                            { href: _vm.logoJumpUrl, rel: _vm.logoNofollow },
                            false
                          ),
                          [
                            _c("div", {
                              staticClass: "J_logoImg logoImg",
                              style: _vm.logoImgStyle,
                              attrs: { id: "pageLogoImg" },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                : _vm.leftAlign
                ? [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isHiddenLogo,
                            expression: "!isHiddenLogo",
                          },
                        ],
                        staticClass: "pageLogo",
                        class: _vm.pageLogoClass,
                        style: _vm.pageLogoStyle,
                        attrs: {
                          id: "pageLogo",
                          sid: _vm.logoStyleId,
                          pw: _vm.pw,
                          ph: _vm.ph,
                        },
                      },
                      [
                        _c(
                          "a",
                          _vm._b(
                            {},
                            "a",
                            { href: _vm.logoJumpUrl, rel: _vm.logoNofollow },
                            false
                          ),
                          [
                            _c("div", {
                              staticClass: "J_logoImg logoImg",
                              style: _vm.logoImgStyle,
                              attrs: { id: "pageLogoImg" },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "pageTitle",
                        class: _vm.titleClass,
                        style: _vm.titleFontStyle,
                        attrs: { id: "pageTitle" },
                      },
                      [
                        _c("div", { style: _vm.pageTitleH1Style }, [
                          _c(
                            "a",
                            {
                              style: _vm.jumpTitleFontStyle,
                              attrs: {
                                href: _vm.jumpTitleUrl,
                                rel: _vm.titleNofollow,
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.title) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }