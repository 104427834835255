<template>
    <find-pwd-version-three v-if="openThemeV3" :module-id="moduleId"></find-pwd-version-three>
    <find-pwd-version-two v-else :module-id="moduleId"></find-pwd-version-two>
</template>
<script>
import FindPwdVersionTwo from './findPwd.vue';
import FindPwdVersionThree from './findPwdV3.vue';
import { mapState } from 'vuex';

export default {
    name: 'FindPassword',
    components: {
        FindPwdVersionTwo,
        FindPwdVersionThree,
    },
    props: {
        moduleId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapState('app', ['openThemeV3']),
    },
};
</script>
<style lang="scss"></style>
