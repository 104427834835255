import { insertSort, initModuleAttr } from './util';
import { MODULE_STYLE } from '@/def/module';
import { initMyModuleInfo } from './util';
import { floatZone } from '@/system/floatEffect/container/module/floatZone';
import { inTabModuleEdit } from '@/modules/tabContainer/util';
import { inFoldModuleEdit } from '@/modules/foldContainer/util';

import { assignModuleIdMap, setModuleToMap } from './helpers/module';
import { isNewsDetailCol, isProductDetailCol } from '@/def/col.js';
import Vue from 'vue';

function getModuleChildrenIds(module = {}, moduleIdMap = {}) {
    const isTabOfFold = module.style === MODULE_STYLE.HORIZON_TAB || module.style === MODULE_STYLE.FOLD;
    const labelList = (module?.content?.labelList || []).filter((label) => label.id > 0);
    const children = isTabOfFold ? labelList.map((label) => moduleIdMap[label.id]) : module.children;
    if (!children || (Array.isArray(children) && children.length === 0)) {
        return [];
    }
    let result = [];
    for (let child of children) {
        if (child) {
            result.push(child.id);
            const list = getModuleChildrenIds(child, moduleIdMap);
            if (list.length > 0) {
                result.push(...list);
            }
        }
    }
    return result;
}

function isBasicModule(style) {
    return style == MODULE_STYLE.FLOAT_BTN || style == MODULE_STYLE.FLOAT_IMG || style == MODULE_STYLE.SIMPLE_TEXT;
}

export default {
    updatePageScrollTop(state, pageScrollTop) {
        state.pageScrollTop = pageScrollTop;
    },
    addModule(state, { addByDrag, module, copyModuleId, parentId, insertIdx }) {
        this.commit('manage/updateModuleAttr', {
            id: module.id,
            attr: initModuleAttr(module.style, module.pattern),
        });

        this.commit('addCurrentPageModuleIdMap', {
            addByDrag,
            module,
            parentId,
            insertIdx,
        });
        this.commit('addCurrentPageModule', {
            addByDrag,
            module,
            copyModuleId,
            parentId,
            insertIdx,
        });

        Vue.prototype.$designer.styleChanged();
    },
    addCurrentPageModule(state, { addByDrag, module, copyModuleId, parentId, insertIdx = -1 }) {
        const { isAfter, moduleId, isContainer } = state.manage.draggableAddInfo.benchmarkModuleInfo;
        if (parentId) {
            state.currentPageModuleIds.push(module.id);
            return;
        }

        if (addByDrag && !isContainer) {
            let benchmarkModuleIndex = state.currentPageModuleIds.indexOf(moduleId);
            if (benchmarkModuleIndex >= 0) {
                benchmarkModuleIndex = isAfter ? benchmarkModuleIndex + 1 : benchmarkModuleIndex;
                state.currentPageModuleIds.splice(benchmarkModuleIndex, 0, module.id);
                return;
            }
        }
        if (copyModuleId > 0) {
            // 拷贝的模块在原先模块下方
            let copyModuleIndex = state.currentPageModuleIds.findIndex((id) => copyModuleId == id);
            if (copyModuleIndex > 0) {
                state.currentPageModuleIds.splice(copyModuleIndex + 1, 0, module.id);
            } else {
                state.currentPageModuleIds.unshift(module.id);
            }
        } else if (insertIdx >= 0) {
            state.currentPageModuleIds.splice(insertIdx, 0, module.id);
        } else {
            if (isNewsDetailCol(window._colId) || isProductDetailCol(window._colId)) {
                // 详情页插入到尾部
                state.currentPageModuleIds.push(module.id);
            } else {
                state.currentPageModuleIds.unshift(module.id);
            }
        }
    },
    addPopupZoneModule(state, modules) {
        let idList = modules.map((item) => item.id);
        let moduleIdMap = {};
        modules.forEach((module) => {
            let { id } = module;
            moduleIdMap[id] = module;
            // state.currentPageModuleIdMap[id] = null;
        });
        assignModuleIdMap(state, moduleIdMap);
        idList = idList.filter((id) => {
            return !state.currentPageModuleIds.includes(id);
        });
        state.currentPageModuleIds = [...state.currentPageModuleIds, ...idList];
    },

    addCurrentPageModuleIdMap(state, { addByDrag, module, parentId, insertIdx }) {
        let { moduleId, isContainer, renderOptions } = state.manage.draggableAddInfo.benchmarkModuleInfo;

        if (!addByDrag) {
            renderOptions = { position: { l: 0, t: 0 } }; // 默认添加至左上角
        }

        let inContainerId = addByDrag && isContainer ? moduleId : state.manage.moduleAdd.inContainerId;
        if (insertIdx > 0) {
            // 预览区内拖拽到新的插入位置
            inContainerId = 0;
        }

        parentId = parentId || inContainerId;

        const { style } = module;
        let basicModule = isBasicModule(style);

        if (parentId > 0) {
            let parent = state.currentPageModuleIdMap[parentId];
            let renderOpts = {};
            let setChild = false;
            switch (parent.style) {
                case MODULE_STYLE.PACK:
                    renderOpts.moduleInPack = {
                        position: renderOptions.position,
                    };
                    setChild = true;
                    break;
                case MODULE_STYLE.HORIZON_TAB:
                    if (!basicModule) {
                        module.inTab = parseInt(parentId);
                    }
                    break;
                case MODULE_STYLE.FOLD:
                    if (!basicModule) {
                        module.inFold = parseInt(parentId);
                    }
                    break;
                case MODULE_STYLE.POPUP_ZONE:
                    if (module.renderOptions.moduleInPopupZone) {
                        renderOptions.position = Object.assign(
                            renderOptions.position || {},
                            module.renderOptions.moduleInPopupZone.position
                        );
                    }
                    renderOpts.moduleInPopupZone = {
                        position: renderOptions.position || {},
                    };
                    setChild = true;
                    this.commit('editMode/addModule', initMyModuleInfo(module));
                    this.commit('editMode/addSortModule', module.id);
                    this.dispatch('editMode/activeBtn');
                    break;
                case MODULE_STYLE.FLOAT_ZONE:
                    renderOpts.moduleInFloatZone = {
                        position: renderOptions.position || {},
                    };
                    setChild = true;
                    floatZone.addModule(module);
                    this.dispatch('editMode/activeBtn');
                    break;
                default:
                    break;
            }
            !$.isEmptyObject(renderOpts) &&
                (module.renderOptions = {
                    ...module.renderOptions,
                    ...renderOpts,
                });
            if (setChild) {
                parent.children.push(module);
                if (parent.style == MODULE_STYLE.PACK) {
                    module.ip = parentId;
                }
            }
            parent.contentChange = true;
        }

        setModuleToMap(state, module);
    },
    updateCurrentPageModules(state, modules) {
        /*
            commit actions
            loadCurrentPageModuleIds
            loadCurrentPageModuleIdMap
            loadCurrentPageModuleAttr
        */
        state.currentPageModuleIds = modules.map((item) => item.id);
        // transform list to Map<id, module>
        const moduleIdMap = modules
            .map((item) => ({ [item.id]: item }))
            .reduce((acc, current) => (acc = Object.assign({}, acc, current)), {});
        state.currentPageModuleIdMap = moduleIdMap;
        if (VITE_APP_MODE !== 'visitor') {
            Object.keys(moduleIdMap).forEach((key) => {
                Vue.set(state.manage.moduleAttr, `module${key}`, initModuleAttr(key, moduleIdMap[key].pattern));
            });
        }
    },
    unshiftModuleIds(state, ids = []) {
        state.currentPageModuleIds = ids.concat(state.currentPageModuleIds);
    },
    assignCurrentPageModuleIds(state, ids = []) {
        state.currentPageModuleIds = state.currentPageModuleIds.concat(ids);
    },
    delModuleIds(state, ids = []) {
        for (let id of ids) {
            const idx = state.currentPageModuleIds.findIndex((item) => item === id);
            state.currentPageModuleIds.splice(idx, 1);
        }
    },
    clearCurrentPageModuleIds(state) {
        state.currentPageModuleIds = [];
    },
    loadCurrentPageModuleIds(state, ids = []) {
        state.currentPageModuleIds = ids;
    },
    assignCurrentPageModuleIdMap(state, moduleIdMap = {}) {
        state.currentPageModuleIdMap = Object.assign({}, state.currentPageModuleIdMap, moduleIdMap);
    },
    loadCurrentPageModuleIdMap(state, moduleIdMap = {}) {
        state.currentPageModuleIdMap = moduleIdMap;
    },
    addChildModule(state, { moduleId, parentId, originalParentId, top = 0, left = 0 }) {
        moduleId = parseInt(moduleId);
        if (Number.isNaN(moduleId)) {
            return;
        }
        // 子模块数据修改
        let module = state.currentPageModuleIdMap[moduleId];
        module.renderOptions = {
            ...module.renderOptions,
            moduleInPack: {
                ...module.renderOptions.moduleInPack,
                position: { l: left, t: top },
            },
        };
        module.ip = parentId;
        // 连接父模块
        let parent = state.currentPageModuleIdMap[parentId];
        parent.children.push(module);

        let updated = {
            [parentId]: parent,
            [moduleId]: module,
        };

        if (originalParentId) {
            // 取消连接旧的父模块
            let originParent = state.currentPageModuleIdMap[originalParentId];
            originParent.children.splice(
                originParent.children.findIndex((child) => child.id === moduleId),
                1
            );
            updated[originalParentId] = originParent;
        }

        assignModuleIdMap(state, updated);
        parent.contentChange = true;
    },
    addComponnets(state, components = {}) {
        state.components = Object.assign({}, state.components, components);
    },
    updateModule(state, module) {
        setModuleToMap(state, module);
    },
    updateNewsList(state, newsInfo) {
        var newsList = state.manage.newsList;

        var targetNew = newsList.find((tmpNewInfo) => tmpNewInfo.id === newsInfo.id);

        if (targetNew) {
            Object.assign(targetNew, newsInfo);
        } else {
            state.manage.newsList.push(newsInfo);
        }
    },
    batchUpdateNewsList(state, newsList) {
        newsList.forEach((info) => {
            this.commit('updateNewsList', info);
        });
    },
    delNew(state, id) {
        var idx = -1;
        state.manage.newsList.find(function (item, index) {
            if (item.id === id) {
                idx = index;
            }
            return item.id === id;
        });

        if (idx != -1) {
            state.manage.newsList.splice(idx, 1);
        }

        // 重刷所有绑定了该文章的文章列表模块
        Object.values(state.currentPageModuleIdMap).forEach(function (module) {
            if (module.style == 6) {
                module.extInfo.ntlHook = !module.extInfo.ntlHook;
            }
        });
    },
    batchAddNews(state, newsList) {
        newsList.forEach(function (item) {
            state.manage.newsList.unshift(item);
        });

        // 重刷所有绑定了该文章的文章列表模块
        Object.values(state.currentPageModuleIdMap).forEach(function (module) {
            if (module.style == 6) {
                module.extInfo.ntlHook = !module.extInfo.ntlHook;
            }
        });

        // 按时间倒序排序
        insertSort(state.manage.newsList, 'date');
    },
    updateNewsGroupList(state, groupInfo) {
        var newsGroupList = state.newsGroupList;
        var targetInfo = newsGroupList.filter(function (tmpNewInfo) {
            return tmpNewInfo.id === groupInfo.id;
        })[0];

        if (targetInfo) {
            Object.assign(targetInfo, groupInfo);
        } else {
            state.newsGroupList.push(groupInfo);
        }
    },
    batchUpdateNewsGroupList(state, newsGroupList) {
        let _this = this;
        newsGroupList.forEach(function (info) {
            _this.commit('updateNewsGroupList', info);
        });
    },
    updateAllNewsCount(state, count) {
        state.allNewsCount = count;
    },
    updateMobiBgMusic(state, mobiBgMusic) {
        state.app.mobiBgMusic = {
            ...mobiBgMusic,
        };
    },
    setModule(state, { moduleId, data }) {
        const module = state.currentPageModuleIdMap[moduleId];
        assignModuleIdMap(state, {
            [moduleId]: {
                ...module,
                ...data,
            },
        });
    },
    deleteModule(state, moduleId) {
        if (state.delModuleList.includes(moduleId)) return;
        const module = state.currentPageModuleIdMap[moduleId];
        const childrenIds = getModuleChildrenIds(module, state.currentPageModuleIdMap);
        state.delModuleList.push(...[moduleId].concat(childrenIds));
        state.manage.delButtonChanged++;

        Vue.prototype.$designer.styleChanged();
    },
    setHiddenModules(state, hiddenModule) {
        let list = state.columnModuleHiddenList;
        if (Array.isArray(hiddenModule)) {
            if (hiddenModule.length === 0) {
                return;
            }
            list = list.concat(hiddenModule);
        }

        if (typeof hiddenModule === 'number') {
            list.push(hiddenModule);
        }
        state.columnModuleHiddenList = [...new Set(list)];
    },
    clearColumnHiddenModules(state) {
        state.columnModuleHiddenList = [];
    },
    hideModule(state, moduleId) {
        state.manage.columnModuleHiddenListChanged++;
        const module = state.currentPageModuleIdMap[moduleId];
        const childrenIds = getModuleChildrenIds(module, state.currentPageModuleIdMap);
        if (childrenIds.length > 0) {
            this.commit('setHiddenModules', [moduleId].concat(childrenIds));
        } else {
            this.commit('setHiddenModules', moduleId);
        }

        const moduleInPackId = module.ip; // inPack
        if (moduleInPackId > 0) {
            const parent = state.currentPageModuleIdMap[moduleInPackId];
            parent.contentChange = true;
        } else if (module.inTab || module.inFold) {
            this.commit('dealWithLabelContainerChild', {
                isDel: true,
                moduleId,
            });
        }

        Vue.prototype.$designer.styleChanged();
    },
    showModule(state, moduleId) {
        this.commit('dealWithLabelContainerChild', { isDel: false, moduleId });

        const module = state.currentPageModuleIdMap[moduleId];
        let toShowIds = [moduleId];

        if (module && Array.isArray(module.children)) {
            for (let child of module.children) {
                // 自由容器最多两层
                toShowIds.push(child.id);
                if (child && Array.isArray(child.children)) {
                    for (let _child of child.children) {
                        toShowIds.push(_child.id);
                    }
                }
            }
        }

        state.columnModuleHiddenList = state.columnModuleHiddenList.filter((id) => toShowIds.includes(id) === false);

        Vue.prototype.$designer.styleChanged();
    },
    addHttpInCodeCount(state, payload) {
        state.httpInCodeCount = payload + state.httpInCodeCount;
    },

    addTopTip(state, payload) {
        state.topTips.push(payload);
    },
    // 弹窗容器 - start
    addPopupZoneChildModule(state, module) {
        let { moduleInPopupZone = {} } = module.renderOptions;
        if (!moduleInPopupZone.position) {
            moduleInPopupZone.position = {};
            module.renderOptions.moduleInPopupZone = moduleInPopupZone;
        }

        let parent = state.currentPageModuleIdMap[module.inPopup];
        if (parent) {
            parent.children = parent.children.filter((child) => {
                return child.id != module.id;
            });
            parent.children.push(module);
        }

        assignModuleIdMap(state, {
            [module.id]: module,
            [parent.id]: parent,
        });

        if (!state.currentPageModuleIds.includes(module.id)) {
            state.currentPageModuleIds.unshift(module.id);
        }

        this.commit('manage/updateModuleAttr', {
            id: module.id,
            attr: initModuleAttr(module.style),
        });
        this.commit('editMode/addSortModule', module.id);
        this.commit('editMode/updateModule', initMyModuleInfo(module));
        this.dispatch('editMode/activeBtn');
    },
    hidePopupZoneChildModule(state, moduleId) {
        this.commit('editMode/hideModule', moduleId);
        this.dispatch('editMode/activeBtn');
    },
    delPopupZoneChildModule(state, module) {
        let parent = state.currentPageModuleIdMap[state.popupZone.popupZoneId];
        let children = parent.children.filter((child) => child.id != module.id);
        parent.children = children;
        setModuleToMap(state, parent);
        state.currentPageModuleIds = state.currentPageModuleIds.filter((id) => {
            return id != module.id;
        });
        this.commit('editMode/deleteModule', module.id);
        this.commit('editMode/addDeleteModule', module.id);
        this.dispatch('editMode/activeBtn');
    },
    resetModule(state, { module }) {
        const moduleId = module.id;
        const currentPageModuleIds = state.currentPageModuleIds;

        // 更新moduleAttr
        this.commit('manage/updateModuleAttr', {
            id: moduleId,
            attr: initModuleAttr(module.style),
        });

        // 更新模块顺序
        let moduleIndex = currentPageModuleIds.findIndex((id) => id === moduleId);

        if (moduleIndex !== -1) {
            currentPageModuleIds.splice(moduleIndex, 1);
        }

        currentPageModuleIds.unshift(moduleId);

        // 更新模块数据
        this.commit('updateModule', module);
    },
    // 弹窗容器 - end
    changeCurrentPageModuleIds(state, moduleIds) {
        state.currentPageModuleIds = moduleIds;
    },
    addChildModuleByCopy(state, { moduleInfoMap, moduleAttrMap, copyModuleId, moduleIdList }) {
        // moduleAttr 处理
        if (VITE_APP_MODE !== 'visitor') {
            state.manage.moduleAttr = Object.assign({}, state.manage.moduleAttr, moduleAttrMap);
        }
        // 模块数据全部塞进去
        assignModuleIdMap(state, moduleInfoMap);
        // 拷贝的模块在原先模块下方
        let copyModuleIndex = state.currentPageModuleIds.findIndex((id) => copyModuleId == id);
        state.currentPageModuleIds.splice(copyModuleIndex + 1, 0, ...moduleIdList);
    },
    dealWithLabelContainerChild(state, { isDel = false, moduleId }) {
        let module = state.currentPageModuleIdMap[moduleId];
        if (!module) {
            return;
        }
        let parentId = module.inTab || module.inFold;
        let isLabelChild = parentId > 0;
        if (!isLabelChild) {
            return;
        }
        if (isDel) {
            this.commit('delLabelContainerChild', { module, parentId });
        } else {
            this.commit('showLabelContainerChild', { module, parentId });
        }
    },
    // 删除标签容器子模块
    delLabelContainerChild(state, { module, parentId }) {
        let { id, style } = module;
        if (module.inTab > 0) {
            inTabModuleEdit.removeModuleInTab(parentId, id, true, style);
        } else if (module.inFold > 0) {
            inFoldModuleEdit.removeModuleInFold(parentId, id, true, style);
        }
    },
    // 删除标签容器子模块
    showLabelContainerChild(state, { module, parentId }) {
        let { id } = module;
        if (module.inTab > 0) {
            inTabModuleEdit.addModuleToTab(parentId, id);
        } else if (module.inFold > 0) {
            inFoldModuleEdit.addModuleToFold(parentId, id);
        }
    },
    designerShowLineHeightChange(state, showLineHeight) {
        state.manage.designer.showLineHeight = showLineHeight;
    },
    initMobiTipList(state, payload) {
        state.mobiTipList = [...new Set(state.mobiTipList.concat(payload))];
    },
    unshiftModule(state, module = {}) {
        if (!state.currentPageModuleIdMap[module.id]) {
            setModuleToMap(state, module);

            if (VITE_APP_MODE !== 'visitor') {
                Vue.set(state.manage.moduleAttr, `module${module.id}`, initModuleAttr(module.id));
            }
            if (state.currentPageModuleIds.includes(module.id) === false) {
                state.currentPageModuleIds.unshift(module.id);
            }

            if (Array.isArray(module.children) && module.children.length > 0) {
                for (let child of module.children) {
                    this.commit('unshiftModule', child);
                }
            }
        }
    },
    pushModule(state, module = {}) {
        if (!state.currentPageModuleIdMap[module.id]) {
            setModuleToMap(state, module);

            if (VITE_APP_MODE !== 'visitor') {
                Vue.set(state.manage.moduleAttr, `module${module.id}`, initModuleAttr(module.id));
            }
            if (state.currentPageModuleIds.includes(module.id) === false) {
                state.currentPageModuleIds.push(module.id);
            }

            if (Array.isArray(module.children) && module.children.length > 0) {
                for (let child of module.children) {
                    this.commit('pushModule', child);
                }
            }
        }
    },
    updateModuleInPackPosition(state, { moduleId, left, top }) {
        let module = state.currentPageModuleIdMap[moduleId];
        try {
            module.renderOptions.moduleInPack.position.l = left;
            module.renderOptions.moduleInPack.position.t = top;
        } catch (error) {
            console.error('[updateModuleInPackPosition] error: ' + error);
        }
    },
    updateModuleInPopupZonePosition(state, { moduleId, left, top }) {
        let module = state.currentPageModuleIdMap[moduleId];
        try {
            module.renderOptions.moduleInPopupZone.position.l = left;
            module.renderOptions.moduleInPopupZone.position.t = top;
        } catch (error) {
            console.error('[updateModuleInPopupZonePosition] error: ' + error);
        }
    },
    updateModuleInFloatZonePosition(state, { moduleId, left, top }) {
        let module = state.currentPageModuleIdMap[moduleId];
        try {
            module.renderOptions.moduleInFloatZone.position.l = left;
            module.renderOptions.moduleInFloatZone.position.t = top;
        } catch (error) {
            console.error('[updateModuleInPopupZonePosition] error: ' + error);
        }
    },

    updatePackChildModulePosition(state, payload) {
        const { moduleId, position } = payload;
        const { left, top } = position;
        let module = state.currentPageModuleIdMap[moduleId];
        try {
            module.renderOptions.moduleInPack.position.l = left;
            module.renderOptions.moduleInPack.position.t = top;
        } catch (error) {
            console.error('[updateModuleInPackPosition] error: ' + error);
        }
    },

    setPopupFormModuleInfo(state, { formId, info }) {
        state.popupFormModuleIdMap = Object.assign({}, state.popupFormModuleIdMap, {
            [formId]: info,
        });
        // Vue.set(state.popupFormModuleIdMap, formId, info);
    },
    setPopupFormId(state, formId) {
        state.popupFormId = formId;
    },

    // 重置模块contentChange状态
    resetModuleContentChangeStatus(state) {
        Object.keys(state.currentPageModuleIdMap).forEach((moduleId) => {
            if (state.currentPageModuleIdMap[moduleId].contentChange) {
                state.currentPageModuleIdMap[moduleId].contentChange = false;
            }
        });
    },

    setShowSubTitleSkinIdList(state, payload) {
        state.showSubTitleSkinIdList = payload || [];
    },
    setFullSiteSubTitleType(state, payload) {
        state.fullSiteSubTitleType = payload || 0;
    },
    addMobiTips(state, tips = '') {
        if (tips !== '') {
            state.mobiTipList = [...new Set(state.mobiTipList.concat(tips))];
        }
    },
};
