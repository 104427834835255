<template>
    <new-news-detail :module-id="moduleId"></new-news-detail>
</template>
<script>
import NewNewsDetail from './module/index.vue';
import { clueLogMixin } from '../util.js';
export default {
    name: 'NewNewsDetailVisitor',
    components: {
        NewNewsDetail,
    },
    mixins: [clueLogMixin],
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {};
    },
};
</script>
