var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isTopbarOpen
    ? _c(
        "div",
        {
          staticClass: "multilingualArea",
          attrs: { id: "webMultilingualArea" },
        },
        [
          _c(
            "div",
            { staticClass: "multilingual" },
            [
              true
                ? [
                    _vm.memberVisible || _vm.manageMode || _vm.fromJZM
                      ? _c(
                          "div",
                          {
                            staticClass: "memberEntrance",
                            style: _vm.memberEntranceStyle,
                            attrs: { id: "memberEntrance" },
                          },
                          [
                            _vm.memberName === "" || _vm.sessionMemberId == 0
                              ? [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "g_mullink member_a memberIcon",
                                      attrs: {
                                        id: "topBarMemberLogin",
                                        href: _vm.loginUrl,
                                      },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "faisco-icons-contact1",
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "g_mullinkFont" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.memberLoginLoginText)
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "g_mullink member_a signupIcon",
                                      attrs: {
                                        id: "topBarMemberSignup",
                                        href: _vm.signUpUrlAndReturn,
                                      },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "faisco-icons-edit1",
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "g_mullinkFont" },
                                        [_vm._v(_vm._s(_vm.memberRegText))]
                                      ),
                                    ]
                                  ),
                                ]
                              : [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "g_mullink member_a memberIcon",
                                      attrs: {
                                        id: "topBarMemberName",
                                        href: _vm.profileUrlAndReturn,
                                      },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "faisco-icons-contact1",
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "g_mullinkFont" },
                                        [_vm._v(_vm._s(_vm.memberName))]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "g_mullink exit",
                                      attrs: { hidefocus: "true" },
                                      on: { click: _vm.logout },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "g_mullinkFont" },
                                        [_vm._v(_vm._s(_vm.topBarLogoutText))]
                                      ),
                                    ]
                                  ),
                                ],
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    (_vm.mallOpen && _vm.mallHidden === 0) || _vm.manageMode
                      ? [
                          _vm.isCnOrTcn
                            ? _c(
                                "a",
                                {
                                  staticClass: "g_mullink carIcon",
                                  style: _vm.mallCartIconStyle,
                                  attrs: {
                                    id: "mallCartIcon",
                                    href: _vm.mcartUrl,
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass: "faisco-icons-cart1",
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "g_mullinkFont" }, [
                                    _vm._v(_vm._s(_vm.rbarCartText)),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "proNum" }, [
                                    _vm._v(_vm._s(_vm.productAmountStr)),
                                  ]),
                                ]
                              )
                            : _c(
                                "a",
                                {
                                  staticClass: "g_mullink carIcon",
                                  style: _vm.mallCartIconStyle,
                                  attrs: {
                                    id: "mallCartIcon",
                                    href: _vm.mcartUrl,
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass: "faisco-icons-cart1",
                                  }),
                                  _c("span", { staticClass: "proNum" }, [
                                    _vm._v(_vm._s(_vm.productAmountStr)),
                                  ]),
                                ]
                              ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.openLanguageInfoList.length > 1 &&
                    (_vm.locaterModule.hidden === 0 || _vm.manageMode)
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "multiLanguageCtrl",
                              class: _vm.languageClass,
                              style: _vm.localeHiddenStyle,
                              attrs: { id: "multiLanguageCtrl" },
                            },
                            [
                              _c("span", {
                                staticClass: "lanTitle",
                                style: _vm.localeFontStyle,
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "lanIcon" }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "lanSelect",
                              attrs: { id: "lanSelect" },
                            },
                            [
                              _c(
                                "table",
                                {
                                  attrs: { cellpadding: "0", cellspacing: "0" },
                                },
                                [
                                  _c("tr", [
                                    _c("td", [
                                      _c(
                                        "div",
                                        { staticClass: "lanSelected" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "multiLanguageCtrl",
                                              class: _vm.languageClass,
                                              attrs: { id: "lanSelected" },
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "lanTitle",
                                                style: _vm.localeFontStyle,
                                              }),
                                              _vm._v(" "),
                                              _c("span", {
                                                staticClass: "lanIcon",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "lanContainer",
                                          class: _vm.languageClass,
                                          style: _vm.localeFontStyle,
                                        },
                                        _vm._l(
                                          _vm.openLanguageInfoList,
                                          function (language) {
                                            return _c(
                                              "div",
                                              _vm._b(
                                                {
                                                  key: language.lcid,
                                                  staticClass: "lanItem",
                                                  class:
                                                    _vm.createLanguageIconClass(
                                                      language.lcid
                                                    ),
                                                  attrs: {
                                                    value: language.url,
                                                  },
                                                },
                                                "div",
                                                _vm._d({}, [
                                                  _vm.createCurrentLanProperty(
                                                    language.lcid
                                                  ),
                                                  language.lcid,
                                                ])
                                              ),
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "lanTitle",
                                                    class:
                                                      _vm.createLanguageTitleClass(
                                                        language.lcid
                                                      ),
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(language.name)
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("span", {
                                                  staticClass: "lanIcon",
                                                }),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }