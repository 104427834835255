//获取flag某位的值
export function getBitMemory(flag, bit) {
    return (flag & bit) == bit;
}

//设置flag某位的值
export function setBitMemory(flag, bit, val) {
    if (val) {
        flag |= bit;
    } else {
        flag &= ~bit;
    }
    return flag;
}

/*
 * 操作多级对象属性,返回一个vue计算属性,用于操作vue多级对象属性
 * Comm.operMultiLevelObjectAttr('module.flag', getFunc, setFunc)
 * operAttr 需要操作的多级属性 如'module.flag'
 * getFunc 获取数据时执行的函数 在函数中this为操作的对象，第一个参数key为操作的属性（如flag）
 * setFunc 设置数据时执行的函数 在函数中this为操作的对象，第一个参数key为操作的属性（如flag），第二个参数newVal为需要设置的新值
 */
function operMultiLevelObjectAttr(operAttr, getFunc, setFunc) {
    function operObjAttr(func) {
        return function () {
            let key = '';
            let operObj = this;
            let attrs = operAttr.split('.');
            key = attrs.pop();
            for (const attr of attrs) {
                operObj = operObj[attr];
            }
            return func.call(operObj, key, ...arguments, this);
        };
    }

    return {
        get: operObjAttr(getFunc),
        set: operObjAttr(setFunc),
    };
}

/*
 * 绑定flag列表到vue的计算属性中，可读写
 *  computed:{
 *    ...O.mapFlag({
 *      useFontIcon: 0x1,
 *      otherFlag: ...
 *    }),
 *    otherAttr:...
 *  }
 * flagKey参数同O.bindFlag方法的flagKey参数, 默认使用this.module.flag
 */
export function mapFlag(flagDefs, flagKey) {
    /*
     * 绑定flag项到vue的计算属性中，可读写
     * computed:{ useFontIcon: O.bindFlag(0x1), otherAttr:... }
     * flagKey不传默认使用this.module.flag作为flag, 传'moduleConf.flag1'为this.moduleConf.flag1
     */
    function bindFlag(bit, flagKey = 'module.flag') {
        return operMultiLevelObjectAttr(
            flagKey,
            function (key) {
                // get
                return getBitMemory(this[key], bit);
            },
            function (key, newVal) {
                // set
                if (!this.flagChangeList) {
                    this.flagChangeList = [];
                }
                this.flagChangeList.push({
                    bit,
                    value: newVal,
                });

                this[key] = setBitMemory(this[key], bit, newVal);
            }
        );
    }
    const flagComputeds = {};
    for (const [key, bit] of Object.entries(flagDefs)) {
        flagComputeds[key] = bindFlag(bit, flagKey);
    }
    return flagComputeds;
}

export const getCookieFlag = function (bit) {
    if (bit >= 32) {
        alert('参数错误');
        return;
    }
    var siteFlag = Fai.Cookie.get('_mobiFlag');
    if (siteFlag == null) {
        siteFlag = 0;
    } else {
        siteFlag = parseInt(siteFlag);
    }
    return (siteFlag & (1 << bit)) != 0 ? true : false;
};

export const setCookieFlag = function (bit, bool) {
    if (bit >= 32) {
        alert('参数错误');
        return;
    }
    var siteFlag = Fai.Cookie.get('_mobiFlag');
    if (siteFlag == null) {
        siteFlag = 0;
    } else {
        siteFlag = parseInt(siteFlag);
    }
    if (bool) {
        siteFlag |= 1 << bit;
    } else {
        siteFlag &= ~(1 << bit);
    }
    var days = 30 * 12 * 5;
    var exp = new Date();
    exp.setTime(exp.getTime() + days * 24 * 60 * 60 * 1000);
    Fai.Cookie.set('_mobiFlag', siteFlag, exp);
};
