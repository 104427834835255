/**
 * =================================================
 *
 * State
 *
 * =================================================
 */
const state = () => ({
    popupZoneId: 0, // 当前弹窗容器id
    renderCount: 0,
});

const getters = {};

/**
 * =================================================
 *
 * actions
 *
 * =================================================
 */
const actions = {};

/**
 * =================================================
 *
 * mutations
 *
 * =================================================
 */
const mutations = {
    updatePopupZoneId(state, payload) {
        state.popupZoneId = payload;
    },
    updatePopupZoneRenderCount(state) {
        state.renderCount++;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
