<template>
    <member-sign-up :module-id="moduleId"></member-sign-up>
</template>
<script>
import MemberSignUp from './module/index.vue';

export default {
    name: 'MemberSignupVisitor',
    components: {
        MemberSignUp,
    },
    props: {
        moduleId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {};
    },
};
</script>
