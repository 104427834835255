<template>
    <component :is="component" :first-group-list="firstGroupList" :select-group="selectGroup" />
</template>

<script>
import HorizonalNav from './horizonal-nav.vue';
import VerticalNav from './vertical-nav.vue';
export default {
    name: 'ProductGroupDetailNav',
    components: {
        HorizonalNav,
        VerticalNav,
    },
    props: {
        horizonal: {
            type: Boolean,
            default: false,
        },
        firstGroupList: {
            type: Array,
            default: () => [],
        },
        selectGroup: {
            type: Number,
            default: -1,
        },
    },
    computed: {
        component() {
            return this.horizonal ? HorizonalNav : VerticalNav;
        },
    },
};
</script>

<style></style>
