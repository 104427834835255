(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vue"), require("@jz/biz-shared"), require("@jz/request"), require("@jz/utils"));
	else if(typeof define === 'function' && define.amd)
		define([, , , ], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("vue"), require("@jz/biz-shared"), require("@jz/request"), require("@jz/utils")) : factory(root["Vue"], root["bizShared"], root["jzRequest"], root["jzUtils"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, (__WEBPACK_EXTERNAL_MODULE__9789__, __WEBPACK_EXTERNAL_MODULE__73959__, __WEBPACK_EXTERNAL_MODULE__44139__, __WEBPACK_EXTERNAL_MODULE__31311__) => {
return 