var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isShowFloatZoneContainer || _vm.isEdit
    ? _c("float-effect-container", {
        attrs: { "components-table": _vm.componentsTable },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }