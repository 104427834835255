/**
 *   主要将产品详情，文章详情，图册详情使用ueditor设置的跳转 转换成mobi对应的栏目和链接地址
 *   因为这部分数据是site和mobi共享的
 **/

export class LinkSettingUtil {
    static switchJump() {
        switchJump();
    }
}

/**
 *   主要将产品详情，文章详情，图册详情使用ueditor设置的跳转 转换成mobi对应的栏目和链接地址
 *   因为这部分数据是site和mobi共享的
 **/
export function switchJump(parentClass) {
    var alist = jm(parentClass ? `${parentClass} .switchJump` : '.switchJump');
    // 文章(102)、文章分类(107)、产品(101)、产品分类(106)、图册(110)、图册分类(112)之间的数据是共用的，链接不能清空
    var commType = [101, 102, 107, 106, 110, 112];
    jm.each(alist, function (i, o) {
        if (i === 'length') {
            return;
        }
        var sJType = o.getAttribute('_t');
        var mJUrl = o.getAttribute('_mu') || o.getAttribute('mJUrl'); /*兼容旧格式*/
        var mColHide = o.getAttribute('_mColHide');
        var mhref = o.getAttribute('href');

        //栏目特殊情况
        if (sJType == 100 && mJUrl == null && mhref == null) {
            o.setAttribute('href', 'javascript:void(0);');
            o.setAttribute('style', 'text-decoration:none;');
            return;
        }
        //无特殊情况
        if (mJUrl == null && !mColHide) {
            return;
        }

        //表示site栏目的链接，不适用mobi端展示
        if (mColHide && commType.indexOf(parseInt(sJType)) == -1) {
            o.setAttribute('href', 'javascript:void(0);');
            o.setAttribute('style', 'text-decoration:none;');
            return;
        }
        //click方法
        if (mJUrl != null) {
            if (mJUrl.substring(0, 10) == 'javascript') {
                o.setAttribute('href', 'javascript:;');
                o.setAttribute('onclick', mJUrl.substring(11) + ';return false;');
            } else {
                //??
                o.setAttribute('href', mJUrl);
            }
        }
    });
    outerUrlJump();
}

export function outerUrlJump() {
    if (!(VITE_APP_MODE !== 'visitor')) {
        return;
    }
    $('#preview')
        .off('click.outerUrlJump')
        .on('click.outerUrlJump', 'a', function (e) {
            let $el = $(this);
            var url = $(this)[0].getAttribute('href');
            if ($el.hasClass('file_download_link')) {
                return;
            }
            if (url === null) return;

            var target = $(this)[0].getAttribute('target');
            // 在线视频链接可能会存在http或者https，不当做外部链接处理
            if (
                url.indexOf('Mobi.openLinkFlv') == -1 &&
                (url.indexOf('http') != -1 || url.indexOf('https') != -1) &&
                target != '_blank'
            ) {
                Fai.ing('管理态不支持跳转外部链接，请通过“预览”进行访问', true);
                e.preventDefault();
            }
        });
}
