var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showBackBtn || _vm.isShowHomeIcon || _vm.isShowHomePage
    ? _c(
        "div",
        {
          staticClass: "productDetailHeader",
          class: _vm.productDetailHeaderCls,
          style: _vm.mobiTipsStyle,
          attrs: { id: "productDetailHeader" + _vm.moduleId },
        },
        [
          _vm.showBackBtn
            ? _c("span", {
                staticClass:
                  "icon-gClose g_round g_iconMiddle newsBack icon-defaultColor",
                class: _vm.headerIconCls,
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isShowHomeIcon
            ? _c("a", {
                staticClass:
                  "icon-gHome g_round g_iconMiddle newsHome icon-defaultColor",
                class: _vm.headerIconCls,
                style: _vm.homeIconStyle,
                attrs: { href: _vm.linkLang + "/index.jsp" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isShowHomePage
            ? _c("span", {
                staticClass:
                  "icon-share g_iconMiddle g_round newsShare icon-defaultColor",
                attrs: { id: "newsDetailShare" },
              })
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }