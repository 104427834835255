var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mixNewsStyleLine newsLine picNum1 J_picNum",
      class: _vm.lineClass,
      attrs: {
        id: _vm.newsLineId,
        topClassname: _vm.topClassName,
        topSwitch: _vm.topSwitch,
        newsId: _vm.id,
        newsName: _vm.title,
        picnum: "3",
      },
    },
    [
      _c("div", { staticClass: "lineBody", attrs: { id: _vm.lineBodyId } }, [
        _c("div", { staticClass: "mixNewsStyleImgBox1" }, [
          _c(
            "a",
            {
              staticStyle: { width: "auto", height: "auto" },
              attrs: {
                hidefocus: "true",
                href: _vm.href,
                target: _vm.target,
                onclick: _vm.onclickStr,
              },
            },
            [
              !_vm.pictureId
                ? _c("div", { staticClass: "icon-noFigureM" })
                : _c("img", {
                    attrs: { alt: "文章附图", title: "", src: _vm.picturePath },
                  }),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mixNewsStyleTitleContainer" }, [
          _c("div", { class: _vm.topFlagClass }),
          _vm._v(" "),
          _c(
            "a",
            {
              class: [
                _vm.showLine ? "mixNewsStyleTitle1" : "mixNewsStyleTitle2",
              ],
              attrs: {
                hidefocus: "true",
                href: _vm.href,
                target: _vm.target,
                onclick: _vm.onclickStr,
              },
            },
            [
              _vm._v(
                "\n                " + _vm._s(_vm.title) + "\n            "
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "mixNewsStyleSummary",
            attrs: {
              hidefocus: "true",
              href: _vm.href,
              target: _vm.target,
              onclick: _vm.onclickStr,
            },
          },
          [_vm._v("\n            " + _vm._s(_vm.summary) + "\n        ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }