var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "module-frame",
    { attrs: { "module-id": _vm.moduleId } },
    [
      _vm.showFixTopHeadHeight
        ? _c("div", {
            staticStyle: { height: "2.25rem" },
            attrs: { id: "fixTopHeadHeight" },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "newsNewDetailPanel newsDetailV3Panel",
          class: _vm.headToolCls,
          attrs: { id: "newsNewDetailPanel" + _vm.moduleId },
        },
        [
          _c("news-detail-header"),
          _vm._v(" "),
          _c("news-detail-content", { attrs: { info: _vm.newsInfo } }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isOpen && _vm.allowSubmitMessage
        ? _c("div", { staticClass: "newsBottom J_newsBottom" }, [
            _vm.allowSubmitMessage
              ? _c(
                  "div",
                  {
                    staticClass: "postComment",
                    class: {
                      "not-allowed-button": _vm.allowSubmitMessage === false,
                    },
                    attrs: { id: "postComment" },
                  },
                  [
                    _c("span", { staticClass: "icon-postComm" }),
                    _vm._v(_vm._s(_vm.mobi_productPostComment)),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("comment"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }