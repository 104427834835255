<template>
    <div class="productNewGroup" :class="productNewGroupClasses" :_prUrl="prUrl">
        <template v-if="isMallTheme && productGroupType == 0">
            <product-group-detail-nav
                :horizonal="isHorizon"
                :first-group-list="firstGroupList"
                :select-group="selectGroup"
            />
            <product-group-detail-add-btn :horizonal="isHorizon" />
            <product-group-detail-panel
                :group-style="groupStyle"
                :first-group-list="firstGroupList"
                :pr-url="prUrl"
                :top-level="topLevel"
                :has-auth="hasAuth"
                :res-root="resRoot"
                :second-group-map="secondGroupMap"
                :select-group="selectGroup"
            />
        </template>
        <template v-else>
            <div class="firstGroup">
                <ul class="firstGroupList">
                    <li
                        v-for="firstGroup in firstGroupList"
                        :key="firstGroup.id"
                        class="firstGroupItem"
                        :class="[firstGroup.id === selectGroup ? 'checkedItem' : '']"
                        :data-id="firstGroup.id"
                    >
                        <p class="firstGroupName">{{ firstGroup.name }}</p>
                    </li>
                </ul>
            </div>
            <div class="unFirstGroup">
                <div class="hideScollBar">
                    <div
                        v-for="(collection, parentId) in secondGroupMap"
                        :id="'sed_' + parentId"
                        :key="parentId"
                        class="secondGroupPanel"
                        :class="[parentId == selectGroup ? 'checkedPanel' : '']"
                    >
                        <div v-for="(secondGroup, i) in collection" :key="secondGroup.id" class="secondGroupItem">
                            <p class="secondGroupTop">
                                <span
                                    class="topLeft"
                                    :style="{
                                        backgroundColor: getRandomColor(i),
                                    }"
                                ></span>
                                <a :href="secondGroup.url" class="topCenter">{{ secondGroup.name }}</a>
                            </p>
                            <div class="secndGroupCenter" :style="secondGroupCenterStyle">
                                <div
                                    v-for="thridGroup in secondGroup.thirdGroupList"
                                    :key="thridGroup.id"
                                    class="J_groupItem"
                                    :class="thridGroupItemClass"
                                    :data-id="thridGroup.id"
                                >
                                    <a
                                        v-if="isImageStyle"
                                        :href="thridGroup.url"
                                        target="_self"
                                        class="itemImageParent"
                                    >
                                        <template v-if="thridGroup.mIcon">
                                            <span
                                                v-if="thridGroup.isFontIconId"
                                                class="J_itemImage itemImage"
                                                :class="[thridGroup.iconPath]"
                                            >
                                            </span>
                                            <template v-else>
                                                <img
                                                    v-if="thridGroup.fileInfo"
                                                    class="J_itemImage itemImage"
                                                    :src="thridGroup.iconPath"
                                                    :data-w="thridGroup.fileInfo.width"
                                                    :data-h="thridGroup.fileInfo.height"
                                                />
                                                <span v-else class="J_itemImage notImage faisco-icons-pic"></span>
                                            </template>
                                        </template>
                                        <span v-else class="J_itemImage notImage faisco-icons-pic"></span>
                                    </a>
                                    <a v-else :href="thridGroup.url" target="_self" class="itemImageParent">
                                        <p class="itemName">{{ thridGroup.name }}</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import ProductGroupDetailNav from '../../components/nav.vue';
import ProductGroupDetailAddBtn from '../../components/nav-add-btn.vue';
import ProductGroupDetailPanel from '../../components/panel.vue';
import { initProductGroup } from '../scripts/initial';

import { mapState } from 'vuex';
import propsMixin from '../../mixins/props';
import { getUrlParamObj } from '@/shared/url';

export default {
    name: 'ProductGroupDetailAllGroupV1',
    components: {
        ProductGroupDetailNav,
        ProductGroupDetailAddBtn,
        ProductGroupDetailPanel,
    },
    mixins: [propsMixin],
    data() {
        return {
            selectGroup: -1,
        };
    },
    computed: {
        ...mapState('app', ['templatePresetIndex', 'resRoot']),
        productNewGroupClasses() {
            return this.isMallTheme
                ? `fk-proGroupThemeStyle fk-themeStyle${this.groupStyle} fk-mallColorStyle${this.templatePresetIndex}`
                : '';
        },
        secondGroupCenterStyle() {
            return this.isImageStyle
                ? {}
                : {
                      width: '98%',
                      paddingLeft: '2%',
                  };
        },
        thridGroupItemClass() {
            return this.isImageStyle ? 'thirdGroupImageItem' : 'thirdGroupTextItem';
        },
    },
    mounted() {
        this.inValidateSelectGroup();
        initProductGroup(this.groupStyle, this.productGroupType, VITE_APP_MODE !== 'visitor');
    },
    methods: {
        getRandomColor(index) {
            const colorArr = [
                '#fda100',
                '#7dcdfe',
                '#41c03f',
                '#c96dfc',
                '#69a9ff',
                '#ff68fd',
                '#ffda68',
                '#adc6ff',
                '#41c4d3',
                '#ff647a',
            ];
            return colorArr[index % 10];
        },
        inValidateSelectGroup() {
            let groupId = -1;
            if (VITE_APP_MODE !== 'visitor') {
                groupId = this.$router.query?.groupId ?? -1;
            } else {
                const params = getUrlParamObj(window.location.search);
                groupId = params.groupId || -1;
            }
            if (groupId == -1 && this.firstGroupList.length > 0) {
                groupId = this.firstGroupList[0].id;
            }
            this.selectGroup = parseInt(groupId, 10);
        },
    },
};
</script>

<style></style>
