// 纯手工拖拽控件，兼容PC和Mobi 2015-11-04 front
export const SimpleDrag = {
    __data: {},
    __func: {},
    __create: new Function(),
};

// 定义一个公共的数据池

// 控件对外接口
SimpleDrag.__create = function (target) {
    _bindEvent(target);
};

// 控件内部使用函数
function _bindEvent(target) {
    var moveObj = {};
    initMoveObj(moveObj, target);

    jm(target).css('transform', 'translateY(0px)');
    jm(target).off('.simpleDrag');
    jm(target)
        .on('mousedown.simpleDrag', function (event) {
            _startFunc(event, moveObj, this);
        })
        .on('touchstart.simpleDrag', function (event) {
            _startFunc(event, moveObj, this);
        })
        .on('mouseup.simpleDrag', function (event) {
            _endFunc(event, moveObj, this);
        })
        .on('touchend.simpleDrag', function (event) {
            _endFunc(event, moveObj, this);
        })
        .on('mousemove.simpleDrag', function (event) {
            _moveFunc(event, moveObj, this);
            event.preventDefault();
        })
        .on('touchmove.simpleDrag', function (event) {
            _moveFunc(event, moveObj, this);
            event.preventDefault();
        });
}

// 每次鼠标点击，改变点击开关，获取起点Y，如果拖拽内容高度超过可视区高度，则获取上一次偏移距离，并且缓存起来
function _startFunc(callEvent, obj, target) {
    obj.isClicking = true;
    obj.startY = getClientY(callEvent);

    if (obj.overflowHeight != 0) {
        obj.transformY = getTranslateY(target);
    }
}

// 每次鼠标松开，检查拖拽距离是否超出边界，如果超出边界，则位置等于边界。
function _endFunc(callEvent, obj, target) {
    obj.isClicking = false;
    obj.moveY = checkMoveY(obj);
    jm(target)
        .css('transform', 'translateY(' + obj.moveY + ')')
        .css('-webkit-transform', 'translateY(' + obj.moveY + 'px)');
    jm(target)
        .css('transition', 'transform 0.5s cubic-bezier(0.45, 0.05, 0.55, 0.95) 0s')
        .css('-webkit-transition', 'transform 0.5s cubic-bezier(0.45, 0.05, 0.55, 0.95) 0s');

    setTimeout(function () {
        jm(target).css('transition', '').css('-webkit-transition', '');
    }, 550);
}

// 每次移动鼠标，将起点Y减去当前Y得到拖拽的偏移距离。
// 如果拖拽内容高度超过可视区高度。获取偏移距离之后加上上一次偏移的距离。因为获取到的clientY都是相对于可视区域的
// 限制上下拖拽距离区间在150px。避免直接拖拽到不可见。
function _moveFunc(callEvent, obj, target) {
    if (obj.isClicking) {
        obj.moveY = getClientY(callEvent) - obj.startY;
        if (obj.overflowHeight != 0) {
            obj.moveY += obj.transformY;
        }

        if (obj.moveY > obj.moveOverflowRange) {
            obj.moveY = obj.moveOverflowRange;
        } else {
            if (obj.moveY < obj.overflowHeight * -1 - obj.moveOverflowRange) {
                obj.moveY = obj.overflowHeight * -1 - obj.moveOverflowRange;
            }
        }
        jm(target)
            .css('transform', 'translateY(' + obj.moveY + ')')
            .css('-webkit-transform', 'translateY(' + obj.moveY + 'px)');
    }
}

function initMoveObj(obj, target) {
    var parentHeight = parseInt(jm(target).parent().height()),
        targetHeight = parseInt(jm(target).height());

    obj.startY = 0;
    obj.moveY = 0;
    obj.isClicking = false;
    obj.overflowHeight = targetHeight - parentHeight;
    obj.transformY = getTranslateY(target);
    obj.moveOverflowRange = jm(jm(target).children()[0]).height();

    if (targetHeight < parentHeight) {
        obj.overflowHeight = 0;
    }
}

function checkMoveY(obj) {
    if (!obj.moveY || obj.moveY > 0) {
        return 0;
    }

    if (obj.moveY < 0) {
        if (obj.moveY * -1 >= obj.overflowHeight) {
            return obj.overflowHeight * -1;
        }
    }

    return obj.moveY;
}

function getClientY(e) {
    var clientY = e.clientY;
    if (e.touches) {
        clientY = e.touches[0].clientY;
    }

    return clientY;
}

function getTranslateY(target) {
    if (target.length == 0) {
        return 0;
    }
    var transformY = jm(target).css('transform');
    if (!transformY) {
        transformY = jm(target).css('-webkit-transform');
    }
    if (transformY == 'none') {
        transformY = 0;
    } else {
        transformY = transformY.replace(' ', '').replace('translateY(', '').replace(')', '');
        transformY = parseInt(transformY, 10);
    }

    if (!transformY) {
        transformY = 0;
    }

    return transformY;
}
