<template>
    <div class="productNewGroup fk-proGroupThemeStyle" :class="productNewGroupClasses" :_prUrl="prUrl">
        <product-group-detail-nav
            v-if="!hideNav"
            :horizonal="isHorizon"
            :first-group-list="firstGroupList"
            :select-group="selectGroup"
        />
        <product-group-detail-add-btn v-if="!hideNav && showAddBtn" :horizonal="isHorizon" />
        <product-group-detail-panel
            :group-style="groupStyle"
            :first-group-list="firstGroupList"
            :pr-url="prUrl"
            :top-level="topLevel"
            :horizonal="isHorizon"
            :has-auth="hasAuth"
            :res-root="resRoot"
            :second-group-map="secondGroupMap"
            :select-group="selectGroup"
            :panel-options="panelOptions"
        />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { GROUP_STYLE, MODULE_ID } from '../../constants';
import { initProductGroupV3 } from '../scripts/initial';
import ProductGroupDetailNav from '../../components/nav.vue';
import ProductGroupDetailAddBtn from '../../components/nav-add-btn.vue';
import ProductGroupDetailPanel from '../../components/panel.vue';
import propsMixin from '../../mixins/props';
import { getUrlParamObj } from '@/shared/url';

export default {
    name: 'ProductGroupDetailV3AllGroupView',
    components: {
        ProductGroupDetailNav,
        ProductGroupDetailAddBtn,
        ProductGroupDetailPanel,
    },
    mixins: [propsMixin],
    data() {
        return {
            moduleId: MODULE_ID,
            manageMode: VITE_APP_MODE !== 'visitor',
            selectGroup: -1,
        };
    },
    computed: {
        ...mapState('app', ['templatePresetIndex', 'resRoot']),
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        productNewGroupClasses() {
            return `fk-themeStyle${this.groupStyle} fk-mallColorStyle${this.templatePresetIndex}`;
        },
        hideNav() {
            return this.groupStyle === GROUP_STYLE.TEXT || this.groupStyle === GROUP_STYLE.ONE_LEVEL_HORIZON;
        },
        showAddBtn() {
            return VITE_APP_MODE !== 'visitor' && this.firstGroupList.length < 1 && this.hasAuth;
        },
    },
    watch: {
        'module.extInfo': {
            deep: true,
            async handler() {
                this.invalidateSelectGroup();
                await this.$nextTick();
                this.initDOM();
            },
        },
    },
    mounted() {
        this.invalidateSelectGroup();
        this.initDOM();
    },
    methods: {
        invalidateSelectGroup() {
            let groupId = -1;
            if (VITE_APP_MODE !== 'visitor') {
                groupId = this.$router.query?.groupId ?? -1;
            } else {
                const params = getUrlParamObj(window.location.search);
                groupId = params.groupId || -1;
            }
            if (groupId == -1 && this.firstGroupList.length > 0) {
                groupId = this.firstGroupList[0].id;
            }
            this.selectGroup = parseInt(groupId, 10);
        },
        initDOM() {
            initProductGroupV3(this.groupStyle, this.productGroupType, VITE_APP_MODE !== 'visitor');
        },
    },
};
</script>

<style></style>
