import { MobiModule } from '@/modules/Module';
import NewsMoreView from './news-more.vue';

class NewsMoreModule extends MobiModule {
    constructor() {
        super(null /** 系统模块没有style */, { system: true });
    }

    render() {
        return NewsMoreView;
    }
}

export const NewsMore = new NewsMoreModule();
