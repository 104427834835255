<template>
    <profile-version-three v-if="openThemeV3" :module-id="moduleId"></profile-version-three>
    <profile-version-two v-else :module-id="moduleId"></profile-version-two>
</template>
<script>
import { mapState } from 'vuex';
import ProfileVersionThree from './profileV3.vue';
import ProfileVersionTwo from './profile.vue';

export default {
    name: 'MemberProfile',
    components: {
        ProfileVersionThree,
        ProfileVersionTwo,
    },
    props: {
        moduleId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapState('app', ['openThemeV3']),
    },
};
</script>
<style lang="scss">
.mobiPrompt {
    position: absolute;
    margin: 0px auto;
    width: 100%;
    height: auto;
    z-index: 9998;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.mobiPrompt .mobiPrompt_inner {
    width: 13.85rem;
    margin: auto;
    line-height: 1rem;
    color: #333;
    z-index: 9999;
    border-radius: 5px;
    background: #fff;
    text-align: center;
    font-size: 0.75rem;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
    opacity: 0;
    transform: scale(0.7);
    transition: all 200ms linear;
}
.mobiPrompt .mobiPrompt_content {
    padding: 2.4rem 1.5rem 1.5rem;
    position: relative;
    line-height: 1.2rem;
}
.mobiPrompt .mobiPrompt_buttons {
    padding-bottom: 1rem;
}
.mobiPrompt .icon-mobiPromptClose {
    position: absolute;
    right: 0.2rem;
    top: 0.2rem;
}
.mobiPrompt .icon-mobiPromptClose:before {
    content: '\e66f';
    font-size: 1.2rem;
    color: #999;
}
.mobiPrompt .mobiPromptCancel {
    display: inline-block;
    width: 5.5rem;
    margin-right: 0.8rem;
    padding: 0.1rem 0;
    border-radius: 3px;
    border: 1px solid #d4d4d4;
    background: #fffeff;
    color: #666;
    font-size: 1.6rem;
}
.mobiPrompt .mobiPromptSave {
    display: inline-block;
    width: 5.5rem;
    padding: 0.1rem 0;
    border-radius: 3px;
    border: 1px solid #4786f2;
    background: #387af2;
    color: #eff2fd;
    font-size: 1.6rem;
}
.mobiPrompt_Bg {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #000;
    top: 0;
    left: 0;
    z-index: 9997;
    overflow: hidden;
    opacity: 0;
    transition: all 200ms linear;
}

.mobiPrompt .icon-PromptCancel:before {
    content: '\e670';
}
.mobiPrompt .icon-PromptSave:before {
    content: '\e777';
}

.g_locale2052 .mobiPrompt .icon-PromptCancel {
    padding: 0.5rem 0;
    font-size: 0.7rem;
}
.g_locale2052 .mobiPrompt .icon-PromptSave {
    padding: 0.5rem 0;
    font-size: 0.7rem;
}
.g_locale2052 .mobiPrompt .icon-PromptCancel:before {
    content: '取消';
    font-family: '\5FAE\8F6F\96C5\9ED1', Helvetica, '黑体', Arial, Tahoma;
}
.g_locale2052 .mobiPrompt .icon-PromptSave:before {
    content: '确认收货';
    font-family: '\5FAE\8F6F\96C5\9ED1', Helvetica, '黑体', Arial, Tahoma;
}
.g_locale2052 .mobiPrompt .icon-PromptCheckin:before {
    content: '确认入住';
    font-family: '\5FAE\8F6F\96C5\9ED1', Helvetica, '黑体', Arial, Tahoma;
}

.g_locale2052 .mobiPrompt .confirm.icon-PromptSave:before {
    content: '确定';
    font-family: '\5FAE\8F6F\96C5\9ED1', Helvetica, '黑体', Arial, Tahoma;
}
.g_locale2052 .mobiPrompt .goToPay.icon-PromptSave:before {
    content: '继续支付';
    font-family: '\5FAE\8F6F\96C5\9ED1', Helvetica, '黑体', Arial, Tahoma;
}
.g_locale2052 .mobiPrompt .giveUpJoin.icon-PromptCancel:before {
    content: '放弃参团';
    font-family: '\5FAE\8F6F\96C5\9ED1', Helvetica, '黑体', Arial, Tahoma;
}

.g_locale2052 .mobiPrompt .proceed.icon-PromptSave:before {
    content: '继续注册';
    font-family: '\5FAE\8F6F\96C5\9ED1', Helvetica, '黑体', Arial, Tahoma;
}
.g_locale2052 .mobiPrompt .loginInstantly.icon-PromptCancel:before {
    content: '立即登录';
    font-family: '\5FAE\8F6F\96C5\9ED1', Helvetica, '黑体', Arial, Tahoma;
}

.g_locale2052 .mobiPrompt .goSetting.icon-PromptSave:before {
    content: '去设置';
    font-family: '\5FAE\8F6F\96C5\9ED1', Helvetica, '黑体', Arial, Tahoma;
}

.g_locale2052 .mobiPrompt .acctlogin.icon-PromptSave:before {
    content: '我知道了';
    font-family: '\5FAE\8F6F\96C5\9ED1', Helvetica, '黑体', Arial, Tahoma;
}
.g_locale2052 .mobiPrompt .acctlogin.icon-PromptCancel {
    display: none;
}

.g_locale2052 .mobiPrompt .promRetreat.icon-PromptSave:before {
    content: '重新申请';
    font-family: '\5FAE\8F6F\96C5\9ED1', Helvetica, '黑体', Arial, Tahoma;
}
.g_locale2052 .mobiPrompt .promRetreat.icon-PromptCancel {
    display: none;
}

.jzm-vicon_warning {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.mobiPromptShow .mobiPrompt_inner {
    opacity: 1;
    transform: scale(1);
}

.mobiPreludeShow .mobiPrelude_inner {
    visibility: visible;
}

.mobiPromptBgShow {
    /*红米note自带浏览器，只有透明过渡时，需要滑动一下屏幕才看到效果，
  若加上高度过渡后，就不用滑动屏幕即可看到效果。
  原因不明。
  */
    opacity: 0.3;
    height: 101%;
}
/*个人资料退出登录的弹窗*/
.jz_themeV3 .mobiPromptShow .mobiPrompt_inner {
    width: 13.45rem;
}
.jz_themeV3 .mobiPrompt .mobiPrompt_content {
    min-height: 2.95rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: #333333;
    font-size: 0.7rem;
    border-bottom: 1px solid #e5e5e5;
    padding: 0.4rem 0.2rem 0.2rem 0.2rem;
    padding: 0.6rem 1.15rem;
    box-sizing: border-box;
}
.jz_themeV3 .mobiPrompt .mobiPrompt_buttons {
    height: 2.25rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.6rem;
}
.jz_themeV3 .mobiPrompt .mobiPromptCancel,
.jz_themeV3 .mobiPrompt .mobiPromptSave {
    border: none;
    background: none;
    margin: 0;
    width: 50%;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;
}
.jz_themeV3 .mobiPrompt .mobiPromptCancel {
    border-right: 1px solid #e5e5e5;
}
.jz_themeV3 .mobiPrompt .mobiPromptSave {
    color: #ff3b30;
}
</style>
