import { MODULE_STYLE } from '@/def/module';
import FormPanel from '@/modules/mobiForm/panel/form_edit_panel';
import { showTopManageFrame } from '@/shared/frame';

export default {
    name: 'onlineMap',
    items: [
        {
            text: '编辑表单',
            onClick(moduleId = '') {
                // const id = moduleId.split('module')[1] || '';
                const id = $(`#${moduleId}`).attr('module');
                Vue.prototype.$designer
                    .open({
                        panelType: 'mobiFormPopup',
                        styleId: MODULE_STYLE.SYS_FORM_POPUP,
                        moduleId: id,
                    })
                    .then(() => {
                        FormPanel.render();
                    });
            },
        },
        {
            display: 'icon',
            iconClass: 'viewData',
            text: '管理客户提交数据',
            title: '设置模块标题栏、边框等样式',
            onClick(moduleId = '') {
                const formId = $(`#${moduleId}`).attr('form');
                showTopManageFrame('manageFrameFormManage', Fai.top.backUrl, Fai.top.lanCode, `formid=${formId}`, true);
            },
        },
    ],
    positionType: 7, // 旧的是7，更奇怪。
};
