var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "product-group-detail-catalog-view",
    _vm._b({}, "product-group-detail-catalog-view", _vm.$attrs, false)
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }