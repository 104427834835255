<template>
    <div :id="id" class="profile_form_detail_edit">
        <div ref="formModule"></div>
    </div>
</template>

<script>
import { getOneFormInfoApi } from '@/api/form.js';
import { mapState } from 'vuex';
import { MODULE_STYLE } from '@/def/module';

const FORM_FLAGS = {
    FILE_UPLOAD_NEW_STYLE: 0x1,
    FORM_PROTOCOL: 0x10000,
};

export default {
    name: 'ProfileFormDetailEdit',
    data() {
        return {
            fakeModuleId: '',
            moduleInfo: {},
            renderOptions: {},
            formComponent: null,
        };
    },
    computed: {
        ...mapState('form', ['editingFormId', 'editingSubmitResultId']),
        id() {
            return `profileFormDetailEdit${this.editingFormId}`;
        },
    },
    watch: {
        editingFormId() {
            this.fetchFormModule();
        },
    },
    created() {
        this.fetchFormModule();
    },
    beforeDestroy() {
        this.formComponent.$destroy();
    },
    methods: {
        async fetchFormModule() {
            if (!this.editingFormId || this.editingFormId <= 0) return;

            const { data } = await getOneFormInfoApi({
                formId: this.editingFormId,
            });
            this.moduleInfo = data.module;
            this.renderOptions = data.renderOptions;

            this.initialModule();

            this.createFormModule();
        },
        initialModule() {
            // set moduleInfo 默认值
            // 接口共用表单弹窗，会将 styleId 渲染为表单弹窗 style, 这里需要更正为表单
            this.moduleInfo.style = MODULE_STYLE.MOBI_FORM;
            // 不开启抽奖
            this.moduleInfo.lotteryOpen = false;
            this.moduleInfo.isOpenLottery = false;

            // 文件上传使用新样式
            this.moduleInfo.flag |= FORM_FLAGS.FILE_UPLOAD_NEW_STYLE;
            // 不开启手机验证码
            this.moduleInfo.smsVerify = false;

            try {
                // 不开启隐私协议
                this.moduleInfo.content.fi.flag &= ~FORM_FLAGS.FORM_PROTOCOL;
                // 不自定义表单提交结果样式
                this.moduleInfo.content.fi.submitSuccessTipCustom = false;
                // 不关联抽奖
                this.moduleInfo.content.fi.associateLottery = false;
            } catch (error) {
                console.error(error);
            }
        },
        /**
         * 这里采用手动 new Vue 方式是因为 provide 无法动态响应，只能是同步传输初始值，
         * 需要等待接口返回数据才渲染模块
         */
        async createFormModule() {
            this.fakeModuleId = -1;
            const { componentsTable } = await import(
                /* webpackChunkName: 'componentTable' */ '@/modules/visitor-interface.js'
            );

            this.formComponent = new Vue({
                store: this.$store,
                provide: {
                    formId: this.editingFormId,
                    moduleInfo: this.moduleInfo,
                    renderOption: this.renderOptions,
                    inMemberDetailEdit: true,
                },
                render: (h) => {
                    return h(componentsTable[MODULE_STYLE.MOBI_FORM], {
                        props: {
                            moduleId: this.fakeModuleId,
                        },
                        on: {
                            'submit-success': () => {
                                this.$emit('submit-success', this.editingSubmitResultId);
                            },
                        },
                    });
                },
            }).$mount(this.$refs['formModule']);
        },
    },
};
</script>

<style lang="scss">
.profile_form_detail_edit {
    .module_content {
        background: #fff;
    }
}
</style>
