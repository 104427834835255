import { getPreviewObject } from '@/shared/getPreviewObject';
import { logDog } from '@/shared/log';

import { allFixWXPA } from '@/system/shared/mobiPATipFix';
import { mobiAlert } from '@/shared/tips';
export function unloadForATag() {
    if (!jm.os.supportsTouch) {
        jm('#g_body').delegate('#customerServiceDiv a', 'click', function () {
            var parentEl = this;
            var service = Fai.top._onlineServiceJson,
                curService;
            if (!parentEl) return;

            var type = parseInt(parentEl.getAttribute('id').substring(11));
            var href = jm(parentEl).attr('href') || '';
            if (parseInt(type) === 2 || parseInt(type) === 1) {
                if (href.indexOf('tel:') < 0 && href.indexOf('triggerServiceNumber') < 0 && href.indexOf('sms:') < 0) {
                    return;
                }

                if (VITE_APP_MODE !== 'visitor') {
                    for (var key in service) {
                        if (service[key].type == type) {
                            curService = service[key];
                            break;
                        }
                    }
                    // 若改了链接则不管了,因为改链接保存时已经校验
                    if (curService.baseSetting.jumpCtrl) {
                        var jumpType = curService.baseSetting.jumpCtrl.jumpType;
                        if (jumpType != 1 && jumpType != 2) {
                            return;
                        }
                    }
                }

                if (!!curService && parseInt(type) == 2 && curService.number == '') {
                    mobiAlert('请输入接受信息的电话');
                    onlineServiceClickCallBack(type, curService.typeStr);
                } else if (
                    !!curService &&
                    type == 1 &&
                    !!curService.phoneInfo &&
                    curService.phoneInfo[0].number == ''
                ) {
                    mobiAlert('请输入电话号码');
                    onlineServiceClickCallBack(type, curService.typeStr);
                } else {
                    mobiAlert('该功能无法在桌面浏览器中启动');
                }

                return false;
            } else if (href.indexOf('tel:') == 0) {
                mobiAlert('该功能无法在桌面浏览器中启动');
            }
        });
    }
}

export function onlineServiceInputWord() {
    var themeV3 = Fai.top._openThemeV3;
    // 管理态下加入这段逻辑，否则只出现在手机端就比较隐晦
    if (!(VITE_APP_MODE !== 'visitor') && !jm.os.supportsTouch) {
        return;
    }
    if (Fai.top._templateFrameId >= 2000 && Fai.top._templateFrameId < 3000 && !themeV3) {
        return;
    }

    // 这里获取的输入框元素也不全,不单单type='text'，还有type为email、number、tel、email这些都可以输入文本的，因此都需要优化，先暂缓处理。ken 20161118
    var inputArea = jm("input[type='text'], input[type='email'], input[type='tel'], input[type='number'], textArea"),
        serviceBox = themeV3
            ? document.getElementById('p_footer_nav_wrap')
            : document.getElementById('webCustomerServiceBox'),
        wxPublicAttentionService = jm('#wxPublicAttentionService'),
        wxPublicShowState = '',
        showBefore = '',
        hasService = false;

    if (serviceBox) {
        if (themeV3) {
            hasService = jm(serviceBox).hasClass('is_no_nav') ? false : true;
        } else {
            hasService = serviceBox.querySelectorAll('.service').length > 0 ? true : false; //是否有开启服务
        }
    }

    // 有开启服务才需要判断是否需要输入时隐藏服务
    if (!serviceBox || (!hasService && wxPublicAttentionService.length < 1)) return;

    inputArea.each(function (index, el) {
        jm(el)
            .off('blur.inputted')
            .off('focus.inputted')
            .on('blur.inputted', function () {
                serviceBox.style.display = showBefore;
                if (wxPublicAttentionService.length) {
                    wxPublicAttentionService[0].style.display = wxPublicShowState;
                }
            })
            .on('focus.inputted', function () {
                showBefore = jm(serviceBox).css('display');
                serviceBox.style.display = 'none';
                if (wxPublicAttentionService.length) {
                    wxPublicShowState = wxPublicAttentionService.css('display');
                    wxPublicAttentionService.hide();
                }
            });
    });
}

export function onlineServiceClickCallBack(type) {
    Fai.top.serviceNavManage.onlineServiceEditV2(type);
}

export function initOnlineServiceWidth() {
    if (Fai.top._openThemeV3) {
        return;
    }
    //20170503 【小需求】mobi-产品详情页面，如果不开商城，显示服务栏
    if (Fai.top._colId === 20) {
        if (Fai.top._bookingOpen || Fai.top._immediatelyConsultOpen || Fai.top._productFormOpen) {
            jm('#webCustomerServiceBox').css('display', 'none'); //开启预约功能后，底部服务要隐藏
            return;
        }
    }
    if (Fai.top._colId === 21 || Fai.top._colId === 28 || Fai.top._colId === 4) {
        if (Fai.top._newsCommentOpen) {
            jm('#webCustomerServiceBox').css('display', 'none'); //开启商城功能后，底部服务要隐藏
            return;
        }
    }
    if (Fai.top.independentSetFooterBar) {
        jm('#webCustomerServiceBox').css('display', 'none');
    }
    if (Fai.top._templateFrameId >= 2000 && Fai.top._templateFrameId < 3000) {
        return;
    }
    var customerServiceDiv = getPreviewObject('customerServiceDiv');
    if (!customerServiceDiv) {
        return;
    }
    var length = customerServiceDiv.querySelectorAll('.service').length;
    if (length === 0) {
        jm('#webCustomerServiceBox').css('opacity', 0);
        Fai.top._openOnlineService = false;
        Fai.top._onlineServiceJson.open = false;
    } else {
        jm('#webCustomerServiceBox').css('opacity', 1);
        Fai.top._openOnlineService = true;
        Fai.top._onlineServiceJson.open = true;
    }
    allFixWXPA(1);
    jm('#customerServiceDiv .service').css('width', 100 / length + '%');
    //jm("#customerServiceDiv .describe").css("width", (320/length-30) + "px");
    //数据统计
    jm('#customerServiceDiv .serviceClass_1')
        .off('click.log')
        .on('click.log', function () {
            //电话
            logDog(200171, 2);
        });
    jm('#customerServiceDiv .serviceClass_5')
        .off('click.log')
        .on('click.log', function () {
            //QQ
            logDog(200171, 1);
        });
    jm('#customerServiceDiv .serviceClass_-1')
        .off('click.log')
        .on('click.log', function () {
            //百度商桥
            logDog(200171, 3);
        });
}
