<template>
    <div v-if="render" :class="classList">
        <div id="webBodyBackground" class="webBackground webBackgroundFix" :style="webBodyBackgroundStyle"></div>
        <div id="navExistOffPanel" class="navExistOffPanel"></div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { COL, isWebDetailPage, isHiddenBackgroundColId } from '@/def/col';

export default {
    name: 'BackgroundBox',
    data() {
        return {};
    },
    computed: {
        ...mapState('backgroundSetting', ['backgroundImg']),
        ...mapState('app', ['colId', 'openThemeV3', 'templateDesignType']),
        resRoot() {
            return this.$store.state.app.resRoot || '';
        },
        backgroundType() {
            return this.backgroundImg.type;
        },
        backgroundSysNum() {
            return this.backgroundImg.sysnum;
        },
        backgroundFilePath() {
            return this.backgroundImg.filePath || this.backgroundImg._backgroundPreFilePath || '';
        },
        backgroundColor() {
            return this.backgroundImg.b_color;
        },
        backgroundSysPath() {
            return `${this.resRoot}/image/background/${this.backgroundSysNum}.jpg`;
        },
        classList() {
            return ['webBackgroundBox', this.webDetailPage, this.webProductNewGroupPage];
        },
        webDetailPage() {
            return isWebDetailPage(this.colId, this.templateDesignType, this.openThemeV3) ? 'webDetailPage' : '';
        },
        webProductNewGroupPage() {
            if (this.colId === COL.SYS_PRODUCT_NEW_GROUP) {
                return 'webProductNewGroupPage';
            }
            return '';
        },
        webBodyBackgroundStyle() {
            let style = {};
            if ((this.backgroundType == 0 && this.backgroundSysNum != 0) || this.backgroundType == 1) {
                style['background-image'] = `url(${
                    this.backgroundType == 1 ? this.backgroundFilePath : this.backgroundSysPath
                })`;
                style['background-size'] = 'cover';
                style['background-position'] = 'center';
            } else if (this.backgroundType == 2) {
                style['background'] = this.backgroundColor;
            }
            return style;
        },
        render() {
            return !isHiddenBackgroundColId(this.colId);
        },
    },
    watch: {
        backgroundImg: {
            handler() {
                if (VITE_APP_MODE !== 'visitor') {
                    // 移除生成的web吐出的style节点
                    $('#webBodyBackgroundStyle').remove();
                }
            },
        },
    },
};
</script>
<style lang="scss">
.webBackgroundFix {
    height: 100%;
    position: fixed;
    top: 0px;
    z-index: -1;
    width: 100%;
    max-width: 768px;
}
.navExistOffPanel {
    z-index: 9;
}
.navExistOffPanel {
    display: none;
    position: fixed;
    margin-left: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    width: 100%;
    max-width: 768px;
    z-index: 1;
    transition: all 300ms;
}
.webProductNewGroupPage {
    display: none;
}
</style>
