// @ts-check
import { MobiModule } from '@/modules/Module';
import NewsDetailV3Visitor from './visitor.vue';
import { MODULE_STYLE } from '@/def/module';

class NewsDetailV3Module extends MobiModule {
    constructor() {
        super(MODULE_STYLE.NEWS_DETAIL_V3, { system: true });
    }

    /** @override */
    render() {
        return NewsDetailV3Visitor;
    }
}

export const NewsDetailV3 = new NewsDetailV3Module();
