import { getFloatEffectInfo } from '@/api/floatEffect';
/**
 * =================================================
 *
 * State
 *
 * =================================================
 */
const state = () => ({
    it: 0,
    imgSrc: '',
    st: 1,
    ic: '',
    ict: 0,
    ibc: '',
    ibct: 0,
    ft: 0,
    ii: '',
    jumpInfo: {},
    jIde: '',
    isEdit: false,
    moduleId: -1,
    isInit: false,
    isRender: true, // 用来强制刷新组件位置
});

const getters = {};

/**
 * =================================================
 *
 * actions
 *
 * =================================================
 */
const actions = {
    loadFloatEffectInfo({ commit }) {
        return getFloatEffectInfo()
            .catch(function () {
                LS.ing(LS.js_systemError, false);
            })
            .then(({ data }) => {
                commit('updateFloatEffectInfo', data.data);
            });
    },
    loadFloatContainerInfo({ commit }, data) {
        // 没有初始化的悬浮容器直接返回
        if (Object.keys(data).length === 0) {
            return;
        }

        let { moduleInfo, moduleId } = data;

        // 更新模块数据
        commit('updateModule', moduleInfo, { root: true });

        moduleInfo.children.forEach((module) => {
            commit('updateModule', module, { root: true });
        });

        // 更新模块对应ID
        commit('floatEffect/updateModuleId', moduleId, { root: true });
    },
};

/**
 * =================================================
 *
 * mutations
 *
 * =================================================
 */
const mutations = {
    updateFloatEffectInfo(state, payload) {
        state.it = payload.it;
        state.imgSrc = payload.imgSrc;
        state.st = payload.st;
        state.ic = payload.ic;
        state.ict = payload.ict;
        state.ibc = payload.ibc;
        state.ibct = payload.ibct;
        state.ft = payload.ft;
        state.ii = payload.ii;
        state.jumpInfo = payload.jumpInfo;
        state.jIde = payload.jumpInfo.s ?? '';
    },
    updateFloatType(state, payload) {
        state.ft = payload;
    },
    updateIconType(state, payload) {
        state.it = payload;
    },
    updateIconId(state, payload) {
        state.ii = payload;
    },
    updateStyleType(state, payload) {
        state.st = payload;
    },
    updateIconColor(state, payload) {
        state.ic = payload;
    },
    updateIconColorType(state, payload) {
        state.ict = payload;
    },
    updateIconBackgroundColor(state, payload) {
        state.ibc = payload;
    },
    updateIconBackgroundColorType(state, payload) {
        state.ibct = payload;
    },
    updateJumpImgSrc(state, payload) {
        state.imgSrc = payload;
    },
    updateJumpInfo(state, payload) {
        state.jumpInfo = payload;
    },
    updateImgSrc(state, payload) {
        state.imgSrc = payload;
    },
    updateEditStatus(state, payload) {
        state.isEdit = payload;
    },
    updateModuleId(state, payload) {
        state.moduleId = payload;
    },
    updateIsInit(state, payload) {
        state.isInit = payload;
    },
    updateIsRender(state, payload) {
        state.isRender = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
