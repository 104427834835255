var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "productNewGroup",
      class: _vm.productNewGroupClasses,
      attrs: { _prUrl: _vm.prUrl },
    },
    [
      _vm.isMallTheme && _vm.productGroupType == 0
        ? [
            _c("product-group-detail-nav", {
              attrs: {
                horizonal: _vm.isHorizon,
                "first-group-list": _vm.firstGroupList,
                "select-group": _vm.selectGroup,
              },
            }),
            _vm._v(" "),
            _c("product-group-detail-add-btn", {
              attrs: { horizonal: _vm.isHorizon },
            }),
            _vm._v(" "),
            _c("product-group-detail-panel", {
              attrs: {
                "group-style": _vm.groupStyle,
                "first-group-list": _vm.firstGroupList,
                "pr-url": _vm.prUrl,
                "top-level": _vm.topLevel,
                "has-auth": _vm.hasAuth,
                "res-root": _vm.resRoot,
                "second-group-map": _vm.secondGroupMap,
                "select-group": _vm.selectGroup,
              },
            }),
          ]
        : [
            _c("div", { staticClass: "firstGroup" }, [
              _c(
                "ul",
                { staticClass: "firstGroupList" },
                _vm._l(_vm.firstGroupList, function (firstGroup) {
                  return _c(
                    "li",
                    {
                      key: firstGroup.id,
                      staticClass: "firstGroupItem",
                      class: [
                        firstGroup.id === _vm.selectGroup ? "checkedItem" : "",
                      ],
                      attrs: { "data-id": firstGroup.id },
                    },
                    [
                      _c("p", { staticClass: "firstGroupName" }, [
                        _vm._v(_vm._s(firstGroup.name)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "unFirstGroup" }, [
              _c(
                "div",
                { staticClass: "hideScollBar" },
                _vm._l(_vm.secondGroupMap, function (collection, parentId) {
                  return _c(
                    "div",
                    {
                      key: parentId,
                      staticClass: "secondGroupPanel",
                      class: [
                        parentId == _vm.selectGroup ? "checkedPanel" : "",
                      ],
                      attrs: { id: "sed_" + parentId },
                    },
                    _vm._l(collection, function (secondGroup, i) {
                      return _c(
                        "div",
                        { key: secondGroup.id, staticClass: "secondGroupItem" },
                        [
                          _c("p", { staticClass: "secondGroupTop" }, [
                            _c("span", {
                              staticClass: "topLeft",
                              style: {
                                backgroundColor: _vm.getRandomColor(i),
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "topCenter",
                                attrs: { href: secondGroup.url },
                              },
                              [_vm._v(_vm._s(secondGroup.name))]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "secndGroupCenter",
                              style: _vm.secondGroupCenterStyle,
                            },
                            _vm._l(
                              secondGroup.thirdGroupList,
                              function (thridGroup) {
                                return _c(
                                  "div",
                                  {
                                    key: thridGroup.id,
                                    staticClass: "J_groupItem",
                                    class: _vm.thridGroupItemClass,
                                    attrs: { "data-id": thridGroup.id },
                                  },
                                  [
                                    _vm.isImageStyle
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "itemImageParent",
                                            attrs: {
                                              href: thridGroup.url,
                                              target: "_self",
                                            },
                                          },
                                          [
                                            thridGroup.mIcon
                                              ? [
                                                  thridGroup.isFontIconId
                                                    ? _c("span", {
                                                        staticClass:
                                                          "J_itemImage itemImage",
                                                        class: [
                                                          thridGroup.iconPath,
                                                        ],
                                                      })
                                                    : [
                                                        thridGroup.fileInfo
                                                          ? _c("img", {
                                                              staticClass:
                                                                "J_itemImage itemImage",
                                                              attrs: {
                                                                src: thridGroup.iconPath,
                                                                "data-w":
                                                                  thridGroup
                                                                    .fileInfo
                                                                    .width,
                                                                "data-h":
                                                                  thridGroup
                                                                    .fileInfo
                                                                    .height,
                                                              },
                                                            })
                                                          : _c("span", {
                                                              staticClass:
                                                                "J_itemImage notImage faisco-icons-pic",
                                                            }),
                                                      ],
                                                ]
                                              : _c("span", {
                                                  staticClass:
                                                    "J_itemImage notImage faisco-icons-pic",
                                                }),
                                          ],
                                          2
                                        )
                                      : _c(
                                          "a",
                                          {
                                            staticClass: "itemImageParent",
                                            attrs: {
                                              href: thridGroup.url,
                                              target: "_self",
                                            },
                                          },
                                          [
                                            _c(
                                              "p",
                                              { staticClass: "itemName" },
                                              [_vm._v(_vm._s(thridGroup.name))]
                                            ),
                                          ]
                                        ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      )
                    }),
                    0
                  )
                }),
                0
              ),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }