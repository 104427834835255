<template>
    <div :id="'newsDetail' + moduleId" :class="themeTypeClass" class="newsDetail">
        <div v-if="isEmptyObject(info)" class="fk_newsDetailNotFound">{{ LS.newsDetailNotFound }}</div>
        <div v-else id="newsDetailPanel" :class="pageStyleCls">
            <div
                v-if="!options.hideNews"
                :id="'newsFigure' + moduleId"
                class="newsFigure"
                :class="newsFigureClass"
                alt="封面图"
                :src-original="picturePath"
                :style="!picturePath ? noFigureStyle : lazyLoadStyle"
            >
                <div v-if="isNewNewsDetail" class="newsInfoTitle" :class="newsInfoTitleCls">
                    <div :class="titleWrapClass" :style="titleWrapStyle">
                        <h1 class="textTitle" :style="titleH1Style">{{ info.title }}</h1>
                    </div>
                    <div v-if="showSubTitle" :class="subTitleClass" v-text="subTitle"></div>
                </div>
            </div>
            <div
                v-if="!(isNewNewsDetail && pageStyle == 0) && !options.hideNews"
                class="newsInfoTitle"
                :class="newsInfoTitleCls"
                :style="newsInfoTitleStyle"
            >
                <div :class="titleWrapClass">
                    <h1 class="textTitle" :style="titleH1Style">{{ info.title }}</h1>
                </div>
                <div v-if="showSubTitle" :class="subTitleClass" v-text="subTitle"></div>
            </div>
            <div class="newsContentPanel">
                <div
                    v-if="!options.hideNews && (showDate || showAuthor || showSource)"
                    class="newsCustomPanel"
                    :class="{ notNewNewsCustomPanel: !isNewNewsDetail }"
                >
                    <template v-if="isNewNewsDetail">
                        <div v-if="showDate" class="newsCustom newsDate">{{ newsDate }}</div>
                        <div v-if="showAuthor" class="newsCustom newsAuthor">{{ info.author }}</div>
                        <div v-if="showViews" class="newsCustom newsView"
                            ><span class="icon-views g_mainColor"></span>{{ info.views }}</div
                        >
                        <div
                            v-if="shareOn && options.templateDesignType == 2"
                            id="newsDetailV3Share"
                            class="icon-share newsShare"
                            ><span>{{ shareLs }}</span></div
                        >
                    </template>
                    <template v-else>
                        <div v-if="showAuthor" class="newsCustom newsAuthor">{{ info.author }}</div>
                        <div v-if="showDate" class="newsCustom newsDate">{{ newsDate }}</div>
                        <div v-if="showSource" class="newsCustom newsSource">{{ newsSourceLs }}{{ info.source }}</div>
                    </template>
                </div>
                <div
                    :class="{
                        blankBottom: noBlankBottom,
                        richContent: !isNewNewsDetail,
                        hide_news: options.hideNews,
                    }"
                    class="newsContent"
                    :style="bottomMargin"
                >
                    <template v-if="options.hideNews">
                        <div class="newsDetail_hide"
                            ><div class="newsDetail_hide--img"></div
                            ><p class="newsDetail_hide--text">{{ options.jz_newsAudit_tips }}</p></div
                        >
                    </template>
                    <html-render v-else :html="newsContent" ref-name="newsContent" />
                    <div v-if="!isExternal && module.copyrightNoticeOpen" class="copyright_notice_content">
                        <span>{{ module.copyrightNoticeContent }}</span>
                        <a target="_blank" class="copyright_notice_content_href" :href="module.newsLink">{{
                            module.newsLink
                        }}</a>
                    </div>
                    <pagenation
                        v-if="totalSize > 1"
                        :module-id="moduleId"
                        :total-size="totalSize"
                        :page-size="1"
                        :pageno="pageno"
                        :open-theme-v3="openThemeV3"
                        :ajax-pagenation="true"
                        @on-change="changePage"
                        @click.native.stop=""
                    >
                    </pagenation>
                </div>
                <div v-if="viewList.length > 0 && !options.hideNews" class="attachList">
                    <attach v-for="(item, index) in viewList" :key="'attach_' + index" :info="item" />
                </div>
                <template v-if="isNewNewsDetail">
                    <div v-if="showSource && info.source" class="newsSource">{{ newsSourceLs }}{{ info.source }}</div>
                    <div v-if="showLink" class="newsLink"
                        ><span v-html="newsLinkLs"></span><a :href="info.link">{{ info.link }}</a></div
                    >
                    <div v-if="showNewsGroup" class="newsGroup">
                        <span class="icon-group g_mainColor newsGroupIcon"></span>
                        <a
                            v-for="(item, index) in groupDataList"
                            :key="`groupItem_${index}`"
                            :href="item.href"
                            class="newsGroupTag g_mainColor"
                            target="_self"
                            >{{ item.name }}</a
                        >
                    </div>
                </template>
                <template v-else>
                    <div v-if="showNewsGroup" class="newsGroup">
                        <a
                            v-for="(item, index) in groupDataList"
                            :key="`groupItem_${index}`"
                            :href="item.href"
                            class="newsGroupTag"
                            target="_self"
                            >{{ item.name }}</a
                        >
                    </div>
                    <div v-if="showLink || showViews" :class="jumpLinkMargin" class="newsCustomPanel2">
                        <div v-if="showLink" class="newsCustom"
                            ><a :href="info.link">{{ originalLs }}</a></div
                        >
                        <div v-if="showViews" class="newsCustom read_count">{{ readLs }}{{ info.views }}</div>
                    </div>
                </template>
                <div v-if="!isNewNewsDetail && shareOn" id="newsDetailV3Share" class="icon-share newsShare"
                    ><span>{{ shareLs }}</span></div
                >
                <news-pagiation v-if="showPrevNext" :open-theme-v3="openThemeV3" />
            </div>
            <div v-if="openThemeV3 && !isOpen && shareOn" class="only_share">
                <span id="newsDetailShare" class="icon-share g_iconMiddle g_round newsShare icon-defaultColor"></span>
            </div>
            <template v-if="isClickImg">
                <image-viewer
                    v-if="showMobiViewer"
                    :z-index="zIndex"
                    :initial-index="initialIndex"
                    :on-close="onClose"
                    :url-list="images"
                    :is-mobi="true"
                    :content-terminal="'mobi'"
                    :manage-mode="manageMode"
                    :document-event-el="documentEventEl"
                    append-to-container-id="g_body"
                    :append-to-body="false"
                    :disable-long-press="banCopyTextImg"
                />
            </template>
            <div id="renderInNewsDetailComment"></div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { bindImgLazyLoad } from '@/shared/imageLazyload';
import { getPictureSlidesImgs } from '@/features/global-mobi-function/methods/share/index';
import { slidesshowMixins } from '@/modules/moduleMixins';
import { getNewForCommentsInfo } from '@/modules/shared/comment';
import { newsDetailMixins } from '../mixins';
import { COL } from '@/def/col';

import pagenation from '@/components/pagenation.vue';
import attach from './attach.vue';
import newsPagiation from './pagination.vue';
import htmlRender from '@/components/html-render/index.vue';
import { getBitMemory } from '@/shared/flag';
export default {
    name: 'Content',
    components: {
        pagenation,
        attach,
        newsPagiation,
        htmlRender,
    },
    mixins: [slidesshowMixins, newsDetailMixins],
    inject: ['moduleId', 'openThemeV3', 'manageMode', 'style'],
    props: {
        info: {
            type: Object,
            default() {
                return {};
            },
        },
        version: {
            type: Number,
            default: 3,
        },
    },
    data() {
        return {
            pageno: 1,
            images: [],
            commentPageno: 1,
        };
    },
    computed: {
        ...mapState('app', ['LS', 'colId', 'realNameAuth']),
        isNewNewsDetail() {
            return this.version == 2;
        },
        isNewNewsDetailPageStyle1() {
            return this.isNewNewsDetail && this.pageStyle == 1;
        },
        isNewsDetailV3() {
            return !this.openThemeV3 && !this.isNewNewsDetail;
        },
        themeTypeClass() {
            let cls = [];
            if (this.openThemeV3) {
                return 'fk_mallNewDetail';
            }
            this.isNewNewsDetail && cls.push('J_newsNewDetail');
            cls.push(this.options.templateDesignType == 2 ? 'fk_mallNewDetail' : 'fk_notMallThemeNewDetail');
            return cls;
        },
        pageStyle() {
            // 页面样式
            return this.content.pageStyle;
        },
        titleWrap() {
            return this.content.tw;
        },
        subTitleClass() {
            let classList = ['subTitle'];
            if (this.titleWrap == 2) {
                classList.push('subTitleNoWrap');
            } else if (this.titleWrap == 0) {
                classList.push('subTitleNormal');
            }
            return classList;
        },
        detailBottomMargin() {
            return this.content.hdm;
        },
        isClickImg() {
            return this.isNewNewsDetail || this.content.ci == 0;
        },
        isOpen() {
            return this.options.isOpen || false;
        },
        pageStyleCls() {
            if (!this.openThemeV3) {
                return '';
            }
            return `pageStyle${this.pageStyle}`;
        },
        picturePath() {
            return this.options.picturePath;
        },
        loadingPath() {
            return this.options.loadingPath;
        },
        newsDate() {
            return this.options.newsDate;
        },
        groupDataList() {
            return this.options.groupDataList || [];
        },
        viewList() {
            return this.options.viewList;
        },
        isFixImgScale() {
            return this.openThemeV3 && this.info.aid == 15904459;
        },
        f_height() {
            return this.firstPageSize.height;
        },
        f_width() {
            return this.firstPageSize.width;
        },
        noFigureStyle() {
            let pageStyle = this.pageStyle;
            let isFixImgScale = this.isFixImgScale;
            let f_height = this.f_height;
            let f_width = this.f_width;
            return (
                (pageStyle == 1 ? 'display:none;' : '') +
                ' width:100%;height:' +
                (isFixImgScale ? 16 * ((f_height * 1.0) / f_width) : Math.ceil(16 * ((f_height * 1.0) / f_width))) +
                'rem;opacity:0.9'
            );
        },
        lazyLoadStyle() {
            let pageStyle = this.pageStyle;
            let f_height = this.f_height;
            let f_width = this.f_width;
            let loadingPath = this.loadingPath;
            return (
                (pageStyle == 1 ? 'display:none;' : '') +
                ' width:100%;height:' +
                16 * ((f_height * 1.0) / f_width) +
                'rem; background-image:url(' +
                loadingPath +
                ')'
            );
        },
        newsInfoTitleCls() {
            if (this.isNewNewsDetail) {
                return this.pageStyle == 1 && !this.options.indepentheadShow ? 'newsInfoTitleStyle' : '';
            }
            let textStyleStr =
                this.openThemeV3 || !this.options.indepentheadShow ? 'title-textStyle' : 'title-textStyleFix';
            return ['title-imgStyle', textStyleStr][this.pageStyle];
        },
        newsInfoTitleStyle() {
            let style = this.isNewNewsDetailPageStyle1 ? { position: 'relative', background: 'none' } : {};
            if (VITE_APP_MODE !== 'visitor') {
                let titleStyle = this.content.titleStyle;
                let boldVal = this.openThemeV3 ? 0 : 1;
                let _style = {
                    fontSize: `${titleStyle.s}px`,
                    textAlign: titleStyle.a == 1 ? 'center' : 'left',
                    fontWeight: titleStyle.w == boldVal ? 'bold' : 'normal',
                };
                titleStyle.fct == 1 && (_style.color = titleStyle.c);
                style = { ...style };
                if (titleStyle.t == 1) {
                    style = Object.assign({}, style, _style);
                }
            }
            return style;
        },
        titleWrapStyle() {
            let style = {};
            if (this.titleWrap == 0) {
                style.height = '2rem';
            }
            style.lineHeight = '1rem';
            style.wordWrap = 'break-word';
            style.whiteSpace = 'normal';
            style.overflow = 'hidden';
            style.textOverflow = 'ellipsis';
            return style;
        },
        titleWrapClass() {
            return [
                this.isNewNewsDetail ? 'g_displayClamp2' : 'g_displayClamp2 displayClamp2Style',
                'titleWrap',
                'titleNoWrap',
            ][this.titleWrap];
        },
        showDate() {
            return this.pageElementList.showDate == 0 && this.newsDate && this.newsDate.length > 0;
        },
        showAuthor() {
            return this.pageElementList.showAuthor == 0 && this.info.author && this.info.author.length > 0;
        },
        showSource() {
            return this.pageElementList.showSource == 0;
        },
        showGroup() {
            return this.pageElementList.showGroup == 0;
        },
        showLink() {
            return this.pageElementList.showLink == 0;
        },
        showViews() {
            return this.pageElementList.showViews == 0;
        },
        showPrevNext() {
            return this.pageElementList.showPrevNext == 0;
        },
        shareOn() {
            return this.content.ss == 0;
        },
        openNewsSubTitle() {
            return this.options.openNewsSubTitle || false;
        },
        showSubTitle() {
            return this.openNewsSubTitle && this.pageElementList.showSubTitle == 1;
        },
        noBlankBottom() {
            let showBottom = this.showNewsGroup || this.showLink || this.showViews;
            return showBottom || this.openThemeV3 ? this.shareOn : this.shareOn && this.options.templateDesignType == 2;
        },
        bottomMargin() {
            return this.detailBottomMargin == 1 ? `padding-bottom:0;margin-bottom:${this.content.bm}rem;` : '';
        },
        newsContentArray() {
            return this.options.newsContent.replace(/<video/g, "<video preload='meta'").split('<_fainp/>');
        },
        totalSize() {
            return this.newsContentArray.length;
        },
        newsContent() {
            return this.newsContentArray[this.pageno - 1];
        },
        showNewsGroup() {
            return this.showGroup && this.groupDataList.length > 0;
        },
        jumpLinkMargin() {
            return this.pageElementList.showPrevNext != 0 && !this.isOpen && this.shareOn ? 'jumpLinkMargin' : '';
        },
        titleH1Style() {
            return this.openThemeV3
                ? {}
                : {
                      font: 'inherit',
                      color: this.isNewNewsDetailPageStyle1 ? '#333' : 'inherit',
                      background: 'inherit',
                  };
        },
        newsSourceLs() {
            return this.LS.newsSource;
        },
        originalLs() {
            return this.LS.original;
        },
        newsLinkLs() {
            return this.LS.newsLink;
        },
        readLs() {
            return this.LS.read;
        },
        shareLs() {
            return this.LS.share;
        },
        newsFigureClass() {
            let classList = [];
            if (!this.picturePath) {
                classList.push('icon-noFigure');
            } else {
                classList.push('J_img_lazyload');
            }
            if (!this.isNewsDetailV3) {
                classList.push('g_bgColor');
            }
            return classList;
        },
        isExternal() {
            const flag = this.options.newsInfo.flag ?? 0;
            return getBitMemory(flag, 0x80);
        },
    },
    watch: {
        newsContent() {
            this.initImgEvent();
        },
        newsId(id) {
            this.getComments(id);
        },
    },
    mounted() {
        this.initImgEvent();
        this.bindImgSlide();
        this.getComments();
    },
    methods: {
        isEmptyObject(obj) {
            var name;
            for (name in obj) {
                return false;
            }
            return true;
        },
        changePage(pageno) {
            this.pageno = pageno;
        },
        photoSlidesshow(index) {
            this.$modulesEventBus.$emit(`newsDetail-slidesshow${this.moduleId}`, index);
        },
        refreshSildeImg() {
            this.images = getPictureSlidesImgs(`#newsDetail${this.moduleId} img`);
        },
        initImgEvent() {
            // 懒加载、幻灯片
            this.$nextTick(() => {
                bindImgLazyLoad(`module${this.moduleId}`);
                this.refreshSildeImg();
            });
        },
        bindImgSlide() {
            let $dom = $(`#module${this.moduleId} .newsContent`);
            let _this = this;
            $dom.off('click.img');
            if (!this.isClickImg) {
                return;
            }
            $dom.on('click.img', 'img', function (event) {
                let $target = $(event.target);
                let $parent = $target.parent();
                //如果图片被设置了链接则不触发幻灯片
                const href = $parent.attr('href') ? $parent.attr('href').trim() : '';
                if (
                    (href.length > 0 && !href.startsWith('javascript:void(0)')) ||
                    ($parent.attr('onclick') && $parent.attr('onclick').trim().length > 0)
                ) {
                    return;
                }
                let index = $target.attr('data-picid').replace('photoswipe_', '') || 0;
                _this.photoSlidesshow(parseInt(index));
            });
        },
        getComments(id = this.newsId) {
            if (!this.isOpen || this.options.hideNews) {
                return;
            }
            if (this.colId == COL.SYS_NEWS_NEW_DETAIL) {
                // SYS_NEWS_NEW_DETAIL 先跟旧版一样，没展示评论。
                return;
            }

            if (this.realNameAuth.allowShowMessage === false) {
                return;
            }

            getNewForCommentsInfo({
                id,
                pageno: this.commentPageno,
                moduleId: this.moduleId,
                isV3: true,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../newsDetail.scss';
.textTitle {
    font: inherit;
    color: inherit;
    line-height: initial;
    background: inherit;
}
.newsContent.richContent.hide_news {
    margin: 4.56rem auto 1.55rem;
    .newsDetail_hide--img,
    .newsDetail_hide--text {
        margin: 0;
    }
    .newsDetail_hide--img {
        margin: 0 auto 1.55rem;
    }
}
.jz_themeV3 .only_share {
    box-sizing: border-box;
    z-index: 1;
}
.module27 {
    .subTitle {
        color: #808080;
        font-size: 0.6rem;
        line-height: 1rem;
        font-weight: normal;
        margin-top: 0.5rem;
    }
    .subTitleNormal {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        word-break: normal;
    }
    .copyright_notice_content {
        margin-top: 1.8rem;

        font-weight: 400;
        font-size: 0.6rem;
        color: #8a8a8a;

        &_href {
            color: #5070b2;
            cursor: pointer;
            text-decoration: none;
            word-break: break-all;
        }
    }
}
.newsCustomPanel.notNewNewsCustomPanel.notNewNewsCustomPanel {
    height: initial;
    line-height: initial;
    flex-wrap: wrap;
    line-height: 1.75;
    .newsAuthor {
        flex-shrink: initial;
    }
    .newsCustom {
        height: initial;
        line-height: inherit;
        max-width: initial;
        text-overflow: initial;
        overflow: initial;
        white-space: initial;
    }
}
</style>
