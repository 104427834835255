<template>
    <float-effect />
</template>

<script>
import FloatEffect from './floatEffect.vue';

export default {
    name: 'FloatEffectIconVisitor',
    components: {
        FloatEffect,
    },
};
</script>

<style></style>
