/**
 * 过滤树中的节点, 返回原来的树
 * @param {object} node - head node
 * @param {Function} filter - filter function
 * @returns {object} original head node
 */
export function tree_filter(node, filter) {
    let valid = filter(node);
    if (valid) {
        if (node.children) {
            let newChildren = [];
            for (let child of node.children) {
                let newChild = tree_filter(child, filter);
                if (newChild !== null) newChildren.push(newChild);
            }
            node.children = newChildren;
        }
    } else {
        return null;
    }

    return node;
}
