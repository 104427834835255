export const getThemeColor = () => {
    const manageMode = VITE_APP_MODE !== 'visitor';
    if (Fai.top._openThemeV3) {
        return manageMode ? Fai.top.$store.state.manage.fullsiteComputedColor : Fai.top._themeColor;
    } else {
        if (Fai.top._other.templateInfo.templateDesignType == 1) {
            return Fai.top._colorFulThemeColor;
        } else if (Fai.top._other.templateInfo.templateDesignType == 2) {
            return Fai.top._mallThemeColor;
        }
    }
    return Fai.top._themeColor;
};
