var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isBannerShow && _vm.render
    ? _c(
        "div",
        {
          staticClass: "webBanner",
          class: _vm.bannerCls,
          style: _vm.bannerStyle,
          attrs: { id: "webBanner" },
        },
        [
          _vm.moduleData
            ? _c(
                "div",
                {
                  staticClass: "swipe bannerSwipe",
                  attrs: {
                    id: "bannerSwipe",
                    "data-size": _vm.bannerlist.length,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "bannerSwipeContainer defaultBanner",
                      attrs: { id: "bannerSwipeContainer" },
                    },
                    _vm._l(_vm.bannerlist, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: item.ide + index,
                          attrs: { id: "bannerItem" + item.ide },
                        },
                        [
                          _c("a", {
                            staticClass: "J_img_lazyload bannerImageDiv",
                            class:
                              item.t === 0 ? "systemtBanner" : "customBanner",
                            attrs: {
                              rel: item.nf ? "nofollow" : void 0,
                              href:
                                item.lt !== 0
                                  ? _vm.getJumpUrl(item)
                                  : "javascript:;",
                              "src-original": item.p,
                              vheight: item.h,
                              vwidth: item.w,
                            },
                          }),
                          _vm._v(" "),
                          _vm.showDesc
                            ? _c("div", { staticClass: "bannerDescription" }, [
                                _c("div", { staticClass: "description" }, [
                                  _vm._v(_vm._s(item.des)),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: _vm.showDesc ? "bannerBullet2" : "bannerBullet",
                      attrs: { id: "bannerBullet" },
                    },
                    [
                      _c(
                        "ul",
                        { staticClass: "bullets", attrs: { id: "bullets" } },
                        _vm._l(_vm.bannerlist, function (item, index) {
                          return _c("li", {
                            key: item.id,
                            class: index === 0 ? "on" : "",
                          })
                        }),
                        0
                      ),
                    ]
                  ),
                ]
              )
            : _c("loading"),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }