var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "timeAxisNewsStyle newsLine J_picNum",
      class: _vm.lineClass,
      attrs: {
        id: _vm.newsLineId,
        topClassname: _vm.topClassName,
        topSwitch: _vm.topSwitch,
        newsId: _vm.id,
        newsName: _vm.title,
        picnum: "6",
      },
    },
    [
      _c("div", { staticClass: "lineBody", attrs: { id: _vm.lineBodyId } }, [
        _c(
          "a",
          {
            attrs: {
              hidefocus: "true",
              href: _vm.href,
              target: _vm.target,
              onclick: _vm.onclickStr,
            },
          },
          [
            _c("div", { staticClass: "mixNewsStyleImgBox7" }, [
              _c("div", { staticClass: "mixNewsStyleImgBox7_left" }, [
                _c("div", { staticClass: "mixNewsStyleTime" }, [
                  _vm._v(_vm._s(_vm.formatDate(_vm.newsDate))),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: [
                      _vm.showLine
                        ? "mixNewsStyleTitle1"
                        : "mixNewsStyleTitle2",
                    ],
                  },
                  [
                    _c("div", { class: _vm.topFlagClass }),
                    _vm._v(_vm._s(_vm.title) + "\n                    "),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mixNewsStyleImgBox7_middleCicle" }),
              _vm._v(" "),
              _c("div", { staticClass: "mixNewsStyleImgBox7_right" }, [
                _c(
                  "div",
                  {
                    staticClass: "newsTitlePic J_newsTitlePic",
                    attrs: { id: "newsTitlePic", alt: _vm.alt },
                  },
                  [
                    _c(
                      "svg",
                      { attrs: { xmlns: "http://www.w3.org/2000/svg" } },
                      [
                        _c("defs", [
                          _c("clipPath", { attrs: { id: "clippath" } }, [
                            _c("polygon", { attrs: { points: "" } }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("image", {
                          staticStyle: { "clip-path": "url(#clippath)" },
                          attrs: {
                            "xmlns:xlink": "http://www.w3.org/1999/xlink",
                            "xlink:href": _vm.tmpPicturePath,
                            preserveAspectRatio: "none",
                            alt: _vm.alt,
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }