import { MobiModule } from '@/modules/Module';
import locater from './module/locater.vue';

class _Locater extends MobiModule {
    constructor() {
        super(null /** 系统模块没style */, {
            system: true,
        });
    }

    render() {
        return locater;
    }
}

export const Locater = new _Locater();
