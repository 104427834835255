var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.openThemeV3
    ? _c("profile-version-three", { attrs: { "module-id": _vm.moduleId } })
    : _c("profile-version-two", { attrs: { "module-id": _vm.moduleId } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }