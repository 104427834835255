var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "float_effect", on: { click: _vm.jumpClickHandler } },
    [
      _vm.styleType === 1
        ? _c(
            "div",
            {
              staticClass:
                "float_effect_style float_effect_style_1 g_main_bgColor_v3 g_oldThemeBgColor",
              style: _vm.iconBgStyle,
            },
            [
              _vm.iconType !== 3
                ? _c(
                    "svg",
                    { staticClass: "preview_icon", style: _vm.iconStyle },
                    [_c("use", { attrs: { "xlink:href": _vm.svgIconId } })]
                  )
                : _vm.imgSrc
                ? _c("img", {
                    staticClass: "preview_icon",
                    attrs: { src: _vm.imgSrc },
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.styleType === 2
        ? _c(
            "div",
            {
              staticClass:
                "float_effect_style float_effect_style_2 g_main_bgColor_v3 g_oldThemeBgColor",
              style: _vm.iconBgStyle,
            },
            [
              _vm.iconType !== 3
                ? _c(
                    "svg",
                    { staticClass: "preview_icon", style: _vm.iconStyle },
                    [_c("use", { attrs: { "xlink:href": _vm.svgIconId } })]
                  )
                : _vm.imgSrc
                ? _c("img", {
                    staticClass: "preview_icon",
                    attrs: { src: _vm.imgSrc },
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.styleType === 3
        ? _c(
            "div",
            {
              staticClass: "float_effect_style float_effect_style_3",
              style: _vm.iconBgStyle,
            },
            [
              _vm.iconType !== 3
                ? _c(
                    "svg",
                    { staticClass: "preview_icon", style: _vm.iconStyle },
                    [_c("use", { attrs: { "xlink:href": _vm.svgIconId } })]
                  )
                : _vm.imgSrc
                ? _c("img", {
                    staticClass: "preview_icon",
                    attrs: { src: _vm.imgSrc },
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }