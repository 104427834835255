<template>
    <component :is="header"></component>
</template>

<script>
import { mapState } from 'vuex';
import HeaderV3 from './v3/index.vue';
import HeaderV2 from './v2/index.vue';
export default {
    name: 'WebHeader',
    data() {
        return {};
    },
    computed: {
        ...mapState('app', ['openThemeV3']),
        header() {
            return this.openThemeV3 ? HeaderV3 : HeaderV2;
        },
    },
};
</script>

<style></style>
