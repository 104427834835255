import { setModuleHeight2 } from '@/manage/utils/module';
import { NEW_MANAGE_HTML_FONTSIZE } from '@/shared/constants';

export function initForms() {
    var webModuleContainer = document.querySelector('#webModuleContainer'),
        moduleForms = webModuleContainer.querySelectorAll('.form');

    for (var i = 0; i < moduleForms.length; i++) {
        var module = moduleForms[i],
            id = module.getAttribute('id');
        if (id) {
            id = id.replace('module', '');
            if (
                module.getAttribute('_autoHeight') != 1 &&
                !(
                    jm(module).hasClass('formStyle52') ||
                    jm(module).hasClass('formStyle53') ||
                    jm(module).hasClass('formStyle35')
                )
            ) {
                const fontsize =
                    window.moduleManageFontSize && window.moduleManageFontSize['module' + id]
                        ? window.moduleManageFontSize['module' + id]
                        : NEW_MANAGE_HTML_FONTSIZE;
                //浮动图片
                setModuleHeight2(id, module.clientHeight, fontsize);
            }
        }
    }
}
