function getPortalHost() {
    var portalHost = Fai.top._portalHost;
    if (!portalHost) {
        //兜底处理 by jser
        if (Fai.top._oem) {
            portalHost = 'i.jz.huaweicloudsite.com';
        } else {
            portalHost = `${window.Hosts.portal}`;
        }
    }

    return portalHost;
}

export function getPurchaseVersionUrl(version, extQuery) {
    version = version || -1;
    // 补足空字符
    if (extQuery === undefined) {
        extQuery = '';
    }
    // 字符补足
    if (extQuery !== '' && extQuery[0] !== '&') {
        extQuery = '&' + extQuery;
    }
    // 拼接查询字符
    var queryArr = ['t=40', '&siteVersion=' + version, '&buySiteVer=true', '&siteSid=' + Fai.top._siteId, extQuery];
    var queryString = '?' + queryArr.join('');

    return '//' + getPortalHost() + '/jump.jsp' + queryString;
}
/**
 *
 * getQurl把now 转换成pre，后台才能直接从search部分获取到对应参数，目前pathname和hash部分未拼接，先观察一下
 * @example
 *  const pre =  "http://ivychen-11.jzm.fff.com/pd.jsp?mid=21103&pid=26774"
 *
 *  const now = "http://ivychen-11.jzm.fff.com/manage/mobiDesignV2.jsp#/?appId=pd&colId=20&data={\"mid\":\"21103\",\"pid\":\"26774\"}"
 *
 *  const getQurl = "http://ivychen-11.jzm.fff.com?mid=21103&pid=26774"
 *
 * @export
 * @return {*}
 */
export function getQurl() {
    return encodeURIComponent(
        VITE_APP_MODE !== 'visitor'
            ? Fai.addUrlParamsV2(Fai.top.location.origin, JSON.parse(Fai.top.$router.currentRoute.query.data || '{}'))
            : window.location.href
    );
}
