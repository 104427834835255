var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "propPanel" },
    [
      _vm._l(_vm.filterProp, function (item, index) {
        return [
          _vm.showPar
            ? [
                item.type == _vm.SYS_MALL_PRICE
                  ? [
                      _c(
                        "param-wrap",
                        {
                          key: "props_" + index,
                          attrs: { type: _vm.textAlignType },
                        },
                        [
                          _c("span", {
                            staticClass: "g_main_color_v3 mallPrice",
                            domProps: {
                              textContent: _vm._s(_vm.mallPrice1(item)),
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "prVipName g_bgColor g_main_bgColor_v3",
                            },
                            [_vm._v(_vm._s(_vm.options.vipName))]
                          ),
                          _vm._v(" "),
                          _vm.showMallCart
                            ? _c("mcart", {
                                attrs: {
                                  options: _vm.options,
                                  text: _vm.info.shopBtnTypeText,
                                  pid: _vm.info.id,
                                  type: _vm.content.type,
                                  "form-id": _vm.info.productFormId,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.options.salePromotionOpen && _vm.info.mallPrice != 0
                        ? [
                            _vm.info.isSaleTypeDis ||
                            (_vm.realPrice < _vm.cancelPrice &&
                              _vm.info.hasMarketPrice)
                              ? _c(
                                  "param-wrap",
                                  {
                                    key: "props_" + index,
                                    attrs: { type: _vm.textAlignType },
                                  },
                                  [
                                    _vm.info.isSaleTypeDis
                                      ? _c("span", {
                                          staticClass: "marketPrice",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.mallPrice2(item)
                                            ),
                                          },
                                        })
                                      : _c("span", {
                                          staticClass: "marketPrice",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.mallPrice3(item)
                                            ),
                                          },
                                        }),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ]
                  : item.type == _vm.SYS_MALL_MARKET_PRICE
                  ? _c(
                      "param-wrap",
                      {
                        key: "props_" + index,
                        attrs: { type: _vm.textAlignType },
                      },
                      [
                        _vm.getArgsParam(item.id).realPrice <
                        _vm.getArgsParam(item.id).cancelPrice
                          ? _c("span", {
                              staticClass: "marketPrice",
                              domProps: {
                                textContent: _vm._s(_vm.mallPrice4(item)),
                              },
                            })
                          : _vm._e(),
                      ]
                    )
                  : _c(
                      "param-wrap",
                      {
                        key: "props_" + index,
                        attrs: { type: _vm.textAlignType },
                      },
                      [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              item.name + _vm.symbol + _vm.getValue(item)
                            ),
                          },
                        }),
                      ]
                    ),
              ]
            : [
                item.type == _vm.SYS_MALL_PRICE
                  ? [
                      _c(
                        "param-wrap",
                        {
                          key: "props_" + index,
                          attrs: { type: _vm.textAlignType },
                        },
                        [
                          _vm.siteCurrencyRight
                            ? _c(
                                "span",
                                { staticClass: "g_main_color_v3 mallPrice" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getArgsParam(item.id).formatRealPrice
                                    ) + _vm._s(_vm.options.choiceCurrencyVal)
                                  ),
                                ]
                              )
                            : _c(
                                "span",
                                { staticClass: "g_main_color_v3 mallPrice" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.options.choiceCurrencyVal) +
                                      _vm._s(
                                        _vm.getArgsParam(item.id)
                                          .formatRealPrice
                                      )
                                  ),
                                ]
                              ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "prVipName g_bgColor g_main_bgColor_v3",
                            },
                            [_vm._v(_vm._s(_vm.options.vipName))]
                          ),
                          _vm._v(" "),
                          _vm.showMallCart
                            ? _c("mcart", {
                                attrs: {
                                  options: _vm.options,
                                  pid: _vm.info.id,
                                  type: _vm.content.type,
                                  text: _vm.info.shopBtnTypeText,
                                  "form-id": _vm.info.productFormId,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.options.salePromotionOpen && _vm.info.mallPrice != 0
                        ? [
                            _vm.info.isSaleTypeDis ||
                            (_vm.getArgsParam(item.id).realPrice <
                              _vm.getArgsParam(item.id).cancelPrice &&
                              _vm.info.hasMarketPrice)
                              ? _c(
                                  "param-wrap",
                                  {
                                    key: "props_" + index,
                                    attrs: { type: _vm.textAlignType },
                                  },
                                  [
                                    _vm.info.isSaleTypeDis
                                      ? _c("span", {
                                          staticClass: "marketPrice",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.mallPrice5(item)
                                            ),
                                          },
                                        })
                                      : _c("span", {
                                          staticClass: "marketPrice",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.mallPrice6(item)
                                            ),
                                          },
                                        }),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ]
                  : item.type == _vm.SYS_MALL_MARKET_PRICE
                  ? _c("p", { key: "props_" + index, class: _vm.textAlign }, [
                      _vm.getArgsParam(item.id).realPrice <
                      _vm.getArgsParam(item.id).cancelPrice
                        ? _c("span", {
                            staticClass: "marketPrice",
                            domProps: {
                              textContent: _vm._s(_vm.mallPrice7(item)),
                            },
                          })
                        : _vm._e(),
                    ])
                  : _vm.getValue(item) != ""
                  ? _c("p", {
                      key: "props_" + index,
                      class: _vm.textAlign,
                      domProps: { innerHTML: _vm._s(_vm.getValue(item)) },
                    })
                  : _vm._e(),
              ],
        ]
      }),
      _vm._v(" "),
      _vm.content.type == 3
        ? [
            _vm.booking_system && _vm.bookingOpen
              ? _c("p", { staticClass: "proListBookingBtnWrap" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "g_button proListBookingBtn g_main_bgColor_v3",
                      attrs: {
                        id: "proListBookingBtn" + _vm.info.id,
                        _pid: _vm.info.id,
                      },
                    },
                    [_vm._v(_vm._s(_vm.encodeHtml(_vm.info.shopBtnTypeText)))]
                  ),
                ])
              : _vm.productFormOpen && _vm.productFormShow == 1
              ? _c(
                  "p",
                  {
                    staticClass: "proListBookingBtnWrap",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.productFormBtnClick.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "g_button proListBookingBtn g_main_bgColor_v3",
                        attrs: { id: "productFormBtn" + _vm.info.id },
                      },
                      [_vm._v(_vm._s(_vm.encodeHtml(_vm.info.shopBtnTypeText)))]
                    ),
                  ]
                )
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }