import { ing } from '@/shared/tips';
import { logDog } from '@/shared/log';
import { customPopup } from '@/shared/popup';
import { changeHref } from '@/features/router/universal';
import { getIntegral } from '@/api/integral';
import { encodeHtml } from '@/shared/util';
function showItgRewardPop(integralName, type, earnItg, customCallBack) {
    var title = '注册成功';
    {
        if (type == 1) {
            title = '登录成功';
        } else if (type == 2) {
            title = '评价成功';
        } else if (type == 3) {
            title = '已确认收货';
        }
    }

    {
        if (type == 0) {
            logDog(201094, 5);
        } else if (type == 1) {
            logDog(201094, 7);
        } else if (type == 2) {
            logDog(201094, 9);
        } else if (type == 3) {
            logDog(201094, 15);
        }
    }

    var contentHtml = [];
    {
        contentHtml.push("<div class='itgRewardPopContain'>");
        contentHtml.push("	<div class='popTop'>");
        contentHtml.push(
            "		<img class='itgRewardPopTitleBg' src='" + Fai.top._resImageRoot + "/image/v2/getItgHead.png'>"
        );
        contentHtml.push("		<div class='topTitle'>" + title + '</div>');
        contentHtml.push('	</div>');
        contentHtml.push("	<div class='popMiddle'>");
        contentHtml.push("		<div class='middleRewardTip'>恭喜获得" + encodeHtml(integralName) + '</div>');
        contentHtml.push("		<div class='middleRewardNum'>+" + earnItg + '</div>');
        contentHtml.push('	</div>');
        contentHtml.push("	<div class='popBottom'>");
        contentHtml.push(
            "		<div class='forwardContain'>查看我的" +
                encodeHtml(integralName) +
                "<span class='icon-itgForward'></span></div>"
        );
        contentHtml.push('	</div>');
        contentHtml.push("  <div class='itgRewardPopClose J-prompt-cancel'>");
        contentHtml.push("  	<img style='width:1.5rem;' src='" + Fai.top._resImageRoot + "/image/hdCouponClose.png'>");
        contentHtml.push('  </div>');
        contentHtml.push('</div>');
    }

    var options = {
        content: contentHtml.join(''),
        cancelCallback() {
            if (type == 0) {
                jm.ajax({
                    type: 'post',
                    url: '/ajax/member_h.jsp?cmd=closeRegistMemberItg',
                    error() {
                        ing(LS.js_systemError);
                    },
                    success() {},
                });
            }
            if (customCallBack && typeof customCallBack == 'function') {
                customCallBack();
            }
        },
    };
    customPopup(options);
    jm('.forwardContain').click(function () {
        jm.setCookie('showIntegral', true);
        changeHref('/profile.jsp');
    });
}
function showLoginTip(showLoginTip) {
    ing(showLoginTip, true);
}

export async function initIntegral() {
    const {
        itgRewardPop: { integralName, showType, earnItg, isShow: isShowItgRewardPop },
        loginTip: { loginTipStr, isShow: isShowloginTip },
    } = await getIntegral();
    if (isShowItgRewardPop) {
        showItgRewardPop(integralName, showType, earnItg);
    } else if (isShowloginTip) {
        showLoginTip(loginTipStr);
    }
}
