<template>
    <module-frame :module-id="moduleId">
        <div v-show="loaded" class="msgBoard msgBoardV3">
            <div class="msgTopV3">
                <template v-if="manageMode">
                    <div class="noticeArea">
                        <div class="notice">
                            {{ msgCanNotSubmitText }}
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="noticeArea"><div class="notice" style="display: none"></div></div>
                </template>
                <template v-for="tmpProp in displayList">
                    <template v-if="defaultCreator !== '' && tmpProp.type === MSGBOARD_PROP_DEF.TYPE.SYS_NAME">
                        <div :key="tmpProp.id" class="g_globalLineV3">
                            <input
                                :id="tmpProp.fieldKey"
                                :type="tmpProp.fieldKey | inputTypeFilter"
                                :value="defaultCreator"
                                style="color: #c8c8c8"
                                :maxlength="MSGBOARD_DEF.LIMIT.REQ_PROP_MAXLEN"
                                class="msg_ipt g_input J_checkMust"
                                :class="[
                                    getBitMemory(tmpProp.flag, MSGBOARD_PROP_DEF.FLAG.REQUIRED) ? 'msg_isMust' : '',
                                ]"
                                disabled
                            />
                            <span class="openThemeV3Must">*</span>
                        </div>
                    </template>
                    <template v-else-if="tmpProp.id == MSGBOARD_PROP_DEF.TYPE.SYS_EMAIL">
                        <div :key="tmpProp.id" class="g_globalLineV3">
                            <input
                                :id="tmpProp.fieldKey"
                                :type="tmpProp.fieldKey | inputTypeFilter"
                                :value="defaultEmail"
                                :maxlength="MSGBOARD_DEF.LIMIT.REQ_PROP_MAXLEN"
                                :placeholder="tmpProp[MSGBOARD_PROP_DEF.INFO.NAME]"
                                class="msg_ipt g_input J_checkMust"
                                :class="mustClassStr(tmpProp.flag)"
                            />
                            <span class="openThemeV3Must">*</span>
                        </div>
                    </template>
                    <template v-else-if="tmpProp.id == MSGBOARD_PROP_DEF.TYPE.SYS_PHONE">
                        <div :key="tmpProp.id" class="g_globalLineV3">
                            <input
                                :id="tmpProp.fieldKey"
                                onkeyup="value=value.replace(/[^\d]/g,'')"
                                :type="tmpProp.fieldKey | inputTypeFilter"
                                :value="defaultPhone"
                                :maxlength="MSGBOARD_DEF.LIMIT.REQ_PROP_MAXLEN"
                                :placeholder="tmpProp[MSGBOARD_PROP_DEF.INFO.NAME]"
                                class="msg_ipt g_input J_checkMust"
                                :class="mustClassStr(tmpProp.flag)"
                            />
                            <span class="openThemeV3Must">*</span>
                        </div>
                    </template>
                    <div v-else :key="tmpProp.fieldKey" class="g_globalLineV3">
                        <input
                            :id="tmpProp.fieldKey"
                            :type="tmpProp.fieldKey | inputTypeFilter"
                            :maxlength="MSGBOARD_DEF.LIMIT.REQ_PROP_MAXLEN"
                            :placeholder="tmpProp[MSGBOARD_PROP_DEF.INFO.NAME]"
                            class="msg_ipt g_input J_checkMust"
                            :class="mustClassStr(tmpProp.flag)"
                        />
                        <span class="openThemeV3Must">*</span>
                    </div>
                </template>
                <div class="g_globalLineV3 g_globalLine_textArea">
                    <textarea
                        id="reqContent"
                        class="J_checkMust g_textArea msg_textArea msg_isMust"
                        :maxlength="MSGBOARD_DEF.LIMIT.REQ_CONTENT_MAXLEN"
                        :placeholder="msgBoardContentV3Text"
                    ></textarea>
                    <span class="openThemeV3Must">*</span>
                </div>
                <div style="clear: both"></div>
                <div class="g_globalLineV3" :class="captchaHideClass">
                    <input
                        id="msgBoardCaptcha"
                        type="text"
                        class="captchaTextV3 g_input"
                        maxlength="4"
                        :placeholder="enterVerificationCodeV3Text"
                    />
                    <img
                        id="msgBoardCaptchaImg"
                        class="captchaImgV3"
                        :src="validateCodeSrc"
                        @click="changeValidateCode"
                    />
                </div>
                <template v-if="manageMode">
                    <div class="g_globalLineV3 g_globalLine_subm">
                        <input
                            type="button"
                            class="g_main_bgColor_v3 g_button sendIcon msgSubmitButton submitIcon"
                            :value="submitV3Text"
                            disabled
                        />
                        <span class="mustSpan">&nbsp;&nbsp;</span>
                    </div>
                </template>
                <template v-else>
                    <div class="g_globalLineV3 g_globalLine_subm">
                        <input
                            type="button"
                            class="g_main_bgColor_v3 g_button sendIcon msgSubmitButton submitIcon"
                            :class="{
                                'not-allowed-button': allowSubmit === false,
                            }"
                            :value="submitV3Text"
                            disabled
                            @click="addMsgHandler"
                        />
                        <span class="mustSpan">&nbsp;&nbsp;</span>
                    </div>
                </template>
            </div>
            <!-- 评论区 start  -->
            <div v-if="showComments" class="msgBottom" :class="{ msgBottomDisplay: msgList.length === 0 }">
                <div class="spaceRow"></div>
                <div class="msgBottomTop">
                    <div class="g_globalLineV3 mtitle">
                        <div class="mbTitle userMsgIcon" style="display: inline-block">{{
                            msgBoardUserCommentsText
                        }}</div>
                    </div>
                    <template v-for="msgInfo in msgList">
                        <div :id="msgInfo.id | msgItemIdText" :key="msgInfo.id" class="jz_msgItemV3">
                            <div class="jz_seperate_lineV3"></div>
                            <div class="msgArea g_panel">
                                <div class="msgTitle msgTitleHeight">
                                    <span class="textAndIcon">
                                        <div class="msgUserV3" :title="msgInfo.msgName">
                                            {{ msgInfo.msgName }}
                                        </div>
                                        <template
                                            v-if="!openMsgAuto && !checkBit(msgInfo.flag, MSGBOARD_DEF.FLAG.PUBLIC)"
                                        >
                                            <div class="g_main_bgColor_v3 jz_private_label">未公开</div>
                                        </template>
                                    </span>
                                    <div class="msgTimeV3" :title="msgInfo.msgTitle2">
                                        {{ formatDate(msgInfo.reqTime) }}
                                    </div>
                                </div>
                                <div class="msgContent" v-html="msgInfo.reqContent"></div>
                                <template v-if="msgInfo.rspContent">
                                    <div class="placeholderDiv"></div>
                                    <div class="reqArea g_replyPanel">
                                        <div class="msgArrow"></div>
                                        <div class="admReq">
                                            <!-- 没有rspTime数据的, 但代码里有, 页面不显示, 后面要显示再处理 -->
                                            <!-- {{ msgInfo.rspTime }} -->
                                            {{ msgBoardReplyText }}
                                        </div>
                                        <div class="admReqContent" v-html="msgInfo.rspContent"></div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div style="clear: both"></div>
                                </template>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <pagination
                v-if="pagination.total !== 0 && showComments"
                :module-id="moduleId"
                :total-size="pagination.total"
                :page-size="module.content.page"
                :pageno="pagination.pageNo"
                :open-theme-v3="openThemeV3"
                :ajax-pagenation="true"
                @on-change="changePage"
            />
        </div>
    </module-frame>
</template>

<script>
// created from fai/webmobi/moduleprinterkit/ModuleMsgBoardV3.java
import ModuleFrame from '@/modules/frame/index.vue';
import { LinkSettingUtil } from '@/shared/linkSetting/index';
import { setHrefEventHasReqArgs } from '@/modules/comm';
import pagination from '@/components/pagenation.vue';
import msgBoardMixin from '../mixins/msg-board';

export default {
    name: 'MsgBoardV3',
    components: {
        ModuleFrame,
        pagination,
    },
    mixins: [msgBoardMixin],
    data() {
        return {
            loaded: false,
        };
    },
    async mounted() {
        await this.loadMsgBoardInfo();
        this.$nextTick(() => {
            this.loaded = true;
        });
        if (VITE_APP_MODE !== 'visitor') {
            await this.initModuleMsgBoardItemManage();
        }
        this.toggleMust();
        LinkSettingUtil.switchJump();
        this.msgCheckMustItem('.msgTopV3');
        setHrefEventHasReqArgs(jm('.msgBottomTop'));
    },
};
</script>
