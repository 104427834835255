<template>
    <div :id="'mProductList' + moduleId" class="mProductList prContainer">
        <template v-for="(item, index) in list">
            <div
                :key="'pr3_0_' + index"
                class="productPicListForm J_product_item"
                :title="manageMode && item.top != 0 ? '该产品已置顶' : ''"
                :productid="item.id"
            >
                <a :href="item.mobiHtmlUrl">
                    <div class="tableBox">
                        <inner-wrap :item="item">
                            <div style="position: relative">
                                <div
                                    v-if="options.salePromotionOpen"
                                    class="g_main_bgColor_v3 g_bgColor f-productListTopPromotion"
                                    >{{ item.saleFlagName }}</div
                                >
                                <span
                                    v-if="options.openKnowPayment && item.productType == 2"
                                    class="quality_content_label"
                                    >{{ item.labelText }}</span
                                >
                                <img
                                    :alt="item.name"
                                    :src="options.loadingPath"
                                    :class="picStretchClass"
                                    :src-original="item.newPath"
                                    class="J_img_lazyload style3Img"
                                />
                                <div v-if="options.showSoldOut" class="soldOutBox"
                                    ><img class="soldOut" :src="options.soldOutImg"
                                /></div>
                            </div>
                        </inner-wrap>
                        <div class="tableCell tableCell-70">
                            <div v-if="showProName" :class="nameWrap ? 'nameWrap' : 'paramNowrap'">
                                <div class="g_link paramName">
                                    <div
                                        v-if="item.isTimedAdded"
                                        class="g_oldThemeBgColor g_main_bgColor_v3 m_timedAddTag"
                                        >即将开售</div
                                    >
                                    <span v-html="getProName(item.name)"></span>
                                    <mcart
                                        v-if="getShowMallCart(item)"
                                        :pid="item.id"
                                        :type="3"
                                        :text="item.shopBtnTypeText"
                                        :form-id="item.productFormId"
                                    />
                                </div>
                            </div>
                            <div v-else :class="nameWrap ? 'nameWrap' : 'paramNowrap'">
                                <p class="g_link paramName">
                                    <mcart
                                        v-if="getShowMallCart(item)"
                                        :pid="item.id"
                                        :type="3"
                                        :text="item.shopBtnTypeText"
                                        :form-id="item.productFormId"
                                    />
                                </p>
                            </div>
                            <prop-panel :info="item"></prop-panel>
                        </div>
                    </div>
                </a>
            </div>
            <div :key="'pr3_1_' + index" class="g_separator separatorLine"></div>
        </template>
    </div>
</template>

<script>
import { productResultMixins } from '../mixins';
import mcart from './mcart.vue';
import propPanel from './propPanel.vue';
import { encodeHtml } from '@/shared/util';

export default {
    name: 'Type3',
    components: {
        'inner-wrap': {
            template: `
                <div class='J_prImgContainer tableCell tableCell-30'>
                    <div v-if="manageMode" class='fk-productListImgWrap'>
                        <div v-if="item.top != 0" class='f-productListTopFlag' >置顶</div>
                        <slot />
                    </div>
                    <template v-else>
                        <slot />
                    </template>
                </div>
            `,
            inject: ['manageMode'],
            props: {
                item: {
                    type: Object,
                    default() {
                        return {};
                    },
                },
            },
        },
        mcart,
        propPanel,
    },
    mixins: [productResultMixins],
    inject: ['moduleId', 'manageMode'],
    props: {
        list: {
            type: Array,
            default() {
                return [];
            },
        },
        isShowLabel: {
            type: Number,
        },
    },
    data() {
        return {};
    },
    computed: {
        picSetting() {
            return this.content.picSetting;
        },
        fontSetting() {
            return this.content.fs;
        },
        picCompress() {
            return this.picSetting.picCompress;
        },
        showProName() {
            return this.fontSetting.spn;
        },
        nameWrap() {
            return this.fontSetting.nw;
        },
        picStretchClass() {
            return this.picCompress == 1 ? 'stretchImg' : '';
        },
    },
    methods: {
        getProName(proName) {
            let searchword = this.getKeyInRounter('searchword');
            return this.highLightKeyword(encodeHtml(proName), searchword);
        },
        getShowMallCart(item) {
            let show = false;
            let { mall_cart, mallOpen, _sessionMemberId, booking_system, productFormShow } = this.options;
            let { MemberPrivileges } = item;
            if (mall_cart && mallOpen) {
                if ((!MemberPrivileges && _sessionMemberId != 0) || MemberPrivileges) {
                    show = true;
                }
            }
            if (this.content.type == 3) {
                if (booking_system && this.bookingOpen) {
                    show = false;
                }
            } else {
                if (booking_system && this.bookingOpen) {
                    show = true;
                } else if (this.productFormOpen && productFormShow == 1) {
                    show = true;
                }
            }

            return show && !this.isOpenKnowPd;
        },
    },
};
</script>

<style></style>
