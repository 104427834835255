<template>
    <div
        v-if="forceShowPicture"
        :id="newsLineId"
        :topClassname="topClassName"
        :topSwitch="topSwitch"
        :newsId="id"
        :newsName="title"
        class="mixNewsStyleLine newsLine J_picNum"
        :class="lineClass"
        picnum="5"
    >
        <div :id="lineBodyId" class="lineBody">
            <div class="mixNewsStyleImgBox4">
                <a hidefocus="true" :href="href" :target="target" :style="mixNewsStyleImgBox4" :onclick="onclickStr">
                    <div v-if="!pictureId" id="newsTitlePic" :alt="alt" class="newsTitlePic icon-noFigureB"></div>
                    <div v-else id="newsTitlePic" :alt="alt" class="newsTitlePic" :style="newsTitlePicStyle"></div>
                    <div class="mixNewsStyleTitleContainer3">
                        <div :class="topFlagClass"></div>
                        <div :class="[showLine ? 'mixNewsStyleTitle1' : 'mixNewsStyleTitle2']">
                            {{ title }}
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
    <div
        v-else
        :id="newsLineId"
        :topClassname="topClassName"
        :topSwitch="topSwitch"
        :newsId="id"
        :newsName="title"
        class="newsLine line icon-gline"
        :class="newsLineClassNames"
        picnum="5"
    >
        <a class="lineBodyLink" :href="href" :target="target" style="width: auto" :onclick="onclickStr">
            <div :id="lineBodyId" class="lineBody lineBodyPic">
                <div v-if="topFlagClass !== ''" class="topFlagTableCell">
                    <div :class="topFlagClass"></div>
                </div>
                <template v-if="topicPicPosition === 1">
                    <div v-if="!pictureId" class="picTableCell" :style="picTableCellStyle">
                        <div :id="newsImgId" :alt="alt" class="newsLeftImg icon-noFigureS" :style="picCellStyle"></div>
                    </div>
                    <div v-else class="picTableCell" :style="picTableCellStyle">
                        <div :id="newsImgId" :alt="alt" class="newsLeftImg" :style="picCellStyle"></div>
                    </div>
                </template>
                <div class="fontTableCell">
                    <div class="fontHiddenTable">
                        <div class="twoLineHidden">
                            {{ title }}
                        </div>
                    </div>
                </div>
                <template v-if="topicPicPosition === 2">
                    <div v-if="!pictureId" class="picTableCell" :style="picTableCellStyle">
                        <div :id="newsImgId" :alt="alt" class="newsLeftImg icon-noFigureS" :style="picCellStyle"></div>
                    </div>
                    <div v-else class="picTableCell" :style="picTableCellStyle">
                        <div :id="newsImgId" :alt="alt" class="newsLeftImg" :style="picCellStyle"></div>
                    </div>
                </template>
            </div>
        </a>
        <div
            v-if="showNewsElementsRequire"
            class="newsElementsPanel g_newsElementsPanel J_newsElementsPanel"
            @click="gotoHref"
        >
            <div v-if="showCommentRequire" class="ne_newsComment faisco-icons-comment">
                <span class="newsCommentCount">{{ totalNewsCommentCount }}</span>
            </div>
            <div v-if="showAuthorRequire" class="ne_newsAuthor faisco-icons-author" :style="newsAuthorStyle">
                <span class="newsAuthorName">{{ newsAuthor }}</span>
            </div>
            <div
                v-if="(showCommentRequire || showAuthorRequire) && (showSortRequire || showDateRequire)"
                class="ne_separatorLine g_ne_separatorLine"
            >
            </div>
            <div v-if="showSortRequire" class="ne_newsSort">{{ groupName }}</div>
            <div v-if="showDateRequire" class="ne_newsTime">{{ formattedNewsDate }}</div>
        </div>
        <template v-else>
            <div v-if="showCommentRequire" class="ne_newsComment faisco-icons-comment">
                <span class="newsCommentCount">{{ totalNewsCommentCount }}</span>
            </div>
            <div v-if="showAuthorRequire" class="ne_newsAuthor faisco-icons-author" :style="newsAuthorStyle">
                <span class="newsAuthorName">{{ newsAuthor }}</span>
            </div>
            <div
                v-if="(showCommentRequire || showAuthorRequire) && (showSortRequire || showDateRequire)"
                class="ne_separatorLine g_ne_separatorLine"
            ></div>
            <div v-if="showSortRequire" class="ne_newsSort">{{ groupName }}</div>
            <div v-if="showDateRequire" class="ne_newsTime">{{ formattedNewsDate }}</div>
        </template>
    </div>
</template>

<script>
import newsMoreMixin from '../mixins/news-more-mixin';

export default {
    name: 'NewsMoreItemStyle4',
    mixins: [newsMoreMixin],
    data() {
        return {};
    },
    computed: {
        newsLineClassNames() {
            return `${this.topFlagLineClass} ${this.firstClassName} ${this.lineClass}One`;
        },
        forceShowPicture() {
            return this.showPicture && this.index === 0;
        },
        newsImgId() {
            return `newsImg${this.id}`;
        },
        mixNewsStyleImgBox4() {
            return {
                width: '100%',
                height: 'auto',
                overflow: 'hidden',
                'max-width': '100%',
                'max-height': '8rem',
                display: 'block',
                position: 'relative',
            };
        },
    },
};
</script>

<style scoped>
.newsLine.line.wWLineOne {
    padding-left: 0.3rem;
}
</style>
