import { COL_DEF_FLAG } from './def/col';
import { checkBit } from './shared/fai';

export async function initData(store, pageData) {
    const firstPaintData = pageData.firstPaintData || {};
    const {
        navV3,
        navV2,
        headerV3,
        headerV2,
        footerV3,
        footerV2,
        banner,
        mobiBgMusic,
        modules: moduleList,
        floatEffect,
        floatZoneModule,
        footerNavInfo,
        footerNavV2,
        LS,
    } = pageData;
    let showSubTitleSkinIdList = firstPaintData.data.showSubTitleSkinIdList || [];

    let fullSiteSubTitleType = firstPaintData.data.fullSiteSubTitleType || 0;

    store.commit('app/updateAllowedBackTop', firstPaintData.data._allowedBackTop);

    if (firstPaintData) {
        store.commit(`app/LoadFirstPaintData`, firstPaintData.data);
    }

    if (moduleList) {
        store.commit(`updateCurrentPageModules`, moduleList);
    }
    if (navV3) {
        store.commit(`nav/LoadNavV3`, navV3);
        store.commit('nav/UpdateIndependentNav', {
            set: checkBit(firstPaintData.data._colInfo.flag, COL_DEF_FLAG.INDEPENDENT_SET_NAV),
        });
    }
    if (navV2) {
        store.commit(`nav/LoadNavV2`, navV2);
    }
    if (headerV3) {
        store.commit(`header/LoadHeaderV3Info`, headerV3);
    }
    if (headerV2) {
        store.commit(`header/LoadHeaderV2Info`, headerV2);
    }
    if (footerV3) {
        store.commit(`footer/LoadFooterInfo`, footerV3);
    }
    if (footerV2) {
        store.commit('footer/LoadFooterInfoV2', footerV2);
    }
    if (banner) {
        store.commit(`banner/LoadBannerInfo`, {
            moduleData: banner,
        });
    }

    if (mobiBgMusic) {
        store.commit(`updateMobiBgMusic`, mobiBgMusic);
    }

    if (LS) {
        store.commit(`app/updateLS`, LS);
    }

    if (floatEffect) {
        store.commit(`floatEffect/updateFloatEffectInfo`, floatEffect);
    }

    if (floatZoneModule) {
        await store.dispatch('floatEffect/loadFloatContainerInfo', floatZoneModule);
    }

    if (footerNavInfo) {
        store.commit('footerNav/LoadFooterNavInfo', footerNavInfo);
    }

    if (footerNavV2) {
        store.commit(`footerNav/LoadFooterNavInfoV2`, footerNavV2);
    }

    store.commit('setShowSubTitleSkinIdList', showSubTitleSkinIdList);
    store.commit('setFullSiteSubTitleType', fullSiteSubTitleType);

    store.commit('backgroundSetting/updateBackgroundImg', firstPaintData._backgroundInfo || {});
}
