var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "preview-area",
      class: _vm.previewClass,
      style: _vm.previewStyle,
      attrs: { id: "preview" },
    },
    [
      _vm.manageMode
        ? _c("loading", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.pageLoading,
                expression: "pageLoading",
              },
            ],
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.simultaneousLogin && !_vm.$isServer && !_vm.manageMode
        ? _c("m-dialog", {
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("div", [
                        _vm._v(
                          "您的账号已在另一台设备登录，如需继续浏览请重新登录"
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1389247417
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.gBodyClasses, attrs: { id: "g_body" } },
        [
          _c("visitor-top-tips"),
          _vm._v(" "),
          _c("div", { staticClass: "webLeft" }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "g_web",
              class: _vm.gWebClasses,
              style: _vm.gWebStyle,
              attrs: { id: "g_web" },
              on: { scroll: _vm.onPageScroll },
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "webTopBox", attrs: { id: "webTopBox" } },
                [
                  _c(
                    "div",
                    { staticClass: "webTop", attrs: { id: "webTop" } },
                    [
                      _c("div", {
                        staticClass: "jz_navExpendAnimate",
                        attrs: { id: "J_navExpandAnimate" },
                      }),
                      _vm._v(" "),
                      _vm.showNavInWebTopBox
                        ? _c(_vm.systemComponents.Nav, {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showNav,
                                expression: "showNav",
                              },
                            ],
                            tag: "component",
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.openThemeV3
                        ? _c("nav-button", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showNav,
                                expression: "showNav",
                              },
                            ],
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              !_vm.openThemeV3
                ? _c(
                    "div",
                    {
                      staticClass: "webHeaderBox",
                      class: _vm.webHeaderBoxClass,
                      attrs: { id: "webHeaderBox" },
                    },
                    [
                      _vm.logo.hideFileUrl
                        ? _c("span", {
                            staticStyle: { display: "hidden" },
                            attrs: { hideFileUrl: _vm.logo.hideFileUrl },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.designMode || (!_vm.isMallTheme && !_vm.designMode)
                        ? [
                            _vm.templateFrameId != 1004 &&
                            _vm.templateFrameId != 1023
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showNavV2Button,
                                        expression: "showNavV2Button",
                                      },
                                    ],
                                    staticClass: "navButton",
                                    attrs: { id: "navButton" },
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "navButtonPanel",
                                    }),
                                    _vm._v(" "),
                                    _vm._m(1),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "headerSiteMaskWhiteBg",
                              attrs: { id: "headerWhiteBg" },
                            }),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "headerSiteMaskBg",
                              attrs: { id: "headerBg" },
                            }),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c(_vm.systemComponents.Header, { tag: "component" }),
                      _vm._v(" "),
                      _vm.showNavV2InHeaderBox
                        ? _c(_vm.systemComponents.Nav, {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showNav,
                                expression: "showNav",
                              },
                            ],
                            tag: "component",
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.locaterV2InNavFixVisible
                        ? _c(_vm.systemComponents.Locater, { tag: "component" })
                        : _vm._e(),
                    ],
                    2
                  )
                : _c(_vm.systemComponents.Header, { tag: "component" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "webBannerBox moveAnimation",
                  class: _vm.webBannerBoxClass,
                  attrs: { id: "webBannerBox" },
                },
                [
                  _vm.locaterV2InNavUnFixVisible
                    ? _c(_vm.systemComponents.Locater, { tag: "component" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.bannerVisible
                    ? _c(_vm.systemComponents.Banner, { tag: "component" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showNavV2InBannerBox
                    ? _c(_vm.systemComponents.Nav, {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showNav,
                            expression: "showNav",
                          },
                        ],
                        tag: "component",
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "webContainerBox",
                  class: _vm.webContainerBoxClass,
                  style: _vm.webContainerStyle,
                  attrs: { id: "webContainerBox" },
                },
                [
                  _vm.manageMode
                    ? [
                        !_vm.pageLoading
                          ? _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "sortable",
                                    rawName: "v-sortable",
                                    value: _vm.sortable,
                                    expression: "sortable",
                                  },
                                ],
                                staticClass: "webModuleContainer",
                                attrs: { id: "webModuleContainer" },
                              },
                              [
                                _vm._l(_vm.renderModules, function (module) {
                                  return _c(_vm.componentsTable[module.style], {
                                    key: module.id,
                                    tag: "component",
                                    attrs: {
                                      "module-id": module.id,
                                      children: module.children,
                                    },
                                  })
                                }),
                                _vm._v(" "),
                                _vm.isEditFloatZone
                                  ? _c(_vm.systemComponents.FloatEffect, {
                                      tag: "component",
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.renderModules.length
                                  ? _c("div", { staticClass: "addNewModule" }, [
                                      _c("div", { staticClass: "addBtn" }, [
                                        _c("div", {
                                          staticClass: "addBtn_Icon",
                                          on: {
                                            click: function ($event) {
                                              return _vm.initModuleTab()
                                            },
                                          },
                                        }),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", {
                                  attrs: { id: "bookingPanelTriggle" },
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    : _c(
                        "div",
                        {
                          staticClass: "webModuleContainer",
                          attrs: { id: "webModuleContainer" },
                        },
                        [
                          _vm._l(_vm.renderModules, function (module) {
                            return _c(_vm.componentsTable[module.style], {
                              key: module.id,
                              tag: "component",
                              attrs: {
                                "module-id": module.id,
                                children: module.children,
                              },
                            })
                          }),
                          _vm._v(" "),
                          _vm.isEditFloatZone
                            ? _c(_vm.systemComponents.FloatEffect, {
                                tag: "component",
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { attrs: { id: "bookingPanelTriggle" } }),
                        ],
                        2
                      ),
                ],
                2
              ),
              _vm._v(" "),
              _vm.openThemeV3
                ? _c(_vm.systemComponents.WebFooter, { tag: "component" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "webFooterBox",
                  class: _vm.webFooterBoxClass,
                  attrs: { id: "webFooterBox" },
                },
                [
                  !_vm.openThemeV3 && _vm.showNavV2InFooterBox
                    ? _c(_vm.systemComponents.Nav, { tag: "component" })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "webFooter",
                      class: _vm.webFooterClass,
                      attrs: { id: "webFooter" },
                    },
                    [
                      !_vm.openThemeV3
                        ? _c(_vm.systemComponents.WebFooter, {
                            tag: "component",
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "fullScreenDivCotainer",
                    attrs: { id: "fullScreenDivCotainer" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(_vm.systemComponents.FooterNav, { tag: "component" }),
              _vm._v(" "),
              _c("div", { staticClass: "u_area_box_wrap J_area_box_wrap" }, [
                _c("div", {
                  class:
                    "fixedAreaBox fixedAreaBox" +
                    _vm.templateFrameId +
                    "_" +
                    _vm.templatePresetIndex,
                  attrs: {
                    id: "fixedAreaBox",
                    tplid: _vm.templateFrameId + "_" + _vm.templatePresetIndex,
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { attrs: { id: "mobiReviewPage" } }),
              _vm._v(" "),
              _c(_vm.systemComponents.BackgroundMusic, { tag: "component" }),
              _vm._v(" "),
              _c(
                "fixed-right-side-btns",
                [
                  _c(_vm.systemComponents.FloatEffectIcon, {
                    tag: "component",
                  }),
                  _vm._v(" "),
                  _c(_vm.systemComponents.BackTop, { tag: "component" }),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.isEditFloatZone
                ? _c(_vm.systemComponents.FloatEffect, { tag: "component" })
                : _vm._e(),
              _vm._v(" "),
              _c(_vm.systemComponents.BackgroundBox, { tag: "component" }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "webRight" }),
          _vm._v(" "),
          _c("div", {
            staticClass: "hiddenModuleForms",
            staticStyle: { display: "none" },
            attrs: { id: "hiddenModuleForms" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isEditMode
        ? _c("div", { staticClass: "edit_mode_wrap" }, [
            _c(
              "span",
              {
                staticClass: "jz_button edit_mode_save",
                class: { active: _vm.isActiveSaveBtn },
                on: {
                  click: function ($event) {
                    return _vm.save($event)
                  },
                },
              },
              [_vm._v("保 存")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "jz_button edit_mode_cancel",
                class: { active: _vm.isActiveCancelBtn },
                on: {
                  click: function ($event) {
                    return _vm.cancel($event)
                  },
                },
              },
              [_vm._v("取 消")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "jz_button edit_mode_close",
                class: { active: _vm.isActiveExitBtn },
                on: {
                  click: function ($event) {
                    return _vm.exit($event)
                  },
                },
              },
              [_vm._v("退出弹窗设计")]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "loading",
        staticStyle: { display: "none" },
        attrs: { id: "webLoading" },
      },
      [
        _c(
          "div",
          {
            staticClass: "splashscreen ui-loader",
            attrs: { id: "splashscreen" },
          },
          [_c("span", { staticClass: "ui-icon ui-icon-loading spin" })]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "menuNav" }, [
      _c("div", { staticClass: "menuNavTip icon-menuNavTip" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }