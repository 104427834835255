var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "productNewGroup" }, [
    _c("div", { staticClass: "firstGroup" }, [
      _c(
        "ul",
        { staticClass: "firstGroupList" },
        _vm._l(_vm.catalogList, function (catalog, i) {
          return _c(
            "li",
            {
              key: i,
              staticClass: "firstGroupItem",
              class: {
                checkedItem:
                  catalog.name === _vm.selectCata ||
                  (_vm.fixedPG === 1 && i === 0),
              },
              attrs: { "data-id": i },
            },
            [
              _c("p", { staticClass: "firstGroupName" }, [
                _vm._v(_vm._s(catalog.name)),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "unFirstGroup" }, [
      _c(
        "div",
        { staticClass: "hideScollBar" },
        _vm._l(_vm.catalogList, function (catalog, i) {
          return _c(
            "div",
            {
              key: i,
              staticClass: "secondGroupPanel",
              class: {
                checkedPanel:
                  catalog.name === _vm.selectCata ||
                  (_vm.fixedPG === 1 && i === 0),
              },
              attrs: { id: "sed_" + i },
            },
            [
              _c("div", { staticClass: "secondGroupItem" }, [
                _vm._m(0, true),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "secndGroupCenter" },
                  _vm._l(catalog.groupList, function (catalogGroup, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "J_groupItem thirdGroupImageItem",
                        attrs: { "data-id": index },
                      },
                      [
                        _vm.isImageStyle
                          ? _c(
                              "a",
                              {
                                staticClass: "itemImageParent",
                                attrs: {
                                  href: catalogGroup.url,
                                  target: "_self",
                                },
                              },
                              [
                                catalogGroup.mIcon
                                  ? [
                                      _vm.isFontIcon(catalogGroup.mIcon)
                                        ? _c("span", {
                                            staticClass:
                                              "J_itemImage itemImage",
                                            class: [catalogGroup.iconPath],
                                          })
                                        : [
                                            catalogGroup.fileInfo
                                              ? _c("img", {
                                                  staticClass:
                                                    "J_itemImage itemImage",
                                                  attrs: {
                                                    src: catalogGroup.iconPath,
                                                    "data-w":
                                                      catalogGroup.fileInfo
                                                        .width,
                                                    "data-h":
                                                      catalogGroup.fileInfo
                                                        .height,
                                                  },
                                                })
                                              : _c("span", {
                                                  staticClass:
                                                    "J_itemImage notImage faisco-icons-pic",
                                                }),
                                          ],
                                    ]
                                  : _c("span", {
                                      staticClass:
                                        "J_itemImage notImage faisco-icons-pic",
                                    }),
                              ],
                              2
                            )
                          : _c(
                              "a",
                              {
                                attrs: {
                                  href: catalogGroup.url,
                                  target: "_self",
                                },
                              },
                              [
                                _c("p", { staticClass: "itemName" }, [
                                  _vm._v(_vm._s(catalogGroup.name)),
                                ]),
                              ]
                            ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "p",
      { staticClass: "secondGroupTop", staticStyle: { display: "none" } },
      [
        _c("span", { staticClass: "topLeft" }),
        _vm._v(" "),
        _c("span", { staticClass: "topCenter" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }