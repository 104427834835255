var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "productNewGroup fk-proGroupThemeStyle",
      class: _vm.productNewGroupClasses,
      attrs: { _prUrl: _vm.prUrl },
    },
    [
      !_vm.hideNav
        ? _c("product-group-detail-nav", {
            attrs: {
              horizonal: _vm.isHorizon,
              "first-group-list": _vm.firstGroupList,
              "select-group": _vm.selectGroup,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.hideNav && _vm.showAddBtn
        ? _c("product-group-detail-add-btn", {
            attrs: { horizonal: _vm.isHorizon },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("product-group-detail-panel", {
        attrs: {
          "group-style": _vm.groupStyle,
          "first-group-list": _vm.firstGroupList,
          "pr-url": _vm.prUrl,
          "top-level": _vm.topLevel,
          horizonal: _vm.isHorizon,
          "has-auth": _vm.hasAuth,
          "res-root": _vm.resRoot,
          "second-group-map": _vm.secondGroupMap,
          "select-group": _vm.selectGroup,
          "panel-options": _vm.panelOptions,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }