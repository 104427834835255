export const MODULE_ID = 5;

export const NEWS_DEF_FLAG = {
    HIDE_DATE: 0x1, //是否隐藏发布日期
    UNAUTO_SUMMARY: 0x2, //是否手动填写文章摘要 默认为false
    AUTH_MEMBER: 0x4, //会员浏览权限 0:默认 1：自定义
    CUS_NEWSLIST_DISPLAY: 0x8, //自定义文章列表展示图
    INDEP_NEWS_PAGE: 0x10, // 独立设置文章详情页面，个是跟文章，
    INDEP_ARTICLE: 0x20, // 独立设置文章内容详情
    IS_EDIT_MOBI: 0x40, // 当前编辑的手机版还是电脑版
    LINKS_EXTERNAL_ARTICLE: 0x80, // 链接外部文章：站内文章还是站外链接
    IS_ORIGIN_HEAD_IMG: 0x100, // 正文头图是否使用原图： 是: true, 否: false
    IS_ORIGIN_NEWSLIST_IMG: 0x200, // 文章列表展示图是否使用原图：是: true, 否: false
    PUBLISH_REGULARLY: 0x400, // 定时发布文章 0：否 1：是
    AUTH_MEMBER_BY_GROUP: 0x800, // 是否选择会员分组， 0： 否, 1: 是
    MEMBER_ONLY_LEVEL: 0x1000, // 会员分组(0: xxxx会员以上, 1: 仅xxxx会员)
    FROM_WECHAT_OA: 0x2000, // 微信公众号导入的文章
};

export const ALT_NAME = '文章封面图';
