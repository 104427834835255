import { jumpModuleUtils } from '@/modules/comm';
import { initWebPage } from '@/shared/misc/init-webpage';
import { preprocessUrl } from '@/shared/url';

import { getScreenPrInfoApi } from '@/api/product';
import { changeHref } from '@/features/router/universal';
import { encodeHtml } from '@/shared/util';
import { decodeHtml } from '@/shared/util';
export const SearchJumpProductUtils = {
    bindJumpSearchProductEvent(moduleId, moduleStyle, data) {
        if (moduleId == null || data == null) return;
        jm('#module' + moduleId + ' a')
            .unbind('click.jumpInfo')
            .bind('click.jumpInfo', function () {
                jumpModuleUtils.addModule(moduleId, {
                    moduleId,
                    style: moduleStyle,
                    data,
                });
            });
    },

    removeJumpSearchProductInfo(moduleId) {
        if (moduleId == null) return;
        jumpModuleUtils.removeModuleInfoById(moduleId);
    },
};

/**********产品筛选模态***********/
export const addProductScreenFilterModel = function (pdListId, cond) {
    var _pdListId = pdListId;

    initWebPage({
        triggerId: 'screenButton',
        panelId: 'productScreenFilterPanel',
        pageBg: true,
        returnSeltFun: true,
        callback: screenInfo,
        direction: 'right',
        pageHead: '筛选',
        closeWebPage: '.screenFilterIcon',
    });

    //唤出模态之后的调用，备用
    async function screenInfo() {
        let { sortName, desc, groupId, psId, keyword = '', mid, psc } = cond;
        let submitData = {};
        submitData.sortName = sortName;
        submitData.pdListId = _pdListId;
        submitData.desc = desc;
        submitData.groupId = groupId;
        submitData.searchword = keyword;
        submitData.mid = mid;

        let result = await getScreenPrInfoApi(submitData);
        if (result.success) {
            var option = jm.toJSON(result.list);
            initScreenFilter(option, psc, groupId, desc, sortName, keyword, psId);
        }
    }
};
/**********产品筛选初始化*************/
const initScreenFilter = function (option, condition, groupID, desc, sortName, keyword, psId) {
    var optionObj = jm.parseJSON(option);
    var conditionObj = jm.parseJSON(condition);
    var defaultLibName = LS.productScreenAll;
    if (conditionObj) {
        var lid = conditionObj.lid;
        for (var i = optionObj.length - 1; i >= 0; i--) {
            var prop = optionObj[i];
            if (prop.lid == lid) {
                defaultLibName = prop.libName;
                break;
            }
        }
    }
    var content = [];

    if (optionObj.length > 1) {
        content.push("<div class='productScreenFilterLib' id='libTab'>");
        content.push("<div class='productScreenLibLeft'>" + LS.productScreenSort + '</div>');
        content.push("<div class='g_mainColor g_main_color_v3 productScreenLibRight'>");
        content.push("<div class='icon-libNextMore libNextMoreIcon'></div>");
        content.push("<div class='productScreenLibName' id='productScreenLibName'>" + defaultLibName + '</div>');
        content.push('</div>');
        content.push('</div>');
        if (conditionObj) {
            let lid = conditionObj.lid;
            content.push("<input type='hidden' id='lidValue' value='" + lid + "'/>");
        } else {
            content.push("<input type='hidden' id='lidValue' value='-1'/>");
        }
    } else if (optionObj.length == 1) {
        content.push("<input type='hidden' id='lidValue' value='" + optionObj[0].lid + "'/>");
    } else {
        content.push("<input type='hidden' id='lidValue' value='-1'/>");
    }

    var contentStr = content.join('');
    jm('#baseScreenFilterContenter').append(contentStr);

    jm('#backContent').show();

    var content2 = [];
    // 确定按钮
    content2.push("<div class='productScreenFilterBottom'>");
    content2.push(
        "<div class='g_bgColor g_main_bgColor_v3 productScreenFilterButton' id='screenCommit'>" +
            LS.productScreenCommit +
            '</div>'
    );
    content2.push('</div>');
    contentStr = content2.join('');
    jm('#basePanel').append(contentStr);

    if (optionObj.length > 1) {
        changeScreenFilterLib(option);
    }

    //初始化产品参数
    initScreenFilterModel(option, conditionObj);

    //确定按钮触发事件
    screenCommitData(option, groupID, desc, sortName, keyword, psId);
};

/**********产品参数面板初始化************/
const initScreenFilterModel = function (option, condition) {
    if (jm('#productScreenFilterOptions').length > 0) {
        //判断是否已经有这个div,有则删除
        jm('#productScreenFilterOptions').remove();
    }

    if (jm('.noScreenProp').length > 0) {
        //判断是否已经有这个div,有则删除
        jm('.noScreenProp').remove();
    }

    var lid = jm('#lidValue').val();

    if (!option) {
        return;
    }

    if (lid < 0) {
        return;
    }

    var optionObj = jm.parseJSON(option);

    var content = [];
    var propCondList;

    if (condition) {
        propCondList = condition.prop;
        lid = condition.lid;
    }

    for (var i = 0; i < optionObj.length; i++) {
        if (optionObj[i].lid == lid) {
            var propList = optionObj[i].propList;
            content.push("<div class='productScreenFilterContent' id='productScreenFilterOptions'>");
            for (var j = 0; j < propList.length; j++) {
                var propName = LS.productScreenAll;
                if (propCondList) {
                    for (var l = 0; l < propCondList.length; l++) {
                        var tmpId = propList[j].id;
                        if (tmpId == propCondList[l].k) {
                            propName = propCondList[l].v;
                            if (tmpId == 11 || tmpId == 12 || tmpId == 13) {
                                var currency = propList[j].currency;
                                propName = propName.replace('[', '');
                                propName = propName.replace(',', '~');
                                propName = propName.replace(']', '');
                                propName = currency + propName;
                                if (propName.indexOf('~') < 0) {
                                    propName = propName + '~';
                                }
                            }
                        }
                    }
                }
                content.push("<div class='productScreenFilterLine' id='screenOption" + j + "'>");
                content.push("<div class='productScreenFilterLineLf'>");
                content.push('<span>' + propList[j].name + '</span>');
                content.push('</div>');
                content.push("<div class='productScreenFilterLineRt'>");
                content.push("<div class='icon-nextMore nextMoreIcon'></div>");
                content.push(
                    "<span class='productScreenFilterOption' id='screenOptionContent" + j + "'>" + propName + '</span>'
                );
                content.push('</div>');
                content.push('</div>');
                content.push("<div class='g_separator'></div>");
            }
            content.push('</div>');
            break;
        }
    }

    var contentStr = content.join('');
    jm('#baseScreenFilterContenter').append(contentStr);

    jm.each(optionObj, function (i, n) {
        if (n.lid == lid) {
            var propList = n.propList;
            for (var j = 0; j < propList.length; j++) {
                changeScreenFilterModel(j, propList[j]);
            }
        }
    });
};

const screenCommitData = function (options, groupID, desc, sortName, keyword, psId) {
    var optionObj = jm.parseJSON(options);
    jm('#screenCommit').bind('click', function () {
        var libOption,
            commitOption = {};
        if (optionObj.length > 1) {
            jm.each(optionObj, function (i, n) {
                var tmpLibName = jm('#productScreenLibName').html();
                tmpLibName = decodeHtml(tmpLibName);
                if (tmpLibName == n.libName) {
                    commitOption.lid = n.lid;
                    libOption = n;
                }
            });
        } else {
            commitOption.lid = optionObj[0].lid;
            libOption = optionObj[0];
        }
        var init = '?';
        var url = preprocessUrl({ path: '', oldPath: '' }) + 'pr.jsp';
        if (groupID) {
            url += init + 'groupId=' + groupID;
            init = '&';
        }
        if (psId != -1) {
            url += init + 'psId=' + psId;
            init = '&';
        }
        if (desc) {
            url += init + 'desc=' + desc;
            init = '&';
        }
        if (sortName) {
            url += init + 'sortName=' + sortName;
            init = '&';
        }
        if (keyword) {
            url += init + 'searchword=' + keyword;
            init = '&';
        }
        if (libOption) {
            var tmpProp = [];
            var propList = libOption.propList;
            for (var i = 0; i < propList.length; i++) {
                var option = jm('#screenOptionContent' + i).html();
                if (option != LS.productScreenAll) {
                    var tmpPropOption = {};
                    var tmpId = propList[i].id;
                    tmpPropOption.k = tmpId;
                    if (tmpId == 11 || tmpId == 12 || tmpId == 13) {
                        var currency = propList[i].currency;
                        if (currency) option = option.replace(currency, '');
                        if (option.charAt(option.length - 1) == '~') {
                            option = option.replace('~', '');
                        } else {
                            option = option.replace('~', ',');
                        }
                        option = '[' + option + ']';
                    } else {
                        option = decodeHtml(option);
                    }
                    tmpPropOption.v = option;
                    tmpProp.push(tmpPropOption);
                }
            }
            commitOption.prop = tmpProp;
            url += init + 'psc=' + jm.encodeUrl(jm.toJSON(commitOption));
        }

        changeHref(url);

        if (VITE_APP_MODE !== 'visitor') {
            $('#productScreenFilterPanel .screenFilterIcon').trigger('click'); // 手动隐藏浮层
        }
    });
};

/**********产品模态切换***********/
const changeScreenFilterModel = function (id, options) {
    var title = options.name;
    var list = options.option;
    var currency = options.currency;

    jm('#screenOption' + id).bind('click', function () {
        jm('#baseScreenFilterContenter').hide();
        var checked = jm('#screenOptionContent' + id).html();
        checked = decodeHtml(checked);
        jm('#screenCommit').hide();
        var content = [];

        content.push("<div class='productScreenFilterOptionContenter' id='optionSelector" + id + "'>");
        content.push("<div class='productScreenFilterTitle'>");
        content.push("<div id='back' class='icon-screenFilterRt screenFilterRtIcon'></div>");
        content.push("<div class='productScreenFilterTi'>" + title + '</div>');
        content.push('</div>');

        content.push("<div class='productScreenFilterContent'>");
        if (checked == LS.productScreenAll) {
            content.push(
                "<div class='productScreenFilterLineCheck' id='propOptionLineAll' onclick='Mobi.changePropValue(\"All\"," +
                    id +
                    ")'>"
            );
        } else {
            content.push(
                "<div class='productScreenFilterLine' id='propOptionLineAll' onclick='Mobi.changePropValue(\"All\"," +
                    id +
                    ")'>"
            );
        }

        content.push("<div class='productScreenFilterLineLfLong'>");
        content.push("<span id='optionAll'>" + LS.productScreenAll + '</span>');
        content.push('</div>');
        if (checked == LS.productScreenAll) {
            content.push("<div class='entry-trangle'>");
            content.push('</div>');
            content.push("<div class='icon-check checkIcon'>");
            content.push('</div>');
        }
        content.push('</div>');
        content.push("<div class='g_separator'></div>");
        for (var i = 0; i < list.length; i++) {
            var tmpOption = list[i];
            if (tmpOption.indexOf('[') > -1 && tmpOption.indexOf(']') > -1) {
                tmpOption = tmpOption.replace('[', '');
                tmpOption = tmpOption.replace(',', '~');
                tmpOption = tmpOption.replace(']', '');
                if (i == list.length - 1) {
                    tmpOption = tmpOption + '~';
                }
            }
            tmpOption = currency + tmpOption;
            if (checked == tmpOption) {
                content.push(
                    "<div class='productScreenFilterLineCheck' id='propOptionLine" +
                        i +
                        "' onclick='Mobi.changePropValue(\"" +
                        i +
                        '",' +
                        id +
                        ")'>"
                );
            } else {
                content.push(
                    "<div class='productScreenFilterLine' id='propOptionLine" +
                        i +
                        "' onclick='Mobi.changePropValue(\"" +
                        i +
                        '",' +
                        id +
                        ")'>"
                );
            }
            content.push("<div class='productScreenFilterLineLfLong'>");
            content.push("<span id='option" + i + "'>" + tmpOption + '</span>');
            content.push('</div>');
            if (checked == tmpOption) {
                content.push("<div class='entry-trangle'>");
                content.push('</div>');
                content.push("<div class='icon-check checkIcon'>");
                content.push('</div>');
            }
            content.push('</div>');
            content.push("<div class='g_separator'></div>");
        }
        content.push('</div>');
        content.push('</div>');

        var contentStr = content.join('');
        jm('#basePanel').append(contentStr);

        jm('#back').on('click', function () {
            jm('#optionSelector' + id).remove();
            jm('#screenCommit').show();
            jm('#baseScreenFilterContenter').show();
        });
    });
};

export const changePropValue = function (line, id) {
    jm('#screenOptionContent' + id).html(jm('#option' + line).html());
    jm('#optionSelector' + id).remove();
    jm('#screenCommit').show();
    jm('#baseScreenFilterContenter').show();
};

export const changeLibValue = function (lid, lidOption) {
    if (lid > 0) {
        for (var i = lidOption.length - 1; i >= 0; i--) {
            if (lidOption[i].lid == lid) {
                jm('.productScreenLibName').html(lidOption[i].libName);
                jm('#lidValue').val(lid);
                break;
            }
        }
    } else {
        jm('.productScreenLibName').html(LS.productScreenAll);
        jm('#lidValue').val(-1);
    }
    jm('#optionSelectorLib').remove();
    jm('#screenCommit').show();
    initScreenFilterModel(jm.toJSON(lidOption));
    jm('#baseScreenFilterContenter').show();
};

/**********产品库切换*************/
const changeScreenFilterLib = function (option) {
    var optionObj = jm.parseJSON(option);
    jm('#libTab').bind('click', function () {
        jm('#screenCommit').hide();
        jm('#baseScreenFilterContenter').hide();
        var lid = jm('#lidValue').val();
        var content = [];
        content.push("<div class='productScreenFilterOptionContenter' id='optionSelectorLib'>");
        content.push("<div class='productScreenFilterTitle'>");
        content.push("<div id='backLib' class='icon-screenFilterRt screenFilterRtIcon'></div>");
        content.push("<div class='productScreenFilterTi'>" + LS.productScreenSort + '</div>');
        content.push('</div>');
        content.push("<div class='productScreenFilterContent'>");
        if (lid < 0) {
            content.push(
                "<div class='productScreenFilterLineCheck' id='propOptionLibAll' onclick='Mobi.changeLibValue(-1," +
                    encodeHtml(jm.toJSON(optionObj)) +
                    ")'>"
            );
        } else {
            content.push(
                "<div class='productScreenFilterLine' id='propOptionLibAll' onclick='Mobi.changeLibValue(-1," +
                    encodeHtml(jm.toJSON(optionObj)) +
                    ")'>"
            );
        }
        content.push("<div class='productScreenFilterLineLf'>");
        content.push('<span>' + LS.productScreenAll + '</span>');
        content.push('</div>');
        if (lid < 0) {
            content.push("<div class='entry-trangle'>");
            content.push('</div>');
            content.push("<div class='icon-check checkIcon'>");
            content.push('</div>');
        }
        content.push('</div>');
        content.push("<div class='g_separator'></div>");
        for (var i = 0; i < optionObj.length; i++) {
            var tmpLid = optionObj[i].lid;
            var tmpLibName = optionObj[i].libName;
            if (tmpLid == lid) {
                content.push(
                    "<div class='productScreenFilterLineCheck' id='propOptionLib'  onclick='Mobi.changeLibValue(" +
                        optionObj[i].lid +
                        ',' +
                        encodeHtml(jm.toJSON(optionObj)) +
                        ")'>"
                );
            } else {
                content.push(
                    "<div class='productScreenFilterLine' id='propOptionLib'  onclick='Mobi.changeLibValue(" +
                        optionObj[i].lid +
                        ',' +
                        encodeHtml(jm.toJSON(optionObj)) +
                        ")'>"
                );
            }
            content.push("<div class='productScreenFilterLineLf'>");
            content.push('<span>' + encodeHtml(tmpLibName) + '</span>');
            content.push('</div>');
            if (tmpLid == lid) {
                content.push("<div class='entry-trangle'>");
                content.push('</div>');
                content.push("<div class='icon-check checkIcon'>");
                content.push('</div>');
            }
            content.push('</div>');
            content.push("<div class='g_separator'></div>");
        }
        content.push('</div>');
        content.push('</div>');

        var contentStr = content.join('');
        jm('#basePanel').append(contentStr);

        jm('#backLib').on('click', function () {
            jm('#optionSelectorLib').remove();
            jm('#screenCommit').show();
            jm('#baseScreenFilterContenter').show();
        });
    });
};
