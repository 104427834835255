<template>
    <module-frame :module-id="moduleId">
        <div id="memberFdPwdStepOne">
            <div class="loginHeader webHeaderBg profileLoginHeader">
                <a :href="returnUrl" class="g_close icon-gClose"></a>
                <span class="title pageTitle" v-text="LS.mobi_memberDialogFwdStepOneTitle"></span>
            </div>
            <div class="loginContent find_paw_content">
                <template v-if="!needMailAuth && !needMobileAuth">
                    <div class="g_globalLine two_way_change_wrap">
                        <input
                            id="macct"
                            class="itemEdit g_input mustItem two_way_change"
                            type="text"
                            :maxlength="MemberDef.Limit.ACCT_MAXLEN"
                            :placeholder="macctPlaceholder"
                        />
                        <span class="icon-userIcon g_mainColor"></span>
                    </div>
                    <div class="g_globalLine J_memberFdPwdBtn">
                        <input
                            type="button"
                            class="g_button sendIcon g_main_bgColor_v3 fpw_btn"
                            :value="LS.mobi_memberDialogNextStep"
                            :disabled="manageMode"
                            @click="memberFdPwdClick"
                        />
                    </div>
                </template>
                <template v-else-if="findPwByMailOpen || findPwByMobileOpen">
                    <div class="g_globalLine globalLineRelative">
                        <span class="find_paw_phone" v-text="wayTips"></span>
                        <input
                            id="acquirerInput"
                            class="itemEdit g_input mustItem"
                            type="text"
                            :maxlength="MemberDef.Limit.ACCT_MAXLEN"
                            :placeholder="tipsPlaceHolder"
                            :style="acquirerInputStyle"
                        />
                    </div>
                    <div class="g_globalLine globalLineRelative" style="display: inline">
                        <span class="find_paw_code" v-text="LS.msgBoardValidateCode"></span>
                        <input
                            id="memCodeInput"
                            type="text"
                            maxlength="6"
                            class="itemEdit g_input mustItem"
                            :style="memCodeInputStyle"
                            :placeholder="LS.msgBoardValidateCode"
                        />
                        <span
                            id="acquireCode"
                            class="acquireCode"
                            @click="acquireCodeClick"
                            v-text="LS.mobi_getMobileCode"
                        ></span>
                    </div>
                    <input id="memCodeSign" style="display: none" disabled />
                    <div class="g_globalLine J_memberFdPwdBtn">
                        <input
                            type="button"
                            class="g_button sendIcon g_main_bgColor_v3 fpw_btn"
                            :value="LS.mobi_memberDialogNextStep"
                            :disabled="manageMode"
                            @click="memberNewFdPwdStepTwoClick"
                        />
                    </div>
                </template>
                <template v-else>
                    <div class="g_globalLine">
                        <input
                            id="macct"
                            class="itemEdit g_input mustItem"
                            type="text"
                            :maxlength="MemberDef.Limit.ACCT_MAXLEN"
                            :placeholder="macctPlaceholder"
                        />
                        <span class="icon-userIcon g_mainColor 456"></span>
                    </div>
                    <div class="g_globalLine J_memberFdPwdBtn">
                        <input
                            type="button"
                            class="g_button sendIcon g_main_bgColor_v3 fpw_btn"
                            :value="LS.mobi_memberDialogNextStep"
                            :disabled="manageMode"
                            @click="memberFdPwdClick"
                        />
                    </div>
                </template>
                <div class="J_showFindPwWay g_globalLine" style="margin-top: 1.5rem"></div>
            </div>
        </div>
    </module-frame>
</template>
<script>
import ModuleFrame from '@/modules/frame/index.vue';

import { MemberDef } from '../../memberDef';
import { mapGetters, mapState } from 'vuex';
import { memberFdPwd, acquireCode, memberNewFdPwdStepTwo } from '../method';

let manageMode = VITE_APP_MODE !== 'visitor';

export default {
    name: 'FindPwdV3',
    components: {
        ModuleFrame,
    },
    props: {
        moduleId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            MemberDef,
            manageMode,
        };
    },
    computed: {
        ...mapState('app', ['LS', 'lcid']),
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId) || {};
        },
        renderOptions() {
            return this.module.renderOptions || {};
        },
        findPwByMobileOpen() {
            return this.renderOptions.findPwByMobileOpen || false;
        },
        findPwByMailOpen() {
            return this.renderOptions.findPwByMailOpen || false;
        },
        authType() {
            return this.renderOptions.authType;
        },
        returnUrl() {
            return this.renderOptions.returnUrl || '/';
        },
        needMailAuth() {
            return this.authType == MemberDef.AuthType.NEED_MAIL_AUTH;
        },
        needMobileAuth() {
            return this.authType == MemberDef.AuthType.NEED_MOBILE_AUTH;
        },
        pleaseInput() {
            return this.LS.mobi_member_PleaseInput || '';
        },
        macctPlaceholder() {
            return this.pleaseInput + this.LS.mobi_member_Account;
        },
        tipsPlaceHolder() {
            let placeHolder = '';
            if (this.findPwByMobileOpen && this.findPwByMailOpen) {
                placeHolder = this.LS.memberDialogPlzEnterBoth;
            } else {
                if (this.findPwByMobileOpen) {
                    placeHolder = this.LS.memberDialogPlzEnterMobile;
                } else if (this.findPwByMailOpen) {
                    placeHolder = this.LS.memberDialogPlzEnterMail;
                }
            }
            return placeHolder;
        },
        wayTips() {
            let tips = '';
            if (this.findPwByMobileOpen && this.findPwByMailOpen) {
                tips = this.LS.phoneOrEmail;
            } else {
                if (this.findPwByMobileOpen) {
                    tips = this.LS.findPasswordPhone;
                } else if (this.findPwByMailOpen) {
                    tips = this.LS.g_email;
                }
            }
            return tips;
        },
        acquirerInputStyle() {
            let style = {};
            if (this.findPwByMailOpen && this.findPwByMobileOpen) {
                style['padding-left'] = '4.5rem';
            } else {
                style['padding-left'] = '3rem';
            }
            style['margin-left'] = '0px';
            return style;
        },
        memCodeInputStyle() {
            return {
                'margin-left': '0',
                width: '100%',
                'padding-left': '3rem',
            };
        },
    },
    methods: {
        memberFdPwdClick() {
            memberFdPwd(this.lcid, this.returnUrl);
        },
        acquireCodeClick() {
            let sec = jm('.acquireCode').attr('sec') || 0;
            if (sec == '' || sec == null || sec == 0) {
                acquireCode(this.findPwByMobileOpen, this.findPwByMailOpen);
            }
        },
        memberNewFdPwdStepTwoClick() {
            memberNewFdPwdStepTwo();
        },
    },
};
</script>
<style lang="scss">
.mobiCol22 {
    #webTopBox,
    #webHeader_v3,
    #webBannerBox,
    #webFooterBox,
    #bgMusicWrapper,
    #fixedRightSideBtns,
    #p_footer_nav_wrap {
        display: none;
    }
    .jz_themeV3 .profileLoginHeader {
        position: relative;
    }
    #memberFdPwdStepOne .profileLoginHeader {
        position: relative;
    }
    .fk-mem-findPwStepOneFindWay {
        height: 1.2rem;
        line-height: 1rem;
        text-align: left;
        color: #959595;
    }

    .fk-mem-findPwStepOne {
        height: 2.5rem;
        line-height: 2.5rem;
        background: #ffffff;
        border: 1px solid #dfdfdf;
        color: #595959;
        margin-bottom: 0.5rem;
        position: relative;
    }
    .fk-mem-findPwStepOne .content {
        position: absolute;
        left: 0.8rem;
    }
    .fk-mem-findPwStepOne:hover {
        cursor: pointer;
    }
    .fk-mem-findPwStepOne .icon-gGoforward {
        position: absolute;
        right: 0.8rem;
        top: 0.8rem;
    }
    .fk-mem-findPw-showMsg {
        padding: 0.5rem 0;
        border-bottom: 1px solid #edc693;
        min-height: 1.5rem;
        background-color: #ffefda;
        color: #e0a85e;
    }
    .fk-mem-findPw-showMsg .showMsg {
        line-height: 1.5rem;
        margin: 0 1rem;
    }
}
</style>
