import { getAllCityInfoApi, getProvinceInfoApi, getCityInfoApi, cityGetChildrenApi } from '@/api/city';

export const site_cityUtil = (function () {
    var site_city = {};
    var site_city_en = {};

    var site_city_computer = null;

    var site_groups = {
        1: ['华东', [310000, 320000, 330000, 340000, 360000]],
        2: ['华北', [110000, 120000, 140000, 370000, 130000, 150000]],
        3: ['华中', [430000, 420000, 410000]],
        4: ['华南', [440000, 450000, 350000, 460000]],
        5: ['东北', [210000, 220000, 230000]],
        6: ['西北', [610000, 650000, 620000, 640000, 630000]],
        7: ['西南', [500000, 530000, 520000, 540000, 510000]],
        8: ['港澳台', [810000, 820000, 710000]],
        9: ['海外', [990000]],
    };

    var site_groups_pinyin = {
        1: ['A-G', [340000, 820000, 110000, 500000, 350000, 620000, 440000, 450000, 520000]],
        2: ['H-K', [460000, 130000, 230000, 410000, 420000, 430000, 320000, 360000, 220000]],
        3: ['L-S', [210000, 150000, 640000, 630000, 370000, 310000, 140000, 610000, 510000]],
        4: ['T-Z', [120000, 710000, 650000, 540000, 810000, 530000, 330000]],
        5: ['OS', [990000]],
    };

    var provinces_cach, provinces_cach_en;

    //初始化省份数据，放入site_city对象中，放入provinces_cach中
    var private_initProvinces = async function (Lcid) {
        var provinceCodes = [];
        jm.each(site_groups_pinyin, function (i, n) {
            jm.each(n[1], function (i2, n2) {
                provinceCodes.push(n2 + '');
            });
        });
        var provinces = await private_ajaxGetCityList(provinceCodes, Lcid);
        if (Lcid == 2052 || Lcid == 1028) {
            provinces_cach = provinces;
        } else {
            provinces_cach_en = provinces;
        }
    };

    var private_ajaxGetAllCityList = async function (Lcid) {
        var rtList = [];
        let result = await getAllCityInfoApi();
        let { success, data, msg } = result;
        if (success) {
            if (Lcid == 2052 || Lcid == 1028) {
                site_city = data.cityInfoList;
            } else {
                site_city_en = data.cityInfoList;
            }
            rtList = data.cityInfoList;
        } else {
            console.log(msg);
        }
        return rtList;
    };

    //ajax根据code列表获取city集合
    var private_ajaxGetCityList = async function (list, Lcid) {
        var rtList = [];
        let result = await getCityInfoApi({
            cityCodeList: jm.toJSON(list),
        });
        let { success, data, msg } = result;
        if (success) {
            jm.each(data.cityInfoList, function (i, n) {
                //将返回的数据放到site_city对象缓存中
                if (Lcid == 2052 || Lcid == 1028) {
                    site_city[n.cityCode] = n.cityInfo;
                } else {
                    site_city_en[n.cityCode] = n.cityInfo;
                }
            });
            rtList = data.cityInfoList;
        } else {
            console.log(msg);
        }

        return rtList;
    };

    //ajax获取子元素
    var private_cityGetChildren = async function (code, Lcid, type) {
        if (!code || isNaN(code) || code <= 0) {
            return [];
        }
        var tmp_site_city = [];
        if (Lcid == 2052 || Lcid == 1028) {
            tmp_site_city = site_city;
        } else {
            tmp_site_city = site_city_en;
        }
        if (typeof tmp_site_city[code] == 'undefined' || tmp_site_city[code] == null) {
            if (typeof code !== 'string') {
                code = code + '';
            }
            private_ajaxGetCityList(new Array(code), Lcid);
        }
        var children = undefined;
        if (tmp_site_city[code]) {
            children = tmp_site_city[code][2]; //先取数组中的子元素，没有在发ajax获取
        }
        if (children) {
            if (children.length == 0) {
                //取街道但是缓存里面有空数组
                if (type == 'street') {
                    children = undefined;
                }
            } else if (type == 'cityOrCounty' && children[0].id.length > 6) {
                //取县区但是里面有非空数组
                children = undefined;
            }
        }
        if (!children) {
            let result = await cityGetChildrenApi({
                type,
                //Lcid,
                code,
            });
            let { success, data, msg } = result;
            if (success) {
                children = data.cityInfoList;
                if (tmp_site_city[code]) {
                    tmp_site_city[code][2] = children;
                }
                jm.each(children, function (i, n) {
                    //把子元素放入site_city缓存中
                    var tmpCityInfo = [];
                    tmpCityInfo.push(n.name);
                    tmpCityInfo.push(n.parentid);
                    tmp_site_city[n.id] = tmpCityInfo;
                });
            } else {
                children = [];
                console.log(msg);
            }
            // jm.ajax({
            //     url:Mobi.addRequestPrefix({ newPath: '/ajax', oldPath: 'ajax'})  + '/city_h.jsp?cmd=getWafNotCk_cityGetChildren&type='+ type +'&Lcid='+ Lcid +'&code='+code,
            //     type : "POST",
            //     async : false,
            //     success : function(result){
            //         result = jm.parseJSON(result);
            //         if(result.success){
            //             children = result.childList;
            //             if(tmp_site_city[code]){
            //             	tmp_site_city[code][2] = children;
            //             }
            //             jm.each(children,function (i,n) {//把子元素放入site_city缓存中
            //                 var tmpCityInfo = [];
            //                 tmpCityInfo.push(n.name);
            //                 tmpCityInfo.push(n.parentid);
            //                 tmp_site_city[n.id] = tmpCityInfo;
            //             });
            //         }else{
            //             children = [];
            //         }
            //     },
            //     error : function (err) {
            //         children = [];
            //     }
            // });
        }
        return children;
    };

    var private_getProvinceList = async function (groupid, isChinese) {
        //var Lcid = 2052;
        var tmp_provinces_cach = provinces_cach;
        if (!isChinese) {
            //Lcid = 1033;
            tmp_provinces_cach = provinces_cach_en;
        }
        var provinces = [];
        var groupProvince;
        if (groupid) {
            var tmp = site_groups[groupid];
            if (tmp) {
                groupProvince = tmp[1] || [];
            }
        }
        if (!tmp_provinces_cach) {
            tmp_provinces_cach = [];
            let result = await getProvinceInfoApi();
            let { data, success, msg } = result;
            if (success) {
                tmp_provinces_cach = data.provinceInfoList;
            } else {
                console.log(msg);
            }
            provinces = tmp_provinces_cach;

            if (isChinese) {
                provinces_cach = tmp_provinces_cach;
            } else {
                provinces_cach_en = tmp_provinces_cach;
            }
        } else {
            provinces = tmp_provinces_cach;
        }

        if (!groupProvince || groupProvince.length <= 0) {
            return tmp_provinces_cach;
        } else {
            provinces = [];
            jm.each(tmp_provinces_cach, function (i, v) {
                if (groupProvince && jm.inArray(parseInt(v.id), groupProvince) >= 0) {
                    provinces.push(v);
                }
            });
            return provinces;
        }
    };

    var private_provinceCodeValid = function (code) {
        if (typeof code !== 'string') {
            code = code + '';
        }
        //如果JS缓存对象里没有数据，则ajax获取并设入JS缓存对象中
        if (site_city[code] == '' || site_city[code] == 'undefined' || site_city[code] == null) {
            private_ajaxGetCityList(new Array(code), 2052);
        }
        if (site_city[code] && site_city[code][1] === '1') {
            return true;
        }
        return false;
    };

    var private_cityCodeValid = function (cityCode, provinceCode) {
        //传provinceCode时则校对该cityCode是否为provinceCode下的city.若不传provinceCode则不校对
        if (typeof cityCode !== 'string') {
            cityCode = cityCode + '';
        }
        if (site_city[cityCode] == '' || site_city[cityCode] == 'undefined' || site_city[cityCode] == null) {
            private_ajaxGetCityList(new Array(cityCode), 2052);
        }
        if (provinceCode) {
            if (typeof provinceCode !== 'string') {
                provinceCode = provinceCode + '';
            }
            if (
                site_city[provinceCode] == '' ||
                site_city[provinceCode] == 'undefined' ||
                site_city[provinceCode] == null
            ) {
                private_ajaxGetCityList(new Array(provinceCode), 2052);
            }
            if (
                site_city[cityCode] &&
                site_city[cityCode][1] === provinceCode &&
                private_provinceCodeValid(provinceCode)
            ) {
                return true;
            }
        } else {
            if (site_city[cityCode] && site_city[cityCode][1] !== '1') {
                return true;
            }
        }
        return false;
    };

    var private_simpleCityName = function (name) {
        if (!name) {
            return;
        }
        if (name.length <= 3) {
            //优先判断3个长度的	这种情况比较多
            if (
                name.slice(name.length - 1, name.length) == '市' ||
                name.slice(name.length - 1, name.length) == '县' ||
                name.slice(name.length - 1, name.length) == '盟'
            ) {
                return name.slice(0, name.length - 1);
            }
        }
        var tmp;
        tmp = name.slice(name.length - 9);
        if (tmp == '群岛的岛礁及其海域') {
            return name.slice(0, name.length - 9);
        }
        tmp = name.slice(name.length - 8);
        if (
            tmp == '土家族苗族自治州' ||
            tmp == '傣族景颇族自治州' ||
            tmp == '哈尼族彝族自治州' ||
            tmp == '布依族苗族自治州' ||
            tmp == '蒙古族藏族自治州'
        ) {
            return name.slice(0, name.length - 8);
        }
        tmp = name.slice(name.length - 7);
        if (tmp == '藏族羌族自治州' || tmp == '壮族苗族自治州' || tmp == '黎族苗族自治县' || tmp == '苗族侗族自治州') {
            return name.slice(0, name.length - 7);
        }
        tmp = name.slice(name.length - 6);
        if (tmp == '傈僳族自治州' || tmp == '哈萨克自治州') {
            return name.slice(0, name.length - 6);
        }
        tmp = name.slice(name.length - 5);
        if (
            tmp == '彝族自治州' ||
            tmp == '藏族自治州' ||
            tmp == '白族自治州' ||
            tmp == '傣族自治州' ||
            tmp == '黎族自治县' ||
            tmp == '回族自治州' ||
            tmp == '蒙古自治州'
        ) {
            return name.slice(0, name.length - 5);
        }

        tmp = name.slice(name.length - 4);
        if (tmp == '') {
            return name.slice(0, name.length - 4);
        }
        tmp = name.slice(name.length - 3);
        if (tmp == '蒙古族' || tmp == '自治州') {
            return name.slice(0, name.length - 3);
        }
        tmp = name.slice(name.length - 2);
        //"神农架林区"  -->  神农架
        if (tmp == '群岛' || tmp == '地区' || tmp == '林区') {
            return name.slice(0, name.length - 2);
        }
        //阿拉善盟
        if (
            name.slice(name.length - 1, name.length) == '市' ||
            name.slice(name.length - 1, name.length) == '县' ||
            name.slice(name.length - 1, name.length) == '盟'
        ) {
            return name.slice(0, name.length - 1);
        }
        return name;
    };

    var private_getProvince = function (groupid) {
        return private_getProvinceList(groupid, true);
    };

    var private_get_cityOrcounty = async function (code) {
        return await private_cityGetChildren(code, 2052, 'cityOrCounty');
    };

    var private_get_street = async function (code) {
        return await private_cityGetChildren(code, 2052, 'street');
    };

    var private_getInfo = async function (id) {
        if (!id) {
            return { id: id, name: '', parentId: 0 };
        }
        var tmp = site_city[id];
        if (!tmp) {
            id = id + '';
            tmp = site_city[id];
        }
        if (tmp) {
            return { id: id, name: tmp[0], parentId: tmp[1] };
        } else {
            if (typeof id !== 'string') {
                id = id + '';
            }
            var rtList = await private_ajaxGetCityList(new Array(id), 2052);
            if (rtList.length > 0) {
                tmp = rtList[0].cityInfo;
            }
            if (tmp) {
                return { id: id, name: tmp[0], parentId: tmp[1] };
            } else {
                return { id: id, name: '', parentId: 0 };
            }
        }
    };

    var private_simpleProvinceName = function (name) {
        if (!name) {
            return;
        }
        if (name.length == 3 && name.slice(name.length - 1, name.length) == '省') {
            return name.slice(0, name.length - 1);
        }
        var tmp = name.slice(name.length - 6);
        if (tmp == '维吾尔自治区') {
            return name.slice(0, name.length - 6);
        }
        tmp = name.slice(name.length - 5);
        if (tmp == '特别行政区' || tmp == '壮族自治区' || tmp == '回族自治区') {
            return name.slice(0, name.length - 5);
        }
        tmp = name.slice(name.length - 3);
        if (tmp == '自治区') {
            return name.slice(0, name.length - 3);
        }
        if (name.slice(name.length - 1, name.length) == '省') {
            return name.slice(0, name.length - 1);
        }
        return name;
    };
    var private_getProvinceEn = function (groupid) {
        return private_getProvinceList(groupid, false);
    };
    var private_get_cityOrcountyEn = function (code) {
        return private_cityGetChildren(code, 1033, 'cityOrCounty');
    };
    var private_get_streetEn = function (code) {
        return private_cityGetChildren(code, 1033, 'street');
    };

    var private_getInfoEn = async function (id) {
        if (!id) {
            return { id: id, name: '', parentId: 0 };
        }
        var tmp = site_city_en[id];
        if (tmp) {
            return { id: id, name: tmp[0], parentId: tmp[1] };
        } else {
            if (typeof id !== 'string') {
                id = id + '';
            }
            var rtList = await private_ajaxGetCityList(new Array(id), 1033);
            if (rtList.length > 0) {
                tmp = rtList[0].cityInfo;
            }
            if (tmp) {
                return { id: id, name: tmp[0], parentId: tmp[1] };
            } else {
                return { id: id, name: '', parentId: 0 };
            }
        }
    };
    var private_simpleProvinceNameEn = function (name) {
        if (!name) {
            return;
        }
        if (name.slice(name.length - 29, name.length) == 'Special Administrative Region') {
            name = name.slice(0, name.length - 30);
        }
        if (name.slice(name.length - 17, name.length) == 'Autonomous Region') {
            name = name.slice(0, name.length - 18);
        }
        if (
            name.slice(name.length - 8, name.length) == 'Province' ||
            name.slice(name.length - 8, name.length) == 'province'
        ) {
            name = name.slice(0, name.length - 9);
        }
        if (name.slice(name.length - 6, name.length) == 'Zhuang') {
            name = name.slice(0, name.length - 7);
        }

        if (name.slice(name.length - 5, name.length) == 'Uygur') {
            name = name.slice(0, name.length - 6);
        }
        if (name.slice(name.length - 3, name.length) == 'Hui') {
            name = name.slice(0, name.length - 4);
        }
        return name;
    };
    var private_simpleCityNameEn = function (name) {
        if (!name) {
            return;
        }
        if (name.slice(name.length - 29, name.length) == 'Tibetan Autonomous Prefecture') {
            name = name.slice(0, name.length - 30);
        }
        if (name.slice(name.length - 22, name.length) == 'Li and Miao Autonomous') {
            name = name.slice(0, name.length - 23);
        }
        if (name.slice(name.length - 13, name.length) == 'Li Autonomous') {
            name = name.slice(0, name.length - 14);
        }
        if (name.slice(name.length - 10, name.length) == 'Prefecture') {
            name = name.slice(0, name.length - 11);
        }
        if (name.slice(name.length - 9, name.length) == 'Mongolian') {
            name = name.slice(0, name.length - 10);
        }
        if (name.slice(name.length - 6, name.length) == 'County') {
            name = name.slice(0, name.length - 7);
        }
        if (name.slice(name.length - 4, name.length) == 'City') {
            name = name.slice(0, name.length - 5);
        }
        return name;
    };

    //从js缓存对象里获取子元素（此方法适用于site_city对象中是全部数据时）
    var private_getCityFormJScache = function (Lcid, code) {
        var tmpList = [];
        var rtList = [];
        if (Lcid == 2052 || Lcid == 1028) {
            tmpList = site_city;
        } else {
            tmpList = site_city_en;
        }
        jm.each(tmpList, function (i, n) {
            var tmpCity = {};
            if (n[1] == code) {
                tmpCity['id'] = i;
                tmpCity['name'] = n[0];
                tmpCity['parentid'] = n[1];
                rtList.push(tmpCity);
            }
        });
        return rtList;
    };

    // 装入所有国省市的下级区域数量, 并返回该id下级区域数量，第一次时间复杂度O(n)，其他时候O(1)
    var private_loadcomputerCount = function (Lcid, id) {
        if (site_city_computer != null) {
            return site_city_computer[id];
        }
        site_city_computer = {};
        var tmpList = [];
        if (Lcid == 2052 || Lcid == 1028) {
            tmpList = site_city;
        } else {
            tmpList = site_city_en;
        }

        $.each(tmpList, function (i, n) {
            var tmpCity = site_city_computer[n[1]] || 0;
            tmpCity++;
            site_city_computer[n[1]] = tmpCity;
        });
        return site_city_computer[id];
    };

    const getCitiesByLcid = async (provinceId, lcid) => {
        let citiesOrCounties = {};
        if (lcid == 2052 || lcid == 1028) {
            citiesOrCounties = await site_cityUtil.getCities(provinceId);
        } else {
            citiesOrCounties = await site_cityUtil.getCitiesEn(provinceId);
        }
        return citiesOrCounties ?? [];
    };

    const private_getDataFromLocationString = async (location = '', lcid) => {
        // province
        let provinces = {};
        if (lcid == 2052 || lcid == 1028) {
            provinces = await site_cityUtil.getProvince();
        } else {
            provinces = await site_cityUtil.getProvinceEn();
        }
        let selectedProvince = provinces.find((province) => location.startsWith(province.name));

        if (!selectedProvince) return {};

        const data = [selectedProvince];

        // city
        const cities = await getCitiesByLcid(selectedProvince.id, lcid);
        let selectedCity = cities.find((city) => location.includes(city.name));

        if (!selectedCity) return {};
        data.push(selectedCity);

        // county
        const counties = await getCitiesByLcid(selectedCity.id, lcid);
        let selectedCounty = counties.find((county) => location.includes(county.name));
        if (selectedCounty) {
            data.push(selectedCounty);
        }

        return data;
    };

    return {
        getAreaGroups: function () {
            return site_groups;
        },
        getAreaGroupsPinYin: function () {
            return site_groups_pinyin;
        },
        getProvince: function (groupid) {
            return private_getProvince(groupid);
        },
        getCities: async function (provinceid) {
            return await private_get_cityOrcounty(provinceid);
        },
        getCounty: async function (cityId) {
            return await private_get_cityOrcounty(cityId);
        },
        getStreet: function (countId) {
            return private_get_street(countId);
        },
        getInfo: function (id) {
            return private_getInfo(id);
        },
        simpleProvinceName: function (list) {
            jm.each(list, function (i) {
                list[i].name = private_simpleProvinceName(list[i].name);
            });
        },
        simpleProvinceNameStr: function (name) {
            return private_simpleProvinceName(name);
        },
        //用于Array
        simpleCityName: function (list) {
            jm.each(list, function (i) {
                list[i].name = private_simpleCityName(list[i].name);
            });
        },
        //用于str
        simpleCityNameStr: function (name) {
            return private_simpleCityName(name);
        },
        //用于外语版
        simpleProvinceNameEn: function (list) {
            jm.each(list, function (i) {
                list[i].name = private_simpleProvinceNameEn(list[i].name);
            });
        },
        simpleCityNameEn: function (list) {
            jm.each(list, function (i) {
                list[i].name = private_simpleCityNameEn(list[i].name);
            });
        },
        simpleCityNameStrEn: function (name) {
            return private_simpleCityNameEn(name);
        },
        simpleProvinceNameStrEn: function (name) {
            return private_simpleProvinceNameEn(name);
        },
        getProvinceEn: function (groupid) {
            return private_getProvinceEn(groupid);
        },
        getCitiesEn: function (provinceid) {
            return private_get_cityOrcountyEn(provinceid);
        },
        getCountyEn: function (cityId) {
            return private_get_cityOrcountyEn(cityId);
        },
        getStreetEn: function (countyId) {
            return private_get_streetEn(countyId);
        },
        getInfoEn: function (id) {
            return private_getInfoEn(id);
        },
        //尽可能的合并城市名称为省名称
        // citylist : 城市code list
        simpleCityListStr: function (citylist) {
            if (!citylist || citylist.length <= 0) {
                return '未添加地区';
            }
            var provincelist = {};
            jm.each(citylist, function (i, e) {
                if (('' + e).length < 6) {
                    return;
                }
                var obj = private_getInfo(e);
                if (provincelist[obj.parentId]) {
                    provincelist[obj.parentId].count++;
                } else {
                    provincelist[obj.parentId] = { count: 1, objs: [] };
                }
                provincelist[obj.parentId].objs.push(obj);
            });
            var provincestr = [];
            var citystr = [];
            jm.each(provincelist, function (i, o) {
                var citylength = private_loadcomputerCount(2052, i);
                if (citylength == o.count) {
                    provincestr.push(private_simpleProvinceName(private_getInfo(i).name));
                    provincestr.push('、');
                } else {
                    jm.each(o.objs, function (tmpi, tmpo) {
                        citystr.push(private_simpleCityName(tmpo.name));
                        citystr.push('、');
                    });
                }
            });
            var result = provincestr.join('') + citystr.join('');
            return result.slice(0, result.length - 1);
        },
        isValidProvince: function (code) {
            return private_provinceCodeValid(code);
        },
        isValidCity: function (cityCode, provinceCode) {
            return private_cityCodeValid(cityCode, provinceCode);
        },
        getCityList: function (list, Lcid) {
            return private_ajaxGetCityList(list, Lcid);
        },
        getAllCityInfo: function (Lcid) {
            return private_ajaxGetAllCityList(Lcid);
        },
        initProvinces: function (Lcid) {
            return private_initProvinces(Lcid);
        },
        getCityFormJScache: function (Lcid, code) {
            return private_getCityFormJScache(Lcid, code);
        },
        getDataFromLocationString(location = '', lcid) {
            return private_getDataFromLocationString(location, lcid);
        },
    };
})();
