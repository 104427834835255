import { MobiModule } from '@/modules/Module';
import PhotoGroupDetailView from './photo-group-detail.vue';

class PhotoGroupDetailModule extends MobiModule {
    constructor() {
        super(null /** 系统模块没有style */, { system: true });
    }

    render() {
        return PhotoGroupDetailView;
    }
}

export const PhotoGroupDetail = new PhotoGroupDetailModule();
