var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "newsDetail",
      class: _vm.themeTypeClass,
      attrs: { id: "newsDetail" + _vm.moduleId },
    },
    [
      _vm.isEmptyObject(_vm.info)
        ? _c("div", { staticClass: "fk_newsDetailNotFound" }, [
            _vm._v(_vm._s(_vm.LS.newsDetailNotFound)),
          ])
        : _c(
            "div",
            { class: _vm.pageStyleCls, attrs: { id: "newsDetailPanel" } },
            [
              !_vm.options.hideNews
                ? _c(
                    "div",
                    {
                      staticClass: "newsFigure",
                      class: _vm.newsFigureClass,
                      style: !_vm.picturePath
                        ? _vm.noFigureStyle
                        : _vm.lazyLoadStyle,
                      attrs: {
                        id: "newsFigure" + _vm.moduleId,
                        alt: "封面图",
                        "src-original": _vm.picturePath,
                      },
                    },
                    [
                      _vm.isNewNewsDetail
                        ? _c(
                            "div",
                            {
                              staticClass: "newsInfoTitle",
                              class: _vm.newsInfoTitleCls,
                            },
                            [
                              _c(
                                "div",
                                {
                                  class: _vm.titleWrapClass,
                                  style: _vm.titleWrapStyle,
                                },
                                [
                                  _c(
                                    "h1",
                                    {
                                      staticClass: "textTitle",
                                      style: _vm.titleH1Style,
                                    },
                                    [_vm._v(_vm._s(_vm.info.title))]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.showSubTitle
                                ? _c("div", {
                                    class: _vm.subTitleClass,
                                    domProps: {
                                      textContent: _vm._s(_vm.subTitle),
                                    },
                                  })
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !(_vm.isNewNewsDetail && _vm.pageStyle == 0) &&
              !_vm.options.hideNews
                ? _c(
                    "div",
                    {
                      staticClass: "newsInfoTitle",
                      class: _vm.newsInfoTitleCls,
                      style: _vm.newsInfoTitleStyle,
                    },
                    [
                      _c("div", { class: _vm.titleWrapClass }, [
                        _c(
                          "h1",
                          { staticClass: "textTitle", style: _vm.titleH1Style },
                          [_vm._v(_vm._s(_vm.info.title))]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.showSubTitle
                        ? _c("div", {
                            class: _vm.subTitleClass,
                            domProps: { textContent: _vm._s(_vm.subTitle) },
                          })
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "newsContentPanel" },
                [
                  !_vm.options.hideNews &&
                  (_vm.showDate || _vm.showAuthor || _vm.showSource)
                    ? _c(
                        "div",
                        {
                          staticClass: "newsCustomPanel",
                          class: {
                            notNewNewsCustomPanel: !_vm.isNewNewsDetail,
                          },
                        },
                        [
                          _vm.isNewNewsDetail
                            ? [
                                _vm.showDate
                                  ? _c(
                                      "div",
                                      { staticClass: "newsCustom newsDate" },
                                      [_vm._v(_vm._s(_vm.newsDate))]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showAuthor
                                  ? _c(
                                      "div",
                                      { staticClass: "newsCustom newsAuthor" },
                                      [_vm._v(_vm._s(_vm.info.author))]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showViews
                                  ? _c(
                                      "div",
                                      { staticClass: "newsCustom newsView" },
                                      [
                                        _c("span", {
                                          staticClass: "icon-views g_mainColor",
                                        }),
                                        _vm._v(_vm._s(_vm.info.views)),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.shareOn &&
                                _vm.options.templateDesignType == 2
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "icon-share newsShare",
                                        attrs: { id: "newsDetailV3Share" },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.shareLs)),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            : [
                                _vm.showAuthor
                                  ? _c(
                                      "div",
                                      { staticClass: "newsCustom newsAuthor" },
                                      [_vm._v(_vm._s(_vm.info.author))]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showDate
                                  ? _c(
                                      "div",
                                      { staticClass: "newsCustom newsDate" },
                                      [_vm._v(_vm._s(_vm.newsDate))]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showSource
                                  ? _c(
                                      "div",
                                      { staticClass: "newsCustom newsSource" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.newsSourceLs) +
                                            _vm._s(_vm.info.source)
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "newsContent",
                      class: {
                        blankBottom: _vm.noBlankBottom,
                        richContent: !_vm.isNewNewsDetail,
                        hide_news: _vm.options.hideNews,
                      },
                      style: _vm.bottomMargin,
                    },
                    [
                      _vm.options.hideNews
                        ? [
                            _c("div", { staticClass: "newsDetail_hide" }, [
                              _c("div", {
                                staticClass: "newsDetail_hide--img",
                              }),
                              _c(
                                "p",
                                { staticClass: "newsDetail_hide--text" },
                                [_vm._v(_vm._s(_vm.options.jz_newsAudit_tips))]
                              ),
                            ]),
                          ]
                        : _c("html-render", {
                            attrs: {
                              html: _vm.newsContent,
                              "ref-name": "newsContent",
                            },
                          }),
                      _vm._v(" "),
                      !_vm.isExternal && _vm.module.copyrightNoticeOpen
                        ? _c(
                            "div",
                            { staticClass: "copyright_notice_content" },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.module.copyrightNoticeContent)
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "copyright_notice_content_href",
                                  attrs: {
                                    target: "_blank",
                                    href: _vm.module.newsLink,
                                  },
                                },
                                [_vm._v(_vm._s(_vm.module.newsLink))]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.totalSize > 1
                        ? _c("pagenation", {
                            attrs: {
                              "module-id": _vm.moduleId,
                              "total-size": _vm.totalSize,
                              "page-size": 1,
                              pageno: _vm.pageno,
                              "open-theme-v3": _vm.openThemeV3,
                              "ajax-pagenation": true,
                            },
                            on: { "on-change": _vm.changePage },
                            nativeOn: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.viewList.length > 0 && !_vm.options.hideNews
                    ? _c(
                        "div",
                        { staticClass: "attachList" },
                        _vm._l(_vm.viewList, function (item, index) {
                          return _c("attach", {
                            key: "attach_" + index,
                            attrs: { info: item },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isNewNewsDetail
                    ? [
                        _vm.showSource && _vm.info.source
                          ? _c("div", { staticClass: "newsSource" }, [
                              _vm._v(
                                _vm._s(_vm.newsSourceLs) +
                                  _vm._s(_vm.info.source)
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showLink
                          ? _c("div", { staticClass: "newsLink" }, [
                              _c("span", {
                                domProps: { innerHTML: _vm._s(_vm.newsLinkLs) },
                              }),
                              _c("a", { attrs: { href: _vm.info.link } }, [
                                _vm._v(_vm._s(_vm.info.link)),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showNewsGroup
                          ? _c(
                              "div",
                              { staticClass: "newsGroup" },
                              [
                                _c("span", {
                                  staticClass:
                                    "icon-group g_mainColor newsGroupIcon",
                                }),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.groupDataList,
                                  function (item, index) {
                                    return _c(
                                      "a",
                                      {
                                        key: `groupItem_${index}`,
                                        staticClass: "newsGroupTag g_mainColor",
                                        attrs: {
                                          href: item.href,
                                          target: "_self",
                                        },
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    )
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    : [
                        _vm.showNewsGroup
                          ? _c(
                              "div",
                              { staticClass: "newsGroup" },
                              _vm._l(_vm.groupDataList, function (item, index) {
                                return _c(
                                  "a",
                                  {
                                    key: `groupItem_${index}`,
                                    staticClass: "newsGroupTag",
                                    attrs: { href: item.href, target: "_self" },
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showLink || _vm.showViews
                          ? _c(
                              "div",
                              {
                                staticClass: "newsCustomPanel2",
                                class: _vm.jumpLinkMargin,
                              },
                              [
                                _vm.showLink
                                  ? _c("div", { staticClass: "newsCustom" }, [
                                      _c(
                                        "a",
                                        { attrs: { href: _vm.info.link } },
                                        [_vm._v(_vm._s(_vm.originalLs))]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showViews
                                  ? _c(
                                      "div",
                                      { staticClass: "newsCustom read_count" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.readLs) +
                                            _vm._s(_vm.info.views)
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ],
                  _vm._v(" "),
                  !_vm.isNewNewsDetail && _vm.shareOn
                    ? _c(
                        "div",
                        {
                          staticClass: "icon-share newsShare",
                          attrs: { id: "newsDetailV3Share" },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.shareLs))])]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showPrevNext
                    ? _c("news-pagiation", {
                        attrs: { "open-theme-v3": _vm.openThemeV3 },
                      })
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _vm.openThemeV3 && !_vm.isOpen && _vm.shareOn
                ? _c("div", { staticClass: "only_share" }, [
                    _c("span", {
                      staticClass:
                        "icon-share g_iconMiddle g_round newsShare icon-defaultColor",
                      attrs: { id: "newsDetailShare" },
                    }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isClickImg
                ? [
                    _vm.showMobiViewer
                      ? _c("image-viewer", {
                          attrs: {
                            "z-index": _vm.zIndex,
                            "initial-index": _vm.initialIndex,
                            "on-close": _vm.onClose,
                            "url-list": _vm.images,
                            "is-mobi": true,
                            "content-terminal": "mobi",
                            "manage-mode": _vm.manageMode,
                            "document-event-el": _vm.documentEventEl,
                            "append-to-container-id": "g_body",
                            "append-to-body": false,
                            "disable-long-press": _vm.banCopyTextImg,
                          },
                        })
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("div", { attrs: { id: "renderInNewsDetailComment" } }),
            ],
            2
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }