//类window.prompt()弹窗
export const prompt = function (options) {
    var defaultOptions = {
        promptWrap: '',
        content: '',
        textClass: '',
        cancelClass: '',
        insertSelector: 'body',
    };

    options = jm.extend(defaultOptions, options);

    var id = parseInt(Math.random() * 10000);

    jm('.mobiPrompt').remove();
    jm('.mobiPrompt_Bg').remove();
    var html = "<div id='mobiPrompt" + id + "' class='mobiPrompt " + options.promptWrap + " '></div>",
        bg = "<div id='mobiPrompt_Bg" + id + "' class='mobiPrompt_Bg'></div>";

    jm(html).appendTo(options.insertSelector);
    jm(bg).appendTo(options.insertSelector);

    var prompt = jm('#mobiPrompt' + id),
        prompt_Bg = jm('#mobiPrompt_Bg' + id);

    var tipsPanel = [];

    tipsPanel.push("<div class='mobiPrompt_inner'>");
    tipsPanel.push("<div class=' mobiPrompt_content'>");
    tipsPanel.push("<div class=''>" + options.content + '</div>');
    tipsPanel.push("<div class='J-prompt-cancel icon-mobiPromptClose'></div>");
    tipsPanel.push('</div>');
    tipsPanel.push("<div class=' mobiPrompt_buttons '>");
    tipsPanel.push(
        "<div class='J-prompt-cancel mobiPromptCancel " + options.cancelClass + " icon-PromptCancel'></div>"
    );
    tipsPanel.push("<div class='J-prompt-save mobiPromptSave " + options.textClass + " icon-PromptSave'></div>");
    tipsPanel.push('</div>');
    tipsPanel.push('</div>');
    prompt.append(tipsPanel.join(''));

    if (options.callback) {
        prompt.find('.J-prompt-save').on('click', function () {
            prompt.removeClass('mobiPromptShow');
            prompt_Bg.removeClass('mobiPromptBgShow');
            setTimeout(function () {
                prompt.remove();
                prompt_Bg.remove();
            }, 200);
            options.callback();
        });
    }

    if (options.cancelCallback) {
        prompt.find('.J-prompt-cancel').on('click', function () {
            prompt.removeClass('mobiPromptShow');
            prompt_Bg.removeClass('mobiPromptBgShow');
            setTimeout(function () {
                prompt.remove();
                prompt_Bg.remove();
            }, 200);
            options.cancelCallback();
        });
    } else {
        prompt.find('.J-prompt-cancel').on('click', function () {
            prompt.removeClass('mobiPromptShow');
            prompt_Bg.removeClass('mobiPromptBgShow');
            setTimeout(function () {
                prompt.remove();
                prompt_Bg.remove();
            }, 200);
        });
    }

    window.setTimeout(function () {
        prompt_Bg.addClass('mobiPromptBgShow');
        prompt.addClass('mobiPromptShow');
    }, 100);
};
