var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "memberBasicInfoArea" }, [
      _c("div", { staticClass: "memberBasicInfo" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("span", {
          staticClass: "memberName",
          domProps: { textContent: _vm._s(_vm.memberNameText) },
        }),
        _vm._v(" "),
        _vm.memberLevelAuthInfo.allow
          ? _c("span", {
              staticClass: "memberLevelName",
              domProps: { textContent: _vm._s(_vm.memberLevelName) },
            })
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "modifyArea" },
      [
        _vm.isOpenKnowPayment
          ? _c("li", { attrs: { id: "knowPayment" } }, [
              _c("div", { staticClass: "itemName" }, [
                _c("svg", { staticStyle: { left: "0.5rem" } }, [
                  _c("use", { attrs: { "xlink:href": "#jzm-vicon_mall" } }),
                ]),
                _c("span", {
                  staticStyle: { "margin-left": "0.6rem" },
                  domProps: { textContent: _vm._s(_vm.LS.minePayKnowProducts) },
                }),
              ]),
              _vm._v(" "),
              _vm._m(1),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.integralOpen
          ? _c("li", { attrs: { id: "integral" } }, [
              _c("div", { staticClass: "itemName" }, [
                _c("span", {
                  staticClass: "icon-integralIcon",
                  class: { newProfileIcon: _vm.isNewTheme },
                }),
                _c("span", {
                  domProps: {
                    textContent: _vm._s(_vm.LS.integral_mine + _vm.itgName),
                  },
                }),
              ]),
              _vm._v(" "),
              _vm._m(2),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isCrownVer
          ? [
              _vm.openCollection
                ? _c("li", { attrs: { id: "collection" } }, [
                    _c("div", { staticClass: "itemName" }, [
                      _c("span", {
                        staticClass: "icon-starIcon",
                        class: { newProfileIcon: _vm.isNewTheme },
                      }),
                      _c("span", {
                        domProps: { textContent: _vm._s(_vm.LS.my_collection) },
                      }),
                    ]),
                    _vm._v(" "),
                    _vm._m(3),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.openCoupon
                ? _c("li", { attrs: { id: "coupon" } }, [
                    _c("div", { staticClass: "itemName" }, [
                      _c("span", {
                        staticClass: "icon-coupons",
                        class: { newProfileIcon: _vm.isNewTheme },
                      }),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.LS.integral_mine + _vm.LS.coupons
                          ),
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _vm._m(4),
                  ])
                : _vm._e(),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.isOpenMyForm
          ? _c(
              "li",
              { attrs: { id: "myForm", _noFormRecord: _vm.noFormRecord } },
              [
                _c("div", { staticClass: "itemName" }, [
                  _c("svg", [
                    _c("use", { attrs: { "xlink:href": "#jzm-v28" } }),
                  ]),
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(_vm.LS.mobi_member_myForm),
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "remind", class: _vm.hidden }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "clickIcon" }, [
                  _c("span", {
                    staticClass: "update_tips",
                    class: _vm.hidden,
                    domProps: { textContent: _vm._s(_vm.updateTips) },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "icon-clickIcon" }),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.memberBulletinOpen
          ? _c("li", { attrs: { id: "latestNews", orderlist: "1" } }, [
              _c("div", { staticClass: "itemName" }, [
                _c("span", {
                  staticClass: "icon-noticeIcon",
                  class: { newProfileIcon: _vm.isNewTheme },
                }),
                _c("span", {
                  domProps: { textContent: _vm._s(_vm.bulletinTitle) },
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "bulletinTitle",
                  class: _vm.newsReminds,
                  attrs: { id: "bulletinTitle", sessionMid: _vm.sessionMid },
                }),
              ]),
              _vm._v(" "),
              _vm._m(5),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isCn && _vm.hasPrize && _vm.isOpenLottery
          ? _c("li", { attrs: { id: "myreward" } }, [
              _c("div", { staticClass: "itemName" }, [
                _c("span", { staticClass: "icon-pInfoIcon" }),
                _c("span", {
                  domProps: {
                    textContent: _vm._s(_vm.LS.mobi_member_MyReward),
                  },
                }),
              ]),
              _vm._v(" "),
              _vm._m(6),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("li", { attrs: { id: "personalInfo" } }, [
          _c("div", { staticClass: "itemName" }, [
            _c("span", {
              staticClass: "icon-pInfoIcon",
              class: { newProfileIcon: _vm.isNewTheme },
            }),
            _c("span", {
              domProps: {
                textContent: _vm._s(_vm.LS.mobi_membert_PersonalInfo),
              },
            }),
          ]),
          _vm._v(" "),
          _vm._m(7),
        ]),
        _vm._v(" "),
        _c("li", { attrs: { id: "password" } }, [
          _c("div", { staticClass: "itemName" }, [
            _c("span", {
              staticClass: "icon-cPswIcon",
              class: { newProfileIcon: _vm.isNewTheme },
            }),
            _c("span", {
              domProps: {
                textContent: _vm._s(_vm.LS.mobi_membert_ResetPassword),
              },
            }),
          ]),
          _vm._v(" "),
          _vm._m(8),
        ]),
        _vm._v(" "),
        _vm.isOtherLogin
          ? _c("li", { attrs: { id: "changeOtherLoginAcct" } }, [
              _c("div", { staticClass: "itemName" }, [
                _c("span", {
                  staticClass: "icon-bangding",
                  class: { newProfileIcon: _vm.isNewTheme },
                }),
                _c("span", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.LS.mobi_membert_ChangeOtherLoginAcct
                    ),
                  },
                }),
              ]),
              _vm._v(" "),
              _vm._m(9),
            ])
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "onLogout", on: { click: _vm.onLogoutClick } }, [
      _c("span", { staticClass: "icon-cLogoutIcon" }),
      _vm._v(_vm._s(_vm.LS.mobi_member_onLogout)),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon-pnameIcon" }, [
      _c("img", { attrs: { id: "memberHeadPic" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clickIcon" }, [
      _c("span", { staticClass: "icon-clickIcon" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clickIcon" }, [
      _c("span", { staticClass: "icon-clickIcon" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clickIcon" }, [
      _c("span", { staticClass: "icon-clickIcon" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clickIcon" }, [
      _c("span", { staticClass: "icon-clickIcon" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clickIcon" }, [
      _c("span", { staticClass: "icon-clickIcon" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clickIcon" }, [
      _c("span", { staticClass: "icon-clickIcon" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clickIcon" }, [
      _c("span", { staticClass: "icon-clickIcon" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clickIcon" }, [
      _c("span", { staticClass: "icon-clickIcon" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clickIcon" }, [
      _c("span", { staticClass: "icon-clickIcon" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }