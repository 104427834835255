<template>
    <float-effect
        v-if="isShowFloatIcon"
        id="floatEffect"
        :icon-type="it"
        :img-src="imgSrc"
        :style-type="st"
        :icon-background-color="ibc"
        :icon-background-color-type="ibct"
        :icon-color="ic"
        :icon-color-type="ict"
        :jump-url="jumpInfo.u"
        :jump-info="jumpInfo"
    >
    </float-effect>
</template>

<script>
import floatEffect from './floatEffectIcon.vue';

export default {
    components: {
        floatEffect,
    },
    data() {
        return {};
    },
    computed: {
        isShowFloatIcon() {
            return this.ft === 0 && this.isShowFloatEffect;
        },
        ft: {
            get() {
                return this.$store.state.floatEffect.ft;
            },
        },
        it: {
            get() {
                return this.$store.state.floatEffect.it;
            },
        },
        st: {
            get() {
                return this.$store.state.floatEffect.st;
            },
        },
        ic: {
            get() {
                return this.$store.state.floatEffect.ic;
            },
        },
        ict: {
            get() {
                return this.$store.state.floatEffect.ict;
            },
        },
        ibc: {
            get() {
                return this.$store.state.floatEffect.ibc;
            },
        },

        ibct: {
            get() {
                return this.$store.state.floatEffect.ibct;
            },
        },
        imgSrc: {
            get() {
                return this.$store.state.floatEffect.imgSrc;
            },
        },
        jumpInfo: {
            get() {
                return this.$store.state.floatEffect.jumpInfo;
            },
        },
        isShowFloatEffect: {
            get() {
                return this.$store.getters['panelSetting/isShowFloatEffect'];
            },
        },
        isInit: {
            get() {
                return this.$store.state.floatEffect.isInit;
            },
            set(isInit) {
                this.$store.commit('floatEffect/updateIsInit', isInit);
            },
        },
    },
    async mounted() {
        if (VITE_APP_MODE !== 'visitor') {
            await this.$store.dispatch('floatEffect/loadFloatEffectInfo');
            this.isInit = true;
        }
        if (this.isShowFloatIcon) {
            this.fixPosition();
        }
    },
    methods: {
        isPCPreview() {
            const isInFrame = self != top;
            const isPC = (function () {
                var userAgentInfo = navigator.userAgent;
                var Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
                var flag = true;
                for (var v = 0; v < Agents.length; v++) {
                    if (userAgentInfo.indexOf(Agents[v]) > 0) {
                        flag = false;
                        break;
                    }
                }
                return flag;
            })();

            return !isInFrame && isPC;
        },
        fixPosition() {
            // 获取返回顶部的按钮位置，实时定位
            const isFixPosition = this.isPCPreview();
            const $body = jm('body');
            const $main = jm('#g_web');
            const isExitFloatZone = $body.length && $main.length;
            const $el = jm(this.$el);

            // 修复PC访客态预览位置
            if (isFixPosition && $el && isExitFloatZone) {
                // 仅当不是frame而且是pc访问时，生效
                const dx = ($body.offset().width - $main.offset().width) / 2;
                let left = $el.offset().left;
                const fixedLeft = left + dx;
                $el.css({
                    left: `${fixedLeft}px`,
                });
            }
        },
    },
};
</script>

<style></style>
