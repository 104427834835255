import request from '../request';

export function getSearchProjectList(id = 0) {
    return request.get('/ajax/siteCustomSearch_h.jsp', {
        params: {
            cmd: 'getWafNotCk_find',
            id,
        },
    });
}
