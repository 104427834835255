/**
 * @fileoverview
 * 前端性能相关
 */

import { getBrowserType } from '@/shared/browser';

export function report() {
    setTimeout(function () {
        if (window.performance) {
            var timing = performance.timing;
            // DNS 查询耗时
            var lookupDomainTime = timing.domainLookupEnd - timing.domainLookupStart;
            // 与服务器端建立连接所花费的时间
            var connectTime = timing.connectEnd - timing.connectStart;
            // request请求耗时
            var requestTime = timing.responseEnd - timing.requestStart;
            var initDomTreeTime = timing.domInteractive - timing.responseEnd;
            var domReadyTime = timing.domComplete - timing.domInteractive;

            var data = [];
            data.push('&dt=' + lookupDomainTime);
            data.push('&ct=' + connectTime);
            data.push('&rt=' + requestTime);
            data.push('&wt=' + initDomTreeTime);
            data.push('&pt=' + domReadyTime);
            data.push('&bt=' + getBrowserType());

            var url = '/ajax/mobistatistics_h.jsp?cmd=wafNotCk_report';
            jm.ajax({
                url,
                type: 'post',
                cache: false,
                data: data.join(''),
                success() {},
            });
        }
    }, 500);
}
