<template>
    <div
        :id="newsLineId"
        :topClassname="topClassName"
        :topSwitch="topSwitch"
        :newsId="id"
        :newsName="title"
        class="timeAxisNewsStyle newsLine J_picNum"
        :class="lineClass"
        picnum="6"
    >
        <div :id="lineBodyId" class="lineBody">
            <a hidefocus="true" :href="href" :target="target" :onclick="onclickStr">
                <div class="mixNewsStyleImgBox7">
                    <div class="mixNewsStyleImgBox7_left">
                        <div class="mixNewsStyleTime">{{ formatDate(newsDate) }}</div>
                        <div :class="[showLine ? 'mixNewsStyleTitle1' : 'mixNewsStyleTitle2']">
                            <div :class="topFlagClass"></div>{{ title }}
                        </div>
                    </div>
                    <div class="mixNewsStyleImgBox7_middleCicle"></div>
                    <div class="mixNewsStyleImgBox7_right">
                        <div id="newsTitlePic" :alt="alt" class="newsTitlePic J_newsTitlePic">
                            <svg xmlns="http://www.w3.org/2000/svg">
                                <defs>
                                    <clipPath id="clippath">
                                        <polygon points=""></polygon>
                                    </clipPath>
                                </defs>
                                <image
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    :xlink:href="tmpPicturePath"
                                    preserveAspectRatio="none"
                                    :alt="alt"
                                    style="clip-path: url(#clippath)"
                                ></image>
                            </svg>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import newsMoreMixin from '../mixins/news-more-mixin';
import { dayjs } from '@jz/utils';

export default {
    name: 'NewsMoreItemStyle7',
    mixins: [newsMoreMixin],
    data() {
        return {};
    },
    computed: {
        ...mapState('app', ['resRoot']),
        tmpPicturePath() {
            return this.pictureId === '' ? this.resRoot + '/image/default/noPic.jpg' : this.bigPicturePath;
        },
    },
    methods: {
        formatDate(date) {
            return dayjs(date).format('YYYY/MM/DD');
        },
    },
};
</script>

<style></style>
