export default {
    state: () => ({
        productList: [],
        libList: [],
        libPropListObj: {},
        productListSimple: [],
        allProductCount: 0,
    }),
    mutations: {
        updateProductList(state, productInfo) {
            var productList = state.productList;
            var filterNew = productList.filter(function (tmpNewInfo) {
                return tmpNewInfo.id === productInfo.id;
            })[0];

            if (filterNew) {
                Object.assign(filterNew, productInfo);
            } else {
                state.productList.push(productInfo);
            }
        },
        batchUpdateProductList(state, productList) {
            productList.forEach(function (info) {
                window.$store.commit('updateProductList', info);
            });
            //window.$store.commit('updateAllProductCount', state.productList.length);
        },
        delProduct(state, id) {
            var idx = -1;
            state.productList.find(function (item, index) {
                if (item.id === id) {
                    idx = index;
                }
                return item.id === id;
            });

            if (idx != -1) {
                state.productList.splice(idx, 1);
            }

            // 重刷所有绑定了该文章的文章列表模块
            Object.values(state.modules).forEach(function (module) {
                if (module.style == 6) {
                    module.extInfo.ntlHook = !module.extInfo.ntlHook;
                }
            });
        },
        batchAddProduct(state, productList) {
            productList.forEach(function (item) {
                state.productList.unshift(item);
            });
        },
        // 产品库
        batchAddProductLib(state, productLibList) {
            var libList = state.libList;
            productLibList.forEach(function (lib) {
                var libInfo = libList.filter(function (tmpInfo) {
                    return tmpInfo.id === lib.id;
                })[0];

                if (libInfo) {
                    Object.assign(libInfo, lib);
                } else {
                    state.libList.push(lib);
                }
            });
        },

        // 产品库参数
        updateLibPropListObj(state, libPropListObj) {
            for (var key in libPropListObj) {
                if (Object.hasOwnProperty.call(libPropListObj, key)) {
                    Vue.set(state.libPropListObj, key, libPropListObj[key]);
                }
            }
        },
        updateProductListSimple(state, productInfo) {
            var productList = state.productListSimple;
            var filterNew = productList.filter(function (tmpNewInfo) {
                return tmpNewInfo.id === productInfo.id;
            })[0];

            if (filterNew) {
                Object.assign(filterNew, productInfo);
            } else {
                state.productListSimple.push(productInfo);
            }
        },
        batchUpdateProductListSimple(state, productList) {
            productList.forEach(function (info) {
                window.$store.commit('updateProductListSimple', info);
            });
        },
        batchAddProductListSimple(state, productList) {
            productList.forEach(function (item) {
                state.productListSimple.push(item);
            });

            // 去重
            var map = {};
            for (var i = state.productListSimple.length - 1; i >= 0; i--) {
                var item = state.productListSimple[i];
                if (map[item.id]) {
                    state.productListSimple.splice(i, 1);
                } else {
                    map[item.id] = true;
                }
            }
        },
        updateAllProductCount(state, count) {
            state.allProductCount = count;
        },
    },
    actions: {
        batchUpdateProductListModule({ commit, rootState }, productList) {
            productList.forEach(function (info) {
                commit('updateProductList', info);
                // 重刷编辑面板中直接选择面板中的数据
                commit('updateProductListSimple', {
                    id: info.id,
                    name: info.name,
                });
            });

            // 重刷所有绑定了该产品的产品列表模块
            Object.values(rootState.currentPageModuleIdMap).forEach(function (module) {
                if (module.style == 3) {
                    module.extInfo.ntlHook = !module.extInfo.ntlHook;
                }
            });
        },
    },
};
