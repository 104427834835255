import { getPreviewObject } from '@/shared/getPreviewObject';
import { ValidateCode } from '@/shared/validate-code';
import { addMsgBoardData } from '@/api/module/msg-board';
import { getBitMemory } from '@/shared/flag';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { getUrlParam } from '@/shared/url';
import { getMsgBoardInfo } from '@/api/module/msg-board';

import { MsgBoardPropDef, MsgBoardDef, MODULE_ID } from '../prop-def';
import { dayjs } from '@jz/utils';
import { checkBit, mobiIng, popupWindow } from '@/shared/fai';
import { getSiteMultiLanguageUrl } from '@/shared/url';
import { setPublicMsg, delMsg } from '@/api/module/msg-board';
import { successHandle } from '@/shared/fai';
import { encodeHtml } from '@/shared/util';
import pictureSlidesMixin from './pictureSlidesMixin';
import Vue from 'vue';
import { MobileAuthPopupService, MOBILE_AUTH_MESSAGE_TYPES, MOBILE_AUTH_TIPS } from '@/services';
import { getThemeColor } from '@/shared/theme-color';
export default {
    mixins: [pictureSlidesMixin],
    filters: {
        inputTypeFilter: function (value) {
            if (value === 'reqEmail') {
                return 'email';
            } else if (value === 'reqPhone') {
                return 'tel';
            } else {
                return 'text';
            }
        },
        msgItemIdText: function (id) {
            return `msgItem${id}`;
        },
    },
    data() {
        return {
            MSGBOARD_PROP_DEF: MsgBoardPropDef,
            MSGBOARD_DEF: MsgBoardDef,
            manageMode: VITE_APP_MODE !== 'visitor',
            displayList: [],
            defaultEmail: '',
            defaultPhone: '',
            defaultCreator: '',
            openMsgAuto: false,
            showCaptcha: true,
            msgList: [],
            moduleId: MODULE_ID,
            memberId: void 0,
            pagination: {
                pageNo: 1,
                total: 0,
            },
        };
    },
    computed: {
        ...mapGetters(['getModuleById']),
        ...mapState('app', ['openThemeV3', 'LS', 'realNameAuth']),
        ...mapState(['mobiTipList']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        msgBoardContentV3Text() {
            return this.LS.msgBoardContentV3;
        },
        captchaHideClass() {
            if (this.showCaptcha) {
                return ' J_msgBoardCaptcha ';
            } else {
                return ' J_msgBoardCaptcha msgBoardCaptchaHide';
            }
        },
        enterVerificationCodeV3Text() {
            return this.LS.mobi_EnterVerificationCodeV3;
        },
        validateCodeSrc() {
            return `/validateCode.jsp?${Math.random() * 1000}`;
        },
        submitV3Text() {
            return this.LS.mobi_SubmitV3;
        },
        msgBoardUserCommentsText() {
            return this.LS.mobi_msgBoard_UserComments;
        },
        msgCanNotSubmitText() {
            return this.LS.mobi_msgBoard_MsgCanNotSubmit;
        },
        msgBoardReplyText() {
            return this.LS.msgBoardReply;
        },
        showComments() {
            const visitorMode = VITE_APP_MODE === 'visitor';
            if (visitorMode) {
                return this.realNameAuth.allowShowMessage;
            }
            return true;
        },
        allowSubmit() {
            const visitorMode = VITE_APP_MODE === 'visitor';
            if (visitorMode) {
                return this.realNameAuth.allowSubmitMessage;
            }
            return false;
        },
    },
    mounted() {
        this.initRealNameAuthTips();
    },
    methods: {
        ...mapMutations(['addMobiTips']),
        async addMsgHandler() {
            if (this.allowSubmit === false) {
                return;
            }

            const mobileAuthPopupService = new MobileAuthPopupService(getThemeColor());
            const { needAuth } = await mobileAuthPopupService.needAuth(MOBILE_AUTH_MESSAGE_TYPES.MSG_BOARD);
            if (needAuth) {
                mobileAuthPopupService.createSubmitHandler(
                    () => {
                        this.addMsg();
                    },
                    (err) => {
                        mobiIng(err.msg);
                    }
                );
                mobileAuthPopupService.show();
            } else {
                this.addMsg();
            }
        },
        addMsg() {
            var content = jm('#reqContent').val();
            var validateCode = jm('#msgBoardCaptcha').val();
            var msg = jm('.notice').show();
            var $J_msgBoardCaptcha = jm('.J_msgBoardCaptcha');
            //msgBoard Must
            var msgBdMust = 0;

            if (Fai.top.isJuly3FreeUser) {
                this.$logDog(200964, 4);
                this.msgBoardShowMsg('免费版暂不支持此功能');
                return;
            }
            var _this = this;
            jm('input.msg_isMust').each(function () {
                var thisMsgIptId = jm(this).attr('id');
                var thisMsgIptName = jm(this).attr('placeholder');
                var thisMsgIptVal = jm(this).val();
                if (thisMsgIptVal == '' || thisMsgIptVal == null) {
                    msg.html(jm.format(_this.LS.msgBoardInputIsEmpty, encodeHtml(thisMsgIptName)));
                    /*msg.html("请输入"+thisMsgIptName);*/
                    getPreviewObject(thisMsgIptId).focus();
                    msgBdMust = 1;
                    return false;
                }
                if (thisMsgIptId == 'reqPhone' && !jm.isPhone(thisMsgIptVal)) {
                    msg.html(jm.format(_this.LS.msgBoardInputCorrect, encodeHtml(thisMsgIptName)));
                    /*msg.html("请输入正确的"+thisMsgIptName);*/
                    getPreviewObject(thisMsgIptId).focus();
                    msgBdMust = 1;
                    return false;
                }
                if (thisMsgIptId == 'reqEmail' && !jm.isEmail(thisMsgIptVal)) {
                    msg.html(jm.format(_this.LS.msgBoardInputCorrect, encodeHtml(thisMsgIptName)));
                    /*msg.html("请输入正确的"+thisMsgIptName);*/
                    getPreviewObject(thisMsgIptId).focus();
                    msgBdMust = 1;
                    return false;
                }
            });
            if (msgBdMust == 1) {
                return false;
            }
            //msgBoard input Over Length
            var msgBdOverLength = 0;
            jm('input.msg_ipt').each(function () {
                var thisMsgIptId = jm(this).attr('id');
                var thisMsgIptName = jm(this).attr('placeholder');
                var thisMsgIptVal = jm(this).val();
                var thisMsgIptMaxLen = jm(this).attr('maxlength');
                if (thisMsgIptVal.length > thisMsgIptMaxLen) {
                    msg.html(jm.format(_this.LS.msgBoardInputMaxLength, encodeHtml(thisMsgIptName), thisMsgIptMaxLen));
                    msgBdOverLength = 1;
                    return false;
                }
                if (thisMsgIptId == 'reqPhone' && thisMsgIptVal.length && !jm.isPhone(thisMsgIptVal)) {
                    msg.html(jm.format(_this.LS.msgBoardInputCorrect, encodeHtml(thisMsgIptName)));
                    getPreviewObject(thisMsgIptId).focus();
                    msgBdOverLength = 1;
                    return false;
                }
                if (thisMsgIptId == 'reqEmail' && thisMsgIptVal.length && !jm.isEmail(thisMsgIptVal)) {
                    msg.html(jm.format(_this.LS.msgBoardInputCorrect, encodeHtml(thisMsgIptName)));
                    getPreviewObject(thisMsgIptId).focus();
                    msgBdOverLength = 1;
                    return false;
                }
            });
            if (msgBdOverLength == 1) {
                return false;
            }

            if (content == null || content == '') {
                this.msgBoardShowMsg(LS.msgBoardInputContent);
                getPreviewObject('reqContent').focus();
                return;
            }
            var contentLimit = 10000;
            if (content.length > contentLimit) {
                this.msgBoardShowMsg(jm.format(LS.msgBoardInputContent2, contentLimit));
                getPreviewObject('reqContent').focus();
                return;
            }
            // 没有显示验证码不校验验证码，只有显示验证码的时候才校验验证码是否有输入
            if (!$J_msgBoardCaptcha.hasClass('msgBoardCaptchaHide')) {
                if (validateCode == null || validateCode == '') {
                    this.msgBoardShowMsg(LS.msgBoardInputValidateCode);
                    getPreviewObject('msgBoardCaptcha').focus();
                    return;
                }
            }
            this.msgBoardShowMsg(LS.msgBoardDoing);

            //get data
            var msgBdData = {};
            jm('input.msg_ipt').each(function () {
                var thisMsgIptId = jm(this).attr('id');
                var thisMsgIptVal = jm(this).val();
                msgBdData[thisMsgIptId] = thisMsgIptVal.trim();
            });
            msgBdData['reqContent'] = content;
            msgBdData['memberId'] = this.memberId === undefined ? 0 : this.memberId;
            const data =
                jm.encodeUrl(jm.toJSON(msgBdData)) +
                '&validateCode=' +
                jm.encodeUrl(validateCode) +
                '&moduleId=' +
                jm.encodeUrl(this.moduleId);
            addMsgBoardData(data)
                .catch(function () {
                    this.msgBoardShowMsg(LS.js_systemError);
                })
                .then((response) => {
                    const result = response;
                    if (result.success) {
                        this.$mobiIng(LS.msgBoardSendOk, 1);
                        /*jm('#msgAdd input[type="text"]').val('');
                    jm('#reqContent').val('');*/
                        setTimeout(function () {
                            window.location.reload();
                        }, 1500);
                    } else {
                        if (result.errno == 1) {
                            this.msgBoardShowMsg(LS.js_captchaError);
                        } else if (result.errno == 2) {
                            this.msgBoardShowMsg(LS.js_argsError);
                        } else if (result.errno == -4) {
                            this.msgBoardShowMsg(LS.msgBoardAddCountLimit);
                        } else {
                            this.msgBoardShowMsg(result.msg || LS.js_systemError);
                        }

                        if (result.needCode) {
                            this.msgBoardShowMsg(result.msg);
                            $J_msgBoardCaptcha.removeClass('msgBoardCaptchaHide');
                        }

                        if (result.hasFW) {
                            this.$mobiIng(result.msg);
                            jm('.notice').hide();
                        }
                    }
                    ValidateCode.change(jm('#msgBoardCaptchaImg'));
                });
        },
        msgBoardShowMsg(msg) {
            jm('.notice').show();
            jm('.notice').html(msg);
        },
        toggleMust() {
            // 初始化检查必填项是否有值，有的话把红色的星号去掉
            jm('.msgTopV3 .J_checkMust')
                .siblings('.openThemeV3Must')
                .each(function () {
                    if (jm(this).siblings('.J_checkMust').val() != '') {
                        //输入框有内容
                        jm(this).css('display', 'none');
                    }
                });

            // 如果用户有输入内容，则名字必填的*号不显示
            // 写keyUp又写keydown主要是想让红色星号消失得快一点
            jm('.msgTopV3 .J_checkMust.msg_isMust').each(function () {
                jm(this).keyup(function () {
                    if (jm(this).val() != '') {
                        //输入框有内容
                        jm(this).siblings('.openThemeV3Must').css('display', 'none');
                    } else {
                        // 输入框没有内容
                        jm(this).siblings('.openThemeV3Must').css('display', 'inline-block');
                    }
                });
            });

            jm('.msgTopV3 .J_checkMust.msg_isMust').each(function () {
                jm(this).keydown(function () {
                    if (jm(this).val() != '') {
                        //输入框有内容
                        jm(this).siblings('.openThemeV3Must').css('display', 'none');
                    } else {
                        // 输入框没有内容
                        jm(this).siblings('.openThemeV3Must').css('display', 'inline-block');
                    }
                });
            });
        },
        msgCheckMustItem(nodeId) {
            //输入法影响 直接使用keypress up down 会无法检测。 所以加上focusout再次检测
            jm(nodeId)
                .find('.msg_isMust')
                .on('keyup focusout', function () {
                    var flag = true;
                    jm(nodeId)
                        .find('.msg_isMust')
                        .each(function () {
                            if (jm(this).val().trim() === '') {
                                //必填项有任意一个不填，按钮都会被置成disabled
                                flag = false;
                                return;
                            }
                        });
                    if (flag === true) {
                        jm('.sendIcon').removeAttr('disabled');
                    }
                });
        },
        changeValidateCode() {
            ValidateCode.change(jm('#msgBoardCaptchaImg'));
        },
        initPagination() {
            this.pagination.pageNo = parseInt(getUrlParam(window.location.href, `m${this.moduleId}pageno`) || 1);
        },
        async loadMsgBoardInfo() {
            const params = {
                moduleId: this.moduleId,
                [`m${this.moduleId}pageno`]: this.pagination.pageNo,
            };
            const { data = {} } = await getMsgBoardInfo(params);
            const {
                displayList,
                showCaptcha,
                defaultCreator,
                defaultEmail,
                msgList,
                openMsgAuto,
                totalMsgSize,
                defaultPhone,
                memberId,
            } = data;
            this.displayList = displayList;
            this.showCaptcha = showCaptcha;
            this.defaultEmail = defaultEmail;
            this.defaultCreator = defaultCreator;
            this.defaultPhone = defaultPhone;
            this.msgList = msgList;
            this.openMsgAuto = openMsgAuto;
            this.pagination.total = totalMsgSize;
            this.memberId = memberId;

            await Vue.nextTick();
            this.initPictureSlides();
        },
        checkBit(flag, bit) {
            return this.getBitMemory(flag, bit);
        },
        getBitMemory(flag, bit) {
            return getBitMemory(flag, bit);
        },
        mustClassStr(flag) {
            return this.checkBit(flag, MsgBoardPropDef.FLAG.REQUIRED) ? 'msg_isMust' : '';
        },
        async changePage(page) {
            this.pagination.pageNo = page;
            await this.loadMsgBoardInfo();
            if (VITE_APP_MODE !== 'visitor' && typeof this.initModuleMsgBoardItemManage !== 'undefined') {
                this.initModuleMsgBoardItemManage();
            }
        },
        formatDate(date) {
            return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
        },
        initModuleMsgBoardItemManage() {
            for (let msg of this.msgList) {
                let id = msg.id;
                let json = [];

                if (!this.openMsgAuto) {
                    const isPublic = checkBit(msg.flag, 0x1) === true; // MsgBoardDef.Flag.PUBLIC
                    json.push({
                        type: 'normal',
                        className: isPublic ? 'private' : 'public',
                        operationText: isPublic ? '取消公开' : '公开留言',
                        onClick: () => {
                            this.msgBoardPublicThisMsg(id, 0x1, !isPublic);
                        },
                    });
                }

                const isRspContentEmpay = msg.rspContent === undefined || msg.rspContent === '';
                json.push({
                    type: 'normal',
                    className: 'reply',
                    operationText: isRspContentEmpay ? '回复' : '修改回复',
                    onClick() {
                        popupWindow({
                            title: isRspContentEmpay ? '回复留言' : '修改回复',
                            frameSrcUrl: `${getSiteMultiLanguageUrl()}/manage/msgBoardReply.jsp?mbid=1&mobiSettingState=1&id=${id}&ram=${Math.random()}`,
                            width: '650',
                            height: '590',
                        });
                    },
                });
                json.push({
                    type: 'normal',
                    className: 'close',
                    operationText: '删除',
                    onClick: () => {
                        this.msgBoardDelMsg(id, msg.msgName);
                    },
                });

                $(getPreviewObject(`#msgItem${id}`))
                    .off('mouseover')
                    .off('mouseleave')
                    .mouseover(() => {
                        this.$layer.addEditLayer(`msgItem${id}`, json, 1);
                    })
                    .mouseleave(() => {
                        this.$layer.removeEditLayer(`msgItem${id}`);
                    });
            }
        },
        async msgBoardPublicThisMsg(id, flag, status) {
            Fai.bg();
            Fai.ing('正在保存', false);
            try {
                const data = await setPublicMsg({ id, flag, status });
                Fai.removeBg();
                successHandle(data, '', '', '', 1, 1);
                if (data.success) {
                    this.changePage(this.pagination.pageNo);
                }
            } catch (err) {
                console.error(err);
                Fai.ing('系统繁忙!', false);
            }
        },
        msgBoardDelMsg(id, name) {
            if (!confirm('确定删除“' + name + '”的留言？')) {
                return;
            }
            Fai.bg();
            Fai.ing('正在删除', false);
            delMsg(id)
                .then((data) => {
                    Fai.removeBg();
                    if (data.success) {
                        Fai.ing('删除成功', false);
                    }
                    this.changePage(this.pagination.pageNo);
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        initRealNameAuthTips() {
            if (VITE_APP_MODE === 'visitor') {
                if (this.realNameAuth.allowShowMessage === false) {
                    this.addMobiTips(MOBILE_AUTH_TIPS);
                }
            }
        },
    },
};
