<template>
    <div
        v-if="showBackBtn || isShowHomeIcon || isShowHomePage"
        :id="'productDetailHeader' + moduleId"
        class="productDetailHeader"
        :class="productDetailHeaderCls"
        :style="mobiTipsStyle"
    >
        <span
            v-if="showBackBtn"
            :class="headerIconCls"
            class="icon-gClose g_round g_iconMiddle newsBack icon-defaultColor"
        ></span>
        <a
            v-if="isShowHomeIcon"
            :href="linkLang + '/index.jsp'"
            :class="headerIconCls"
            :style="homeIconStyle"
            class="icon-gHome g_round g_iconMiddle newsHome icon-defaultColor"
        ></a>
        <span
            v-if="isShowHomePage"
            id="newsDetailShare"
            class="icon-share g_iconMiddle g_round newsShare icon-defaultColor"
        ></span>
    </div>
</template>

<script>
import { newsDetailMixins } from '../mixins';
import { changeIconBg } from '@/system/shared/newsDetail';
import { COL } from '@/def/col';
import { TEMPLATE_LAYOUT } from '@/def/template';
import { mapState } from 'vuex';
export default {
    name: 'Header',
    mixins: [newsDetailMixins],
    inject: ['moduleId', 'openThemeV3'],
    props: {
        version: {
            type: Number,
            default: 3,
        },
    },
    computed: {
        ...mapState('app', ['openThemeV3', 'colId', 'templateLayoutId']),
        isShowHomeIcon() {
            return this.showHomePage == (this.openThemeV3 ? 1 : 0);
        },
        isShowHomePage() {
            return (this.isNewsDetailV3 && this.showShareOn) || (this.isNewNewsDetail && this.showShareOn);
        },
        isNewNewsDetail() {
            return this.version == 2;
        },
        isNewsDetailV3() {
            return !this.openThemeV3 && !this.isNewNewsDetail;
        },
        pageStyle() {
            // 页面样式
            return this.content.pageStyle;
        },
        linkLang() {
            //获取多语言版本
            return this.options.linkLang;
        },
        productDetailHeaderCls() {
            const pageStyle2 = 'detailHeaderColor';
            if (this.isNewNewsDetail) {
                return pageStyle2;
            }
            return ['', pageStyle2][this.pageStyle] || '';
        },
        headerIconCls() {
            // 从 Mobi.changeIconBg 迁移
            if (!this.openThemeV3) {
                return [];
            }
            return ['newsIconWhite', 'newsIconBlack'][this.pageStyle];
        },
        showBackBtn() {
            // 是否显示返回按钮 0 开启 1不开启
            return this.pageElementList.sbb == 0;
        },
        showHomePage() {
            //显示返回首页按钮
            return this.pageElementList[this.isNewNewsDetail ? 'showHome' : 'showHomePage'];
        },
        showShareOn() {
            // 开启分享
            return this.content.ss == 0;
        },
        homeIconStyle() {
            return {
                right: this.openThemeV3 || (this.isNewNewsDetail && !this.showShareOn) ? '0.45rem' : '2.5rem',
            };
        },
        // eslint-disable-next-line vue/return-in-computed-property
        mobiTipsStyle() {
            if (this.openThemeV3) {
                return {};
            } else if (this.templateLayoutId != TEMPLATE_LAYOUT.NAV_AUTO && this.colId == COL.CUS) {
                return {
                    top: `1.5rem;`,
                };
            }
        },
    },
    updated() {
        changeIconBg();
    },
};
</script>

<style lang="scss">
.productDetailHeader {
    background-color: rgba(255, 255, 255, 0);
}
</style>
