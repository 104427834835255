/**
 * 这个文件是兼容site端方法，有些链接控件在site设置了，但是mobi由于没有保护导致报错。
 */
import { linkToForm } from '@/modules/mobiForm/util';
import { videoDialog } from '@/modules/onlineVideo/util';
import lottery from '@/features/global-mobi-function/methods/lottery/index';
import { getSiteDownloadInfo, getMobiDownloadInfo } from '@/api/linkSetting';

import { preprocessUrl } from '@/shared/url';
import { ing, mobiIng } from '@/shared/fai';
import { changeHref } from '@/features/router/universal';
import { getNewsOrProductIdQuery } from '@/features/router/util';
import { format } from '@/shared/fai';
const argCookieKey = '_reqArgs';
import { browser } from '@jz/utils';

export function resignerJZFunc() {
    // 注册JZ全局变量
    if (typeof window === 'object') {
        window.JZ = {};
        window.JZ.transformForLinkFunc = transformForLinkFunc;
        window.JZ.getLotteryInfo = lottery.getLotteryInfo;
        window.JZ.setUrlArgToCookie = setUrlArgToCookie;
    }
}

/**
 * 针对mobi与site对称链接功能的转换
 * @param {*} funcName 函数名称
 * @param {*} funcArgs 函数对应的参数
 */
function transformForLinkFunc(funcName, funcArgs) {
    const fileDownload = 'fileDownload',
        linkFlv = 'linkFlv',
        linkForm = 'linkForm';

    const docPreview = 'docPreview';

    try {
        let { jumpIde = '', info } = funcArgs,
            formId;
        switch (funcName) {
            case fileDownload: // 文件下载 这里分2种是因为site和mobi所需的参数不一样，要区分对待。
                !!jumpIde && siteFileDownload(jumpIde); // from site
                !!info && mobiFileDownload(info); // from mobi
                break;

            case linkForm: // 链接表单
                formId = funcArgs;
                !!formId && linkToForm(formId);
                break;

            case linkFlv: // 链接视频
                !!funcArgs && openLinkFlv(funcArgs);
                break;

            case docPreview:
                !!funcArgs && previewDoc(funcArgs);
                break;
            default:
                break;
        }
    } catch (error) {
        console.log(error);
    }
}

function siteFileDownload(ide) {
    // 链接控件--文件下载
    let //params = '&ide=' + ide + "&url=" + Fai.encodeUrl(window.location.pathname + window.location.search + window.location.hash);
        params = {
            ide,
            url: window.location.pathname + window.location.search + window.location.hash,
        };

    getSiteDownloadInfo(ide, params.url).then((result) => {
        if (result.success) {
            let file = result.file,
                {
                    allowDownload,
                    downloadUrl,
                    memberLevelIsLow,
                    visitorMemberAcct,
                    levelLimitName,
                    allowFileDownload,
                    memberId,
                    memberGroupIsOk,
                    memberOnlyLevel,
                    memberOnlyLevelIsOk,
                } = file;

            if (allowDownload) {
                // 下载文件
                //downloadUrl ? window.open(downloadUrl, '_self') : ing('当前文件已丢失', true);
                if (downloadUrl) {
                    // 这里先临时处理管理态下载时，因为资源在http，会被阻塞  管理态和预览太都会在iframe中
                    if (self !== top) {
                        window.open(downloadUrl);
                    } else {
                        window.open(downloadUrl, '_self');
                    }
                } else {
                    ing('当前文件已丢失', 0);
                }
            } else {
                if (allowFileDownload == 1 && !memberOnlyLevel && memberLevelIsLow && memberId > 0) {
                    ing(format(LS.memberDownloadLevelLimit, visitorMemberAcct, levelLimitName), true);
                } else if (allowFileDownload == 1 && memberOnlyLevel && !memberOnlyLevelIsOk && memberId > 0) {
                    ing(`亲爱的${visitorMemberAcct}，您暂无权限下载该文件。`, true);
                } else if (allowFileDownload == 2 && !memberGroupIsOk && memberId > 0) {
                    ing(`亲爱的${visitorMemberAcct}，您暂无权限下载该文件。`, true);
                } else {
                    // 这里就按着mobi的逻辑走
                    let returnUrl = `${window.location.pathname}${window.location.search}`;
                    //ing('请先登录或注册成为会员。', true);
                    returnUrl = Fai.top.encodeURIComponent(returnUrl);
                    changeHref(preprocessUrl({ path: '', oldPath: '' }) + `login.jsp?returnUrl=${returnUrl}`);
                    return;
                }
            }
        }
    });
}

export function mobiFileDownload(options) {
    //  使用文件ID获取下载权限和下载路径

    getMobiDownloadInfo(options)
        .then((data) => {
            if (!data.success) {
                if (data.login === false) {
                    // ing("请先登录或注册成为会员。", 0);
                    let returnUrl = `${window.location.pathname}${window.location.search}`;
                    returnUrl = Fai.top.encodeURIComponent(returnUrl);
                    changeHref(preprocessUrl({ path: '', oldPath: '' }) + `login.jsp?returnUrl=${returnUrl}`);
                    return;
                }

                if (data.auth === false) {
                    // 用户名
                    const visitorMemberAcct = data.visitorMemberAcct;
                    // 目标会员名称
                    const levelLimitName = data.levelLimitName;
                    // 权限类型
                    const downloadAuthType = data.downloadAuthType;
                    // 请升级
                    const memberOnlyLevel = data.memberOnlyLevel || false;

                    if (downloadAuthType == 1 && !memberOnlyLevel) {
                        ing(`亲爱的${visitorMemberAcct},您还需要升级到${levelLimitName}才能下载该文件。`, 0);
                    } else if (downloadAuthType == 1 && memberOnlyLevel) {
                        ing(`亲爱的${visitorMemberAcct},您暂时没有权限下载该文件。`, 0);
                    } else if (downloadAuthType == 2) {
                        ing(`亲爱的${visitorMemberAcct},您暂时没有权限下载该文件。`, 0);
                    }

                    return;
                }
            }

            let downloadUrl = data.downloadUrl;

            if (downloadUrl) {
                // 这里先临时处理管理态下载时，因为资源在http，会被阻塞  管理态和预览太都会在iframe中
                if (VITE_APP_MODE !== 'visitor') {
                    window.open(downloadUrl);
                } else {
                    window.open(downloadUrl, '_self');
                }
            } else {
                ing('当前文件已丢失', 0);
            }
        })
        .catch(() => {
            ing('服务繁忙，请稍候重试', false);
        });
}

async function previewDoc(docPreviewInfo) {
    let { jumpIde } = docPreviewInfo;
    let { data: result } = await jzRequest.get(`/ajax/linkChg_h.jsp?cmd=getWafNotCk_getDocPreviewInfo`, {
        params: {
            ide: jumpIde,
            url: location.href,
        },
    });

    if (result.success) {
        let { previewStatus, previewUrl, memberName, memberLevelName } = result.data;
        if (previewStatus == 0) {
            let el = document.createElement('a');
            el.id = jumpIde;
            el.href = previewUrl;
            el.target = (browser.isIos() && browser.isWeChat()) || !browser.isPc() ? '_self' : '_blank';
            $('body').append(el);
            document.getElementById(jumpIde).click();
            $(`#${jumpIde}`).remove();
            // window.open(previewUrl, '_self');
        } else if (previewStatus == -1) {
            // 需要登录
            mobiIng(LS.memberLoginFirst, 0);
            setTimeout(() => {
                let returnUrl = `${window.location.pathname}${window.location.search}`;
                returnUrl = Fai.top.encodeURIComponent(returnUrl);
                changeHref(preprocessUrl({ path: '', oldPath: '' }) + `login.jsp?returnUrl=${returnUrl}`);
            }, 1000);
            return;
        } else if (previewStatus == -2) {
            // 会员等级不够
            mobiIng(`${format(LS.memberViewLevelLimit, memberName, memberLevelName)}`, 0);
        } else if (previewStatus == -3) {
            // 会员等级没权限
            mobiIng(`${format(LS.site_file_view_group_tip, memberName)}`, 0);
        } else if (previewStatus == -4) {
            // 当前文件已丢失
            mobiIng(LS.fileNotFound, 0);
        }
    } else {
        mobiIng(result.msg, 0);
    }
}

function openLinkFlv(funcArgs) {
    let { flvCode, flvType } = funcArgs,
        match = new RegExp('`', 'g'),
        flvInfo = {};

    flvCode = flvCode.replace(match, '"');
    flvInfo = {
        t: flvType,
        iu: flvCode,
    };
    videoDialog(0, flvInfo, true);
}

function getIsSetReqHttpOnlyCookie() {
    return Fai.top._setReqHttpOnlyCookie;
}

// 处理jsp吐出的onclick="JZ.setUrlArgToCookie(this, 'testArgsValue');"的跳转
function setUrlArgToCookie(element, args) {
    const href = element.href || '';
    if (!href) return;
    if (VITE_APP_MODE !== 'visitor') {
        return Vue.prototype.$designer.promiseCheckSaveBar().then(() => setUrlCookie());
    } else {
        setUrlCookie();
    }

    function setUrlCookie() {
        return handleSetUrlArgToCookie(args)
            .then(() => {
                let cloneA = element.cloneNode();
                cloneA.setAttribute('onclick', '');
                const extInfo = getNewsOrProductIdQuery(element);
                changeHref(cloneA.getAttribute('href'), extInfo);
                cloneA = null;
            })
            .catch((err) => console.error(err));
    }
    return false;
}

// 设置urlArg到cookie的动作
function handleSetUrlArgToCookie(args) {
    return new Promise((resolve, reject) => {
        try {
            if (typeof args === 'object') {
                args = JSON.stringify(args);
            }
        } catch (err) {
            console.error(err);
            resolve();
        }
        if (getIsSetReqHttpOnlyCookie()) {
            jm.ajax({
                url: '/ajax/setCookie_h.jsp',
                type: 'post',
                data: {
                    cmd: 'setWafCk_setReqArgsCookie',
                    _reqArgs: args,
                },
                success() {
                    resolve();
                },
                error(err) {
                    reject(err);
                },
            });
        } else {
            jm.cookie(argCookieKey, args, { path: '/' });
            resolve();
        }
    });
}
