export const TYPE = {
    DEFAULT: 0,
    DRAWER: 1,
    SUSPENSION: 2,
    LABEL: 3,
};

export const LAYOUT = {
    DRAWER_ALL_COVER: 1, // 抽屉-全铺
    DRAWER_PART_COVER: 2, // 抽屉-半铺
    DRAWER_LEFT: 3, // 抽屉-左展开
    DRAWER_RIGHT: 4, // 抽屉-右展开
    SUSPENSION_ALL_COVER: 5, // 悬浮-全铺展开
    LABEL_SLIDE: 6, // 标签-水平滑动
    LABEL_PANEL: 7, // 标签-下拉面板
    SUSPENSION_LEFT: 8, // 悬浮 左展开
    SUSPENSION_BOX: 9, // 悬浮 -小抽屉
    DRAWER_PART_COVER_SLIDE: 10, // 抽屉-横向平铺
};
