<template>
    <module-frame :module-id="moduleId">
        <div :id="productGroupDetailPanelId" class="photoGroupDetailPanel">
            <div :id="productGroupDetailHeaderId" class="photoGroupDetailHeader detailHeaderColor">
                <template v-if="isShowBackBtn">
                    <span class="icon-gClose g_round g_iconMiddle photoGroupDetailBack icon-defaultColor"></span>
                </template>
                <template v-if="isShowShare == 0">
                    <span
                        id="newsDetailShare"
                        class="icon-share g_iconMiddle g_round photoGroupDetailShare icon-defaultColor"
                    ></span>
                </template>
            </div>
            <div class="photoGroupDetail">
                <div :id="photoGroupPicPanelId" class="photoGroupPicPanel">
                    <div :id="photoGroupPicListId" class="photoGroupPicList">
                        <div v-for="photo in photoList" :key="photo.id" class="photoGroupPic">
                            <div
                                class="photoGroupPicImg photoGroup-img J_img_lazyload"
                                :src-original="photo.picThumbPath"
                                :alt="photo.picThumbName"
                                :datasrc="photo.picThumbPath"
                                :data-picid="photo.picThumbId"
                                :style="{
                                    width: '100%',
                                    height: computedPicHeight,
                                    backgroundImage: `url(${loadingPath})`,
                                }"
                            >
                            </div>
                        </div>
                    </div>
                    <template v-if="photoList.length > 1">
                        <div id="photoListBullet" class="photoListBullet">
                            <ul class="bullets">
                                <li
                                    v-for="(photoItem, index) in photoList"
                                    :key="photoItem.id"
                                    :class="{ on: index === 0 }"
                                ></li>
                            </ul>
                        </div>
                        <div
                            class="J_btn J_preBtn faisco-icons-M000217 f-btn f-preBtn faisco-icons fontIcon faisco-icons-back5"
                        ></div>
                        <div
                            class="J_btn J_nextBtn faisco-icons-M000215 f-btn f-nextBtn faisco-icons fontIcon faisco-icons-forward1"
                        ></div>
                        <div class="f-photoNumber">
                            <span class="J_curPhotoNumber this_page">1</span><span class="pageLine">/</span
                            ><span class="J_maxNum total_page">{{ photoList.length }}</span>
                        </div>
                    </template>
                </div>
                <div class="photoGroupDetailContent">
                    <h1 class="title" :class="titleRightShare">
                        {{ photoGroupInfo.name }}
                    </h1>
                    <template v-if="isShowDetail === 0">
                        <div class="separateLine"></div>
                        <html-render class="photoGroupDesc" :html="photoGroupContent"></html-render>
                    </template>
                </div>
            </div>
        </div>
    </module-frame>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { getLanCode } from '@/shared/fai';
import { bindImgLazyLoad } from '@/shared/imageLazyload';
import { LinkSettingUtil } from '@/shared/linkSetting';
import { initPhotoGroupSwipe, photoGroupContentInit } from '../utils';

import { initPictureSlidesV4 } from '@/shared/photo/slides';
import { MODULE_ID } from '../constants';

import ModuleFrame from '@/modules/frame/index.vue';
import { setHrefEventHasReqArgs } from '@/modules/comm';

import HtmlRender from '@/components/html-render/index.vue';

export default {
    components: {
        ModuleFrame,
        HtmlRender,
    },
    data() {
        return {
            moduleId: MODULE_ID,
            manageMode: VITE_APP_MODE !== 'visitor',
        };
    },
    computed: {
        ...mapGetters(['getModuleById']),
        ...mapState('app', ['lcid', 'isRegularHeadStyle', 'loadingPath', 'LS']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        moduleExtInfo() {
            return this.module?.extInfo ?? {};
        },
        photoList() {
            return this.moduleExtInfo?.photoList ?? [];
        },
        photoGroupInfo() {
            return this.moduleExtInfo?.photoGroupInfo ?? {};
        },
        picHeight() {
            return this.moduleExtInfo?.picHeight ?? '';
        },
        picWidth() {
            return this.moduleExtInfo?.picWidth ?? '';
        },
        grapicShareInfo() {
            return this.moduleExtInfo?.grapicShareInfo ?? {};
        },
        slideList() {
            return this.moduleExtInfo?.slideList ?? [];
        },
        productGroupDetailPanelId() {
            return `photoGroupDetailPanel${this.moduleId}`;
        },
        productGroupDetailHeaderId() {
            return `photoGroupDetailHeader${this.moduleId}`;
        },
        isShowBackBtn() {
            return (this.module?.content?.sbb ?? 0) === 0;
        },
        isShowDetail() {
            return this.module?.content?.sd ?? 0;
        },
        isShowShare() {
            return this.module?.content?.ss ?? 0;
        },
        isShowHome() {
            return this.module?.content?.hs ?? 0;
        },
        linkLang() {
            return `/${getLanCode(this.options.lcid || 2052)}`;
        },
        homeLink() {
            return `${this.linkLang}/index.jsp`;
        },
        photoGroupPicPanelId() {
            return `photoGroupPicPanel${this.moduleId}`;
        },
        photoGroupPicListId() {
            return `photoGroupPicList${this.moduleId}`;
        },
        onephotoList() {
            return this.photoList.length === 1 ? 'onephotoList' : '';
        },
        photoGroupPicClass() {
            return `photoGroupPic ${this.onephotoList}`;
        },
        titleRightShare() {
            return (this.isShowShare === 0 && !this.isRegularHeadStyle) ||
                (this.isShowShare === 0 && VITE_APP_MODE !== 'visitor')
                ? 'photo_title_flex'
                : '';
        },
        isEmptyPhotoGroupContent() {
            return (
                this.photoGroupContent === '<p><br /></p>' ||
                this.photoGroupContent === '<p></p>' ||
                this.photoGroupContent === ''
            );
        },
        computedPicHeight() {
            return `${Math.ceil(16 * ((this.picHeight * 1.0) / this.picWidth))}rem`;
        },
        photoGroupContent() {
            return this.moduleExtInfo.photoGroupInfo.content;
        },
    },
    mounted() {
        if (this.photoList.length > 1) {
            initPhotoGroupSwipe(`photoGroupPicPanel${this.moduleId}`);
        }

        this.initSlide();

        LinkSettingUtil.switchJump();
        this.initShareInfo();
        if (this.isShowShare == 0) {
            this.initShareDataPhotoGroup();
        }

        bindImgLazyLoad(`module${this.moduleId}`);
        photoGroupContentInit(this.moduleId);

        setHrefEventHasReqArgs(jm(`#module${this.moduleId}`));
        window.addEventListener('resize', this.updateSwipe);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateSwipe);
    },
    methods: {
        updateSwipe() {
            if (this.photoList.length > 1) {
                initPhotoGroupSwipe(`photoGroupPicPanel${this.moduleId}`);
            }
        },
        initShareDataPhotoGroup() {
            let browserTitle = this.moduleExtInfo.browserTitle;
            let shareLinkList = this.moduleExtInfo.shareLinkList;
            let shareUrl = this.moduleExtInfo.shareUrl;
            Fai.top._shareDataPhotoGroup = {
                browserTitle: browserTitle,
                shareUrl,
                shareLinkList,
            };
        },
        initSlide() {
            const slideOption = {
                fromCurentInx: true,
                captionDetailClass: 'caption_detail-photoGroup',
                contentTerminal: 'mobi-图册目录-图册详情',
            };
            initPictureSlidesV4(`#photoGroupPicList${this.moduleId} .photoGroupPicImg`, this.slideList, slideOption);
        },
        initShareInfo() {
            Fai.top._shareDataPhotoDetail = this.grapicShareInfo;
        },
    },
};
</script>

<style lang="scss">
@import '../photo-group-detail.scss';
</style>
