import { checkBit } from '@/shared/fai';
import { ALT_NAME, NEWS_DEF_FLAG } from '../constants';
import { dayjs } from '@jz/utils';
import { changeHref } from '@/features/router/universal';
import { createUrlArgsOnclickStr } from '@/modules/comm';
import { mapGetters } from 'vuex';

export default {
    props: {
        size: {
            type: Number,
            default: 0,
        },
        newsItem: {
            type: Object,
            default: () => ({}),
        },
        index: {
            type: Number,
            default: -1,
            require: true,
        },
        showLine: {
            type: Boolean,
            default: false,
        },
        topVisible: {
            type: Boolean,
            default: true,
        },
        newsSelect: {
            type: Boolean,
            default: false,
        },
        topSignType: {
            type: Number,
            default: 1,
        },
        mixStyleType: {
            type: Boolean,
            default: false,
        },
        topicPicPosition: {
            type: Number,
            default: -1,
        },
        picSizeType: {
            type: Number,
            default: -1,
        },
        customWidth: {
            type: String,
            default: '',
        },
        customHeight: {
            type: String,
            default: '',
        },
        showComment: {
            type: Boolean,
            default: false,
        },
        siteProfFlag: {
            type: Number,
            require: true,
        },
        showAuthor: {
            type: Boolean,
            default: true,
        },
        hideType: {
            type: Boolean,
            default: false,
        },
        hideDate: {
            type: Boolean,
            default: false,
        },
        newsArrangeWay: {
            type: Number,
            default: 1,
        },
        count: {
            type: Number,
            default: 0,
        },
        picNum: {
            type: Number,
            default: 0,
        },
        openRemoveUrlArgs: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters('app', ['hashRemoved']),
        id() {
            return this.newsItem.id;
        },
        newsLineId() {
            return `newsList${this.id}`;
        },
        title() {
            return this.newsItem.title;
        },
        topClassName() {
            return this.newsItem.top !== 0 ? 'top2' : 'top1';
        },
        topSwitch() {
            return this.newsItem.top !== 0 ? 'off' : 'on';
        },
        lineClass() {
            return this.showLine ? ' wWLine' : '';
        },
        lineBodyId() {
            return `lineBody${this.id}`;
        },
        setReqArgCookieAndJumpStr() {
            return this.newsItem.setReqArgCookieAndJumpStr;
        },
        href() {
            return this.newsItem.url;
        },
        target() {
            return '_self';
        },
        pictureId() {
            let pictureId = this.newsItem.pictureId || '';
            let nlPictureId = this.newsItem.nlPictureId || '';
            return checkBit(this.newsItem.flag || 0, NEWS_DEF_FLAG.CUS_NEWSLIST_DISPLAY) ? nlPictureId : pictureId;
        },
        picturePath() {
            return this.newsItem.picturePath;
        },
        topFlagClass() {
            return this.newsItem.top !== 0 && this.topVisible && !this.newsSelect
                ? ` g_topFlag g_topFlag${this.topSignType}`
                : '';
        },
        summary() {
            return this.newsItem.summary || '';
        },
        topFlagLineClass() {
            return this.newsItem.top !== 0 && this.topVisible ? ' topFlagLine' : '';
        },
        alt() {
            return ALT_NAME;
        },
        showCommentRequire() {
            return checkBit(this.siteProfFlag, 0x100000) && this.showComment;
        },
        newsAuthor() {
            return this.newsItem?.author ?? '';
        },
        showAuthorRequire() {
            return this.showAuthor && this.newsAuthor !== '';
        },
        groupName() {
            return this.newsItem.groupName;
        },
        showSortRequire() {
            return !this.hideType && this.groupName !== '';
        },
        showNewsElementsRequire() {
            return this.showCommentRequire || this.showAuthorRequire || this.showSortRequire || this.showDateRequire;
        },
        totalNewsCommentCount() {
            return this.newsItem.totalNewsCommentCount;
        },
        newsDate() {
            return this.newsItem.date;
        },
        bigPicturePath() {
            return this.newsItem.bigPicturePath;
        },
        newsTitlePicStyle() {
            return {
                background: `url(${this.bigPicturePath}) no-repeat 0 0`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            };
        },
        firstClassName() {
            return this.index === 0 || (this.mixStyleType && this.index === 1) ? 'firstClass' : '';
        },
        lastClassName() {
            return this.index === this.size - 1;
        },
        newsLineClassName() {
            return `${this.lineClass} ${this.topFlagLineClass}`;
        },
        newsAuthorStyle() {
            return {
                marginLeft: !this.showCommentRequire ? '0rem' : void 0,
            };
        },
        showPicture() {
            return this.mixStyleType;
        },
        hasArrangeWay() {
            return this.picNum == 0 || this.picNum == 1 || this.picNum == 3; //当picNum为0 1 3 时才有新的排列样式
        },
        hasTwoColumnArrangeWay() {
            return this.hasArrangeWay && this.newsArrangeWay === 2;
        },
        isNeedTwoColumn() {
            return (
                (this.index % 2 == 1 && this.picNum == 0) ||
                (this.index % 2 == 0 && this.index != 0 && this.picNum == 1) ||
                (this.index % 2 == 0 && this.index != 0 && this.picNum == 3)
            );
        },
        newsListRealLen() {
            return this.size >= this.count ? this.count : this.size;
        },
        picTableCellStyle() {
            return {
                width: `${this.customWidth}px`,
                height: `${this.customHeight}px`,
            };
        },
        picCellStyle() {
            if (!this.pictureId) {
                return this.picTableCellStyle;
            }
            return {
                ...this.picTableCellStyle,
                background: `url(${this.picturePath}) no-repeat 0 0`,
                'background-size': 'cover',
                'background-position': 'center',
            };
        },
        showDateRequire() {
            return !this.hideDate;
        },
        formattedNewsDate() {
            return dayjs(this.newsDate).format('YYYY/MM/DD');
        },
        onclickStr() {
            const { ndReqArgs } = this.newsItem;
            return createUrlArgsOnclickStr(ndReqArgs, this.openRemoveUrlArgs, this.hashRemoved);
        },
    },
    methods: {
        gotoHref() {
            changeHref(this.href);
        },
    },
};
