var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _c(
      "div",
      {
        staticClass: "fk-photoGroupResult",
        attrs: { id: "photoGroupResult" + _vm.moduleId },
      },
      [
        _vm.emptyPhotoGroup
          ? [
              _c("div", {
                domProps: {
                  textContent: _vm._s(_vm.LS.noIllustrationsUnderThisCategory),
                },
              }),
            ]
          : _vm._l(_vm.filterPhotoGroupList, function (photoGroup) {
              return _c(
                "div",
                {
                  key: photoGroup.id,
                  staticClass: "f-photoGroupItem",
                  style: _vm.photoGroupStyle,
                  attrs: { id: "photoGroupR" + photoGroup.id },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "f-photoGroupR-a",
                      attrs: {
                        hidefocus: "true",
                        href: _vm.photoGroupHref(photoGroup),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.photoGroupHrefClick(photoGroup)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "f-photoGroupR-img photoGroup-img J_img_lazyload",
                          style: _vm.photoGroupImgStyle(photoGroup),
                          attrs: {
                            "src-original": photoGroup.picThumbPath640,
                            title: photoGroup.name,
                          },
                        },
                        [
                          _c("div", {
                            staticClass:
                              "f-photoGroupR-curtain f-photoGroupR-curtainDefault",
                          }),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "f-photoGroupR-title",
                            style: _vm.cusTitleFontStyle,
                            domProps: { textContent: _vm._s(photoGroup.name) },
                          }),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "f-photoGroupR-photoNum",
                            domProps: {
                              textContent: _vm._s(photoGroup.photoNumbers),
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }