import { ing } from '@/shared/tips';
import request from '../request';

export function getPageProductResultData(params) {
    let url = `/api/${VITE_APP_MODE !== 'visitor' ? 'manage' : 'guest'}/module/getPageProductResultData`;

    return request
        .get(url, {
            params,
        })
        .then(({ data }) => {
            return data;
        })
        .catch(() => {
            ing(LS.systemError, false);
        });
}
