var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "profile_form_detail_edit", attrs: { id: _vm.id } },
    [_c("div", { ref: "formModule" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }