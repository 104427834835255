var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.getIsAgreeRefund(_vm.aftersaleRecordStatus)
        ? [
            _c("div", { staticClass: "agree_refund_wrap" }, [
              _c(
                "div",
                { staticClass: "g_color g_main_color_v3 agree_refund_text" },
                [_vm._v(" 商家同意退款 ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "agree_refund_deal_time_wrap" }, [
                _c("span", { staticClass: "agree_refund_deal_time_label" }, [
                  _vm._v("处理时间"),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "agree_refund_deal_time" }, [
                  _vm._v(
                    _vm._s(_vm.formatDate(_vm.latestAftersaleRecord.replyTime))
                  ),
                ]),
              ]),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.getIsRejectRefund(_vm.aftersaleRecordStatus)
        ? [
            _c("div", { staticClass: "reject_refund_wrap" }, [
              _c(
                "div",
                { staticClass: "g_color g_main_color_v3 reject_refund_text" },
                [_vm._v(" 商家拒绝退款 ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "reject_refund_deal_time_wrap" }, [
                _c("span", { staticClass: "reject_refund_deal_time_label" }, [
                  _vm._v("处理时间"),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "reject_refund_deal_time" }, [
                  _vm._v(
                    _vm._s(_vm.formatDate(_vm.latestAftersaleRecord.replyTime))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "reject_refund_reason_wrap" }, [
                _c("span", { staticClass: "reject_refund_reason" }, [
                  _vm._v("拒绝原因"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "font-size": "0.64rem",
                      color: "rgb(51, 51, 51)",
                      "word-break": "break-word",
                    },
                  },
                  [
                    _c(
                      "v-clamp",
                      {
                        attrs: { autoresize: "", "max-lines": 3 },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "after",
                              fn: function ({ toggle, expanded, clamped }) {
                                return [
                                  clamped || expanded
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "g_color g_main_color_v3",
                                          staticStyle: { cursor: "pointer" },
                                          on: { click: toggle },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                expanded
                                                  ? "收起内容"
                                                  : "查看全部"
                                              ) +
                                              "\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          959114994
                        ),
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.latestAftersaleRecord.rejectReason) +
                            "\n                        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "request_refund_wrap" }, [
        _c(
          "div",
          { staticClass: "g_color g_main_color_v3 request_refund_text" },
          [_vm._v(" 发起了退款申请，等待商家处理 ")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "request_refund_request_time_wrap" }, [
          _c("span", { staticClass: "request_refund_request_time_label" }, [
            _vm._v("申请时间"),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "request_refund_request_time" }, [
            _vm._v(
              _vm._s(_vm.formatDate(_vm.latestAftersaleRecord.createTime))
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "request_refund_reason_wrap" }, [
          _c("span", { staticClass: "request_refund_reason_label" }, [
            _vm._v("退款原因"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "request_refund_reason_text" },
            [
              _c(
                "v-clamp",
                {
                  attrs: { autoresize: "", "max-lines": 3 },
                  scopedSlots: _vm._u([
                    {
                      key: "after",
                      fn: function ({ toggle, expanded, clamped }) {
                        return [
                          clamped || expanded
                            ? _c(
                                "span",
                                {
                                  staticClass: "g_color g_main_color_v3",
                                  staticStyle: { cursor: "pointer" },
                                  on: { click: toggle },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        expanded ? "收起内容" : "查看全部"
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.latestAftersaleRecord.reason) +
                      "\n                    "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _vm.latestAftersaleRecord.imgUrlList.length
          ? _c("div", { staticClass: "request_refund_cert_list_wrap" }, [
              _c(
                "div",
                { staticStyle: { "margin-left": "2.5rem" } },
                _vm._l(
                  _vm.latestAftersaleRecord.imgUrlList,
                  function (img, index) {
                    return _c("img", {
                      key: img,
                      staticClass: "request_refund_cert_img",
                      attrs: { src: img },
                      on: {
                        click: function ($event) {
                          return _vm.previewImg(index)
                        },
                      },
                    })
                  }
                ),
                0
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "request_refund_action" }, [
          _vm.wxpayAutoRefund &&
          _vm.aftersaleRecordStatus === 5 &&
          _vm.showAciton
            ? _c(
                "div",
                {
                  staticClass:
                    "modify_request_refund g_main_bgColor_v3 g_oldThemeBgColor",
                  on: { click: _vm.modifyRequest },
                },
                [_vm._v("修改申请")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.aftersaleRecordStatus === 5 && _vm.showAciton
            ? _c(
                "div",
                {
                  staticClass: "cancel_request_refund",
                  on: { click: _vm.cancelRequestRefund },
                },
                [_vm._v("撤销申请")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.aftersaleRecordStatus === 1 && _vm.showAciton
            ? _c(
                "div",
                {
                  staticClass: "cancel_request_refund",
                  on: { click: _vm.cancelRequestRefund },
                },
                [_vm._v("撤销申请")]
              )
            : _vm._e(),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }