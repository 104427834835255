import { MobiModule } from '@/modules/Module';
import BackgroundBoxView from './index.vue';

class _BackgroundBox extends MobiModule {
    constructor() {
        super(null /** 系统模块没有style */, { system: true });
    }

    render() {
        return BackgroundBoxView;
    }
}

export const BackgroundBox = new _BackgroundBox();
