import { createPopupWindow } from '@/shared/popupWindow/popupWindowVersionTwo';
import { popupWindow as FaiPopupWindow } from '@/shared/fai';

export const statisticsHttpInCode = function (moduleId, count) {
    if (!Fai.top.httpInCode) {
        Fai.top.httpInCode = {};
    }
    Fai.top.httpInCode[moduleId] = count;

    Fai.top.httpInCodeModuleList = [];

    for (var key in Fai.top.httpInCode) {
        if (Fai.top.httpInCode[key] == 0) {
            continue;
        }
        Fai.top.httpInCodeModuleList.push(key);
        window.$store.commit('addHttpInCodeCount', Fai.top.httpInCode[key]);
    }
    if (window.$store.state.httpInCodeCount == 0) {
        if (jm('.httpIncode').length > 0) {
            jm(document).ready(function () {
                window.location.reload();
            });
        }
    } else {
        Fai.top.$('.httpIncode').text('点击定位链接位置（' + window.$store.state.httpInCodeCount + '条）');
    }
};

export function popupWindow(options) {
    var saveBeforePopup = true;
    if (!Fai.isNull(options.saveBeforePopup)) {
        saveBeforePopup = options.saveBeforePopup;
    }
    if (saveBeforePopup && Vue.prototype.$designer.checkSaveBar()) {
        return;
    }

    Vue.prototype.$layer.removeAllEditLayer();

    if (options.version == 2) {
        return createPopupWindow(options);
    } else {
        return FaiPopupWindow(options);
    }
}

export function getRootFontSize() {
    var r = jm('html').css('font-size');
    r = r.substring(0, r.length - 2);
    return Number(r);
}

export function isContainExternallink() {
    return;
}
