var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.renderTopTips.length
    ? _c(
        "div",
        {
          staticClass: "webTips jz-webTips",
          style: _vm.webTipsStyle,
          attrs: { id: "webTips" },
        },
        [
          _c("div", { staticClass: "mobitips", attrs: { id: "mobitips" } }, [
            _c(
              "div",
              { staticClass: "mobiScrollbar", style: _vm.mobiScrollbarStyle },
              [
                _c(
                  "div",
                  { staticClass: "j-marqueeContainer J_marqueeContainer" },
                  [
                    _c(
                      "ul",
                      {
                        staticClass: "marquee J_marquee",
                        attrs: { id: "mobiTipsMarquee" },
                      },
                      [
                        _vm._l(_vm.mobiTipList, function (mobiTip) {
                          return _c("li", {
                            domProps: { innerHTML: _vm._s(mobiTip) },
                          })
                        }),
                        _vm._v(" "),
                        _vm._l(_vm.topTips, function (item) {
                          return [
                            item.type == undefined || item.type == 0
                              ? _c(
                                  "li",
                                  {
                                    staticStyle: {
                                      color: "red",
                                      "margin-left": "20px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "免费域名不支持调用第三方代码，请使用已购买的域名进行访问   "
                                    ),
                                  ]
                                )
                              : item.type == 1 && _vm.httpInCodeCount
                              ? _c(
                                  "li",
                                  {
                                    staticStyle: {
                                      color: "#ff8e1e",
                                      "font-size": "13px",
                                      "text-align": "0",
                                      "margin-left": "20px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "网站内插入的代码存在非HTTPS链接，该链接无法正常加载显示，请替换成HTTPS链接或使用非HTTPS方式访问   "
                                    ),
                                  ]
                                )
                              : item.type == 2
                              ? _c(
                                  "li",
                                  {
                                    staticStyle: {
                                      color: "red",
                                      "margin-left": "20px",
                                    },
                                  },
                                  [_vm._v("免费域名不支持插件代码   ")]
                                )
                              : item.type == 3
                              ? _c(
                                  "li",
                                  {
                                    staticStyle: {
                                      color: "red",
                                      "margin-left": "20px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "基于安全原因，2018年4月11号起新注册的用户免费域名访问不支持插件代码和第三方代码，请"
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticStyle: { color: "#5874d8" },
                                        attrs: {
                                          onclick: "Mobi.logCorpDog(200848, 5)",
                                          href: _vm.urlForBindDevDomain,
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v("绑定独立域名")]
                                    ),
                                    _vm._v("进行访问或"),
                                    _c(
                                      "a",
                                      {
                                        staticStyle: { color: "#5874d8" },
                                        attrs: {
                                          onclick: "Mobi.logCorpDog(200848, 2)",
                                          href: _vm.urlForApplyFaiscoAuth,
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v("申请凡科认证")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }