// 表单 接口

import { ing } from '@/shared/tips';
import request from './request';

export function getFormLotteryByFormIdApi(params) {
    let url = `/api/${VITE_APP_MODE !== 'visitor' ? 'manage' : 'guest'}/form/getFormLotteryByFormId`;

    return request
        .get(url, {
            params,
        })
        .then(({ data }) => {
            return data;
        })
        .catch(() => {
            ing(LS.systemError, false);
        });
}

export function getFormDataApi(params = {}) {
    let url = `/api/manage/form/getFormData`;

    return request
        .get(url, {
            params,
        })
        .then(({ data }) => {
            return data;
        })
        .catch(() => {
            ing(LS.systemError, false);
        });
}

// 数据多，数据挂载data
export function addSubmitApi(data) {
    let url = `/api/guest/form/addSubmit`;
    return request
        .post(url, {
            data,
        })
        .then(({ data }) => {
            return data;
        })
        .catch(() => {
            ing(LS.systemError, false);
        });
}

export function sendValidateCodeApi(params) {
    let url = `/api/${VITE_APP_MODE !== 'visitor' ? 'manage' : 'guest'}/form/sendValidateCode`;

    return request
        .get(url, {
            params,
        })
        .then(({ data }) => {
            return data;
        })
        .catch(() => {
            ing(LS.systemError, false);
        });
}

export function addJZFormApi(params) {
    let url = `/api/manage/form/addJZForm`;

    return request
        .get(url, {
            params,
        })
        .then(({ data }) => {
            return data;
        })
        .catch(() => {
            ing(LS.systemError, false);
        });
}

export function setFormStatusListApi(data) {
    let url = `/api/manage/form/setFormStatusList`;

    return request
        .get(url, {
            data,
        })
        .then(({ data }) => {
            return data;
        })
        .catch(() => {
            ing(LS.systemError, false);
        });
}

export function getFormItemEditInfoApi(data) {
    let url = `/api/manage/form/getFormItemEditInfo`;

    return request
        .get(url, {
            data,
        })
        .then(({ data }) => {
            return data;
        })
        .catch(() => {
            ing(LS.systemError, false);
        });
}

export function getOneFormInfoApi(params) {
    let url = `/api/${VITE_APP_MODE !== 'visitor' ? 'manage' : 'guest'}/form/getOneFormInfo`;

    return request
        .get(url, {
            params,
        })
        .then(({ data }) => {
            return data;
        })
        .catch(() => {
            ing(LS.systemError, false);
        });
}

export function getFormProtocolDetail(params) {
    return request
        .get(`/api/guest/form/getFormProtocolDetail`, {
            params,
        })
        .then(({ data }) => {
            return data;
        })
        .catch(() => {
            ing(LS.systemError, false);
        });
}

export function memberModifySubmit(data) {
    return request
        .post(`/api/guest/form/memberModifySubmit`, {
            data,
        })
        .then(({ data }) => {
            return data;
        });
}
