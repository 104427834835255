<template>
    <span
        v-if="!(isShowBooking || isShowPdForm)"
        :id="pid"
        class="J_fk-mallCart faiscoIconsGwc icon-gwc g_color g_main_color_v3"
        :style="mallCartStyle"
    ></span>
    <span v-else class="is_cart2">
        <span v-if="cart2" class="bookingBtnPlaceholder"></span>
        <span
            :id="'proListBookingBtn' + pid"
            :_pid="pid"
            class="g_button proListBookingBtn g_main_bgColor_v3"
            @click.stop.prevent="productFormBtnClick"
            >{{ text }}</span
        >
    </span>
</template>

<script>
import { productFormBtnClick } from '@/modules/shared/product/btn';
export default {
    props: {
        pid: {
            type: Number,
            default: -1,
        },
        type: {
            type: [Number, String],
            default: -1,
        },
        options: {
            //
            type: Object,
            default() {
                return {};
            },
        },
        formId: {
            type: Number,
            default: -1,
        },
        text: {
            type: String,
            default: '',
        },
        cart2: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isShowBooking() {
            let { booking_system, bookingOpen } = this.options;
            return booking_system && bookingOpen;
        },
        isShowPdForm() {
            let { productFormOpen, productFormShow } = this.options;
            return productFormOpen && productFormShow == 1;
        },
        mallCartStyle() {
            return {
                'font-size': '1rem',
                width: 'auto',
                height: 'auto',
                float: 'right',
                'margin-right': '0.5rem',
            };
        },
    },
    methods: {
        productFormBtnClick() {
            if (this.isShowBooking) {
                return;
            }
            productFormBtnClick(this.formId, this.pid);
        },
    },
};
</script>

<style></style>
