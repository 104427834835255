import { MobiModule } from '@/modules/Module';
import FooterNavView from './module/footer-nav.vue';

class _FooterNav extends MobiModule {
    constructor() {
        super(null /** 系统模块没有style */, { system: true });
    }

    render() {
        return FooterNavView;
    }
}

export const FooterNav = new _FooterNav();
