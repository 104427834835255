var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showDialog,
          expression: "showDialog",
        },
      ],
      ref: "dialog",
      staticClass: "m-dialog",
    },
    [
      _c("div", { staticClass: "m-dialog-content" }, [_vm._t("content")], 2),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "m-dialog-confirm-button", on: { click: _vm.confirm } },
        [_vm._v("确定")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }