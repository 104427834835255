<template>
    <div
        :id="newsLineId"
        :topClassname="topClassName"
        :topSwitch="topSwitch"
        :newsId="id"
        :newsName="title"
        class="mixNewsStyleLine newsLine J_picNum"
        :class="lineClass"
        picnum="4"
    >
        <div :id="lineBodyId" class="lineBody">
            <div class="mixNewsStyleImgBox3">
                <a
                    hidefocus="true"
                    :href="href"
                    :target="target"
                    style="
                        width: 100%;
                        height: auto;
                        overflow: hidden;
                        max-width: 100%;
                        max-height: 8rem;
                        display: block;
                        position: relative;
                    "
                    :onclick="onclickStr"
                >
                    <div v-if="!pictureId" id="newsTitlePic" :alt="alt" class="newsTitlePic icon-noFigureB"></div>
                    <div v-else id="newsTitlePic" :alt="alt" class="newsTitlePic" :style="newsTitlePicStyle"></div>
                    <div class="mixNewsStyleTitleContainer3">
                        <div :class="topFlagClass"></div>
                        <div :class="[showLine ? 'mixNewsStyleTitle1' : 'mixNewsStyleTitle2']">
                            {{ title }}
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import newsMoreMixin from '../mixins/news-more-mixin';

export default {
    name: 'NewsMoreItemStyle3',
    mixins: [newsMoreMixin],
    data() {
        return {};
    },
};
</script>
