var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "attachListItem--item" },
    [
      _c(
        "a",
        {
          attrs: { target: "_blank" },
          on: { click: _vm.changeTheUrlToAdaptApple },
        },
        [
          _c("div", { staticClass: "attachListItem" }, [
            _c("div", { staticClass: "attachFileType" }, [
              _c("div", { class: _vm.info.fileIcon }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "fileContent" }, [
              _c("div", { staticClass: "fileContentName" }, [
                _vm._v(_vm._s(_vm.info.name)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "fileContentSize" }, [
                _vm._v(_vm._s(_vm.info.fileSizeOut)),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.showViewPDF
        ? [
            _c(
              "a",
              {
                staticClass: "attachListViewIcon",
                attrs: { target: "_blank", href: _vm.pdfPath },
                on: { click: _vm.log },
              },
              [
                _c("svg", { staticClass: "jzm-vicon_preview" }, [
                  _c("use", { attrs: { "xlink:href": "#jzm-vicon_preview" } }),
                ]),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }