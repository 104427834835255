/**
 *
 * @param {Number} type backgroundImageType
 */
export function createBackgroundImageStyle(type, color) {
    type = Number(type);
    let style = {};
    if (type === 2) {
        // 自适应
        style.backgroundSize = 'cover';
    } else if (type === 3) {
        // 自适应
        style.backgroundSize = '100% 100%';
    } else if (type === 4) {
        // 平铺
        style.backgroundSize = 'inherit';
    } else if (type === 5) {
        // 不平铺
        style.backgroundRepeat = 'no-repeat';
    } else if (type === 6) {
        style.backgroundSize = 'contain;';
    } else if (type === 7) {
        style.backgroundRepeat = 'repeat-x';
        style.backgroundPosition = 'center top';
    } else if (type === 8) {
        style.backgroundRepeat = 'repeat-x';
        style.backgroundPosition = 'center center';
    } else if (type === 9) {
        style.backgroundRepeat = 'repeat-x';
        style.backgroundPosition = 'center bottom';
    } else if (type === 10) {
        style.backgroundRepeat = 'repeat-y';
        style.backgroundPosition = 'left center';
    } else if (type === 11) {
        style.backgroundRepeat = 'repeat-y';
        style.backgroundPosition = 'center center';
    } else if (type === 12) {
        style.backgroundRepeat = 'repeat-y';
        style.backgroundPosition = 'right center';
    } else if (type === 13) {
        style.backgroundRepeat = 'no-repeat';
        style.backgroundPosition = 'center top';
        style.backgroundsize = '100% 100%';
    } else if (type === 14) {
        style.backgroundRepeat = 'no-repeat';
        style.backgroundPosition = 'center center';
    } else if (type === 15) {
        style.backgroundRepeat = 'no-repeat';
        style.backgroundPosition = 'left top';
    } else if (type === 16) {
        style.backgroundRepeat = 'no-repeat';
        style.backgroundPosition = 'center top';
    } else if (type === 17) {
        style.backgroundRepeat = 'no-repeat';
        style.backgroundPosition = 'right top';
    } else if (type === 18) {
        style.backgroundRepeat = 'no-repeat';
        style.backgroundPosition = 'left center';
    } else if (type === 19) {
        style.backgroundRepeat = 'no-repeat';
        style.backgroundPosition = 'right center';
    } else if (type === 20) {
        style.backgroundRepeat = 'no-repeat';
        style.backgroundPosition = 'left bottom';
    } else if (type === 21) {
        style.backgroundRepeat = 'no-repeat';
        style.backgroundPosition = 'center bottom';
    } else if (type === 22) {
        style.backgroundRepeat = 'no-repeat';
        style.backgroundPosition = 'right bottom';
    } else if (color.indexOf('linear-gradient') < 0) {
        // 默认不显示图片
        style.backgroundImage = 'none';
    }
    return style;
}
