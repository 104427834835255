<template>
    <!-- 左下角广告 图片加关闭按钮类型 组件 -->
    <div class="ad_component_loverLeft" @mouseenter.stop="mouseenterAction" @mouseleave="mouseleaveAction">
        <div class="ad_loverLeft_inner">
            <div class="ad_loverLeft_header" @click="closeAd">
                <img v-if="closeImg" :src="config.closeImg" class="ad_loverLeft_header__close" />
                <a v-else href="javascript:void(0)" class="ad_loverLeft_header__close"></a>
            </div>
            <div class="ad_loverLeft_body">
                <a :href="siteUpdateUrl" target="_blank" class="ad_loverLeft_link" @click="clickAd">
                    <img :src="adImg" :alt="adAlt" />
                </a>
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoverLeft',
    inject: ['websiteInfo'],
    props: {
        config: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {
        siteUpdateUrl() {
            return this.websiteInfo.siteUpdateUrl;
        },
        adImg() {
            return this.config.adImg;
        },
        adAlt() {
            return this.config.adAlt;
        },
        closeImg() {
            return !!this.config.closeImg;
        },
    },
    methods: {
        mouseenterAction() {
            this.$emit('toggleTip', true);
        },
        mouseleaveAction() {
            this.$emit('toggleTip', false);
        },
        clickAd() {
            this.$emit('clickAd');
        },
        closeAd() {
            this.$emit('closeAd');
        },
    },
};
</script>

<style lang="scss">
.ad_component_loverLeft {
    position: fixed;
    z-index: 33;
    color: #333;
}
.ad_loverLeft_inner {
    position: relative;
    width: 100%;
    height: 100%;
    background: none;
    border: none;
}
</style>
