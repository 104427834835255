<template>
    <banner></banner>
</template>

<script>
import banner from './banner.vue';
export default {
    name: 'WebBanner',
    components: {
        banner,
    },
    data() {
        return {};
    },
};
</script>

<style></style>
