import { mobiShowScroll, mobiHideScroll } from '@/shared/util';
import { Clue } from '@jz/biz-shared';
/*
 *文章分享
 *显示更多分享
 *
 */
export const showMore = function () {
    var sharePanel = document.getElementById('sharePanel');
    var showOrHide = document.getElementById('showOrHide');
    if (sharePanel && sharePanel.style) {
        if (sharePanel.style.height == 'auto') {
            sharePanel.style.height = '';
            showOrHide.className = showOrHide.className.replace('hideIcon', 'showIcon');
            sharePanel.style.background = '';
        } else {
            //sharePanel.style.background = "#E8E8E8";
            sharePanel.style.height = 'auto';
            showOrHide.className = showOrHide.className.replace('showIcon', 'hideIcon');
        }
    }
};

/*
 *
 *文章分享
 *打开遮罩层和关闭遮罩层
 */
export const showBg = function () {
    mobiHideScroll();
    var bh = document.body.clientHeight;
    var bw = document.body.clientWidth;
    var parent = document.body;
    var parentNode_div = document.createElement('div');
    var childNode_bg = document.createElement('div');
    var childNode_tips = document.createElement('div');
    parentNode_div.id = 'shareParentDiv';
    childNode_bg.id = 'shareFullbg';
    childNode_bg.className = 'shareFullbg';
    childNode_tips.id = 'shareTipsDiv';
    childNode_tips.className = 'shareTips';
    //parent.appendChild(childNode_bg);
    parent.appendChild(parentNode_div);
    var parentDiv = document.getElementById('shareParentDiv');
    parentDiv.appendChild(childNode_bg);
    parentDiv.appendChild(childNode_tips);

    var fullbgDiv = document.getElementById('shareFullbg');

    //fullbgDiv.appendChild(childNode_tips);
    fullbgDiv.style.height = bh + 'px';
    fullbgDiv.style.width = bw + 'px';
    fullbgDiv.style.position = 'fixed';

    fullbgDiv.onclick = function () {
        var tmp_fullbgDiv = document.getElementById('shareParentDiv');
        //var tmp_fullbgDiv = document.getElementById("fullbg");
        mobiShowScroll();
        tmp_fullbgDiv.parentNode.removeChild(tmp_fullbgDiv);
    };
};

export const clueLogMixin = {
    mounted() {
        Clue?.logClueNewsDetailSourceContent();
    },
};
