var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.backTopOpen
    ? _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isFirstScreen,
              expression: "!isFirstScreen",
            },
          ],
          staticClass: "backTop_box",
          attrs: { id: "backTop" },
          on: { click: _vm.gotoTop },
        },
        [
          _c("div", { staticClass: "backTop", attrs: { id: "backTopIcon" } }, [
            _vm.backTopStyle === 0
              ? _c("span", {
                  staticClass: "backTop_icon g_main_bgColor_v3",
                  class: _vm.backTopCls,
                  style: _vm.backTopComputedStyle,
                })
              : _vm.backTopStyle === 1
              ? _c("span", {
                  staticClass: "backTop_icon g_main_color_v3",
                  class: _vm.backTopCls,
                  style: _vm.backTopComputedStyle,
                })
              : _vm.backTopStyle === 2
              ? _c("span", {
                  staticClass: "backTop_icon g_main_color_v3",
                  class: _vm.backTopCls,
                  style: _vm.backTopComputedStyle,
                })
              : _vm._e(),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }