// @ts-check
import { MobiModule } from '@/modules/Module';
import ProfileVisitor from './visitor.vue';
import { MODULE_STYLE } from '@/def/module';

class MemberProfileModule extends MobiModule {
    constructor() {
        super(MODULE_STYLE.MEMBER_PROFILE, { system: true });
    }

    /** @override */
    render() {
        return ProfileVisitor;
    }
}

export const MemberProfile = new MemberProfileModule();
